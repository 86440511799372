import { GridTabDataType } from 'store/types';

export enum SimplifiedEnum {
  LAYOUT_ELEMENT = 'LayoutElement',
  LAYOUT_STACK_ELEMENT = 'LayoutStackElement',
  UI_TYPE_STATE = 'UiTypeState',
  TYPE_DECL_KEY = 'TypeDeclKey',
}

export enum StackEnum {
  STACK = 'stack',
  ROW = 'row',
  COLUMN = 'column',
}

export enum DropBoxEnum {
  GLOBAL = 'global',
  INNER = 'inner',
  TOP = 'top',
  SINGLE = 'single',
}

export enum GridTabEnum {
  MAIN = 'main',
}

export enum DefaultPanelSize {
  DEFAULT = 50,
  FULL_VIEW = 100,
}

export const GRID_TAB: Record<GridTabEnum, GridTabDataType> = {
  [GridTabEnum.MAIN]: {
    id: GridTabEnum.MAIN,
    key: GridTabEnum.MAIN,
    label: 'grid.tabs.main',
    isCloseable: false,
    isActive: true,
    isCurrentlyDragging: false,
    isMainGridTab: true,
  },
};
