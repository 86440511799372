import { ViewersMetadata, ViewersMetadataType } from 'store/types';

export const selectPrioritizedPanel = (panels: ViewersMetadata[]): ViewersMetadata | undefined => {
  let selectedPanel: ViewersMetadata | undefined;
  for (let i = 0; i < panels.length; i += 1) {
    const currentPanel = panels[i];
    const currentPanelType = currentPanel?.Type;

    if (currentPanelType === ViewersMetadataType.PRIMARY_DASHBOARD) {
      return currentPanel;
    }
    if (currentPanelType === ViewersMetadataType.DASHBOARD) {
      selectedPanel = currentPanel;
    }
  }

  return selectedPanel;
};
