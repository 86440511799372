import { Filters, SortingRule } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { AppStateTokens } from 'store/types/AppStateType';
import { CustomToastType, OpenToastType, ToastType } from 'utils';
import { AuthTypeModel, StorageEntityModel } from 'api';
import { HandlerResultDataType } from 'view/TypeToolbar/types';
import { LogsLevelEnum } from 'view/Logs/utils/types';

export type EditorSavedDataType = {
  editorData: StorageEntityModel;
  viewerData: HandlerResultDataType | null;
};

export type EditorTabDataType = Record<string, EditorSavedDataType>;

export enum StorageKeyEnum {
  VERSION = 'version',
  FILTERS = 'filters',
  SORTING = 'sorting',
  SHOW_DISCLAIMER_ONCE = 'showDisclaimerOnce',
  TOKENS = 'tokens',
  AUTH_TYPE = 'authType',
  TOAST_DATA = 'toastData',
  PARAMS_VIEWERS = 'paramsViewers',
  SESSION_ID = 'sessionId',
  SELECTED_LLM = 'selectedLlm',
  IS_LOGS_PANEL = 'isLogsPanel',
  IS_LOGS_AUTO_SCROLL = 'isLogsAutoScroll',
  LOGS_FILTERS = 'logsFilters',
}

export type ExternalStorage = {
  getVersion: () => string | null;
  setVersion: (version: string) => void;
  setFiltersByKey: (key: string, filters: Filters<DataType>) => void;
  getFiltersByKey: (key: string) => Filters<DataType>;
  clearFilterByKey: (key: string) => void;
  setSortByKey: (key: string, sorting: Array<SortingRule<DataType>>) => void;
  getSortByKey: (key: string) => Array<SortingRule<DataType>>;
  clearSortByKey: (key: string) => void;
  clear: () => void;
  setTokens: (tokens: AppStateTokens) => void;
  getTokens: () => AppStateTokens | undefined;
  removeTokens: () => void;
  setAuthType: (type: AuthTypeModel) => void;
  getAuthType: () => AuthTypeModel | undefined;
  removeAuthType: () => void;
  addToast: (toast: CustomToastType) => void;
  getToasts: () => OpenToastType;
  removeToast: (type: ToastType, id: string) => void;
  removeAllTypeToasts: (type: ToastType) => void;
  removeAllToasts: () => void;
  setParamsViewers: (viewerName: string, data: EditorSavedDataType) => void;
  getParamsViewers: () => EditorTabDataType;
  removeParamsViewer: (viewerName: string) => void;
  removeAllParamsViewers: () => void;
  getSessionId: () => string | null;
  setSessionId: (sessionId: string) => void;
  removeSessionId: () => void;
  getSelectedLlm: () => string | null;
  setSelectedLlm: (llm: string) => void;
  setIsLogsPanel: (isOpen: boolean) => void;
  getIsLogsPanel: () => boolean;
  setIsLogsAutoScroll: (isAutoScroll: boolean) => void;
  getIsLogsAutoScroll: () => boolean;
  setLogsFilters: (filters: string[]) => void;
  getLogsFilters: () => LogsLevelEnum[];
};
