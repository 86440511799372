import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { OnValidate } from '@monaco-editor/react';

import { Button } from 'shared/Button';
import { CodeEditor } from 'shared/CodeEditor';
import { deserialize, serialize } from 'utils';
import { UiModal } from 'ui/UiModal';
import { LanguageEnum } from 'api';

export type CodeEditorModalProps<TValue = unknown> = {
  data?: TValue;
  isOpen?: boolean;
  isReadOnly?: boolean;
  onClose: () => void;
  onSave?: (data: TValue) => void | Promise<void>;
};

export const CodeEditorModal = <TValue,>({
  data,
  isReadOnly = false,
  isOpen = false,
  onClose,
  onSave,
}: CodeEditorModalProps<TValue>) => {
  const { t } = useTranslation();
  const [code, setCode] = useState('');
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (data && isOpen) {
      setCode(serialize(data, undefined, 2));
      setIsSaveButtonDisabled(true);
    }
  }, [data, isOpen]);

  const handleChanged = (value?: string) => {
    value && setCode(value);
    setIsSaveButtonDisabled(false);
  };

  const handleValidate: OnValidate = (markers) => {
    markers.length > 0 && setIsSaveButtonDisabled(true);
  };

  const handleSave = () => {
    if (!code) return;
    onSave?.(deserialize(code));
  };

  return (
    <UiModal size="lg" title={t('editor.openJSONEditor') || ''} isOpen={isOpen} onClose={onClose} defaultHeight={600}>
      <UiModal.Body className="overflow-hidden">
        <CodeEditor
          isReadonly={isReadOnly}
          code={code}
          language={LanguageEnum.Json}
          onChange={handleChanged}
          onValidate={handleValidate}
          isAutoFocus={true}
        />
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={t('buttons.cancel')} variant="secondary" onClick={onClose} />
        {!isReadOnly && <Button title={t('buttons.save')} disabled={isSaveButtonDisabled} onClick={handleSave} />}
      </UiModal.Footer>
    </UiModal>
  );
};
