import {
  actions,
  Column,
  ColumnInstance,
  Filters,
  HeaderProps,
  Row,
  SortingRule,
  useBlockLayout,
  useColumnOrder,
  useExpanded,
  useFilters,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import { LoadingStatusesEnum } from 'api';
import { FilterCell } from 'view/Grid/components/TableHeader/FilterCell';
import { FilterInput } from 'view/Grid/components/TableHeader/FilterInput';
import { ContextMenuProvider } from 'shared/ContextMenu';
import { Loader } from 'shared/Loader';
import { prepareCustomFilterCellType } from 'containers/TabItemContent/utils/types';
import { addDataToClickboard } from 'utils';
import { getSelectedRangeCells } from 'view/Grid/utils/selectedRangeCells';
import { CheckboxSelectionAll } from 'view/Grid/components/CheckboxSelection';
import { CheckboxSelection } from 'view/Grid/components/CheckboxSelection/CheckboxSelection';

import { useCellRangeSelection } from './plugins/useCellRangeSelection';
import { useFilterColumnCheckBox } from './plugins/useFilterColumnCheckBox';
import {
  ColumnNameType,
  CoordinateType,
  DataType,
  ExcelGridOptions,
  ExportGridOptionsType,
  GetRowIdType,
  GridModeEnum,
  GridRef,
  SelectedRowsRangeType,
} from './utils/types';
import { TableHeader } from './components/TableHeader';
import { TableBody } from './components/TableBody';
import { defaultGetRowId, downloadGridCSVHelper } from './utils/helpers';
import { downloadGridExcelHelper } from './utils/excel';
import { HorizontalScroll } from './components/HorizontalScroll';
import { GridContext } from './Context';
import { useNavigation } from './hooks';
import { SelectedRowsCounter } from './components/SelectedRowsCounter';
import { useColumnWidth } from './plugins/useColumnWidth';
import { HandlerRecordStatus } from './components/HandlerRecordStatus';

import styles from './styles.module.scss';

type GridProps<T extends DataType> = {
  gridId: string;
  className?: string;
  columns: Readonly<Column<T>>[];
  initiallyHiddenColumns?: string[];
  data: T[];
  status?: LoadingStatusesEnum;
  selectedRowsRange?: SelectedRowsRangeType;
  selectedCheckbox?: Record<string, boolean>;
  variant?: 'primary' | 'secondary';
  isColumnFilter?: boolean;
  initialFilters?: Filters<T>;
  initialSortBy?: Array<SortingRule<T>>;
  isCountRowCellExist?: boolean;
  gridMode?: GridModeEnum;
  gridName?: string;
  getRowId?: GetRowIdType;
  selectedRowId?: string;
  onChangeSelectedRowId?: (rowId: string) => void;
  onSelectedRowsRangeChanged?: (newSelectedRowsRange: SelectedRowsRangeType | undefined) => void;
  onRowDoubleClicked?: (row: Row<DataType>) => void;
  onFiltersChanged?: (changedFilter: Filters<T>) => void;
  onSortingChanged?: (changedSortBy: Array<SortingRule<T>>) => void;
  onSelectedCheckboxChange?: (value: Record<string, boolean>) => void;
  shouldClearCellsSelection?: boolean;
  customFilterCell?: ({
    passedGridRef,
    passedColumns,
    gridMode,
    rows,
    onSelectedRowsRangeChanged,
    clearCellSelectionState,
    selectAllRowsAllCells,
    isSelectAllRecordsBtnClicked,
  }: prepareCustomFilterCellType) => JSX.Element;
  selectedRowsPerPage?: SelectedRowsRangeType;
  setSelectedRowsPerPage?: Dispatch<SetStateAction<SelectedRowsRangeType>>;
  maxRangeCounter?: number;
  useCheckboxes?: boolean;
  isAvailableNextPage?: boolean;
  isSelectAllRecordsBtnClicked?: boolean;
  setIsSelectAllRecordsBtnClicked?: Dispatch<SetStateAction<boolean>>;
  isRunHandlers?: boolean;
  isHandlersArr?: boolean;
  setHeaders?: (passedHeaders: ColumnInstance<DataType>[]) => void;
};

export const Grid = forwardRef(
  (
    {
      gridId,
      initialFilters = [],
      initialSortBy = [],
      className,
      columns,
      initiallyHiddenColumns = [],
      data,
      variant = 'primary',
      isColumnFilter = true,
      useCheckboxes = false,
      isCountRowCellExist,
      gridName,
      status = LoadingStatusesEnum.SUCCESS,
      gridMode = GridModeEnum.DEFAULT,
      selectedRowsRange = {},
      getRowId: customGetRowId,
      selectedRowId: controlledSelectedRowId,
      onChangeSelectedRowId: onChangeControlledSelectedRowId,
      onSelectedCheckboxChange,
      onSelectedRowsRangeChanged = () => undefined,
      onRowDoubleClicked = () => undefined,
      onSortingChanged = () => undefined,
      shouldClearCellsSelection,
      customFilterCell,
      selectedCheckbox = {},
      selectedRowsPerPage,
      setSelectedRowsPerPage,
      isSelectAllRecordsBtnClicked,
      setIsSelectAllRecordsBtnClicked,
      isRunHandlers = false,
      setHeaders,
    }: GridProps<DataType>,
    ref: ForwardedRef<GridRef>
  ) => {
    const backgroundColor = {
      primary: 'bg-primary',
      secondary: 'bg-modal-grid-background',
    }[variant];

    const gridRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const firstUseEffectIteration = useRef<boolean>(true);

    const selectedAllBtnClickedStatRef = useRef(false);

    const [isHiddenVerticalScroll, setHiddenVerticalScroll] = useState(false);
    const [isHiddenHorizontalScroll, setHiddenHorizontalScroll] = useState(false);
    const isShowScrollCorner = !isHiddenVerticalScroll;

    const getRowId = useCallback(
      (row: DataType, relativeIndex: number, parent?: Row<DataType>) => {
        if (customGetRowId) {
          return customGetRowId(row, relativeIndex, parent);
        }

        return defaultGetRowId(row, relativeIndex, parent);
      },
      [customGetRowId]
    );

    useEffect(() => {
      if (firstUseEffectIteration.current) {
        firstUseEffectIteration.current = false;
      } else {
        shouldClearCellsSelection && clearCellSelectionState();
      }
    }, [shouldClearCellsSelection]);

    const [uncontrolledSelectedRowId, onChangeUncontrolledSelectedRowId] = useState<string>();
    const selectedRowId = controlledSelectedRowId ?? uncontrolledSelectedRowId;
    const onChangeSelectedRowId = onChangeControlledSelectedRowId ?? onChangeUncontrolledSelectedRowId;

    const {
      isDefaultMarkedIndex,
      markedIndex,
      resetMarkedRow,
      onKeyDown: navigationKeyDownHandler,
      attachRows,
    } = useNavigation({ selectedRowId, onChangeSelectedRowId });

    const {
      setCurrentSelectedCellIds,
      setSelectedCellIds,
      changeSelectedCell,
      updateSelectedRowsPerPageState,
      selectAllRowsAllCells,
      clearCellSelectionState,
      selectNewCellsArr,
      selectProvidedRowsCells,
      rewriteActiveRowStartCell,
      getColumnWidthProps,
      totalColumnsWidth,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      preFilteredRows,
      prepareRow,
      setColumnOrder,
      visibleColumns,
      cellIdSplitBy,
      headers,
      state: { selectedCellIds, currentSelectedCellIds, selectedRowsIds },
    } = useTable<DataType>(
      {
        autoResetResize: false,
        autoResetFilters: false,
        autoResetSortBy: false,
        columns,
        data,
        getRowId,
        defaultColumn: {
          applyMaxWidth: true,
          Filter: (props) => <FilterInput {...props} />,
        },
        initialState: {
          filters: initialFilters,
          sortBy: initialSortBy,
          hiddenColumns: initiallyHiddenColumns,
        },
        stateReducer: (newState, action) => {
          if (action.type === actions.toggleSortBy || action.type === actions.setSortBy) {
            resetMarkedRow();
          }

          return newState;
        },
      },
      useCellRangeSelection,
      useColumnWidth,
      useFilterColumnCheckBox,
      useFilters,
      useSortBy,
      useExpanded,
      useColumnOrder,
      useResizeColumns,
      useBlockLayout,
      (hooks) => {
        isColumnFilter &&
          hooks.allColumns.push((columnDeclarations) => [
            {
              id: 'filter',
              customHeader: true,
              suppressDrag: true,
              suppressHideColumn: true,
              suppressHeaderTooltip: true,
              applyMaxWidth: true,
              minWidth: 45,
              width: 45,
              maxWidth: 45,
              disableResizing: true,
              Header: (props: HeaderProps<DataType>) => {
                const {
                  isSelectAllRecordsBtnClicked: contextIsSelectAllRecordsBtnClicked,
                  selectedCheckbox: selectedCheckboxContext,
                } = useContext(GridContext);

                if (customFilterCell) {
                  selectedAllBtnClickedStatRef.current = Boolean(contextIsSelectAllRecordsBtnClicked);

                  return customFilterCell({
                    passedGridRef: gridRef,
                    passedColumns: props.allColumns,
                    gridMode,
                    rows: props.rows,
                    onSelectedRowsRangeChanged,
                    clearCellSelectionState,
                    selectAllRowsAllCells,
                    updateSelectedRowsPerPageState: props.updateSelectedRowsPerPageState,
                    passedSelectedRowsPerPage: props.state.selectedRowsPerPageState,
                    isSelectAllRecordsBtnClicked: contextIsSelectAllRecordsBtnClicked,
                  });
                }

                return (
                  <>
                    <FilterCell columns={props.allColumns} />
                    {useCheckboxes && (
                      <CheckboxSelectionAll
                        rows={props.rows}
                        selectedRows={selectedCheckboxContext}
                        onSelectedCheckboxChange={onSelectedCheckboxChange}
                      />
                    )}
                  </>
                );
              },
              Cell: ({ row }: { row: Row<DataType> }) => {
                const { selectedRowsRange: selectedRowsRangeContext, selectedCheckbox: selectedCheckboxContext } =
                  useContext(GridContext);

                if (gridMode === GridModeEnum.READ_ONLY) return null;

                return (
                  <div className="d-flex justify-content-around align-items-center h-100">
                    {isCountRowCellExist ? row.index + 1 : null}

                    {gridMode === GridModeEnum.MAIN && (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        readOnly
                        checked={
                          selectedAllBtnClickedStatRef.current ||
                          !!Object.values(selectedRowsRangeContext).find(
                            (selectedRow) => selectedRow._key === row.original._key
                          )
                        }
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    )}
                    {useCheckboxes && (
                      <CheckboxSelection
                        row={row}
                        selectedRows={selectedCheckboxContext}
                        onSelectedCheckboxChange={onSelectedCheckboxChange}
                      />
                    )}
                  </div>
                );
              },
            },
            ...columnDeclarations,
          ]);
        isRunHandlers &&
          hooks.allColumns.push((columnDeclarations) => [
            {
              id: 'handlerStatus',
              customHeader: true,
              suppressDrag: true,
              suppressHideColumn: true,
              suppressHeaderTooltip: true,
              applyMaxWidth: true,
              minWidth: 30,
              width: 30,
              maxWidth: 30,
              Header: () => <div className="bg-grid-headers-background h-100 w-100" />,
              Cell: ({ row }: { row: Row<DataType> }) => <HandlerRecordStatus row={row} />,
            },
            ...columnDeclarations,
          ]);
      }
    );

    useEffect(() => {
      setHeaders && setHeaders(headers);
    }, [headers]);

    // TODO: Refactor it. This callback hides the loader when columns are fit.
    const loaderMarkCallbackRef = useCallback((node: HTMLDivElement | null) => {
      setTimeout(() => {
        node?.classList.add('d-none');
      }, 20);
    }, []);

    useEffect(() => {
      rows.forEach((row) => {
        prepareRow(row);
      });

      attachRows(rows);
    }, [rows]);

    // select the first row if the selected row is empty
    useEffect(() => {
      if (selectedRowId || rows.length === 0) return;

      const firstRow = rows[0];
      onChangeSelectedRowId(firstRow.id);

      const filterColumnIndex = firstRow.allCells.findIndex((cell) => cell.column.id === ColumnNameType.FILTER);
      rewriteActiveRowStartCell(firstRow.allCells[filterColumnIndex !== -1 ? filterColumnIndex + 1 : 0]?.id || '');
    }, [rows]);

    const selectedRangeCells = useMemo(
      () => ({ ...currentSelectedCellIds, ...selectedCellIds }),
      [currentSelectedCellIds, selectedCellIds]
    );

    const handleResetSelection = () => {
      onChangeControlledSelectedRowId?.(rows[0].id);
      clearCellSelectionState();
    };

    const handleSortByChanged = (updatedSortBy: SortingRule<DataType>[]) => {
      if (rows.length > 0) {
        onSortingChanged(rows.length > 0 ? updatedSortBy : []);
        handleResetSelection();
      }
    };

    const exportGridOptions: ExportGridOptionsType = useMemo(
      () => ({
        toExcel: (options: ExcelGridOptions) => downloadGridExcelHelper(visibleColumns, rows, options),
        toCSV: () => downloadGridCSVHelper(visibleColumns, rows, gridName),
      }),
      [gridName, visibleColumns, rows]
    );

    const handleCopySelectedRangeCells = useCallback(() => {
      const stringifyCells = getSelectedRangeCells(columns, rows, selectedRangeCells, { cellIdSplitBy });

      addDataToClickboard(stringifyCells);
    }, [cellIdSplitBy, columns, rows, selectedRangeCells]);

    useImperativeHandle(ref, () => ({
      exportGridOptions,
    }));

    const changeOrderColumns = useCallback(
      (startIndex: number, endIndex: number) => {
        const columnIds = visibleColumns.map((newColumn) => newColumn.id);
        const [columnId] = columnIds.splice(startIndex, 1);

        if (columnId) {
          columnIds.splice(endIndex, 0, columnId);
          setColumnOrder(columnIds);
        }
      },
      [setColumnOrder, visibleColumns]
    );

    const scrollHeaderTo = useCallback((coordinate: CoordinateType) => {
      if (!headerRef.current) return;

      headerRef.current.scrollTo({
        behavior: 'smooth',
        ...coordinate,
      });
    }, []);

    const scrollBodyTo = useCallback((coordinate: CoordinateType) => {
      if (!bodyRef.current) return;

      bodyRef.current.scrollTo({
        behavior: 'smooth',
        ...coordinate,
      });
    }, []);

    const context = useMemo(
      () => ({
        headerRef,
        bodyRef,
        changeOrderColumns,
        selectedRowsRange,
        isSelectAllRecordsBtnClicked,
        setIsSelectAllRecordsBtnClicked,
        selectedCheckbox,
        handleSortByChanged,
      }),
      [
        headerRef,
        bodyRef,
        changeOrderColumns,
        selectedRowsRange,
        isSelectAllRecordsBtnClicked,
        setIsSelectAllRecordsBtnClicked,
        selectedCheckbox,
        handleSortByChanged,
      ]
    );

    const getCustomTableProps = useCallback(
      () =>
        getTableProps({
          style: { ...getTableProps().style, minWidth: 'auto' },
        }),
      [getTableProps]
    );

    return status === LoadingStatusesEnum.INITIAL || status === LoadingStatusesEnum.LOADING ? (
      <Loader />
    ) : (
      <GridContext.Provider value={context}>
        <div
          ref={gridRef}
          {...getCustomTableProps()}
          className={cx(
            `mb-0 border-dark d-flex flex-column h-100 user-select-none position-relative overflow-auto ${backgroundColor}`,
            className
          )}
        >
          <div ref={loaderMarkCallbackRef} className={cx('w-100 h-100 position-absolute', styles.loaderMask)}>
            <Loader />
          </div>
          <TableHeader
            gridRef={gridRef}
            isShowCorner={isShowScrollCorner}
            headerGroups={headerGroups}
            gridMode={gridMode}
            onSortByChanged={handleSortByChanged}
          />
          <ContextMenuProvider>
            {rows?.length > 0 ? ( // TODO this condition prevent bug (Open drilled simple list -> add row -> delete all row -> first row will not render)
              <TableBody
                gridId={gridId}
                isHiddenVerticalScroll={isHiddenVerticalScroll}
                setHiddenVerticalScroll={setHiddenVerticalScroll}
                isDefaultMarkedIndex={isDefaultMarkedIndex}
                markedIndex={markedIndex}
                columns={visibleColumns}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                rows={rows}
                preFilteredRows={preFilteredRows}
                isInfoCellExist={isColumnFilter}
                isCountRowCellExist={isCountRowCellExist}
                selectedCellIds={selectedCellIds}
                selectedRangeCells={selectedRangeCells}
                selectedRowsRange={selectedRowsRange}
                selectedRowsIds={selectedRowsIds}
                onSelectedRowsRangeChanged={onSelectedRowsRangeChanged}
                handleCopySelectedRangeCells={handleCopySelectedRangeCells}
                gridMode={gridMode}
                onRowDoubleClicked={onRowDoubleClicked}
                selectedRowId={selectedRowId}
                onSelectRowId={onChangeSelectedRowId}
                navigationKeyDownHandler={navigationKeyDownHandler}
                getColumnWidthProps={getColumnWidthProps}
                rewriteActiveRowStartCell={rewriteActiveRowStartCell}
                selectNewCellsArr={selectNewCellsArr}
                selectProvidedRowsCells={selectProvidedRowsCells}
                selectedRowsPerPage={selectedRowsPerPage}
                setSelectedRowsPerPage={setSelectedRowsPerPage}
                updateSelectedRowsPerPageState={updateSelectedRowsPerPageState}
                isSelectAllRecordsBtnClicked={context.isSelectAllRecordsBtnClicked}
                setIsSelectAllRecordsBtnClicked={context.setIsSelectAllRecordsBtnClicked}
                setSelectedCellIds={setSelectedCellIds}
                setCurrentSelectedCellIds={setCurrentSelectedCellIds}
                changeSelectedCell={changeSelectedCell}
              />
            ) : (
              // ToDo: If rows is not defined, provide empty component
              <div className="flex-grow-1" />
            )}
            <SelectedRowsCounter
              areAllRecordsSelected={selectedAllBtnClickedStatRef.current}
              selectedRowsCount={Object.keys(selectedRowsRange).length}
            />
          </ContextMenuProvider>
          <HorizontalScroll
            isShowCorner={isShowScrollCorner}
            totalColumnsWidth={totalColumnsWidth}
            isHidden={isHiddenHorizontalScroll}
            setHidden={setHiddenHorizontalScroll}
            scrollBodyTo={scrollBodyTo}
            scrollHeaderTo={scrollHeaderTo}
          />
        </div>
      </GridContext.Provider>
    );
  }
);
// add display name to avoid lint error - Component definition is missing display name(react/display-name
Grid.displayName = 'Grid';
