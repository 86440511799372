import { FC, memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImperativePanelGroupHandle, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { handlerApi, HandlerGetParamsRequestDto, RunHandlerStatusCodeEnum, StorageEntityModel } from 'api';
import { selectDataset } from 'containers/TabItemContent/store/selectors';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { Loader } from 'shared/Loader';
import {
  ExecutedHandlerResultData,
  ExecutedHandlerRunTaskDataType,
  ExecutedHandlerStatusData,
} from 'view/TypeToolbar/types';
import { UiEmpty } from 'ui';
import { resizePlotly, ToastTypeEnum } from 'utils';
import { externalStorage } from 'shared/ExternalStorage';
import { EditorTabDataType } from 'shared/ExternalStorage/utils/types';
import { selectIsReadonly } from 'store/selectors/AppState.selector';
import { getTimeoutDelay } from 'utils/helpers/timeoutDelay';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';
import { useSSEContext } from 'containers/SSEProvider';
import { env } from 'env';
import { showHandlerToast } from 'utils/helpers/showHandlerToast';

import { EditorViewerWithParams } from 'view/EditorData/EditorViewerWithParams';

import { Viewers } from '..';
import styles from './styles.module.scss';

type ParamsViewerProps = {
  activeTab?: string;
  panelKey?: string;
  panelWithParamsName?: string;
};

type ActiveTaskDataType = {
  taskRunId: string;
  data: StorageEntityModel;
};

export const ParamsViewer: FC<ParamsViewerProps> = memo(({ activeTab, panelKey, panelWithParamsName }) => {
  const { t } = useTranslation();
  const groupPanelRef = useRef<ImperativePanelGroupHandle>(null);
  const dataset = useSelector(selectDataset);
  const environment = useSelector(selectActiveEnvironment);
  const { taskProgress } = useSSEContext();

  const { activeTab: mainActiveTab } = useTabParams();
  const { state } = useTabItemContext();
  const isAppStateReadOnly = useSelector(selectIsReadonly);
  const isReadOnly = isAppStateReadOnly || state.isTypeReadOnly;

  const uniqueViewerName = `${panelKey}${panelWithParamsName}`;

  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [editorTabData, setEditorTabData] = useState<EditorTabDataType | null>(externalStorage.getParamsViewers());
  const [activeTaskData, setActiveTaskData] = useState<ActiveTaskDataType | null>(null);

  useEffect(() => {
    if (groupPanelRef.current) {
      groupPanelRef.current.setLayout([50, 50], 'percentages');
    }
  }, [activeTab]);

  useEffect(() => {
    setIsLoadingStatus(false);
  }, [panelWithParamsName]);

  useEffect(() => {
    if (taskProgress && activeTaskData && activeTaskData.taskRunId === taskProgress.TaskRunId) {
      const { StatusCode, TaskRunId } = taskProgress;

      if (StatusCode === RunHandlerStatusCodeEnum.Completed) {
        getTaskResult(TaskRunId, activeTaskData?.data);
      } else if (StatusCode === RunHandlerStatusCodeEnum.Failed || StatusCode === RunHandlerStatusCodeEnum.Cancelled) {
        showHandlerToast(ToastTypeEnum.ERROR, t('toast.failedUpperCase'));
        setIsLoadingStatus(false);
        externalStorage.removeParamsViewer(uniqueViewerName);
      }
    }
  }, [taskProgress]);

  const getTaskResult = (taskRunId: string, editorResponseData: StorageEntityModel) => {
    const params = {
      dataset: dataset.activeDatasetName,
      dataSource: environment,
      taskRunIds: [taskRunId],
    };
    handlerApi
      .runTasksResult<ExecutedHandlerResultData[]>(params)
      .then((res) => {
        if (res.length) {
          externalStorage.setParamsViewers(uniqueViewerName, {
            editorData: editorResponseData,
            viewerData: res[0].Result,
          });
          showHandlerToast(ToastTypeEnum.SUCCESS, `${panelKey} / ${activeTab}`);
          setIsLoadingStatus(false);
        }
      })
      .catch((e) => {
        showHandlerToast(ToastTypeEnum.ERROR, e);
      })
      .finally(() => setIsLoadingStatus(false));
  };

  const getTaskStatus = (taskStatusParams: HandlerGetParamsRequestDto, editorData: StorageEntityModel) => {
    const startTime = Date.now();
    let timeout: NodeJS.Timeout;

    const fetchData = (params: HandlerGetParamsRequestDto, editorResponseData: StorageEntityModel) => {
      handlerApi
        .runTasksStatus<ExecutedHandlerStatusData[]>(params)
        .then((data) => {
          const { StatusCode, TaskRunId } = data[0];
          const isHandlerExecuted =
            StatusCode !== RunHandlerStatusCodeEnum.Submitted && StatusCode !== RunHandlerStatusCodeEnum.Running;
          clearTimeout(timeout);

          if (isHandlerExecuted) {
            if (StatusCode === RunHandlerStatusCodeEnum.Completed) {
              getTaskResult(TaskRunId, editorResponseData);
            } else {
              showHandlerToast(ToastTypeEnum.ERROR, t('toast.failedUpperCase'));
              setIsLoadingStatus(false);
              externalStorage.removeParamsViewer(uniqueViewerName);
            }
          } else {
            timeout = setTimeout(() => fetchData(params, editorResponseData), getTimeoutDelay(startTime));
          }
        })
        .catch((e) => {
          setIsLoadingStatus(false);
          showHandlerToast(ToastTypeEnum.ERROR, e);
        });
    };

    fetchData(taskStatusParams, editorData);
  };

  const executeHandlerViewer = (data: StorageEntityModel) => {
    const params = {
      dataset: dataset.activeDatasetName,
      dataSource: environment,
      table: mainActiveTab?.id,
      keys: panelKey ? [panelKey] : undefined,
      method: panelWithParamsName,
      args: data as object,
    };

    handlerApi
      .runTasks<ExecutedHandlerRunTaskDataType[]>(params)
      .then((response) => {
        if (response[0].TaskRunId) {
          setIsLoadingStatus(true);

          if (env.isSSEHandlers) {
            setActiveTaskData({ taskRunId: response[0].TaskRunId, data });
          } else {
            const tasksStatusParams = {
              dataset: dataset.activeDatasetName,
              dataSource: environment,
              taskRunIds: [response[0].TaskRunId],
            };
            getTaskStatus(tasksStatusParams, data);
          }

          setEditorTabData({ ...editorTabData, [uniqueViewerName]: { editorData: data, viewerData: null } });
        }
      })
      .catch((e) => {
        setIsLoadingStatus(false);
        showHandlerToast(ToastTypeEnum.ERROR, e);
      });
  };

  if (!mainActiveTab?.id) return null;

  const renderViewerData = () => {
    if (isLoadingStatus) return <Loader />;

    const viewerData = externalStorage.getParamsViewers()[uniqueViewerName]?.viewerData;

    if (!viewerData)
      return (
        <div className={cx('h-100 d-flex justify-content-center', styles.rightPane)}>
          <UiEmpty />
        </div>
      );

    return <Viewers activeViewerType={viewerData._t} data={viewerData} />;
  };

  return (
    <PanelGroup direction="horizontal" ref={groupPanelRef}>
      <Panel className="d-flex flex-column h-100 position-relative" onResize={resizePlotly} minSize={25}>
        <EditorViewerWithParams
          isReadOnly={isReadOnly}
          recordType={mainActiveTab.id}
          partSchemaName={`${panelWithParamsName}Args`}
          editorData={editorTabData?.[uniqueViewerName]?.editorData}
          onSubmit={executeHandlerViewer}
        />
      </Panel>
      <PanelResizeHandle className="verticalPanelResizer" />
      <Panel className="h-100" minSize={25}>
        {renderViewerData()}
      </Panel>
    </PanelGroup>
  );
});

ParamsViewer.displayName = 'ParamsViewer';
