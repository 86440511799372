import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TypeToolbarDataType = {
  isLogsOpened: boolean;
};

const initState: { typeToolbarData: TypeToolbarDataType } = {
  typeToolbarData: {
    isLogsOpened: false,
  },
};

const TypeToolbarSlice = createSlice({
  name: 'typeToolbarData',
  initialState: initState,
  reducers: {
    toggleLogsContainer(state, action: PayloadAction<boolean>) {
      state.typeToolbarData.isLogsOpened = action.payload;
    },
  },
});

export const TypeToolbarSliceReducer = TypeToolbarSlice.reducer;

export const { toggleLogsContainer } = TypeToolbarSlice.actions;
