import { useMemo } from 'react';

import useToggle from './useToggle';

interface Actions {
  setTrue: () => void;
  setFalse: () => void;
  set: (value: boolean) => void;
  toggle: () => void;
}
export function useBoolean(defaultValue = false): [boolean, Actions] {
  const [state, { toggle, set }] = useToggle(Boolean(defaultValue));

  const actions: Actions = useMemo(() => {
    const setTrue = () => set(true);
    const setFalse = () => set(false);
    return {
      toggle,
      set: (v) => set(Boolean(v)),
      setTrue,
      setFalse,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, actions];
}
