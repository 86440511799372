import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

type GoToDrilledDataIconProps = {
  className?: string;
  isDisable?: boolean;
  onClick: () => void;
};

export const GoToDrilledDataIcon: FC<GoToDrilledDataIconProps> = ({ isDisable, className, onClick }) => {
  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    // "stopPropagation" needed if click occurred from NO Active carousel item.
    // We need just to replace old breadcrumb tab to the new one, without setting active current tab where icon located
    e.stopPropagation();
    onClick();
  };

  return (
    <Icon
      className={className}
      SvgIcon={APP_ICONS.goToArray}
      title={t('shared.open') || ''}
      disable={isDisable}
      onClick={handleClick}
    />
  );
};
