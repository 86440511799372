import { ColumnResizeMode, Header } from '@tanstack/react-table';

import { UIGridHeaderCellWrapper } from '../UIGridHeaderCellWrapper/UIGridHeaderCellWrapper.component';
import { UIGridHeaderCellResizer } from '../UIGridHeaderCellResizer/UIGridHeaderCellResizer.component';
import './UIGridHeaderCell.styles.scss';

type UIGridHeaderCellProps<TData, TValue> = {
  header: Header<TData, TValue>;
  resizeMode: ColumnResizeMode;
  resizingDeltaOffset: number;
};

export const UIGridHeaderCell = <TData, TValue>({
  header,
  resizeMode,
  resizingDeltaOffset,
}: UIGridHeaderCellProps<TData, TValue>) => (
  <div className="ui-grid-header-cell" style={{ width: header.getSize() }}>
    {header.isPlaceholder ? null : <UIGridHeaderCellWrapper header={header} />}
    <UIGridHeaderCellResizer resizingDeltaOffset={resizingDeltaOffset} resizeMode={resizeMode} header={header} />
  </div>
);
