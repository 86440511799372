import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorProps, EditorSubmitType } from 'view/Editor/type';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { StorageEntityModel } from 'api';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';

type EditorHandlerProps = {
  partSchemaName: string;
  schemaType: string;
  selectedRecordType?: string;
  onCancel?: () => void;
  onSubmit?: (data: StorageEntityModel) => void;
};

export const EditorHandler: FC<EditorHandlerProps> = ({
  partSchemaName,
  schemaType,
  selectedRecordType,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { getSchema } = useEditorRequest();
  const [editorProps, setEditorProps] = useState<Pick<EditorProps, 'schema' | 'rootSchemaName'> | null>(null);

  const areHandlerElementsOptional = editorProps?.schema[editorProps?.rootSchemaName].Elements?.every(
    (element) => element.Optional
  );

  useEffect(() => {
    if ((selectedRecordType || schemaType) && partSchemaName) {
      getSchema.fetch({ name: selectedRecordType || schemaType }).then((schema) => {
        setEditorProps({
          schema: schema.typeList,
          rootSchemaName: Object.keys(schema.typeList)?.find((it) => it.endsWith(partSchemaName)) || '',
        });
      });
    }
  }, [schemaType, partSchemaName]);

  const handleSubmit = ({ data }: EditorSubmitType) => onSubmit?.(data);

  if (getSchema.isLoading) return <Loader />;

  if (!editorProps) return <UiEmpty />;

  return (
    <EditorInstance
      {...editorProps}
      i18n={{
        submitBtnText: t('buttons.execute'),
      }}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      isSubmitDisabled={!areHandlerElementsOptional}
    />
  );
};
