export type UserModel = {
  username: string;
  firstName: string;
  lastName: string | null;
  fullName: string;
  email: string | null;
  roles: UserRole[];
  sessionId: string;
};

export enum UserRole {
  Developer = 'Developer',
}
