import cx from 'classnames';
import { FC, Fragment, createContext, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { GridTabDataType } from 'store/types';
import { useDnD } from 'hook/dnd.hook';
import { EditorTabsState } from 'view/EditorContent/utils/types';
import { selectGridMaximizedId, selectIsGridMaximized } from 'store/selectors/grid.selector';
import { useAppContext } from 'context';
import { resizePlotly } from 'utils';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';

import { DraggableEditorContainer } from '../DraggableEditorContainer';
import { ConfigType, DraggableContainerProps, DndContextType } from '../utils/types';
import { DefaultPanelSize, DropBoxEnum, StackEnum } from '../utils/constants';
import { findTarget } from '../utils/helpers/findTarget';
import { DropBox } from '../DropBox';

import styles from './styles.module.scss';
import { DropPreview } from '../DropPreview';

export const DnDContext = createContext<DndContextType>({
  config: {
    type: '',
    key: '',
    isMainGrid: false,
    id: '',
    size: DefaultPanelSize.FULL_VIEW,
    content: [],
  },
  draggingLabel: {
    id: '',
    key: '',
    label: '',
    isCloseable: false,
  },
  dropMarker: { current: null },
  dropPreview: { current: null },
  dropZone: null,
  isDragging: false,
  isLoading: true,
  leftMouseBtnClicked: false,
  tabs: { activeTab: undefined, openedTabs: [] },
  targetStackRef: null,
  tabsZone: null,
  initialTabsZone: null,
  startTabIndex: 0,
  endTabIndex: 0,
  setDraggingLabel: () => undefined,
  setDropZone: () => undefined,
  setIsDragging: () => undefined,
  setConfig: () => undefined,
  setLeftMouseBtnClicked: () => undefined,
  setTabs: () => undefined,
  setTargetStackRef: () => undefined,
  setTabsZone: () => undefined,
  setInitialTabsZone: () => undefined,
  setStartTabtIndex: () => undefined,
  setEndTabIndex: () => undefined,
  selectedGridRow: undefined,
  blockDnd: false,
  setBlockDnd: () => undefined,
  managerRef: { current: null },
  generalType: '',
  saveLayout: () => Promise.resolve(undefined),
});

export const DraggableContainer: FC<DraggableContainerProps> = ({
  isLoading,
  type,
  selectedGridRow,
  showToolbar,
  withKey = true,
  tabs: passedTabs,
  showEditor: hideEditor,
  draggableGrid,
  onUpdate,
  refreshFlag,
  isDashboardType = false,
  isDefaultLayoutRestored = false,
  setIsDefaultLayoutRestored = () => undefined,
  entitiesLength,
  schemaTypeData,
  data,
  addLayoutToSettings,
  headers,
}) => {
  const { state } = useAppContext();

  const { action } = useTabItemContext();

  const isEditorMaximized = !!state.fullScreenLayerId;
  const maximizedEditorId = state.fullScreenLayerId;
  const isGridMaximized = useSelector(selectIsGridMaximized);
  const maximizedGridId = useSelector(selectGridMaximizedId);

  const [blockDnd, setBlockDnd] = useState(false);
  const [tabs, setTabs] = useState<EditorTabsState>({ activeTab: undefined, openedTabs: [] as GridTabDataType[] });
  const [leftMouseBtnClicked, setLeftMouseBtnClicked] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [tabsZone, setTabsZone] = useState<HTMLDivElement | null>(null);
  const [initialTabsZone, setInitialTabsZone] = useState<HTMLElement | null>(null);
  const [dropZone, setDropZone] = useState<HTMLDivElement | null>(null);
  const [targetStackRef, setTargetStackRef] = useState<ConfigType | GridTabDataType | null | undefined>(null);
  const [draggingLabel, setDraggingLabel] = useState<GridTabDataType | null>(null);
  const [generalType] = useState(type);

  const [startTabIndex, setStartTabtIndex] = useState(0);
  const [endTabIndex, setEndTabIndex] = useState(startTabIndex);

  const dropMarker = useRef<HTMLDivElement | null>(null);
  const dropPreview = useRef<HTMLDivElement | null>(null);
  const managerRef = useRef<HTMLDivElement | null>(null);

  const { showLoader, config, viewersRequest, fetchListPanelsData, setConfig, saveLayout } = useDnD({
    tabs,
    type,
    selectedGridRow,
    passedTabs,
    setTabs,
    draggableGrid,
    isDefaultLayoutRestored,
    setIsDefaultLayoutRestored,
    schemaTypeData,
    data,
    addLayoutToSettings,
    headers,
  });

  useLayoutEffect(() => {
    if (entitiesLength === 0) {
      return;
    }

    if ((withKey && type && selectedGridRow?.key) || (!withKey && type)) {
      fetchListPanelsData();
    }
  }, [selectedGridRow?.key, type, refreshFlag]);

  useEffect(() => {
    if (!isDragging) {
      dropMarker.current?.removeAttribute('style');
    }
  }, [isDragging]);

  useEffect(() => {
    setBlockDnd(isEditorMaximized || isGridMaximized);
  }, [isEditorMaximized, isGridMaximized]);

  useEffect(() => {
    resizePlotly();
  }, [isEditorMaximized]);

  useEffect(() => {
    action.updateLayoutConfig?.(config);
  }, [config]);

  useEffect(() => {
    action.updateSaveMethod?.(saveLayout);
  }, []);

  const isHide = useCallback(
    (el: ConfigType) =>
      (isEditorMaximized || isGridMaximized) && !findTarget(el, maximizedEditorId! || maximizedGridId!),
    [isEditorMaximized, isGridMaximized, maximizedEditorId, maximizedGridId]
  );

  const isSingleStackDragging = useCallback(
    (el: ConfigType) => {
      if (!isDragging || !draggingLabel) {
        return false;
      }

      if (el.content.length === 1 && findTarget(el, draggingLabel.id)) {
        return true;
      }

      return false;
    },
    [isDragging, draggingLabel, config]
  );

  const renderMainGridStack = (passedConfig: ConfigType) =>
    draggableGrid &&
    draggableGrid({
      tabs: passedConfig.content as GridTabDataType[],
      stackRef: passedConfig,
      type,
    });

  const renderStacks = useCallback(
    (passedConfig: ConfigType) => {
      if (!hideEditor) {
        const mainStack = findTarget(passedConfig, 'stack-main');
        return renderMainGridStack(mainStack || passedConfig);
      }

      if ((passedConfig.type as StackEnum) === StackEnum.STACK && !passedConfig.isMainGrid) {
        return (
          <DraggableEditorContainer
            isLoading={isLoading}
            type={type}
            selectedGridRow={selectedGridRow}
            showToolbar={showToolbar}
            stackRef={passedConfig}
            onUpdate={onUpdate}
            isSingleTabStackDragging={isSingleStackDragging(passedConfig)}
            configType={config.type}
            isDashboardType={isDashboardType}
          />
        );
      }

      if ((passedConfig.type as StackEnum) === StackEnum.STACK && passedConfig.isMainGrid && draggableGrid) {
        return renderMainGridStack(passedConfig);
      }

      if (passedConfig.type === 'row' || (passedConfig.type === 'column' && hideEditor)) {
        return (
          <div
            id={passedConfig.id}
            key={passedConfig.key}
            className={cx(passedConfig.type === 'row' ? 'row-el' : 'column-el flex-column w-100', 'd-flex w-100 h-100')}
          >
            <PanelGroup direction={passedConfig.type === 'row' ? 'horizontal' : 'vertical'}>
              {passedConfig.content.map((el, index, { length }) => (
                <Fragment key={index}>
                  <Panel
                    minSize={0}
                    maxSize={100}
                    collapsible
                    className={cx(
                      { 'd-none': isHide(el as ConfigType) },
                      { [styles.singleDragging]: isSingleStackDragging(el as ConfigType) }
                    )}
                    onResize={resizePlotly}
                  >
                    {renderStacks(el as ConfigType)}
                  </Panel>
                  {index < length - 1 && (
                    <PanelResizeHandle
                      className={cx(
                        passedConfig.type === 'row' ? 'verticalPanelResizer' : 'horizontalPanelResizer my-1_5',
                        {
                          'd-none': isEditorMaximized,
                        }
                      )}
                    />
                  )}
                </Fragment>
              ))}
            </PanelGroup>
          </div>
        );
      }

      return undefined;
    },
    [draggableGrid, selectedGridRow, config, isDragging, showLoader]
  );

  return (
    <DnDContext.Provider
      value={{
        startTabIndex,
        endTabIndex,
        config,
        draggingLabel,
        dropMarker,
        dropPreview,
        dropZone,
        isDragging,
        isLoading: viewersRequest.isLoading,
        leftMouseBtnClicked,
        tabs,
        targetStackRef,
        tabsZone,
        setIsDragging,
        setDraggingLabel,
        setDropZone,
        setConfig,
        setLeftMouseBtnClicked,
        setTabs,
        setTargetStackRef,
        setTabsZone,
        setStartTabtIndex,
        setEndTabIndex,
        selectedGridRow,
        blockDnd,
        setBlockDnd,
        managerRef,
        initialTabsZone,
        setInitialTabsZone,
        generalType,
        saveLayout,
      }}
    >
      <div ref={managerRef} className="manager h-100">
        <DropBox position={DropBoxEnum.GLOBAL} stackRef={config} />
        <div className="editor-container d-flex h-100 position-relative">{renderStacks(config)}</div>
        <div ref={dropPreview} className={cx('preview d-none', styles.previewWindow)}>
          <DropPreview
            showToolbar
            activeTab={draggingLabel?.id}
            draggingTab={draggingLabel}
            selectedGridRow={selectedGridRow}
            type={type}
          />
        </div>
        <div ref={dropMarker} className={cx(styles.dashed, 'position-fixed border border-secondary pe-none d-none')} />
      </div>
    </DnDContext.Provider>
  );
};
