import { SyntheticEvent, useEffect, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { useMergeRefs } from '@floating-ui/react';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import './UIGridVerticalScroll.styles.scss';

type UIGridVerticalScrollProps = {
  className?: string;
};

export const UIGridVerticalScroll = <TData,>({ className }: UIGridVerticalScrollProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { ref: resizeRef, height = 0 } = useResizeObserver();
  const mergedRefs = useMergeRefs([resizeRef, ref]);
  const { virtualizer, isHiddenVerticalScroll, setHiddenVerticalScroll } = useGrid<TData>();
  const totalHeight = virtualizer.getTotalSize();

  const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    const offsetTop = event.currentTarget.scrollTop;
    if (offsetTop === virtualizer.scrollOffset) return;
    virtualizer.scrollToOffset(offsetTop);
  };

  useEffect(() => {
    setHiddenVerticalScroll(totalHeight <= height);
  }, [totalHeight, height]);

  useEffect(() => {
    if (!ref.current) return;

    const currentScrollTop = ref.current.scrollTop;
    if (currentScrollTop === virtualizer.scrollOffset) return;
    ref.current.scrollTop = virtualizer.scrollOffset;
  }, [virtualizer.scrollOffset]);

  return (
    <div
      ref={mergedRefs}
      className={cx('ui-grid-vertical-scroll', className, {
        'ui-grid-vertical-scroll--hidden': isHiddenVerticalScroll,
      })}
      onScroll={onScroll}
    >
      <div style={{ height: totalHeight }} />
    </div>
  );
};
