import { FC } from 'react';
import { Dayjs } from 'dayjs';
import cx from 'classnames';

import { UIInputTime } from 'ui';
import { DateFormatEnum, DateFormatUtility } from 'utils';

import styles from './QueryBuilderTimeInput.module.scss';

type QueryBuilderTimeInputProps = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const QueryBuilderTimeInput: FC<QueryBuilderTimeInputProps> = ({ value, onChange }) => {
  const time = value ? DateFormatUtility.create(value, DateFormatEnum.TIME).toInstance() : null;

  const onChangeHandler = (changedTime: Dayjs | null) => {
    onChange(changedTime?.isValid ? changedTime.format(DateFormatEnum.TIME_MS) : changedTime?.toString() ?? null);
  };

  return <UIInputTime className={cx(styles.dropdown)} value={time} onChange={onChangeHandler} />;
};
