import { FC } from 'react';
import cx from 'classnames';

import { APP_ICONS } from 'utils/icons';
import './EditorBreadcrumbItem.scss';
import { useValidationError } from 'view/Editor/validation/hook/validationError.hook';
import { EditorErrorTooltip } from 'view/Editor/validation/component/EditorErrorTooltip';

const IconClose = APP_ICONS.close;

type EditorBreadcrumbItemProps = {
  label: string;
  path: string[];
  isCloseable: boolean;
  isActive?: boolean;
  onClose: () => void;
  onClick: () => void;
};

export const EditorBreadcrumbItem: FC<EditorBreadcrumbItemProps> = ({
  isActive,
  path,
  isCloseable,
  label,
  onClose,
  onClick,
}) => {
  const { nestedErrorList } = useValidationError({ path });
  const isErrorExist = nestedErrorList.length > 0;

  const classNames = cx(
    'editor-breadcrumb-item',
    { 'editor-breadcrumb-item--active': isActive },
    { 'editor-breadcrumb-item--error': isErrorExist && !isActive }
  );

  return (
    <EditorErrorTooltip errors={nestedErrorList}>
      <div className={classNames} onClick={onClick}>
        {label}
        {isCloseable && (
          <div
            className="editor-breadcrumb-item__close"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <IconClose />
          </div>
        )}
      </div>
    </EditorErrorTooltip>
  );
};
