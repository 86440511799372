import { QueryOperatorEnum, SchemaTypeElementsModel } from 'api';

import { ValueWithConditionType } from '../types';
import { generateOptionsBySchemaType } from './generateOptionsBySchemaType.helper';
import { generateValueBySchemaType } from './generateValueBySchemaType.helper';
import { getTypeBySchema } from './getTypeBySchema.helper';

export const generateValueWithConditionBySchema = (
  schema: SchemaTypeElementsModel | undefined
): ValueWithConditionType => {
  const schemaType = getTypeBySchema(schema);
  const conditions = generateOptionsBySchemaType(schemaType);
  const condition = conditions.at(0)?.value ?? QueryOperatorEnum.Contains;
  const value = generateValueBySchemaType(schemaType);
  return { value, condition };
};
