import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { APP_ICONS } from 'utils/icons';
import { MaximizeButton } from 'shared/MaximizeButton';
import {
  ChatType,
  CloExclusionsStateType,
  SessionType,
} from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { LetterButton } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/History/components/Toolbar/components/LetterButton';
import { CloExclusionsButton } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/History/components/Toolbar/components/CloExclusionsButton';

import { PromptLabel } from './components/PromptLabel';
import { ActiveLlmLabel } from './components/ActiveLlmLabel';
import { ChatHistory } from './components/ChatHistory';
import { ChatButton } from './components/ChatButton';

import styles from './styles.module.scss';

type ToolbarProps = {
  cloExclusionsState: CloExclusionsStateType;
  isShowMaximized: boolean;
  isMaximized: boolean;
  isTopic: boolean;
  activeSession?: SessionType;
  isLoading: boolean;
  sessions: SessionType[];
  selectSession: (session: SessionType) => void;
  deleteSelectedSessions: (sessions: SessionType[]) => void;
  startNewChat: (chatType: ChatType, isProfile?: boolean) => void;
  toggleMaximizedView: () => void;
  sendCloExclusions: () => void;
  openMailClient: () => void;
};

export const Toolbar: FC<ToolbarProps> = memo(
  ({
    cloExclusionsState,
    isShowMaximized,
    isMaximized,
    isTopic,
    isLoading,
    activeSession,
    sessions,
    startNewChat,
    selectSession,
    deleteSelectedSessions,
    toggleMaximizedView,
    sendCloExclusions,
    openMailClient,
  }) => {
    const { t } = useTranslation();

    // const handleOpenUserProfileTab = () => {
    //   dispatch(openTab({ id: 'UserProfile', key: 'UserProfile', label: 'User Profiles' }));
    //   setActiveTab('UserProfile');
    // };

    return (
      <div className={cx('d-flex align-items-center gap-2_5 w-100 py-6px px-8px', styles.container, styles.top)}>
        {isTopic && (
          <>
            <ChatButton
              tooltip={t('chat.newTooltipTypes.internal')}
              icon={APP_ICONS.chat}
              disabled={isLoading}
              onClick={() => startNewChat(ChatType.INTERNAL, true)}
            >
              {t('chat.newTypes.internal')}
            </ChatButton>
            {/* ToDo: Temporary hide buttons */}
            {/* <ChatButton */}
            {/*  tooltip={t('chat.newTooltipTypes.userProfiles')} */}
            {/*  icon={APP_ICONS.userSettings} */}
            {/*  disabled={isLoading} */}
            {/*  onClick={handleOpenUserProfileTab} */}
            {/* > */}
            {/*  {t('chat.newTypes.userProfiles')} */}
            {/* </ChatButton> */}
            {/* <ChatButton */}
            {/*  tooltip={t('chat.newTooltipTypes.aiSearchProfiles')} */}
            {/*  icon={APP_ICONS.userSettings} */}
            {/*  disabled={isLoading} */}
            {/*  onClick={() => */}
            {/*    dispatch(openTab({ id: 'AISearchProfile', key: 'AISearchProfile', label: 'AI Search Profiles' })) */}
            {/*  } */}
            {/* > */}
            {/*  {t('chat.newTypes.aiSearchProfiles')} */}
            {/* </ChatButton> */}
            {activeSession?.Profile && <PromptLabel prompt={activeSession.Profile} />}
            {activeSession?.LlmToUse && <ActiveLlmLabel llm={activeSession.LlmToUse} />}
            <div className="d-flex gap-2 flex-grow-1 justify-content-end">
              {isShowMaximized && <MaximizeButton isMaximizedView={isMaximized} onCLick={toggleMaximizedView} />}
              <LetterButton isDisabled={isLoading} openMailClient={openMailClient} />
              <CloExclusionsButton
                cloExclusionsState={cloExclusionsState}
                isLoading={isLoading}
                onClick={sendCloExclusions}
              />
              <ChatHistory
                activeSession={activeSession}
                isLoading={isLoading}
                sessions={sessions}
                selectSession={selectSession}
                deleteSelectedSessions={deleteSelectedSessions}
              />
            </div>
          </>
        )}
      </div>
    );
  }
);

Toolbar.displayName = 'Toolbar';
