import { FC } from 'react';
import { Modal } from 'reactstrap';
import cx from 'classnames';

import { UiModalContent, UiModalContentProps } from 'ui/UiModal/UiModalContent';
import './UiModal.scss';

export enum ModalSize {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

const getModalSize = (size: keyof typeof ModalSize) =>
  ({
    [ModalSize.sm]: 300,
    [ModalSize.md]: 600,
    [ModalSize.lg]: 800,
    [ModalSize.xl]: 1140,
  })[size];

export type UiModalProps = UiModalContentProps & {
  isOpen?: boolean;
  className?: string;
  size?: keyof typeof ModalSize;
  backdrop?: boolean;
};

export const UiModal: FC<UiModalProps> = ({
  isOpen,
  size = 'md',
  title,
  className,
  isClosable = true,
  minWidthConstraints = 300,
  minHeightConstraints = 300,
  defaultHeight = 250,
  defaultWidth,
  isDraggable = true,
  children,
  onClose,
  backdrop = false,
}) => {
  const classes = cx('ui-modal', className);
  const currentDefaultWidth = defaultWidth || getModalSize(size);

  return (
    <Modal size={size} isOpen={isOpen} fade={false} backdrop={backdrop} className={classes} unmountOnClose>
      <UiModalContent
        minWidthConstraints={minWidthConstraints}
        minHeightConstraints={minHeightConstraints}
        defaultWidth={currentDefaultWidth}
        defaultHeight={defaultHeight}
        onClose={onClose}
        title={title}
        isDraggable={isDraggable}
        isClosable={isClosable}
      >
        {children}
      </UiModalContent>
    </Modal>
  );
};
