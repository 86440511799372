import { HandlerGetParamsRequestDto } from 'api';
import { SchemaTypeHierarchyResponseDto } from 'api/data/schema.data';
import { SchemaTypeDataModel, SchemaTypeHierarchyModel, SchemaTypeModel } from 'api/model/schema.model';
import { LambdaConverter } from 'api/type';

export const convertSchemaTypeHierarchyDtoToModel: LambdaConverter<
  SchemaTypeHierarchyResponseDto,
  SchemaTypeHierarchyModel[]
> = (dto) =>
  dto
    .flat()
    .filter(
      (successor, index, self) =>
        index === self.findIndex((s) => s.Name === successor.Name && s.Name === successor.Name)
    );

export const convertSchemaTypeDtoToModel = (
  dto: Record<string, SchemaTypeModel>,
  type: string
): SchemaTypeDataModel => {
  const rootType = Object.keys(dto)?.find((name) => name.search(type) !== -1) || '';

  return {
    rootType,
    typeList: dto,
  };
};

export const convertHandlerDtoToParams = ({
  dataSource,
  traceId,
  args,
  panelId,
  ...rest
}: HandlerGetParamsRequestDto) => ({
  arguments: args,
  traceid: traceId,
  data_source: dataSource,
  panel_id: panelId,
  ...rest,
});

export const convertRunStatusDtoToParams = ({ dataSource, dataset, taskRunIds }: HandlerGetParamsRequestDto) => ({
  data_source: dataSource,
  dataset,
  task_run_ids: taskRunIds,
});

export const convertCancelAllHandlersDtoToParams = ({ dataSource, dataset }: HandlerGetParamsRequestDto) => ({
  data_source: dataSource,
  dataset,
});

export const convertRunResultDtoToParams = ({ dataSource, dataset, taskRunIds }: HandlerGetParamsRequestDto) => ({
  data_source: dataSource,
  dataset,
  task_run_ids: taskRunIds,
});
