/* eslint-disable  @typescript-eslint/no-explicit-any */
export type EventManagerHandler<T = any> = (args?: T) => void;

export interface EventManager {
  once<T = unknown>(eventName: string, handler: EventManagerHandler<T>): EventManager;
  subscribe<T = unknown>(eventName: string, handler: EventManagerHandler<T>): EventManager;
  unsubscribe<T = unknown>(eventName: string, handler?: EventManagerHandler<T>): EventManager;
  emit<T = unknown>(eventName: string, args?: T): EventManager;
}

export function createEventManager(): EventManager {
  const eventList = new Map<string, Set<EventManagerHandler>>();

  return {
    subscribe<T = unknown>(eventName: string, handler: EventManagerHandler<T>) {
      if (eventList.has(eventName)) {
        eventList.get(eventName)?.add(handler);
      } else {
        eventList.set(eventName, new Set([handler]));
      }

      return this;
    },
    once<T = unknown>(eventName: string, handler: EventManagerHandler<T>) {
      const onceHandler: EventManagerHandler<T> = (args?: T) => {
        this.unsubscribe(eventName, onceHandler);
        handler(args);
      };

      this.subscribe(eventName, onceHandler);

      return this;
    },
    unsubscribe<T = unknown>(eventName: string, handler: EventManagerHandler<T>) {
      if (eventList.has(eventName)) {
        eventList.get(eventName)!.delete(handler);
      }

      return this;
    },
    emit<T = unknown>(eventName: string, args?: T) {
      if (eventList.has(eventName)) {
        eventList.get(eventName)?.forEach((handler) => handler(args));
      }

      return this;
    },
  };
}

export const eventManager = createEventManager();
