import { FC, Fragment } from 'react';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';

import { isArray, resizePlotly } from 'utils';
import { Viewers } from 'view/Viewers';
import { EntityPanelViewOfModel } from 'api';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

type CompositeViewProps = {
  ColsCount?: number;
  RowsCount?: number;
  Views?: EntityPanelViewOfModel[];
  onTransition?: (data: TransitionRecordType) => void;
};

export const CompositeView: FC<CompositeViewProps> = ({ ColsCount, RowsCount, Views, onTransition }) => {
  if (!ColsCount || !RowsCount || !Views?.length) return null;

  const viewsArr =
    ColsCount > 1
      ? Array.from({ length: RowsCount }, (_, rowIndex) =>
          Views.slice(rowIndex * ColsCount, (rowIndex + 1) * ColsCount)
        )
      : Views;

  return (
    <PanelGroup direction="vertical">
      {viewsArr.map((row, rowIndex) => (
        <Fragment key={rowIndex}>
          <Panel onResize={resizePlotly}>
            {isArray(row) && row.length > 0 ? (
              <PanelGroup direction="horizontal">
                {row.map((viewer, viewerIndex) => (
                  <Fragment key={viewerIndex}>
                    <Panel onResize={resizePlotly}>
                      <Viewers activeViewerType={viewer._t} data={viewer} onTransition={onTransition} />
                    </Panel>
                    {viewerIndex !== ColsCount - 1 && <PanelResizeHandle className="verticalPanelResizer" />}
                  </Fragment>
                ))}
              </PanelGroup>
            ) : (
              <Viewers activeViewerType={Views[rowIndex]._t} data={Views[rowIndex]} onTransition={onTransition} />
            )}
          </Panel>
          {rowIndex !== RowsCount - 1 && <PanelResizeHandle className="horizontalPanelResizer" />}
        </Fragment>
      ))}
    </PanelGroup>
  );
};
