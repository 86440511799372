import { FilterValue, Row } from 'react-table';

import { SimpleViewModel } from 'api';
import { ExcelCellValue } from 'services/excel';

export enum GridModeEnum {
  DEFAULT = 'DEFAULT',
  MAIN = 'MAIN',
  EDITOR = 'EDITOR',
  READ_ONLY = 'READ ONLY',
}

export type DataType = Record<string, SimpleViewModel>;

export type ExcelGridOptions = {
  sheetName: string;
  additionalCells?: { name: string; value: ExcelCellValue }[];
  selectedRowKeys: string[];
};

export enum GridColumWidth {
  MIN_WIDTH = 100,
  DEFAULT_WIDTH = 150,
  MAX_WIDTH = 400,
}

export enum BoolEnum {
  YES = 'Y',
  NO = 'N',
}

export enum ColumnNameType {
  FILTER = 'filter',
}

export type ExportGridOptionsType = {
  toExcel(options: ExcelGridOptions): void;
  toCSV(): void;
};

export type SelectedRowIdType = { _key: SimpleViewModel; _t: SimpleViewModel; allCells: string[] };

export type SelectedRowsIdsType = { [key: string]: SelectedRowIdType };

export type SelectedRangeCellsType = Record<string, boolean>;

export type SelectedRowsRangeType = Record<string, { _key: SimpleViewModel; _t?: SimpleViewModel }>;

export type GridRef = {
  exportGridOptions: ExportGridOptionsType;
};

export type GetRowIdType = (row: DataType, relativeIndex: number, parent?: Row<DataType>) => string;

export type CoordinateType = { left?: number; top?: number };

export type GridFiltersType = {
  id: string;
  value: FilterValue;
};
