import cx from 'classnames';

import { useGrid } from '../../hooks';
import './UIGridCorner.style.scss';

type UIGridCornerProps = {
  className?: string;
  direction: 'horizontal' | 'vertical';
};

export const UIGridCorner = <TData,>({ direction, className }: UIGridCornerProps) => {
  const { isHiddenVerticalScroll } = useGrid<TData>();
  const isVerticalDirection = direction === 'vertical';
  const directionClassName = isVerticalDirection ? 'ui-grid-corner--vertical' : 'ui-grid-corner--horizontal';

  if (isHiddenVerticalScroll) return null;

  return <div className={cx('ui-grid-corner', directionClassName, className)} />;
};
