import { PropsWithChildren } from 'react';

type UIGridWrapperProps = PropsWithChildren<{
  className?: string;
}>;

export const UIGridWrapper = ({ className, children }: UIGridWrapperProps) => (
  // FIXME: Adding total size breaks the parent width of the grid. Right now it's 100%.
  <div className={className} /* style={{ width: api.getTotalSize() }} */>{children}</div>
);
