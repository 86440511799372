import { FC } from 'react';

type LoaderProps = {
  message?: string;
  isLoading?: boolean;
};

export const Loader: FC<LoaderProps> = ({ message = 'Loading ...', isLoading = true }) =>
  isLoading ? (
    <div className="position-relative h-100 w-100 d-flex" data-testid="loader">
      <div className="m-auto p-2 text-center">
        <div className="text-text2 fs-20 fw-bolder">{message}</div>
      </div>
    </div>
  ) : null;
