import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { Grid } from 'view/Grid';
import { isArray, toast } from 'utils';
import { mapMetadataToGridColumnsHelper } from 'view/Grid/utils/helpers';
import { useRequest } from 'hook/request.hook';
import { UiModal, UIButton } from 'ui';
import { DataType } from 'view/Grid/utils/types';
import { KeySelectToolbar } from 'view/Editor/components/TableBody/TableBodyCell/KeySelect/KeySelectToolbar';
import './KeySelectModal.scss';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

// Fixed values needed cause currently we don't have pagination for this table
const LIMIT = 1000;
const SKIP = 0;

type KeySelectModalProps = {
  isOpen: boolean;
  defaultKey: string;
  schemaName: string;
  schemaModuleName: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

export const KeySelectModal: FC<KeySelectModalProps> = ({
  schemaName,
  schemaModuleName,
  defaultKey,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { request } = useEditorState();
  const [selectedKey, setSelectedKey] = useState(defaultKey);
  const { state } = useRequest(request.getSelectRecords, {
    autorun: true,
    args: { params: { type: schemaName, module: schemaModuleName, skip: SKIP, limit: LIMIT, table_format: true } },
    onError: toast.error,
  });

  const columns = useMemo(() => {
    const schema = state?.schema.typeList[state.schema.rootType];

    return schema && isArray(schema?.Elements) ? mapMetadataToGridColumnsHelper(schema.Elements) : [];
  }, [state]);

  const selectedData = useMemo(() => {
    const tempFields = ['_key', '_t'];
    const recordData = structuredClone(state)?.data.find((it) => it._key === selectedKey);
    if (recordData) {
      tempFields.forEach((field) => delete recordData[field]);
    }
    return recordData;
  }, [selectedKey, state]);

  const handleSubmitKey = () => {
    onSubmit(selectedKey);
  };

  const handleRowDoubleClick = (row: Row<DataType>) => onSubmit(row.id);

  return (
    <UiModal isOpen={isOpen} defaultHeight={400} title={t('editor.modalHeader') || ''} onClose={onClose}>
      <UiModal.Body className="key-select-modal__body">
        <div className="key-select-modal__toolbar">
          <KeySelectToolbar schemaName={schemaName} data={selectedData} onSubmit={onSubmit} />
        </div>
        <Grid
          gridId="keySelectModal"
          variant="secondary"
          data={state?.data || []}
          columns={columns || []}
          isColumnFilter={false}
          selectedRowId={selectedKey}
          onChangeSelectedRowId={setSelectedKey}
          getRowId={(row) => row._key as string}
          onRowDoubleClicked={handleRowDoubleClick}
        />
      </UiModal.Body>
      <UiModal.Footer>
        <UIButton variant="secondary" onClick={onClose}>
          {t('buttons.cancel')}
        </UIButton>
        <UIButton onClick={handleSubmitKey}>{t('buttons.apply')}</UIButton>
      </UiModal.Footer>
    </UiModal>
  );
};
