import { memo } from 'react';
import { Translation } from 'react-i18next';

export const Disclaimer = memo(() => (
  <Translation>
    {(t) => (
      <div className="p-2 pb-3 text-text4">
        <p className="m-0 fs-14 lh-16 fw-semibold mb-2">{t('chat.disclaimer.paragraph')}</p>
        <ul className="m-0 fs-13">
          <li>{t('chat.disclaimer.list.0')}</li>
          <li>{t('chat.disclaimer.list.1')}</li>
          <li>{t('chat.disclaimer.list.2')}</li>
          <li>{t('chat.disclaimer.list.3')}</li>
          <li>{t('chat.disclaimer.list.4')}</li>
        </ul>
      </div>
    )}
  </Translation>
));

Disclaimer.displayName = 'Disclaimer';
