import { Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { Navigate } from 'react-router-dom';

import { selectIsAuthenticated } from 'store/selectors/AppState.selector';
import { RouterEnum } from 'router';

type SecureRouteProps = PropsWithChildren;

export const SecureRoute = ({ children }: SecureRouteProps) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Fragment>{children}</Fragment>;
  }

  return <Navigate to={RouterEnum.LOGIN} />;
};
