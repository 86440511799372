import { FlatViewModel, SchemaTypeListModel, SchemaTypesItemModel, StorageEntityModel, ValueTypeModel } from 'api';
import { ValueType } from 'store/types';
import { CellSchemaType } from 'view/Editor/helpers/getCellProps.helper';
import { DictionaryViewType } from 'view/Editor/components/EditorView/DictionaryView/DictionaryViewValueCell.component';
import { ValidationResultType } from 'view/Editor/validation/type';

// STATE TYPE
export type EditorTabType = {
  title: string;
  key: string;
  index?: number;
  path: string[];
  childIndex?: number;
  valueType?: ValueType['Type'];
  moduleName?: string;
  keyName?: string;
  keyModuleName?: string;
  schemaPath?: string;
  isVector?: boolean;
  isFlat?: boolean;
  schemaType?: CellSchemaType;
  // TODO when connect GenericTypes schema probably remove it
  selector: string;
  tabEditorValueType?: ValueTypeModel;
  isReadOnly?: boolean;
};

export type EditorBreadcrumbsType = {
  tabs: EditorTabType[];
  activeTabIndex: number;
};

export type AdditionalSchemaType = {
  type: string;
};

export type UpdateFlatWithTypeDataType = {
  type: string;
  nextTabIndex?: number;
};

export type FlatWithType = FlatViewModel & {
  _t: string;
};

export type EditorValidationErrorType = Record<string, ValidationResultType>;

export type EditorContextState = {
  isReadOnly: boolean;
  isDisableKeyFields: boolean;
  rootSchema: string;
  editorSchema: SchemaTypeListModel | null;
  editorData: StorageEntityModel;
  originalEditorData: StorageEntityModel;
  breadcrumbsData: EditorBreadcrumbsType;
  allTypes: SchemaTypesItemModel[] | null;
  editorToolbarRef: HTMLDivElement | null;
  validationError: EditorValidationErrorType;
};

// FETCH
export type EditorContextFetch = {
  getAdditionalSchema: (params: AdditionalSchemaType) => Promise<SchemaTypeListModel | null>;
  getFlatWithTypeDataFetch: (params: UpdateFlatWithTypeDataType) => Promise<FlatWithType | null>;
};

// ACTION

export type UpdateEditorDataProps = {
  path: string[];
  updateValue: unknown;
};

export type AddGroupListElementActionProps = {
  path: string[];
  headers: string[];
  rowCount: number;
};

export type RemoveGroupListElementActionProps = {
  path: string[];
  headers: string[];
  rowCount: number;
};

export type AddListElementActionProps = {
  path: string[];
  selector?: string;
  index?: number;
};

export enum RemoveListDataElementType {
  ByIndex = 'ByIndex',
  AllExceptIndex = 'AllExceptIndex',
}

export type RemoveListElementActionProps = {
  path: string[];
  index: number[];
  removeType?: RemoveListDataElementType;
};

export type MoveListElementActionProps = {
  path: string[];
  from: number;
  to: number;
};

export type AddDictElementActionProps = {
  path: string[];
  type: DictionaryViewType;
  index?: number;
};

export type EditorInitDataType = {
  data?: StorageEntityModel;
  schema: SchemaTypeListModel;
  rootSchemaName: string;
};

export type EditorInitDataResultType = {
  data: StorageEntityModel;
  schema: SchemaTypeListModel;
  rootSchemaName: string;
};

export type EditorContextAction = {
  initEditor: (data: EditorInitDataType) => EditorInitDataResultType;
  setEditorToolbarRef: (ref: HTMLDivElement) => void;
  findSchemaByEndWith: (value: string) => string | null;
  setEditorDataAction: (data: StorageEntityModel) => void;
  updateEditorData: (payload: UpdateEditorDataProps) => void;
  switchTabAction: (index: number) => void;
  closeTabAction: (index: number) => void;
  openEditorTabAction: (payload: EditorTabType, prevIndex: number) => void;
  addGroupListElementAction: (payload: AddGroupListElementActionProps) => void;
  removeGroupListElementAction: (payload: RemoveGroupListElementActionProps) => void;
  addListElementAction: (payload: AddListElementActionProps) => void;
  removeListElementAction: (payload: RemoveListElementActionProps) => void;
  addDictElementAction: (payload: AddDictElementActionProps) => void;
  moveListElementAction: (payload: MoveListElementActionProps) => boolean;
  setAllTypesAction: (types: SchemaTypesItemModel[]) => void;
  validateEditor: () => boolean;
};
