import { Cell } from 'react-table';

import { GridsType, SavedColumnType } from 'containers/TabItemContent/utils/types';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';

export const useSavedGridContext = (gridId: string) => {
  const { action } = useTabItemContext();

  const findSavedGridColumn = (passedGrids: GridsType, passedCell: Cell) =>
    passedGrids[gridId]?.find((el) => Object.keys(el)[0] === passedCell.column.id);

  const saveGridColumn = (passedCell: Cell) => {
    action.setGrids((prev) => {
      const prevCopy: GridsType = JSON.parse(JSON.stringify(prev));
      const savedColumn = { [passedCell.column.id]: passedCell.column.width } as SavedColumnType;

      if (prevCopy[gridId] && !findSavedGridColumn(prevCopy, passedCell)) {
        prevCopy[gridId] = [...prevCopy[gridId], savedColumn];
      } else {
        prevCopy[gridId] = [savedColumn];
      }

      return prevCopy;
    });
  };

  return { findSavedGridColumn, saveGridColumn };
};
