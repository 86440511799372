import { HandlerType, SchemaTypeDeclareHandlersModel } from 'api';
import { ContentHandlersModel } from 'view/Handlers';

export const contentHandlersConverter = {
  toDomain: (handlers?: SchemaTypeDeclareHandlersModel[] | null): ContentHandlersModel => {
    const contentHandlers = handlers?.filter((handler) => handler.Type === HandlerType.CONTENT) || [];

    const isExist = contentHandlers.length > 0;
    const isMultiChoice = contentHandlers.length > 1;

    return { handlers: contentHandlers, isExist, isMultiChoice };
  },
};
