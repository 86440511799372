import './EditorErrorTooltip.scss';
import { FC, ReactElement } from 'react';

import { UITooltip } from 'ui';

export type EditorBreadcrumbItemProps = {
  errors: string[];
  errorLimit?: number;
  children: ReactElement<unknown, string>;
};

export const EditorErrorTooltip: FC<EditorBreadcrumbItemProps> = ({ errors, errorLimit = 3, children }) => {
  const renderErrorList = () => (
    <div className="editor-error-tooltip">
      {errors?.slice(0, errorLimit).map((error, index) => (
        <div className="editor-error-tooltip__error-item" key={index}>
          {error}
        </div>
      ))}
    </div>
  );

  return (
    <UITooltip isDisable={!errors.length} title={renderErrorList()}>
      <span>{children}</span>
    </UITooltip>
  );
};
