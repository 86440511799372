import cx from 'classnames';
import { useSelector } from 'react-redux';
import { FC, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import Logo from 'assets/logos/cl-logo.svg';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import {
  handlerApi,
  HandlerGetParamsRequestDto,
  LoadingStatusesEnum,
  RunHandlerStatusCodeEnum,
  StorageEntityModel,
} from 'api';
import { selectToolbarData } from 'store/selectors/typeToolbar.selector';
import { SelectedRowsRangeType } from 'view/Grid/utils/types';
import { DateFormatEnum, DateFormatUtility, isDefined, isNullable, ToastTypeEnum } from 'utils';
import { progressMessagesDataMock } from 'mock/logs-list';
import { useAppDispatch } from 'store/hooks';
import { updateProgressMessagesData } from 'store/shared-reducers/LogsDataSlice';
import {
  clearHandlerState,
  setAllCanceledHandlerIds,
  setAllHandlerTasksStatus,
  setCanceledHandlerIds,
  setRecordStatuses,
  setRunHandlersData,
  setTaskHandlerStatus,
} from 'store/shared-reducers/ToolbarHandlerSlice';
import { selectCanceledHandlerIds, selectHandlerData } from 'store/selectors/toolbarHandler.selector';
import { env } from 'env';
import { getTimeoutDelay } from 'utils/helpers/timeoutDelay';
import { selectIsReadonly } from 'store/selectors/AppState.selector';
import { UiModal } from 'ui';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { useSSEContext } from 'containers/SSEProvider';
import { useAppContext } from 'context';
import { showHandlerToast } from 'utils/helpers/showHandlerToast';
import { useComponentsMapper } from 'shared/Hooks/useComponentsMapper';

import { CommonButton } from './components/CommonButton';
import { HandlerWithParamsModal } from './components/HandlerWithParamsModal';
import { HandlersContainer } from './components/HandlersContainer';
import {
  ExecutedHandlerData,
  ExecutedHandlerInfoByRecord,
  ExecutedHandlerRunTaskDataType,
  ExecutedHandlerStatusData,
  HandlerContentTypeEnum,
  HandlerItemType,
} from './types';
import { getRunHandlersBySchema } from './helpers/getRunHandlersBySchema.helper';
import { downloadDecodedData, downloadJsonObject, getRunHandlerKey } from './helpers';

export type TypeToolbarProps = {
  selectedRowType?: string;
  selectedRowKey?: string;
  selectedRangeRows?: SelectedRowsRangeType;
  containerClassname?: string;
};

type ExecutedHandlersType = ExecutedHandlerStatusData & {
  recordKey: string;
  handler: HandlerItemType;
};

type NewRunHandlersType = Record<string, { key: string; statusCode: string; executableHandler: HandlerItemType }>;
type HandlerRecordKeysType = Record<string, Record<string, string>>;

export const TypeToolbaInner: FC<TypeToolbarProps> = ({
  selectedRowType,
  selectedRowKey,
  selectedRangeRows,
  containerClassname,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { taskProgress } = useSSEContext();

  const { state: appState } = useAppContext();
  const { state, action } = useTabItemContext();
  const isAppStateReadOnly = useSelector(selectIsReadonly);
  const isFinalReadOnly = isAppStateReadOnly || state.isTypeReadOnly;

  const { data: schema } = useSelector(selectGridSchema);
  const { isLogsOpened } = useSelector(selectToolbarData);
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);
  const runningHandlerData = useSelector(selectHandlerData);
  const canceledHandlerIds = useSelector(selectCanceledHandlerIds);

  const [handlers, setHandlers] = useState<HandlerItemType[]>();
  const [executedHandlersInfoByRecord, setExecutedHandlersInfoByRecord] = useState<ExecutedHandlerInfoByRecord>({});
  const [selectedHandlerWithParams, setSelectedHandlerWithParams] = useState<HandlerItemType>();
  const [executedHandlers, setExecutedHandlers] = useState<ExecutedHandlersType[]>([]);

  const [newRunHandlers, setNewRunHandlers] = useState<NewRunHandlersType>({});
  const [handlerRecordKeys, setHandlerRecordKeys] = useState<HandlerRecordKeysType>({});

  const activeTab = schema.Name;
  const isRunHandlers = Boolean(handlers?.length);
  const isManyRowsSelected = Object.keys(selectedRangeRows || {})?.length > 1;

  const getHandlerStatus = (statusCode: string) => {
    switch (statusCode) {
      case RunHandlerStatusCodeEnum.Completed:
        return LoadingStatusesEnum.SUCCESS;
      case RunHandlerStatusCodeEnum.Failed:
      case RunHandlerStatusCodeEnum.Cancelled:
        return LoadingStatusesEnum.ERROR;
      default:
        return LoadingStatusesEnum.LOADING;
    }
  };

  useEffect(() => {
    setHandlers(getRunHandlersBySchema(schema, selectedRowKey || '', state.pinnedHandlers));
  }, [selectedRowKey, state.pinnedHandlers]);

  useEffect(() => {
    if (taskProgress && newRunHandlers[taskProgress?.TaskRunId]) {
      const { TaskRunId, Key, StatusCode } = taskProgress;
      const runHandler = newRunHandlers[TaskRunId].executableHandler;

      const currentExecutedHandlerData: ExecutedHandlerInfoByRecord = {
        ...executedHandlersInfoByRecord,
        [getRunHandlerKey(runHandler.id, runHandler.isStatic, Key)]: {
          data: {} as ExecutedHandlerData,
          status: getHandlerStatus(StatusCode),
          error: '',
        },
      };

      dispatch(setRecordStatuses([{ Key, StatusCode, TaskRunId }]));

      setNewRunHandlers({
        ...newRunHandlers,
        [TaskRunId]: { ...newRunHandlers[TaskRunId], statusCode: StatusCode },
      });
      setHandlerRecordKeys({
        ...handlerRecordKeys,
        [runHandler.id]: { ...handlerRecordKeys[runHandler.id], [Key!]: StatusCode },
      });
      setExecutedHandlersInfoByRecord(currentExecutedHandlerData);

      const toastMessage = Key ? `${Key} / ${runHandler.name}` : runHandler.name;

      if (StatusCode === RunHandlerStatusCodeEnum.Failed) {
        showHandlerToast(ToastTypeEnum.ERROR, toastMessage);
      } else if (
        StatusCode === RunHandlerStatusCodeEnum.Completed ||
        StatusCode === RunHandlerStatusCodeEnum.Cancelled
      ) {
        showHandlerToast(ToastTypeEnum.SUCCESS, toastMessage);
        if (isDefined(runHandler.return)) {
          downloadHandlerResult(TaskRunId, runHandler.name);
        }
      }
    }
  }, [taskProgress]);

  useEffect(() => {
    if (runningHandlerData[activeTab] && !env.isSSEHandlers) {
      let executedHandlerInfo: ExecutedHandlerInfoByRecord = {};
      const isStaticHandler = (handlerName: string) => handlers?.find((item) => item.id === handlerName)?.isStatic;

      Object.keys(runningHandlerData[activeTab]).forEach((handler) => {
        if (isDefined(selectedRowKey) && selectedRowKey in runningHandlerData[activeTab][handler]) {
          const selectedRowHandlerStatus = runningHandlerData[activeTab][handler][selectedRowKey].StatusCode;

          executedHandlerInfo = {
            ...executedHandlerInfo,
            [getRunHandlerKey(handler, isStaticHandler(handler), selectedRowKey)]: {
              data: {} as ExecutedHandlerData,
              status: getHandlerStatus(selectedRowHandlerStatus),
              error: '',
            },
          };
        } else if (isStaticHandler(handler)) {
          const currentHandler = runningHandlerData[activeTab][handler];
          const firstKey = Object.keys(currentHandler)[0];
          const selectedRowHandlerStatus = currentHandler[firstKey]?.StatusCode;

          executedHandlerInfo = {
            ...executedHandlerInfo,
            [getRunHandlerKey(handler, true, '')]: {
              data: {} as ExecutedHandlerData,
              status: getHandlerStatus(selectedRowHandlerStatus),
              error: '',
            },
          };
        }
      });

      setExecutedHandlersInfoByRecord(executedHandlerInfo);
    }
  }, [selectedRowKey, runningHandlerData]);

  useEffect(() => {
    dispatch(clearHandlerState());
  }, [activeTab]);

  const downloadHandlerResult = (taskRunId: string, handlerName: string) => {
    const params = {
      dataset: dataset,
      dataSource: environment,
      taskRunIds: [taskRunId],
    };

    handlerApi
      .runTasksResult<ExecutedHandlerRunTaskDataType[]>(params)
      .then((data) => {
        const handlerResult = data[0].Result;
        if (isNullable(handlerResult)) return;
        if (handlerResult._t === HandlerContentTypeEnum.BinaryContent) {
          downloadDecodedData(handlerResult.Content, handlerResult.Name || '');
        } else {
          const exportTime = DateFormatUtility.create().format(DateFormatEnum.DATE_TIME_MS);
          const fileName = `${exportTime} ${data[0].Key} ${handlerName}`;
          downloadJsonObject(handlerResult, fileName);
        }
      })
      .catch((e: AxiosError) => showHandlerToast(ToastTypeEnum.ERROR, e));
  };

  useEffect(() => {
    if (executedHandlers.length) {
      const newCanceledArr: string[] = [];
      executedHandlers.forEach(({ TaskRunId, StatusCode, Key: handlerKey, handler }) => {
        if (canceledHandlerIds.includes(TaskRunId)) return;

        newCanceledArr.push(TaskRunId);
        const toastMessage = handlerKey ? `${handlerKey} / ${handler.name}` : handler.name;

        if (StatusCode === RunHandlerStatusCodeEnum.Failed) {
          showHandlerToast(ToastTypeEnum.ERROR, toastMessage);
        } else {
          showHandlerToast(ToastTypeEnum.SUCCESS, toastMessage);
          if (isDefined(handler.return)) {
            downloadHandlerResult(TaskRunId, handler.name);
          }
        }
      });

      dispatch(setAllCanceledHandlerIds(newCanceledArr));
    }
  }, [executedHandlers]);

  const changeHandlerStatus = (handlerStatusData: ExecutedHandlerStatusData[], handlerId: string) => {
    dispatch(
      setTaskHandlerStatus({
        tableName: activeTab,
        handlerId,
        taskRunArr: handlerStatusData,
      })
    );
    dispatch(setRecordStatuses(handlerStatusData));
  };

  const configExecutedHadlerArr = (
    item: ExecutedHandlerStatusData,
    handler: HandlerItemType,
    rowsData: ExecutedHandlerRunTaskDataType[]
  ) => ({ ...item, handler, recordKey: rowsData.find((row) => row.TaskRunId === item.TaskRunId)?.Key || '' });

  const getTasksStatus = (
    taskParams: HandlerGetParamsRequestDto,
    taskHandler: HandlerItemType,
    taskRowsData: ExecutedHandlerRunTaskDataType[]
  ) => {
    const startTime = Date.now();
    let timeout: NodeJS.Timeout;

    const fetchData = (
      params: HandlerGetParamsRequestDto,
      handler: HandlerItemType,
      rowsData: ExecutedHandlerRunTaskDataType[]
    ) => {
      handlerApi
        .runTasksStatus<ExecutedHandlerStatusData[]>(params)
        .then((data) => {
          const areAllHandlersExecuted = data.every(
            ({ StatusCode }) =>
              StatusCode !== RunHandlerStatusCodeEnum.Submitted && StatusCode !== RunHandlerStatusCodeEnum.Running
          );

          changeHandlerStatus(data, handler.id);
          clearTimeout(timeout);

          if (areAllHandlersExecuted) {
            const executedHandlersData = data.map((item) => configExecutedHadlerArr(item, handler, rowsData));
            setExecutedHandlers(executedHandlersData);
          } else {
            const notCompletedTaskRunIds: string[] = [];
            const completedHandlers: ExecutedHandlersType[] = [];

            data.forEach((item) => {
              if (
                item.StatusCode === RunHandlerStatusCodeEnum.Submitted ||
                item.StatusCode === RunHandlerStatusCodeEnum.Running
              ) {
                notCompletedTaskRunIds.push(item.TaskRunId);
              } else {
                completedHandlers.push(configExecutedHadlerArr(item, handler, rowsData));
              }
            });
            setExecutedHandlers(completedHandlers);
            timeout = setTimeout(
              () => fetchData({ ...params, taskRunIds: notCompletedTaskRunIds }, handler, rowsData),
              getTimeoutDelay(startTime)
            );
          }
        })
        .catch((error) => {
          showHandlerToast(ToastTypeEnum.ERROR, error);
          const data: ExecutedHandlerStatusData[] = params.taskRunIds
            ? params.taskRunIds.map((item) => ({
                TaskRunId: item,
                StatusCode: RunHandlerStatusCodeEnum.Failed,
              }))
            : [];

          changeHandlerStatus(data, handler.id);
        });
    };

    fetchData(taskParams, taskHandler, taskRowsData);
  };

  const executeHandlerByRecords = (executableHandler: HandlerItemType, params?: StorageEntityModel) => {
    const handlersBySelectedRows =
      selectedRangeRows &&
      Object.values(selectedRangeRows)?.map((row) => ({
        ...executableHandler,
        key: getRunHandlerKey(executableHandler.id, executableHandler.isStatic, row._key as string),
        rowKey: row._key as string,
      }));

    const selectedRowKeys: string[] = [];

    const configRowKeysArr = () => {
      switch (true) {
        case executableHandler.isStatic:
          return undefined;
        case selectedRowKeys.length !== 0:
          return selectedRowKeys;
        case isDefined(selectedRowKey):
          return [selectedRowKey];
        default:
          return undefined;
      }
    };

    handlersBySelectedRows?.forEach(({ rowKey }) => {
      selectedRowKeys.push(rowKey);
    });

    dispatch(
      setAllHandlerTasksStatus({
        tableName: activeTab,
        handlerId: executableHandler.id,
        keys: configRowKeysArr(),
        statusCode: RunHandlerStatusCodeEnum.Submitted,
      })
    );

    handlerApi
      .runTasks<ExecutedHandlerRunTaskDataType[]>({
        dataset,
        dataSource: environment,
        table: activeTab,
        keys: configRowKeysArr(),
        method: executableHandler.id,
        ...(params && { args: params }),
      })
      .then((data) => {
        const recordStatusData = data.map((item) => ({ ...item, StatusCode: RunHandlerStatusCodeEnum.Submitted }));
        if (env.isSSEHandlers) {
          const handlerKeysObj: NewRunHandlersType = {};
          const runHandlerRecordKeys: Record<string, string> = {};
          const newExecutedData: ExecutedHandlerInfoByRecord = { ...executedHandlersInfoByRecord };

          data.forEach((item) => {
            handlerKeysObj[item.TaskRunId] = {
              key: item.Key,
              statusCode: RunHandlerStatusCodeEnum.Submitted,
              executableHandler,
            };
            runHandlerRecordKeys[item.Key] = RunHandlerStatusCodeEnum.Submitted;
            newExecutedData[getRunHandlerKey(executableHandler.id, executableHandler.isStatic, item.Key)] = {
              data: {} as ExecutedHandlerData,
              status: getHandlerStatus(RunHandlerStatusCodeEnum.Submitted),
              error: '',
            };
          });
          const newHandlerRecordKeys = {
            ...handlerRecordKeys,
            [executableHandler.id]: {
              ...handlerRecordKeys[executableHandler.id],
              ...runHandlerRecordKeys,
            },
          };

          setNewRunHandlers({ ...newRunHandlers, ...handlerKeysObj });
          setHandlerRecordKeys(newHandlerRecordKeys);
          setExecutedHandlersInfoByRecord(newExecutedData);
        } else {
          dispatch(setRunHandlersData({ tableName: activeTab, handlerId: executableHandler.id, rowsData: data }));

          const tasksStatusParams = {
            dataset,
            dataSource: environment,
            taskRunIds: data.map((item) => item.TaskRunId),
          };

          getTasksStatus(tasksStatusParams, executableHandler, data);
        }

        dispatch(setRecordStatuses(recordStatusData));
        dispatch(updateProgressMessagesData(progressMessagesDataMock)); // ToDo: delete mock
      })

      .catch((e: AxiosError) => {
        showHandlerToast(ToastTypeEnum.ERROR, e);
        dispatch(
          setAllHandlerTasksStatus({
            tableName: activeTab,
            handlerId: executableHandler.id,
            keys: configRowKeysArr(),
            statusCode: RunHandlerStatusCodeEnum.Failed,
          })
        );
      });
  };

  const handleCancelHandlerRun = (handlerId: string, isStaticHandler?: boolean) => {
    if (!selectedRowKey && !isStaticHandler) return;

    const rowKey = isStaticHandler ? Object.keys(runningHandlerData[activeTab][handlerId])[0] : selectedRowKey || '';

    const selectedRowTaskRunId = runningHandlerData[activeTab][handlerId][rowKey].TaskRunId;
    handlerApi
      .cancel<ExecutedHandlerStatusData[]>({
        dataset,
        dataSource: environment,
        taskRunIds: [selectedRowTaskRunId],
      })
      .then((data) => {
        if (env.isSSEHandlers) return;

        const isCanceledHandler = data[0].StatusCode === RunHandlerStatusCodeEnum.Cancelled;
        if (isCanceledHandler) {
          const canceledIds = data.map((item) => item.TaskRunId);
          dispatch(setCanceledHandlerIds(canceledIds));
          changeHandlerStatus(data, handlerId);
          showHandlerToast(ToastTypeEnum.SUCCESS, t('shared.success'));
        } else {
          showHandlerToast(ToastTypeEnum.ERROR, t('toast.failedUpperCase'));
        }
      })

      .catch((e: AxiosError) => {
        if (!env.isSSEHandlers) {
          changeHandlerStatus(
            [{ TaskRunId: selectedRowTaskRunId, StatusCode: RunHandlerStatusCodeEnum.Failed }],
            handlerId
          );
        }
        showHandlerToast(ToastTypeEnum.ERROR, e);
      });
  };

  const handlePinHandler = (currentHandler: HandlerItemType) => {
    setHandlers((prev) => {
      const updatedHandlers = prev?.map((handler) =>
        handler.id === currentHandler.id ? { ...handler, isPinned: !handler.isPinned } : handler
      );

      if (updatedHandlers) {
        action.saveUiTypeData(
          updatedHandlers.reduce((acc: string[], item) => (item.isPinned ? [...acc, item.id] : acc), [])
        );
      }
      return updatedHandlers;
    });
  };

  const handleOpenModalWithParams = (handler: HandlerItemType) => {
    setSelectedHandlerWithParams(handler);
  };

  const handeOpenConfirmManyRecordsModal = (executableHandler: HandlerItemType) => {
    UiModal.info({
      title: t('confirmationModal.executingHandlers.title'),
      content: t('confirmationModal.executingHandlers.confirmationTexts', {
        numberOfItems: Object.keys(selectedRangeRows || {})?.length,
      }),
      okText: t('buttons.run'),
      onOk: (close) => {
        if (executableHandler?.hasParams) {
          handleOpenModalWithParams(executableHandler);
        } else {
          executeHandlerByRecords(executableHandler);
        }
        close();
      },
    });
  };

  const handleCloseModalWithParams = () => setSelectedHandlerWithParams(undefined);

  const handleHandlerClick = (currentHandler: HandlerItemType) => {
    if (isFinalReadOnly) {
      return;
    }

    if (isManyRowsSelected && !currentHandler.isStatic) {
      handeOpenConfirmManyRecordsModal(currentHandler);
    } else if (currentHandler.hasParams) {
      handleOpenModalWithParams(currentHandler);
    } else {
      executeHandlerByRecords(currentHandler);
    }
  };

  return (
    <>
      <div
        className={cx(
          'bg-type-toolbar-background d-flex align-items-center py-1 px-2 w-100 border-bottom border-type-toolbar-border topBorderGradient',
          {
            'justify-content-between': isRunHandlers,
            'justify-content-start': !isRunHandlers,
            'p-0': !isRunHandlers && !env.isDatasetSupport,
            'py-1 px-2': isRunHandlers || env.isDatasetSupport,
          },
          containerClassname
        )}
      >
        {appState.isUserMode && (
          <div className="me-2">
            <Logo />
          </div>
        )}
        {env.isDatasetSupport && <CommonButton />}
        {isRunHandlers && (
          <HandlersContainer
            handlers={handlers}
            executedHandlersInfoByRecord={executedHandlersInfoByRecord}
            isLogsOpened={isLogsOpened}
            onPinHandler={handlePinHandler}
            onHandlerClick={handleHandlerClick}
            onCancelHandler={handleCancelHandlerRun}
            isReadOnly={isFinalReadOnly}
          />
        )}
      </div>

      {selectedHandlerWithParams && (
        <HandlerWithParamsModal
          schemaType={activeTab}
          selectedRowType={selectedRowType}
          selectedHandlerWithParams={selectedHandlerWithParams}
          onExecuteHandler={executeHandlerByRecords}
          onClose={handleCloseModalWithParams}
        />
      )}
    </>
  );
};

export const TypeToolbar: FC<TypeToolbarProps> = (props) => {
  const { TypeToolbaInner: TypeToolbaInnerComponent } = useComponentsMapper();

  return <TypeToolbaInnerComponent {...props} />;
};
