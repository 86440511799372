import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { LogsLevelEnum, LogsListViewEnum } from 'view/Logs/utils/types';

import { LogsHeaderFilter } from '../LogsHeaderFilter';

import styles from './styles.module.scss';

type LogsHeaderIconsProps = {
  activeListView: LogsListViewEnum;
  onClearLogsList: () => void;
  onSetActiveFilters: (levels: LogsLevelEnum[]) => void;
  activeFilters: LogsLevelEnum[];
};

export const LogsHeaderIcons = ({
  activeListView,
  onClearLogsList,
  onSetActiveFilters,
  activeFilters,
}: LogsHeaderIconsProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Tooltip>
        <TooltipTrigger>
          <Icon SvgIcon={APP_ICONS.clear} onClick={onClearLogsList} />
        </TooltipTrigger>
        <TooltipContent>{t('logs.eraseLogs')}</TooltipContent>
      </Tooltip>
      {activeListView === LogsListViewEnum.FLAT_VIEW && (
        <LogsHeaderFilter onSetActiveFilters={onSetActiveFilters} activeFilters={activeFilters} />
      )}
    </div>
  );
};
