import { FC } from 'react';

import { UIButton } from 'ui';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import './EditorButtonsGroup.scss';

type EditorButtonsGroupProps = {
  isSubmitDisabled?: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
};

export const EditorButtonsGroup: FC<EditorButtonsGroupProps> = ({ isSubmitDisabled = false, onSubmit, onCancel }) => {
  const { state, i18n } = useEditorState();

  if (state.isReadOnly) return null;

  return (
    <div className="editor-buttons-group">
      {onCancel && (
        <UIButton variant="secondary" onClick={onCancel}>
          {i18n?.cancelBtnText}
        </UIButton>
      )}
      <UIButton onClick={onSubmit} disabled={isSubmitDisabled}>
        {i18n?.submitBtnText}
      </UIButton>
    </div>
  );
};
