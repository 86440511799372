enum TimeoutDelayEnum {
  FIVE_HUNDRED_MILLISECONDS = 500,
  ONE_SECOND = 1000,
  TWO_SECONDS = 2000,
  FIVE_SECONDS = 5000,
}

export const getTimeoutDelay = (startTime: number) => {
  const currentTime = Date.now();
  let delay: number;

  if (currentTime - startTime < TimeoutDelayEnum.TWO_SECONDS) {
    delay = TimeoutDelayEnum.FIVE_HUNDRED_MILLISECONDS;
  } else if (currentTime - startTime < TimeoutDelayEnum.FIVE_SECONDS) {
    delay = TimeoutDelayEnum.ONE_SECOND;
  } else {
    delay = TimeoutDelayEnum.FIVE_SECONDS;
  }

  return delay;
};
