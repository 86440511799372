import { QueryOperatorEnum, ValueTypeModel } from 'api';
import { OptionType } from 'ui';
import { ValueTypesEnum } from 'utils';

export const generateOptionsBySchemaType = (type: ValueTypeModel): OptionType<QueryOperatorEnum>[] => {
  switch (type) {
    case ValueTypesEnum.String:
      return [
        { label: 'Contains', value: QueryOperatorEnum.Contains },
        { label: 'Equals', value: QueryOperatorEnum.Equal },
        { label: 'Ends With', value: QueryOperatorEnum.EndsWith },
        { label: 'Starts With', value: QueryOperatorEnum.StartsWith },
      ];
    case ValueTypesEnum.Int:
    case ValueTypesEnum.Double:
    case ValueTypesEnum.Long:
      return [
        { label: 'Equals', value: QueryOperatorEnum.Equal },
        { label: 'Not Equals', value: QueryOperatorEnum.NotEqual },
        { label: 'Less Than', value: QueryOperatorEnum.Less },
        { label: 'Less Than Or Equal', value: QueryOperatorEnum.LessOrEqual },
        { label: 'Greater Than Or Equal', value: QueryOperatorEnum.MoreOrEqual },
        { label: 'Greater Than', value: QueryOperatorEnum.More },
      ];
    case ValueTypesEnum.Bool:
      return [
        { label: 'Equals', value: QueryOperatorEnum.Equal },
        { label: 'Not Equals', value: QueryOperatorEnum.NotEqual },
      ];
    case ValueTypesEnum.Date:
    case ValueTypesEnum.Time:
    case ValueTypesEnum.DateTime:
      return [
        { label: 'Equals', value: QueryOperatorEnum.Equal },
        { label: 'Not Equals', value: QueryOperatorEnum.NotEqual },
        { label: 'From', value: QueryOperatorEnum.MoreOrEqual },
        { label: 'To', value: QueryOperatorEnum.LessOrEqual },
      ];
    default:
      return [];
  }
};
