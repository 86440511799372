import { StyledDragingTabType } from '../types';

export const styleDraggingTab = (draggingTab: StyledDragingTabType) => {
  const { blockPointer, tab, event, originalPositionX, originalPositionY, dropPreview } = draggingTab;

  const xTranslation = event.clientX - originalPositionX;
  const yTranslation = event.clientY - originalPositionY;

  const dropPreviewCopy = dropPreview;

  tab.style.display = 'flex';
  tab.style.width = `${tab.offsetWidth}px`;
  tab.style.height = `${tab.offsetHeight}px`;
  tab.style.left = `${tab.offsetLeft}px`;
  tab.style.position = 'fixed';
  tab.style.top = `${tab.offsetTop}px`;

  if (blockPointer) {
    tab.style.pointerEvents = 'none';
  }

  tab.style.transform = `translate(${xTranslation}px, ${yTranslation}px)`;
  tab.style.zIndex = '5000';

  if (dropPreviewCopy && !dropPreviewCopy.classList.contains('d-none')) {
    dropPreviewCopy.style.pointerEvents = 'none';
    dropPreviewCopy.style.position = 'fixed';
    dropPreviewCopy.style.top = `${tab.offsetTop || dropPreviewCopy.style.top}px`;
    dropPreviewCopy.style.left = `${tab.offsetLeft || dropPreviewCopy.style.left}px`;

    dropPreviewCopy.style.transform = `translate(${xTranslation}px, ${yTranslation}px)`;

    tab.style.display = 'none';
  }
};
