import { ViewerTypesEnum } from 'view/Viewers/utils/constants';
import { EntityPanelViewOfModel } from 'api';
import { cleanTypeHelper } from 'utils/helpers/cleanType.helper';

export const checkSimpleTypesHelper = (viewerData: EntityPanelViewOfModel[]) => {
  const viewerTypes = Object.values<string>(ViewerTypesEnum);
  const cache = new Map<string, boolean>();

  return viewerData.every((viewer) => {
    const { _t: viewerType } = viewer;

    if (cache.has(viewerType)) return cache.get(viewerType);

    const cleanViewerType = cleanTypeHelper(viewerType);
    const isDynamicType = viewerTypes.indexOf(cleanViewerType) === -1;

    cache.set(viewerType, isDynamicType);

    return isDynamicType;
  });
};
