import { useTranslation } from 'react-i18next';

import { useRequest } from 'hook/request.hook';
import { entityApi, schemaApi, storageApi, StorageEntityModel, StorageGetEntityRequestDto } from 'api';
import { toast } from 'utils';
import { SchemaTypeRequestDto } from 'api/data/schema.data';

type EditorSaveRequestType = {
  data: StorageEntityModel;
  recordType: string;
  oldRecordKey?: string;
};

export const useEditorRequest = () => {
  const { t } = useTranslation();
  const getRecordRequest = useRequest(storageApi.getRecord);
  const saveEntityRequest = useRequest(entityApi.saveEntity);
  const getSchemaRequest = useRequest(schemaApi.getType);

  const fetchSaveEntity = async ({ data, recordType, oldRecordKey }: EditorSaveRequestType) => {
    try {
      const result = await saveEntityRequest.fetch({ data: { _t: recordType, ...data }, oldRecordKey });
      toast.success(t('editor.entitySavedSuccessfully'));
      return result;
    } catch (e) {
      toast.error(e);
      throw e;
    }
  };

  const fetchSchema = async (data: SchemaTypeRequestDto) => {
    try {
      return await getSchemaRequest.fetch(data);
    } catch (e) {
      toast.error(e);
      throw e;
    }
  };

  const fetchRecord = async (data: StorageGetEntityRequestDto) => {
    try {
      return await getRecordRequest.fetch(data);
    } catch (e) {
      toast.error(e);
      throw e;
    }
  };

  return {
    getRecord: {
      ...getRecordRequest,
      fetch: fetchRecord,
    },
    saveEntity: {
      ...saveEntityRequest,
      fetch: fetchSaveEntity,
    },
    getSchema: {
      ...getSchemaRequest,
      fetch: fetchSchema,
    },
  };
};
