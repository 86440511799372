import { UIControlRuleType } from '../types';

export const formRules = {
  required<T extends object>(message: string): UIControlRuleType<T> {
    return {
      validate: Boolean,
      message,
    };
  },
};
