import { Cell, Row } from '@tanstack/react-table';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import { UIBodyCell } from '../UIGridBodyCell/UIBodyCell.component';
import { UIGridBodyRow } from '../UIGridBodyRow/UIGridBodyRow.component';
import { UIGridVirtualContent } from '../UIGridVirtualContent/UIGridVirtualContent.component';
import { UIGridVerticalScroll } from '../UIGridVerticalScroll/UIGridVerticalScroll.component';

import './UIGridBody.styles.scss';

type UiGridBodyProps = {
  className?: string;
};

export const UiGridBody = <TData,>({ className }: UiGridBodyProps) => {
  const { bodyRef } = useGrid<TData>();

  const renderCell = <TValue,>(cell: Cell<TData, TValue>) => <UIBodyCell cell={cell} />;

  return (
    <div className={cx('ui-grid-body', className)}>
      <div className="ui-grid-body__content" ref={bodyRef}>
        <UIGridVirtualContent>
          {(row: Row<TData>) => <UIGridBodyRow row={row} renderCell={renderCell} />}
        </UIGridVirtualContent>
      </div>
      <UIGridVerticalScroll className="ui-grid-body__vertical-scroll" />
    </div>
  );
};
