import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_ICONS } from 'utils/icons';
import { IconWithTooltip } from 'shared/IconWithTooltip/IconWithTooltip';
import { CodeEditorModal } from 'view/Editor/components/CodeEditorModal/CodeEditorModal';
import { StorageEntityModel } from 'api';

export type EditorToolbarJsonEditProps = {
  isReadOnly?: boolean;
  data: StorageEntityModel;
  onSubmit?: (data: StorageEntityModel) => void;
};

export const EditorToolbarJsonEdit: FC<EditorToolbarJsonEditProps> = ({ isReadOnly, data, onSubmit }) => {
  const { t } = useTranslation();
  const [isOpenCodeModalVisible, setIsOpenCodeModalVisible] = useState(false);

  return (
    <>
      <IconWithTooltip
        onClick={() => setIsOpenCodeModalVisible(true)}
        SvgIcon={APP_ICONS.openJson}
        tooltip={t('editorToolbar.json')}
      />
      <CodeEditorModal<StorageEntityModel>
        isReadOnly={isReadOnly}
        data={data}
        isOpen={isOpenCodeModalVisible}
        onClose={() => setIsOpenCodeModalVisible(false)}
        onSave={onSubmit}
      />
    </>
  );
};
