import { useLayoutEffect } from 'react';
import type { RefObject } from 'react';

import { useBoolean } from 'utils/hooks';

export const useDisplayTooltip = (ref: RefObject<HTMLElement>, isForce = false) => {
  const [isTooltipDisplayed, { set }] = useBoolean(false);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const labelDiv = ref.current;
    const isOverflow = labelDiv.offsetWidth < labelDiv.scrollWidth;

    const isEnabled = isOverflow || isForce;

    set(isEnabled || false);
  }, [ref, set, isForce]);

  return isTooltipDisplayed;
};
