import { SchemaTypeElementsModel, SchemaTypeListModel, StorageEntityModel, StorageEntityRootModel } from 'api';
import { getPropByPath } from 'view/Editor/helpers/getPropByPath.helper';
import { binaryContentModuleName } from 'view/Editor/helpers/getCellProps.helper';

import { EditorValidationType, ValidationResultType } from './type';
import { getValidationRulesBySchema, validationRules } from './rules/index';

export const validateValueBySchema = (
  schema: ValidationSchemaByPathResult,
  value: unknown
): ValidationResultType | null => {
  const rules = validationRules();
  const ruleWithError = schema.rules?.find((rule) => !rules[rule]?.validate(value));

  return ruleWithError
    ? ({
        rule: ruleWithError,
        path: schema.path,
        message: rules[ruleWithError].message,
      } as ValidationResultType)
    : null;
};

export type ValidationSchemaByPathResult = {
  rules: EditorValidationType[];
  path: string[];
};

export type ValidationSchemaByPathProps = {
  schema: SchemaTypeListModel;
  editorData: StorageEntityModel;
  rootSchema: string;
  path: string[];
};

export const getValidationSchemaByPath = ({
  schema,
  rootSchema,
  editorData,
  path,
}: ValidationSchemaByPathProps): ValidationSchemaByPathResult | null => {
  if (!schema || !rootSchema || !path) return null;

  const getResult = (elementSchema: SchemaTypeElementsModel, resultPath: string[]) => ({
    rules: getValidationRulesBySchema(elementSchema),
    path: resultPath,
  });

  const checkSchema = (currentSchemaKey: string, pathIndex: number): ValidationSchemaByPathResult | null => {
    const currentSchema = schema[currentSchemaKey];
    if (!currentSchema) return null;

    const currentElement = currentSchema?.Elements?.find((element) => element.Name === path[pathIndex]);
    const pathSimple = path.slice(0, pathIndex + 1);
    const pathList = path.slice(0, pathIndex + 2);
    const isLastPath = pathIndex + 1 === path.length;

    if (!currentElement) {
      return {
        rules: [],
        path: pathSimple,
      };
    }

    if (isLastPath) return getResult(currentElement, pathSimple);

    // TODO Remove isBinaryContent after backend improve
    const isBinaryContent =
      `${currentElement?.Data?.Module?.ModuleName}.${currentElement?.Data?.Name}` === binaryContentModuleName;
    const isVectorData = !!currentElement.Vector;
    const isVectorNestedData = !isBinaryContent && isVectorData && currentElement?.Data?.Module?.ModuleName;
    const isVectorNestedKey = isVectorData && currentElement?.Key?.Module?.ModuleName;
    const isNotVectorNestedData = !isVectorData && currentElement?.Data?.Module?.ModuleName;

    if (isBinaryContent) {
      return getResult(currentElement, pathSimple);
    }

    if (isVectorNestedData) {
      const vectorNestedPathIndex = pathIndex + 2; //  2 - for avoid list index
      const nestedSchemaName = `${currentElement.Data!.Module.ModuleName}.${currentElement.Data!.Name}`;
      return checkSchema(nestedSchemaName, vectorNestedPathIndex);
    }

    if (isNotVectorNestedData) {
      const getNestedSchemaName = () => {
        const typeFromData = (getPropByPath(editorData, pathSimple) as StorageEntityRootModel)?._t;
        if (typeFromData) {
          const schemaFromData = `${currentElement.Data!.Module.ModuleName}.${typeFromData}`;
          if (schema[schemaFromData]) return schemaFromData;
        }
        return `${currentElement.Data!.Module.ModuleName}.${currentElement.Data!.Name}`;
      };
      return checkSchema(getNestedSchemaName(), pathIndex + 1);
    }

    if (isVectorNestedKey || isVectorData) return getResult(currentElement, pathList);

    return getResult(currentElement, pathSimple);
  };

  return checkSchema(rootSchema, 0);
};
