import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getEnvironmentData } from 'store/shared-reducers/EnvironmentDataSlice';
import { useAppDispatch } from 'store/hooks';
import { isError, isLoading, toast } from 'utils';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { getAppState, setNoneType, setUser } from 'store/shared-reducers/AppStateSlice';
import { authApi } from 'api';
import { selectEnvironment } from 'containers/TabItemContent/store/selectors';
import { useRequest } from 'hook/request.hook';
import { selectStore } from 'store/selectors/AppState.selector';
import { externalStorage } from 'shared/ExternalStorage';
import { ReloadApplicationModal } from 'view/ReloadApplicationModal';
import { RouterEnum } from 'router';

type StartupGuardProps = PropsWithChildren;

export const StartupGuard: FC<StartupGuardProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { status: appStateStatus } = useSelector(selectStore);
  // const { status: appStateStatus, user } = useSelector(selectStore);
  const { status: environmentStatus } = useSelector(selectEnvironment);
  const { status: authTypesStatus, hasError: isAuthTypesError, fetch: getAuthTypes } = useRequest(authApi.getTypes);
  const { status: profileStatus, hasError: isProfileError, fetch: getProfile } = useRequest(authApi.profile);

  const isInitLoading = isLoading([authTypesStatus, profileStatus, environmentStatus, appStateStatus]);
  const isInitError = isError([environmentStatus, appStateStatus]) || isAuthTypesError || isProfileError;

  const previousSessionId = useRef<string | null>(externalStorage.getSessionId());
  // const isSessionIdChanged = Boolean(previousSessionId.current) && previousSessionId.current !== user?.sessionId;
  const isSessionIdChanged = false;

  const initApplication = () => {
    const initPromises = Promise.all([getAuthTypes(), getProfile(), dispatch(getEnvironmentData())]);

    initPromises
      .then(([authTypesResponse, profile]) => {
        if (authTypesResponse.length === 0) {
          dispatch(setNoneType());
        }

        if (!previousSessionId.current) {
          externalStorage.setSessionId(profile.sessionId);
        }

        dispatch(setUser(profile));
        dispatch(getAppState(profile.username));
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const resetApplication = () => {
    externalStorage.removeSessionId();
    window.location.reload();
  };

  useEffect(() => {
    initApplication();
  }, []);

  if (isInitError) {
    return <Navigate to={RouterEnum.SERVER_ERROR} />;
  }

  if (isInitLoading) {
    return <AppFallbackSkeleton skipContentLoading />;
  }

  return (
    <>
      <ReloadApplicationModal isOpen={isSessionIdChanged} onOk={resetApplication} />
      {children}
    </>
  );
};
