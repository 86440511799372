import { FC, useEffect, useState } from 'react';

import { CodeEditor } from 'shared/CodeEditor';
import { EntityPanelViewOfModel } from 'api';

type ScriptViewerProps = {
  data: EntityPanelViewOfModel;
};

export const ScriptViewer: FC<ScriptViewerProps> = ({ data }) => {
  const { Body: codeEditor, Language, WordWrap } = data;
  const [code, setCode] = useState<string>('');

  useEffect(() => {
    codeEditor && setCode(codeEditor.join('\n'));
  }, [codeEditor]);

  const handleCodeEditorChanged = (value?: string) => {
    value && setCode(value);
  };

  if (!code) return null;

  return (
    <div className="h-100 overflow-auto">
      <CodeEditor isReadonly code={code} language={Language} wordWrap={WordWrap} onChange={handleCodeEditorChanged} />
    </div>
  );
};
