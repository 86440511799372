import { Column, Row } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { toString } from 'utils';

export type SelectedRangeCellsOptions = {
  cellIdSplitBy: string;
  includeHeaders?: boolean;
};

export const getSelectedRangeCells = (
  columns: Column<DataType>[],
  rows: Row<DataType>[],
  selectedRangeCells: Record<string, boolean>,
  options: SelectedRangeCellsOptions
): string => {
  const { cellIdSplitBy, includeHeaders = false } = options;

  const availableColumns = columns.filter((column) => !column.suppressHideColumn);

  const selectedColumns = Object.keys(selectedRangeCells)
    .map((key) => key.split(cellIdSplitBy)[0])
    .filter((value, index, self) => self.indexOf(value) === index);

  const filteredColumns = availableColumns.filter((column) => column.id && selectedColumns.includes(column.id));

  const th = filteredColumns.reduce((str, column) => str.concat(toString(column.Header), '\t'), '');
  const td = rows
    .map((row) =>
      availableColumns
        .filter((column) => column.id && selectedRangeCells[`${column.id}_${row.index}`])
        .reduce((str: string, column) => {
          if (column.id && column.id in row.values) {
            const value = row.values[column.id];

            return str.concat(toString(value), '\t');
          }
          return str;
        }, '')
    )
    .join('\n')
    .trim();

  return includeHeaders ? `${th}\n${td}` : td;
};
