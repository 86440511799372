import { FC, memo, SyntheticEvent, useEffect } from 'react';
import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';

import { CoordinateType } from 'view/Grid/utils/types';

import styles from './styles.module.scss';

type HorizontalScrollProps = {
  totalColumnsWidth: number;
  isShowCorner: boolean;
  isHidden: boolean;
  setHidden: (isHidden: boolean) => void;
  scrollBodyTo: (coordinate: CoordinateType) => void;
  scrollHeaderTo: (coordinate: CoordinateType) => void;
};

export const HorizontalScroll: FC<HorizontalScrollProps> = memo(
  ({ totalColumnsWidth, isShowCorner, isHidden, setHidden, scrollBodyTo, scrollHeaderTo }) => {
    const { ref, width = 0 } = useResizeObserver();

    useEffect(() => {
      setHidden(totalColumnsWidth < width);
    }, [width, totalColumnsWidth]);

    const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
      const offsetLeft = event.currentTarget.scrollLeft;

      scrollHeaderTo({ left: offsetLeft });
      scrollBodyTo({ left: offsetLeft });
    };

    return (
      <div
        ref={ref}
        className={cx('w-100 flex-shrink-0 d-flex', styles.container, {
          [styles.hidden]: isHidden,
        })}
      >
        <div className={cx('flex-grow-1 overflow-x-scroll', styles.scrollbarBackgroundColor)} onScroll={onScroll}>
          <div style={{ width: totalColumnsWidth }} className={styles.container} />
        </div>
        {isShowCorner && (
          <div className={cx('flex-shrink-0 bg-primary', styles.corner, styles.scrollbarBackgroundColor)} />
        )}
      </div>
    );
  }
);

HorizontalScroll.displayName = 'HorizontalScroll';
