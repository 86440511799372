import { isSafeNumber, parse, stringify, Replacer, JavaScriptValue, NumberStringifier } from 'lossless-json';
import BigNumber from 'bignumber.js';

// Almost never return exponential notation:
BigNumber.config({ EXPONENTIAL_AT: 1e9 });

export const parseStringNumber = (value: string) => (isSafeNumber(value) ? parseFloat(value) : BigNumber(value));

export const deserialize = <T = unknown>(text: string): T => parse(text, undefined, parseStringNumber) as T;

const bigNumberStringifier: NumberStringifier = {
  test: (value: JavaScriptValue) => BigNumber.isBigNumber(value),
  stringify: (value: JavaScriptValue) => (value as BigNumber).valueOf(),
};

export const serialize = <T extends object>(value: T, replacer?: Replacer, space?: number | string): string =>
  stringify(value, replacer, space, [bigNumberStringifier]) as string;
