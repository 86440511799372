import { LogsEventDataType, LogsListViewEnum } from 'view/Logs/utils/types';

import { LogsFlatViewList } from './components/LogsFlatViewList';

type LogsListProps = {
  activeListView: LogsListViewEnum;
  logsListData: LogsEventDataType[];
};

export const LogsList = ({ activeListView, logsListData }: LogsListProps) => {
  switch (activeListView) {
    case LogsListViewEnum.FLAT_VIEW:
      return <LogsFlatViewList logsListData={logsListData} />;
    case LogsListViewEnum.HANDLERS:
      return null; // TODO
    case LogsListViewEnum.NOTIFICATIONS:
      return null; // TODO
    default:
      return null;
  }
};
