export class AbortedPromiseError extends Error {
  constructor(message = 'The promise has been aborted') {
    super(message);
    this.name = 'AbortedPromiseError';
  }
}

export function isAbortedPromiseError(error: unknown): boolean {
  return error instanceof AbortedPromiseError;
}

export function makeCancellablePromise<T>(promise: Promise<T>, signal: AbortSignal) {
  return new Promise((resolve, reject) => {
    promise.then((value: T) => !signal.aborted && resolve(value)).catch((error) => !signal.aborted && reject(error));

    signal.addEventListener('abort', () => {
      reject(new AbortedPromiseError(signal.reason as string));
    });
  });
}
