import { FC } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { EDITOR_TABS, EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { ViewerContainer } from 'containers/ViewerContainer';
import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { EditorSelectSuccessor } from 'view/EditorContent/EditorSelectSuccessor';
import { NoGridData } from 'containers/TabItemContent/components/NoGridData';
import { selectMetaData } from 'containers/TabItemContent/store/selectors';
import { useAppContext } from 'context';
import { SchemaDisplayKind } from 'api';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { Dag } from 'view/Dag';
import { EditorEntity } from 'view/EditorData/EditorEntity';
import { EditorNewEntity } from 'view/EditorData/EditorNewEntity';
import { EditorSingleton } from 'view/EditorData/EditorSingleton';
import { selectIsReadonly } from 'store/selectors/AppState.selector';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { selectActiveDataset } from 'store/selectors';
import { EditorUpdateType } from 'view/EditorData/type';
import { EditorCopyEntity } from 'view/EditorData/EditorCopyEntity';

export type EditorTabContentProps = {
  activeTab?: EditorTabsEnum | string;
  type: string;
  transitionTab?: TransitionRecordType | null;
  selectedGridRow?: SelectedGridRowType;
  successorType?: string;
  showToolbar?: boolean;
  onTransition?: (data: TransitionRecordType) => void;
  onApplySuccessorType: (successor: string) => void;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const EditorTabContent: FC<EditorTabContentProps> = ({
  activeTab,
  type,
  transitionTab,
  selectedGridRow,
  showToolbar,
  successorType,
  onTransition,
  onApplySuccessorType,
  onUpdate,
}) => {
  const { state } = useAppContext();
  const { activeEditorTab } = state;
  // TODO: remove the store from the editor.
  const metaData = useSelector(selectMetaData);

  const isAppStateReadOnly = useSelector(selectIsReadonly);
  const tabItemContext = useTabItemContext();
  const dataset = useSelector(selectActiveDataset);
  const isReadOnly = isAppStateReadOnly || tabItemContext.state.isTypeReadOnly;

  if (metaData.data.DisplayKind === SchemaDisplayKind.Singleton) {
    return <EditorSingleton dataset={dataset} isReadOnly={isReadOnly} recordType={type} onUpdate={onUpdate} />;
  }

  if (activeTab?.includes(EditorTabsEnum.EDITOR_TRANSITION) && transitionTab?.type && transitionTab?.key) {
    return (
      <EditorEntity
        key={activeTab}
        isDisableKeyFields
        recordType={transitionTab.type}
        recordKey={transitionTab.key}
        onTransition={onTransition}
      />
    );
  }

  if (activeTab === EditorTabsEnum.EDITOR && activeTab === activeEditorTab && selectedGridRow?.key) {
    return (
      <EditorEntity
        dataset={dataset}
        isReadOnly={isReadOnly}
        recordType={type}
        recordKey={selectedGridRow.key}
        onUpdate={onUpdate}
        onTransition={onTransition}
      />
    );
  }

  if (activeTab === EditorTabsEnum.NEW_ENTITY && activeTab === activeEditorTab) {
    return <EditorNewEntity recordType={successorType ?? type} recordKey={selectedGridRow?.key} onUpdate={onUpdate} />;
  }

  if (activeTab === EditorTabsEnum.COPY_ENTITY && activeTab === activeEditorTab && selectedGridRow?.key) {
    return (
      <EditorCopyEntity recordType={selectedGridRow.type ?? type} recordKey={selectedGridRow.key} onUpdate={onUpdate} />
    );
  }

  if (activeTab === EditorTabsEnum.SELECT_TYPE) {
    return <EditorSelectSuccessor type={type} onApplySuccessorType={onApplySuccessorType} />;
  }

  if (activeTab === EditorTabsEnum.DAG && selectedGridRow?.type && selectedGridRow.key) {
    return <Dag type={selectedGridRow.type} selectedRowKey={selectedGridRow.key} />;
  }

  return activeTab && Boolean(!EDITOR_TABS[activeTab as EditorTabsEnum]) ? (
    <ViewerContainer
      panelKey={selectedGridRow?.key}
      panelType={type}
      activeTab={activeTab}
      showToolbar={showToolbar}
      onTransition={onTransition}
    />
  ) : (
    <NoGridData title={t('noData.inTheReport')} />
  );
};
