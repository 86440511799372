import { useState } from 'react';

import { KeyboardCode } from 'utils';

export enum Direction {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

type UseKeyboardNavigationProps = {
  itemsCount: number;
  isFirstSelected?: boolean;
  direction?: Direction;
  isPreventDefaultEvent?: boolean;
};

export const useKeyboardNavigation = ({
  itemsCount,
  isFirstSelected = true,
  direction = Direction.Vertical,
  isPreventDefaultEvent = true,
}: UseKeyboardNavigationProps) => {
  const defaultFocusedIndex = isFirstSelected ? 0 : -1;
  const [focusedIndex, setFocusedIndex] = useState(defaultFocusedIndex);

  const incrementIndex = () => {
    setFocusedIndex((prevIndex) => (prevIndex + 1) % itemsCount);
  };

  const decrementIndex = () => {
    setFocusedIndex((prevIndex) => (prevIndex === defaultFocusedIndex ? itemsCount - 1 : (prevIndex - 1) % itemsCount));
  };

  const resetFocusIndex = () => {
    setFocusedIndex(defaultFocusedIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (direction === Direction.Vertical) {
      if ((e.code as KeyboardCode) === KeyboardCode.ArrowDown) {
        isPreventDefaultEvent && e.preventDefault();
        incrementIndex();
      }
      if ((e.code as KeyboardCode) === KeyboardCode.ArrowUp) {
        isPreventDefaultEvent && e.preventDefault();
        decrementIndex();
      }
    }

    if (direction === Direction.Horizontal) {
      if ((e.code as KeyboardCode) === KeyboardCode.ArrowRight) {
        isPreventDefaultEvent && e.preventDefault();
        incrementIndex();
      }
      if ((e.code as KeyboardCode) === KeyboardCode.ArrowLeft) {
        isPreventDefaultEvent && e.preventDefault();
        decrementIndex();
      }
    }
  };

  return { focusedIndex, defaultFocusedIndex, handleKeyDown, resetFocusIndex, setFocusedIndex, incrementIndex };
};
