import { CellProps, Column } from 'react-table';

import { ComplexVectorCell } from 'view/Editor/components/TableBody/TableBodyCell/ComplexVectorCell';
import { DataType } from 'view/Grid/utils/types';
import { getColumnProps } from 'services/getColumnProps';
import { getCellProps } from 'view/Editor/helpers/getCellProps.helper';
import { SchemaTypeModel } from 'api';
import { ColumnNamesEnum } from 'utils/types/columnNames';

type GenerateComplexColumnsHelperProps = {
  schema?: SchemaTypeModel;
  path: string[];
  schemaPath?: string;
  currentTabIndex: number;
};

export const generateComplexColumnsHelper = ({
  schema,
  path,
  schemaPath = '',
  currentTabIndex,
}: GenerateComplexColumnsHelperProps): Column<DataType>[] =>
  schema && schema.Elements
    ? schema.Elements.filter((element) => !element.Hidden).map((element, index) => {
        const isExpander = index === 0;
        const columnId = isExpander ? ColumnNamesEnum.EXPANDER : element.Name;

        return {
          ...getColumnProps(element, { isExpander, columnId, isColumnFormatter: false }),
          Cell: (props: CellProps<DataType>) => {
            const { key, ...cellProps } = {
              ...props,
              ...getCellProps({ schemaElement: element, index, path, schemaPath, keys: schema.Keys }),
            };
            // TODO Use cellProps.path after refactor path in getCellProps method
            const fullPath = [...path, cellProps.row.index.toString(), element.Name];

            return <ComplexVectorCell key={key} {...cellProps} path={fullPath} currentTabIndex={currentTabIndex} />;
          },
        };
      })
    : [];
