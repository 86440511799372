import { getCellProps } from 'view/Editor/helpers/getCellProps.helper';
import { isDefined } from 'utils';
import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { FlatViewModel, SchemaTypeModel, ValueTypeModel } from 'api';

// TODO: this funtion will be changed as soon a we define what we should do in case of "Data" and "Key" type
export const getDataHelper = (
  schema: SchemaTypeModel,
  data: FlatViewModel,
  path: string[],
  schemaPath = ''
): EditorViewDataType[] =>
  schema.Elements
    ? schema.Elements.filter((element) => !element.Hidden).map((element, index) => {
        const value = data && isDefined(data[element.Name]) ? data[element.Name] : null;

        return {
          value,
          ...getCellProps({ schemaElement: element, index, keys: schema.Keys, path, schemaPath }),
        };
      })
    : [];

export const separateSimpleVectorHelper = (data: EditorViewDataType[]) => {
  const simpleVectorData: EditorViewDataType[] = [];
  const restData: EditorViewDataType[] = [];

  const simpleListType: Partial<ValueTypeModel>[] = [
    'String',
    'Int',
    'Double',
    'Bool',
    'Long',
    'Date',
    'Time',
    'DateTime',
  ];

  const isSimpleType = (valueType?: ValueTypeModel) => valueType && simpleListType.includes(valueType);

  data.forEach((row) => {
    row.isVector && isSimpleType(row.valueType) ? simpleVectorData.push(row) : restData.push(row);
  });

  return [simpleVectorData, restData];
};
