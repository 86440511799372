import cx from 'classnames';
import { NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import React, { useRef } from 'react';

import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { useDisplayTooltip } from 'shared/Tooltip/hooks/useDisplayTooltip';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { UiDropdown, UiDropdownItem } from 'ui';
import { useBoolean } from 'utils/hooks';
import { useAppContext } from 'context';

import styles from './styles.module.scss';

export type TabItemProps = {
  isChatTab?: boolean;
  isActive: boolean;
  label: string;
  isCloseable?: boolean;
  isRecordTab?: boolean;
  disableActions?: boolean;
  role: string;
  isHiddenTab?: boolean;
  isDashboardTab?: boolean;
  onClick: () => void;
  onCloseTab?: (event?: React.MouseEvent) => void;
  onCloseOtherTabs?: () => void;
  onCloseAllTabs?: () => void;
  navLinkClassname?: string;
};

export const TabItem: FC<TabItemProps> = (props) => {
  const {
    isChatTab,
    isActive,
    label,
    isRecordTab,
    disableActions = false,
    isCloseable = true,
    role,
    isHiddenTab = false,
    isDashboardTab = false,
    onClick,
    onCloseTab,
    onCloseAllTabs,
    onCloseOtherTabs,
    navLinkClassname,
  } = props;

  const { t } = useTranslation();
  const tabItemRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const isTooltipDisplayed = useDisplayTooltip(labelRef);
  const [isOpenDropdown, { toggle: toggleDropdown }] = useBoolean(false);
  const { state } = useAppContext();

  const dropdownMenuItems = [
    { label: 'Close tab', onClick: onCloseTab },
    { label: 'Close other tabs', onClick: onCloseOtherTabs },
    { label: 'Close all tabs', onClick: onCloseAllTabs },
  ];

  const handleToggleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    toggleDropdown();
  };

  const handleDropdownItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    item: (typeof dropdownMenuItems)[number]
  ) => {
    if (!item.onClick) return;

    event.stopPropagation();
    item.onClick();
  };

  return (
    <div ref={tabItemRef} className="flex-grow-1 h-100" role={role}>
      <NavLink
        tag="div"
        className={cx(
          styles.container,
          styles.containerTopBot,
          {
            'text-tab-text-active border-tab-border-active': isActive,
          },
          {
            [styles.containerActiveBorder]: isActive,
          },
          {
            [styles.recordTab]: !isActive && isRecordTab,
          },
          {
            [styles.noBorder]: isHiddenTab || isDashboardTab,
          },
          {
            [styles.dashboardTab]: isDashboardTab,
          },
          navLinkClassname
        )}
        onClick={onClick}
      >
        {((isActive && state.showLineHider) || (isChatTab && isActive)) && (
          <div className={cx('position-absolute', styles.bottomBorderPlaceholder)} />
        )}

        <Tooltip enabled={isTooltipDisplayed}>
          <TooltipTrigger ref={labelRef} className={cx('overflow-hidden me-2', styles.label)}>
            {label}
          </TooltipTrigger>
          <TooltipContent>{label}</TooltipContent>
        </Tooltip>

        {isCloseable && (
          <div className="d-flex">
            {!disableActions && (
              <UiDropdown
                isOpen={isOpenDropdown}
                toggle={toggleDropdown}
                tag="span"
                container={isHiddenTab ? tabItemRef : 'body'}
                toggleButton={
                  <Icon
                    SvgIcon={APP_ICONS.arrowDown}
                    className={styles.tabIcon}
                    onClick={handleToggleClick}
                    color={isActive ? 'text-tab-active-icon' : 'text-tab-icon'}
                  />
                }
              >
                {dropdownMenuItems.map((item) => (
                  <UiDropdownItem
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => handleDropdownItemClick(event, item)}
                    key={item.label}
                    tag="div"
                  >
                    {item.label}
                  </UiDropdownItem>
                ))}
              </UiDropdown>
            )}

            <Icon
              className={styles.tabIcon}
              SvgIcon={APP_ICONS.close}
              title={t('shared.close') || ''}
              onMouseDown={onCloseTab}
              color={isActive ? 'text-tab-active-icon' : 'text-tab-icon'}
            />
          </div>
        )}
      </NavLink>
    </div>
  );
};
