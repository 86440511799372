import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Cell, ColumnWidthProps } from 'react-table';
import cx from 'classnames';

import { ColumnNameType, GridColumWidth, GridModeEnum, SelectedRangeCellsType } from 'view/Grid/utils/types';
import { isNegativeNumber, isNumberType } from 'utils';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { GridsType, SavedColumnType } from 'containers/TabItemContent/utils/types';
import { useSavedGridContext } from 'shared/Hooks/useSavedGridContext';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';

import styles from './styles.module.scss';

type TableBodyCellType = {
  cell: Cell;
  gridId: string;
  gridMode: GridModeEnum;
  selectedRangeCells: SelectedRangeCellsType;
  getColumnWidthProps?: () => ColumnWidthProps;
  canvasContext?: CanvasRenderingContext2D | null;
  isCellActivelySelected: boolean;
};

export const TableBodyCell: FC<TableBodyCellType> = memo(
  ({ cell, gridId, selectedRangeCells, gridMode, canvasContext, isCellActivelySelected }) => {
    const BORDER_X = 2;
    const DOUBLED_BORDER_X = BORDER_X * 2;
    const DEFAULT_PADDING_X = 16;

    const cellProps = useMemo(() => cell.getCellProps(), [cell]);
    const ref = useRef<HTMLDivElement | null>(null);
    const [isEnableTooltip, enableTooltip] = useState(false);
    const [maxColumnWidth, setMaxColumnWidth] = useState(GridColumWidth.MAX_WIDTH);
    const [allowAmendMaxWidth, setAllowAmendMaxWidth] = useState(false);

    const { state, action } = useTabItemContext();
    const { findSavedGridColumn, saveGridColumn } = useSavedGridContext(gridId);

    const getCellProperties = (computedStyle: CSSStyleDeclaration) => {
      const cellSidesPadding = (parseInt(computedStyle.padding.split(' ')[1], 10) || 0) * 2;
      const cellSidesBorder = (parseInt(computedStyle.border.split(' ')[0], 10) || 0) * 2;
      const cellMarginRight = (parseInt(computedStyle.margin.split(' ')[1], 10) || 0) * 2;
      const cellMarginLeft = parseInt(computedStyle.margin.split(' ')[3], 10) || 0;

      return [cellSidesPadding, cellSidesBorder, cellMarginRight, cellMarginLeft];
    };

    const getIconsWidth = () => {
      if (!ref.current || !canvasContext) {
        return 0;
      }

      const canvasContextCopy = canvasContext;

      const svgElem = ref.current.querySelector('svg');
      const vectorDrilledElem = ref.current.querySelector('.vector-drilled');
      const muiIcon = ref.current.querySelector('.MuiSvgIcon-root');

      let iconsWidth = svgElem?.width.baseVal.value || 0;

      const swgWrapper = svgElem?.closest('div');

      if (swgWrapper) {
        const swgWrapperCompStyle = getComputedStyle(swgWrapper);

        const [svgSidesPadding, , svgMarginRight, svgMarginLeft] = getCellProperties(swgWrapperCompStyle);

        iconsWidth = iconsWidth + svgMarginRight + svgMarginLeft + svgSidesPadding;
      }

      if (vectorDrilledElem) {
        const vectorDrilledCompStyle = getComputedStyle(vectorDrilledElem);

        canvasContextCopy.font = vectorDrilledCompStyle.font;

        const vectorIconTextWidth = canvasContextCopy.measureText(vectorDrilledElem.innerHTML).width;
        const [vectorSidesPadding] = getCellProperties(vectorDrilledCompStyle);

        iconsWidth = iconsWidth + vectorIconTextWidth + vectorSidesPadding;
      }

      if (muiIcon) {
        iconsWidth += DOUBLED_BORDER_X;
      }

      return iconsWidth;
    };

    const getCellContentWidth = () => {
      if (!ref.current || !canvasContext) {
        return GridColumWidth.DEFAULT_WIDTH;
      }

      const canvasContextCopy = canvasContext;

      let cellComputedStyle = getComputedStyle(ref.current);
      let textWrapper: HTMLSpanElement | HTMLInputElement | null = ref.current.querySelector('.MuiInputBase-input');

      if (!textWrapper) {
        textWrapper = ref.current.querySelector('span');
      }

      let [cellPadding, cellBorder, cellMarginRight, cellMarginLeft] = getCellProperties(cellComputedStyle);

      if (textWrapper) {
        cellComputedStyle = getComputedStyle(textWrapper);

        const [spanPadding, spanBorder, spanMarginRight, spanMarginLeft] = getCellProperties(cellComputedStyle);

        cellPadding += spanPadding;
        cellBorder += spanBorder;
        cellMarginRight += spanMarginRight;
        cellMarginLeft += spanMarginLeft;
      }

      canvasContextCopy.font = cellComputedStyle.font;

      return Math.ceil(
        canvasContextCopy.measureText(
          (textWrapper as HTMLInputElement)?.value ||
            (textWrapper as HTMLInputElement)?.placeholder ||
            String(cell.value) ||
            ref.current.innerText
        ).width +
          (cellPadding || DEFAULT_PADDING_X) +
          (cellBorder === 0 ? BORDER_X : DOUBLED_BORDER_X) * 2 +
          cellMarginRight * 2 +
          cellMarginLeft +
          getIconsWidth()
      );
    };

    const setColumnAndCellWidth = (currentCell: Cell, contentWidth: number) => {
      if (contentWidth <= Number(currentCell.column.width) && !findSavedGridColumn(state.grids, currentCell)) {
        return;
      }

      const cellCopy = currentCell;
      const newWidth = (contentWidth as GridColumWidth) > maxColumnWidth ? maxColumnWidth : contentWidth;

      const savedGridColumn = findSavedGridColumn(state.grids, cellCopy);

      const widthToApply = savedGridColumn ? savedGridColumn[cellCopy.column.id] : newWidth;

      cellCopy.column.width = widthToApply;
      cellProps.style!.width = `${widthToApply}px`;

      if (!findSavedGridColumn(state.grids, currentCell)) {
        saveGridColumn(cellCopy);
      }
    };

    useEffect(() => {
      setAllowAmendMaxWidth(cell.column.isResizing);

      if (allowAmendMaxWidth && !cell.column.isResizing) {
        const columnWidth = Number(cell.column.width);

        setMaxColumnWidth(
          (columnWidth as GridColumWidth) > GridColumWidth.MAX_WIDTH ? columnWidth : GridColumWidth.MAX_WIDTH
        );

        action.setGrids((prev) => {
          const prevCopy: GridsType = JSON.parse(JSON.stringify(prev));

          const resizedColumn = findSavedGridColumn(prevCopy, cell);

          if (resizedColumn) {
            resizedColumn[cell.column.id] = columnWidth;
          } else {
            prevCopy[gridId] = [
              ...(prevCopy[gridId] || []),
              { [cell.column.id]: cell.column.width } as SavedColumnType,
            ];
          }

          return prevCopy;
        });
      }
    }, [cell.column.isResizing, allowAmendMaxWidth]);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const cellContentWidth = getCellContentWidth();
      setColumnAndCellWidth(cell, cellContentWidth);

      const content = ref.current;
      const isOverflow = content.offsetWidth < content.scrollWidth;
      enableTooltip(isOverflow);
    }, [cell.column.width]);

    const style = useMemo(
      () => ({
        ...cellProps.style,
        ...(cell.column.applyMaxWidth && { maxWidth: cell.column.maxWidth }),
        ...(gridMode === GridModeEnum.EDITOR && {
          backgroundColor: `var(--bs-gray-500)`,
        }),
      }),
      [cell.column.applyMaxWidth, cell.column.maxWidth, cellProps.style, gridMode]
    );

    const isCellSelected = useMemo(() => selectedRangeCells[cell.id], [cell.id, selectedRangeCells]);

    const renderCell = useMemo(() => cell.render('Cell'), [cell.value]);
    const { key, ...cellPropsToPass } = cellProps;
    return (
      <Tooltip enabled={isEnableTooltip} delay={{ close: 0, open: 1000 }}>
        <TooltipTrigger
          {...cellPropsToPass}
          {...cell.getCellRangeSelectionProps()}
          key={key}
          ref={ref}
          style={style}
          className={cx(
            `${
              gridMode === GridModeEnum.EDITOR ? 'border-primary border-end-2 border-bottom-2' : 'py-1 px-2'
            } overflow-hidden`,
            styles.bodyCell,
            {
              [styles.cellSelected]: isCellSelected && gridMode !== GridModeEnum.EDITOR,
              [styles.activelySelected]: isCellActivelySelected,
              'text-grid-text-danger': cell.column.columnType !== 'String' && isNegativeNumber(cell.value),
              'text-end': isNumberType(cell.column.columnType),
              [styles.editorBodyCell]: gridMode === GridModeEnum.EDITOR && cell.column.id !== ColumnNameType.FILTER,
            }
          )}
        >
          {renderCell}
        </TooltipTrigger>
        <TooltipContent>{renderCell}</TooltipContent>
      </Tooltip>
    );
  }
);

TableBodyCell.displayName = 'TableBodyCell';
