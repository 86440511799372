import { ComponentPropsWithRef, ElementType, FC } from 'react';

import { Icon } from 'shared/Icon';

import './UIGridMenuItem.styles.scss';

type UIGridMenuItemProps = {
  label: string;
  icon: ElementType<ComponentPropsWithRef<'svg'>>;
};

export const UIGridMenuItem: FC<UIGridMenuItemProps> = ({ label, icon }) => (
  <div className="ui-grid-menu-item">
    <Icon className="ui-grid-menu-item__icon" clickable={false} SvgIcon={icon} />
    <span className="ui-grid-menu-item__label">{label}</span>
  </div>
);
