import { useSelector } from 'react-redux';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { useAppDispatch } from 'store/hooks';
import { updateActiveTypeMetadata } from 'store/shared-reducers/GridTabsDataSlice';
import { openTab } from 'store/shared-reducers/AppStateSlice';
import { selectOpenedTabs } from 'store/selectors/AppState.selector';
import { GridTabDataType } from 'store/types';
import { getSchemaTypes } from 'view/Header/store/SchemaTypesSlice';
import { LoadingStatusesEnum, schemaApi } from 'api';
import { KeyboardCode, toast } from 'utils';
import { useRequest } from 'hook/request.hook';
import { getDatasetData } from 'store/shared-reducers/DatasetDataSlice';
import { Direction, useKeyboardNavigation } from 'view/Header/components/HeaderSearch/hooks/useKeyboardNavigation';
import { selectSchemaTypes } from 'store/selectors';
import { useAppContext } from 'context';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

import { DropdownSection } from '../DropdownSection';
import styles from './styles.module.scss';
import { filterTypeHelper } from '../../utils/helpers';
import { SchemaSearchType } from '../../utils/types';

export const HeaderSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const openedTabs = useSelector(selectOpenedTabs);
  const { schemaTypes, status } = useSelector(selectSchemaTypes);
  const searchBarRef = useRef<HTMLInputElement>(null);
  const searchBarClass = 'input-search';
  const [filterTextFormatted, setFilterTextFormatted] = useState('');
  const [filterTextRaw, setFilterTextRaw] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<SchemaSearchType>();
  const [isHorizontalNavigationActive, setIsHorizontalNavigationActive] = useState(false);
  const { fetch: apiGetType } = useRequest(schemaApi.getType);

  const { activeTab, setActiveTab } = useTabParams();
  const { state, action } = useAppContext();

  const sections = [
    {
      title: t('header.tabgroups.opened'),
      items: filteredData?.OpenedTypes || [],
      isCustomType: true,
      isHide: !openedTabs.length,
    },
    {
      title: t('header.tabgroups.tables'),
      items: filteredData?.NotEmptyTypes || [],
      isLoading: status === LoadingStatusesEnum.LOADING,
      isHide: true,
    },
    {
      title: t('header.tabgroups.types'),
      items: filteredData?.FinalTypes || [],
      isLoading: status === LoadingStatusesEnum.LOADING,
    },
  ];

  useEffect(() => {
    const notTypeSectionItems = [...(filteredData?.OpenedTypes || []), ...(filteredData?.NotEmptyTypes || [])];

    if (notTypeSectionItems.length === 0) {
      const typeSectionIndex = 2;
      setSectionFocusedIndex(typeSectionIndex);
    }
  }, [filteredData]);

  const onSelectTab = (tab: GridTabDataType) => {
    apiGetType({ name: tab.id })
      .then((data) => {
        !state.showLineHider && action.setShowLineHiderAction(true);

        const metaData = data.typeList[data.rootType];
        const typeName = metaData.Name;
        const tabModule = metaData.Module.ModuleName;

        dispatch(updateActiveTypeMetadata(metaData));
        dispatch(openTab(tab));
        activeTab?.id && dispatch(getDatasetData({ type: typeName, module: tabModule }));
        setActiveTab(tab.id);

        setDropdownOpen(false);
        searchBarRef.current?.blur();
      })
      .catch(toast.error);
  };

  const handleKeysDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isEnterPressed =
      (e.code as KeyboardCode) === KeyboardCode.Enter || (e.code as KeyboardCode) === KeyboardCode.NumpadEnter;

    if ((e.code as KeyboardCode) === KeyboardCode.Tab) {
      e.preventDefault();
      incrementSectionIndex();
    }

    isHorizontalNavigationActive && handleHorizontalNavigation(e);
    handleVerticalNavigation(e);

    if ((e.code as KeyboardCode) === KeyboardCode.Escape) {
      e.preventDefault();
      setDropdownOpen(false);
      searchBarRef.current?.blur();
    }
    if (isEnterPressed && filteredData?.FinalTypes.length === 0) {
      e.preventDefault();
      const searchValue: string = searchBarRef.current?.value || '';
      onSelectTab({
        id: searchValue,
        key: searchValue,
        label: searchValue,
      });
    }

    if (isEnterPressed && focusedItemIndex > -1) {
      e.preventDefault();
      sections[activeSectionIndex].items[focusedItemIndex] &&
        onSelectTab(sections[activeSectionIndex].items[focusedItemIndex]);
    }
  };

  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && (e.code as KeyboardCode) === KeyboardCode.KeyF) {
        e.preventDefault();
        searchBarRef.current?.focus();
        setDropdownOpen(true);
      }
    };

    window.addEventListener('keydown', keyPressHandler);

    return () => window.removeEventListener('keydown', keyPressHandler);
  }, []);

  useEffect(() => {
    dispatch(getSchemaTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!dropdownOpen) {
      setFilterTextRaw('');
    }
    setFilteredData({ ...schemaTypes, OpenedTypes: openedTabs });
  }, [dropdownOpen, schemaTypes]);

  useEffect(() => {
    onFilter(filterTextRaw);
    resetFocusedItemIndex();
    setIsHorizontalNavigationActive(() => false);
  }, [filterTextRaw]);

  useEffect(() => {
    const mouseDown = (event: MouseEvent) => {
      if (!closestDropdownContainer(event)) {
        dropdownOpen && setDropdownOpen(false);
      }
    };

    const closeSearchMenu = () => {
      dropdownOpen && setDropdownOpen(false);
    };

    if (!dropdownOpen) {
      resetActiveSectionIndex();
      resetFocusedItemIndex();
    }

    document.addEventListener('mousedown', mouseDown);
    document.addEventListener('closesearchmenu', closeSearchMenu as EventListenerOrEventListenerObject);

    return () => {
      document.removeEventListener('mousedown', mouseDown);
      document.removeEventListener('closesearchmenu', closeSearchMenu as EventListenerOrEventListenerObject);
    };
  }, [dropdownOpen]);

  const closestDropdownContainer = (event: MouseEvent) => (event.target as HTMLElement).closest('.dropdownContainer');

  const handleInputClick: MouseEventHandler = (event): void => {
    event.stopPropagation();
    setDropdownOpen(true);
    resetFocusedItemIndex();
    resetActiveSectionIndex();
    setIsHorizontalNavigationActive(() => false);
  };

  const onFilter = (text: string) => {
    if (!schemaTypes.NotEmptyTypes || !schemaTypes.FinalTypes) {
      return;
    }

    const textFormatted = text.trim().replace(/ +/g, ' ');
    setFilterTextFormatted(textFormatted);

    const filteredRecords = filterTypeHelper(schemaTypes.NotEmptyTypes, textFormatted);
    const filteredTypes = filterTypeHelper(schemaTypes.FinalTypes, textFormatted);
    const filteredOpenedTypes = filterTypeHelper(openedTabs, textFormatted);
    setFilteredData({
      ...schemaTypes,
      NotEmptyTypes: filteredRecords,
      FinalTypes: filteredTypes,
      OpenedTypes: filteredOpenedTypes,
    });
  };

  const {
    focusedIndex: activeSectionIndex,
    handleKeyDown: handleHorizontalNavigation,
    resetFocusIndex: resetActiveSectionIndex,
    setFocusedIndex: setSectionFocusedIndex,
    incrementIndex: incrementSectionIndex,
  } = useKeyboardNavigation({
    itemsCount: sections?.length,
    direction: Direction.Horizontal,
  });

  const sectionItemsLength = useMemo(() => sections[activeSectionIndex].items.length, [activeSectionIndex]);

  const {
    focusedIndex: focusedItemIndex,
    defaultFocusedIndex: defaultFocusedItemIndex,
    handleKeyDown: handleVerticalNavigation,
    resetFocusIndex: resetFocusedItemIndex,
  } = useKeyboardNavigation({
    itemsCount: sectionItemsLength,
    isFirstSelected: false,
    direction: Direction.Vertical,
  });

  useEffect(() => {
    resetFocusedItemIndex();
  }, [activeSectionIndex]);

  useEffect(() => {
    if (focusedItemIndex === defaultFocusedItemIndex) {
      return;
    }

    setIsHorizontalNavigationActive(() => true);
  }, [focusedItemIndex]);

  return (
    <div className={cx('searchIconContainer', styles.searchIconContainer)}>
      <UncontrolledDropdown isOpen={dropdownOpen} className="dropdownContainer">
        <Icon
          clickable={false}
          SvgIcon={APP_ICONS.search}
          className={cx('position-absolute ms-8px top-50 pe-none', styles.searchIcon)}
          color="text-header-search-input-placeholder-icon"
        />
        <Input
          innerRef={searchBarRef}
          className={cx(
            'bg-header-search-input-background border-header-search-input-border text-header-search-input-text fs-13 ps-32px rounded',
            styles.searchBar,
            searchBarClass
          )}
          placeholder={t('header.input.placeholder') || ''}
          bsSize="sm"
          onChange={(e) => setFilterTextRaw(e.target.value)}
          onKeyDown={handleKeysDown}
          value={filterTextRaw}
          onClick={handleInputClick}
          type="search"
        />
        <DropdownToggle className="w-100" tag="div" />
        <DropdownMenu className={styles.dropdownMenu}>
          <div className="d-flex flex-row">
            {sections.map((section, sectionIndex) => {
              if (section.isHide) return null;
              const isSectionActive = sectionIndex === activeSectionIndex;

              return (
                <DropdownSection
                  key={section.title}
                  searchText={filterTextFormatted}
                  isCustomType={section.isCustomType}
                  title={section.title}
                  activeTab={activeTab?.id}
                  items={section.items}
                  onClick={onSelectTab}
                  focusedItemIndex={isSectionActive ? focusedItemIndex : -1}
                  isActive={isSectionActive}
                />
              );
            })}
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
