import { ComponentPropsWithRef, ElementType, FC, memo, useMemo, useRef } from 'react';
import cx from 'classnames';

import { AuthTypeModel, AuthTypeEnum } from 'api';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger, useDisplayTooltip } from 'shared/Tooltip';

import styles from './LoginType.module.scss';

type LoginTypeProps = AuthTypeModel & {
  className?: string;
  onSelect: (type: AuthTypeModel) => void;
};

export const LoginType: FC<LoginTypeProps> = memo(({ className, onSelect, ...authType }) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const enabledTooltip = useDisplayTooltip(textRef);
  const icon = useMemo(() => {
    const authTypeLogos = new Map<AuthTypeEnum, ElementType<ComponentPropsWithRef<'svg'>>>([
      [AuthTypeEnum.ActiveDirectory, APP_ICONS.windowsLogo],
      [AuthTypeEnum.Okta, APP_ICONS.oktaLogo],
    ]);
    return authTypeLogos.get(authType.type) || APP_ICONS.developLogo;
  }, [authType.type]);

  return (
    <Tooltip enabled={enabledTooltip}>
      <TooltipTrigger className={className}>
        <button className={cx('rounded p-3 h-100 w-100', styles.button)} onClick={() => onSelect(authType)}>
          <div className="d-flex flex-column align-items-center gap-2 overflow-hidden">
            <Icon SvgIcon={icon} clickable={false} />
            <span ref={textRef} className="w-100 text-text2 fs-13 lh-16 text-truncate">
              {authType.name}
            </span>
          </div>
        </button>
      </TooltipTrigger>
      <TooltipContent>{authType.name}</TooltipContent>
    </Tooltip>
  );
});

LoginType.displayName = 'LoginType';
