export enum ViewersMetadataType {
  PRIMARY_DASHBOARD = 'PrimaryDashboard',
  DASHBOARD = 'Dashboard',
  DETAILS = 'Details',
}

export type ViewersMetadata = {
  Name: string;
  Type?: ViewersMetadataType;
};
