import { ComponentPropsWithRef, ElementType, FC, ReactNode, useCallback, useRef } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';

import styles from './styles.module.scss';

type ChatButtonProps = {
  tooltip: string;
  disabled?: boolean;
  icon: ElementType<ComponentPropsWithRef<'svg'>>;
  children: ReactNode;
  onClick: () => void;
};

export const ChatButton: FC<ChatButtonProps> = ({ disabled = false, icon, children, onClick, tooltip }) => {
  const ref = useRef<HTMLButtonElement>(null);

  const blur = useCallback(() => {
    if (!ref.current) return;

    ref.current.blur();
  }, []);

  const onClickHandler = useCallback(() => {
    onClick();
    blur();
  }, [onClick, blur]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          innerRef={ref}
          disabled={disabled}
          className={cx(
            styles.button,
            'd-flex align-items-center bg-new-chat-btn-background gap-1 border-none text-new-chat-btn-text px-2 py-1 rounded'
          )}
          onClick={onClickHandler}
        >
          <Icon SvgIcon={icon} clickable={false} />
          <span className="fs-13 lh-16 text-capitalize">{children}</span>
        </Button>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
