import { handlerApi } from 'api';

import { CloExclusionsState } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  table: string;
  dataset: string;
  datasource: string;
  chatKey: string;
};

export const getCloExclusionsStateRequest = ({ key, table, datasource, dataset, chatKey }: RequestParamsType) =>
  handlerApi.run<CloExclusionsState>({
    key,
    table,
    dataset,
    method: 'GetCloExclusionsState',
    dataSource: datasource,
    args: {
      CurrentChat: chatKey,
    },
  });
