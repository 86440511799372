import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ColumnInstance } from 'react-table';

import { GridTabDataType } from 'store/types';
import { ConfigType, LayoutType, useDndHookType } from 'view/DragnDrop/utils/types';
import { makeStack } from 'view/DragnDrop/utils/helpers/makeStack';
import { ColumnStateType, entityApi, EntityListPanelModel } from 'api';
import { EDITOR_TABS, EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { DefaultPanelSize, GridTabEnum, SimplifiedEnum, StackEnum } from 'view/DragnDrop/utils/constants';
import { findParent } from 'view/DragnDrop/utils/helpers/findParent';
import { isAlltabsInLayout, returnDefaultConfig, toast } from 'utils';
import { useAppContext } from 'context';
import { correctContent } from 'view/DragnDrop/utils/helpers/correctContent';
import { isGridTabDataType } from 'view/DragnDrop/utils/guards/isGridTabDataType';
import { isConfigType } from 'view/DragnDrop/utils/guards/isConfigType';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';
import { getDeParsedConfig } from 'utils/helpers/getDeParsedConfig.helper';
import { ColumnNameType, DataType } from 'view/Grid/utils/types';
import { selectUser } from 'store/selectors/AppState.selector';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { filterConfigTransitionTabs } from 'view/DragnDrop/utils/helpers/filterConfigTransitionTabs';

import { useRequest } from './request.hook';

export const useDnD = ({
  tabs,
  type,
  selectedGridRow,
  passedTabs,
  setTabs,
  draggableGrid,
  isDefaultLayoutRestored,
  setIsDefaultLayoutRestored,
  schemaTypeData,
  data,
  addLayoutToSettings,
  headers,
}: useDndHookType) => {
  const { state, action } = useAppContext();
  const { action: tabItemAction, state: tabItemState } = useTabItemContext();
  const { activeEditorTab } = state;
  const viewersRequest = useRequest(entityApi.getListPanels);
  const [viewers, setViewers] = useState<GridTabDataType[]>([]);
  const [initiallyReceivedLayout, setInitiallyReceivedLayout] = useState(true);
  const [appliedNonDefaultConfig, setAppliedNonDefaultConfig] = useState(false);
  const [beforePrevConfig, setBeforePrevConfig] = useState<ConfigType>({} as ConfigType);
  const user = useSelector(selectUser);

  const viewersContainer = useRef<string[]>([]);

  useEffect(() => {
    action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);

    const configCopy = structuredClone(config);
    const configByRecord = filterConfigTransitionTabs(configCopy);

    setConfig(configByRecord);

    const transitionTabParent = findParent(configCopy, EditorTabsEnum.EDITOR_TRANSITION);

    if (transitionTabParent) {
      (transitionTabParent as ConfigType).content = (transitionTabParent as ConfigType).content.filter((tab) => {
        (tab as GridTabDataType).isActive = tab.id === EditorTabsEnum.EDITOR;

        return tab.id !== EditorTabsEnum.EDITOR_TRANSITION;
      });

      const correctedConfigCopy = correctContent(configCopy) as ConfigType;

      setConfig(correctedConfigCopy);
    }
  }, [selectedGridRow?.key]);

  const [config, setConfig] = useState<ConfigType>(returnDefaultConfig(tabs.openedTabs));
  const [showLoader, setShowLoader] = useState(false);

  const getEditorTabs = useCallback(
    (passedViewers: GridTabDataType[]) => {
      switch (true) {
        case Boolean(passedTabs):
          return [...(passedTabs?.map((id) => EDITOR_TABS[id]) || []), ...passedViewers];

        case activeEditorTab === EditorTabsEnum.NEW_ENTITY:
          return [EDITOR_TABS[EditorTabsEnum.NEW_ENTITY]];

        case activeEditorTab === EditorTabsEnum.COPY_ENTITY:
          return [EDITOR_TABS[EditorTabsEnum.COPY_ENTITY]];

        case activeEditorTab === EditorTabsEnum.SELECT_TYPE:
          return [EDITOR_TABS[EditorTabsEnum.SELECT_TYPE]];

        default: {
          const isDAGType = type === 'DAG';
          return [
            EDITOR_TABS[EditorTabsEnum.EDITOR],
            ...(isDAGType ? [EDITOR_TABS[EditorTabsEnum.DAG]] : []),
            ...passedViewers,
          ];
        }
      }
    },
    [activeEditorTab, viewers]
  );

  const processInitialLayout = (layout: LayoutType, passedContainer: string[] = []) => {
    const savedConfig = structuredClone(layout);

    const deparsedConfig = layout.length
      ? (getDeParsedConfig(savedConfig, passedContainer) as ConfigType)
      : structuredClone(config);

    const standartConfig = correctContent(deparsedConfig) as ConfigType;

    const editorStack = findParent(standartConfig, EditorTabsEnum.EDITOR) as ConfigType;

    let editorTab = {} as GridTabDataType;
    let isFirstPrimaryTab = true;

    editorStack.content.forEach((tab) => {
      const gridTypeTab = tab as GridTabDataType;

      // Handle case if Primary tab is before editor tab
      if (gridTypeTab.id === EditorTabsEnum.EDITOR && Object.keys(editorTab).length === 0) {
        // Save link to the Editor tab to deactivate it in case Primary tab is found
        editorTab = tab as GridTabDataType;
        gridTypeTab.isActive = true;
      }

      // Editor tab initially is not primary
      if (isFirstPrimaryTab && viewers.find((viewer) => viewer.id === gridTypeTab.id)?.isPrimary) {
        gridTypeTab.isActive = true;
        isFirstPrimaryTab = false;

        // Make Editor tab inactive
        editorTab.isActive = false;
      } else if (gridTypeTab.id !== EditorTabsEnum.EDITOR) {
        gridTypeTab.isActive = false;
      }
    });

    return deparsedConfig;
  };

  const getConfig = (prevConfig: ConfigType, passedContainer: string[] = []) => {
    if (data && Object.hasOwn(data, 'Layout') && initiallyReceivedLayout) {
      return processInitialLayout(data.Layout as LayoutType, passedContainer);
    }

    if (appliedNonDefaultConfig) {
      setAppliedNonDefaultConfig(false);

      return structuredClone(beforePrevConfig);
    }

    return structuredClone(prevConfig);
  };

  useEffect(() => {
    const openedTabs = getEditorTabs(viewers);
    const activeTab = openedTabs.find((tab) => tab.id === tabs.activeTab) ? tabs.activeTab : openedTabs[0]?.id;

    if (openedTabs.length) {
      openedTabs[0].isActive = true;
    }

    setTabs({ activeTab, openedTabs });

    setConfig((prev) => {
      // Use case - layout is not exist for the opened type
      if (selectedGridRow?.type && type && data && !Object.hasOwn(data, 'Layout')) {
        let editorTab = {} as GridTabDataType;

        const primaryTab = openedTabs.find((tab) => {
          if (tab.id === EditorTabsEnum.EDITOR) {
            editorTab = tab;
            tab.isActive = false;
          }

          return tab.isPrimary;
        });

        if (!primaryTab) {
          editorTab.isActive = true;
        }

        const editorStack = findParent(config, EditorTabsEnum.EDITOR) as ConfigType;

        if (editorStack) {
          editorStack.content = openedTabs;
        }

        const correctedConfig = correctContent(config) as ConfigType;
        correctedConfig.size = DefaultPanelSize.FULL_VIEW;
      }

      // Use case - layout is loaded for the opened type
      if (
        data &&
        Object.hasOwn(data, 'Layout') &&
        activeEditorTab !== EditorTabsEnum.NEW_ENTITY &&
        activeEditorTab !== EditorTabsEnum.COPY_ENTITY &&
        activeEditorTab !== EditorTabsEnum.SELECT_TYPE
      ) {
        const standartConfig = getConfig(prev, viewersContainer.current);

        initiallyReceivedLayout && setInitiallyReceivedLayout(false);

        const correctedParsedConfig = correctContent(standartConfig) as ConfigType;
        // First - find MainGrid stack
        const mainGridStack = findParent(correctedParsedConfig, GridTabEnum.MAIN) as ConfigType;

        // Second - make MainGrid tab active if it is not active
        if (mainGridStack) {
          mainGridStack.isMainGrid = true;
          mainGridStack.content.forEach((tab) => {
            const tabCopy = tab;
            (tabCopy as GridTabDataType).isActive = (tabCopy.id as GridTabEnum) === GridTabEnum.MAIN;
          });
        }

        // Add new viewers to editor stack if new viewers were added dynamically
        if (!isAlltabsInLayout(openedTabs, viewersContainer.current, selectedGridRow, type)) {
          const standartLayout = getConfig(prev);
          // Find editor stack
          const editorStack = findParent(standartLayout, EditorTabsEnum.EDITOR) as ConfigType;

          if (editorStack) {
            // Filter newly added viewers
            const newViewers = viewers.filter((viewer) => !viewersContainer.current.some((tab) => tab === viewer.id));

            const nonReceivedViewers = viewersContainer.current.filter(
              (savedViewer) => !openedTabs.some((tab) => tab.id === savedViewer)
            );

            if (nonReceivedViewers.length) {
              nonReceivedViewers.forEach((viewer) => {
                const newViewerParent = findParent(standartLayout, viewer);

                if (newViewerParent?.id === editorStack.id) {
                  editorStack.content = editorStack.content.filter((tab) => tab.id !== viewer);
                } else if (newViewerParent && isConfigType(newViewerParent)) {
                  newViewerParent.content = newViewerParent.content.filter(
                    (tab) => (tab as GridTabDataType).isActive || tab.id !== viewer
                  );
                }
              });
            }

            if (newViewers.length) {
              // Array of viewers that don't have own stack
              const newViweresOutOfStacks: GridTabDataType[] = [];

              // Find viewers that weren't dragged to own stack earlier
              newViewers.forEach((newViewer) => {
                const newViewerParent = findParent(correctedParsedConfig, newViewer.id);

                if (newViewerParent === undefined) {
                  newViweresOutOfStacks.push(newViewer);
                }
              });

              // Add new viwers to Editor stack
              editorStack.content = [...editorStack.content, ...newViweresOutOfStacks];
            }

            let editorTab = {} as GridTabDataType;
            const activeEditorStackTab = editorStack.content.find((tab) => {
              if (isGridTabDataType(tab) && tab.id === EditorTabsEnum.EDITOR) {
                editorTab = tab;
              }

              return (tab as GridTabDataType).isActive;
            });

            if (!activeEditorStackTab) {
              editorTab.isActive = true;
            }

            const correctedParsedConfigEditor = findParent(correctedParsedConfig, EditorTabsEnum.EDITOR) as ConfigType;

            if (correctedParsedConfigEditor) {
              correctedParsedConfigEditor.content = editorStack.content;
            }

            viewersContainer.current = openedTabs.map((tab) => tab.id);

            saveLayout(getParsedConfig(correctedParsedConfig, true) as LayoutType, tabItemState.pinnedHandlers);
          }
        }

        correctedParsedConfig.key = `${correctedParsedConfig.type}-${correctedParsedConfig.content[0]?.key.replace(
          /\s/g,
          ''
        )}`;
        correctedParsedConfig.id = correctedParsedConfig.key;

        // At last - return loaded layout
        return correctedParsedConfig;
      }

      // Use case - creating new entity / copy entity / selecting type
      if (
        data &&
        Object.hasOwn(data, 'Layout') &&
        (activeEditorTab === EditorTabsEnum.NEW_ENTITY ||
          activeEditorTab === EditorTabsEnum.COPY_ENTITY ||
          activeEditorTab === EditorTabsEnum.SELECT_TYPE)
      ) {
        setBeforePrevConfig(prev);
        setAppliedNonDefaultConfig(true);
        const standartConfig = returnDefaultConfig(openedTabs);

        const correctedParsedConfig = correctContent(standartConfig) as ConfigType;
        // First - Find MainGrid stack;
        const mainStack = findParent(correctedParsedConfig, GridTabEnum.MAIN) as ConfigType;
        mainStack.isMainGrid = true;

        // Second - Leave only one tab of MainGrid
        mainStack.content = [mainStack.content.find((tab) => (tab.id as GridTabEnum) === GridTabEnum.MAIN)!];

        // Third - Make MainGrid tab active if it is not active
        (mainStack.content[0] as GridTabDataType).isActive = true;

        // At last - return default layout
        return {
          type: StackEnum.COLUMN,
          key: `column-${mainStack.id}`,
          id: `column-${mainStack.id}`,
          isMainGrid: false,
          size: DefaultPanelSize.FULL_VIEW,
          content: [mainStack, makeStack(openedTabs)],
        };
      }

      if (draggableGrid === undefined) {
        return makeStack(openedTabs);
      }

      const updatedConfig = structuredClone(prev);

      const updatedContent: ConfigType[] = structuredClone(prev.content) as ConfigType[];

      const nonMainConfEl = updatedContent.filter((it) => !it.id.includes(GridTabEnum.MAIN));

      if (nonMainConfEl.length && nonMainConfEl.length === 1 && nonMainConfEl[0].type === StackEnum.STACK) {
        if (nonMainConfEl[0].content) {
          nonMainConfEl[0].content.forEach((el) => {
            const tab = openedTabs.find((openedTab) => openedTab.id === el.id);

            if (tab) {
              tab.isActive = (el as GridTabDataType).isActive;
            }
          });
        }

        nonMainConfEl[0].content = openedTabs;
        nonMainConfEl[0].id = `${nonMainConfEl[0].type}-${openedTabs[0]?.key.replace(/\s/g, '')}`;
        nonMainConfEl[0].key = nonMainConfEl[0].id;

        updatedConfig.content = updatedContent;
      }

      return updatedConfig;
    });
    setShowLoader(false);
  }, [activeEditorTab, viewers]);

  const getSortDesc = (column: ColumnInstance<DataType>) => {
    if (column.isSorted && !column.isSortedDesc) {
      return false;
    }

    if (column.isSorted && column.isSortedDesc) {
      return true;
    }

    return undefined;
  };

  const saveLayout = async (simplifiedConfig: LayoutType, handlers: string[] = [], readOnly = false) => {
    if (!schemaTypeData || !simplifiedConfig || !user) {
      return;
    }

    try {
      const { Name, Module } = schemaTypeData;
      const oldRecordKey = `${Module.ModuleName};${Name};${user.username}`;

      const columnsState = headers?.current?.reduce(
        (acc: ColumnStateType[], column) =>
          column.id !== ColumnNameType.FILTER
            ? [
                ...acc,
                {
                  Id: column.id,
                  Filter: column.filterValue || '',
                  Hidden: !column.isVisible,
                  SortDesc: getSortDesc(column),
                  SizeInPercent: parseInt(String(column.getHeaderProps().style?.width || 150), 10),
                  MaxSizeInPixels: column.maxWidth,
                  MinSizeInPixels: column.minWidth,
                  SizeFixed: !column.canResize,
                },
              ]
            : acc,
        []
      );

      const body = {
        _t: SimplifiedEnum.UI_TYPE_STATE,
        ReadOnly: readOnly,
        Layout: simplifiedConfig,
        User: user.username,
        ColumnsState: columnsState || [],
        PinnedHandlers: handlers || [],
        Type: {
          Module: Module.ModuleName,
          Name,
          _t: SimplifiedEnum.TYPE_DECL_KEY,
        },
      };

      if (data && !Object.hasOwn(data, 'Layout')) {
        addLayoutToSettings && addLayoutToSettings(simplifiedConfig);
        initiallyReceivedLayout && setInitiallyReceivedLayout(false);
      }

      await entityApi
        .saveLayout({
          data: body,
          oldRecordKey,
        })
        .then(() => {
          tabItemAction.updatePinnedHandlers(body.PinnedHandlers);
        });
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    if (isDefaultLayoutRestored === false) {
      return;
    }

    const openedTabs = getEditorTabs(viewers);
    const defaultLayout = returnDefaultConfig(openedTabs);

    const editorStack = findParent(defaultLayout, EditorTabsEnum.EDITOR) as ConfigType;

    if (editorStack) {
      editorStack.content.forEach((tab) => {
        (tab as GridTabDataType).isActive = tab.id === EditorTabsEnum.EDITOR;
      });
    }

    if (
      !(
        activeEditorTab === EditorTabsEnum.NEW_ENTITY ||
        activeEditorTab === EditorTabsEnum.COPY_ENTITY ||
        activeEditorTab === EditorTabsEnum.SELECT_TYPE
      )
    ) {
      const simpleConfig = getParsedConfig(defaultLayout, true) as LayoutType;

      saveLayout(simpleConfig);
    }

    setConfig(defaultLayout);

    setIsDefaultLayoutRestored(false);
  }, [isDefaultLayoutRestored, activeEditorTab]);

  const fetchListPanelsData = async () => {
    try {
      setShowLoader(true);
      const viewersData: EntityListPanelModel[] = await viewersRequest.fetch({
        type,
        key: selectedGridRow?.key,
      });

      let isFirstPrimaryTab = true;

      setViewers(
        viewersData?.map(({ Name, Type }) => {
          const viewer = {
            id: Name,
            key: Name,
            label: Name,
            isCloseable: false,
            isActive: isFirstPrimaryTab && !!Type,
            isCurrentlyDragging: false,
            isPrimary: Type,
          };

          if (Type && isFirstPrimaryTab) {
            isFirstPrimaryTab = false;
          }

          return viewer;
        })
      );
    } catch (e) {
      toast.error(e);
    }
  };

  return {
    viewersRequest,
    fetchListPanelsData,
    config,
    setConfig,
    showLoader,
    saveLayout,
  };
};
