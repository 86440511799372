import cx from 'classnames';
import { Placement } from '@floating-ui/react';

import { Handler } from '../Handler';
import { ExecutedHandlerInfo, HandlerItemType } from '../../types';

export type PinnedHandlerProps = {
  selectedRowKey?: string;
  handler: HandlerItemType;
  handlerExecutionInfo?: ExecutedHandlerInfo;
  tooltipPlacement: Placement;
  onHandlerClick: (handler: HandlerItemType) => void;
  onCancelHandler: (handlerId: string, isStatic?: boolean) => void;
  id?: string;
  className?: string;
  isVisibleHandler?: boolean;
  isReadOnly?: boolean;
};
export const PinnedHandlerItem = ({
  handler,
  handlerExecutionInfo,
  onHandlerClick,
  onCancelHandler,
  id,
  tooltipPlacement,
  className,
  isVisibleHandler = false,
  isReadOnly = false,
}: PinnedHandlerProps) => {
  const isExecutionDisabled = handler.isDisable || isReadOnly;

  return (
    <button
      id={id}
      className={cx('d-flex justify-content-start fs-13 border-0 align-items-center', className)}
      onClick={() => onHandlerClick(handler)}
      disabled={isExecutionDisabled}
    >
      <Handler
        handler={handler}
        handlerExecutionInfo={handlerExecutionInfo}
        tooltipPlacement={tooltipPlacement}
        onCancelHandler={onCancelHandler}
        isVisibleHandler={isVisibleHandler}
        isReadOnly={isReadOnly}
      />
    </button>
  );
};
