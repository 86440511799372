import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import { getErrorMessage, LoadingStatusesEnum, storageApi, StorageDatasetModel } from 'api';
import { DataType } from 'view/Grid/utils/types';
import { DatasetDataStateType, DatasetRequestParamsType } from 'store/types';
import { toast, isString } from 'utils';

const initialState: DatasetDataStateType = {
  datasetData: {
    rowsData: [],
    status: LoadingStatusesEnum.INITIAL,
    error: '',
  },
};

export const getDatasetData = createAsyncThunk<
  StorageDatasetModel[],
  DatasetRequestParamsType,
  { rejectValue: string }
>('getDatasetData', async ({ type, module }: DatasetRequestParamsType, { rejectWithValue }) => {
  try {
    return storageApi.getDatasets({ type, module });
  } catch (e) {
    toast.error(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

export const selectDataset = createAsyncThunk<string, string, { rejectValue: unknown }>(
  'selectDataset',
  async (dataset: string) =>
    new Promise<string>((resolve) => {
      setTimeout(() => {
        resolve(dataset);
      }, 100);
    })
);

const isExistDataset = (datasets: DataType[], datasetName: string = ''): boolean => {
  if (datasets.length === 0) {
    return false;
  }

  return datasets.some((d: DataType) => d.Name === datasetName);
};

const DatasetDataSlice = createSlice({
  name: 'datasetData',
  initialState: initialState,
  reducers: {
    cleanDatasetData(state) {
      state.datasetData = initialState.datasetData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDatasetData.fulfilled, (state, action: PayloadAction<StorageDatasetModel[]>) => {
        const formatedRowsData = action.payload.map((el) => {
          if (el.Name === null) {
            el.Name = '';
          }

          return el;
        });

        state.datasetData.rowsData = formatedRowsData;

        if (
          (!state.datasetData.activeDatasetName && isExistDataset(action.payload)) ||
          !isExistDataset(action.payload, state.datasetData.activeDatasetName)
        ) {
          state.datasetData.activeDatasetName = '';
        }

        state.datasetData.status = LoadingStatusesEnum.SUCCESS;
      })
      .addCase(selectDataset.fulfilled, (state, action: PayloadAction<string>) => {
        state.datasetData.activeDatasetName = action.payload;
        state.datasetData.status = LoadingStatusesEnum.SUCCESS;
      })
      .addMatcher(isAnyOf(getDatasetData.rejected, selectDataset.pending), (state) => {
        state.datasetData.status = LoadingStatusesEnum.LOADING;
        state.datasetData.error = '';
      })
      .addMatcher(isAnyOf(getDatasetData.rejected, selectDataset.rejected), (state, action) => {
        state.datasetData.status = LoadingStatusesEnum.ERROR;
        state.datasetData.error = isString(action.payload) ? action.payload : '';
      });
  },
});

export const DatasetDataSliceReducer = DatasetDataSlice.reducer;
