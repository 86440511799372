import { FC, memo } from 'react';

type SelectedRowsCounterType = {
  areAllRecordsSelected: boolean;
  selectedRowsCount: number;
};

export const SelectedRowsCounter: FC<SelectedRowsCounterType> = memo(({ areAllRecordsSelected, selectedRowsCount }) => {
  if (selectedRowsCount === 0) {
    return null;
  }

  return (
    <div className="text-rows-count-color p-1 bg-rows-counter-background fs-13">
      <>{areAllRecordsSelected ? 'All' : `Selected Rows: ${selectedRowsCount}`}</>
    </div>
  );
});

SelectedRowsCounter.displayName = 'SelectedRowsCounter';
