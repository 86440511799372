import { FC, useMemo, useState } from 'react';
import Spreadsheet from 'react-spreadsheet';
import cx from 'classnames';
import XLSX from 'xlsx-js-style';

import { KeyboardCode } from 'utils';

import { UIExcelSheetBar } from './UIExcelSheetBar';
import { UIExcelCornerIndicator } from './UIExcelCornerIndicator';

import './UIExcel.scss';

export type SheetData = {
  value: string;
  readOnly?: boolean;
}[][];

export type UIExcelProps = {
  workbook: XLSX.WorkBook;
  className?: string;
  darkMode?: boolean;
  readOnly?: boolean;
  minRowLength?: number;
};

export const UIExcel: FC<UIExcelProps> = ({ workbook, className, darkMode, readOnly, minRowLength = 100 }) => {
  const [currentSheet, setCurrentSheetIndex] = useState<number>(0);
  const currentSheetName = workbook.SheetNames[currentSheet];

  const data = useMemo(
    () =>
      XLSX.utils.sheet_to_json(workbook.Sheets[currentSheetName], { header: 1, defval: '' }).map((row) => {
        const cells = Object.values(row as object).map((cell) => ({
          value: cell,
          readOnly: Boolean(readOnly),
        }));

        const additionalCellsLength = COLUMN_LABELS.length - cells.length;
        if (additionalCellsLength > 0) {
          const additionalCells = Array.from({ length: additionalCellsLength }, () => ({
            value: '',
            readOnly: true,
          }));

          return [...cells, ...additionalCells];
        }

        return cells;
      }),
    [currentSheet]
  );

  const rowLabels = Array(minRowLength > data.length ? minRowLength : data.length)
    .fill(0)
    .map((_, i) => `${i + 1}`);

  const handleSheetChange = (index: number) => {
    setCurrentSheetIndex(index);
  };

  const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
    // This Excel view have to be literally readonly.
    // But when you paste the content into cell, it become editable. Library haven't support such case yet.
    // We have to prevent the paste and cut behaviour for this view.
    if ((e.ctrlKey || e.metaKey) && (e.code === KeyboardCode.KeyV || e.code === KeyboardCode.KeyX)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className={cx('ui-excel', className)}>
        <Spreadsheet
          data={data}
          columnLabels={COLUMN_LABELS}
          rowLabels={rowLabels}
          CornerIndicator={UIExcelCornerIndicator}
          darkMode={darkMode}
          onKeyDown={handleKeyDown}
        />
      </div>

      <UIExcelSheetBar
        sheetNames={workbook.SheetNames}
        currentSheet={currentSheet}
        onCurrentSheetChange={handleSheetChange}
      />
    </>
  );
};

const COLUMN_LABELS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
