import { useId, useMemo } from 'react';

import { Grid } from 'view/Grid';
import { EntityPanelViewOfContainerType, EntityPanelViewOfNameType } from 'api';
import { isArray, isArrayOfStrings, isObject } from 'utils';

import { getColumns, getRows } from './helpers';

type TableViewerProps = {
  ColHeaders?: EntityPanelViewOfNameType[] | null | string[];
  ValuesContainer?: EntityPanelViewOfContainerType | null | undefined;
  RowCount?: number;
};

export const TableViewer = ({ ColHeaders, ValuesContainer }: TableViewerProps) => {
  const id = useId();
  const { columns, rows } = useMemo(() => {
    if (isArrayOfStrings(ColHeaders) && isObject(ValuesContainer) && isArray(ValuesContainer.Array)) {
      return {
        columns: getColumns(ColHeaders, ValuesContainer.Array),
        rows: getRows(ColHeaders, ValuesContainer.Array),
      };
    }

    return { columns: [], rows: [] };
  }, [ColHeaders, ValuesContainer]);

  return (
    <div className="h-100 overflow-auto">
      <Grid gridId={id} columns={columns} data={rows} />
    </div>
  );
};
