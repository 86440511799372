import { CellProps, ColumnInstance } from 'react-table';

import { TableBodyCell } from 'view/Editor/components/TableBody/TableBodyCell';
import { DataType } from 'view/Grid/utils/types';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { TransitionIcon } from 'view/Editor/components/TableBody/TableBodyCell/TransitionIcon';
import { CellSchemaType } from 'view/Editor/helpers/getCellProps.helper';
import './style.scss';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';

// TODO Refactor type
export interface SimpleVectorCellColumnProps extends ColumnInstance<DataType> {
  enumSelector?: string;
  schemaType: CellSchemaType;
  keyName?: string;
  keyModuleName?: string;
  path: string[];
}

export interface SimpleVectorCellProps extends CellProps<DataType> {
  column: SimpleVectorCellColumnProps;
}

export const SimpleVectorCell = ({ value, cell, row, column }: SimpleVectorCellProps) => {
  const { action, state } = useEditorState();
  const fullPath = [...column.path, row.index?.toString()];
  const isReadOnly = state.isReadOnly || Boolean(cell.column.isReadOnly);

  const handleCellChange = (updatedValue: unknown) => {
    action.updateEditorData({
      path: fullPath,
      updateValue: updatedValue,
    });
  };

  return (
    <div title={dataToString(value)} className="simple-vector-cell">
      <TableBodyCell
        isVector
        key={fullPath.toString()}
        path={fullPath}
        enumSelector={column?.enumSelector}
        keyName={column?.keyName}
        isRequired={Boolean(cell.column.isRequired)}
        isReadOnly={isReadOnly}
        keyModuleName={column?.keyModuleName}
        schemaType={column?.schemaType}
        elementName={cell.column.Header as string}
        originalValue={value}
        valueType={cell.column.columnType}
        prefixElement={<TransitionIcon data={value} />}
        onChange={handleCellChange}
      />
    </div>
  );
};
