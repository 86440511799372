import { FC, MouseEvent, ReactNode, useContext, useId, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';

import { CarouselContext } from 'shared/Carousel/Context';
import { ButtonCode } from 'utils';

import styles from './styles.module.scss';

type CarouselItemType = {
  children: ReactNode;
  useFullWidth?: boolean;
};

export const CarouselItem: FC<CarouselItemType> = ({ children, useFullWidth = false }) => {
  const previousWidth = useRef<number>(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const id = useId();
  const { activeId, registerItem, unregisterItem, setActiveId } = useContext(CarouselContext);

  const handleCarouselItemClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isActive || (event.button as ButtonCode) !== ButtonCode.MAIN) return;

    setActiveId(id);
  };

  useLayoutEffect(() => {
    const getWidth = () => previousWidth.current || getBoundingWidth();

    const getBoundingWidth = () => {
      if (!ref.current) return 0;
      return ref.current.getBoundingClientRect().width;
    };

    registerItem(id, {
      id,
      getBoundingWidth,
      getWidth,
    });

    return () => {
      unregisterItem(id);
    };
  }, [id]);

  const isActive = id === activeId;

  return (
    <div
      data-carousel-item-id={`carouse-item-${id}`}
      ref={ref}
      className={cx(
        styles.carouselItem,
        'border-editor-grid-border rounded flex-shrink-0',
        {
          'border-editor-grid-secondary-border': isActive,
        },
        {
          [styles.flexGrow]: useFullWidth,
          [styles.nonActiveBorder]: !isActive,
        }
      )}
      onClick={handleCarouselItemClick}
    >
      {children}
    </div>
  );
};
