import cx from 'classnames';
import { FC } from 'react';

import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { GridTabDataType } from 'store/types';
import { EditorContent } from 'view/EditorContent';

import { EditorUpdateType } from 'view/EditorData/type';

import { DropBox } from '../DropBox';
import { ConfigType } from '../utils/types';
import { DropBoxEnum, StackEnum } from '../utils/constants';
import styles from './styles.module.scss';

export type DraggableEditorContainerProps = {
  isLoading?: boolean;
  type: string;
  selectedGridRow?: SelectedGridRowType;
  showToolbar?: boolean;
  stackRef: ConfigType | GridTabDataType;
  isSingleTabStackDragging: boolean;
  configType: string;
  onUpdate?: (data?: EditorUpdateType) => void;
  isDashboardType?: boolean;
};

export const DraggableEditorContainer: FC<DraggableEditorContainerProps> = ({
  isLoading,
  type,
  selectedGridRow,
  showToolbar,
  stackRef,
  isSingleTabStackDragging,
  configType,
  onUpdate,
  isDashboardType = false,
}) => (
  <>
    {(configType as StackEnum) === StackEnum.STACK && isSingleTabStackDragging && (
      <DropBox position={DropBoxEnum.SINGLE} stackRef={stackRef} />
    )}
    <div
      className={cx(
        'h-100',
        'w-100',
        (configType as StackEnum) === StackEnum.STACK && isSingleTabStackDragging ? styles.zeroWidth : 'w-100'
      )}
    >
      <EditorContent
        isLoading={isLoading}
        type={type}
        selectedGridRow={selectedGridRow}
        showToolbar={showToolbar}
        stackRef={stackRef as ConfigType}
        onUpdate={onUpdate}
        isDashboardType={isDashboardType}
      />
    </div>
  </>
);
