import { fetchEventSource } from '@microsoft/fetch-event-source';

import { deserialize, serialize, toString } from 'utils';
import {
  convertCancelAllHandlersDtoToParams,
  convertHandlerDtoToParams,
  convertRunResultDtoToParams,
  convertRunStatusDtoToParams,
  EntityPanelViewOfModel,
} from 'api';
import { getBaseUrl } from 'api/helpers/generateBaseUrl';
import { generateHeaders } from 'api/helpers/generateHeaders';

import { HandlerGetParamsRequestDto, HandlerOptionsType } from '../data/handler.data';
import { HandlerGetParamsModel } from '../model/handler.model';
import { axiosMain } from '../axios';

export type HandlerApi = {
  run<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  runTasks<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  runTasksStatus<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  runTasksResult<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  postRun<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  postRunStream<TResult>(params: HandlerGetParamsRequestDto, options?: HandlerOptionsType<TResult>): void;
  runView(data: HandlerGetParamsRequestDto): Promise<EntityPanelViewOfModel>;
  cancel<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
  cancelAll<TResult>(data: HandlerGetParamsRequestDto): Promise<TResult>;
};

export const handlerApi: HandlerApi = {
  async run<TResult>(dto: HandlerGetParamsRequestDto): Promise<TResult> {
    const params = convertHandlerDtoToParams(dto);
    const res = await axiosMain.post<TResult>('/handlers/post_run', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  async runTasks<ExecutedHandlerRunTaskDataType>(
    dto: HandlerGetParamsRequestDto
  ): Promise<ExecutedHandlerRunTaskDataType> {
    const params = convertHandlerDtoToParams(dto);
    const res = await axiosMain.post<ExecutedHandlerRunTaskDataType>('/tasks/run', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  async runTasksStatus<ExecutedHandlerStatusData>(dto: HandlerGetParamsRequestDto): Promise<ExecutedHandlerStatusData> {
    const params = {
      ...convertRunStatusDtoToParams(dto),
      dataset: '',
    };

    const res = await axiosMain.post<ExecutedHandlerStatusData>('/tasks/run/status', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  async runTasksResult<ExecutedHandlerResultData>(dto: HandlerGetParamsRequestDto): Promise<ExecutedHandlerResultData> {
    const params = convertRunResultDtoToParams(dto);

    const res = await axiosMain.post<ExecutedHandlerResultData>('/tasks/run/result', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  async postRun<TResult>(dto: HandlerGetParamsRequestDto): Promise<TResult> {
    const params = convertHandlerDtoToParams(dto);
    const res = await axiosMain.post<TResult>('/handlers/post_run_async', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  postRunStream<TResult>(dto: HandlerGetParamsRequestDto, options?: HandlerOptionsType<TResult>): void {
    let controller = new AbortController();
    const baseUrl = getBaseUrl();

    const { signal, ...params } = {
      ...convertHandlerDtoToParams(dto),
      arguments: dto.args ? serialize(dto.args) : undefined,
    };

    const cancelStream = () => {
      controller.abort();
      controller = new AbortController();
      options?.onError?.();
    };

    signal?.addEventListener('abort', cancelStream);

    const queryParams = Object.keys(params).reduce((searchParams: URLSearchParams, key: string) => {
      const value = toString(params[key as keyof typeof params]);
      if (value) searchParams.append(key, value);
      return searchParams;
    }, new URLSearchParams());

    let lastValue: TResult | null = null;

    fetchEventSource(`${baseUrl}/handlers/run/?${queryParams.toString()}`, {
      signal: controller.signal,
      openWhenHidden: true,
      headers: {
        ...generateHeaders(),
        'Content-Type': 'application/octet-stream',
      },
      onmessage(event: { data: string }) {
        const value = deserialize<TResult>(event.data);
        lastValue = value;
        options?.onProgress?.(value);
      },
      onerror() {
        cancelStream();
        throw new Error('Something went wrong with event source.');
      },
      onclose() {
        console.log('onClose');
        options?.onClose?.(lastValue);

        if (!lastValue) {
          throw new Error('Cannot get data from the stream');
        }
      },
    });
  },

  async runView(dto: HandlerGetParamsRequestDto): Promise<EntityPanelViewOfModel> {
    const params = convertHandlerDtoToParams(dto);
    const res = await axiosMain.get<HandlerGetParamsModel<{ ViewOf: EntityPanelViewOfModel }>>('/entity/run_view', {
      params: {
        ...params,
        arguments: dto.args ? serialize(dto.args) : undefined,
      },
    });

    return res.data.Result.ViewOf;
  },

  async cancel<ExecutedHandlerStatusData>(dto: HandlerGetParamsRequestDto): Promise<ExecutedHandlerStatusData> {
    const params = convertRunStatusDtoToParams(dto);

    const res = await axiosMain.post<ExecutedHandlerStatusData>('/tasks/run/cancel', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },

  async cancelAll<ExecutedHandlerStatusData>(dto: HandlerGetParamsRequestDto): Promise<ExecutedHandlerStatusData> {
    const params = convertCancelAllHandlersDtoToParams(dto);

    const res = await axiosMain.post<ExecutedHandlerStatusData>('/tasks/run/cancel_all', serialize(params), {
      signal: dto.signal,
      headers: { 'Content-Type': 'application/json' },
    });

    return res.data;
  },
};
