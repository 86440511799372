import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { SchemaTypeModel, SettingsRecordModel, storageApi } from 'api';

import { useRequest } from 'hook/request.hook';
import { LayoutType } from 'view/DragnDrop/utils/types';
import { selectUser } from 'store/selectors/AppState.selector';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';

export function useViewSettings(schema?: SchemaTypeModel) {
  const { action } = useTabItemContext();
  const user = useSelector(selectUser);
  const { fetch: getSettings, state, hasInitFetch } = useRequest(storageApi.getTypeViewSettings);
  const [defaultSettings, setDefaultSettings] = useState<SettingsRecordModel>({
    LayoutSettings: {
      HideEditor: false,
    },
    ReadOnly: false,
    UseCache: false,
  });

  const [updater, setUpdater] = useState(false);

  const fetch = useCallback(() => {
    if (!schema || !user) return;

    action.resetPinnedHandlers();
    action.resetIsReadOnly();

    const { Name, Module } = schema;
    getSettings({ type: Name, module: Module.ModuleName, user: user.username }).then((res) => {
      const { PinnedHandlers, ReadOnly: resReadOnly } = res || {};

      resReadOnly && setDefaultSettings((prev) => ({ ...prev, ReadOnly: resReadOnly }));

      action.updatePinnedHandlers(PinnedHandlers || []);

      action.updateIsReadOnly(resReadOnly || false);
    });
  }, [schema, hasInitFetch]);

  useEffect(fetch, [schema]);

  const addLayoutToSettings = (layout: LayoutType) => {
    setDefaultSettings({
      ...defaultSettings,
      Layout: layout,
    });
    setUpdater((prev) => !prev);
  };

  const typeViewSettings = useCallback(() => ({ ...defaultSettings, ...state }), [updater, state]);

  return {
    typeViewSettings: typeViewSettings(),
    addLayoutToSettings,
    isInitFetch: !hasInitFetch,
  };
}
