import { FC, RefObject, useState } from 'react';
import cx from 'classnames';
import {
  DateTimePicker,
  DateTimePickerProps,
  PickersLayoutProps,
  DateCalendar,
  PickersActionBar,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { Box, Divider, PopperPlacementType } from '@mui/material';
import { DateOrTimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';

import { DateFormatEnum, DateFormatUtility } from 'utils';
import { APP_ICONS } from 'utils/icons';

import { UIInput, UIInputProps } from './UIInput.component';
import { UITimePicker } from './UIInputTime.component';
import { usePicker } from './hooks';

export type UIInputDateTimeProps<T = Dayjs> = Pick<
  DateTimePickerProps<T>,
  'value' | 'onChange' | 'format' | 'ampm' | 'showDaysOutsideCurrentMonth' | 'className' | 'open' | 'onClose' | 'onOpen'
> & {
  pickerRef?: RefObject<HTMLDivElement>;
  pickerButtonRef?: RefObject<HTMLButtonElement>;
  disabledPickerButton?: boolean;
  pickerPlacement?: PopperPlacementType;
  inputProps?: UIInputProps;
};

export type UIDateTimePickerLayoutProps<T = Dayjs> = PickersLayoutProps<T | null, T, DateOrTimeViewWithMeridiem>;

export const UIInputDateTime: FC<UIInputDateTimeProps> = ({
  pickerRef,
  pickerButtonRef,
  pickerPlacement = 'bottom-end',
  disabledPickerButton,
  className,
  ampm = false,
  format = DateFormatEnum.DATE_TIME,
  showDaysOutsideCurrentMonth = true,
  inputProps,
  ...rest
}) => {
  const classes = cx('ui-input-date-time', className);

  return (
    <DateTimePicker<Dayjs>
      format={format}
      ampm={ampm}
      className={classes}
      showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
      slots={{
        textField: UIInput,
        layout: UIDateTimePickerLayout,
        openPickerIcon: APP_ICONS.calendar,
      }}
      slotProps={{
        popper: {
          ref: pickerRef,
          placement: pickerPlacement,
          sx: {
            '& .MuiDateCalendar-root': {
              width: 230,
              height: '100%',
            },
          },
        },
        openPickerButton: {
          ref: pickerButtonRef,
          disabled: disabledPickerButton,
        },
        textField: {
          placeholder: '',
          ...inputProps,
        },
        actionBar: {
          actions: ['today'],
        },
      }}
      {...rest}
    />
  );
};

const UIDateTimePickerLayout: FC<UIDateTimePickerLayoutProps> = ({
  value,
  onClose,
  onChange,
  onCancel,
  onClear,
  onAccept,
}) => {
  const { getStringWithRequiredDigits } = usePicker();

  const initialValue = value || DateFormatUtility.create().toInstance();

  const [date, setDate] = useState<Dayjs | null | undefined>(initialValue);
  const [hours, setHours] = useState<string>(getStringWithRequiredDigits(initialValue?.hour()));
  const [minutes, setMinutes] = useState<string>(getStringWithRequiredDigits(initialValue?.minute()));
  const [seconds, setSeconds] = useState<string>(getStringWithRequiredDigits(initialValue?.second()));

  const isDateTimeDefined = date || hours || minutes || seconds;

  const handleDateChange = (e: Dayjs | null | undefined) => {
    setDate(e);
  };

  const handleAcceptDateTime = () => {
    const updatedDateTime = isDateTimeDefined
      ? DateFormatUtility.create(`${date?.format(DateFormatEnum.DATE)} ${hours}:${minutes}:${seconds}`).toInstance()
      : null;

    onChange(updatedDateTime);
    onClose();
  };

  return (
    <Box
    // sx={{
    //   '& .MuiDateCalendar-root': {
    //     width: 230,
    //     height: '100%',
    //   },
    // }}
    >
      <DateCalendar
        value={date}
        onChange={handleDateChange}
        showDaysOutsideCurrentMonth
        dayOfWeekFormatter={(_, weekday) => weekday.format('dd')}
      />
      <PickersActionBar
        actions={['today']}
        onSetToday={() => handleDateChange(DateFormatUtility.create().toInstance())}
        onAccept={onAccept}
        onCancel={onCancel}
        onClear={onClear}
      />
      <Divider sx={{ border: '1px solid var(--bs-gray-800)' }} />
      <UITimePicker
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        setHours={setHours}
        setMinutes={setMinutes}
        setSeconds={setSeconds}
        handleClosePicker={onClose}
        handleSubmit={handleAcceptDateTime}
      />
    </Box>
  );
};
