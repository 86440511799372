import { FC } from 'react';
import { Button } from 'reactstrap';
import { CellProps } from 'react-table';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { DataType } from 'view/Grid/utils/types';
import { EntityPanelViewOfDataType, handlerApi, HandlerGetParamsRequestDto } from 'api';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { useRequest } from 'hook/request.hook';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';

import { getHandlerParams } from './utils/helpers';
import { getFormattedValue } from '../../utils/helpers';
import styles from './DataHandlerCell.module.scss';

type DataHandlerCellProps = CellProps<DataType, EntityPanelViewOfDataType>;

export const DataHandlerCell: FC<DataHandlerCellProps> = ({ value }) => {
  // TODO: Move it into the interceptor
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);

  const { isLoading, fetch } = useRequest<string, HandlerGetParamsRequestDto>(handlerApi.run);
  const formattedText = getFormattedValue(value);

  const executeHandler = () => {
    const handlerData = getHandlerParams(value);
    if (!handlerData) return;

    fetch({ ...handlerData, dataset, dataSource: environment });
  };

  return (
    <Button
      className={cx('bg-transparent border-0 p-0 d-flex align-items-center gap-1 mw-100', styles.button)}
      disabled={isLoading}
      onClick={executeHandler}
    >
      <Icon className={styles.icon} SvgIcon={APP_ICONS.play} clickable={false} />
      <span className="fs-13 text-truncate">{formattedText}</span>
    </Button>
  );
};
