import { useCallback, useRef, useState } from 'react';

const widthCalculationHelper = (mouseMoveEvent: React.MouseEvent<HTMLDivElement>, minWidth: number) => {
  const maxPaneWidth = window.innerWidth - minWidth;
  const rightPaneWidth = window.innerWidth - mouseMoveEvent.clientX;

  switch (true) {
    case rightPaneWidth < minWidth:
      return minWidth;
    case rightPaneWidth > maxPaneWidth:
      return maxPaneWidth;
    default:
      return rightPaneWidth;
  }
};

export const useVerticalSplit = (paneMinWidth = 400) => {
  const rightBlockRef = useRef<HTMLDivElement | null>(null);

  const [isResizing, setIsResizing] = useState(false);
  const [rightBlockWidth, setRightBlockWidth] = useState<number>();

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const runResize = useCallback(
    (mouseMoveEvent: React.MouseEvent<HTMLDivElement>) => {
      if (isResizing) {
        const newWidth = widthCalculationHelper(mouseMoveEvent, paneMinWidth);
        setRightBlockWidth(newWidth);
      }
    },
    [isResizing, paneMinWidth]
  );

  return {
    rightBlockRef,
    rightBlockWidth,
    startResizing,
    stopResizing,
    runResize,
  };
};
