import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type AvatarWithIconProps = {
  isActive?: boolean;
  color?: 'primary' | 'secondary';
  className?: string;
  children: ReactNode;
};

export const AvatarWithIcon: FC<AvatarWithIconProps> = ({
  isActive = true,
  color = 'primary',
  children,
  className,
}) => (
  <div className={cx(styles.circle, 'position-relative', className)}>
    {children}
    {isActive && (
      <Icon
        className={cx('position-absolute text-white', styles.mask, {
          [styles.maskPrimary]: color === 'primary',
          [styles.maskSecondary]: color === 'secondary',
        })}
        SvgIcon={APP_ICONS.mask}
        clickable={false}
      />
    )}
  </div>
);
