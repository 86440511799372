import BigNumber from 'bignumber.js';

export type ModuleTypeModel = {
  ModuleName: string;
};

export type NestedTypeModel = {
  Module: ModuleTypeModel;
  Name: string;
};

export type ValueTypeModel =
  | 'String'
  | 'Int'
  | 'Double'
  | 'Bool'
  | 'Long'
  | 'Date'
  | 'Time'
  | 'DateTime'
  | 'Variant'
  | 'Key'
  | 'TimeElement'
  | 'DateTimeElement'
  | 'Data'
  | 'Binary'
  | 'Dict'
  | 'Mixed';

export type SimpleViewModel = string | number | boolean | undefined | null | BigNumber;

export type DictionaryDataValueModel = Record<string, SimpleViewModel>;

export enum DictionaryDataPath {
  Key = 'key',
  Value = 'value',
}

export type DictionaryDataModel = {
  [DictionaryDataPath.Key]: string;
  [DictionaryDataPath.Value]: DictionaryDataValueModel | DictionaryDataValueModel[];
};

export type DictionaryModel = Record<string, DictionaryDataModel[]>;

export type FlatViewModel = {
  [key: string]: SimpleViewModel | FlatViewModel | FlatViewModel[] | SimpleViewModel[];
};
