import { useSelector } from 'react-redux';

import { useComponentsMapper } from 'shared/Hooks/useComponentsMapper';
import { AppContext } from 'context';
import { selectApplicationName } from 'store/selectors/AppState.selector';

import './index.scss';
import { TabItemProvider } from 'containers/TabItemContent/context';
import { SSEProvider } from 'containers/SSEProvider';

export const App = () => {
  const applicationName = useSelector(selectApplicationName);

  const { Header, Main } = useComponentsMapper();

  if (applicationName) document.title = applicationName; // TODO: check other way to set title

  return (
    <div className="d-flex flex-column bg-primary content position-relative h-100">
      <AppContext>
        <SSEProvider>
          <TabItemProvider>
            <Header />
            <Main />
          </TabItemProvider>
        </SSEProvider>
      </AppContext>
    </div>
  );
};
