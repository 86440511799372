import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { LogsFlatViewItem } from 'view/Logs/components/LogsList/LogsFlatViewItem';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { externalStorage } from 'shared/ExternalStorage';
import { LogsEventDataType, LogsLevelEnum } from 'view/Logs/utils/types';

import styles from './styles.module.scss';

type LogsFlatViewListProps = {
  logsListData: LogsEventDataType[];
};

export const LogsFlatViewList = ({ logsListData }: LogsFlatViewListProps) => {
  const { t } = useTranslation();
  const bottomRef = useRef<null | HTMLDivElement>(null);

  const [isAutoScroll, setIsAutoScroll] = useState(externalStorage.getIsLogsAutoScroll());

  useEffect(() => {
    isAutoScroll &&
      bottomRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
  }, [isAutoScroll, logsListData]);

  const changeIsAutoScroll = () => {
    setIsAutoScroll(!isAutoScroll);
    externalStorage.setIsLogsAutoScroll(!isAutoScroll);
  };

  return (
    <>
      <div className="d-flex justify-content-end w-100">
        <Tooltip>
          <TooltipTrigger>
            <Icon
              SvgIcon={APP_ICONS.scrollAutomatically}
              className={cx(styles.autoScrollIcon, {
                [styles.activeAutoScrollIcon]: isAutoScroll,
              })}
              onClick={changeIsAutoScroll}
            />
          </TooltipTrigger>
          <TooltipContent>{t('scroll.scrollDownAutomatically') || ''}</TooltipContent>
        </Tooltip>
      </div>
      <div className="overflow-auto mb-1">
        {logsListData.map((item) => {
          const isErrorItem = [LogsLevelEnum.ERROR, LogsLevelEnum.FATAL, LogsLevelEnum.CRITICAL].includes(
            item.Level as LogsLevelEnum
          );

          return (
            <div
              key={uuid()}
              className={cx(styles.logsItem, {
                [styles.errorItem]: isErrorItem,
              })}
            >
              <LogsFlatViewItem listItem={item} isErrorItem={isErrorItem} />
            </div>
          );
        })}

        <div ref={bottomRef} />
      </div>
    </>
  );
};
