import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';

export const findParent = (passedConfig: ConfigType, searchKey: string): ConfigType | GridTabDataType | undefined => {
  if (passedConfig.content?.some((el) => el.key === searchKey)) {
    return passedConfig;
  }

  for (let i = 0; i < passedConfig.content?.length; i += 1) {
    const iChild = passedConfig.content[i] as ConfigType;

    const result = findParent(iChild, searchKey);

    if (result) {
      return result;
    }
  }

  return undefined;
};
