import { FC, ReactNode, useMemo, useState } from 'react';

import { UIInput } from 'ui';
import { ExtendedModalIcon } from 'view/Editor/components/TableBody/TableBodyCell/ExtendedModalIcon';
import { ParseGenericValue } from 'view/Editor/helpers/parseGenericValue.helper';
import { GenericModal, GenericModalSubmitDataType } from 'view/GenericModal';

export type GenericKeySelectProps = {
  value?: string;
  error?: boolean;
  isReadOnly?: boolean;
  prefixElement?: ReactNode;
  onChange: (value: string) => void;
};

export const GenericKeySelect: FC<GenericKeySelectProps> = ({ value, isReadOnly, error, prefixElement, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { initialGenericType, initialGenericRecordKey } = useMemo(
    () => ({
      initialGenericType: ParseGenericValue.toDomainType(value),
      initialGenericRecordKey: ParseGenericValue.toDomainRecord(value),
    }),
    [value]
  );

  const handleOpenKeyModal = () => {
    setIsModalOpen(true);
  };

  const handleGenericModalSubmit = (params: GenericModalSubmitDataType) => {
    const dtoGenericType = ParseGenericValue.toDtoType(params.type);
    const updatedGenericValue = ParseGenericValue.toDtoValue(dtoGenericType, params.recordKey);
    onChange(updatedGenericValue);
  };

  return (
    <>
      <UIInput
        readOnly
        variant="transparent"
        value={value}
        error={error}
        suffixElement={<ExtendedModalIcon onClick={handleOpenKeyModal} disable={isReadOnly} />}
        prefixElement={prefixElement}
      />
      {isModalOpen && (
        <GenericModal
          initialGenericType={initialGenericType}
          initialGenericRecordKey={initialGenericRecordKey}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleGenericModalSubmit}
        />
      )}
    </>
  );
};
