import { FC } from 'react';

import i18n from 'i18n';
import { InfoIcon, UIButton, UIButtonVariantType, UiModal, UiModalProps } from 'ui';

import './ReloadApplicationModal.scss';

export type ReloadApplicationModalProps = Pick<UiModalProps, 'isOpen' | 'size' | 'onClose'> & {
  okType?: UIButtonVariantType;
  okText?: string;
  onOk?: () => void;
};

export const ReloadApplicationModal: FC<ReloadApplicationModalProps> = ({
  isOpen,
  okType = 'primary',
  onOk,
  onClose,
}) => (
  <UiModal
    className="reload-application-modal"
    isOpen={isOpen}
    size="sm"
    isClosable={false}
    title={i18n.t('reloadApplicationModal.title')}
    onClose={onClose}
    defaultWidth={370}
    minHeightConstraints={150}
    defaultHeight={150}
    backdrop={true}
  >
    <UiModal.Body>
      <div className="reload-application-modal__content">
        <div className="reload-application-modal__icon">
          <InfoIcon size="Full" />
        </div>
        {i18n.t('reloadApplicationModal.content')}
      </div>
    </UiModal.Body>
    <UiModal.Footer>
      <div className="reload-application-modal__nav">
        <UIButton variant={okType} onClick={onOk}>
          {i18n.t('buttons.ok')}
        </UIButton>
      </div>
    </UiModal.Footer>
  </UiModal>
);
