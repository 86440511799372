import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { LogsLevelEnum, LogsListViewEnum } from 'view/Logs/utils/types';
import { useLogsPanel } from 'view/Logs/hooks/useLogsPanel';

import { LogsHeaderIcons } from './components/LogsHeaderIcons';
import { LogsHeaderSearch } from './components/LogsHeaderSearch';
import { logsHeaderViewIcons } from './utils/constants';
import styles from './styles.module.scss';

type LogsHeaderProps = {
  activeListView: LogsListViewEnum;
  onChangeListView: (listView: LogsListViewEnum) => void;
  searchValue: string;
  onChangeSearchValue: Dispatch<SetStateAction<string>>;
  onClearLogsList: () => void;
  onSetActiveFilters: (levels: LogsLevelEnum[]) => void;
  activeFilters: LogsLevelEnum[];
};

export const LogsHeader = ({
  activeListView,
  onChangeListView,
  searchValue,
  onChangeSearchValue,
  onClearLogsList,
  onSetActiveFilters,
  activeFilters,
}: LogsHeaderProps) => {
  const { t } = useTranslation();
  const { togglePanel } = useLogsPanel();

  return (
    <>
      <div className={cx('d-flex justify-content-between py-1 px-2', styles.container)}>
        <div className={styles.iconsContainer}>
          {logsHeaderViewIcons.map(({ id, SvgIcon, tooltipContent, viewStatus, iconStyles }) => (
            <Tooltip key={id}>
              <TooltipTrigger>
                <Icon
                  SvgIcon={SvgIcon}
                  className={cx(styles.iconButton, iconStyles, { [styles.activeIcon]: activeListView === viewStatus })}
                  onClick={() => onChangeListView(viewStatus)}
                />
              </TooltipTrigger>
              <TooltipContent>{t(tooltipContent)}</TooltipContent>
            </Tooltip>
          ))}
        </div>

        <Icon SvgIcon={APP_ICONS.cross} className="ms-2" onClick={() => togglePanel(false)} />
      </div>

      <div className="d-flex align-items-center justify-content-between p-2">
        <LogsHeaderSearch searchValue={searchValue} onChangeSearchValue={onChangeSearchValue} />
        <LogsHeaderIcons
          activeListView={activeListView}
          onClearLogsList={onClearLogsList}
          onSetActiveFilters={onSetActiveFilters}
          activeFilters={activeFilters}
        />
      </div>
    </>
  );
};
