import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HandlerStatisticType, LogsDataType, ProgressMessageType } from 'store/types';
import { LogsListViewEnum } from 'view/Logs/utils/types';

const initialState: { logsData: LogsDataType } = {
  logsData: {
    listView: LogsListViewEnum.FLAT_VIEW,
    progressMessagesData: [],
    handlerStatisticsData: [],
    isFlatListScrollDown: true,
    storeSearchValue: '',
    isAllflatViewFilters: true,
  },
};

const LogsDataSlice = createSlice({
  name: 'logsData',
  initialState: initialState,
  reducers: {
    changeLogsListView(state, action: PayloadAction<LogsListViewEnum>) {
      state.logsData.listView = action.payload;
    },
    updateProgressMessagesData(state, action: PayloadAction<ProgressMessageType[]>) {
      state.logsData.progressMessagesData = action.payload;
    },
    updateHandlerStatisticsData(state, action: PayloadAction<HandlerStatisticType[]>) {
      state.logsData.handlerStatisticsData = action.payload;
    },
    cleanLogsViewArray(state) {
      switch (state.logsData.listView) {
        case LogsListViewEnum.FLAT_VIEW:
          state.logsData.progressMessagesData = [];
          break;
        case LogsListViewEnum.HANDLERS:
          state.logsData.handlerStatisticsData = [];
          break;
        default:
          break;
      }
    },
    setListSearchValue(state, action: PayloadAction<string>) {
      state.logsData.storeSearchValue = action.payload;
    },
  },
});

export const LogsDataSliceReducer = LogsDataSlice.reducer;
export const {
  changeLogsListView,
  updateProgressMessagesData,
  updateHandlerStatisticsData,
  cleanLogsViewArray,
  setListSearchValue,
} = LogsDataSlice.actions;
