import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StorageEntityModel } from 'api';
import { EditorToolbarJsonEdit } from 'view/Editor/components/EditorToolbar/EditorToolbarJsonEdit';
import { Toolbar } from 'shared/Toolbar/Toolbar';
import { IconWithTooltip } from 'shared/IconWithTooltip/IconWithTooltip';
import { APP_ICONS } from 'utils/icons';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { EditorWaringIcon } from 'view/Editor/validation/component/EditorWarningIcon';
import { EditorErrorTooltip } from 'view/Editor/validation/component/EditorErrorTooltip';

export const EditorToolbar: FC = () => {
  const { t } = useTranslation();
  const { state, action, event } = useEditorState();
  const errorList = Object.keys(state.validationError);

  const handleSubmit = (data: StorageEntityModel) => event?.onSubmit?.({ data });

  const renderError = () => {
    const errorMsg = `${t('editor.validation.containErrors')} [${errorList.length}]`;

    return (
      <Toolbar.Group>
        <EditorErrorTooltip errors={[errorMsg]}>
          <EditorWaringIcon />
        </EditorErrorTooltip>
      </Toolbar.Group>
    );
  };

  return (
    <Toolbar>
      {errorList.length > 0 && renderError()}
      <Toolbar.Group>
        {event?.onRefresh && (
          <IconWithTooltip onClick={event.onRefresh} SvgIcon={APP_ICONS.refresh} tooltip={t('editorToolbar.refresh')} />
        )}
        {!state.isDisableKeyFields && (
          <EditorToolbarJsonEdit isReadOnly={state.isReadOnly} onSubmit={handleSubmit} data={state.editorData} />
        )}
      </Toolbar.Group>
      <Toolbar.Divider />
      <Toolbar.Group>
        <div ref={action.setEditorToolbarRef} />
      </Toolbar.Group>
    </Toolbar>
  );
};
