import cx from 'classnames';
import { DragDropContext, Draggable, Droppable, type DropResult } from '@hello-pangea/dnd';
import { type FC, ReactElement } from 'react';

import { GridTabDataType } from 'store/types';
import { ButtonCode, handleMouseDown } from 'utils';
import { useElementsDimension } from 'utils/hooks';
import { DnDDraggable } from 'view/DragnDrop/Draggable';
import { DropBox } from 'view/DragnDrop/DropBox';
import { ConfigType } from 'view/DragnDrop/utils/types';
import { DropBoxEnum } from 'view/DragnDrop/utils/constants';

import { TabsDropdown } from '../TabsDropdown/TabsDropdown';
import { TabItem, TabItemProps } from '../TabItem';
import styles from './TabsDefault.module.scss';

export type TabsDefaultProps = {
  dndTabs?: boolean;
  isChatTabs?: boolean;
  openedTabs: GridTabDataType[];
  activeTab?: string;
  stackRef?: ConfigType | GridTabDataType;
  renderRightContent?: ReactElement;
  isCurrentlyDragging?: boolean;
  isEditorTabs?: boolean;
  isDashboardTabs?: boolean;
  isDragging: boolean;
  getTabProps: (tab: GridTabDataType) => TabItemProps;
  onDragEnd: (result: DropResult) => void;
  onDragStart: () => void;
  onSetDraggingTab: (tab: GridTabDataType) => void;
  onReorderTabs: (tabs: GridTabDataType[], startIndex: number, endIndex: number) => void;
};

export const TabsDefault: FC<TabsDefaultProps> = (props) => {
  const {
    dndTabs,
    isChatTabs,
    openedTabs,
    activeTab,
    renderRightContent,
    stackRef,
    isEditorTabs = false,
    isDashboardTabs = false,
    isDragging,
    isCurrentlyDragging,
    getTabProps,
    onReorderTabs,
    onDragEnd,
    onDragStart,
    onSetDraggingTab,
  } = props;

  const { elementsRef, dropdownRef, visibleElements, hiddenElements, isDropdownButtonVisible } = useElementsDimension({
    elements: openedTabs,
    reorderOptions: {
      activeElementIndex: openedTabs.findIndex((tab) => tab.key === activeTab),
      isCurrentlyDragging,
      onReorderElements: onReorderTabs,
    },
  });

  return (
    <div
      className={cx(styles.tabsContainer, {
        [styles.dashboardContainer]: isDashboardTabs,
      })}
    >
      {!dndTabs && !isChatTabs && <div className={styles.topLine} />}
      {isEditorTabs && <div className={styles.topEditorLine} />}
      <div
        ref={elementsRef}
        className={cx('d-flex flex-nowrap', styles.header, {
          [styles.container]: openedTabs?.length,
          [styles.editorContainer]: isEditorTabs,
        })}
      >
        {dndTabs ? (
          <DropBox position={DropBoxEnum.TOP} stackRef={stackRef}>
            {visibleElements?.map((tab, index) => {
              const tabProps = getTabProps(tab);
              return (
                <DnDDraggable
                  className={cx(styles.draggable, {
                    [styles.draggableActive]: tabProps.isActive,
                  })}
                  key={tab.id}
                  index={index}
                  openedTabs={openedTabs}
                  onCloseTab={tabProps.onCloseTab}
                  stackRef={stackRef}
                >
                  <TabItem {...tabProps} />
                </DnDDraggable>
              );
            })}
          </DropBox>
        ) : (
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="visibleTabs" direction="horizontal">
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className="d-flex w-100">
                  {visibleElements?.map((tab, index) => (
                    <Draggable draggableId={tab.id} index={index} key={tab.id}>
                      {(provided) => {
                        const tabProps = getTabProps(tab);
                        return (
                          <div
                            className={cx('w-100', styles.draggable, {
                              [styles.draggableActive]: tabProps.isActive,
                            })}
                            ref={provided?.innerRef}
                            {...provided?.draggableProps}
                            {...provided?.dragHandleProps}
                            onMouseDown={(event) =>
                              handleMouseDown(event, {
                                [ButtonCode.MIDDLE]: tabProps.onCloseTab,
                              })
                            }
                          >
                            <TabItem {...tabProps} />
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {isDropdownButtonVisible && !isDragging && (
          <div ref={dropdownRef} className="d-flex align-items-center">
            <TabsDropdown
              dndTabs={dndTabs}
              tabs={hiddenElements}
              getTabProps={getTabProps}
              onSetDraggingTab={onSetDraggingTab}
            />
          </div>
        )}

        {renderRightContent && renderRightContent}
      </div>
    </div>
  );
};
