import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionType, UiModal, UiSelect } from 'ui';
import { SchemaTypeDeclareHandlersModel } from 'api';
import { Button } from 'shared/Button';
import { RunContentHandlerParams } from 'view/Handlers';

type ContentHandlersModalProps = {
  handlers: SchemaTypeDeclareHandlersModel[];
  isOpen: boolean;
  onClose: () => void;
  onApply: (params: Pick<RunContentHandlerParams, 'handler'>) => Promise<void>;
};

export const ContentHandlersModal: FC<ContentHandlersModalProps> = ({ handlers, isOpen, onClose, onApply }) => {
  const { t } = useTranslation();
  const [selectedOption, selectOption] = useState<OptionType | null>(null);
  const options: OptionType[] = useMemo(
    () =>
      handlers.map((handler) => ({
        label: handler.Label,
        value: handler.Name,
      })),
    [handlers]
  );

  const applyHandler = () => {
    const selectedHandler = handlers.find((handler) => handler.Name === selectedOption?.value);
    if (!selectedHandler) throw new Error('Cannot apply empty handler');

    onApply({ handler: selectedHandler });
    onClose();
  };

  return (
    <UiModal isOpen={isOpen} size="sm" title={t('chat.contentHandlersModal.title') ?? ''} onClose={onClose}>
      <UiModal.Body className="overflow-auto">
        <UiSelect
          className="bg-gray-500 border-gray-600"
          menuPortalTarget={document.body}
          value={selectedOption}
          options={options}
          onChange={selectOption}
        />
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={t('buttons.cancel')} variant="secondary" onClick={onClose} />
        <Button title={t('buttons.apply')} disabled={!selectedOption} onClick={applyHandler} />
      </UiModal.Footer>
    </UiModal>
  );
};
