import { LogsListViewEnum } from 'view/Logs/utils/types';
import { APP_ICONS } from 'utils/icons';

import styles from '../styles.module.scss';

export const logsHeaderViewIcons = [
  {
    id: 'logsBurgerLargeIcon',
    SvgIcon: APP_ICONS.burgerLarge,
    tooltipContent: 'logs.flatView',
    viewStatus: LogsListViewEnum.FLAT_VIEW,
    iconStyles: styles.burgerLargeIcon,
  },
  {
    id: 'logsRunIcon',
    SvgIcon: APP_ICONS.run,
    tooltipContent: 'logs.handlers',
    viewStatus: LogsListViewEnum.HANDLERS,
    iconStyles: styles.runIcon,
  },
  {
    id: 'logsSystemIcon',
    SvgIcon: APP_ICONS.system,
    tooltipContent: 'logs.notifications',
    viewStatus: LogsListViewEnum.NOTIFICATIONS,
    iconStyles: styles.systemIcon,
  },
];
