import React, { ComponentPropsWithRef, ElementType, FC } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { UiDropdown, UiDropdownItem, UITooltip } from 'ui';

import styles from './TableToolbar.module.scss';

export type TableToolbarChildItemProps = {
  key: string;
  title: string;
  onClick?: () => void;
};

export type TableToolbarItemProps = {
  key: string;
  tooltipContent?: string;
  icon: ElementType<ComponentPropsWithRef<'svg'>>;
  isDisabled?: boolean;
  children?: TableToolbarChildItemProps[];
  onClick?: () => void;
};

export type TableToolbarProps = {
  items: TableToolbarItemProps[];
};

export const TableToolbar: FC<TableToolbarProps> = ({ items }) => (
  <div className={styles.TableToolbar}>
    {items?.map((item) => (
      <div
        key={item.key}
        className={cx(styles.TableToolbar__item)}
        onClick={() => !item?.isDisabled && item.onClick?.()}
      >
        {item?.children ? (
          <UiDropdown
            container={'body'}
            tag={'div'}
            toggleButton={
              <UITooltip title={item.tooltipContent} isDisable={!item.tooltipContent}>
                <div aria-hidden="true" className="d-flex align-items-center">
                  <Icon SvgIcon={item.icon} disable={item.isDisabled} />
                </div>
              </UITooltip>
            }
          >
            {!item?.isDisabled &&
              item?.children?.map((childMenuItem) => (
                <UiDropdownItem key={childMenuItem.key} onClick={() => childMenuItem.onClick?.()}>
                  {childMenuItem.title}
                </UiDropdownItem>
              ))}
          </UiDropdown>
        ) : (
          <UITooltip title={item.tooltipContent} isDisable={!item.tooltipContent}>
            <div aria-hidden="true" className="d-flex align-items-center">
              <Icon SvgIcon={item.icon} disable={item.isDisabled} />
            </div>
          </UITooltip>
        )}
      </div>
    ))}
  </div>
);
