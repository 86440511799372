import { FC } from 'react';

import { UiDropdown, UiDropdownItem, UiDropdownMenu } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { GridTabDataType } from 'store/types';

import { TabItem, TabItemProps } from '../TabItem';
import styles from './TabsDropdown.module.scss';

type TabsDropdownProps = {
  dndTabs?: boolean;
  tabs: GridTabDataType[];
  getTabProps: (tab: GridTabDataType) => TabItemProps;
  onSetDraggingTab: (tab: GridTabDataType) => void;
};

export const TabsDropdown: FC<TabsDropdownProps> = ({ tabs, getTabProps, dndTabs, onSetDraggingTab }) => (
  <UiDropdown
    className="mx-0_5"
    tag="span"
    toggleButton={<Icon SvgIcon={APP_ICONS.more} className="bg-primary px-2_5 py-1_5" />}
    renderMenu={() => (
      <UiDropdownMenu className={styles.dropdownMenu}>
        <div className={styles.dropdownMenuContainer}>
          {tabs.map((tab) => (
            <UiDropdownItem key={tab.id} className="p-1">
              <div className="flex-grow-1" onMouseDown={() => dndTabs && onSetDraggingTab(tab)}>
                <TabItem isHiddenTab {...getTabProps(tab)} />
              </div>
            </UiDropdownItem>
          ))}
        </div>
      </UiDropdownMenu>
    )}
  />
);
