import { Filters, SortingRule } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { ArrayElement, OpenToastType, ToastType } from 'utils';
import { AppStateTokens } from 'store/types/AppStateType';
import { LogsLevelEnum } from 'view/Logs/utils/types';
import { initLogsFilters } from 'view/Logs/components/LogsHeader/components/LogsHeaderFilter/utils/constants';
import { AuthTypeModel } from 'api';

import { EditorSavedDataType, EditorTabDataType, ExternalStorage, StorageKeyEnum } from './utils/types';
import { StorageDictionary } from './utils/helpers';

export const externalStorage: ExternalStorage = {
  getVersion: () => localStorage.getItem(StorageKeyEnum.VERSION),
  setVersion: (version: string) => localStorage.setItem(StorageKeyEnum.VERSION, version),
  setFiltersByKey(key: string, filters: Filters<DataType>) {
    StorageDictionary.createByKey<ArrayElement<Filters<DataType>>>(StorageKeyEnum.FILTERS)
      .updateOrDeleteByValue(key, filters)
      .save();
  },
  getFiltersByKey(key: string) {
    const dictionary = StorageDictionary.createByKey<ArrayElement<Filters<DataType>>>(StorageKeyEnum.FILTERS);
    return dictionary.get(key) ?? [];
  },
  clearFilterByKey(key: string) {
    const dictionary = StorageDictionary.createByKey<Filters<DataType>>(StorageKeyEnum.FILTERS);
    dictionary.delete(key);
    dictionary.save();
  },
  setSortByKey: (key: string, sorting: Array<SortingRule<DataType>>) => {
    StorageDictionary.createByKey<SortingRule<DataType>>(StorageKeyEnum.SORTING)
      .updateOrDeleteByValue(key, sorting)
      .save();
  },
  getSortByKey(key: string) {
    const dictionary = StorageDictionary.createByKey<SortingRule<DataType>>(StorageKeyEnum.SORTING);
    return dictionary.get(key) ?? [];
  },
  clearSortByKey(key: string) {
    const dictionary = StorageDictionary.createByKey<SortingRule<DataType>>(StorageKeyEnum.SORTING);
    dictionary.delete(key);
    dictionary.save();
  },
  clear() {
    Object.values(StorageKeyEnum).forEach((key: string) => localStorage.removeItem(key));
  },
  setTokens(tokens: AppStateTokens) {
    localStorage.setItem(StorageKeyEnum.TOKENS, JSON.stringify(tokens));
  },
  getTokens() {
    const data = localStorage.getItem(StorageKeyEnum.TOKENS)?.trim();
    return data ? (JSON.parse(data) as AppStateTokens) : undefined;
  },
  removeTokens() {
    localStorage.removeItem(StorageKeyEnum.TOKENS);
  },
  setAuthType(type: AuthTypeModel) {
    localStorage.setItem(StorageKeyEnum.AUTH_TYPE, JSON.stringify(type));
  },
  getAuthType() {
    const data = localStorage.getItem(StorageKeyEnum.AUTH_TYPE)?.trim();
    return data ? (JSON.parse(data) as AuthTypeModel) : undefined;
  },
  removeAuthType() {
    localStorage.removeItem(StorageKeyEnum.AUTH_TYPE);
  },
  addToast({ type, id, ...rest }) {
    const data = localStorage.getItem(StorageKeyEnum.TOAST_DATA)?.trim();
    const parsedData: OpenToastType = data ? JSON.parse(data) : {};
    const dataType = type in parsedData ? parsedData[type] : {};
    const newData = { ...parsedData, [type]: { ...dataType, [id!]: rest } };

    localStorage.setItem(StorageKeyEnum.TOAST_DATA, JSON.stringify(newData).trim());
  },
  getToasts() {
    const data = localStorage.getItem(StorageKeyEnum.TOAST_DATA)?.trim();
    return data ? (JSON.parse(data) as OpenToastType) : {};
  },
  removeToast(type: ToastType, id: string) {
    const data: OpenToastType = JSON.parse(localStorage.getItem(StorageKeyEnum.TOAST_DATA)!.trim());

    if (data && data[type] && data[type][id]) delete data[type][id];

    localStorage.setItem(StorageKeyEnum.TOAST_DATA, JSON.stringify(data));
  },
  removeAllTypeToasts(type: ToastType) {
    const data = JSON.parse(localStorage.getItem(StorageKeyEnum.TOAST_DATA)!.trim());
    const newData = { ...data, [type]: {} };

    localStorage.setItem(StorageKeyEnum.TOAST_DATA, JSON.stringify(newData));
  },
  removeAllToasts() {
    localStorage.setItem(StorageKeyEnum.TOAST_DATA, '');
  },
  setParamsViewers(viewerName: string, data: EditorSavedDataType) {
    const paramsViewersData = localStorage.getItem(StorageKeyEnum.PARAMS_VIEWERS)?.trim();
    const innerData = paramsViewersData ? JSON.parse(paramsViewersData) : {};
    const newData = { ...innerData, [viewerName]: data };

    localStorage.setItem(StorageKeyEnum.PARAMS_VIEWERS, JSON.stringify(newData));
  },
  getParamsViewers() {
    const data = localStorage.getItem(StorageKeyEnum.PARAMS_VIEWERS)?.trim();
    return data ? (JSON.parse(data) as EditorTabDataType) : {};
  },
  removeParamsViewer(viewerName: string) {
    const paramsViewersData = localStorage.getItem(StorageKeyEnum.PARAMS_VIEWERS)?.trim();
    const innerData: EditorTabDataType = paramsViewersData ? JSON.parse(paramsViewersData) : {};

    if (viewerName in innerData) delete innerData[viewerName];

    localStorage.setItem(StorageKeyEnum.PARAMS_VIEWERS, JSON.stringify(innerData));
  },
  removeAllParamsViewers() {
    localStorage.setItem(StorageKeyEnum.PARAMS_VIEWERS, '');
  },
  getSessionId() {
    return localStorage.getItem(StorageKeyEnum.SESSION_ID);
  },
  setSessionId(sessionId: string) {
    localStorage.setItem(StorageKeyEnum.SESSION_ID, sessionId);
  },
  removeSessionId() {
    localStorage.removeItem(StorageKeyEnum.SESSION_ID);
  },
  setIsLogsPanel(isOpen: boolean) {
    localStorage.setItem(StorageKeyEnum.IS_LOGS_PANEL, JSON.stringify(isOpen));
  },
  getSelectedLlm() {
    return sessionStorage.getItem(StorageKeyEnum.SELECTED_LLM);
  },
  setSelectedLlm(llm: string) {
    sessionStorage.setItem(StorageKeyEnum.SELECTED_LLM, llm);
  },
  getIsLogsPanel() {
    const data = localStorage.getItem(StorageKeyEnum.IS_LOGS_PANEL)?.trim() as string;
    return data ? (JSON.parse(data) as boolean) : false;
  },
  setIsLogsAutoScroll(isAutoScroll: boolean) {
    localStorage.setItem(StorageKeyEnum.IS_LOGS_AUTO_SCROLL, JSON.stringify(isAutoScroll));
  },
  getIsLogsAutoScroll() {
    const data = localStorage.getItem(StorageKeyEnum.IS_LOGS_AUTO_SCROLL)?.trim() as string;
    return data ? (JSON.parse(data) as boolean) : false;
  },
  setLogsFilters(filters: string[]) {
    localStorage.setItem(StorageKeyEnum.LOGS_FILTERS, JSON.stringify(filters));
  },
  getLogsFilters() {
    const data = localStorage.getItem(StorageKeyEnum.LOGS_FILTERS)?.trim() as string;
    return data ? (JSON.parse(data) as LogsLevelEnum[]) : initLogsFilters;
  },
};
