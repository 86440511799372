import { FC } from 'react';

import { EntityPanelModel, EntityPanelViewOfModel, StorageEntityModel } from 'api';
import { isArrayViewOfType } from 'utils';
import { cleanTypeHelper } from 'utils/helpers/cleanType.helper';
import { EditorContentToolbar } from 'view/EditorContent/EditorContentToolbar/EditorContentToolbar';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

import { EditorViewer } from 'view/EditorData/EditorViewer';

import { ListContentContainer } from './components';

type DefaultViewerProps = {
  showToolbar?: boolean;
  data: EntityPanelModel['ViewOf'];
  viewerKey?: string;
  viewerType?: string;
  onTransition?: (data: TransitionRecordType) => void;
  onRefresh?: () => void;
};

export const DefaultViewer: FC<DefaultViewerProps> = ({
  viewerKey,
  viewerType,
  data,
  showToolbar,
  onTransition,
  onRefresh,
}) => {
  if (isArrayViewOfType(data)) {
    return (
      <>
        {showToolbar && <EditorContentToolbar panelData={data} onRefresh={onRefresh} />}
        <ListContentContainer viewerKey={viewerKey} viewerType={viewerType} data={data} onTransition={onTransition} />
      </>
    );
  }

  const editorType = cleanTypeHelper((data as EntityPanelViewOfModel)?._t);
  if (editorType) {
    return <EditorViewer editorData={data as StorageEntityModel} recordType={editorType} onTransition={onTransition} />;
  }

  return null;
};
