import BigNumber from 'bignumber.js';

import { SchemaTypeElementsModel } from 'api';
import { isBool, isNumber, ValueTypesEnum } from 'utils';

import { ValueWithConditionType } from '../types';
import { getTypeBySchema } from './getTypeBySchema.helper';

export function validateValueBySchemaType({ value }: ValueWithConditionType, schema: SchemaTypeElementsModel): boolean {
  const schemaType = getTypeBySchema(schema);
  switch (schemaType) {
    case ValueTypesEnum.Int:
    case ValueTypesEnum.Double:
    case ValueTypesEnum.Long:
      return Boolean(BigNumber.isBigNumber(value) || isNumber(value));
    case ValueTypesEnum.Bool:
      return isBool(value);
    case ValueTypesEnum.Date:
    case ValueTypesEnum.Time:
    case ValueTypesEnum.DateTime:
      return Boolean(value);
    default:
      return Boolean(value);
  }
}
