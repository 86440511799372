import { SyntheticEvent, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import { UIGridCorner } from '../UIGridCorner/UIGridCorner.component';
import './UIGridHorizontalScroll.styles.scss';

type UIGridHorizontalScrollProps = {
  className?: string;
};

export const UIGridHorizontalScroll = <TData,>({ className }: UIGridHorizontalScrollProps) => {
  const {
    isHiddenHorizontalScroll,
    api,
    setBodyScrollLeft,
    setHeaderScrollLeft,
    setFooterScrollLeft,
    setHiddenHorizontalScroll,
  } = useGrid<TData>();
  const { ref, width = 0 } = useResizeObserver();
  const totalColumnsWidth = api.getTotalSize();

  useEffect(() => {
    setHiddenHorizontalScroll(totalColumnsWidth < width);
  }, [width, totalColumnsWidth]);

  const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
    const offsetLeft = event.currentTarget.scrollLeft;

    setHeaderScrollLeft(offsetLeft);
    setBodyScrollLeft(offsetLeft);
    setFooterScrollLeft(offsetLeft);
  };

  return (
    <div
      ref={ref}
      className={cx('ui-grid-horizontal-scroll', className, {
        'ui-grid-horizontal-scroll--hidden': isHiddenHorizontalScroll,
      })}
    >
      <div className="ui-grid-horizontal-scroll__bar ui-grid-horizontal-scrollbar" onScroll={onScroll}>
        <div style={{ width: totalColumnsWidth }} className="ui-grid-horizontal-scrollbar__content" />
      </div>
      <UIGridCorner className="ui-grid-horizontal-scroll__corner" direction="horizontal" />
    </div>
  );
};
