import { RefObject, FC } from 'react';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { SortOrderEnum } from 'view/Grid/components/TableHeader/utils/types';
import { UiDropdown, UiDropdownItem } from 'ui';
import { useBoolean } from 'utils/hooks';

import { sortDropdownItems } from './utils/SortDropdown.constants';
import styles from './SortDropdown.module.scss';

type SortDropdownProps = {
  gridRef: RefObject<HTMLDivElement>;
  handleChangeSorting: (sortValue: SortOrderEnum) => void;
};

export const SortDropdown: FC<SortDropdownProps> = ({ gridRef, handleChangeSorting }) => {
  const [isDropdownOpen, { toggle }] = useBoolean(false);

  const handleDropdownToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
  };

  const handleSelectItem = (sortValue: SortOrderEnum) => () => {
    handleChangeSorting(sortValue);
  };

  return (
    <UiDropdown
      isOpen={isDropdownOpen}
      toggle={handleDropdownToggle}
      size="sm"
      tag="span"
      toggleButton={<Icon clickable={false} SvgIcon={APP_ICONS.burger} />}
      container={gridRef}
    >
      {sortDropdownItems.map(({ SvgIcon, value, label }) => (
        <UiDropdownItem key={value} onClick={handleSelectItem(value)} className={styles.dropdownItem}>
          <Icon SvgIcon={SvgIcon} />
          <span className="ps-2">{label}</span>
        </UiDropdownItem>
      ))}
    </UiDropdown>
  );
};
