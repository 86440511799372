import { useMemo } from 'react';

import { useGrid } from '../../hooks';
import { getOffsetByMode } from '../../helpers';
import './UIGridResizeIndicator.styles.scss';

export const UIGridResizeIndicator = <TData,>() => {
  const { api } = useGrid<TData>();
  const resizeMode = api.options.columnResizeMode!; // TODO: remove ! operator.
  const columnSizing = api.getState().columnSizingInfo;
  const isResizing = columnSizing.isResizingColumn;

  const position = useMemo(() => {
    if (!columnSizing.isResizingColumn) return { left: 0, offset: 0 };

    const resizedColumn = api.getColumn(columnSizing.isResizingColumn);
    if (!resizedColumn) return { left: 0, offset: 0 };

    return {
      offset: getOffsetByMode(resizeMode, resizedColumn, columnSizing.deltaOffset || 0),
      left: resizedColumn.getStart() + resizedColumn.getSize(),
    };
  }, [columnSizing, resizeMode]);

  if (!isResizing) return null;

  return (
    <div
      className="ui-grid-resize-indicator"
      style={{ left: position.left, transform: `translateX(${position.offset}px)` }}
    />
  );
};
