import { FC } from 'react';

export type QueryBuilderHeaderProps = {
  title: string;
};

export const QueryBuilderHeader: FC<QueryBuilderHeaderProps> = ({ title }) => (
  <div className="d-flex">
    <span className="text-secondary">{title}</span>
  </div>
);
