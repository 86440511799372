import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type TextareaSelectedFilesProps = {
  selectedFiles: File[];
  handleRemoveFile: (fileName: string) => void;
};

export const TextareaSelectedFiles = ({ selectedFiles, handleRemoveFile }: TextareaSelectedFilesProps) => (
  <div className={styles.filesBlock}>
    {selectedFiles.map((item, index) => (
      <div key={index} className={styles.file}>
        <span className={styles.fileName}>{item.name}</span>
        <Icon SvgIcon={APP_ICONS.close} onClick={() => handleRemoveFile(item.name)} />
      </div>
    ))}
  </div>
);
