import { CellValue } from 'react-table';
import BigNumber from 'bignumber.js';

import { ValueType } from 'store/types';
import {
  ValueTypesEnum,
  formatNumberValue,
  isArray,
  isArrayFlatViewType,
  isArraySimpleViewType,
  isNumberType,
  isObject,
  isSimpleViewType,
  toString,
  isBool,
  DateFormatUtility,
  DateFormatEnum,
} from 'utils';

export type ValueFormatterProps = {
  value: unknown;
  valueType?: ValueType['Type'];
  isVector?: boolean;
  moduleName?: string;
  formatValue?: string;
};

export class ValueFormatterService {
  static complexValueFormatter({
    value,
    valueType,
    isVector,
    moduleName,
    formatValue,
  }: ValueFormatterProps): string | number | boolean | undefined {
    switch (true) {
      case isVector:
        return this.vectorValueFormat({
          value,
          moduleName,
          valueType,
          formatValue,
        });

      case isObject(value) && !BigNumber.isBigNumber(value):
        return this.flatValueFormat(value);

      default:
        return this.simpleValueFormatter(value, valueType, formatValue, true);
    }
  }

  static simpleValueFormatter(
    value: CellValue,
    type?: ValueType['Type'],
    format?: string,
    isShowMiliseconds?: boolean,
    maxValue?: number
  ): string | number | boolean | undefined {
    if (isSimpleViewType(value) || BigNumber.isBigNumber(value)) {
      switch (true) {
        case type === ValueTypesEnum.String:
          return typeof value === 'undefined' ? value : toString(value);
        case isNumberType(type):
          return formatNumberValue(value, format, type, maxValue);
        case type === ValueTypesEnum.Bool:
          return isBool(value) ? value : undefined;
        case type === ValueTypesEnum.Date:
          return value ? DateFormatUtility.create(String(value)).format(DateFormatEnum.DATE) : undefined;
        case type === ValueTypesEnum.DateTime:
          return value ? DateFormatUtility.create(String(value)).format(DateFormatEnum.DATE_TIME) : undefined;
        case type === ValueTypesEnum.Time:
          return value
            ? DateFormatUtility.create(String(value), DateFormatEnum.TIME_MS).format(DateFormatEnum.TIME)
            : undefined;
        default:
          if (typeof value === 'undefined') return value;

          return toString(value);
      }
    }
    return '';
  }

  private static vectorValueFormat({
    value,
    valueType,
    formatValue,
    moduleName,
  }: Pick<ValueFormatterProps, 'value' | 'moduleName' | 'formatValue' | 'valueType'>): string {
    if (moduleName) {
      return `${moduleName ?? ''}...`;
    }

    if (isArraySimpleViewType(value)) {
      return value.map((val) => this.simpleValueFormatter(val, valueType, formatValue, true)).join('; ');
    }

    if (isArrayFlatViewType(value)) {
      const uniqValue = value.filter((it) => it && it);

      return valueType === ValueTypesEnum.Key
        ? uniqValue.map((val) => val._t).join('; ') // array generic key
        : uniqValue.map((val) => this.flatValueFormat(val)).join('; ');
    }

    return '';
  }

  private static flatValueFormat(obj: object) {
    const values = Object.keys(obj).map((key) => {
      const value = obj[key as keyof object];
      if (isArray(value)) return this.arrayValueToString(value);
      if (isObject(value)) return this.objectValueToString(value);

      return value as string;
    });

    return this.getShortValue(values);
  }

  private static arrayValueToString<T>(value: T[]) {
    const firstProps = value.map((subObj) =>
      isObject(subObj) ? (Object.values(subObj)[0] as string) : (subObj as string)
    );
    const restCount = value.slice(1).length;
    if (isObject(firstProps[0])) {
      return this.objectValueToString(firstProps[0]);
    }
    const firstPropString = firstProps[0] ? `${firstProps[0]}...` : '';
    return restCount > 0 ? `${firstProps[0]}... + ${restCount} more` : `${firstPropString}`;
  }

  private static objectValueToString<T extends object>(value: T): string {
    const firstProp = Object.values(value)[0];
    const firstPropString = firstProp ? `${firstProp as string}...` : '';
    return isObject(firstProp) ? this.flatValueFormat(firstProp) : `${firstPropString}`;
  }

  private static getShortValue<T>(values: T[], maxLength = 4) {
    return values
      .filter((it) => it)
      .map((value, index) => {
        if (isObject(value)) {
          return value;
        }
        return index < maxLength ? value : '...';
      })
      .join('; ');
  }
}
