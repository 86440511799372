import cx from 'classnames';
import Markdown from 'react-markdown';

import ChatClLogo from 'assets/icons/chat-cl-logo.svg';
import { Copy } from 'shared/Copy';
import { DotLoader } from 'shared/DotLoader';
import { ProgressOutputType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { AvatarWithIcon } from '../LockCircle';
import styles from './styles.module.scss';

type ReplyProps = {
  isShowIcon?: boolean;
  text?: string;
  isLoading: boolean;
  uploadingProgress?: ProgressOutputType;
};

export const Reply = ({ text, isLoading, isShowIcon = false, uploadingProgress }: ReplyProps) => {
  if (!isLoading && text === undefined) {
    return null;
  }
  const isReplying = isLoading && text === undefined;
  const loader = uploadingProgress ? (
    <div className="d-flex align-items-center gap-3">
      <p className="text-break text-prewrap m-0">{uploadingProgress.message}</p>
      <DotLoader className="flex-shrink-0" />
    </div>
  ) : (
    <DotLoader className="h-100 w-100" />
  );

  const textWithLineBreaks = text?.replaceAll('\n', '  \n  ');

  return (
    <div className={cx(styles.container, 'p-2 w-75')}>
      <div className="d-flex gap-1_5 position-relative">
        <AvatarWithIcon className="flex-shrink-0" color="secondary" isActive={isShowIcon}>
          <ChatClLogo className="rounded-circle" />
        </AvatarWithIcon>
        <div
          className={cx('fs-13 lh-16 text-text1 text-break text-prewrap', {
            'bg-chat-reply-background': !isReplying,
          })}
        >
          {isReplying ? (
            loader
          ) : (
            <div className={cx(styles.markdownContainer, 'fs-13 text-markdown-viewer-text')}>
              <Markdown>{textWithLineBreaks}</Markdown>
            </div>
          )}
        </div>
        {!isReplying && (
          <div className={cx('position-absolute top-0 end-0 ps-4 h-100', styles.actions)}>
            <Copy textToCopy={text || ''} />
          </div>
        )}
      </div>
    </div>
  );
};
