import cx from 'classnames';
import { DragDropContext, Draggable, Droppable, type DropResult } from '@hello-pangea/dnd';
import { type FC, ReactElement, useEffect, useRef, useState } from 'react';

import { GridTabDataType } from 'store/types';
import { Icon } from 'shared/Icon';
import { ButtonCode, handleMouseDown } from 'utils';
import { APP_ICONS } from 'utils/icons';
import { DnDDraggable } from 'view/DragnDrop/Draggable';
import { DropBox } from 'view/DragnDrop/DropBox';
import { ConfigType } from 'view/DragnDrop/utils/types';
import { DropBoxEnum } from 'view/DragnDrop/utils/constants';

import { TabsDropdown } from '../TabsDropdown/TabsDropdown';
import { TabItem, TabItemProps } from '../TabItem';
import styles from './TabsScrollable.module.scss';

export type TabsScrollableProps = {
  dndTabs?: boolean;
  isChatTabs?: boolean;
  activeTab?: string;
  openedTabs: GridTabDataType[];
  stackRef?: ConfigType | GridTabDataType;
  renderRightContent?: ReactElement;
  isEditorTabs?: boolean;
  isDashboardTabs?: boolean;
  isDragging: boolean;
  getTabProps: (tab: GridTabDataType) => TabItemProps;
  onDragEnd: (result: DropResult) => void;
  onDragStart: () => void;
  onSetDraggingTab: (tab: GridTabDataType) => void;
};

enum ScrollDirection {
  Left = 'left',
  Right = 'right',
}

const NAVIGATION_WIDTH = 50;
const DROPDOWN_WIDTH = 20;
const ONE_TAP_SCROLL_LENGTH = 400;

export const TabsScrollable: FC<TabsScrollableProps> = (props) => {
  const {
    activeTab,
    dndTabs,
    isChatTabs,
    openedTabs,
    renderRightContent,
    stackRef,
    isEditorTabs = false,
    isDashboardTabs = false,
    isDragging,
    getTabProps,
    onDragEnd,
    onDragStart,
    onSetDraggingTab,
  } = props;

  const scrollableRef = useRef<HTMLDivElement>(null);
  const [isEnabledMoveLeft, setIsEnabledMoveLeft] = useState<boolean>(true);
  const [isEnabledMoveRight, setIsEnabledMoveRight] = useState<boolean>(true);
  const [isTabsNavigationOverflowed, setIsTabsNavigationOverflowed] = useState<boolean>(false);

  const getNavigationScrollEnabling = (element: Element) => {
    const currentClientWidth = element.clientWidth;
    const currentLeftScroll = element.scrollLeft;
    const minLeftScroll = 0;
    const maxLeftScroll = element.scrollWidth;
    const gap = 1;

    return {
      isLeftEnabled: currentLeftScroll > minLeftScroll,
      isRightEnabled: currentLeftScroll + currentClientWidth + gap < maxLeftScroll,
      minLeftScroll,
      maxLeftScroll,
      currentLeftScroll,
      currentClientWidth,
    };
  };

  const handleUpdateNavigationScrollEnabling = () => {
    const tabsContainer = scrollableRef.current?.children[0];

    if (tabsContainer) {
      const { isLeftEnabled, isRightEnabled } = getNavigationScrollEnabling(tabsContainer);
      setIsEnabledMoveLeft(isLeftEnabled);
      setIsEnabledMoveRight(isRightEnabled);
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver((nodes) => {
      nodes.forEach((node) => {
        const tabsContainer = scrollableRef.current?.children[0];
        const dndTabsContainer = scrollableRef.current?.children[0].children[0];
        const container = dndTabs ? dndTabsContainer : tabsContainer;

        if (container?.children) {
          const scrollWidth = Array.from(container?.children).reduce(
            (acc, element) => acc + element.getBoundingClientRect().width,
            0
          );

          setIsTabsNavigationOverflowed(node.target.clientWidth < scrollWidth + NAVIGATION_WIDTH + DROPDOWN_WIDTH);
        }

        handleUpdateNavigationScrollEnabling();
      });
    });

    if (scrollableRef.current) {
      const tabsContainer = scrollableRef.current?.children[0];
      const dndTabsContainer = scrollableRef.current?.children[0].children[0];
      const container = dndTabs ? dndTabsContainer : tabsContainer;

      observer.observe(container);
    }

    return () => {
      observer.disconnect();
    };
  }, [openedTabs, renderRightContent, dndTabs]);

  useEffect(() => {
    const activeTabIndex = openedTabs.findIndex((tab) => tab.id === activeTab);

    const tabsContainer = scrollableRef.current?.children[0];
    const dndTabsContainer = scrollableRef.current?.children[0].children[0];
    const container = dndTabs ? dndTabsContainer : tabsContainer;

    if (activeTabIndex !== -1 && container) {
      const activeTabElement = container.children[activeTabIndex];

      if (activeTabElement) {
        activeTabElement.scrollIntoView({ block: 'center' });
      }
    }
  }, [activeTab, openedTabs, renderRightContent, dndTabs]);

  const handleNavigationSheetScroll = (direction: ScrollDirection) => {
    const tabsContainer = scrollableRef.current?.children[0];

    if (tabsContainer) {
      const { isLeftEnabled, isRightEnabled, minLeftScroll, maxLeftScroll, currentLeftScroll, currentClientWidth } =
        getNavigationScrollEnabling(tabsContainer);

      if (direction === ScrollDirection.Left) {
        tabsContainer.scrollLeft =
          currentLeftScroll - ONE_TAP_SCROLL_LENGTH > minLeftScroll
            ? currentLeftScroll - ONE_TAP_SCROLL_LENGTH
            : minLeftScroll;
        setIsEnabledMoveLeft(isLeftEnabled);
        return;
      }

      if (direction === ScrollDirection.Right) {
        tabsContainer.scrollLeft =
          currentLeftScroll + currentClientWidth + ONE_TAP_SCROLL_LENGTH < maxLeftScroll
            ? currentLeftScroll + ONE_TAP_SCROLL_LENGTH
            : maxLeftScroll;
        setIsEnabledMoveRight(isRightEnabled);
      }
    }
  };

  return (
    <div
      className={cx(styles.tabsContainer, {
        [styles.dashboardContainer]: isDashboardTabs,
      })}
    >
      {!dndTabs && !isChatTabs && <div className={styles.topLine} />}
      {isEditorTabs && <div className={styles.topEditorLine} />}
      <div
        ref={scrollableRef}
        className={cx('d-flex flex-nowrap', styles.header, styles.scrollable, {
          [styles.container]: openedTabs?.length,
          [styles.editorContainer]: isEditorTabs,
        })}
      >
        {dndTabs ? (
          <div onScroll={handleUpdateNavigationScrollEnabling} className="w-100 d-flex align-items-center">
            <DropBox position={DropBoxEnum.TOP} stackRef={stackRef}>
              {openedTabs?.map((tab, index) => {
                const tabProps = getTabProps(tab);
                return (
                  <DnDDraggable
                    className={cx(styles.draggable, {
                      [styles.draggableActive]: tabProps.isActive,
                    })}
                    key={tab.id}
                    index={index}
                    openedTabs={openedTabs}
                    onCloseTab={tabProps.onCloseTab}
                    stackRef={stackRef}
                  >
                    <TabItem {...tabProps} />
                  </DnDDraggable>
                );
              })}
            </DropBox>
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="visibleTabs" direction="horizontal">
              {(droppableProvided) => (
                <div
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  className="d-flex w-100"
                  onScroll={handleUpdateNavigationScrollEnabling}
                >
                  {openedTabs?.map((tab, index) => (
                    <Draggable draggableId={tab.id} index={index} key={tab.id}>
                      {(provided) => {
                        const tabProps = getTabProps(tab);
                        return (
                          <div
                            ref={provided?.innerRef}
                            className={cx('w-100', styles.draggable, {
                              [styles.draggableActive]: tabProps.isActive,
                            })}
                            {...provided?.draggableProps}
                            {...provided?.dragHandleProps}
                            onMouseDown={(event) =>
                              handleMouseDown(event, {
                                [ButtonCode.MIDDLE]: tabProps.onCloseTab,
                              })
                            }
                          >
                            <TabItem {...tabProps} />
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {isTabsNavigationOverflowed && (
          <div className={cx(styles.scrollbarButtons, { [styles.overflowed]: isTabsNavigationOverflowed })}>
            <Icon
              SvgIcon={APP_ICONS.prevArrow}
              onClick={() => handleNavigationSheetScroll(ScrollDirection.Left)}
              disable={!isEnabledMoveLeft}
            />
            <Icon
              SvgIcon={APP_ICONS.nextArrow}
              onClick={() => handleNavigationSheetScroll(ScrollDirection.Right)}
              disable={!isEnabledMoveRight}
            />
          </div>
        )}

        {!isDragging && isTabsNavigationOverflowed && (
          <TabsDropdown
            dndTabs={dndTabs}
            tabs={openedTabs}
            getTabProps={getTabProps}
            onSetDraggingTab={onSetDraggingTab}
          />
        )}

        {renderRightContent && renderRightContent}
      </div>
    </div>
  );
};
