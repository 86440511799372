import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useChatHistoryToggleTooltip(isLoading: boolean, isEmpty: boolean, isOpen: boolean) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipMessage = useMemo(() => t(isEmpty ? 'chat.emptyHistoryTooltip' : 'chat.historyTooltip'), [isEmpty, t]);

  const toggleTooltip = useCallback(() => {
    if (isOpen) return;

    setShowTooltip((prevIsShow) => !prevIsShow);
  }, [isOpen]);

  useEffect(() => {
    if (isLoading || isOpen) setShowTooltip(false);
  }, [isLoading, isOpen]);

  return {
    showTooltip,
    toggleTooltip,
    tooltipMessage,
  };
}
