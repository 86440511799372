import { ProfileDto } from '../dto';

export type ProfileModel = {
  profileName: string;
  profileDescription: string;
};

function createProfileModel(dto: ProfileDto): ProfileModel {
  return {
    profileName: dto.ProfileName,
    profileDescription: dto.ProfileDescription,
  };
}

export const ProfileModel = {
  create: createProfileModel,
};
