import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/Button';

import './HeaderFilterCellActions.styles.scss';

type HeaderFilterCellActionsProps = {
  onApply: () => void;
  onCancel: () => void;
};

export const HeaderFilterCellActions: FC<HeaderFilterCellActionsProps> = ({ onApply, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="header-filter-cell-actions">
      <Button title={t('buttons.cancel')} variant="secondary" onClick={onCancel} />
      <Button title={t('buttons.apply')} onClick={onApply} />
    </div>
  );
};
