import { useContext } from 'react';

import { EditorContextAction, EditorContextFetch, EditorContextState } from 'view/Editor/context/editorContext/type';
import { EditorContextType, EditorContextWrapper } from 'view/Editor/context/editorContext/editorContext';
import { EditorEventType, EditorRequestConfigType } from 'view/Editor/type';
import { EditorI18nType } from 'view/Editor/i18n';

export const useEditorState = (): EditorContextType => {
  const data = useContext(EditorContextWrapper);

  return {
    event: data?.event || ({} as EditorEventType),
    state: data?.state || ({} as EditorContextState),
    action: data?.action || ({} as EditorContextAction),
    fetch: data?.fetch || ({} as EditorContextFetch),
    request: data?.request || ({} as EditorRequestConfigType),
    i18n: data?.i18n || ({} as EditorI18nType),
  };
};
