import { EntityPanelViewOfDataType } from 'api';
import { isArray, isObject } from 'utils';

export const getHandlerParams = (viewOfData: EntityPanelViewOfDataType) => {
  if (isObject(viewOfData) && isArray(viewOfData.Extensions) && viewOfData.Extensions[0]) {
    const handlerParams = viewOfData.Extensions[0];
    return {
      table: handlerParams.TargetTypeName.split('.').at(-1)?.replace(/Data$/, ''),
      method: handlerParams.HandlerName,
    };
  }

  return null;
};
