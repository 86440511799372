import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectActiveEnvironment, selectEnvironments } from 'store/selectors/environment.selector';
import { selectEnvironment } from 'store/shared-reducers/EnvironmentDataSlice';
import { useAppDispatch } from 'store/hooks';
import { isDefined } from 'utils';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { useAppNavigate } from 'router/hooks';

type EnvironmentGuardProps = PropsWithChildren;

export const EnvironmentGuard: FC<EnvironmentGuardProps> = ({ children }) => {
  const { navigateToServerError } = useAppNavigate();
  const dispatch = useAppDispatch();
  const { environment: environmentParam } = useParams();
  const environments = useSelector(selectEnvironments);
  const selectedEnvironment = useSelector(selectActiveEnvironment);

  const isExistsParamEnvironmentInStore = useMemo(
    () => environments.some((env) => env.Name === environmentParam),
    [environmentParam, environments]
  );
  const isDiffEnvs = environmentParam !== selectedEnvironment;

  useEffect(() => {
    if (isDefined(environmentParam) && isDiffEnvs && isExistsParamEnvironmentInStore) {
      dispatch(selectEnvironment(environmentParam));
    }
  }, [environmentParam]);

  if (!isExistsParamEnvironmentInStore) {
    navigateToServerError();
  }

  return isDiffEnvs ? <AppFallbackSkeleton /> : <>{children}</>;
};
