import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLatest } from 'shared/Hooks/useLatest';
import { isNullable } from 'utils';
import {
  contentHandlerApi,
  ContentHandlerAttachmentEnum,
  ContentHandlerModel,
  ContentHandlersModel,
  RunContentHandlerParams,
} from 'view/Handlers';

import { useAppContext } from 'context';
import { ChatAboutParams } from 'context/types';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

import { closeTab } from 'store/shared-reducers/AppStateSlice';

import { useCloExclusions } from '../useCloExclusions';
import { useMessage } from '../useMessage';
import { useMessages } from '../useMessages';
import { useFiles } from '../useFiles';
import { useSessions } from '../useSessions';
import { useActiveChat } from '../useActiveChat';
import { ChatType, MessageGroupStatus, ProgressOutputType, SessionType } from '../../utils/types';

import { UseHistoryOptions } from './utils/types';
import { emailService } from './utils/emailService';
import { getKeyRequest } from './utils/getKeyRequest';
import { sendPromptRequest, sendPromptStreamRequest } from './utils/sendPromptRequest';
import { startNewChatRequest } from './utils/startNewChatRequest';
import { isExistsEntityRequest } from './utils/isExistsEntityRequest';
import { selectActiveDataset, selectActiveEnvironment } from './store/selectors';
import { getEmailBody } from './utils/getEmailBody';

export function useHistory({
  chatKey,
  isLoading,
  activeTypeTab,
  onChangeLoading,
  loadViewers,
  resetViewers,
}: UseHistoryOptions) {
  const { activeTab } = useTabParams();

  const dispatch = useDispatch();
  const environment = useSelector(selectActiveEnvironment);
  const dataset = useSelector(selectActiveDataset);
  const storedActiveTypeTab = useLatest(activeTypeTab);
  const sendMessageController = useRef<AbortController>(new AbortController());
  const [isSending, setIsSending] = useState<boolean>(false);

  const { openTabParams } = useAppContext();
  const [chatAboutParams, setChatAboutParams] = useState<ChatAboutParams | null>(null);
  const contentHandlersParams = useRef<Pick<RunContentHandlerParams, 'type' | 'key'> | null>(null);
  const [contentHandlers, setContentHandlers] = useState<ContentHandlersModel | null>(null);

  const [uploadingProgress, setUploadingProgress] = useState<ProgressOutputType>();

  const { activeChat, chatType, isPrivateType, getActiveChat, setActiveChat } = useActiveChat();
  const { sessions, load: loadSessions, select: switchSession, deleteMany: deleteManySessions } = useSessions();
  const {
    files,
    onChange: onChangeFiles,
    getFilesAsBase64List,
    getUnsavedFiles,
    getRemovedFileNames,
    onRemove: onRemoveFile,
    loadChatFiles,
    onSend,
    getFileNames,
  } = useFiles();
  const {
    messageGroups,
    addQuestion,
    addAnswer,
    createGroup,
    updateStatus: updateGroupStatus,
    onClear: onClearMessages,
    load: loadMessages,
  } = useMessages();
  const { message, onChange: onChangeMessage, onChangeEvent: onChangeInput, onClear: onClearMessage } = useMessage();
  const {
    state: cloExclusionsState,
    getCloExclusionsState: getcloExclusionsState,
    sendCloExclusions: sendCloExclusionsRequest,
  } = useCloExclusions();

  const keyRef = useRef<string | null>(null);

  const isSinglePDF = files.length === 1 && files[0].filename.endsWith('.pdf') && !files[0].isSaved;
  const isNoMoreThanOneFile = files.length <= 1 && files[0] ? !files[0].isSaved : true;

  const isCloAnalyzer = activeChat?.IsClo;
  const isUserProfileAnalyzer = isNoMoreThanOneFile && activeChat?.IsUserProfile;

  const startNewChat = useCallback(
    (type: ChatType, promptId?: string) => {
      const key = keyRef.current;
      if (key === null || isNullable(environment) || isNullable(dataset)) {
        throw new Error(
          `Cannot execute request because one of these parameters is empty: "key", "dataset" or "environment"`
        );
      }

      onChangeLoading(true);

      startNewChatRequest({
        key,
        dataset,
        promptId,
        activeChat: activeChat?.ChatKey,
        table: storedActiveTypeTab.current,
        datasource: environment,
        isEncrypted: type === ChatType.PRIVATE,
      })
        .then((currentChat) => {
          onClearMessage();
          onClearMessages();
          resetViewers();
          setActiveChat(currentChat);

          const params = {
            key,
            dataset,
            chatKey: currentChat.ChatKey,
            table: storedActiveTypeTab.current,
            datasource: environment,
          };
          return Promise.all([
            loadViewers(currentChat.ChatKey),
            loadChatFiles(params).then(() => {}),
            loadMessages(params),
            loadSessions(params),
            getcloExclusionsState(params),
          ]).then(() => {
            if (chatAboutParams) {
              handleUpdateChatByHandler(chatAboutParams.data);
              openTabParams.chatAbout.clearParams();
              setChatAboutParams(null);
            }
          });
        })
        .finally(() => onChangeLoading(false));
    },
    [
      activeChat,
      environment,
      dataset,
      onChangeLoading,
      storedActiveTypeTab,
      onClearMessage,
      onClearMessages,
      resetViewers,
      loadChatFiles,
      loadViewers,
      loadMessages,
      loadSessions,
      chatAboutParams,
    ]
  );

  const sendStreamMessage = (inputMessage?: string) => {
    const key = keyRef.current;
    if (key === null || isNullable(environment) || isNullable(dataset) || isNullable(activeChat)) return;

    const trimmedMessage = (inputMessage || message).trim();
    const unsavedFiles = getUnsavedFiles();

    if ((trimmedMessage.length === 0 && unsavedFiles.length === 0) || isLoading) return;

    const groupId = createGroup();
    addQuestion(groupId, trimmedMessage, unsavedFiles);

    onClearMessage();
    onChangeLoading(true);
    setIsSending(true);

    sendMessageController.current = new AbortController();

    getFilesAsBase64List().then((fileContentBytes: string[]) => {
      const sendParams = {
        dataset,
        key,
        chatKey: activeChat.ChatKey,
        fileNames: getFileNames(),
        fileContentBytes: fileContentBytes,
        removedFileNames: getRemovedFileNames(),
        datasource: environment,
        table: storedActiveTypeTab.current,
        prompt: trimmedMessage,
        signal: sendMessageController.current.signal,
      };

      return sendPromptStreamRequest({
        ...sendParams,
        onProgress: setUploadingProgress,
        onError: () => {
          setIsSending(false);
          onChangeLoading(false);
          updateGroupStatus(groupId, MessageGroupStatus.ERROR);
        },
        onClose: (output) => {
          const params = {
            dataset,
            key,
            chatKey: activeChat.ChatKey,
            datasource: environment,
            table: storedActiveTypeTab.current,
          };

          addAnswer(groupId, output?.message ?? '');
          onSend();
          // TODO: temporary fix, check later

          Promise.all([loadViewers(activeChat.ChatKey), getcloExclusionsState(params), loadChatFiles(params)]).finally(
            () => {
              setUploadingProgress(undefined);
              onChangeLoading(false);
              setIsSending(false);
            }
          );
        },
      });
    });
  };

  const sendDefaultMessage = (inputMessage?: string) => {
    const key = keyRef.current;
    if (key === null || isNullable(environment) || isNullable(dataset) || isNullable(activeChat)) return;

    const trimmedMessage = (inputMessage || message).trim();
    const unsavedFiles = getUnsavedFiles();

    if ((trimmedMessage.length === 0 && unsavedFiles.length === 0) || isLoading) return;

    const groupId = createGroup();
    addQuestion(groupId, trimmedMessage, unsavedFiles);

    onClearMessage();
    onChangeLoading(true);
    setIsSending(true);

    sendMessageController.current = new AbortController();

    getFilesAsBase64List()
      .then((fileContentBytes: string[]) =>
        sendPromptRequest({
          dataset,
          key,
          chatKey: activeChat.ChatKey,
          fileNames: getFileNames(),
          fileContentBytes: fileContentBytes,
          removedFileNames: getRemovedFileNames(),
          datasource: environment,
          table: storedActiveTypeTab.current,
          prompt: trimmedMessage,
          signal: sendMessageController.current.signal,
        })
      )
      .then((answer: string | void) => {
        const params = {
          dataset,
          key,
          chatKey: activeChat.ChatKey,
          datasource: environment,
          table: storedActiveTypeTab.current,
        };

        addAnswer(groupId, answer ?? '');
        onSend();
        // TODO: temporary fix, check later
        return Promise.all([loadViewers(activeChat.ChatKey), getcloExclusionsState(params), loadChatFiles(params)]);
      })
      .catch(() => updateGroupStatus(groupId, MessageGroupStatus.ERROR))
      .finally(() => {
        setUploadingProgress(undefined);
        onChangeLoading(false);
        setIsSending(false);
      });
  };

  const sendMessage = (inputMessage?: string) => {
    if (isCloAnalyzer || isUserProfileAnalyzer) {
      return sendStreamMessage(inputMessage);
    }
    return sendDefaultMessage(inputMessage);
  };

  const cancelSendMessage = useCallback(() => sendMessageController.current.abort(), []);

  const selectSession = useCallback(
    (session: SessionType) => {
      if (session.ChatKey === activeChat?.ChatKey) return;

      const key = keyRef.current;
      if (key === null || isNullable(environment) || isNullable(dataset)) {
        throw new Error(
          `Cannot execute request because one of these parameters is empty: "key", "dataset" or "environment"`
        );
      }

      onChangeLoading(true);

      switchSession({
        key,
        table: storedActiveTypeTab.current,
        datasource: environment,
        dataset,
        activeChat: activeChat?.ChatKey,
        session,
      })
        .then((currentChat) => {
          setActiveChat(currentChat);
          onClearMessage();

          const params = {
            key,
            chatKey: currentChat.ChatKey,
            table: storedActiveTypeTab.current,
            datasource: environment,
            dataset,
          };
          return Promise.all([
            loadSessions(params),
            loadChatFiles(params),
            loadMessages(params),
            getcloExclusionsState(params),
            loadViewers(currentChat.ChatKey),
          ]);
        })
        .finally(() => onChangeLoading(false));
    },
    [
      activeChat?.ChatKey,
      dataset,
      environment,
      loadChatFiles,
      loadMessages,
      loadViewers,
      onChangeLoading,
      storedActiveTypeTab,
      switchSession,
    ]
  );

  const deleteSelectedSessions = useCallback(
    (selectedSessions: SessionType[]) => {
      const key = keyRef.current;
      if (key === null || isNullable(dataset) || isNullable(environment)) {
        throw new Error(
          `Cannot execute request because one of these parameters is empty: "key", "dataset" or "environment"`
        );
      }

      onChangeLoading(true);

      deleteManySessions({
        key,
        dataset,
        table: storedActiveTypeTab.current,
        datasource: environment,
        sessions: selectedSessions,
      })
        .then((availableSessions: SessionType[]) => {
          // deleted all sessions
          if (availableSessions.length === 0) {
            startNewChat(ChatType.INTERNAL);
            return;
          }

          const isAvailableActiveChat = availableSessions.some(
            (session: SessionType) => session.ChatKey === activeChat?.ChatKey
          );
          if (!isAvailableActiveChat) {
            const firstAvailableChat = availableSessions[0];
            selectSession(firstAvailableChat);
          }
        })
        .finally(() => onChangeLoading(false));
    },
    [
      dataset,
      environment,
      onChangeLoading,
      deleteManySessions,
      storedActiveTypeTab,
      startNewChat,
      activeChat?.ChatKey,
      selectSession,
    ]
  );

  const handleUpdateChatByHandler = (data: ContentHandlerModel) => {
    if (data.attachmentType === ContentHandlerAttachmentEnum.MessageInput) {
      onChangeMessage(data.content);
      sendMessage(data.content);
    } else {
      onChangeFiles([data.content]);
    }
  };

  const sendCloExclusions = useCallback(() => {
    const key = keyRef.current;
    if (key === null || isNullable(dataset) || isNullable(environment) || isNullable(activeChat)) {
      throw new Error(
        `Cannot execute request because one of these parameters is empty: "key", "dataset", "activeChat" or "environment"`
      );
    }

    onChangeLoading(true);

    sendCloExclusionsRequest({
      key,
      dataset,
      table: storedActiveTypeTab.current,
      datasource: environment,
      chatKey: activeChat.ChatKey,
    }).finally(() => onChangeLoading(false));
  }, [dataset, environment, activeChat?.ChatKey]);

  const openMailClient = useCallback(() => {
    const key = keyRef.current;
    if (key === null || isNullable(dataset) || isNullable(environment) || isNullable(activeChat)) {
      throw new Error(
        `Cannot execute request because one of these parameters is empty: "key", "dataset", "activeChat" or "environment"`
      );
    }

    onChangeLoading(true);

    getEmailBody({
      key,
      dataset,
      table: storedActiveTypeTab.current,
      datasource: environment,
      currentChat: activeChat.ChatKey,
    })
      .then((body) => {
        emailService.open({
          to: '',
          subject: 'TIP chat notification',
          body,
        });
      })
      .finally(() => onChangeLoading(false));
  }, [dataset, environment, activeChat?.ChatKey]);

  const getContentByHandler = useCallback(
    (params: Pick<RunContentHandlerParams, 'handler'>) => {
      if (!contentHandlersParams.current) throw new Error("Run missed required params: 'type' | 'key'.");

      onChangeLoading(true);

      contentHandlerApi
        .runContentHandler({
          handler: params.handler,
          key: contentHandlersParams.current.key,
          type: contentHandlersParams.current.type,
          dataset,
          environment,
        })
        .then(handleUpdateChatByHandler)
        .finally(() => {
          onChangeLoading(false);
        });
    },
    [dataset, environment]
  );

  const getContentByKey = useCallback(
    (params: { type: string; recordKey: string }) => {
      onChangeLoading(true);

      contentHandlerApi
        .getContentHandlers({ type: params.type, key: params.recordKey, dataset, environment })
        .then((handlersModel) => {
          if (!handlersModel.isExist) throw new Error("The record doesn't have any handlers.");

          contentHandlersParams.current = { type: params.type, key: params.recordKey };

          if (!handlersModel.isMultiChoice) {
            return getContentByHandler({ handler: handlersModel.handlers[0] });
          }

          setContentHandlers(handlersModel);
          return Promise.resolve();
        })
        .finally(() => onChangeLoading(false));
    },
    [dataset, environment, sendMessage]
  );

  const handleClearContentHandlers = () => {
    setContentHandlers(null);
    contentHandlersParams.current = null;
  };

  const getChatKeyOnMount = async (): Promise<string> => {
    if (chatKey && dataset) {
      const isExistsEntity = await isExistsEntityRequest({
        dataset,
        key: chatKey,
        type: storedActiveTypeTab.current,
      });

      if (!isExistsEntity && activeTab?.id) {
        dispatch(closeTab({ id: activeTab.id }));
        throw new Error('Can not find the entity');
      }

      return chatKey;
    }

    return getKeyRequest({ type: storedActiveTypeTab.current });
  };

  const getChatOnMount = async () => {
    if (isNullable(dataset) || isNullable(environment)) {
      throw new Error(`Cannot execute request because one of these parameters is empty: "dataset" or "environment"`);
    }

    onChangeLoading(true);

    try {
      const mountChatKey = await getChatKeyOnMount();
      keyRef.current = mountChatKey;

      openTabParams.chatAbout.getParams((chatParams) => {
        if (chatParams) {
          setChatAboutParams(chatParams);
        } else {
          getActiveChat({
            key: mountChatKey,
            dataset,
            table: storedActiveTypeTab.current,
            datasource: environment,
          }).then((currentChat) => {
            if (currentChat) {
              const params = {
                key: mountChatKey,
                dataset,
                chatKey: currentChat.ChatKey,
                table: storedActiveTypeTab.current,
                datasource: environment,
              };

              return Promise.all([
                loadChatFiles(params),
                loadMessages(params),
                loadSessions(params),
                loadViewers(currentChat.ChatKey),
                getcloExclusionsState(params),
              ]);
            }

            return null;
          });
        }
      });
    } finally {
      onChangeLoading(false);
    }
  };

  useEffect(() => {
    getChatOnMount();
  }, [chatKey, environment, storedActiveTypeTab, dataset]);

  useEffect(() => {
    if (!isSinglePDF || !isCloAnalyzer || isLoading) return;

    onChangeMessage('classify CLO Exclusions from the file with page numbers');
  }, [isSinglePDF, isLoading]);

  return {
    getContentByHandler,
    contentHandlers,
    chatAboutParams,
    handleClearContentHandlers,
    cloExclusionsState,
    openMailClient,
    isExecuteAnalyzer: isSinglePDF && isCloAnalyzer,
    activeChat,
    isSending,
    chatType,
    isPrivateType,
    sessions,
    selectSession,
    deleteSelectedSessions,
    files,
    onChangeFiles,
    onRemoveFile,
    message,
    messageGroups,
    sendMessage,
    cancelSendMessage,
    startNewChat,
    onChangeInput,
    getContentByKey,
    getUnsavedFiles,
    uploadingProgress,
    sendCloExclusions,
  };
}
