import { FC } from 'react';

import {
  VariantSelect,
  VariantSelectDataType,
  VariantType,
} from 'view/Editor/components/TableBody/TableBodyCell/VariantSelect/VariantSelect.component';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { DictionaryDataValueModel } from 'api';
import { isArray } from 'utils';
import { UIInput } from 'ui';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';
import { SchemaType } from 'view/Editor/helpers/getCellProps.helper';
import { GoToDrilledDataIcon } from 'view/Editor/components/TableBody/TableBodyCell/GoToDrilledDataIcon';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

export enum DictionaryViewType {
  Flat = 'Flat',
  List = 'List',
}

type DictionaryViewValueCellProps = {
  title?: string;
  isReadonly?: boolean;
  path: string[];
  value: DictionaryDataValueModel | DictionaryDataValueModel[] | null;
};

export const DictionaryViewValueCell: FC<DictionaryViewValueCellProps> = ({
  isReadonly = false,
  value,
  title = '',
  path,
}) => {
  const { action } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const dictViewType = isArray(value) ? DictionaryViewType.List : DictionaryViewType.Flat;

  const handleChangeVariant = (updateValue: VariantSelectDataType) => {
    action.updateEditorData({ path, updateValue });
  };

  if (dictViewType === DictionaryViewType.Flat) {
    const dictFlatValue = value as DictionaryDataValueModel;
    const variantType = (dictFlatValue && (Object.keys(dictFlatValue)?.[0] as VariantType)) || '';
    const variantValue = (dictFlatValue && Object.values(dictFlatValue)?.[0]) ?? '';

    return (
      <VariantSelect
        isReadOnly={isReadonly}
        selectOnFocus
        variantType={variantType}
        value={variantValue}
        onChange={handleChangeVariant}
      />
    );
  }

  const handleOpenVariantList = () => {
    action.openEditorTabAction(
      {
        title: `${title} Value`,
        key: `${title}_value`,
        path,
        isVector: true,
        schemaType: SchemaType.VariantList,
        selector: '',
      },
      tabState.tabIndex
    );
  };

  if (dictViewType === DictionaryViewType.List) {
    return (
      <UIInput
        selectOnFocus
        prefixElement={
          <div className="me-1_5">
            <GoToDrilledDataIcon onClick={handleOpenVariantList} />
          </div>
        }
        readOnly
        ellipsis
        variant="transparent"
        value={dataToString(value)}
      />
    );
  }

  return null;
};
