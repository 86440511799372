import { HandlerType, SchemaTypeModel } from 'api';

import { HandlerItemType } from '../types';
import { getRunHandlerKey } from './getRunHandlerKey.helper';

export const getRunHandlersBySchema = (
  schema: SchemaTypeModel,
  selectedRowKey: string,
  pinnedHandlers: string[] = []
): HandlerItemType[] | undefined => {
  const handlersArr = schema.Declare?.Handlers?.filter((handler) => handler.Type === HandlerType.JOB).map(
    (handler) => ({
      name: handler.Label,
      id: handler.Name,
      type: handler.Type,
      isStatic: handler.Static,
      hasParams: Boolean(handler.Params),
      key: getRunHandlerKey(handler.Name, handler.Static, selectedRowKey),
      isPinned: Boolean(pinnedHandlers?.includes(handler.Name)),
      isDisable: handler.Static ? false : !selectedRowKey,
      executedInfo: undefined,
      return: handler.Return,
    })
  );

  return selectedRowKey ? handlersArr : handlersArr?.filter((handler) => handler.isStatic);
};
