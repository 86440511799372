import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import {
  ColumnDef,
  ColumnResizeMode,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import { GridContext, GridContextProps } from '../../contexts';

type UiGridProviderProps<TData> = PropsWithChildren<{
  rowHeight: number;
  columns: ColumnDef<TData>[];
  data: TData[];
  columnResizeMode: ColumnResizeMode;
}>;

export const UiGridProvider = <TData,>({
  rowHeight,
  columnResizeMode,
  data,
  columns,
  children,
}: UiGridProviderProps<TData>) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  const [openedHeaderMenuId, setOpenedHeaderMenuId] = useState<string>();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const [isHiddenVerticalScroll, setHiddenVerticalScroll] = useState(false);
  const [isHiddenHorizontalScroll, setHiddenHorizontalScroll] = useState(false);

  const table = useReactTable<TData>({
    data,
    columns,
    columnResizeMode,
    state: {
      sorting,
      columnVisibility,
    },
    defaultColumn: {
      minSize: 100,
      size: 150,
      maxSize: 400,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { rows } = table.getRowModel();
  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => bodyRef.current,
    estimateSize: useCallback(() => rowHeight, [rowHeight]),
  });

  const setHeaderScrollLeft = useCallback((left: number) => {
    if (!headerRef.current) return;
    headerRef.current.scrollLeft = left;
  }, []);

  const setBodyScrollLeft = useCallback((left: number) => {
    if (!bodyRef.current) return;
    bodyRef.current.scrollLeft = left;
  }, []);

  const setFooterScrollLeft = useCallback((left: number) => {
    if (!footerRef.current) return;
    footerRef.current.scrollLeft = left;
  }, []);

  const context: GridContextProps<TData> = {
    api: table,
    headerRef,
    bodyRef,
    footerRef,
    openedHeaderMenuId,
    isHiddenVerticalScroll,
    isHiddenHorizontalScroll,
    virtualizer,
    setHeaderScrollLeft,
    setBodyScrollLeft,
    setFooterScrollLeft,
    setOpenedHeaderMenuId,
    setHiddenHorizontalScroll,
    setHiddenVerticalScroll,
  };

  return <GridContext.Provider value={context}>{children}</GridContext.Provider>;
};
