import { isArray, isBool, isDefined, isString } from 'utils';

import { EditorValidationSchemaRuleType, EditorValidationFunctionType, EditorValidationType } from '../type';

export const isRequiredValidation: EditorValidationFunctionType = (value) => {
  if (isArray(value)) return value.length > 0;
  if (isBool(value)) return true;
  if (isString(value)) return !!value.trim();

  return isDefined(value);
};

export const isRequiredSchema: EditorValidationSchemaRuleType = (elementSchema) =>
  !elementSchema.Optional ? EditorValidationType.IsRequired : null;
