import { useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { useEffect } from 'react';

import { useAppNavigate } from 'router';
import { GridTabDataType } from 'store/types';
import { selectOpenedTabs } from 'store/selectors/AppState.selector';

type ActiveTab = Pick<GridTabDataType, 'id' | 'key' | 'label' | 'dynamicType'>;

type TabParamsData = {
  activeTab?: ActiveTab;
  setActiveTab: (type: string) => void;
};

export const useTabParams = (): TabParamsData => {
  const { activeTab: activeTabId } = useParams();
  const { navigateToRoot, navigateToActiveTab } = useAppNavigate();

  const openedTabs = useSelector(selectOpenedTabs);
  const currentTab = openedTabs.find((tab) => tab.id === activeTabId);

  useEffect(() => {
    if (activeTabId && !currentTab) {
      navigateToRoot();
    }

    if (!activeTabId && openedTabs.length) {
      navigateToActiveTab(openedTabs[0].id);
    }
  }, [activeTabId, openedTabs]);

  const activeTab: ActiveTab | undefined = currentTab
    ? {
        id: currentTab.id,
        key: currentTab.key,
        label: currentTab.label,
        dynamicType: currentTab.dynamicType,
      }
    : undefined;

  const setActiveTab = (updatedType: string): void => {
    navigateToActiveTab(updatedType);
  };

  return { activeTab, setActiveTab };
};
