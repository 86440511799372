import { ChangeEvent, FormEvent, SyntheticEvent, useMemo } from 'react';

import { mapControlsToRulesDictionary, validateValueByRules } from './UIForm.helpers';
import { useUIFormStore } from './UIFormStore.hook';
import { UIControlType } from '../../types';

type UseUIFormOptions<T extends object> = {
  controls: Array<UIControlType<T>>;
  initialValues: T;
  onSubmit: (values: T) => Promise<void>;
  onValuesChange?: (values: T) => void;
};

export function useUIForm<T extends object>(options: UseUIFormOptions<T>) {
  const { stateRef, setErrors, setValueByKey, touchedByKey, setAllTouched, setIsSubmitting } = useUIFormStore(
    options.initialValues
  );
  const rulesDictionary = useMemo(() => mapControlsToRulesDictionary(options.controls), [options.controls]);

  const validate = () => {
    const { values } = stateRef.current;
    const updatedErrors = (Object.keys(values) as Array<keyof T>).reduce(
      (acc, key: keyof T) => {
        const rules = rulesDictionary[key];
        const value = values[key];
        acc[key] = validateValueByRules(value, rules);
        return acc;
      },
      {} as Record<keyof T, string>
    );
    setErrors(updatedErrors);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    validate();
    setAllTouched();

    const { errors, values } = stateRef.current;
    const isValid = Object.values(errors).every((message) => !message);
    if (isValid) {
      setIsSubmitting(true);
      options.onSubmit(values).finally(() => setIsSubmitting(false));
    }
  };

  const handleBlur = (event: SyntheticEvent) => {
    const { name } = event.target as HTMLInputElement;
    if (!name) {
      // TODO: add more details about the element.
      throw new Error('Provide a name attribute for the element');
    }
    touchedByKey(name);
    validate();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target as HTMLInputElement;
    if (!name) {
      // TODO: add more details about the element.
      throw new Error('Provide a name attribute for the element');
    }

    setValueByKey(name, event.target.value as T[keyof T]);
    options.onValuesChange?.(stateRef.current.values);
    validate();
  };

  return {
    ...stateRef.current,
    handleSubmit,
    handleBlur,
    handleChange,
  };
}
