import { useCallback, useEffect, useMemo, useState } from 'react';

import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

type SelectedSessionsType = Record<SessionType['ChatKey'], boolean>;

export function useSelectedSessions(sessions: SessionType[]) {
  const [selectedSessions, setSelectedSessions] = useState<SelectedSessionsType>({});

  const isAllSelection = useMemo(() => {
    const values = Object.values(selectedSessions);
    return values.length > 0 && values.every((value) => value);
  }, [selectedSessions]);

  const isEmptySelection = useMemo(
    () => Object.values(selectedSessions).every((isSelectedSession: boolean) => !isSelectedSession),
    [selectedSessions]
  );

  const isSelected = useCallback((key: string): boolean => selectedSessions[key] || false, [selectedSessions]);

  const getSelectedSessions = useCallback(
    (): SessionType[] => sessions.filter((session) => isSelected(session.ChatKey)),
    [isSelected, sessions]
  );

  const sessionsToMap = useCallback((currentSessions: SessionType[]) => {
    const sessionMap = currentSessions.reduce(
      (map, session: SessionType) => ({
        ...map,
        [session.ChatKey]: false,
      }),
      {} as SelectedSessionsType
    );

    setSelectedSessions(sessionMap);
  }, []);

  const toggleSession = useCallback((session: SessionType) => {
    setSelectedSessions((prevSelectedSessions) => {
      if (session.ChatKey in prevSelectedSessions) {
        const previousValue = prevSelectedSessions[session.ChatKey];
        return {
          ...prevSelectedSessions,
          [session.ChatKey]: !previousValue,
        };
      }

      return prevSelectedSessions;
    });
  }, []);

  const updateAllSessions = useCallback((isChecked: boolean) => {
    setSelectedSessions((prevSelectedSessions) =>
      Object.keys(prevSelectedSessions).reduce(
        (updatedSelectedSessions, key) => ({
          ...updatedSelectedSessions,
          [key]: isChecked,
        }),
        {} as SelectedSessionsType
      )
    );
  }, []);

  const toggleAllSessions = useCallback(() => updateAllSessions(!isAllSelection), [isAllSelection, updateAllSessions]);

  const resetAllSessions = useCallback(() => updateAllSessions(false), [updateAllSessions]);

  useEffect(() => {
    sessionsToMap(sessions);
  }, [sessionsToMap, sessions]);

  return {
    isSelected,
    isEmptySelection,
    isAllSelection,
    toggleSession,
    toggleAllSessions,
    getSelectedSessions,
    resetAllSessions,
  };
}
