import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid } from 'view/Grid';
import { useAppDispatch } from 'store/hooks';
import { selectDataset as setActiveDatasetName } from 'store/shared-reducers/DatasetDataSlice';
import { DataType } from 'view/Grid/utils/types';
import { Button } from 'shared/Button';
import { UiModal } from 'ui/UiModal';
import { selectDatasetData } from 'store/selectors/dateset.selector';

import { FilterInput } from 'view/Grid/components/TableHeader/FilterInput';
import { Cell } from 'view/Grid/components/TableHeader/Cell';

const DATASET_COLUMNS = [
  {
    id: 'expander',
    Header: 'Data Set',
    accessor: 'Name',
  },
];

type DatasetModalProps = {
  isModalOpened: boolean;
  handleCloseModal: () => void;
  gridId: string;
};

export const DatasetModal = ({ isModalOpened, handleCloseModal, gridId }: DatasetModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { status, rowsData, activeDatasetName } = useSelector(selectDatasetData);

  const [selectedDataset, selectDataset] = useState<string | undefined>(activeDatasetName);
  const columns = useMemo(
    () =>
      DATASET_COLUMNS.map((column) => ({
        ...column,
        Filter: FilterInput,
        Cell,
      })),
    []
  );

  useEffect(() => {
    selectDataset(activeDatasetName);
  }, [activeDatasetName]);

  const getRowId = useCallback((row: DataType) => row.Name as string, []);

  const handleApply = () => {
    if (selectedDataset && selectedDataset !== activeDatasetName) {
      dispatch(setActiveDatasetName(selectedDataset));
    }

    handleCloseModal();
  };

  return (
    <UiModal
      isOpen={isModalOpened}
      defaultHeight={350}
      title={t('datasetModal.datasets') || ''}
      onClose={handleCloseModal}
    >
      <UiModal.Body className="d-grid overflow-auto">
        <div className="border border-gray-600 bg-gray-200">
          <Grid
            variant="secondary"
            gridId={gridId}
            status={status}
            columns={columns}
            data={rowsData}
            isColumnFilter={false}
            getRowId={getRowId}
            selectedRowId={selectedDataset}
            onChangeSelectedRowId={selectDataset}
          />
        </div>
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={t('buttons.cancel')} variant="secondary" onClick={handleCloseModal} />
        <Button title={t('buttons.apply')} onClick={handleApply} />
      </UiModal.Footer>
    </UiModal>
  );
};
