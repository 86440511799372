import { DependencyList, useMemo } from 'react';

export function throttle<Args extends unknown[]>(fn: (...args: Args) => void, wait: number) {
  let lastArgs: Args | undefined;

  const run = () => {
    if (lastArgs) {
      fn(...lastArgs);
      lastArgs = undefined;
    }
  };

  return (...args: Args) => {
    const isRun = !!lastArgs;

    lastArgs = args;

    if (isRun) return;

    setTimeout(run, wait);
  };
}

export function useThrottle<Args extends unknown[]>(cb: (...args: Args) => void, wait: number, deps: DependencyList) {
  return useMemo(() => throttle(cb, wait), deps);
}
