import { BinaryContentTypesEnum } from 'view/Viewers/utils/constants';

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = reject;
  });

export const base64ToFile = (base64: string, filename: string): Promise<File> =>
  fetch(base64)
    .then((response) => response.blob())
    .then((blob) => new File([blob], filename));

export const base64ToBytes = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const getMimeTypeFromBuffer = (buffer: Uint8Array): string | null => {
  const uint8arr = new Uint8Array(buffer);

  const len = 4;
  if (uint8arr.length >= len) {
    const signatureArr = new Array(len);
    for (let i = 0; i < len; i++) {
      signatureArr[i] = new Uint8Array(buffer)[i].toString(16);
    }
    const signature = signatureArr.join('').toUpperCase();

    switch (signature) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
        return 'image/jpeg';
      case '504B0304':
        return 'application/zip';
      case '504B34':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return null;
    }
  }
  return null;
};

export const convertContentToBase64ByType = (type: BinaryContentTypesEnum, content: string): string => {
  switch (type) {
    case BinaryContentTypesEnum.Html:
    case BinaryContentTypesEnum.Plotly:
      return window.atob(content);
    case BinaryContentTypesEnum.Pdf:
      return `data:application/pdf;base64,${content}`;
    case BinaryContentTypesEnum.Png:
    case BinaryContentTypesEnum.Jpg:
      return `data:image/;base64,${content}`;
    default:
      throw new Error(`Cannot convert content to base64 by type`);
  }
};
