import { createContext, Dispatch, RefObject, SetStateAction } from 'react';

import { SelectedRowsRangeType } from 'view/Grid/utils/types';

type GridContextType = {
  headerRef: RefObject<HTMLDivElement>;
  bodyRef: RefObject<HTMLDivElement>;
  changeOrderColumns: (startIndex: number, endIndex: number) => void;
  selectedRowsRange: SelectedRowsRangeType;
  isSelectAllRecordsBtnClicked?: boolean;
  setIsSelectAllRecordsBtnClicked?: Dispatch<SetStateAction<boolean>>;
  selectedCheckbox: Record<string, boolean>;
};

export const GridContext = createContext<GridContextType>({} as GridContextType);
