import { useDispatch, useSelector } from 'react-redux';

import { selectActiveDataset, selectActiveEnvironment } from 'store/selectors';
import { toast } from 'utils';
import { openTab } from 'store/shared-reducers/AppStateSlice';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

import { startNewChatRequest } from '../../InteractiveDashboardContainer/hooks/useHistory/utils/startNewChatRequest';
import { useActiveChat } from '../../InteractiveDashboardContainer/hooks/useActiveChat';
import { ChatType } from '../../InteractiveDashboardContainer/utils/types';

const TOPIC = 'Topic';
const KEY = 'Content Analyst';
const LABEL = 'Chat';

export function useStartNewChat() {
  const { setActiveTab } = useTabParams();
  const { activeChat, chatType } = useActiveChat();
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);
  const chatTabInfo = { id: TOPIC, key: TOPIC, label: LABEL };

  const dispatch = useDispatch();
  const startNewChat = (chatName: string) => {
    startNewChatRequest({
      key: KEY,
      dataset: dataset || '',
      promptId: chatName,
      activeChat: activeChat?.ChatKey,
      table: TOPIC,
      datasource: environment || '',
      isEncrypted: chatType === ChatType.PRIVATE,
    })
      .then(() => {
        dispatch(openTab(chatTabInfo));
        setActiveTab(chatTabInfo.id);
      })
      .catch(toast.error);
  };

  return startNewChat;
}
