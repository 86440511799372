import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UiModal } from 'ui';
import { EditorNewRecord } from 'view/Editor/EditorOption/EditorNewRecord';

type KeySelectCreateModalProps = {
  isOpen: boolean;
  schemaName: string;
  onClose: () => void;
  onSubmit?: (key: string) => void;
};

export const KeySelectCreateModal: FC<KeySelectCreateModalProps> = ({ isOpen, onClose, schemaName, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <UiModal isOpen={isOpen} defaultHeight={450} title={t('editor.entityModalHeader')} onClose={onClose}>
      <UiModal.Body className="overflow-auto">
        <EditorNewRecord recordType={schemaName} onClose={onClose} onSubmit={onSubmit} />
      </UiModal.Body>
    </UiModal>
  );
};
