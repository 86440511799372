import { RefObject, useContext, useState } from 'react';
import { HeaderGroup, SortingRule } from 'react-table';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import cx from 'classnames';

import { DataType, GridModeEnum } from 'view/Grid/utils/types';
import { GridContext } from 'view/Grid/Context';
import { ScrollCorner } from 'view/Grid/components/ScrollCorner';

import { HeaderWrapperCell } from './HeaderWrapperCell';
import { HeaderCell } from './HeaderCell';
import styles from './styles.module.scss';

type TableHeaderProps = {
  gridRef: RefObject<HTMLDivElement>;
  isShowCorner: boolean;
  headerGroups: HeaderGroup<DataType>[];
  gridMode: GridModeEnum;
  onSortByChanged: (updatedSortBy: SortingRule<DataType>[]) => void;
};

export const TableHeader = ({ gridRef, isShowCorner, headerGroups, gridMode, onSortByChanged }: TableHeaderProps) => {
  const { headerRef, changeOrderColumns } = useContext(GridContext);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onBeforeDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);

    const { source, destination } = result;
    if (!destination) return;

    const isDisabledDroppable = destination.index === 0;
    const nextEnabledDroppableIndex = destination.index + 1;

    changeOrderColumns(source.index, isDisabledDroppable ? nextEnabledDroppableIndex : destination.index);
  };

  return (
    <div ref={headerRef} className={cx('d-flex flex-shrink-0 overflow-hidden flex-column', styles.row)}>
      {headerGroups.map((headerGroup, groupsIndex) => {
        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

        return (
          <DragDropContext onBeforeCapture={onBeforeDragStart} onDragEnd={onDragEnd} key={key}>
            <Droppable
              droppableId="droppable"
              direction="horizontal"
              renderClone={(provided, snapshot, rubric) => {
                const column = headerGroups[groupsIndex].headers[rubric.source.index];
                const sortByProps = column.getSortByToggleProps();

                return (
                  <HeaderWrapperCell
                    isDragOccurring={isDragging}
                    snapshot={snapshot}
                    provided={provided}
                    column={column}
                    className={cx('text-light fs-13', styles.cell)}
                  >
                    <HeaderCell
                      gridRef={gridRef}
                      column={column}
                      sortByProps={sortByProps}
                      gridMode={gridMode}
                      onSortByChanged={onSortByChanged}
                    />
                  </HeaderWrapperCell>
                );
              }}
            >
              {(droppableProvided) => (
                <div {...headerGroupProps} ref={droppableProvided.innerRef} className="flex-column">
                  <div className="d-flex">
                    {headerGroup.headers.map((column: HeaderGroup<DataType>, index, headerArr) => {
                      const sortByProps = column.getSortByToggleProps();

                      return (
                        <div className={cx(styles.headerWrapper)} key={column.id}>
                          <Draggable
                            isDragDisabled={column.suppressDrag}
                            key={column.id}
                            draggableId={column.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <HeaderWrapperCell
                                isDragOccurring={isDragging}
                                snapshot={snapshot}
                                provided={provided}
                                column={column}
                                className={cx('text-light fs-13', styles.cell)}
                                lastEl={headerArr.length - 1 === index}
                              >
                                <HeaderCell
                                  gridRef={gridRef}
                                  column={column}
                                  sortByProps={sortByProps}
                                  gridMode={gridMode}
                                  onSortByChanged={onSortByChanged}
                                />
                              </HeaderWrapperCell>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}
                    {droppableProvided.placeholder}
                  </div>

                  <div className={styles.filter}>
                    {headerGroup.headers.map((column: HeaderGroup<DataType>) => (
                      <div className={cx(styles.headerWrapper)} key={column?.id} style={{ width: column?.totalWidth }}>
                        {column.canFilter ? (
                          <div className={styles.filterContainer} style={{ width: column?.totalWidth }}>
                            {column.render('Filter')}
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        );
      })}
      {isShowCorner && <ScrollCorner />}
    </div>
  );
};
