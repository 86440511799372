import { SchemaTypeElementsModel, SchemaTypeListModel, StorageEntityModel } from 'api';

export enum EditorValidationType {
  IsRequired = 'isRequired',
}

export type EditorValidationFunctionType = (value: unknown) => boolean;

export type EditorValidationSchemaRuleType = (value: SchemaTypeElementsModel) => EditorValidationType | null;

export type EditorValidationRuleType = {
  schemaRule: EditorValidationSchemaRuleType;
  validate: EditorValidationFunctionType;
  message: string;
};

export type ValidationResultType = {
  rule: EditorValidationType;
  path: string[];
  message: string;
};

export type ValidationDataType = {
  data: StorageEntityModel;
  schema: SchemaTypeListModel;
  rootSchemaName: string;
};
