import { isObject, toString } from 'utils';
import { EntityPanelViewOfNameType, ValueTypeModel } from 'api';
import { mapMetadataToGridColumnsHelper } from 'view/Viewers/utils/helper';
import { getColumnTypeByCells } from 'view/Grid/utils/helpers';

export const ROW_COLUMN_NAME = 'Row Col';

const generateRowColumn = (rowHeaders: Array<EntityPanelViewOfNameType | null>) => {
  const rowValue = rowHeaders.find(isObject<EntityPanelViewOfNameType>);
  const rowValueType = rowValue ? (Object.keys(rowValue)[0] as ValueTypeModel) : 'String';
  return {
    Name: ROW_COLUMN_NAME,
    Value: { Type: rowValueType },
  };
};

export const getColumns = (
  colHeaders: EntityPanelViewOfNameType[],
  rowHeaders: Array<EntityPanelViewOfNameType | null>,
  cells: Array<EntityPanelViewOfNameType | null>
) => {
  const columnCount = colHeaders.length;
  const metaDataElements = colHeaders.map((header, index) => {
    const columnType = getColumnTypeByCells(index, columnCount, cells);
    const name = Object.values(header)[0];

    return {
      Name: toString(name),
      Value: { Type: columnType },
    };
  });

  return mapMetadataToGridColumnsHelper([generateRowColumn(rowHeaders), ...metaDataElements]);
};

export const getRows = (
  colHeaders: EntityPanelViewOfNameType[],
  rowHeaders: Array<EntityPanelViewOfNameType | null>,
  cells: Array<EntityPanelViewOfNameType | null>
) => {
  const finalCells = [];
  const colHeadersLength = colHeaders.length;

  for (let i = 0; i < rowHeaders.length; i += 1) {
    const rowHeader = rowHeaders[i];
    const rowCountValue = isObject(rowHeader) ? Object.values(rowHeader).at(0) : null;
    const rowCountCell = { [ROW_COLUMN_NAME]: rowCountValue };

    const offsetIndex = i * colHeadersLength;
    const initialRowData = cells.slice(offsetIndex, offsetIndex + colHeadersLength);

    const rowCells = initialRowData.reduce((acc, cur, colCount) => {
      const colKey = colHeaders[colCount].String as string;
      const colValue = isObject(cur) ? Object.values(cur).at(0) : null;
      return { ...acc, [colKey]: colValue };
    }, {});

    finalCells.push({ ...rowCountCell, ...rowCells });
  }

  return finalCells;
};
