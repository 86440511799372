import { FC, useEffect, useMemo } from 'react';

import { OptionType, UIInput, UIInputProps, UiSelect } from 'ui';
import { isDefined, toast } from 'utils';
import { useRequest } from 'hook/request.hook';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { FlatWithType } from 'view/Editor/context/editorContext/type';
import { GoToDrilledDataIcon } from 'view/Editor/components/TableBody/TableBodyCell/GoToDrilledDataIcon';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';
import { SchemaType } from 'view/Editor/helpers/getCellProps.helper';

import styles from './GenericDataValue.component.module.scss';

export type GenericDataValueProps = Pick<UIInputProps, 'variant' | 'error'> & {
  drillPath?: string[];
  value: FlatWithType;
  type?: string;
  isReadOnly?: boolean;
  onChange?: (value: FlatWithType) => void;
};

export const GenericDataValue: FC<GenericDataValueProps> = ({
  value,
  isReadOnly,
  error,
  type,
  drillPath,
  onChange,
}) => {
  const { action, state, fetch, request } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const { fetch: fetchTypes } = useRequest(request.getTypes);

  useEffect(() => {
    if (!state.allTypes) {
      fetchTypes().then(action.setAllTypesAction).catch(toast.error);
    }
    if (type) {
      void fetch.getAdditionalSchema({ type });
    }
  }, []);

  const typeOptions = useMemo<OptionType[] | null>(
    () =>
      state.allTypes ? state.allTypes.map((it) => ({ value: it.Name, label: it.Label, module: it.Module })) : null,
    [state.allTypes]
  );

  const currentType = useMemo<OptionType | null>(
    () => (type && typeOptions?.find((it) => it.value === type)) || null,
    [type, typeOptions]
  );

  const handleTypeChange = (schemaType: string | null) => {
    if (!schemaType) return;

    fetch
      .getFlatWithTypeDataFetch({ type: schemaType, nextTabIndex: tabState.tabIndex + 1 })
      .then((newData) => newData && onChange?.(newData));
  };

  const handleOpenGenericDataTab = () => {
    if (!drillPath || !type) return;

    const pathString = drillPath.toString();

    action.openEditorTabAction(
      {
        isFlat: true,
        isVector: false,
        title: pathString,
        key: pathString,
        path: drillPath,
        schemaType: SchemaType.GenericData,
        selector: action.findSchemaByEndWith(type) || '',
      },
      tabState.tabIndex
    );
  };

  return (
    <div className={styles.GenericDataValue}>
      <div className={styles.GenericDataValue__value}>
        <UIInput
          selectOnFocus
          readOnly
          variant="transparent"
          value={dataToString(value)}
          error={error}
          prefixElement={isDefined(drillPath) && <GoToDrilledDataIcon onClick={handleOpenGenericDataTab} />}
        />
      </div>
      {typeOptions && (
        <div className={styles.GenericDataValue__type}>
          <UiSelect
            className="h-100"
            isDisabled={isReadOnly}
            menuPortalTarget={document.body}
            value={currentType}
            options={typeOptions}
            onChange={(data) => handleTypeChange(data?.value || null)}
            isTransparent
          />
        </div>
      )}
    </div>
  );
};
