import { FC } from 'react';
import cx from 'classnames';

import { PdfHighlightType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';

import { PdfHighlight } from './components';

type PdfHighlightsProps = {
  className?: string;
  highlights: PdfHighlightType[];
  activeIndex: number;
};

export const PdfHighlights: FC<PdfHighlightsProps> = ({ className, highlights, activeIndex }) => {
  if (highlights.length === 0) return null;

  return (
    <div className={cx(className)}>
      {highlights.map((highlight, index) => (
        <PdfHighlight key={index} isActive={highlight.index === activeIndex} {...highlight} />
      ))}
    </div>
  );
};
