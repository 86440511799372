import { Fragment, ReactElement } from 'react';
import { Cell, Row } from '@tanstack/react-table';

import './UIGridBodyRow.style.scss';

type UIGridBodyRowProps<TData> = {
  row: Row<TData>;
  renderCell: <TValue>(cell: Cell<TData, TValue>) => ReactElement;
};

export const UIGridBodyRow = <TData,>({ row, renderCell }: UIGridBodyRowProps<TData>) => (
  <div className="ui-grid-body-row">
    {row.getVisibleCells().map((cell) => (
      <Fragment key={cell.id}>{renderCell(cell)}</Fragment>
    ))}
  </div>
);
