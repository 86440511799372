import { GridTabDataType } from 'store/types';

import { DefaultPanelSize, GridTabEnum, StackEnum } from '../constants';

export const makeStack = (tabs: GridTabDataType[]) => ({
  type: StackEnum.STACK,
  key: `stack-${tabs[0]?.id}`,
  id: `stack-${tabs[0]?.id}`,
  isMainGrid: tabs[0]?.id === GridTabEnum.MAIN,
  size: DefaultPanelSize.DEFAULT,
  content: tabs,
});
