import { EventManagerHandler } from 'utils/helpers/eventManager';
import { UIModalConfirmProps } from 'ui/UiModal/UiModalUtil/UIModalConfirm';

export enum UiModalEvent {
  Open = 'Open',
}

export enum UIModalMethodType {
  Info = 'info',
  Warning = 'warning',
  Delete = 'delete',
  Error = 'error',
  Confirm = 'confirm',
}

export type UiModalMethodProps = Omit<UIModalConfirmProps, 'onOk' | 'onCancel' | 'onClose'> & {
  onCancel?: (close: () => void) => void;
  onOk?: (close: () => void) => void;
};

export type UiModalEventType<T = unknown> = {
  subscribe: (callback: EventManagerHandler<T>) => void;
  emit: (data: T, type: UIModalMethodType) => void;
};
