import { SimpleValueType } from 'store/types';

export enum ValueTypesEnum {
  Data = 'Data',
  Bool = 'Bool',
  Double = 'Double',
  Long = 'Long',
  Int = 'Int',
  String = 'String',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Key = 'Key',
  TimeElement = 'TimeElement',
  DateTimeElement = 'DateTimeElement',
  Binary = 'Binary',
  Variant = 'Variant',
  Dict = 'Dict',
}

export const simpleValueTypes: Array<SimpleValueType> = [
  ValueTypesEnum.String,
  ValueTypesEnum.Bool,
  ValueTypesEnum.Int,
  ValueTypesEnum.Double,
  ValueTypesEnum.Long,
  ValueTypesEnum.Date,
  ValueTypesEnum.Time,
  ValueTypesEnum.DateTime,
  ValueTypesEnum.TimeElement,
  ValueTypesEnum.DateTimeElement,
];

export enum FormatTypesUnum {
  NUMBER_AMOUNT = 'NumberAmount',
  NUMBER_BASIS_POINT = 'NumberBp',
  NUMBER_PERCENT = 'NumberPercent',
}
