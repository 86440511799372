import { ColumnInstance, Row } from 'react-table';

import {
  ExcelCellType,
  ExcelCellValue,
  ExcelBodyColumns,
  ExcelCellObject,
  generateCell,
  exportToExcelFile,
  ExcelAdditionalColumns,
  generateAdditionalInfoCell,
  getColumnWidth,
  ExcelHeaderRows,
  generateHeaderCell,
} from 'services/excel';
import { DateFormatEnum, DateFormatUtility, isNumberType } from 'utils';

import { DataType, ExcelGridOptions } from './types';

const generateExcelHeader = (columns: ColumnInstance[]): ExcelHeaderRows =>
  columns.map((item) => generateHeaderCell(item.Header as string));

const generateExcelBody = (columns: ColumnInstance[], rows: Row<DataType>[]): ExcelBodyColumns =>
  rows.map((row) =>
    columns.reduce((arr, column) => {
      if (column.id in row.values) {
        const isNumberColumnType = isNumberType(column.columnType);
        const value = row.values[column.id];
        const formattedValue = column.valueFormatter ? column.valueFormatter(value) : value;

        const cell = generateCell(
          ((isNumberColumnType ? value : formattedValue) ?? '') as ExcelCellValue,
          isNumberColumnType ? ExcelCellType.NUMBER : ExcelCellType.STRING
        );

        arr.push(cell);
      }

      return arr;
    }, [] as ExcelCellObject[])
  );

const getExcelAdditionalInfoData = (rows: { name: string; value: ExcelCellValue }[]): ExcelAdditionalColumns => {
  const result = [] as ExcelAdditionalColumns;

  rows.forEach(({ name, value }) => {
    result.push([generateAdditionalInfoCell(name), generateAdditionalInfoCell(value)]);
  });

  return result;
};

export const downloadGridExcelHelper = (
  columns: ColumnInstance<DataType>[],
  rows: Row<DataType>[],
  { sheetName, additionalCells, selectedRowKeys }: ExcelGridOptions
) => {
  const availableColumns = columns.filter((column) => !column.suppressHideColumn);
  const headerColumns = generateExcelHeader(availableColumns);

  const filteredSelectedRowKeys: Row<DataType>[] = [];

  rows.forEach((row) => {
    if (selectedRowKeys.includes(row.id)) filteredSelectedRowKeys.push(row);
  });

  const bodyColumns = generateExcelBody(availableColumns, filteredSelectedRowKeys);

  const additionalInfoColumns = additionalCells ? getExcelAdditionalInfoData(additionalCells) : [];

  const currentDate = DateFormatUtility.create().format(DateFormatEnum.DATE);

  const fileName = `Report_${currentDate}.xlsx`;

  const columnWidths = headerColumns.map(() => getColumnWidth(20));

  const body = [...additionalInfoColumns, headerColumns, ...bodyColumns];

  exportToExcelFile({ fileName, sheetName, body, columnWidths });
};
