import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import Plotly from 'plotly.js-dist';
import cx from 'classnames';

import styles from './styles.module.scss';

type PlotlyViewerProps = {
  content: string;
};

export const PlotlyViewer: FC<PlotlyViewerProps> = ({ content }) => {
  const plotlyRef = useRef<HTMLDivElement>(null);
  const decodeBase64 = useMemo(() => decodeURIComponent(escape(window.atob(content))), [content]);

  const clearNode = useCallback(() => {
    if (!plotlyRef.current) return;

    while (plotlyRef.current.firstChild) {
      plotlyRef.current.removeChild(plotlyRef.current.firstChild);
    }
  }, []);

  useEffect(() => {
    if (plotlyRef.current) {
      window.Plotly = Plotly;

      const plotlyFragment = document.createRange().createContextualFragment(decodeBase64);

      plotlyRef.current.append(plotlyFragment);
    }

    return () => {
      window.Plotly = undefined;
      clearNode();
    };
  }, [decodeBase64, clearNode]);

  return <div ref={plotlyRef} className={cx('h-100 p-2 text-text1', styles.plotlyWrapper)} />;
};
