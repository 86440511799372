import { useEffect, useState } from 'react';

import { useRequest } from 'hook/request.hook';
import { getDashboardItems } from 'api/requests/initialScreen.api';
import { ProfileDto } from 'api/data/profile.data';

import { DefaultViewProfileMenu } from './components/DefaultViewProfileMenu';
import { useStartNewChat } from './utils/useStartNewChat';
import { MainScreenDefault } from './components/MainScreenDefault';

const DEFAULT_SCREEN = 'default';

export const InitialScreen = () => {
  const [activeScreen, setActiveScreen] = useState(DEFAULT_SCREEN);
  const startNewChat = useStartNewChat();
  const { state: dashboardItems, fetch: fetchDashboardItems } = useRequest(getDashboardItems);

  useEffect(() => {
    fetchDashboardItems();
  }, []);

  const profilesDictionary: { [key: string]: ProfileDto[] } =
    dashboardItems?.reduce((acc, dashboardItem) => {
      if (dashboardItem.Profiles) return { ...acc, [dashboardItem.Name]: dashboardItem.Profiles };
      return acc;
    }, {}) || {};

  const handleClickTile = (Name: string, Profiles?: ProfileDto[]) => () => {
    if (Profiles) {
      setActiveScreen(Name);
    } else {
      startNewChat(Name);
    }
  };

  return activeScreen === DEFAULT_SCREEN ? (
    <MainScreenDefault handleClickTile={handleClickTile} dashboardItems={dashboardItems} />
  ) : (
    <DefaultViewProfileMenu
      profiles={profilesDictionary[activeScreen]}
      name={activeScreen}
      onClickBack={() => {
        setActiveScreen(DEFAULT_SCREEN);
      }}
    />
  );
};
