import { FC, useEffect } from 'react';

import { EditorSubmitType } from 'view/Editor/type';
import { EditorUpdateType } from 'view/EditorData/type';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { Loader } from 'shared/Loader';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';

type EditorSingletonProps = {
  dataset?: string;
  isReadOnly?: boolean;
  recordType: string;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const EditorSingleton: FC<EditorSingletonProps> = ({ recordType, dataset, isReadOnly, onUpdate }) => {
  const { getRecord, saveEntity } = useEditorRequest();

  useEffect(() => {
    if (recordType) getEditorData();
  }, [recordType, dataset]);

  const handleSubmit = ({ data }: EditorSubmitType) => {
    saveEntity.fetch({ data, recordType }).then((result) => {
      onUpdate?.({ newKey: result.key });
    });
  };

  const getEditorData = () => {
    void getRecord.fetch({
      key: '',
      type: recordType,
      dataset,
    });
  };

  if (getRecord.isLoading) return <Loader />;

  if (!getRecord.state) return <UiEmpty />;

  return (
    <EditorInstance
      readOnly={isReadOnly}
      data={getRecord.state.data}
      schema={getRecord.state.schema.typeList}
      rootSchemaName={getRecord.state.schema.rootType}
      onCancel={getEditorData}
      onSubmit={handleSubmit}
    />
  );
};
