import { MutableRefObject, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useSelector } from 'react-redux';
import { ColumnInstance } from 'react-table';
import { t } from 'i18next';

import { DataType, GridModeEnum, GridRef, SelectedRowsRangeType } from 'view/Grid/utils/types';
import { NoGridData } from 'containers/TabItemContent/components/NoGridData';
import { getNoGridDataTitle } from 'containers/TabItemContent/utils/helper';
import { DraggableContainer } from 'view/DragnDrop/DraggableContainer';
import { TypeToolbar } from 'view/TypeToolbar';
import { TabSchemaDataType } from 'store/types/tabMetaDataTypes';
import { QueryBuilder } from 'view/QueryBuilder';
import { DraggableGridContainer } from 'view/DragnDrop/DraggableGridContainer';
import { draggableGridParams } from 'utils/types/draggableGridParamsType';
import { Grid } from 'view/Grid';
import { ConfigType } from 'view/DragnDrop/utils/types';
import { DropBox } from 'view/DragnDrop/DropBox';
import { DropBoxEnum, StackEnum } from 'view/DragnDrop/utils/constants';
import { GridToolbar } from 'view/GridToolbar';
import { HandlerType, KindTypeModel } from 'api';
import { useAppContext } from 'context';
import { isDefined } from 'utils';
import { EDITOR_TABS, EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { DraggableEditorContainer } from 'view/DragnDrop/DraggableEditorContainer';
import { makeStack } from 'view/DragnDrop/utils/helpers/makeStack';
import { prepareCustomFilterCellType } from 'containers/TabItemContent/utils/types';
import { dispatch } from 'store';
import { removeHandlerRecordsData } from 'store/shared-reducers/ToolbarHandlerSlice';
import { Loader } from 'shared/Loader';
import { selectIsReadonly } from 'store/selectors/AppState.selector';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';

import { EditorUpdateType } from 'view/EditorData/type';

import { useEntities, useFilters, useNewTab, useSorting, useViewSettings } from './hooks';
import { MainGridFilterCell } from './components/MainGridFilterCell';

type GridWithToolbarProps = {
  activeTypeTab: string;
  metaData: TabSchemaDataType;
};

export const MainTypeContainer = ({ activeTypeTab, metaData }: GridWithToolbarProps) => {
  const { typeViewSettings, addLayoutToSettings, isInitFetch } = useViewSettings(metaData.data);
  const {
    queryBuilder,
    pagination,
    status,
    loading,
    isSelectedLoading,
    columns,
    entities,
    error,
    selectedRow,
    selectedRangeRows,
    selectRangeRows,
    fetch: fetchEntities,
    selectRow,
    getRowId,
    selectRowByKeyWithDelay,
    deleteSelectedRangeRows,
    exportBulkEntities,
    saveRecordPermanently,
    selectedRowsPerPage,
    setSelectedRowsPerPage,
    refreshFlag,
    isSelectAllRecordsBtnClicked,
    setIsSelectAllRecordsBtnClicked,
    selectAllRecords,
    maxEntitiesCountRef,
  } = useEntities({
    tabSchema: metaData.data,
    useCache: typeViewSettings.UseCache,
    columnsState: typeViewSettings.ColumnsState,
  });
  const id = useId();
  const { initial: initialFilters, onChanged: onFiltersChanged } = useFilters(activeTypeTab);
  const { initial: initialSortBy, onChanged: onSortingChanged } = useSorting(
    activeTypeTab,
    typeViewSettings.ColumnsState
  );
  const { handler: generateTab } = useNewTab();

  const typeStateInitialFilters = useMemo(
    () =>
      typeViewSettings.ColumnsState?.reduce(
        (acc: { id: string; value: string }[], column) =>
          column.Filter ? [...acc, { id: column.Id, value: column.Filter }] : acc,
        []
      ) || [],

    [typeViewSettings]
  );

  const initiallyHiddenColumns = useMemo(
    () =>
      typeViewSettings.ColumnsState?.reduce(
        (acc: string[], column) => (column.Hidden ? [...acc, column.Id] : acc),
        []
      ) || [],

    [typeViewSettings]
  );

  const [isDefaultLayoutRestored, setIsDefaultLayoutRestored] = useState(false);

  const headers = useRef<ColumnInstance<DataType>[]>([]);

  const updateHeaders = useCallback((passedHeaders: ColumnInstance<DataType>[]) => {
    headers.current = [...passedHeaders];
  }, []);

  const gridSchema = useSelector(selectGridSchema);

  const { action, state } = useAppContext();
  const isAppStateReadOnly = useSelector(selectIsReadonly);
  const { state: tabItemState } = useTabItemContext();

  const isFinalReadOnly = isAppStateReadOnly || tabItemState.isTypeReadOnly;

  const cacheTypeData = state.cacheTabData?.[activeTypeTab] || null;
  const jobHandlers = metaData.data.Declare?.Handlers?.filter((handler) => handler.Type === HandlerType.JOB);
  const isRunHandlers = isDefined(jobHandlers) && jobHandlers.length !== 0 && !isFinalReadOnly;

  useEffect(() => {
    if (isRunHandlers && entities.length) dispatch(removeHandlerRecordsData());
  }, [entities]);

  const handleCancelEditorData = () => {
    action.setActiveEditorTabAction(
      gridSchema.data.Kind === KindTypeModel.ABSTRACT ? EditorTabsEnum.SELECT_TYPE : EditorTabsEnum.EDITOR
    );
  };

  const gridRef = useRef() as MutableRefObject<GridRef>;

  const onExportGridOptions = useCallback(() => gridRef.current.exportGridOptions, []);

  const ouUpdateDataByEditor = (data?: EditorUpdateType) => {
    fetchEntities(); // TODO: add the ability to select the page where this row exists.
    if (data && data.newKey) {
      selectRow((prevSelectedRow) => ({ ...prevSelectedRow, key: data.newKey || '' }));
    }
  };

  const processEntityCreation = (data?: EditorUpdateType) => {
    data && ouUpdateDataByEditor(data);
    handleCancelEditorData();
  };

  const handleRefresh = () => {
    action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);
    fetchEntities();
  };

  const handleToggleQueryBuilder = () =>
    queryBuilder.isShowQueryBuilder ? queryBuilder.closeQueryBuilder() : queryBuilder.showQueryBuilder();

  const processNoData = () => {
    const NO_DATA_EDITOR_SUPPORTED_TYPES = [EditorTabsEnum.NEW_ENTITY, EditorTabsEnum.SELECT_TYPE];

    if (NO_DATA_EDITOR_SUPPORTED_TYPES.includes(state.activeEditorTab)) {
      return (
        <DraggableEditorContainer
          isLoading={false}
          type={metaData.data.Name}
          showToolbar={true}
          stackRef={makeStack([EDITOR_TABS[state.activeEditorTab]])}
          onUpdate={processEntityCreation}
          isSingleTabStackDragging={true}
          configType={StackEnum.STACK}
        />
      );
    }

    if (loading || isInitFetch) {
      return <Loader />;
    }

    return (
      <NoGridData
        title={getNoGridDataTitle(status, error) || t('noData.inTheReport')}
        isRefreshButton
        handleButtonClick={fetchEntities}
      />
    );
  };

  const prepareCustomFilterCell = ({
    passedGridRef,
    passedColumns,
    gridMode,
    rows,
    onSelectedRowsRangeChanged,
    clearCellSelectionState,
    selectAllRowsAllCells,
    updateSelectedRowsPerPageState,
    passedSelectedRowsPerPage,
    isSelectAllRecordsBtnClicked: paramIsSelectAllRecordsBtnClicked,
  }: prepareCustomFilterCellType) => {
    if (pagination.range.end > maxEntitiesCountRef.current) {
      maxEntitiesCountRef.current = pagination.range.end;
    }

    if (!pagination.isAvailableNextPage && pagination.isAvailableNextPageRef.current) {
      pagination.isAvailableNextPageRef.current = false;
    }

    return (
      <MainGridFilterCell
        gridRef={passedGridRef}
        columns={passedColumns}
        gridMode={gridMode}
        rows={rows}
        onSelectedRowsRangeChanged={onSelectedRowsRangeChanged}
        clearCellSelectionState={clearCellSelectionState}
        selectAllRowsAllCells={selectAllRowsAllCells}
        maxEntitiesCount={maxEntitiesCountRef.current}
        isAvailableNextPageState={pagination.isAvailableNextPageRef.current}
        maxRangeCounter={pagination.range.end}
        selectedRowsPerPage={passedSelectedRowsPerPage}
        setSelectedRowsPerPage={setSelectedRowsPerPage}
        updateSelectedRowsPerPageState={updateSelectedRowsPerPageState}
        isSelectAllRecordsBtnClicked={!!paramIsSelectAllRecordsBtnClicked}
        setIsSelectAllRecordsBtnClicked={setIsSelectAllRecordsBtnClicked}
      />
    );
  };

  const renderGrid = () => (
    <Grid
      gridId={id}
      initialFilters={[...typeStateInitialFilters, ...initialFilters]}
      initialSortBy={initialSortBy}
      ref={gridRef}
      columns={columns}
      initiallyHiddenColumns={initiallyHiddenColumns}
      data={entities as DataType[]}
      gridName={activeTypeTab}
      gridMode={isFinalReadOnly ? GridModeEnum.READ_ONLY : GridModeEnum.MAIN}
      selectedRowId={selectedRow.key}
      selectedRowsRange={selectedRangeRows}
      getRowId={getRowId}
      onRowDoubleClicked={generateTab}
      onChangeSelectedRowId={(rowId) => {
        action.onConfirmLeaveEditor(() => selectRowByKeyWithDelay(rowId));
      }}
      onSelectedRowsRangeChanged={(newSelectedRowsRange: SelectedRowsRangeType | undefined) => {
        if (newSelectedRowsRange) {
          selectRangeRows(newSelectedRowsRange);
        }
      }}
      onFiltersChanged={onFiltersChanged}
      onSortingChanged={onSortingChanged}
      shouldClearCellsSelection={loading}
      customFilterCell={prepareCustomFilterCell}
      selectedRowsPerPage={selectedRowsPerPage}
      setSelectedRowsPerPage={setSelectedRowsPerPage}
      isSelectAllRecordsBtnClicked={isSelectAllRecordsBtnClicked}
      setIsSelectAllRecordsBtnClicked={setIsSelectAllRecordsBtnClicked}
      isRunHandlers={isRunHandlers}
      setHeaders={updateHeaders}
    />
  );

  const processGridComponent = (stackRef: ConfigType) => (
    <>
      <DropBox position={DropBoxEnum.INNER} stackRef={stackRef} />
      <GridToolbar
        isGridPageDataLoading={pagination.isPageDataLoading}
        type={activeTypeTab}
        selectedGridRowKey={selectedRow.key}
        entities={entities}
        paginationRange={pagination.range}
        paginationPage={pagination.page}
        isAvailablePreviousPage={pagination.isAvailablePreviousPage}
        isAvailableNextPage={pagination.isAvailableNextPage}
        isQueryApplied={queryBuilder.isQueryApplied}
        selectedRangeRows={selectedRangeRows}
        onExportGridOptions={onExportGridOptions}
        showQueryBuilder={handleToggleQueryBuilder}
        typeViewSettings={typeViewSettings}
        onDeleteSelectedRangeRows={deleteSelectedRangeRows}
        onExportBulkEntities={exportBulkEntities}
        onSaveRecordPermanently={saveRecordPermanently}
        onPreviousPage={pagination.onPreviousPage}
        onNextPage={pagination.onNextPage}
        onFirstPage={pagination.onFirstPage}
        onRefresh={handleRefresh}
        isSelectAllRecordsBtnClicked={isSelectAllRecordsBtnClicked}
        selectAllRecords={selectAllRecords}
        isAllRecordsSelected={Object.keys(selectedRangeRows).length === maxEntitiesCountRef.current}
        onRestoreDefaultLayout={() => setIsDefaultLayoutRestored(true)}
      />
      <div className="flex-grow-1 overflow-hidden">
        <PanelGroup direction="horizontal">
          {queryBuilder.isShowQueryBuilder && (
            <>
              <Panel id="main-type-container-query-builder" minSize={30} defaultSize={40} maxSize={70} order={1}>
                <QueryBuilder
                  initialQuery={cacheTypeData?.query || queryBuilder.query}
                  elements={queryBuilder.queryBuilderElements}
                  renderHeader={queryBuilder.renderQueryBuilderHeader}
                  onCancel={queryBuilder.closeQueryBuilder}
                  onApply={queryBuilder.applyQuery}
                  onReset={queryBuilder.resetQuery}
                />
              </Panel>
              <PanelResizeHandle className="verticalPanelResizer" />
            </>
          )}
          <Panel id="main-type-container-grid" order={2}>
            {entities.length ? renderGrid() : processNoData()}
          </Panel>
        </PanelGroup>
      </div>
    </>
  );

  const createDraggableGridContainer = ({ tabs, stackRef, type }: draggableGridParams) => (
    <DraggableGridContainer
      selectedGridRow={selectedRow}
      tabs={tabs}
      stackRef={stackRef}
      type={type}
      onUpdate={ouUpdateDataByEditor}
    >
      {processGridComponent(stackRef)}
    </DraggableGridContainer>
  );

  return (
    <>
      <TypeToolbar
        selectedRowKey={selectedRow.key}
        selectedRowType={selectedRow.type}
        selectedRangeRows={selectedRangeRows}
      />
      <DraggableContainer
        isLoading={isSelectedLoading || (selectedRow?.key === '' && selectedRow?.type === '') || loading}
        withKey={true}
        type={metaData.data.Name}
        selectedGridRow={selectedRow}
        showEditor={!(typeViewSettings.LayoutSettings.HideEditor || entities.length === 0)}
        draggableGrid={createDraggableGridContainer}
        onUpdate={ouUpdateDataByEditor}
        refreshFlag={refreshFlag}
        isDefaultLayoutRestored={isDefaultLayoutRestored}
        setIsDefaultLayoutRestored={setIsDefaultLayoutRestored}
        schemaTypeData={metaData.data}
        data={typeViewSettings}
        addLayoutToSettings={addLayoutToSettings}
        headers={headers}
      />
    </>
  );
};
