import { FC, ReactNode, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { UiModal } from 'ui/UiModal';
import { InfoIcon, UIButton } from 'ui';
import './InfoModalWithCheckbox.scss';

type InfoModalWithCheckbox = {
  isOpen: boolean;
  title?: string;
  content?: ReactNode | string;
  onClose: () => void;
  onConfirm: (value?: boolean) => void;
  isWithDependencies?: boolean;
};

export const InfoModalWithCheckbox: FC<InfoModalWithCheckbox> = ({
  isOpen,
  title,
  content,
  onClose,
  onConfirm,
  isWithDependencies = true,
}) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    isOpen && setIsChecked(true);
  }, [isOpen]);

  const handleConfirm = () => (isWithDependencies ? onConfirm(isChecked) : onConfirm());

  return (
    <UiModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      defaultHeight={150}
      defaultWidth={460}
      minHeightConstraints={150}
      minWidthConstraints={460}
    >
      <UiModal.Body>
        <div className="info-modal-with-checkbox__wrapper">
          <div className="info-modal-with-checkbox__icon">
            <InfoIcon size="Full" />
          </div>
          <div className="info-modal-with-checkbox__content">{content}</div>
        </div>
      </UiModal.Body>
      <UiModal.Footer>
        <div className="info-modal-with-checkbox__footer">
          {isWithDependencies && (
            <label className="info-modal-with-checkbox__checkbox-wrapper">
              <Input readOnly checked={isChecked} onChange={() => setIsChecked(!isChecked)} type="checkbox" />
              {t('confirmationModal.withDependencies')}
            </label>
          )}
          <div className="info-modal-with-checkbox__nav">
            <UIButton variant="secondary" onClick={onClose}>
              {t('buttons.no')}
            </UIButton>
            <UIButton onClick={handleConfirm}>{t('buttons.yes')}</UIButton>
          </div>
        </div>
      </UiModal.Footer>
    </UiModal>
  );
};
