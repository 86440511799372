import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { QueryBuilderIndexDropdown } from '../QueryBuilderIndexDropdown/QueryBuilderIndexDropdown.component';

type QueryBuilderIntroductionProps = {
  className?: string;
};

export const QueryBuilderIntroduction: FC<QueryBuilderIntroductionProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx('d-flex', className)}>
      <div className="d-flex flex-column gap-3 m-auto">
        <h6 className="text-center text-text2 m-0 fw-normal">{t('queryBuilder.introduction')}</h6>
        <QueryBuilderIndexDropdown />
      </div>
    </div>
  );
};
