export enum FileStatusType {
  NONE = 'none',
  ADDED = 'added',
  UPDATED = 'updated',
  REMOVED = 'removed',
}

export const FileStatusMap: Record<string, FileStatusType> = {
  Added: FileStatusType.ADDED,
  Removed: FileStatusType.REMOVED,
  Updated: FileStatusType.UPDATED,
};

export type FileType = {
  filename: string;
  status: FileStatusType;
  savedStatus: FileStatusType;
  isSaved: boolean;
  hasExclusions?: boolean;
};

export type MessageType = {
  files: FileType[];
  text: string;
  id: string;
};

export enum MessageGroupStatus {
  INITIAL,
  LOADING,
  ERROR,
  SUCCESS,
  CANCELED,
}

export type MessageGroupType = {
  status: MessageGroupStatus;
  id: string;
  question?: MessageType;
  answer?: MessageType;
};

export enum ChatType {
  PRIVATE = 'private',
  INTERNAL = 'internal',
  ENCRYPTED = 'encrypted',
}

export type SessionType = {
  ChatKey: string;
  Created: string;
  IsEncrypted: boolean;
  Profile: string;
  Name?: string;
  IsClo?: boolean;
  IsUserProfile?: boolean;
  LlmToUse?: string;
};

export enum CloExclusionsState {
  Hidden = 'Hidden',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
}

export type CloExclusionsStateType = {
  state: CloExclusionsState;
  isHiddenState: boolean;
  isDisabled: boolean;
  isEnabled: boolean;
};

export type ProgressOutputType = {
  message: string;
  done: boolean;
};
