export enum LoadingStatusesEnum {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ErrorResponseDto = {
  Message: string | Record<string, string[]>;
};

export type LambdaConverter<In, Out> = (source: In) => Out;

export enum QueryOperatorEnum {
  Not = 'Not',
  And = 'And',
  Or = 'Or',
  Nor = 'Nor',
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Less = 'Less',
  LessOrEqual = 'LessOrEqual',
  More = 'More',
  MoreOrEqual = 'MoreOrEqual',
  In = 'In',
  NotIn = 'NotIn',
  Contains = 'Contains',
  Size = 'Size',
  All = 'All',
  Exists = 'Exists',
  EndsWith = 'EndsWith',
  StartsWith = 'StartsWith',
}

export enum RunHandlerStatusCodeEnum {
  Empty = 'Empty',
  Submitted = 'Submitted',
  Running = 'Running',
  Paused = 'Paused',
  Completed = 'Completed',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
}
