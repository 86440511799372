import { ButtonHTMLAttributes, FC } from 'react';
import cx from 'classnames';

import './UIButton.styles.scss';

export type UIButtonVariantType = 'primary' | 'secondary' | 'destructive' | 'success' | 'warning' | 'icon';

export type UIButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: UIButtonVariantType;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export const UIButton: FC<UIButtonProps> = ({
  className,
  variant = 'primary',
  disabled,
  icon,
  iconPosition = 'start',
  children,
  ...rest
}) => {
  const classes = cx('ui-button', `ui-button--${variant}`, className);

  const content = <span className="ui-button__text">{children}</span>;

  return (
    <button className={classes} disabled={disabled} {...rest}>
      {iconPosition === 'start' ? (
        <>
          {icon}
          {content}
        </>
      ) : (
        <>
          {content}
          {icon}
        </>
      )}
    </button>
  );
};
