import { FC, useMemo, useRef } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger, useDisplayTooltip } from 'shared/Tooltip';
import { FileStatusType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import styles from './styles.module.scss';

type FileItemProps = {
  status?: FileStatusType;
  name: string;
  isLoading?: boolean;
  isRemovable?: boolean;
  onRemove?: () => void;
};

export const FileItem: FC<FileItemProps> = ({
  status = 'none',
  name,
  isLoading = false,
  isRemovable = true,
  onRemove,
}) => {
  const fileNameRef = useRef<HTMLDivElement | null>(null);
  const enabledTooltip = useDisplayTooltip(fileNameRef);

  const isShowRemoveButton = useMemo(() => !isLoading && isRemovable, [isLoading, isRemovable]);
  const isShowStatus = useMemo(() => status !== 'none', [status]);

  return (
    <>
      <div
        className={cx(
          styles.chip,
          'd-flex gap-2 align-items-center text-text1 px-2 py-1 bg-gray-600 rounded fs-13 lh-16'
        )}
      >
        <Icon SvgIcon={APP_ICONS.file} clickable={false} />
        <Tooltip enabled={enabledTooltip}>
          <TooltipTrigger ref={fileNameRef} className="text-truncate">
            {name}
          </TooltipTrigger>
          <TooltipContent>{name}</TooltipContent>
        </Tooltip>
        {isShowStatus && <span>({status})</span>}
        {isShowRemoveButton && (
          <Icon className="ms-auto flex-shrink-0" SvgIcon={APP_ICONS.bigClose} onClick={onRemove} />
        )}
      </div>
    </>
  );
};
