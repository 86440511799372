import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { useAppNavigate } from 'router/hooks';

export const DefaultRedirectGuard = () => {
  const { navigateToEnvironment } = useAppNavigate();

  const activeEnvironment = useSelector(selectActiveEnvironment);

  useEffect(() => {
    if (activeEnvironment) {
      navigateToEnvironment(encodeURIComponent(activeEnvironment));
    }
  }, [activeEnvironment]);

  return <AppFallbackSkeleton />;
};
