import BigNumber from 'bignumber.js';

import { QueryOperatorEnum } from 'api/type';

export const CUSTOM_INDEX = 'custom';

export type ValueWithConditionType = {
  value: string | number | BigNumber | boolean | null;
  condition: QueryOperatorEnum;
};

export type QueryType = Record<string, ValueWithConditionType>;
