import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { UiSelect } from 'ui';
import { useQueryBuilderContext } from 'view/QueryBuilder/hooks';

import styles from './QueryBuilderIndexDropdown.module.scss';

export const QueryBuilderIndexDropdown = () => {
  const { t } = useTranslation();
  const { selectedIndex, selectIndex, indexes } = useQueryBuilderContext();
  const selectedOption = useMemo(
    () => indexes.find((index) => index.value === selectedIndex),
    [indexes, selectedIndex]
  );

  return (
    <div className={cx('d-flex align-items-center', styles.gap)}>
      <div className={cx('bg-query-background rounded fs-13 lh-16 py-1 text-text1', styles.label)}>
        {t('queryBuilder.label')}
      </div>
      <UiSelect
        className={cx('bg-query-background rounded h-100', styles.dropdown)}
        menuPortalTarget={document.body}
        value={selectedOption}
        options={indexes}
        onChange={(selected) => selectIndex(selected?.value)}
      />
    </div>
  );
};
