import { FC, memo, useEffect, useState } from 'react';

import { DividerContainer } from 'shared/DividerContainer';
import { DraggableContainer } from 'view/DragnDrop/DraggableContainer';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { storageApi } from 'api';
import { TypeToolbar } from 'view/TypeToolbar';
import { useRequest } from 'hook/request.hook';
import { toast } from 'utils';

type SingletonContainerProps = {
  type: string;
  moduleName: string;
};

export const SingletonContainer: FC<SingletonContainerProps> = memo(({ type, moduleName }) => {
  const { fetch, state, isLoading } = useRequest(storageApi.select);

  const [tabs, setTabs] = useState<EditorTabsEnum[]>([]);
  const entitiesLength = state?.data.length ?? 0;

  const fetchTabs = async () => {
    setTabs([]);

    if (type) {
      try {
        const select = await fetch({ params: { type, module: moduleName, limit: 1 } });
        setTabs([select.data.length === 0 ? EditorTabsEnum.NEW_ENTITY : EditorTabsEnum.EDITOR]);
      } catch (e) {
        toast.error(e);
      }
    }
  };

  const handleUpdate = () => {
    fetchTabs();
  };

  useEffect(() => {
    if (type) {
      fetchTabs();
    }
  }, [type, moduleName]);

  if (!state || isLoading || tabs.length === 0) return null;

  return (
    <DividerContainer>
      <TypeToolbar />
      <DraggableContainer
        withKey={false}
        type={type}
        tabs={tabs}
        onUpdate={handleUpdate}
        showEditor
        entitiesLength={entitiesLength}
      />
    </DividerContainer>
  );
});

SingletonContainer.displayName = 'SingletonContainer';
