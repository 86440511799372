import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Button } from 'reactstrap';

import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './PDFSearchToggle.module.scss';

type PDFSearchToggleProps = { onClick: () => void };

export const PDFSearchToggle: FC<PDFSearchToggleProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Tooltip>
      <TooltipTrigger className="align-self-center">
        <Button
          className={cx(
            'd-flex align-items-center justify-content-center border-0 p-0 rounded bg-transparent',
            styles.button
          )}
          onClick={onClick}
        >
          <Icon clickable={false} SvgIcon={APP_ICONS.search} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('viewers.pdf.search')}</TooltipContent>
    </Tooltip>
  );
};
