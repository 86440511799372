import { FC, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { UIModalConfirm } from './UIModalConfirm';
import { uiModalCreate } from './config';
import { UiModalMethodProps } from './type';

export const UiModalContainer: FC = () => {
  const [stack, setStack] = useState<Record<string, UiModalMethodProps>>({});

  useEffect(() => {
    uiModalCreate.subscribe((data) => data && createModal(data));
  }, []);

  const createModal = (data: UiModalMethodProps) => {
    setStack((state) => {
      state[uuid()] = data;
      return { ...state };
    });
  };

  const removeModal = (key: string) => {
    setStack((state) => {
      delete state[key];
      return { ...state };
    });
  };

  const handleCancel = (onCancel: UiModalMethodProps['onCancel'], key: string) => {
    if (onCancel) {
      onCancel(() => removeModal(key));
    } else {
      removeModal(key);
    }
  };

  const handleOk = (onOk: UiModalMethodProps['onOk'], key: string) => {
    if (onOk) {
      onOk(() => removeModal(key));
    } else {
      removeModal(key);
    }
  };

  return (
    <>
      {Object.entries(stack)?.map(([key, data]) => (
        <UIModalConfirm
          key={key}
          {...data}
          onClose={() => removeModal(key)}
          onOk={() => handleOk(data?.onOk, key)}
          onCancel={() => handleCancel(data?.onCancel, key)}
        />
      ))}
    </>
  );
};
