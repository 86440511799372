import { ChangeEvent, useState } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { env } from 'env';
import { supportApi } from 'api';
import { toast } from 'utils';
import { UIButton, UiModal } from 'ui';
import { useBoolean } from 'utils/hooks';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { InputTitle } from 'view/Logs/components/SendLogsModal/components/InputTitle';
import { ToField } from 'view/Logs/components/SendLogsModal/components/ToField';
import { TextareaFormatting } from 'view/Logs/components/SendLogsModal/components/TextareaFormatting';
import { selectApplicationName } from 'store/selectors/AppState.selector';
import { useAppDispatch } from 'store/hooks';
import { selectSendLogsData } from 'view/Logs/store/selectors';
import { cleanSendLogsData } from 'store/shared-reducers/SendLogsDataSlice';

import styles from './styles.module.scss';

export const SendFeedbackButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applicationName = useSelector(selectApplicationName);
  const { emailTags } = useSelector(selectSendLogsData);
  const [isSendFeedbackModalOpen, { toggle: toggleFeedbackModal }] = useBoolean();

  const initialSubjectValue = t('sendLogsModal.initialSubjectValue', {
    applicationName: applicationName || 'CL-UI',
    frontendVersion: env.frontendVersion,
  });

  const [subjectValue, setSubjectValue] = useState(initialSubjectValue);
  const [feedbackTextareaValue, setFeedbackTextareaValue] = useState('');
  const [feedbackFiles, setFeedbackFiles] = useState<File[]>([]);

  const handleSendButtonClick = () => {
    supportApi
      .sendFeedback()
      .then(() => {
        toast.success(t('shared.success'));
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const processFeedbackIconClick = () => {
    if (env.isFeedbackLocal) {
      handleSendButtonClick();
    } else {
      toggleFeedbackModal();
    }
  };

  const handleSendFeedback = () => {
    if (env.isFeedbackLocal) {
      handleSendButtonClick();
    } else {
      console.log('Feedback is sent with the next data: ', {
        to: emailTags,
        subject: subjectValue,
        text: feedbackTextareaValue,
        files: feedbackFiles,
      });
    }

    toggleFeedbackModal();
    setSubjectValue(initialSubjectValue);
  };

  const closeSendFeedBackModal = () => {
    toggleFeedbackModal();
    dispatch(cleanSendLogsData());

    // This is to preved case when user see 'Subject' changes before modal close
    setTimeout(() => {
      setSubjectValue(initialSubjectValue);
    });
  };

  const processSubjectValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSubjectValue(e.target.value);
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger>
          <Button className={cx('border-none bg-transparent p-1')} onClick={processFeedbackIconClick}>
            <Icon SvgIcon={APP_ICONS.sendLetter} className="text-header-icon" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('header.sendFeedback')}</TooltipContent>
      </Tooltip>

      <UiModal
        size="lg"
        defaultHeight={450}
        isOpen={isSendFeedbackModalOpen}
        title={t('sendLogsModal.feedback') || ''}
        onClose={closeSendFeedBackModal}
      >
        <UiModal.Body>
          <div className="d-flex mb-1">
            <InputTitle title={t('sendLogsModal.to')} />
            <ToField />
          </div>

          <div className="d-flex mb-3_5">
            <InputTitle title={t('sendLogsModal.subject')} />
            <input className={styles.fieldBlock} value={subjectValue} onChange={processSubjectValueChange} />
          </div>

          <TextareaFormatting setFeedbackTextareaValue={setFeedbackTextareaValue} setFeedbackFiles={setFeedbackFiles} />
        </UiModal.Body>

        <UiModal.Footer>
          <div className={styles.flexEnd}>
            <UIButton variant="secondary" className="me-2_5" onClick={closeSendFeedBackModal}>
              {t('buttons.cancel')}
            </UIButton>

            <UIButton onClick={handleSendFeedback}>{t('buttons.send')}</UIButton>
          </div>
        </UiModal.Footer>
      </UiModal>
    </>
  );
};
