import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import { Button } from 'reactstrap';

import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { KeyboardCode } from 'utils';
import { FileType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { ChatInputButton } from './components/ChatInputButton';

import styles from './styles.module.scss';

type ChatInputProps = {
  isExecuteAnalyzer: boolean;
  isSending: boolean;
  isLoading: boolean;
  isProfileChosen: boolean;
  input: string;
  isShowMaskIcon?: boolean;
  onChangeInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSendMessage: () => void;
  cancelSendMessage: () => void;
  openFileExplorer: () => void;
  openKeyDialog: () => void;
  getUnsavedFiles: () => FileType[];
};

export type ChatInputRefType = {
  focus: () => void;
};

export const ChatInput = memo(
  forwardRef<ChatInputRefType, ChatInputProps>(
    (
      {
        isExecuteAnalyzer,
        isSending,
        isLoading,
        isProfileChosen,
        input,
        onChangeInput,
        onSendMessage,
        cancelSendMessage,
        openFileExplorer,
        /* openKeyDialog */
        isShowMaskIcon = false,
        getUnsavedFiles,
      },
      ref
    ) => {
      const { t } = useTranslation();
      const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
      const unsavedFiles = getUnsavedFiles();

      const isInputDisabled = !isProfileChosen || isLoading !== isSending;
      const isSendingDisabled = !isProfileChosen || (input.length === 0 && unsavedFiles.length === 0);
      const isAttachFileDisabled = !isProfileChosen || isLoading;

      const placeholder = useMemo(() => {
        if (!isProfileChosen) return t('chat.pleaseStartNewChat');
        if (isSending && !isExecuteAnalyzer) return t('chat.cancelRequest');
        if (isLoading) return t('chat.loading');
        return t('chat.sendMessage');
      }, [isSending, t, isLoading, isProfileChosen]);

      const focus = useCallback(() => textAreaRef.current?.focus(), []);

      useEffect(() => {
        !isLoading && focus();
      }, [isLoading, focus]);

      useImperativeHandle(ref, () => ({
        focus,
      }));

      const onEnterPress = useCallback(
        (event: KeyboardEvent) => {
          const isEnterPressed =
            (event.code as KeyboardCode) === KeyboardCode.Enter ||
            (event.code as KeyboardCode) === KeyboardCode.NumpadEnter;

          if (isEnterPressed && !event.shiftKey) {
            event.preventDefault();

            if (isLoading) return;

            onSendMessage();
          }
        },
        [onSendMessage, isLoading]
      );

      return (
        <div className="p-2 position-relative d-flex gap-2">
          {isShowMaskIcon && (
            <Icon className={cx('position-absolute', styles.maskIcon)} SvgIcon={APP_ICONS.mask} clickable={false} />
          )}
          <TextareaAutosize
            ref={textAreaRef}
            minRows={1}
            maxRows={8}
            value={input}
            onChange={onChangeInput}
            placeholder={placeholder}
            disabled={isInputDisabled}
            onKeyDown={onEnterPress}
            readOnly={isExecuteAnalyzer}
            className={cx(
              'form-control bg-chat-input-background border-chat-input-border text-chat-input-text fs-13 w-100 px-3 py-2',
              styles.searchBar,
              {
                [styles.private]: isShowMaskIcon,
              }
            )}
          />
          <div className={cx('position-absolute d-flex align-items-center gap-2', styles.iconWrapper)}>
            <ChatInputButton
              isSending={isSending && !isExecuteAnalyzer}
              isLoading={isLoading}
              isDisabledSending={isSendingDisabled}
              onSendMessage={onSendMessage}
              cancelSendMessage={cancelSendMessage}
            />
            <Button
              className="bg-transparent p-0 border-0 rounded"
              onClick={openFileExplorer}
              disabled={isAttachFileDisabled}
            >
              <Icon SvgIcon={APP_ICONS.attach} />
            </Button>
            {/* ToDo: Temporary hide key modal execution button */}
            {/* <Button */}
            {/*  className="bg-transparent p-0 border-0 rounded" */}
            {/*  disabled={isAttachFileDisabled} */}
            {/*  onClick={openKeyDialog} */}
            {/* > */}
            {/*  <Icon SvgIcon={APP_ICONS.keyMedium} /> */}
            {/* </Button> */}
          </div>
        </div>
      );
    }
  )
);

ChatInput.displayName = 'ChatInput';
