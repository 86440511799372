/* eslint-disable */
import BigNumber from 'bignumber.js';

export const deepClone = (obj: any) => {
  if (obj === null) return null;

  const getClone = (data: any): any => {
    if (BigNumber.isBigNumber(data)) {
      return new BigNumber(data.toString());
    }

    return { ...data };
  };

  const clone = getClone(obj);

  Object.keys(clone).forEach((key) => (clone[key] = typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]));

  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
    ? Array.from(obj)
    : clone;
};
