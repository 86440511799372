import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Row } from 'react-table';

import { useRequest } from 'hook/request.hook';
import { StorageItemModel, schemaApi, storageApi } from 'api';
import { isArray, toast } from 'utils';
import { mapMetadataToGridColumnsHelper } from 'view/Grid/utils/helpers';
import { UiModal } from 'ui';
import { Button } from 'shared/Button';
import { QueryBuilder, QueryType } from 'view/QueryBuilder';
import { usePagination } from 'shared/Hooks/usePagination/pagination.hook';
import { useQueryBuilder } from 'shared/Hooks/useQueryBuilder/queryBuilder.hook';
import { DataType } from 'view/Grid/utils/types';

import { GenericTypesTable } from './GenericTypesTable.component';
import { GenericRecordsTable } from './GenericRecordsTable.component';
import { GenericToolbar } from './GenericToolbar.component';

export type GenericModalSubmitDataType = { type: string; recordKey: string };

type GenericModalProps = {
  isOpen: boolean;
  initialGenericType?: string;
  initialGenericRecordKey?: string;
  onSubmit: (values: GenericModalSubmitDataType) => void;
  onClose: () => void;
};

export const GenericModal: FC<GenericModalProps> = ({
  isOpen,
  initialGenericType,
  initialGenericRecordKey,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();

  const pagination = usePagination();
  const queryBuilder = useQueryBuilder();

  const {
    fetch: fetchSelect,
    state: records,
    isLoading: isRecordsLoading,
    hasError: isRecordsError,
  } = useRequest(storageApi.select, { onError: toast.error });

  const {
    isLoading: isLoadingTypes,
    fetch: fetchTypes,
    hasError: isTypesError,
    state: types,
  } = useRequest(schemaApi.getTypes, {
    onError: toast.error,
  });

  const [selectedGenericType, setSelectedGenericType] = useState(initialGenericType);
  const [selectedGenericRecordKey, setSelectedGenericRecordKey] = useState(initialGenericRecordKey);
  const [entities, setEntities] = useState<StorageItemModel[]>([]);

  const recordsSchema = records?.schema.typeList[records.schema.rootType];

  const isSubmitDisabled = !selectedGenericType || !selectedGenericRecordKey;

  const genericRecordColumns = useMemo(
    () =>
      recordsSchema?.Elements && isArray(recordsSchema?.Elements)
        ? mapMetadataToGridColumnsHelper(recordsSchema.Elements)
        : [],
    [recordsSchema]
  );

  const onApplyQuery = (query: QueryType) => {
    queryBuilder.apply(query);
    pagination.onResetPage();
  };

  const resetQuery = () => {
    pagination.onResetPage();
    queryBuilder.reset();
  };

  const clearSelectedGenericRecordKey = () => setSelectedGenericRecordKey(undefined);

  const getGenericData = async () => {
    if (!selectedGenericType) throw new Error('Cannot fetch data with empty type');

    const limit = pagination.getLimit();
    const skip = pagination.getSkipByCurrentPage();

    try {
      const genericData = await fetchSelect({
        params: {
          type: selectedGenericType,
          skip,
          limit,
          table_format: true,
        },
        dto: queryBuilder.mapQueryToDTO(),
      });

      pagination.updateRange(pagination.sliceEntitiesByLimit(genericData.data).length);
      pagination.reset();
      pagination.updatePagination(genericData.data);

      const paginatedEntities = pagination.sliceEntitiesByLimit(genericData.data);

      const isExistRecordByKey = genericData.data.some((record) => record._key === selectedGenericRecordKey);
      if (!isExistRecordByKey) {
        clearSelectedGenericRecordKey();
      }

      setEntities(paginatedEntities);
    } finally {
      pagination.reset();
    }
  };

  useEffect(() => {
    if (selectedGenericType) {
      getGenericData();
    }
  }, [selectedGenericType, queryBuilder.query, pagination.page]);

  useEffect(() => {
    void fetchTypes();
  }, []);

  const handleSubmit = (row?: Row<DataType>) => {
    if (isSubmitDisabled) return;
    onSubmit({
      type: selectedGenericType,
      recordKey: row?.id || selectedGenericRecordKey,
    });
    onClose();
  };

  const isError = isTypesError || isRecordsError;

  const renderModalContent = () => {
    if (isError) {
      // ToDo: Provide fallback by design.
      return <div className="d-flex w-100 h-100 justify-content-center align-items-center">Something went wrong</div>;
    }

    return (
      <PanelGroup direction="horizontal">
        <Panel defaultSize={queryBuilder.isShow ? 25 : 40} order={1}>
          <div className="h-100 border border-chat-tabs-bottom-border">
            <GenericTypesTable
              isLoading={isLoadingTypes}
              genericTypes={types}
              selectedGenericType={selectedGenericType}
              onSelectGenericType={setSelectedGenericType}
            />
          </div>
        </Panel>
        <PanelResizeHandle className="verticalPanelResizerModal" />
        {queryBuilder.isShow && (
          <>
            <Panel minSize={30} defaultSize={35} maxSize={70} order={2}>
              <div className="h-100 border border-chat-tabs-bottom-border">
                <QueryBuilder
                  initialQuery={queryBuilder.query}
                  elements={queryBuilder.generateElementsBySchema(recordsSchema, records?.schema.typeList)}
                  renderHeader={queryBuilder.renderHeader}
                  onCancel={queryBuilder.close}
                  onApply={onApplyQuery}
                  onReset={resetQuery}
                />
              </div>
            </Panel>
            <PanelResizeHandle className="verticalPanelResizerModal" />
          </>
        )}
        <Panel defaultSize={queryBuilder.isShow ? 40 : 60} order={3}>
          <div className="d-flex flex-column h-100 border border-chat-tabs-bottom-border">
            <div className="flex-grow-0">
              <GenericToolbar
                isGridPageDataLoading={pagination.isPageDataLoading}
                entities={entities}
                paginationRange={pagination.generateRangeByEntities(entities)}
                paginationPage={pagination.page}
                isAvailablePreviousPage={pagination.isAvailablePreviousPage}
                isAvailableNextPage={pagination.isAvailableNextPage}
                isQueryApplied={queryBuilder.isApplied}
                showQueryBuilder={queryBuilder.show}
                onPreviousPage={pagination.onPreviousPage}
                onNextPage={pagination.onNextPage}
                onFirstPage={pagination.onFirstPage}
              />
            </div>
            <div className="flex-grow-1 overflow-auto">
              <GenericRecordsTable
                data={entities}
                columns={genericRecordColumns}
                isLoading={isRecordsLoading || isLoadingTypes}
                selectedGenericRecordKey={selectedGenericRecordKey}
                onSelectedGenericRecordKey={setSelectedGenericRecordKey}
                onRowDoublerClick={handleSubmit}
              />
            </div>
          </div>
        </Panel>
      </PanelGroup>
    );
  };

  return (
    <UiModal isOpen={isOpen} defaultHeight={400} size="xl" title={t('editor.modalHeader') ?? ''} onClose={onClose}>
      <UiModal.Body className="overflow-auto">
        <div className="d-flex overflow-hidden w-100 h-100">{renderModalContent()}</div>
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={t('buttons.cancel')} variant="secondary" onClick={onClose} />
        <Button title={t('buttons.apply')} disabled={isSubmitDisabled} onClick={handleSubmit} />
      </UiModal.Footer>
    </UiModal>
  );
};
