import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getErrorMessage, schemaApi, LoadingStatusesEnum, SchemaTypesItemModel } from 'api';
import { SchemaSearchType } from 'view/Header/utils/types';
import { filterSchemaTypes } from 'view/Header/utils/helpers';
import { isString, toast } from 'utils';

type MainSearchState = {
  schemaTypes: SchemaSearchType;
  status: LoadingStatusesEnum;
  error: string;
};

const initialState: MainSearchState = {
  schemaTypes: {} as SchemaSearchType,
  status: LoadingStatusesEnum.INITIAL,
  error: '',
};

export const getSchemaTypes = createAsyncThunk('getSchemaTypes', async (_, { rejectWithValue }) => {
  try {
    return schemaApi.getTypes();
  } catch (e) {
    toast.error(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const SchemaTypesSlice = createSlice({
  name: 'SchemaTypesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getSchemaTypes.pending, (state) => {
        state.status = LoadingStatusesEnum.LOADING;
        state.error = '';
      })
      .addCase(getSchemaTypes.fulfilled, (state, action: PayloadAction<SchemaTypesItemModel[]>) => {
        state.schemaTypes = filterSchemaTypes(action.payload);
        state.status = LoadingStatusesEnum.SUCCESS;
      })
      .addCase(getSchemaTypes.rejected, (state, action) => {
        state.status = LoadingStatusesEnum.ERROR;
        state.error = isString(action.payload) ? action.payload : '';
      }),
});

export const SchemaTypesSliceReducer = SchemaTypesSlice.reducer;
