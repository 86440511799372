import { Cell, flexRender } from '@tanstack/react-table';

import './UIBodyCell.style.scss';

type UIBodyCellProps<TData, TValue> = {
  cell: Cell<TData, TValue>;
};

export const UIBodyCell = <TData, TValue>({ cell }: UIBodyCellProps<TData, TValue>) => (
  <div className="ui-grid-body-cell" style={{ width: cell.column.getSize() }}>
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </div>
);
