import { CSSObjectWithLabel, GroupBase, StylesConfig, Theme, ThemeConfig } from 'react-select';

import { isObject } from 'utils';

import { OptionType } from './types';

type StylesProps<
  K extends keyof StylesConfig<TOption, isMulti, Group>,
  TOption = OptionType,
  isMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
> = Parameters<NonNullable<StylesConfig<TOption, isMulti, Group>[K]>>[1];

export const themeConfig: ThemeConfig = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'var(--bs-gray-600)',
    primary25: 'var(--bs-gray-900)',
    primary50: 'var(--bs-gray-600)',
  },
});

export const generateStyles = <
  TOption = OptionType,
  isMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
>(
  overrideStyles?: StylesConfig<TOption, isMulti, Group> & { isTransparent?: boolean }
): StylesConfig<TOption, isMulti, Group> => {
  const applyOverrideStyles = <K extends keyof StylesConfig<TOption, isMulti, Group>>(
    key: K,
    baseStyles: CSSObjectWithLabel,
    props: StylesProps<K, TOption, isMulti, Group>
  ): CSSObjectWithLabel | undefined => {
    if (isObject(overrideStyles)) {
      const stylesFn = overrideStyles[key];
      if (stylesFn) {
        return stylesFn(baseStyles, props);
      }
    }

    return undefined;
  };

  const pickControlBackgroundColor = (isFocused: boolean, isTransparent?: boolean) => {
    if (isTransparent) return 'transparent';
    if (isFocused) return 'var(--bs-input-normal-background-focus)';
    return 'var(--bs-input-normal-background)';
  };

  return {
    control: (base, props) => ({
      backgroundColor: pickControlBackgroundColor(props.isFocused, overrideStyles?.isTransparent),
      border: `2px solid ${props.isFocused ? 'var(--bs-input-focused-non-bottom)' : 'transparent'}`,
      boxShadow: `0px 2px 0px -1px var(--bs-select-option-border)`,
      borderBottom: `2px solid ${props.isFocused ? 'var(--bs-query-input-focused)' : 'transparent'}`,
      display: 'flex',
      alignItems: 'center',
      minWidth: '100px',
      padding: '0px 4px',
      width: '100%',
      height: '100%',
      ...applyOverrideStyles('control', base, props),
      '&:hover': {
        background: 'var(--bs-input-hover-background)',
      },
    }),
    valueContainer: (base, props) => ({
      ...base,
      padding: 0,
      ...applyOverrideStyles('valueContainer', base, props),
    }),
    placeholder: (base, props) => ({
      ...base,
      marginLeft: 0,
      marginRight: 0,
      color: 'var(--bs-hint-text)',
      ...applyOverrideStyles('placeholder', base, props),
    }),
    indicatorSeparator: (base, props) => ({
      display: 'none',
      ...applyOverrideStyles('indicatorSeparator', base, props),
    }),
    singleValue: (base, props) => ({
      ...base,
      marginLeft: '0',
      color: props.selectProps.menuIsOpen ? 'var(--bs-hint-text)' : 'var(--bs-text1)',
      paddingRight: '0.5rem',
      ...applyOverrideStyles('singleValue', base, props),
    }),
    input: (base, props) => ({
      ...base,
      margin: 0,
      padding: 0,
      ...applyOverrideStyles('input', base, props),
    }),
    menu: (base, props) => ({
      ...base,
      left: 0,
      background: 'var(--bs-dropdownmenu-background)',
      boxShadow: '0px 0px 10px 0px var(--bs-dropdownmenu-box-shadow)',
      marginTop: 0,
      fontSize: 13,
      ...applyOverrideStyles('menu', base, props),
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: 2,
    }),
    menuPortal: (base, props) => ({
      ...base,
      zIndex: 1050,
      ...applyOverrideStyles('menuPortal', base, props),
    }),
    option: (base, props) => ({
      ...base,
      paddingTop: '0.225rem',
      paddingBottom: '0.225rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      background: props.isFocused ? 'var(--bs-dropdownmenu-item-background-hover)' : 'transparent',
      color:
        props.isFocused || props.isSelected
          ? 'var(--bs-dropdownmenu-item-color-hover)'
          : 'var(--bs-dropdownmenu-item-color)',
      cursor: 'pointer',
      ...applyOverrideStyles('option', base, props),
    }),
  };
};
