import { ErrorCode, FileError, FileRejection } from 'react-dropzone';

const generateMessageByError = (file: File, error: FileError) => {
  switch (error.code) {
    case ErrorCode.FileInvalidType:
      return error.message.replaceAll(',', ', '); // Add spaces between mime-types.
    default:
      return error.message;
  }
};

export const generateErrorMessage = (fileRejection: FileRejection): string => {
  let message = '';
  for (let i = 0; i < fileRejection.errors.length; i += 1) {
    const error = fileRejection.errors[i];
    const errorMessage = generateMessageByError(fileRejection.file, error);
    message = message.concat(`${errorMessage} \n`);
  }

  return message;
};
