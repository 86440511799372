import React, { FC } from 'react';
import { Node } from 'reactflow';

import { DictionaryView } from 'view/Editor/components/EditorView/DictionaryView/DictionaryView.component';
import { isArray } from 'utils';
import { DAGNodeType } from 'view/Dag';
import { UiEmpty } from 'ui';

type DagViewerNodeDetailsProps = {
  node: Node<{ label: string; meta?: Omit<DAGNodeType['Data'], 'Label'> }>;
};

export const DagViewerNodeDetails: FC<DagViewerNodeDetailsProps> = ({ node }) => {
  const data = node.data.meta?.NodeData && isArray(node.data.meta?.NodeData) ? node.data.meta.NodeData : [];

  return (
    <div className="p-2 fs-13 lh-16 h-100 d-flex flex-column">
      <h6 className="text-center text-text4 fs-13 text-truncate flex-shrink-0">{node.data.label}</h6>
      <div className="d-flex flex-grow-1 overflow-x-auto">
        {data.length === 0 ? <UiEmpty /> : <DictionaryView isReadonly path={[]} data={data} />}
      </div>
    </div>
  );
};
