import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/Button';

type UIFormActionsProps = { isSubmitting: boolean };

export const UIFormActions: FC<UIFormActionsProps> = ({ isSubmitting }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center gap-2 ">
      <Button disabled={isSubmitting} variant="secondary" title={t('buttons.cancel')} />
      <Button disabled={isSubmitting} title={t('buttons.save')} />
    </div>
  );
};
