import { isNullable } from 'utils';

import { PdfMatchType } from '../../../utils/types';

export function getInitialMatchedIndexByMatches(
  matches: PdfMatchType[],
  initialMatch?: { page: number }
): number | undefined {
  if (isNullable(initialMatch?.page)) return undefined;

  const matchedIndex = matches.findIndex((match) => match.page === initialMatch?.page);
  return matchedIndex === -1 ? undefined : matchedIndex + 1; // Added +1 because indexes started from the 1, 0 uses from the empty matches.
}
