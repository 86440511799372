import { FC, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type ContentViewerProps = {
  content: string;
};

export const ContentViewer: FC<ContentViewerProps> = ({ content }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const decodeBase64 = useMemo(() => window.atob(content), [content]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = decodeBase64;
    }
  }, [decodeBase64]);

  return <div ref={contentRef} className={cx('h-100 p-3 text-text1 fs-13', styles.contentWrapper)} />;
};
