import { useMemo } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { FlatTableView } from 'view/Editor/components/EditorView/FlatTableView';
import { SimpleVectorView } from 'view/Editor/components/EditorView/SimpleVectorView';
import { FlatViewModel, SchemaTypeModel } from 'api';
import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { getDataHelper, separateSimpleVectorHelper } from 'view/Editor/components/EditorView/ComplexView/utils/helpers';

import { EditorViewScroll } from '../../EditorViewScroll/EditorViewScroll.component';
import styles from './styles.module.scss';

type ComplexViewProps = {
  data?: FlatViewModel;
  schema: SchemaTypeModel;
  tab: EditorTabType;
  nextTabTitle?: string;
};

export const ComplexView = ({ data = {}, schema, tab, nextTabTitle }: ComplexViewProps) => {
  const { path, schemaPath } = tab;
  const memoData = useMemo(() => getDataHelper(schema, data, path, schemaPath), [data, schema, path, schemaPath]);
  const [simpleVectorData, restData] = useMemo(() => separateSimpleVectorHelper(memoData), [memoData]);

  const getFlatTablePanel = () => (
    <>
      <Panel>
        <EditorViewScroll>
          <FlatTableView data={restData} nextTabTitle={nextTabTitle} />
        </EditorViewScroll>
      </Panel>
      <PanelResizeHandle className={styles.resizer} />
    </>
  );

  return simpleVectorData.length > 0 ? (
    <PanelGroup direction="vertical">
      {restData.length > 0 && getFlatTablePanel()}
      <Panel>
        <EditorViewScroll>
          <SimpleVectorView data={simpleVectorData} tab={tab} />
        </EditorViewScroll>
      </Panel>
    </PanelGroup>
  ) : (
    <EditorViewScroll>
      <FlatTableView data={restData} nextTabTitle={nextTabTitle} />
    </EditorViewScroll>
  );
};
