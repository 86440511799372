import { createContext, useContext, useEffect, useState, FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { EventSourcePolyfill } from 'event-source-polyfill';

import { env } from 'env';
import { sseApi } from 'api';
import { ExecutedHandlerStatusData } from 'view/TypeToolbar/types';
import { selectAccessToken } from 'store/selectors/AppState.selector';
import { LogsEventDataType } from 'view/Logs/utils/types';

type SSEContextType = {
  taskProgress?: ExecutedHandlerStatusData | null;
  logsData?: LogsEventDataType | null;
  error?: string;
};

const TASK_PROGRESS_EVENT = 'task_progress';
const LOGS_EVENT = 'logs';

const SSEContext = createContext<SSEContextType | undefined>(undefined);

export const useSSEContext = (): SSEContextType => {
  const context = useContext(SSEContext);

  if (!context) {
    console.error('useSSEContext must be used within an SSEProvider');
    return { error: 'SSEContext not found' };
  }
  return context;
};

export const SSEProvider: FC<PropsWithChildren> = ({ children }) => {
  const accessToken = useSelector(selectAccessToken) as string;

  const [taskProgress, setTaskProgress] = useState<ExecutedHandlerStatusData | null>(null);
  const [logsData, setLogsData] = useState<LogsEventDataType | null>(null);

  const addTaskProgress = (evt: unknown) => {
    const event = evt as MessageEvent;
    const newEvent = JSON.parse(event.data as string) as ExecutedHandlerStatusData;
    setTaskProgress(newEvent);
  };

  const addLogsData = (evt: unknown) => {
    const event = evt as MessageEvent;
    const newEvent = JSON.parse(event.data as string) as LogsEventDataType;
    setLogsData(newEvent);
  };

  useEffect(() => {
    if (!env.isSSEHandlers) return () => {};

    const eventSource = new EventSourcePolyfill(sseApi.subscribe(), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    eventSource.addEventListener(TASK_PROGRESS_EVENT, addTaskProgress);
    eventSource.addEventListener(LOGS_EVENT, addLogsData);

    return () => {
      eventSource.removeEventListener(TASK_PROGRESS_EVENT, addTaskProgress);
      eventSource.removeEventListener(LOGS_EVENT, addLogsData);
      eventSource.close();
    };
  }, []);

  return <SSEContext.Provider value={{ taskProgress, logsData }}>{children}</SSEContext.Provider>;
};
