type Env = {
  apiUrl: string;
  frontendVersion: string;
  isDemoMode: boolean;
  isFeedbackLocal: boolean;
  feedBackEmail: string;
  defaultTheme: string;
  environment: string;
  isDatasetSupport: boolean;
  isDemoTheme: boolean;
  isLogsPanel: boolean;
  isSSEHandlers: boolean;
};

export const getApiUrl = (): string => {
  const apiUrl = process.env.CL_API_URL?.trim();
  if (apiUrl) return apiUrl;
  const apiProtocol = process.env.CL_API_PROTOCOL?.trim() || 'http';
  const apiHost = process.env.CL_API_HOST?.trim() || 'localhost';
  const apiPort = process.env.CL_API_PORT?.trim() || '8008';

  return `${apiProtocol}://${apiHost}:${apiPort}`;
};

export const env: Env = {
  apiUrl: getApiUrl(),
  frontendVersion: process.env.CL_FRONTEND_VERSION || '22-0627-0-fcdcaf9',
  isDemoMode: process.env.CL_DEMO_MODE === 'true',
  isFeedbackLocal: process.env.CL_FEEDBACK_LOCAL === 'true',
  feedBackEmail: process.env.CL_FEEDBACK_EMAIL || 'ui.dev@compatibl.com',
  defaultTheme: process.env.CL_DEFAULT_THEME || 'Dark',
  environment: process.env.CL_ENVIRONMENT || '',
  isDatasetSupport: process.env.CL_DATASET_SUPPORT === 'true',
  isDemoTheme: process.env.CL_DEMO_THEME === 'true',
  isLogsPanel: process.env.CL_LOGS === 'true',
  isSSEHandlers: process.env.CL_USE_SSE_HANDLERS === 'true',
};
