import { FC, useState } from 'react';

import { UIInput } from 'ui';
import { ExtendedModalIcon } from 'view/Editor/components/TableBody/TableBodyCell/ExtendedModalIcon';
import { KeySelectModal } from 'view/Editor/components/TableBody/TableBodyCell/KeySelect/KeySelectModal';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

export type KeySelectProps = {
  value: string;
  schemaName?: string;
  schemaModuleName?: string;
  isReadOnly?: boolean;
  isVector?: boolean;
  onChange: (value: string | null) => void;
  onChangeInput?: (value: string | null) => void;
};

export const KeySelect: FC<KeySelectProps> = ({
  value,
  isReadOnly,
  isVector,
  schemaModuleName,
  schemaName,
  onChangeInput,
  onChange,
}) => {
  const { event } = useEditorState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleKeySubmit = (key: string) => {
    onChange(key);
    setIsModalOpen(false);
  };

  const handleOpenKeyModal = () => {
    setIsModalOpen(true);
  };

  const renderKeyTransition = () => {
    if (isVector && schemaName && value && event?.onTransition) {
      const handleTransitionToRecord = () => {
        event.onTransition?.({
          type: schemaName,
          key: value,
        });
      };

      return (
        <div className="me-1_5">
          <Icon SvgIcon={APP_ICONS.goToObject} onClick={handleTransitionToRecord} />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <UIInput
        variant="transparent"
        readOnly={!onChangeInput}
        selectOnFocus
        value={value || ''}
        prefixElement={renderKeyTransition()}
        suffixElement={<ExtendedModalIcon onClick={handleOpenKeyModal} disable={isReadOnly} />}
        onChange={(e) => onChangeInput?.(e.target.value || null)}
        onBlur={(e) => onChange(e.target.value || null)}
      />
      {isModalOpen && schemaModuleName && schemaName && (
        <KeySelectModal
          schemaModuleName={schemaModuleName}
          schemaName={schemaName}
          defaultKey={value}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleKeySubmit}
        />
      )}
    </>
  );
};
