import React from 'react';
import { ColumnDef, ColumnResizeMode } from '@tanstack/react-table';

import {
  UiGridBody,
  UIGridFoot,
  UIGridHead,
  UIGridHorizontalScroll,
  UiGridProvider,
  UIGridResizeIndicator,
  UIGridWrapper,
} from './components';
import { ResizeMode } from './types';
import './UIGrid.styles.scss';

type UiGridProps<TData> = {
  rowHeight?: number;
  columns: ColumnDef<TData>[];
  data: TData[];
  columnResizeMode?: ColumnResizeMode;
};

export const UiGrid = <TData,>({
  rowHeight = 28,
  columnResizeMode = ResizeMode.onEnd,
  columns,
  data,
}: UiGridProps<TData>) => (
  <UiGridProvider rowHeight={rowHeight} columnResizeMode={columnResizeMode} columns={columns} data={data}>
    <UIGridWrapper className="ui-grid">
      <UIGridHead className="ui-grid__head" />
      <UiGridBody className="ui-grid__body" />
      <UIGridFoot className="ui-grid__foot" />
      <UIGridHorizontalScroll className="ui-grid__horizontal-scroll" />
      <UIGridResizeIndicator />
    </UIGridWrapper>
  </UiGridProvider>
);
