import { FC, useId, useMemo } from 'react';

import { Grid } from 'view/Grid';
import { isArray, isArrayOfObjects, isObject } from 'utils';
import { EntityPanelViewOfContainerType, EntityPanelViewOfNameType } from 'api';

import { getColumns, getRows } from './utils/helpers';

type MatrixViewerProps = {
  ColHeaders?: EntityPanelViewOfNameType[] | null | string[];
  ValuesContainer?: EntityPanelViewOfContainerType | null | undefined;
  RowHeadersContainer?: EntityPanelViewOfContainerType;
};

export const MatrixViewer: FC<MatrixViewerProps> = ({ ColHeaders, ValuesContainer, RowHeadersContainer }) => {
  const id = useId();
  const { columns, rows } = useMemo(() => {
    if (
      isArrayOfObjects(ColHeaders) &&
      isObject(ValuesContainer) &&
      isArray(ValuesContainer.Array) &&
      isObject(RowHeadersContainer) &&
      isArray(RowHeadersContainer.Array)
    ) {
      return {
        columns: getColumns(ColHeaders, RowHeadersContainer.Array, ValuesContainer.Array),
        rows: getRows(ColHeaders, RowHeadersContainer.Array, ValuesContainer.Array),
      };
    }

    return { columns: [], rows: [] };
  }, [ColHeaders, RowHeadersContainer, ValuesContainer]);

  return (
    <div className="h-100 overflow-auto">
      <Grid gridId={id} columns={columns} data={rows} />
    </div>
  );
};
