import cx from 'classnames';
import type { DropdownMenuProps } from 'reactstrap';
import { DropdownMenu } from 'reactstrap';
import type { FC } from 'react';

import styles from './UiDropdownMenu.module.scss';

export type UiDropdownMenuProps = DropdownMenuProps;

export const UiDropdownMenu: FC<UiDropdownMenuProps> = (props) => {
  const { children, container = 'body', className, ...rest } = props;

  const classes = cx('bg-dropdownmenu-background border-none rounded my-2', styles.dropdownMenu, className);

  return (
    <DropdownMenu className={classes} container={container} {...rest}>
      {children}
    </DropdownMenu>
  );
};
