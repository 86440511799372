import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { ProfileStructureDto } from 'api/data/profileStructure.data';

import styles from './styles.module.scss';

type DefaultViewTileProps = ProfileStructureDto & { onClickTile: () => void };

export const DefaultViewTile = ({ Name, Description, onClickTile }: DefaultViewTileProps) => (
  <div className={cx('c-pointer rounded-lg p-2_5 bg-primary shadow-sm', styles.tileContainer)} onClick={onClickTile}>
    <Icon SvgIcon={APP_ICONS.chatBig} clickable={false} />
    <div className="fw-bold text-tab-text-active">{Name}</div>
    <div className="fs-13 text-text1">{Description}</div>
  </div>
);
