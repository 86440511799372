import { useMemo, useState } from 'react';
import { VisibilityState, Column } from '@tanstack/react-table';

const mapColumnIdsToDic = <TData>(columns: Column<TData>[]): VisibilityState =>
  columns.reduce((dic: VisibilityState, column: Column<TData>) => {
    dic[column.id] = column.getIsVisible();
    return dic;
  }, {});

export function useVisibilityColumns<TData>(columns: Column<TData>[]) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => mapColumnIdsToDic(columns));
  const isAllColumnsChecked = useMemo(
    () => Object.values(columnVisibility).every((isVisible) => isVisible),
    [columnVisibility]
  );
  const isEmptySelection = useMemo(
    () => Object.values(columnVisibility).every((isVisible) => !isVisible),
    [columnVisibility]
  );

  const toggleColumnVisibleById = (columnId: string) =>
    setColumnVisibility((prevColumnVisibility) => ({
      ...prevColumnVisibility,
      [columnId]: !prevColumnVisibility[columnId],
    }));

  const toggleAllColumnsVisibility = () =>
    setColumnVisibility((prevColumnVisibility) =>
      Object.keys(prevColumnVisibility).reduce((dic, key) => {
        dic[key] = !isAllColumnsChecked;
        return dic;
      }, {} as VisibilityState)
    );

  const resetColumnsVisibility = () => setColumnVisibility(mapColumnIdsToDic(columns));

  return {
    columnVisibility,
    isEmptySelection,
    isAllColumnsChecked,
    toggleColumnVisibleById,
    toggleAllColumnsVisibility,
    resetColumnsVisibility,
  };
}
