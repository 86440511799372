import { FC, useRef } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

type LetterButtonProps = {
  isDisabled: boolean;
  openMailClient: () => void;
};

export const LetterButton: FC<LetterButtonProps> = ({ isDisabled, openMailClient }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const onClick = () => {
    openMailClient();
    buttonRef.current?.blur();
  };

  return (
    <>
      <Tooltip>
        <TooltipTrigger className="d-flex">
          <Button
            className="d-flex align-items-center bg-transparent border-0 self p-0"
            innerRef={buttonRef}
            disabled={isDisabled}
            onClick={onClick}
          >
            <Icon SvgIcon={APP_ICONS.mail} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('chat.sendLetter.tooltip')}</TooltipContent>
      </Tooltip>
    </>
  );
};
