import { LogsLevelEnum } from './types';

export const getStatusTextColor = (level: string) => {
  switch (level) {
    case LogsLevelEnum.NOTSET:
    case LogsLevelEnum.DEBUG:
    case LogsLevelEnum.INFO:
      return 'text-logs-item-info-color';
    case LogsLevelEnum.WARNING:
    case LogsLevelEnum.WARN:
      return 'text-logs-item-warning-color';
    case LogsLevelEnum.ERROR:
    case LogsLevelEnum.FATAL:
    case LogsLevelEnum.CRITICAL:
      return 'text-logs-item-error-color';
    default:
      return 'text-logs-item-info-color';
  }
};
