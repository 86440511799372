export const nextSiblingStyling = (closestTabWrapperCopy: HTMLElement, draggingTab: HTMLDivElement) => {
  let sibling = closestTabWrapperCopy.nextSibling as HTMLElement;

  while (sibling) {
    if (sibling === draggingTab) {
      sibling = sibling.nextSibling as HTMLElement;
    }

    if (sibling === null) {
      break;
    }

    sibling.style.transform = `translate(${draggingTab.offsetWidth}px, ${0}px)`;

    sibling = sibling.nextSibling as HTMLElement;
  }
};
