import { FC, ReactNode, useCallback, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import cx from 'classnames';

import { generateErrorMessage } from './utils/helpers';
import { ErrorModal } from './components/ErrorModal';
import styles from './styles.module.scss';

type UploadZoneOptions = {
  open: () => void;
};

type UploadZoneProps = {
  isLoading: boolean;
  className?: string;
  children: (options: UploadZoneOptions) => ReactNode;
  onChangeFiles: (files: File[]) => void;
};

export const UploadZone: FC<UploadZoneProps> = ({ isLoading, className, children, onChangeFiles }) => {
  const [errors, setErrors] = useState<string[]>([]);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const messages = fileRejections.map((fileRejection: FileRejection) => generateErrorMessage(fileRejection));
    setErrors(messages);
  }, []);

  return (
    <>
      <ErrorModal isOpen={errors.length > 0} errors={errors} close={() => setErrors([])} />
      <Dropzone
        disabled={isLoading}
        accept={{
          'application/pdf': [],
          'text/csv': [],
          'text/plain': ['.sql', '.py', '.cpp', '.hpp', '.c', '.h', '.cs', '.java', '.js', '.ts', '.md', '.xls', '.xlsx', '.docx', '.json', '.msg', '.eml', '.csv'],
          'application/zip': [], // For ZIP files
          'application/x-zip-compressed': [], // Also for ZIP files, to cover different MIME types that might be used
        }}
        noClick
        onDrop={onChangeFiles}
        onDropRejected={onDropRejected}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject, open }) => (
          <div
            {...getRootProps({
              className: cx(styles.dropzone, className, {
                [styles.dropzoneActive]: isDragActive,
                [styles.dropzoneReject]: isDragReject,
              }),
            })}
          >
            <input {...getInputProps()} />
            {children({ open })}
          </div>
        )}
      </Dropzone>
    </>
  );
};
