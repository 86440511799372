import { FC, useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { APP_ICONS } from 'utils/icons';

import { PdfInputPagination } from './components';
import styles from './PDFPagination.module.scss';

type PDFPaginationProps = {
  currentPage?: number;
  lastPage?: number;
  onChangePage: (page: number) => void;
};

export const PDFPagination: FC<PDFPaginationProps> = ({ currentPage = 1, lastPage = 1, onChangePage }) => {
  const { t } = useTranslation();

  const isDisabledPrevious = useMemo(() => currentPage === 1, [currentPage]);
  const isDisabledNext = useMemo(() => currentPage === lastPage, [currentPage, lastPage]);

  const previousPage = useCallback(() => {
    if (isDisabledPrevious) return;

    onChangePage(currentPage - 1);
  }, [isDisabledPrevious, currentPage, onChangePage]);

  const nextPage = useCallback(() => {
    if (isDisabledNext) return;

    onChangePage(currentPage + 1);
  }, [isDisabledNext, currentPage, onChangePage]);

  return (
    <div className="d-flex gap-2">
      <Tooltip>
        <TooltipTrigger>
          <Button
            className={cx(
              'bg-gray-400 d-flex align-items-center justify-content-center border-0 p-0 rounded',
              styles.button
            )}
            disabled={isDisabledPrevious}
            onClick={previousPage}
          >
            <Icon className={styles.previousIcon} clickable={false} SvgIcon={APP_ICONS.arrowDown} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('viewers.pdf.previousPage')}</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger>
          <Button
            className={cx(
              'bg-gray-400 d-flex align-items-center justify-content-center border-0 p-0 rounded',
              styles.button
            )}
            disabled={isDisabledNext}
            onClick={nextPage}
          >
            <Icon clickable={false} SvgIcon={APP_ICONS.arrowDown} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('viewers.pdf.nextPage')}</TooltipContent>
      </Tooltip>
      <div className="d-flex align-items-center gap-1 text-text1 fs-13 lh-16">
        <PdfInputPagination page={currentPage} lastPage={lastPage} onChangePage={onChangePage} />/
        <span>{lastPage}</span>
      </div>
    </div>
  );
};
