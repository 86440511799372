import { Dispatch, SetStateAction } from 'react';

export const outOfTabsStyling = (
  draggingTab: HTMLDivElement,
  setDragOutOfTabsZone: Dispatch<SetStateAction<boolean>>,
  existingTabs: NodeListOf<Element> | null
) => {
  setDragOutOfTabsZone(true);

  if (existingTabs) {
    for (let i = 0; i < existingTabs.length; i++) {
      if (existingTabs[i] !== draggingTab) {
        (existingTabs[i] as HTMLElement).style.transform = 'translate(0px, 0px)';
        (existingTabs[i] as HTMLElement).style.removeProperty('pointer-events');
      }
    }
  }
};
