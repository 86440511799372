import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { GridTabDataType, ViewersMetadata, ViewersMetadataType } from 'store/types';
import { getViewersTabs, toast } from 'utils';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { LoadingStatusesEnum, entityApi } from 'api';

export function useTabs() {
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);
  const [status, setStatus] = useState<LoadingStatusesEnum>(LoadingStatusesEnum.INITIAL);
  const [activeTab, setActiveTab] = useState<string>();
  const [tabs, setTabs] = useState<GridTabDataType[]>([]);

  const activateTab = useCallback((tab: string) => setActiveTab(tab), []);

  const updateOrderTabs = useCallback((sortedTabs: GridTabDataType[]) => setTabs(sortedTabs), []);

  const reset = useCallback((shouldResetStatus = true) => {
    setTabs([]);
    setActiveTab(undefined);

    shouldResetStatus && setStatus(LoadingStatusesEnum.INITIAL);
  }, []);

  const loadTabs = useCallback(
    (activeType: string, key?: string): Promise<{ tabs: GridTabDataType[]; activeTab?: string }> => {
      setStatus(LoadingStatusesEnum.LOADING);

      return entityApi
        .getListPanels({ type: activeType, key, dataset })
        .then((viewerMetadata) => {
          const mappedTabs = getViewersTabs(
            viewerMetadata.filter(
              (metadata: ViewersMetadata) => metadata.Type !== ViewersMetadataType?.PRIMARY_DASHBOARD
            )
          );
          setTabs(mappedTabs);

          const firstTab: GridTabDataType | undefined = mappedTabs[0];
          firstTab && setActiveTab(firstTab.key);

          setStatus(LoadingStatusesEnum.SUCCESS);

          return {
            tabs: mappedTabs,
            activeTab: firstTab?.key,
          };
        })
        .catch((e) => {
          toast.error(e);
          setStatus(LoadingStatusesEnum.ERROR);
          reset(false);
          return { tabs: [] };
        });
    },
    [dataset, environment, reset]
  );

  return {
    status,
    activeTab,
    tabs,
    loadTabs,
    activateTab,
    updateOrderTabs,
    reset,
  };
}
