import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  addEmailTag,
  changeEmailTag,
  removeEmailTag,
  setIsToFieldValid,
} from 'store/shared-reducers/SendLogsDataSlice';
import { useAppDispatch } from 'store/hooks';
import { Icon } from 'shared/Icon';
import { selectSendLogsData } from 'view/Logs/store/selectors';
import { APP_ICONS } from 'utils/icons';

import { getIsValidEmail } from './utils/helpers';
import styles from './styles.module.scss';

export const ToField = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isToFieldValid, emailTags } = useSelector(selectSendLogsData);

  const [inputValue, setInputValue] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const isIncludesInvalidValue = emailTags.find((item) => !item.isValid);

    dispatch(setIsToFieldValid(!isIncludesInvalidValue));
  }, [emailTags, dispatch]);

  const handleChangeTag = (e: FormEvent<HTMLDivElement>) => {
    setTagValue(e.currentTarget.textContent!);
  };

  const handleRemoveTag = (tagId: string) => () => {
    dispatch(removeEmailTag(tagId));
  };

  const handleTagBlur = (tagId: string) => () => {
    setInputValue('');
    if (tagValue) {
      const newTagValue = {
        id: tagId,
        label: tagValue,
        isValid: getIsValidEmail(tagValue),
      };

      dispatch(changeEmailTag(newTagValue));
      setTagValue('');
    }
  };

  const handleAddTag = () => {
    setInputValue('');
    setIsFocused(false);
    inputValue &&
      dispatch(
        addEmailTag({
          id: uuid(),
          label: inputValue,
          isValid: getIsValidEmail(inputValue),
        })
      );
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value.trimStart());
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      handleAddTag();
    }
  };

  const showValidationMessage = () => {
    switch (true) {
      case !isToFieldValid:
        return t('validationMessage.invalidEmail');
      case !emailTags.length:
        return t('validationMessage.emptyTagsArray');
      default:
        return '';
    }
  };

  return (
    <div className="d-flex flex-column w-100 overflow-hidden">
      <div
        className={cx('align-items-center', styles.fieldContainer, {
          [styles.focusedContainer]: isFocused,
          [styles.invalidField]: !isToFieldValid || !emailTags.length,
        })}
      >
        {emailTags.map((item) => (
          <div
            key={item.id}
            contentEditable="true"
            suppressContentEditableWarning={true}
            className={cx(styles.fieldTag, {
              [styles.invalidTag]: !item.isValid,
            })}
            onBlur={handleTagBlur(item.id)}
            onInput={handleChangeTag}
          >
            <span>{item.label}</span>
            <Icon SvgIcon={APP_ICONS.crossSmall} className={styles.crossIcon} onClick={handleRemoveTag(item.id)} />
          </div>
        ))}

        <input
          className={styles.fieldBlock}
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleInputKeyPress}
          onBlur={handleAddTag}
          onFocus={() => setIsFocused(true)}
        />
      </div>
      <div className={styles.errorMessage}>{showValidationMessage()}</div>
    </div>
  );
};
