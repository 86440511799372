import { EntityPanelArgumentsType, EntityPanelViewOfDataType } from 'api';
import { NavigateToOptions } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/ViewersNavigator';
import { Actions as PDFViewerActions } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer';
import { isObject } from 'utils';

export const parseArguments = (args: EntityPanelViewOfDataType['Arguments']): object =>
  args?.reduce(
    (acc, arg: EntityPanelArgumentsType) => ({
      ...acc,
      [arg.ParameterName]: Object.values(arg.Argument).at(0),
    }),
    {}
  ) ?? {};

type PdfSearchActionArguments = { panel_id: string; page_number: number; action_name: string; match: string };
type PdfScrollToPageActionArguments = { panel_id: string; page_number: number; action_name: string };

const isScrollToPageAction = (input: unknown): input is PdfScrollToPageActionArguments =>
  isObject<PdfScrollToPageActionArguments>(input) &&
  input?.action_name === 'scroll_to_page' &&
  input?.page_number > 0 &&
  Object.hasOwn(input, 'panel_id');

const isSearchAction = (input: unknown): input is PdfSearchActionArguments =>
  isObject<PdfSearchActionArguments>(input) &&
  input?.action_name === 'search' &&
  input?.page_number > 0 &&
  Boolean(input.match) &&
  Object.hasOwn(input, 'panel_id');

export const mapArgumentsToNavigation = (args: object): NavigateToOptions | null => {
  if (isScrollToPageAction(args)) {
    return {
      panel: args.panel_id,
      action: PDFViewerActions.SCROLL_TO_PAGE,
      params: { page: args.page_number },
    };
  }

  if (isSearchAction(args)) {
    return {
      panel: args.panel_id,
      action: PDFViewerActions.SEARCH_BY_MATCH,
      params: { match: args.match, page: args.page_number },
    };
  }

  return null;
};
