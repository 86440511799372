import { FC, useState } from 'react';
import XLSX from 'xlsx-js-style';
import { useSelector } from 'react-redux';

import { UIExcel } from 'ui/UIExcel/UIExcel';
import { selectApplicationTheme } from 'store/selectors/AppState.selector';
import { THEME_KEYS } from 'store/constants/theme';

export type XlsxViewerProps = {
  content: string;
};

export const XlsxViewer: FC<XlsxViewerProps> = ({ content }) => {
  const theme = useSelector(selectApplicationTheme);
  const [workbook] = useState(() => XLSX.read(content, { type: 'base64' }));

  return <UIExcel workbook={workbook} darkMode={theme === THEME_KEYS.dark} readOnly />;
};
