import { UserInitials } from 'view/Header/components/UserInitials';
import { FileType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { AvatarWithIcon } from '../LockCircle';

type MessageProps = {
  isShowIcon?: boolean;
  text: string;
  files: FileType[];
};

export const Message = ({ text, isShowIcon = false }: MessageProps) => (
  <div className="d-flex gap-1_5 p-2 flex-row-reverse w-75 ms-auto">
    <AvatarWithIcon isActive={isShowIcon}>
      <UserInitials clickable={false} chatIcon />
    </AvatarWithIcon>
    <div className="d-flex flex-column gap-2 bg-grid-active-row justify-content-center px-3 py-2 rounded">
      <div className="fs-13 lh-16 text-text1 text-break text-prewrap">{text}</div>
    </div>
  </div>
);
