import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { ColumnInstance } from 'react-table';
import { DraggableProvided, DraggableStateSnapshot } from '@hello-pangea/dnd';

import { ColumnResizer } from 'view/Grid/components/TableHeader/ColumnResizer';
import { DataType } from 'view/Grid/utils/types';

type HeaderWrapperCellProps = {
  className: string;
  column: ColumnInstance<DataType>;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  isDragOccurring: boolean;
  children: ReactNode;
  lastEl?: boolean;
};

export const HeaderWrapperCell = ({
  className,
  isDragOccurring,
  column,
  snapshot,
  provided,
  children,
  lastEl = false,
}: HeaderWrapperCellProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { key, ...headerProps } = column.getHeaderProps();

  const getWidth = useCallback(() => {
    if (!ref.current) return 0;

    return ref.current.getBoundingClientRect().width;
  }, []);

  const style = useMemo(() => {
    if (isDragOccurring) {
      const { isDragging } = snapshot;

      return {
        ...provided.draggableProps.style,
        ...(!isDragging && { width: getWidth() }),
      };
    }

    return {
      ...(column.applyMaxWidth && { maxWidth: column.maxWidth }),
    };
  }, [isDragOccurring, column.applyMaxWidth, column.maxWidth, snapshot, provided.draggableProps.style, getWidth]);

  const setRef = useCallback(
    (node: HTMLDivElement | null) => {
      provided.innerRef(node);
      ref.current = node;
    },
    [provided]
  );

  return (
    <div className="position-relative p-0_5 ps-0" key={key} {...headerProps}>
      <div className={className} ref={setRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
        {children}
      </div>
      {!isDragOccurring && (
        <ColumnResizer {...column.getResizerProps?.()} isResizing={column.isResizing} lastEl={lastEl} />
      )}
    </div>
  );
};
