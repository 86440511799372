import cx from 'classnames';
import { CSSProperties, FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { ColumnWidthProps, Row } from 'react-table';

import { DataType, GridModeEnum, SelectedRangeCellsType, SelectedRowsRangeType } from 'view/Grid/utils/types';
import { TableBodyCell } from 'view/Grid/components/TableBody/TableBodyCell';

import styles from './styles.module.scss';

type TableRowType = {
  gridId: string;
  rowIndex: number;
  markedIndex: number;
  style?: CSSProperties;
  row: Row<DataType>;
  selectedRangeCells: SelectedRangeCellsType;
  gridMode: GridModeEnum;
  onClick: (event: MouseEvent) => void;
  onDoubleClick: (event: MouseEvent) => void;
  selectedRowId: string | undefined;
  getColumnWidthProps?: () => ColumnWidthProps;
  canvasContext?: CanvasRenderingContext2D | null;
  selectedRowsRange: SelectedRowsRangeType;
};

export const TableRow: FC<TableRowType> = memo(
  ({
    gridId,
    style,
    rowIndex,
    row,
    markedIndex,
    selectedRangeCells,
    gridMode,
    onClick,
    onDoubleClick,
    selectedRowId,
    getColumnWidthProps,
    canvasContext,
    selectedRowsRange,
  }) => {
    const isActiveRow = useCallback((rowId: string) => rowId === selectedRowId, [selectedRowId]);

    const rowBackground = useMemo(() => {
      if (isActiveRow(row.id)) return 'bg-grid-active-row';
      if (gridMode !== GridModeEnum.EDITOR && rowIndex === markedIndex && Object.keys(selectedRowsRange).length === 0) {
        return 'bg-grid-active-row-selected';
      }
      return '';
    }, [gridMode, markedIndex, row.id, rowIndex, selectedRowId, isActiveRow]);

    const { key, ...rowPropsToPass } = row.getRowProps({ style });

    return (
      <div
        {...rowPropsToPass}
        key={key}
        className={cx(`text-grid-text position-absolute top-0 start-0 ${rowBackground}`, styles.tableRow, {
          [styles.active]: isActiveRow(row.id),
        })}
        data-row-id={row.id}
        data-row-index={rowIndex}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {row.cells.map((cell) => {
          const cellProps = cell.getCellProps();
          return (
            <TableBodyCell
              key={cellProps.key}
              gridId={gridId}
              cell={cell}
              selectedRangeCells={selectedRangeCells}
              gridMode={gridMode}
              getColumnWidthProps={getColumnWidthProps}
              canvasContext={canvasContext}
              isCellActivelySelected={
                isActiveRow(row.id) && gridMode !== GridModeEnum.EDITOR && selectedRangeCells[cell.id]
              }
            />
          );
        })}
      </div>
    );
  }
);

TableRow.displayName = 'TableRow';
