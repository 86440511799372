import { type FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { GridTabDataType } from 'store/types';
import { ButtonCode, handleMouseDown } from 'utils';

import { styleDraggingTab } from '../utils/helpers/styleDraggingTab';
import { styleSiblingTabs } from '../utils/helpers/styleSiblingTabs';

import { DnDContext } from '../DraggableContainer';
import { findParent } from '../utils/helpers/findParent';
import { ConfigType, StyledDragingTabType } from '../utils/types';
import { isGridTabDataType } from '../utils/guards/isGridTabDataType';

import styles from './styles.module.scss';

export type DnDDraggableProps = {
  className?: string;
  children: ReactNode;
  index: number;
  openedTabs: GridTabDataType[];
  onCloseTab?: () => void;
  stackRef?: ConfigType | GridTabDataType;
};

export const DnDDraggable: FC<DnDDraggableProps> = ({
  className,
  children,
  index,
  openedTabs,
  onCloseTab,
  stackRef,
}) => {
  const {
    isDragging,
    draggingLabel,
    dropZone,
    dropPreview,
    leftMouseBtnClicked,
    tabsZone,
    targetStackRef,
    setIsDragging,
    setDraggingLabel,
    setLeftMouseBtnClicked,
    startTabIndex,
    endTabIndex,
    setStartTabtIndex,
    setEndTabIndex,
    config,
    setConfig,
    dropMarker,
    blockDnd,
    initialTabsZone,
    setInitialTabsZone,
  } = useContext(DnDContext);

  const [originalPositionX, setOriginalPositionX] = useState(0);
  const [originalPositionY, setOriginalPositionY] = useState(0);
  const [dragOutOfTabsZone, setDragOutOfTabsZone] = useState(false);
  const [toggleNextTab, setToggleNextTab] = useState(true);
  const [allTabs, setAllTabs] = useState<NodeListOf<Element> | null>(null);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tabWrapperRef = useRef<HTMLDivElement | null>(null);

  const processMouseMove = useCallback(
    (moveEvent: MouseEvent) => {
      if (!tabWrapperRef.current) {
        return;
      }

      setIsDragging(true);

      const styledDraggingTab: StyledDragingTabType = {
        blockPointer: true,
        tab: tabWrapperRef.current,
        event: moveEvent,
        originalPositionX: originalPositionX,
        originalPositionY: originalPositionY,
        dropPreview: dropPreview.current,
      };

      styleDraggingTab(styledDraggingTab);

      const targetElement = document.elementFromPoint(moveEvent.clientX, moveEvent.clientY) as HTMLElement;

      if (!targetElement) {
        return;
      }

      const closestTabWrapper = targetElement.closest('.tab-wrapper') as HTMLElement;
      const closestDroppable = targetElement.closest('.droppable') as HTMLElement;

      if (!closestDroppable) {
        dropMarker.current?.classList.add('d-none');
      }

      const configCopy = JSON.parse(JSON.stringify(config)) as ConfigType;

      const configCopyParent = findParent(configCopy, draggingLabel!.id) as ConfigType;

      if (!configCopyParent) {
        return;
      }

      const siblingGridTAbIndex =
        startTabIndex === configCopyParent.content.length - 1 ? startTabIndex - 1 : startTabIndex + 1;

      const draggingGridTab = configCopyParent.content[startTabIndex];
      const siblingGridTab = configCopyParent.content[siblingGridTAbIndex];

      if (
        draggingGridTab &&
        siblingGridTab &&
        isGridTabDataType(draggingGridTab) &&
        draggingGridTab.isActive &&
        isGridTabDataType(siblingGridTab) &&
        toggleNextTab
      ) {
        siblingGridTab.isActive = true;
        draggingGridTab.isCurrentlyDragging = true;

        setConfig(configCopy);
        setToggleNextTab(false);
      }

      styleSiblingTabs(
        moveEvent,
        closestTabWrapper,
        tabWrapperRef.current,
        closestDroppable,
        initialTabsZone,
        startTabIndex,
        endTabIndex,
        setEndTabIndex,
        dragOutOfTabsZone,
        setDragOutOfTabsZone,
        allTabs
      );
    },
    [
      originalPositionX,
      originalPositionY,
      tabWrapperRef.current,
      endTabIndex,
      draggingLabel,
      leftMouseBtnClicked,
      tabsZone,
      targetStackRef,
      dragOutOfTabsZone,
      dropPreview,
      toggleNextTab,
      blockDnd,
      initialTabsZone,
      allTabs,
    ]
  );

  const processLeftMouseBtnUp = useCallback(() => {
    document.body.style.cursor = 'auto';

    if (!tabWrapperRef.current) {
      return;
    }

    setLeftMouseBtnClicked(false);

    let prevSibling = tabWrapperRef.current;
    let nextSibling = prevSibling;

    while (prevSibling) {
      prevSibling.classList.remove('active');
      prevSibling.removeAttribute('style');
      prevSibling = prevSibling.previousSibling as HTMLDivElement;
    }

    while (nextSibling) {
      nextSibling.classList.remove('active');
      nextSibling.removeAttribute('style');
      nextSibling = nextSibling.nextSibling as HTMLDivElement;
    }

    let child = tabsZone?.firstChild as HTMLElement;

    while (child) {
      child.removeAttribute('style');
      child = child.nextSibling as HTMLDivElement;
    }

    tabWrapperRef.current.removeAttribute('style');

    tabWrapperRef.current = null;

    dropPreview.current?.classList.add('d-none');
    dropMarker.current?.classList.add('d-none');
    setToggleNextTab(true);

    // Handle case when drop was performed onto unsupported area
    if (draggingLabel && isDragging) {
      const configCopy = structuredClone(config);

      // Find original stack of dragging label
      const parent = findParent(configCopy, draggingLabel.id) as ConfigType;

      if (parent) {
        // Mark all tabs as undragging currently and make active only original tab
        parent.content.forEach((tab) => {
          (tab as GridTabDataType).isCurrentlyDragging = false;
          (tab as GridTabDataType).isActive = tab.id === draggingLabel.id;
        });

        setConfig(configCopy);
      }
    }
  }, [
    isDragging,
    startTabIndex,
    endTabIndex,
    tabWrapperRef.current,
    dropZone,
    draggingLabel,
    leftMouseBtnClicked,
    tabsZone,
    targetStackRef,
    dropPreview,
    toggleNextTab,
    dropMarker,
    blockDnd,
    initialTabsZone,
    config,
  ]);

  useEffect(() => {
    if (leftMouseBtnClicked) {
      document.addEventListener('mousemove', processMouseMove);
      document.addEventListener('mouseup', processLeftMouseBtnUp);
    }

    return () => {
      document.removeEventListener('mousemove', processMouseMove);
      document.removeEventListener('mouseup', processLeftMouseBtnUp);
    };
  }, [
    originalPositionX,
    originalPositionY,
    startTabIndex,
    endTabIndex,
    processMouseMove,
    processLeftMouseBtnUp,
    dropZone,
    draggingLabel,
    leftMouseBtnClicked,
    blockDnd,
  ]);

  useEffect(() => {
    if (!leftMouseBtnClicked && draggingLabel) {
      setIsDragging(false);
      setDragOutOfTabsZone(false);
    }
  }, [leftMouseBtnClicked, draggingLabel]);

  const processMouseDownOnTab = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      document.body.style.cursor = 'grabbing';

      if (blockDnd || !wrapperRef.current) {
        document.body.style.cursor = 'auto';
        return;
      }

      if ((event.button as ButtonCode) === ButtonCode.MIDDLE) {
        const wrapperIndex = wrapperRef.current?.dataset.index;

        if (wrapperIndex && openedTabs[Number(wrapperIndex)].isCloseable) {
          handleMouseDown(event, {
            [ButtonCode.MIDDLE]: onCloseTab,
          });
        }

        document.body.style.cursor = 'auto';

        return;
      }

      tabWrapperRef.current = wrapperRef.current;

      if (!tabWrapperRef.current) {
        return;
      }

      const allPossibleTabs = document.querySelectorAll('.tab-wrapper');

      setAllTabs(allPossibleTabs);

      const closestDroppable = tabWrapperRef.current.closest('.droppable') as HTMLElement;
      setInitialTabsZone(closestDroppable);

      const tabWrappers = closestDroppable?.querySelectorAll('.tab-wrapper');
      let allowTransform = false;

      tabWrappers?.forEach((wrapper) => {
        (wrapper as HTMLElement).style.maxWidth = `${wrapper.getBoundingClientRect().width}px`;

        if (wrapper === tabWrapperRef.current) {
          allowTransform = true;
        }

        if (allowTransform) {
          (wrapper as HTMLElement).style.transform = `translate(${tabWrapperRef.current?.getBoundingClientRect()
            .width}px, ${0}px)`;
        }
      });

      setLeftMouseBtnClicked(true);
      setDraggingLabel(openedTabs[+tabWrapperRef.current.dataset.index!]);

      setStartTabtIndex(+tabWrapperRef.current.dataset.index!);
      setEndTabIndex(+tabWrapperRef.current.dataset.index!);

      setOriginalPositionX(event.pageX);
      setOriginalPositionY(event.pageY);

      const styledDraggingTab: StyledDragingTabType = {
        blockPointer: false,
        tab: tabWrapperRef.current,
        event: event.nativeEvent,
        originalPositionX: event.pageX,
        originalPositionY: event.pageY,
      };

      styleDraggingTab(styledDraggingTab);

      if (dropMarker.current) {
        dropMarker.current.style.top = `${styledDraggingTab.tab.offsetTop + styledDraggingTab.tab.offsetHeight}px`;
      }
    },
    [
      tabWrapperRef.current,
      startTabIndex,
      endTabIndex,
      originalPositionX,
      originalPositionY,
      leftMouseBtnClicked,
      config,
      dropMarker,
      blockDnd,
      initialTabsZone,
    ]
  );

  return (
    <div
      className={cx(
        'tab-wrapper flex-grow-1',
        { 'd-none': stackRef?.id.includes('main') && (stackRef as ConfigType).content.length === 1 && !isDragging },
        className,
        styles.draggable
      )}
      data-index={index}
      data-testid="tab-wrapper"
      onMouseDown={(event) => processMouseDownOnTab(event)}
      ref={wrapperRef}
    >
      {children}
    </div>
  );
};
