import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPanelModel } from 'api';
import { APP_ICONS } from 'utils/icons';
import { CodeEditorModal } from 'view/Editor/components/CodeEditorModal/CodeEditorModal';
import { IconWithTooltip } from 'shared/IconWithTooltip/IconWithTooltip';

type EditorContentJsonViewProps = {
  panelData?: EntityPanelModel['ViewOf'];
};

export const EditorContentJsonView: FC<EditorContentJsonViewProps> = ({ panelData }) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <>
      <IconWithTooltip
        SvgIcon={APP_ICONS.openJson}
        tooltip={t('editorToolbar.json') || ''}
        onClick={() => setIsOpenModal(true)}
      />
      <CodeEditorModal isReadOnly isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} data={panelData} />
    </>
  );
};
