import { EntityPanelViewOfNameType } from 'api';
import { mapMetadataToGridColumnsHelper } from 'view/Viewers/utils/helper';
import { isObject, ValueTypesEnum } from 'utils';
import { getColumnTypeByCells } from 'view/Grid/utils/helpers';

export const getColumns = (
  colHeaders: string[],
  cells: Array<EntityPanelViewOfNameType | null>,
  headerSeparator?: string
) => {
  const columnCount = colHeaders.length;
  const metadataElements = colHeaders.map((header, index) => {
    const columnType = getColumnTypeByCells(index, columnCount, cells);
    return {
      Name: header,
      Value: { Type: columnType },
    };
  });

  return mapMetadataToGridColumnsHelper(metadataElements, undefined, headerSeparator);
};

const isDataType = (input: unknown): input is Record<ValueTypesEnum.Data, unknown> =>
  isObject(input) && Object.hasOwn(input, ValueTypesEnum.Data);

export const getRows = (columns: string[], cells: Array<EntityPanelViewOfNameType | null>) => {
  const columnSize = columns.length;
  const rows = [];

  for (let i = 0; i < cells.length; i += columnSize) {
    const row = cells.slice(i, i + columnSize);

    const data = columns.reduce((acc, column, columnIndex) => {
      const rowValue = row[columnIndex];
      if (isDataType(rowValue)) return { ...acc, [column]: rowValue.Data };

      const value = isObject(rowValue) ? Object.values(rowValue).at(0) : null;
      return { ...acc, [column]: value };
    }, {});

    rows.push(data);
  }

  return rows;
};
