import { useMemo } from 'react';

import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { ValidationResultType } from 'view/Editor/validation/type';

export type UseValidationErrorProps = {
  path?: string[];
};

export const useValidationError = ({ path }: UseValidationErrorProps) => {
  const { state } = useEditorState();

  const getPathValidationError = (): ValidationResultType | undefined => {
    if (!path) return undefined;

    return state.validationError?.[path.toString()];
  };

  const getNestedValidationErrors = (): ValidationResultType[] => {
    if (!state?.validationError || !path) return [];

    const errors = Object.values(state.validationError);
    if (!path.length) return errors; // Case for root empty path

    return errors.filter((error) => path.every((key, index) => key === error.path[index]));
  };

  const nestedErrorList = useMemo(
    () => getNestedValidationErrors()?.map((it) => `${it.path.join('.')} - ${it.message}`),
    [state.validationError, path]
  );

  const errorList = useMemo(() => {
    const pathError = getPathValidationError();
    if (pathError) return [pathError.message];

    return nestedErrorList;
  }, [state.validationError, path]);

  return {
    nestedErrorList,
    errorList,
  };
};
