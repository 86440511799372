import { DictionaryDataModel } from 'api';

export enum ActionMode {
  NONE,
  ADD_NODE,
  EDIT_NODE,
  EDIT_EDGE,
}

export type DAGNodeType = {
  _t: string;
  Id_: string;
  Position: { X: number; Y: number };
  Data: {
    Label: string;
    NodeData?: DictionaryDataModel[];
    NodeDataReference?: string;
  };
};
export type DAGEdgeType = {
  Id_: string;
  Source: string;
  Target: string;
  Label?: string;
};

export type DAGType = {
  _t: string;
  Name: string;
  Nodes: DAGNodeType[];
  Edges: DAGEdgeType[];
};

export type DagDtoType = {
  nodes: DAGNodeType[];
  edges: DAGEdgeType[];
};
