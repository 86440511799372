import { createContext } from 'react';

import { ActionType, NavigateToOptions } from '../types';

export type ViewersNavigatorContextType = {
  actionStack: ActionType[];
  addActionToStack: (action: ActionType) => void;
  removeActionFromStack: (action: ActionType) => void;
  navigateTo: (options: NavigateToOptions) => void;
};

export const ViewersNavigatorContext = createContext({} as ViewersNavigatorContextType);
