import { StorageEntityModel, NestedTypeModel } from 'api';

export type EntityListPanelModel = {
  Name: string;
  Type?: string;
};

export type EntityPanelViewType = {
  _t: string;
  Id: string;
};

export type EntityPanelModel = {
  _t: string;
  ViewFor: EntityPanelViewType;
  ViewName: string;
  ReadOnly: boolean;
  ViewOf: EntityPanelViewOfModel | EntityPanelViewOfModel[] | StorageEntityModel;
};

export type EntitySaveModel = {
  key: string;
};

export type EntitiesDeleteModel = { _key: string; _t?: string }[];

export type EntityPanelViewOfColHeaderModel = string[] | EntityPanelViewOfNameType[] | null;

export type EntityActionModel = {
  Name: string;
  Arguments?: object;
  Type: NestedTypeModel;
  Data: unknown[];
  Handler: string;
  DataSet: {
    DataSetName: string;
  };
  Silent: boolean;
};

export enum LanguageEnum {
  R = 'R',
  Json = 'Json',
  Xml = 'Xml',
  Python = 'Python',
  Markdown = 'Markdown',
  Sql = 'Sql',
  Cpp = 'Cpp',
  C = 'C',
  Csharp = 'Csharp',
  Java = 'Java',
  Typescript = 'Typescript',
  Javascript = 'Javascript',
  Plaintext = 'Plaintext',
}

export type EntityPanelViewOfModel = {
  _t: string;
  Content?: string;
  ContentType?: string;
  RowCount?: number;
  ColCount?: number;
  ColHeaders?: EntityPanelViewOfColHeaderModel;
  Name?: EntityPanelViewOfNameType;
  RowHeadersContainer?: EntityPanelViewOfContainerType;
  ValuesContainer?: EntityPanelViewOfContainerType;
  Views?: EntityPanelViewType[];
  Style?: EntityPanelViewOfStyle[];
  Body?: string[];
  Language?: LanguageEnum;
  Action?: EntityActionModel;
  MultiHeaderSeparator?: string;
  WordWrap?: boolean;
  ViewName?: string;
};

export type EntityPanelHandlerExtensionType = {
  _t: string;
  HandlerName: string;
  TargetTypeName: string;
};

export type EntityPanelArgumentsType = {
  Argument: Exclude<EntityPanelViewOfNameType, 'Data'>;
  ParameterName: string;
};

export type EntityPanelViewOfDataType = {
  _t: string;
  Arguments?: EntityPanelArgumentsType[];
  Value: EntityPanelViewOfNameType;
  Extensions?: EntityPanelHandlerExtensionType[];
};

export type EntityPanelViewOfNameType = {
  String?: string;
  Int?: number;
  Double?: number;
  Data?: EntityPanelViewOfDataType;
};

export type EntityPanelViewOfContainerType = {
  _t: string;
  ID: string;
  Array?: Array<EntityPanelViewOfNameType | null>;
};

export type EntityPanelViewOfStyle = {
  Column?: number;
  Row?: number;
  ForegroundColor?: string;
  BackgroundColor?: string;
  Format?: {
    FormatID: string;
  };
};
