import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AppStateModel,
  authApi,
  AuthStrategyEnum,
  AuthTokesModel,
  AuthTypeEnum,
  AuthTypeModel,
  entityApi,
  getErrorMessage,
  LoadingStatusesEnum,
  storageApi,
  UserModel,
  VersionType,
} from 'api';

import { env } from 'env';
import { THEME_KEYS } from 'store/constants/theme';
import { GridTabDataType, ThemesType } from 'store/types';
import { AppStateType } from 'store/types/AppStateType';
import { isString, toast } from 'utils';
import { externalStorage } from 'shared/ExternalStorage';

const initialState: AppStateType = {
  status: LoadingStatusesEnum.INITIAL,
  isReadonly: false,
  applicationTheme: env.defaultTheme,
  tokens: {},
  openedTabs: [],
};

export const renew = createAsyncThunk('renew', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState() as { AppStateSliceReducer: AppStateType };
    const { tokens, authType } = state.AppStateSliceReducer;

    if (!authType?.login_strategy) {
      return Promise.reject(new Error('Cannot renew token without auth type.'));
    }

    if (!tokens.refreshToken) {
      return Promise.reject(new Error('Cannot renew token by empty refresh token.'));
    }

    return authApi.renew(authType, { refresh_token: tokens.refreshToken });
  } catch (error) {
    return rejectWithValue(getErrorMessage(error));
  }
});

export const getAppState = createAsyncThunk('getInitialTabs', async (key: string, { rejectWithValue }) => {
  try {
    return storageApi.getAppState(key);
  } catch (e) {
    toast.error(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const AppState = createSlice({
  name: 'AppState',
  initialState: {
    ...initialState,
    tokens: externalStorage.getTokens() ?? {},
    authType: externalStorage.getAuthType(),
  },
  reducers: {
    setNoneType(state: AppStateType) {
      state.authType = {
        id: 'NoneType',
        name: 'None',
        type: AuthTypeEnum.None,
        login_path: '',
        login_strategy: AuthStrategyEnum.default,
        renew_path: '',
      };
    },
    setType(state: AppStateType, action: PayloadAction<AuthTypeModel>) {
      state.authType = action.payload;
      externalStorage.setAuthType(action.payload);
    },
    clearType(state: AppStateType) {
      state.authType = initialState.authType;
      externalStorage.removeAuthType();
    },
    setTokens(state: AppStateType, action: PayloadAction<AuthTokesModel>) {
      const { access_token: accessToken, refresh_token: refreshToken } = action.payload;
      state.tokens.accessToken = accessToken;
      state.tokens.refreshToken = refreshToken;
      externalStorage.setTokens({ accessToken, refreshToken });
    },
    setUser(state: AppStateType, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },
    logout(state: AppStateType) {
      state.authType = initialState.authType;
      state.tokens = initialState.tokens;
      state.user = initialState.user;
      externalStorage.removeTokens();
      externalStorage.removeSessionId();
    },
    setTabs(state, action: PayloadAction<GridTabDataType[]>) {
      const updatedTabs = action.payload;

      if (updatedTabs.length === 0) {
        state.openedTabs = initialState.openedTabs;
      } else {
        state.openedTabs = updatedTabs;
      }
    },
    openTab(state, action: PayloadAction<GridTabDataType>) {
      const { payload } = action;
      const { openedTabs } = state;
      const openedTabIndex = openedTabs.findIndex((tab) => tab.id === payload.id);

      state.activeTabIndex = openedTabIndex >= 0 ? openedTabIndex : 0;
      state.openedTabs = openedTabIndex >= 0 ? openedTabs : [...openedTabs, payload];
    },
    closeTab(state, action: PayloadAction<Pick<GridTabDataType, 'id'>>) {
      state.openedTabs = state.openedTabs.filter((tab) => tab.id !== action.payload.id);
    },
    setApplicationTheme(state, action: PayloadAction<string>) {
      document.documentElement.setAttribute('theme', action.payload);
      state.applicationTheme = action.payload;
    },
    setVersions(state, action: PayloadAction<VersionType[]>) {
      state.versions = action.payload;
    },
    updateActiveTabIndex(state, action: PayloadAction<Pick<GridTabDataType, 'id'>>) {
      state.activeTabIndex = state.openedTabs.findIndex((tab) => tab.id === action.payload.id);
    },
    saveAppState(state) {
      if (!state.user) return;

      const openedTabs = state.openedTabs.map((tab) => ({
        Type: {
          Name: tab.id,
          Module: tab.id,
          Label: tab.label,
        },
      }));

      try {
        entityApi.saveEntity({
          oldRecordKey: state.user.username,
          data: {
            ApplicationTheme: state.applicationTheme,
            BackendVersion: state.backendVersion,
            OpenedTabs: openedTabs,
            ActiveTabIndex: state.activeTabIndex,
            User: state.user.username,
            _t: 'UiAppState',
          },
        });
      } catch (error) {
        toast.error(error);
      }
    },
  },
  extraReducers: (builder) =>
    builder
      // ToDo: Implement renew as fetch and rid od redux toolkit
      .addCase(renew.fulfilled, (state: AppStateType, action: PayloadAction<AuthTokesModel>) => {
        const { access_token: accessToken, refresh_token: refreshToken } = action.payload;
        state.tokens.accessToken = accessToken;
        state.tokens.refreshToken = refreshToken;
        externalStorage.setTokens({ accessToken, refreshToken });
      })
      .addCase(renew.rejected, (state: AppStateType) => {
        state.tokens = initialState.tokens;
        state.user = initialState.user;
      })
      .addCase(getAppState.pending, (state) => {
        state.status = LoadingStatusesEnum.LOADING;
      })
      .addCase(getAppState.rejected, (state, action) => {
        state.status = LoadingStatusesEnum.ERROR;
        state.status = LoadingStatusesEnum.ERROR;
        state.error = isString(action.payload) ? action.payload : '';
      })
      .addCase(getAppState.fulfilled, (state, action: PayloadAction<AppStateModel>) => {
        const {
          OpenedTabs = [],
          ActiveTabIndex,
          ReadOnly,
          ApplicationName,
          ApplicationTheme,
          Versions,
          BackendVersion,
        } = action.payload || {};

        state.versions = Versions;
        state.isReadonly = ReadOnly ?? false;
        state.applicationName = ApplicationName;

        const defaultBackendVersion = '0.0.1';

        state.backendVersion = BackendVersion || defaultBackendVersion;

        const theme = ApplicationTheme
          ? THEME_KEYS[ApplicationTheme.toLowerCase() as keyof ThemesType]
          : env.defaultTheme;

        document.documentElement.setAttribute('theme', theme);
        state.applicationTheme = theme;

        const openTabsKeys = state.openedTabs.map((tab) => tab.key);
        const initialTabs: GridTabDataType[] =
          OpenedTabs?.filter((initialTab) => openTabsKeys.indexOf(initialTab.Type.Name) === -1).map((initialTab) => ({
            id: initialTab.Type.Name,
            label: initialTab.Type.Label,
            key: initialTab.Key ?? initialTab.Type.Name,
          })) || [];
        state.openedTabs = state.openedTabs.concat(initialTabs);
        state.activeTabIndex = ActiveTabIndex;

        state.status = LoadingStatusesEnum.SUCCESS;
      }),
});

export const {
  setTokens,
  setType,
  clearType,
  setUser,
  logout,
  setNoneType,
  setTabs,
  openTab,
  closeTab,
  saveAppState,
  setApplicationTheme,
  updateActiveTabIndex,
} = AppState.actions;
export const { reducer: AppStateSliceReducer } = AppState;
