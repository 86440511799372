import { CSSProperties, FC, ReactNode } from 'react';
import cx from 'classnames';

type UiCollapseProps = {
  className?: string;
  isOpen: boolean;
  children: ReactNode;
};

export const UICollapse: FC<UiCollapseProps> = ({ className, isOpen, children }) => {
  // TODO: add animation
  const style: CSSProperties | undefined = isOpen
    ? undefined
    : {
        overflow: 'hidden',
        height: 0,
        visibility: 'hidden',
      };

  return (
    <div className={cx(className)} style={style}>
      {children}
    </div>
  );
};
