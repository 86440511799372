import { GridTabDataType } from 'store/types';

export enum EditorTabsEnum {
  EDITOR = 'editor',
  EDITOR_TRANSITION = 'editor-transition',
  NEW_ENTITY = 'newEntity',
  COPY_ENTITY = 'copyEntity',
  HISTORY = 'history',
  SELECT_TYPE = 'selectType',
  DAG = 'directed-acyclic-graph',
}

export const EDITOR_TABS: Record<EditorTabsEnum, GridTabDataType> = {
  [EditorTabsEnum.EDITOR]: {
    id: EditorTabsEnum.EDITOR,
    key: EditorTabsEnum.EDITOR,
    label: 'editor.tabs.editor',
    isCloseable: false,
    isActive: false,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.EDITOR_TRANSITION]: {
    id: EditorTabsEnum.EDITOR_TRANSITION,
    key: EditorTabsEnum.EDITOR_TRANSITION,
    label: '',
    isCloseable: true,
    isActive: true,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.NEW_ENTITY]: {
    id: EditorTabsEnum.NEW_ENTITY,
    key: EditorTabsEnum.NEW_ENTITY,
    label: 'editor.tabs.newEntity',
    isCloseable: false,
    isActive: true,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.COPY_ENTITY]: {
    id: EditorTabsEnum.COPY_ENTITY,
    key: EditorTabsEnum.COPY_ENTITY,
    label: 'editor.tabs.copyEntity',
    isCloseable: false,
    isActive: true,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.SELECT_TYPE]: {
    id: EditorTabsEnum.SELECT_TYPE,
    key: EditorTabsEnum.SELECT_TYPE,
    label: 'editor.tabs.selectType',
    isCloseable: false,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.HISTORY]: {
    id: EditorTabsEnum.HISTORY,
    key: EditorTabsEnum.HISTORY,
    label: 'editor.tabs.history',
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
  [EditorTabsEnum.DAG]: {
    id: EditorTabsEnum.DAG,
    key: EditorTabsEnum.DAG,
    label: 'editor.tabs.dag',
    isCloseable: false,
    isCurrentlyDragging: false,
    isMainGridTab: false,
  },
};
