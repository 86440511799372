import { FC } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { DotLoader } from 'shared/DotLoader';

import styles from './styles.module.scss';

type ChatInputButtonProps = {
  isDisabledSending: boolean;
  isSending: boolean;
  isLoading: boolean;
  cancelSendMessage: () => void;
  onSendMessage: () => void;
};

export const ChatInputButton: FC<ChatInputButtonProps> = ({
  isSending,
  cancelSendMessage,
  isDisabledSending,
  isLoading,
  onSendMessage,
}) => {
  if (isSending) {
    return (
      <Button className={cx('bg-transparent p-0 border-0 rounded', styles.cancelIcon)} onClick={cancelSendMessage}>
        <Icon SvgIcon={APP_ICONS.cancelRed} clickable={false} />
      </Button>
    );
  }

  if (isLoading) {
    return <DotLoader className={cx(styles.loadingIcon)} />;
  }

  return (
    <Button
      className={cx('position-absolute1 bg-transparent p-0 border-0 rounded', styles.sendIcon)}
      disabled={isDisabledSending}
      onClick={() => onSendMessage()}
    >
      <Icon SvgIcon={APP_ICONS.send} clickable={false} />
    </Button>
  );
};
