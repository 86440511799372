import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Loader } from 'shared/Loader';
import { SchemaDisplayKind } from 'api';
import { isInteractiveDashboard } from 'containers/TabItemContent/utils/helper';
import { DashboardTypeContainer } from 'containers/TabItemContent/components/DashboardTypeContainer';
import { isError as isErrorStatus, isLoading } from 'utils';
import { useAppDispatch } from 'store/hooks';
import { getActiveTypeMetadata } from 'store/shared-reducers/GridTabsDataSlice';
import { getDatasetData } from 'store/shared-reducers/DatasetDataSlice';

import { MainTypeContainer } from './components/MainTypeContainer';
import { InteractiveDashboardContainer } from './components/InteractiveDashboardContainer';
import { NoGridData } from './components/NoGridData';
import { selectDataset, selectMetaData } from './store/selectors';
import { SingletonContainer } from './components/SingletonContainer';

export type TabItemContentProps = {
  activeTabId: string;
  options: {
    isDynamicDashboard: boolean;
    isDynamicPrimaryDashboard: boolean;
    dynamicId?: string;
  };
};

export const TabItemContent: FC<TabItemContentProps> = ({ activeTabId, options }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const metaData = useSelector(selectMetaData);
  const dataset = useSelector(selectDataset);

  const isDashboard = metaData.data.DisplayKind === SchemaDisplayKind.Dashboard;

  useEffect(() => {
    dispatch(getActiveTypeMetadata(activeTabId));
  }, [activeTabId]);

  useEffect(() => {
    if (metaData.data.Name) {
      dispatch(getDatasetData({ type: metaData.data.Name, module: metaData.data.Module.ModuleName }));
    }
  }, [metaData.data.Name]);

  const loading = useMemo(
    () => isLoading([metaData.status, dataset.status]) || activeTabId !== metaData.data.Name,
    [dataset.status, metaData.status, metaData.data.Name, activeTabId]
  );

  const isError = useMemo(() => isErrorStatus([dataset.status, metaData.status]), [dataset.status, metaData.status]);

  if (isError) {
    return <NoGridData title={t('noData.contactAdministrator')} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (metaData?.data?.DisplayKind === SchemaDisplayKind.Singleton) {
    return (
      <SingletonContainer
        type={metaData?.data?.Name}
        moduleName={metaData?.data?.Module?.ModuleName}
        key={activeTabId}
      />
    );
  }

  if (isDashboard || options.isDynamicDashboard || options.isDynamicPrimaryDashboard) {
    if (isInteractiveDashboard(metaData.data) || options.isDynamicPrimaryDashboard) {
      return <InteractiveDashboardContainer type={activeTabId} chatKey={options.dynamicId} />;
    }

    return <DashboardTypeContainer type={activeTabId} key={activeTabId} />;
  }

  if (!isDashboard) {
    return <MainTypeContainer activeTypeTab={activeTabId} metaData={metaData} key={activeTabId} />;
  }

  return <NoGridData title={t('noData.contactAdministrator')} />;
};
