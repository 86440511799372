import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/Button';

type EditorButtonsGroupProps = {
  submitTitle: string;
  onSubmit: () => void;
  onCancel: () => void;
  isCancelDisabled?: boolean;
  isSubmitDisabled?: boolean;
};

export const EditorButtonsGroup: FC<EditorButtonsGroupProps> = ({
  submitTitle,
  onSubmit,
  onCancel,
  isCancelDisabled,
  isSubmitDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 d-flex align-items-center justify-content-end px-4 py-3 gap-2_5">
      <Button title={t('buttons.cancel')} variant="secondary" onClick={onCancel} disabled={isCancelDisabled} />
      <Button title={t(submitTitle)} onClick={onSubmit} disabled={isSubmitDisabled} />
    </div>
  );
};
