import { forwardRef, HTMLProps, RefAttributes } from 'react';
import { useMergeRefs } from '@floating-ui/react';

import { isObject } from 'utils';

import { useTooltipContext } from '../../hooks/useTooltipContext';

function isRef<T extends HTMLElement>(input: unknown): input is RefAttributes<T> {
  return isObject(input) && 'ref' in input;
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(({ children, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = isRef(children) ? children.ref : null;
  const refs = [context.refs.setReference, propRef];
  if (childrenRef) refs.push(childrenRef);
  const ref = useMergeRefs(refs);

  return (
    <div ref={ref} data-state={context.open ? 'open' : 'closed'} {...context.getReferenceProps(props)}>
      {children}
    </div>
  );
});

TooltipTrigger.displayName = 'TooltipTrigger';
