import { createContext, FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { GridsType, TabItemContextType } from 'containers/TabItemContent/utils/types';
import { ConfigType, LayoutType } from 'view/DragnDrop/utils/types';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

export const TabItemContext = createContext<TabItemContextType | null>(null);

type handlerType = (simplifiedConfig: LayoutType, handlers?: string[], readOnly?: boolean) => Promise<void>;

export const TabItemProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activeTab } = useTabParams();

  const [grids, setGrids] = useState<GridsType>({} as GridsType);
  const [appLayoutConfig, setAppLayoutConfig] = useState<ConfigType>({} as ConfigType);
  const [pinnedHandlers, setPinnedHandlers] = useState<string[]>([]);
  const [isTypeReadOnly, setIsTypeReadOnly] = useState(false);

  const saveRecordMethodRef = useRef<handlerType | null>(null);

  const updateLayoutConfig = (config: ConfigType) => {
    setAppLayoutConfig(config);
  };

  const updateSaveMethod = (callback: handlerType) => {
    saveRecordMethodRef.current = callback;
  };

  const saveUiTypeData = (handlers?: string[]) => {
    const simplifiedConfig = getParsedConfig(appLayoutConfig, true) as LayoutType;

    saveRecordMethodRef.current && saveRecordMethodRef.current(simplifiedConfig, handlers, isTypeReadOnly);
  };

  const updatePinnedHandlers = (newHandlers: string[]) => {
    setPinnedHandlers(newHandlers);
  };

  const resetPinnedHandlers = () => {
    setPinnedHandlers([]);
  };

  const updateIsReadOnly = (readOnly: boolean) => {
    setIsTypeReadOnly(readOnly);
  };

  const resetIsReadOnly = () => {
    setIsTypeReadOnly(false);
  };

  useEffect(() => {
    setGrids({} as GridsType);
  }, [activeTab?.id]);

  const value: TabItemContextType = {
    state: {
      grids,
      pinnedHandlers,
      isTypeReadOnly,
    },
    action: {
      setGrids,
      updateLayoutConfig,
      updateSaveMethod,
      saveUiTypeData,
      updatePinnedHandlers,
      resetPinnedHandlers,
      updateIsReadOnly,
      resetIsReadOnly,
    },
  };

  return <TabItemContext.Provider value={value}>{children}</TabItemContext.Provider>;
};
