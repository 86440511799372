import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Editor } from 'view/Editor/Editor';
import { entityApi, schemaApi, storageApi } from 'api';
import { EditorProps } from 'view/Editor/type';

export type EditorInstanceProps = Omit<EditorProps, 'requestConfig'>;

export const EditorInstance: FC<EditorInstanceProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Editor
      requestConfig={{
        getSchema: schemaApi.getType,
        getTypes: schemaApi.getTypes,
        getTypeHierarchy: schemaApi.getTypeHierarchy,
        getSelectRecords: storageApi.select,
        saveRecord: entityApi.saveEntity,
      }}
      i18n={{
        submitBtnText: t('buttons.save'),
        cancelBtnText: t('buttons.cancel'),
      }}
      {...props}
    />
  );
};
