import { FC, memo } from 'react';

import { EntityPanelViewOfModel } from 'api';
import { isArray, isLoading, isNullable } from 'utils';
import { Loader } from 'shared/Loader';
import { Viewers } from 'view/Viewers';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

import { ViewHandlerActionWrapper } from './components';

type ViewHandlerActionComponentProps = Pick<EntityPanelViewOfModel, 'Action'> & {
  viewerKey?: string;
  viewerType?: string;
  environment?: string;
  dataset?: string;
  onTransition?: (data: TransitionRecordType) => void;
};

export const ViewHandlerAction: FC<ViewHandlerActionComponentProps> = memo(
  ({ Action, viewerKey, viewerType, dataset, environment, onTransition }) => {
    if (!Action || !viewerType || !viewerKey || !environment || isNullable(dataset)) return null;

    return (
      <ViewHandlerActionWrapper
        Action={Action}
        viewerType={viewerType}
        viewerKey={viewerKey}
        dataset={dataset}
        environment={environment}
      >
        {({ status, data }) => {
          const loading = isLoading([status]);
          if (loading) return <Loader />;

          if (isNullable(data)) return null;

          const activeViewerType = isArray(data) ? undefined : data._t;
          return (
            <Viewers
              activeViewerType={activeViewerType}
              panelKey={viewerKey}
              panelType={viewerType}
              data={data}
              onTransition={onTransition}
            />
          );
        }}
      </ViewHandlerActionWrapper>
    );
  }
);

ViewHandlerAction.displayName = 'ViewHandlerAction';
