import { FC } from 'react';

import { DraggableContainer } from 'view/DragnDrop/DraggableContainer';
import { TypeToolbar } from 'view/TypeToolbar';

export type DashboardTypeContainerProps = {
  type: string;
};

export const DashboardTypeContainer: FC<DashboardTypeContainerProps> = ({ type }) => (
  <>
    <TypeToolbar />
    <DraggableContainer withKey={false} showToolbar={false} type={type} tabs={[]} showEditor isDashboardType />
  </>
);
