import { FC, useCallback, useEffect } from 'react';
import cx from 'classnames';

import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { ChatHistoryHeader } from './components/ChatHistoryHeader';
import { ChatHistoryOption } from './components/ChatHistoryOption';
import { ChatHistoryFooter } from './components/ChatHistoryFooter';
import { useSelectedSessions } from './hooks/useSelectedSessions';

import styles from './styles.module.scss';

type ChatHistoryMenuProps = {
  activeSession?: SessionType;
  isOpen: boolean;
  sessions: SessionType[];
  selectSession: (session: SessionType) => void;
  deleteSelected: (selectedSessions: SessionType[]) => void;
};

export const ChatHistoryMenu: FC<ChatHistoryMenuProps> = ({
  activeSession,
  isOpen,
  sessions,
  selectSession,
  deleteSelected,
}) => {
  const {
    isSelected,
    isEmptySelection,
    isAllSelection,
    toggleSession,
    toggleAllSessions,
    getSelectedSessions,
    resetAllSessions,
  } = useSelectedSessions(sessions);

  const deleteSelectedHandler = useCallback(() => {
    const selectedSessions = getSelectedSessions();
    deleteSelected(selectedSessions);
  }, [deleteSelected, getSelectedSessions]);

  useEffect(() => {
    !isOpen && resetAllSessions();
  }, [isOpen, resetAllSessions]);

  return (
    <div className={cx(styles.menu, 'bg-400 bg-gray-500 py-1 rounded border-gray-700 shadow-lg pb-0')}>
      <ChatHistoryHeader
        className="border-bottom border-gray-700"
        isAllSelection={isAllSelection}
        isEmptySelection={isEmptySelection}
        toggleAllSessions={toggleAllSessions}
      />
      <div className={cx(styles.content, 'overflow-y-auto')}>
        {sessions.map((session) => (
          <ChatHistoryOption
            key={session.ChatKey}
            isActive={session.ChatKey === activeSession?.ChatKey}
            isSelected={isSelected(session.ChatKey)}
            session={session}
            onCheck={() => toggleSession(session)}
            onClick={() => selectSession(session)}
          />
        ))}
      </div>
      <ChatHistoryFooter
        className="border-top border-gray-700"
        isDisabled={isEmptySelection}
        deleteSelected={deleteSelectedHandler}
      />
    </div>
  );
};
