import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getErrorMessage, LoadingStatusesEnum, schemaApi, SchemaTypeModel } from 'api';
import { GridTabsDataType } from 'store/types';
import { isString, toast } from 'utils';
import { closeTab } from 'store/shared-reducers/AppStateSlice';

export const getActiveTypeMetadata = createAsyncThunk(
  'getActiveTypeMetadata',
  async (activeTab: string, { rejectWithValue, dispatch }) => {
    try {
      const data = await schemaApi.getType({ name: activeTab });
      return data.typeList[data.rootType];
    } catch (e) {
      toast.error(e);
      dispatch(closeTab({ id: activeTab }));

      return rejectWithValue(getErrorMessage(e));
    }
  }
);

const initialState: { tabsData: GridTabsDataType } = {
  tabsData: {
    metaData: {
      data: {} as SchemaTypeModel,
      status: LoadingStatusesEnum.INITIAL,
      error: '',
    },
  },
};

const GridTabsDataSlice = createSlice({
  name: 'tabsData',
  initialState: initialState,
  reducers: {
    updateActiveTypeMetadata(state, action: PayloadAction<SchemaTypeModel>) {
      state.tabsData.metaData.data = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getActiveTypeMetadata.pending, (state) => {
        state.tabsData.metaData.data = initialState.tabsData.metaData.data;
        state.tabsData.metaData.status = LoadingStatusesEnum.LOADING;
        state.tabsData.metaData.error = '';
      })
      .addCase(getActiveTypeMetadata.fulfilled, (state, action: PayloadAction<SchemaTypeModel>) => {
        state.tabsData.metaData.data = action.payload;
        state.tabsData.metaData.status = LoadingStatusesEnum.SUCCESS;
      })
      .addCase(getActiveTypeMetadata.rejected, (state, action) => {
        state.tabsData.metaData.status = LoadingStatusesEnum.ERROR;
        state.tabsData.metaData.error = isString(action.payload) ? action.payload : '';
      }),
});

export const GridTabsDataSliceReducer = GridTabsDataSlice.reducer;
export const { updateActiveTypeMetadata } = GridTabsDataSlice.actions;

export const { getInitialState } = GridTabsDataSlice;
