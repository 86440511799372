import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { UiDropdownItem } from 'ui';

import styles from './styles.module.scss';
import { OptionType } from './types';

type CustomDropdownProps = {
  selectedValue: string;
  onChange: (value: string) => void;
  options: OptionType[];
};

export const CustomDropdown = ({ selectedValue, onChange, options }: CustomDropdownProps) => {
  const selectedLabel = options.find(({ value }) => value === selectedValue)?.label;

  return (
    <UncontrolledDropdown className={styles.dropdownContainer}>
      <DropdownToggle
        caret
        className={cx(
          'w-100 d-flex justify-content-between align-items-center fs-14 px-2 py-1 bg-user-modal-input-background border-gray-400 text-text1 rounded',
          styles.toggle
        )}
      >
        {selectedLabel}
      </DropdownToggle>
      <DropdownMenu className={cx('bg-dropdownmenu-background border-none rounded', styles.dropdownMenu)}>
        {options.map(({ label, value, isHide }) => {
          if (isHide) return null;

          return (
            <UiDropdownItem key={uuid()} onClick={() => onChange(value)} className={cx('', styles.item)}>
              {label}
            </UiDropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
