import { ExecutedHandlerResult } from '../types';

export const downloadDecodedData = (data: string, filename: string) => {
  const decodedData = atob(data);

  if (!decodedData) return;

  const byteNumbers = new Array(decodedData.length);

  for (let i = 0; i < decodedData.length; i++) {
    byteNumbers[i] = decodedData.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: 'application/octet-stream' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadJsonObject = (obj: ExecutedHandlerResult, filename: string) => {
  const jsonString = JSON.stringify(obj, null, 2);

  const blob = new Blob([jsonString], { type: 'application/json' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
