import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { addDataToClickboard } from 'utils';

import styles from './styles.module.scss';

type CopyProps = {
  className?: string;
  textToCopy: string;
};

export const Copy: FC<CopyProps> = ({ textToCopy, className }) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState(false);

  const copy = useCallback(() => {
    if (isCopied) return;

    clearTimeout(timerRef.current);

    addDataToClickboard(textToCopy)
      .then(() => setIsCopied(true))
      .then(() => {
        timerRef.current = setTimeout(() => setIsCopied(false), 3_000);
      });
  }, [isCopied, textToCopy]);

  useEffect(() => () => clearTimeout(timerRef.current), []);

  return (
    <Tooltip>
      <TooltipTrigger className={className}>
        <Button className="bg-transparent border-0 p-1" onClick={copy}>
          <Icon
            clickable={false}
            SvgIcon={isCopied ? APP_ICONS.applyLarge : APP_ICONS.copy}
            className={cx({ [styles.container]: !isCopied })}
            color={!isCopied ? 'text-copy-icon' : undefined}
          />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t(isCopied ? 'shared.copied' : 'shared.copy')}</TooltipContent>
    </Tooltip>
  );
};
