import dayjs, { Dayjs, UnitType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { ValueType } from 'store/types';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const dateTypesArr: Array<ValueType['Type']> = ['Date', 'Time', 'DateTime'];

export const isDateType = (type?: ValueType['Type']): boolean => (type ? dateTypesArr.includes(type) : false);

export enum DateFormatEnum {
  DATE = 'YYYY-MM-DD',
  DATE_TIME = 'YYYY-MM-DD HH:mm:ss',
  TIME = 'HH:mm:ss',
  TIME_MS = 'HH:mm:ss.SSS',
  DATE_TIME_MS = 'YYYY-MM-DDTHH-mm-ss-SSS',
  TIME_DATE = 'HH:mm:ss YYYY-MM-DD',
}

type DateInput = Dayjs | Date | number | string | null;

type DateFormatUtility = {
  format: (format: string) => string;
  subtract: (amount: number, unit: dayjs.ManipulateType) => DateFormatUtility;
  fromNow: (withoutSuffix?: boolean) => string;
  add: (amount: number, unit?: dayjs.ManipulateType) => DateFormatUtility;
  diff: (date: DateInput, unit?: UnitType, precise?: boolean) => number;
  unix: () => number;
  sort: (date: DateFormatUtility) => number;
  toDate: () => Date;
  toInstance: () => Dayjs;
  isValid: () => boolean;
  utcString: string;
};

function createDateFormatUtility(dateInput?: DateInput, formatInput?: string): DateFormatUtility {
  const instance = dayjs(dateInput, formatInput);

  return {
    format(format: string): string {
      return instance.format(format);
    },
    subtract(amount: number, unit: dayjs.ManipulateType) {
      const newInstance = instance.clone().subtract(amount, unit);
      return clone(newInstance);
    },
    fromNow(withoutSuffix?: boolean): string {
      return instance.fromNow(withoutSuffix);
    },
    add(amount: number, unit?: dayjs.ManipulateType) {
      const newInstance = instance.clone().add(amount, unit);
      return clone(newInstance);
    },
    diff(date: DateInput, unit?: UnitType, precise?: boolean): number {
      return instance.diff(date, unit, precise);
    },
    unix(): number {
      return instance.unix();
    },
    sort(date: DateFormatUtility): number {
      if (instance.isBefore(date.toInstance())) return -1;
      if (instance.isAfter(date.toInstance())) return 1;

      return 0;
    },
    toDate(): Date {
      return instance.toDate();
    },
    toInstance(): Dayjs {
      return instance;
    },
    isValid(): boolean {
      return instance.isValid();
    },
    utcString: instance.utc().format(),
  };

  function clone(cloneInstance: Dayjs): DateFormatUtility {
    return createDateFormatUtility(cloneInstance);
  }
}

export const DateFormatUtility = {
  create: createDateFormatUtility,
};
