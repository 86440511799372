import { ValueTypeModel } from 'api';
import { ValueTypesEnum } from 'utils';

export const generateValueBySchemaType = (type: ValueTypeModel) => {
  switch (type) {
    case ValueTypesEnum.String:
      return '';
    case ValueTypesEnum.Int:
    case ValueTypesEnum.Double:
    case ValueTypesEnum.Long:
    case ValueTypesEnum.Bool:
    case ValueTypesEnum.Date:
    case ValueTypesEnum.Time:
    case ValueTypesEnum.DateTime:
      return null;
    default:
      throw new Error(`Cannot generate value by current schema - ${type}`);
  }
};
