import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Viewers } from 'view/Viewers';
import { isObject } from 'utils';
import { Loader } from 'shared/Loader';
import { useRequest } from 'hook/request.hook';
import { entityApi, EntityPanelViewOfModel, HandlerType } from 'api';
import { NoGridData } from 'containers/TabItemContent/components/NoGridData';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

export type ViewerContainerProps = {
  showToolbar?: boolean;
  activeTab: string;
  panelType: string;
  panelKey?: string;
  onTransition?: (data: TransitionRecordType) => void;
};

export const ViewerContainer: FC<ViewerContainerProps> = ({
  activeTab,
  panelKey,
  panelType,
  showToolbar,
  onTransition,
}) => {
  const { isLoading, state, fetch, hasError } = useRequest(entityApi.getPanel);

  const { data: schema } = useSelector(selectGridSchema);

  const panelWithParamsName = schema.Declare?.Handlers?.find(
    ({ Label, Type, Params }) => Label === activeTab && Type === HandlerType.VIEWER && Boolean(Params)
  )?.Name;

  const fetchPanelData = () => {
    fetch({
      key: panelKey,
      type: panelType,
      panelId: activeTab,
    });
  };

  useEffect(() => {
    if (!panelWithParamsName) {
      fetchPanelData();
    }
  }, [panelKey, panelType, activeTab]);

  if (isLoading) return <Loader />;

  const activeViewerType = isObject<EntityPanelViewOfModel>(state?.ViewOf) ? state?.ViewOf?._t : '';

  // TODO move NoGridData to shared components
  if (hasError) {
    return (
      <NoGridData
        title="Data for this view is not yet available - refresh to try again"
        handleButtonClick={() => {
          fetchPanelData();
        }}
        isRefreshButton
      />
    );
  }

  return (
    <Viewers
      activeTab={activeTab}
      panelType={panelType}
      panelKey={panelKey}
      panelWithParamsName={panelWithParamsName}
      showToolbar={showToolbar}
      activeViewerType={activeViewerType}
      data={state?.ViewOf}
      onTransition={onTransition}
      onRefresh={fetchPanelData}
    />
  );
};
