import { useSelector } from 'react-redux';

import { overrideComponentsDictionary } from 'custom/overrides';

import { selectApplicationTheme } from 'store/selectors/AppState.selector';

import { coreComponentsDictionary } from './coreComponentsDictionary';

export const useComponentsMapper = () => {
  const storedTheme = useSelector(selectApplicationTheme);

  return { ...coreComponentsDictionary, ...overrideComponentsDictionary[storedTheme] };
};
