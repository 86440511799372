import { combineReducers, configureStore, isPlain } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

import { HeaderDataSliceReducer } from 'view/Header/store/HeaderDataSlice';
import { SchemaTypesSliceReducer } from 'view/Header/store/SchemaTypesSlice';

import { GridTabsDataSliceReducer } from './shared-reducers/GridTabsDataSlice';
import { TypeToolbarSliceReducer } from './shared-reducers/TypeToolbarSlice';
import { GridDataSliceReducer } from './shared-reducers/GridDataSlice';
import { LogsDataSliceReducer } from './shared-reducers/LogsDataSlice';
import { SendLogsDataSliceReducer } from './shared-reducers/SendLogsDataSlice';
import { DatasetDataSliceReducer } from './shared-reducers/DatasetDataSlice';
import { EnvironmentDataSliceReducer } from './shared-reducers/EnvironmentDataSlice';
import { ToolbarHandlerSliceReducer } from './shared-reducers/ToolbarHandlerSlice';
import { AppStateSliceReducer } from './shared-reducers/AppStateSlice';
import { listenerMiddleware } from './listeners';

const rootReducer = combineReducers({
  SchemaTypesSliceReducer,
  HeaderDataSliceReducer,
  GridTabsDataSliceReducer,
  TypeToolbarSliceReducer,
  GridDataSliceReducer,
  LogsDataSliceReducer,
  SendLogsDataSliceReducer,
  DatasetDataSliceReducer,
  EnvironmentDataSliceReducer,
  ToolbarHandlerSliceReducer,
  AppStateSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          isSerializable: (value: any) => isPlain(value) || BigNumber.isBigNumber(value),
        },
      }).prepend(listenerMiddleware.middleware),
  });

export const store = setupStore();

export const { dispatch } = store;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
