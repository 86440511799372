import { CellObject, CellStyle, utils as xlsxUtils, writeFile } from 'xlsx-js-style';

const borderCellColor = '000000';
const headerCellBgColor = 'ADD8E6';

export type ExcelCellObject = CellObject;

export type ExcelBodyColumns = ExcelCellObject[][];
export type ExcelAdditionalColumns = ExcelCellObject[][];

export type ExcelHeaderRows = ExcelCellObject[];
export type ExcelBodyRows = ExcelCellObject[];
export type ExcelAdditionalRows = ExcelCellObject[];

export type ExcelOptions = {
  fileName?: string;
  sheetName?: string;
  columnWidths: ExcelCellWidth[];
  body: (ExcelAdditionalRows | ExcelHeaderRows | ExcelBodyRows)[];
};

export type ExcelCellWidth = { wch: number };

export type ExcelCellValue = string | number | boolean | Date;

export enum ExcelCellType {
  STRING = 's',
  NUMBER = 'n',
  ERROR = 'e',
  DATE = 'd',
  STUB = 'z',
}

enum DateFormatOptions {
  TWO_DIGIT = '2-digit',
  NUMERIC = 'numeric',
}

export const getSheetName = (sheetName?: string, sheetNameEnding: string = '...') => {
  if (!sheetName) return 'Sheet 1';

  const sheetNameMaxLength = 31;

  if (sheetName.length <= sheetNameMaxLength) return sheetName;

  return sheetName.slice(0, sheetNameMaxLength - sheetNameEnding.length).concat(sheetNameEnding);
};

export const formatExcelDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: DateFormatOptions.TWO_DIGIT,
    day: DateFormatOptions.TWO_DIGIT,
    year: DateFormatOptions.NUMERIC,
    hour: DateFormatOptions.TWO_DIGIT,
    minute: DateFormatOptions.TWO_DIGIT,
    second: DateFormatOptions.TWO_DIGIT,
    hour12: true,
  };

  return date.toLocaleString(undefined, options);
};

export const generateHeaderCell = (
  value: ExcelCellValue,
  type: ExcelCellType = ExcelCellType.STRING,
  styles: CellStyle = getDefaultHeaderCellStyles()
): CellObject => ({
  v: value,
  t: type,
  s: styles,
});

export const generateCell = (
  value: ExcelCellValue,
  type: ExcelCellType = ExcelCellType.STRING,
  styles: CellStyle = getDefaultCellStyles()
): CellObject => ({
  v: value,
  t: type,
  s: styles,
});

export const generateAdditionalInfoCell = (
  value: ExcelCellValue,
  type: ExcelCellType = ExcelCellType.STRING,
  styles: CellStyle = getAdditinalInfoCellStyles()
): CellObject => ({
  v: value,
  t: type,
  s: styles,
});

export const getDefaultHeaderCellStyles = (): CellStyle => ({
  fill: { fgColor: { rgb: headerCellBgColor } },
  border: {
    top: { style: 'thin', color: { rgb: borderCellColor } },
    right: { style: 'thin', color: { rgb: borderCellColor } },
    bottom: { style: 'thin', color: { rgb: borderCellColor } },
    left: { style: 'thin', color: { rgb: borderCellColor } },
  },
});

export const getDefaultCellStyles = (): CellStyle => ({
  border: {
    top: { style: 'thin', color: { rgb: borderCellColor } },
    right: { style: 'thin', color: { rgb: borderCellColor } },
    bottom: { style: 'thin', color: { rgb: borderCellColor } },
    left: { style: 'thin', color: { rgb: borderCellColor } },
  },
});

export const getAdditinalInfoCellStyles = (): CellStyle => ({
  font: { color: { rgb: '800080' }, italic: true },
});

export const getColumnWidth = (width: number): ExcelCellWidth => ({ wch: width });

export const exportToExcelFile = ({ fileName, sheetName, columnWidths, body }: ExcelOptions) => {
  const workbook = xlsxUtils.book_new();

  const worksheet = xlsxUtils.aoa_to_sheet(body);

  const correctSheetName = getSheetName(sheetName);

  worksheet['!cols'] = columnWidths;

  xlsxUtils.book_append_sheet(workbook, worksheet, correctSheetName);

  writeFile(workbook, fileName || 'Excel_default.xlsx');
};
