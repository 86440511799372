import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, Input } from 'reactstrap';
import cx from 'classnames';
import { ChangeEvent, useState } from 'react';

import { UiDropdownMenu } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { HighlightTextMatch } from 'shared/highlightTextMatch';
import { useBoolean } from 'utils/hooks';
import { escapeStringRegexp } from 'utils';
import { CancelButton } from 'view/Header/components/CancelButton';

import { RunHandlerItem } from '../RunHandlerItem';
import { ExecutedHandlerInfoByRecord, HandlerItemType } from '../../types';

import styles from './styles.module.scss';

const isMatched = (value: string, input: string): boolean => {
  const regex = new RegExp(escapeStringRegexp(value), 'ig');
  return regex.test(input);
};

type RunSelectorProps = {
  handlers?: HandlerItemType[];
  executedHandlersInfoByRecord: ExecutedHandlerInfoByRecord;
  isLogsOpened?: boolean;
  onPinHandler: (handler: HandlerItemType) => void;
  onHandlerClick: (handler: HandlerItemType) => void;
  onCancelHandler: (handlerId: string) => void;
  isReadOnly?: boolean;
};

export const RunHandlers = ({
  handlers = [],
  executedHandlersInfoByRecord,
  onPinHandler,
  onHandlerClick,
  onCancelHandler,
  isReadOnly = false,
}: RunSelectorProps) => {
  const { t } = useTranslation();

  const [isDropdownOpened, { toggle: handleToggleDropdown }] = useBoolean(false);
  const [search, setSearch] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const filteredHandlers = handlers.filter((handler) => isMatched(search, handler.name));

  // TODO: Temporary disabled
  // const handleOpenLogs = (e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();
  //   dispatch(toggleLogsContainer(!isLogsOpened));
  // };

  return (
    <Dropdown
      isOpen={isDropdownOpened}
      toggle={handleToggleDropdown}
      className={cx('d-flex align-items-center me-2', styles.dropdown)}
    >
      <DropdownToggle tag="div">
        <div className="position-relative d-flex align-items-center">
          <Icon clickable={false} SvgIcon={APP_ICONS.run} className="position-absolute ms-2" />
          <Input
            className={cx('bg-gray-500 border-gray-600 fs-13 px-4', styles.input)}
            placeholder={t('typeToolbar.run') || ''}
            value={search}
            onChange={handleInputChange}
          />
          <CancelButton isReadOnly={isReadOnly} />
          {/* TODO: Temporary disabled */}
          {/* <Icon
          SvgIcon={APP_ICONS.bell}
          className={cx('position-absolute', styles.bellIcon)}
          id="run-selector-open-logs"
          onClick={handleOpenLogs}
          />
          <TooltipItem
            id="run-selector-open-logs"
            content={t('typeToolbar.openLogs')}
          />
          */}
        </div>
      </DropdownToggle>
      <UiDropdownMenu className={cx('overflow-y-auto overflow-x-hidden', styles.dropdownMenu)}>
        {filteredHandlers.length ? (
          filteredHandlers.map((handler) => (
            <RunHandlerItem
              key={handler.id}
              handler={handler}
              handlerExecutionInfo={executedHandlersInfoByRecord[handler.key]}
              onHandlerClick={() => onHandlerClick(handler)}
              onPinHandler={() => onPinHandler(handler)}
              renderHandlerName={(handlerName: string) => <HighlightTextMatch text={handlerName} searchText={search} />}
              onCancelHandler={onCancelHandler}
              isReadOnly={isReadOnly}
            />
          ))
        ) : (
          <div className="text-text1 px-2 py-1 fs-13 lh-16">{t('typeToolbar.emptyRunHanlers')}</div>
        )}
      </UiDropdownMenu>
    </Dropdown>
  );
};
