import { FilterProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { Input } from 'reactstrap';
import cx from 'classnames';

import { DataType } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

export const FilterInput = ({ column }: FilterProps<DataType>) => {
  const { t } = useTranslation();
  const { filterValue, setFilter } = column;

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleClearInput = () => {
    setFilter('');
  };

  // 'string' || 'number' || 'date'
  const isInputFilter = true;
  const isDateFilter = false;

  return (
    <>
      {isInputFilter && (
        <div className={cx('position-relative p-0', styles.container)}>
          <Input
            placeholder={t('grid.filterInputPlaceholder') || ''}
            value={filterValue || ''}
            onChange={handleChangeInput}
            className={cx('w-100 bg-transparent fs-13 ps-1 pe-3 text-text2', styles.input)}
          />
          {filterValue && (
            <span className={cx('position-absolute', styles.cancelIcon)} onClick={handleClearInput}>
              <Icon SvgIcon={APP_ICONS.clearFilter} headerCellClear />
            </span>
          )}
        </div>
      )}
      {isDateFilter && (
        <Input
          type="date"
          onChange={handleChangeInput}
          className={cx('w-100 bg-transparent fs-12 p-1 text-text2', styles.input)}
        />
      )}
    </>
  );
};
