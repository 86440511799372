import { ValueTypesEnum } from 'utils';

import { ModuleTypeModel, NestedTypeModel, ValueTypeModel } from './shared.model';

export enum KindTypeModel {
  ABSTRACT = 'Abstract',
  FINAL = 'Final',
  ELEMENT = 'Element',
  ABSTRACT_ELEMENT = 'AbstractElement',
}

export enum SchemaDisplayKind {
  Basic = 'Basic',
  Singleton = 'Singleton',
  Dashboard = 'Dashboard',
}

export enum HandlerType {
  VIEWER = 'Viewer',
  JOB = 'Job',
  CONTENT = 'Content',
}

export type SchemaTypesItemModel = {
  Label: string;
  Module: string;
  Name: string;
  DataAvailable?: boolean;
};

export type SchemaModeluesItemModel = {
  Module: string;
  Prefix: string;
  PrefixLabel: string;
};

export type SchemaTypeDeclareParamsModel = {
  Value?: { type: string };
  Enum: NestedTypeModel;
  Name: string;
};

export type SchemaTypeDeclareHandlersModel = {
  Name: string;
  Label: string;
  Comment?: string;
  Type: HandlerType;
  Return?:
    | {
        Data: {
          Module: ModuleTypeModel;
          Name: string;
        };
      }
    | { Value?: { Type: ValueTypesEnum } }
    | {
        Key: {
          Module: ModuleTypeModel;
          Name: string;
        };
      };
  Params?: SchemaTypeDeclareParamsModel[];
  Static?: boolean;
};

export type SchemaTypeImplementHandlerModel = {
  Name: string;
  Language: { languageID: string };
};

export type SchemaTypeElementsKeyTypeModel = {
  Elements: SchemaTypeElementsModel[];
  Module: ModuleTypeModel;
  Name: string;
};

export type SchemaTypeElementsEnumOptionModel = {
  Comment: string;
  Label: string;
  Name: string;
};

export type SchemaTypeElementsEnumModel = NestedTypeModel & {
  Comment: string;
  Label: string;
  Items: SchemaTypeElementsEnumOptionModel[];
};

export type SchemaTypeElementsModel = {
  _t: string;
  Key?: SchemaTypeElementsKeyTypeModel;
  Value?: {
    Type: ValueTypeModel;
  };
  Data?: NestedTypeModel;
  Enum?: SchemaTypeElementsEnumModel;
  Name: string;
  Label?: string;
  Comment: string;
  Optional?: boolean;
  Filterable?: boolean;
  Vector?: boolean;
  OptionalVectorElement?: boolean;
  Format?: string;
  ReadOnly?: boolean;
  Hidden?: boolean;
};

export type SchemaTypeDataModel = {
  rootType: string;
  typeList: SchemaTypeListModel;
};

export type SchemaTypeListModel = Record<string, SchemaTypeModel>;

export type SchemaTypeModel = {
  Module: ModuleTypeModel;
  Name: string;
  Label: string;
  Comment: string;
  Declare?: { Handlers: SchemaTypeDeclareHandlersModel[] | null };
  Implement?: { Handlers: SchemaTypeImplementHandlerModel[] };
  Elements?: SchemaTypeElementsModel[];
  Keys?: string[];
  Component?: string;
  DisplayKind: SchemaDisplayKind;
  Kind?: KindTypeModel;
  Items?: SchemaTypeElementsEnumOptionModel[];
};

export type SchemaInterfaceModel = {
  Name: string;
  Label: string;
  Module: string;
};

export type SchemaEnumModel = {
  Name: string;
  Label: string;
  Module: string;
};

export type SchemaTypeHierarchyModel = {
  Name: string;
  Label: string;
};
