import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import { FC, useState } from 'react';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';

type MaximizeButtonType = {
  isMaximizedView: boolean;
  onCLick: () => void;
};

export const MaximizeButton: FC<MaximizeButtonType> = ({ isMaximizedView, onCLick }) => {
  const { t } = useTranslation();
  const tooltip = t(isMaximizedView ? 'maximizeButton.exitFullScreen' : 'maximizeButton.fullScreen');
  const icon = isMaximizedView ? APP_ICONS.minimize : APP_ICONS.maximize;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tooltip open={open}>
      <TooltipTrigger
        className="mx-1_5 d-flex"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(false)}
      >
        <Button className={`d-flex align-items-center p-0 bg-transparent border-0`} onClick={onCLick}>
          <Icon SvgIcon={icon} color="text-chat-maximize-btn-color" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};
