import { Dispatch, SetStateAction } from 'react';

import { rightSideStyling } from './rightSideStyling';
import { applyTabsTransition } from './applyTabsTransition';
import { leftSideStiling } from './leftSideStyling';
import { outOfTabsStyling } from './outOfTabsStyling';

export const styleSiblingTabs = (
  event: MouseEvent,
  closestTabWrapper: HTMLElement,
  draggingTab: HTMLDivElement,
  dropZone: HTMLElement,
  tabsZone: HTMLElement | null,
  startTabIndex: number,
  endTabIndex: number,
  setEndTabIndex: Dispatch<SetStateAction<number>>,
  dragOutOfTabsZone: boolean,
  setDragOutOfTabsZone: Dispatch<SetStateAction<boolean>>,
  allTabs: NodeListOf<Element> | null
) => {
  const closesTabWrapperIndex = closestTabWrapper ? +closestTabWrapper.dataset.index! : 0;

  applyTabsTransition(draggingTab, dropZone);

  if (closestTabWrapper && event.clientX > closestTabWrapper.offsetLeft + draggingTab.offsetWidth) {
    rightSideStyling(closestTabWrapper, closesTabWrapperIndex, endTabIndex, setEndTabIndex);
  }

  if (closestTabWrapper && event.clientX < closestTabWrapper.offsetLeft + draggingTab.offsetWidth) {
    leftSideStiling(
      closestTabWrapper,
      closesTabWrapperIndex,
      draggingTab,
      dropZone,
      tabsZone,
      startTabIndex,
      endTabIndex,
      setEndTabIndex,
      dragOutOfTabsZone,
      setDragOutOfTabsZone
    );
  }

  if ((dropZone && dropZone.dataset.position !== 'tabs') || !dropZone) {
    outOfTabsStyling(draggingTab, setDragOutOfTabsZone, allTabs);
  }
};
