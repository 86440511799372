import { v4 as uuid } from 'uuid';

import { isObject } from 'utils';
import { handlerApi } from 'api';

import { FileStatusMap, FileStatusType, MessageGroupStatus, MessageGroupType } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  table: string;
  datasource: string;
  dataset: string;
  chatKey: string;
};

type ResponseMessageType = {
  Files?: Array<{ FileNameWithExt: string; Status: string }>;
  TextInput?: string;
  TextOutput?: string;
};

const isQuestion = (input: unknown): input is Required<Pick<ResponseMessageType, 'TextInput' | 'Files'>> =>
  isObject(input) && 'TextInput' in input;
const isAnswer = (input: unknown): input is Required<Pick<ResponseMessageType, 'TextOutput'>> =>
  isObject(input) && 'TextOutput' in input;

const mapResponseToMessageGroup = (messages: ResponseMessageType[]): MessageGroupType[] => {
  const messageGroups: MessageGroupType[] = [];

  for (let i = 0; i < messages.length; i += 2) {
    const [question, answer] = messages.slice(i, i + 2);
    let messageGroup: MessageGroupType = {
      id: uuid(),
      status: MessageGroupStatus.INITIAL,
    };

    if (isQuestion(question)) {
      messageGroup = {
        ...messageGroup,
        question: {
          id: uuid(),
          text: question.TextInput,
          files: Array.isArray(question.Files)
            ? question.Files.map((file) => {
                const status = FileStatusMap[file.Status] || FileStatusType.NONE;
                return {
                  status,
                  filename: file.FileNameWithExt,
                  isSaved: true,
                  savedStatus: status,
                };
              })
            : [],
        },
      };
    }

    if (isAnswer(answer)) {
      messageGroup = {
        ...messageGroup,
        answer: {
          id: uuid(),
          text: answer.TextOutput,
          files: [],
        },
      };
    }

    messageGroups.push(messageGroup);
  }

  return messageGroups;
};

export const loadHistoryRequest = ({ key, table, datasource, dataset, chatKey }: RequestParamsType) =>
  handlerApi
    .run<Array<ResponseMessageType>>({
      key,
      table,
      dataset,
      method: 'LoadChatHistory',
      dataSource: datasource,
      args: { CurrentChat: chatKey },
    })
    .then(mapResponseToMessageGroup);
