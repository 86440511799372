import cx from 'classnames';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import ReactQuill, { UnprivilegedEditor } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setFormatTextareaLength } from 'store/shared-reducers/SendLogsDataSlice';

import { useDebounce } from 'shared/Hooks/useDebounce';

import { TextareaSelectedFiles } from './components/TextareaSelectedFiles';
import { QuillToolbar } from './components/QuillToolbar';

import { logsQuillFormats } from './utils/constants';

import styles from './styles.module.scss';

type TextareaFormattingType = {
  setFeedbackTextareaValue?: Dispatch<SetStateAction<string>>;
  setFeedbackFiles?: Dispatch<SetStateAction<File[]>>;
};

export const TextareaFormatting = ({ setFeedbackTextareaValue, setFeedbackFiles }: TextareaFormattingType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { debounce } = useDebounce();
  const quillRef = useRef<ReactQuill | null>(null);

  const {
    sendLogsData: { isFormatTextareaValid },
  } = useAppSelector((state) => state.SendLogsDataSliceReducer);

  const [textareaValue, setTextareaValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleOpenUploadModal = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    input.onchange = () => {
      const file = input.files![0];
      setSelectedFiles((prev) => [...prev, file]);
      setFeedbackFiles && setFeedbackFiles((prev) => [...prev, file]);
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: '#toolbar',
        handlers: {
          image: handleOpenUploadModal,
        },
      },
    }),
    []
  );

  const handleChangeTextareaValue = (value: string, delta: unknown, source: unknown, editor: UnprivilegedEditor) => {
    setTextareaValue(value);
    setFeedbackTextareaValue && setFeedbackTextareaValue(value);

    const changeTextareaTextLength = (textLength: string) => dispatch(setFormatTextareaLength(textLength));

    debounce({
      fn: changeTextareaTextLength,
      value: editor.getLength().toString(),
    });
  };

  const handleRemoveFile = (fileName: string) => {
    const newFilesArr = selectedFiles.filter((item) => item.name !== fileName);
    setSelectedFiles(newFilesArr);
    setFeedbackFiles && setFeedbackFiles(newFilesArr);
  };

  return (
    <>
      <div
        className={cx(styles.container, {
          'border-send-feedback-input-border-error': !isFormatTextareaValid,
        })}
      >
        <QuillToolbar />
        <ReactQuill
          ref={quillRef}
          modules={modules}
          formats={logsQuillFormats}
          value={textareaValue}
          onChange={handleChangeTextareaValue}
          placeholder={t('sendLogsModal.textareaPlaceholder') || ''}
          className={cx(`m-0 w-100 overflow-auto`, styles.textarea)}
        />
        <TextareaSelectedFiles selectedFiles={selectedFiles} handleRemoveFile={handleRemoveFile} />
      </div>

      <div className={styles.validationMessage}>
        {!isFormatTextareaValid ? t('validationMessage.noDescription') : ''}
      </div>
    </>
  );
};
