import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { dispatch } from 'store';
import { deserialize } from 'utils';
import { useAppNavigate } from 'router/hooks';
import { renew } from 'store/shared-reducers/AppStateSlice';
import { externalStorage } from 'shared/ExternalStorage';

import { authApiUrls } from './requests/auth.api';
import { generateHeaders } from './helpers/generateHeaders';
import { getBaseUrl } from './helpers/generateBaseUrl';
import { parseAxiosErrorMessage } from './helpers/getErrorMessage';
import { createRefreshAndRetryQueue } from './helpers/refreshAndRetryQuery.helper';

export const axiosMain = axios.create({
  baseURL: getBaseUrl(),
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  transformResponse: (data) => data,
});

export const setupInterceptors = ({ navigateToLogin }: ReturnType<typeof useAppNavigate>) => {
  let isRefreshingToken = false;
  const refreshAndRetryQueue = createRefreshAndRetryQueue();

  axiosMain.interceptors.request.use((request) => {
    const req = request;

    const headers = generateHeaders();

    Object.keys(headers).forEach((headerName: string) => {
      const headerValue = headers[headerName];
      req.headers.set(headerName, headerValue);
    });

    return req;
  });
  axiosMain.interceptors.response.use((response) => {
    const res = response;

    // TODO REMOVE THE DESERIALIZE HERE AND ONLY USE IT WHEN NECESSARY
    if (response.config.responseType !== 'arraybuffer') res.data = deserialize(res.data as string);
    return res;
  });
  axiosMain.interceptors.response.use(
    (res) => res,
    async (error: AxiosError<string> | Error): Promise<AxiosError> => {
      if (axios.isAxiosError(error)) {
        const originalRequest = error.config as AxiosRequestConfig;
        const message = parseAxiosErrorMessage(error);
        const { method, url } = error.config as AxiosRequestConfig;
        const { status } = (error.response as AxiosResponse<string>) ?? {};

        console.error(`🚨 [API] ${(method as string)?.toUpperCase()} ${url as string} | Error ${status} ${message}`);

        switch (status) {
          case 401: {
            const isAuthRenewRequest = originalRequest.url === authApiUrls.renew;

            if (isAuthRenewRequest) {
              externalStorage.removeTokens();
              externalStorage.removeSessionId();
              return Promise.reject(error);
            }

            if (!isRefreshingToken) {
              isRefreshingToken = true;

              try {
                // Refresh the access token
                await dispatch(renew()).unwrap();

                // Retry all requests in the queue with the new token
                refreshAndRetryQueue.iterate(({ config, resolve, reject }) => {
                  axiosMain.request(config).then(resolve).catch(reject);
                });

                // Clear the queue
                refreshAndRetryQueue.clear();

                // Retry the original request
                return axiosMain(originalRequest);
              } catch (refreshError) {
                refreshAndRetryQueue.clear();
                externalStorage.removeTokens();
                externalStorage.removeSessionId();
                navigateToLogin();
              } finally {
                isRefreshingToken = false;
              }
            }

            return new Promise<void>((resolve, reject) => {
              refreshAndRetryQueue.enqueue({ config: originalRequest, resolve, reject });
            });
          }
          case 403: {
            // "Permission denied"
            break;
          }
          case 404: {
            // "Invalid request"
            break;
          }
          case 500: {
            // "Server error"
            break;
          }
          default: {
            // "Unknown error occurred"
            break;
          }
        }
      } else {
        console.error(`🚨 [API] | Error ${error.message}`);
      }

      return Promise.reject(error);
    }
  );
};
