import { useContext } from 'react';

import { ViewersNavigatorContext } from '../contexts';

export function useViewersNavigator() {
  const context = useContext(ViewersNavigatorContext);

  if (context == null) {
    throw new Error('Cannot find context for viewers navigator');
  }

  return context;
}
