import { ViewOfType } from 'store/types';

export const getTypeOfFirstElementHelper = (data: ViewOfType[]): string | null => {
  const firstElement = data.at(0);

  if (firstElement) {
    const { _t: type } = firstElement;
    return type;
  }

  return null;
};
