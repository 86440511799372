import { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Page } from 'react-pdf';

import { Loader } from 'shared/Loader';
import { PdfHighlightType, PdfMatchType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';
import { searchAllMatches } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/helpers';

import { PageCharIndexList } from './utils/pageCharIndexList';
import { isVisibleInContainer, updateHighlightIndexByPosition } from './utils/helpers';
import { PDF_HIGHLIGHT_CLASSNAME } from './utils/constants';
import { PdfHighlights } from './components';
import { CharIndexList } from './utils/charIndexList';

type PdfPageProps = {
  shouldScroll: boolean;
  setScrolled: () => void;
  currentMatchedTerm?: PdfMatchType;
  documentRef: MutableRefObject<HTMLDivElement | null>;
  containerRef: MutableRefObject<HTMLDivElement | null>;
  search: string;
  pageNumber: number;
  onScrollToOffset: (offset: number) => void;
};

export const PdfPage: FC<PdfPageProps> = ({
  shouldScroll,
  setScrolled,
  onScrollToOffset,
  currentMatchedTerm,
  search,
  documentRef,
  containerRef,
  pageNumber,
}) => {
  const [highlights, setHighlights] = useState<PdfHighlightType[]>([]);

  const activeIndex =
    !currentMatchedTerm || currentMatchedTerm.page !== pageNumber ? -1 : currentMatchedTerm.matchIndex;

  useEffect(() => {
    const isMatchedPage = currentMatchedTerm?.page === pageNumber;
    const isEmptyHighlights = highlights.length === 0;
    if (!isMatchedPage || !documentRef.current || !containerRef.current || isEmptyHighlights || !shouldScroll) return;

    const element: HTMLElement | null = documentRef.current.querySelector(
      `div[data-page-number="${pageNumber}"] .${PDF_HIGHLIGHT_CLASSNAME}[data-index="${currentMatchedTerm.matchIndex}"]`
    );

    if (element && !isVisibleInContainer(element, containerRef.current)) {
      onScrollToOffset(element.offsetTop);
    }

    setScrolled();
  }, [currentMatchedTerm, highlights, shouldScroll]);

  const clearHighlights = () => setHighlights([]);

  const highlightMatchesOfPage = useCallback(() => {
    if (!search || !documentRef.current) {
      clearHighlights();
      return;
    }

    const textLayerNode = documentRef.current.querySelector(`div[data-page-number="${pageNumber}"] .textLayer`);
    if (!textLayerNode) return;

    const textLayerRect = textLayerNode.getBoundingClientRect();
    const spanNodes = Array.from(textLayerNode?.querySelectorAll('[role="presentation"]') || []);

    const pageCharIndexes = PageCharIndexList.createByElements(spanNodes);
    if (pageCharIndexes.isEmpty) return;

    const generatedHighlights: PdfHighlightType[] = searchAllMatches(search, pageCharIndexes.fullText)
      .map((match) => pageCharIndexes.slice(match.startIndex, match.endIndex).groupBySpanIndex())
      .reduce((accHighlights, spanIndexes, index) => {
        const currentHighlights = new CharIndexList(Object.values(spanIndexes))
          .removeSpacesBetweenWords()
          .generateRectWithCoordinates(textLayerRect, spanNodes)
          .map((rect) => ({ ...rect, index }));
        return accHighlights.concat(currentHighlights);
      }, [] as PdfHighlightType[]);

    setHighlights(updateHighlightIndexByPosition(generatedHighlights));
  }, [pageNumber, search]);

  return (
    <Page
      className="bg-transparent"
      pageNumber={pageNumber}
      loading={<Loader />}
      onRenderTextLayerSuccess={highlightMatchesOfPage}
    >
      <PdfHighlights className="position-absolute start-0 top-0" highlights={highlights} activeIndex={activeIndex} />
    </Page>
  );
};
