import { FC, useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { Loader } from 'shared/Loader';
import { GridTabDataType } from 'store/types';
import { ConfigType, LayoutType } from 'view/DragnDrop/utils/types';
import { DnDContext } from 'view/DragnDrop/DraggableContainer';
import { DropBox } from 'view/DragnDrop/DropBox';
import { findParent } from 'view/DragnDrop/utils/helpers/findParent';
import { DropBoxEnum } from 'view/DragnDrop/utils/constants';
import { findTarget } from 'view/DragnDrop/utils/helpers/findTarget';
import { correctContent } from 'view/DragnDrop/utils/helpers/correctContent';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { useComponentsMapper } from 'shared/Hooks/useComponentsMapper';

import { useAppContext } from 'context';

import { EditorUpdateType } from 'view/EditorData/type';

import { EDITOR_TABS, EditorTabsEnum } from './utils/constants';
import { EditorTabContent } from './EditorTabContent';
import { EditorTabs } from './EditorTabs';

export type EditorContentProps = {
  isLoading?: boolean;
  type: string;
  selectedGridRow?: SelectedGridRowType;
  withKey?: boolean;
  showToolbar?: boolean;
  tabs?: EditorTabsEnum[];
  stackRef: ConfigType;
  onUpdate?: (data?: EditorUpdateType) => void;
  isDashboardType?: boolean;
  containerClassname?: string;
};

export const EditorContentInner: FC<EditorContentProps> = ({
  isLoading: outerLoading = false,
  type,
  selectedGridRow,
  showToolbar = true,
  stackRef,
  onUpdate,
  isDashboardType = false,
  containerClassname,
}) => {
  const { action } = useAppContext();
  const { state: tabContextState } = useTabItemContext();
  const { config, isLoading, setConfig, saveLayout } = useContext(DnDContext);
  const [successorType, setSuccessorType] = useState<string>();

  const configRef = useRef<ConfigType>(config);

  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const onApplySuccessorType = (successor: string) => {
    setSuccessorType(successor);
  };

  const onChangeTab = (tab: GridTabDataType) => {
    const configCopy = structuredClone(configRef.current);

    const parent = findParent(configCopy, tab.key);

    if (parent) {
      ((parent as ConfigType).content as GridTabDataType[]).forEach((configTab) => {
        configTab.isCurrentlyDragging = false;
        configTab.isActive = configTab.key === tab.key;
      });

      setConfig(configCopy);

      const simplifiedLayout = getParsedConfig(configCopy, true) as LayoutType;

      saveLayout(simplifiedLayout, tabContextState.pinnedHandlers);
    }
  };

  const onSetTabs = (updatedTabs: GridTabDataType[]) => {
    const configCopy = structuredClone(configRef.current);

    const target = findTarget(configCopy, stackRef.key);

    if (target) {
      target.content = updatedTabs;
    }

    const correctedConfig = correctContent(configCopy, true) as ConfigType;

    setConfig(correctedConfig);

    const simplifiedLayout = getParsedConfig(correctedConfig, true) as LayoutType;

    saveLayout(simplifiedLayout);
  };

  const activeTab = (stackRef.content as GridTabDataType[])?.find((tab) => tab.isActive && !tab.isCurrentlyDragging);

  const filterActiveTabs = (tabs: GridTabDataType[]) => tabs?.filter((tab) => tab.isActive).map((tab) => tab.id);

  const handleTransition = (data: TransitionRecordType) => {
    const configCopy = structuredClone(configRef.current);
    const tabId = `${EditorTabsEnum.EDITOR_TRANSITION}-${data.key}-${uuid()}`;

    const target = findTarget(configCopy, stackRef.key);
    if (target) {
      const isTransitionTabDuplicate = target.content.some((tab) => tab.id.includes(data.key));

      target.content = (target.content as GridTabDataType[]).map((tab) => ({
        ...tab,
        isActive: isTransitionTabDuplicate && tab.id.includes(data.key),
      }));

      if (!isTransitionTabDuplicate) {
        target.content.push({
          ...EDITOR_TABS[EditorTabsEnum.EDITOR_TRANSITION],
          id: tabId,
          key: tabId,
          disableActions: true,
          label: data.key,
          transitionData: data,
          isActive: true,
        });
      }

      const correctedConfig = correctContent(configCopy, true) as ConfigType;
      setConfig(correctedConfig);

      const simplifiedLayout = getParsedConfig(correctedConfig, true) as LayoutType;

      saveLayout(simplifiedLayout);
    }
  };

  const handleCloseTab = (data: GridTabDataType) => {
    if (!data?.key) return;
    const configCopy = structuredClone(configRef.current);
    const target = findTarget(configCopy, stackRef.key);

    if (target) {
      const content = target.content as GridTabDataType[];
      const closeTabIndex = content.findIndex((it) => it.key === data.key);
      if (content[closeTabIndex]?.isActive) {
        const editorIndex = content.findIndex((it) => it.key === EditorTabsEnum.EDITOR);
        content[editorIndex >= 0 ? editorIndex : 0].isActive = true;
      }
      target.content.splice(closeTabIndex, 1);
      setConfig(configCopy);
    }
  };

  const handleChangeTab = (data: GridTabDataType) => {
    activeTab?.key === EditorTabsEnum.EDITOR ? action.onConfirmLeaveEditor(() => onChangeTab(data)) : onChangeTab(data);
  };

  if (isLoading || !type || outerLoading) return <Loader />;

  return (
    <div
      className={cx('w-100 d-flex flex-column h-100 overflow-x-auto overflow-y-hidden', containerClassname)}
      data-testid="editor-content"
    >
      {(!isDashboardType || stackRef.content.length > 1) && (
        <EditorTabs
          onCloseTab={handleCloseTab}
          activeTabs={filterActiveTabs(stackRef.content as GridTabDataType[])}
          activeTab={activeTab?.key}
          tabs={stackRef.content as GridTabDataType[]}
          onChangeTab={handleChangeTab}
          onSetTabs={onSetTabs}
          stackRef={stackRef}
        />
      )}
      <div className="stack-content d-flex flex-column h-100 position-relative overflow-auto">
        <DropBox position={DropBoxEnum.INNER} stackRef={stackRef} />
        <EditorTabContent
          showToolbar={showToolbar}
          activeTab={activeTab?.key}
          transitionTab={activeTab?.transitionData}
          type={type}
          selectedGridRow={selectedGridRow}
          successorType={successorType}
          onApplySuccessorType={onApplySuccessorType}
          onUpdate={onUpdate}
          onTransition={handleTransition}
        />
      </div>
    </div>
  );
};

export const EditorContent: FC<EditorContentProps> = (props) => {
  const { EditorContentInner: EditorContentInnerComponent } = useComponentsMapper();

  return <EditorContentInnerComponent {...props} />;
};
