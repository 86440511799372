import './index.scss';

export type ButtonProps = {
  type?: 'submit' | 'reset' | 'button' | undefined;
  title: string;
  variant?: 'primary' | 'secondary' | 'destructive';
  classname?: string;
  disabled?: boolean;
  onClick?: () => void;
  autoMinWidth?: boolean;
};

/**
 * @deprecated. Please use UIButton instead.
 */
export const Button = ({
  type,
  title,
  variant = 'primary',
  classname,
  disabled,
  onClick,
  autoMinWidth = false,
}: ButtonProps) => (
  <button
    type={type}
    className={`c-button-${variant} border-0 text-text3 fs-14 c-button  ${classname || ''}`}
    onClick={onClick}
    disabled={disabled}
    style={autoMinWidth ? { minWidth: 'auto' } : {}}
  >
    {title}
  </button>
);
