import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Tabs } from 'shared/Tabs';
import { closeTab, saveAppState, setTabs, updateActiveTabIndex } from 'store/shared-reducers/AppStateSlice';
import { selectOpenedTabs } from 'store/selectors/AppState.selector';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { useAppContext } from 'context';
import { useAppDispatch } from 'store/hooks';
import { GridTabDataType } from 'store/types';
import { MaximizeButton } from 'shared/MaximizeButton';
import { GridTabEnum } from 'view/DragnDrop/utils/constants';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';
import { selectMetaData } from 'containers/TabItemContent/store/selectors';
import { SchemaDisplayKind } from 'api';

export type GridTabsProps = {
  activeTabId: string;
};

export const GridTabs: FC<GridTabsProps> = ({ activeTabId }) => {
  const { setActiveTab } = useTabParams();

  const dispatch = useAppDispatch();
  const { action, state } = useAppContext();
  const metaData = useSelector(selectMetaData);
  const openedTabs = useSelector(selectOpenedTabs);

  const isDashboard = metaData.data.DisplayKind === SchemaDisplayKind.Dashboard;
  const isMaximizedView = !!state.fullScreenLayerId;

  useEffect(() => {
    if (state.activeEditorTab !== EditorTabsEnum.EDITOR) {
      action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);
    }

    dispatch(saveAppState());
    dispatch(updateActiveTabIndex({ id: activeTabId }));

    action.setMainGridStackContentLengthAction(0);
  }, [activeTabId]);

  const handleMaximizedButton = () => {
    action.setFullScreenLayerIdAction(isMaximizedView ? '' : GridTabEnum.MAIN);
  };

  const handleCloseTab = (tab: GridTabDataType) => {
    action.onConfirmLeaveEditor(() => {
      action.clearCacheQueryByTypeAction(tab.key);
      dispatch(closeTab(tab));

      const oldTabIndex = openedTabs.findIndex((openedTab) => openedTab.id === tab.id);
      const updatedActiveTab = openedTabs[oldTabIndex + 1]?.id || openedTabs[oldTabIndex - 1]?.id;
      setActiveTab(updatedActiveTab);
    });
  };

  const handleChangeTab = (tab: GridTabDataType) => {
    action.onConfirmLeaveEditor(() => setActiveTab(tab.id));
  };

  const renderMaximizeBtn = () => {
    const isMultiTabStack = state.mainGridStackContentLength > 1;
    const someStackAlreadyMaximized = isMaximizedView && !state.fullScreenLayerId?.includes(GridTabEnum.MAIN);

    if (isMultiTabStack || someStackAlreadyMaximized || isDashboard) {
      return null;
    }

    return (
      <div className="d-flex h-100 align-items-center px-2">
        <MaximizeButton isMaximizedView={isMaximizedView} onCLick={handleMaximizedButton} />
      </div>
    );
  };

  if (state.isUserMode) return null;

  return (
    <Tabs
      openedTabs={openedTabs}
      activeTab={activeTabId}
      onChangeTab={handleChangeTab}
      onCloseTab={handleCloseTab}
      onSetTabs={(tabs) => dispatch(setTabs(tabs))}
      renderRightContent={renderMaximizeBtn()}
    />
  );
};
