import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Copy } from 'shared/Copy';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLanguage } from 'view/Header/store/HeaderDataSlice';
import { setApplicationTheme } from 'store/shared-reducers/AppStateSlice';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import {
  selectActiveTabIndex,
  selectApplicationTheme,
  selectBackendVersion,
  selectOpenedTabs,
  selectUser,
} from 'store/selectors/AppState.selector';
import { UserIcon } from 'ui';
import { toast } from 'utils';
import { entityApi } from 'api';

import { UserInitials } from '../UserInitials';
import styles from './styles.module.scss';
import { DropdownRow } from '../DropdownRow';
import { AVAIABLE_LANGUAGES } from './utils/constants';
import { getThemeOptionsHelper } from './utils/helpers';
import { LogoutButton, Versions } from './components';
import { InfoListItem } from './components/InfoListItem';

export const UserInfo: FC = () => {
  // const { state, action } = useAppContext();
  const user = useSelector(selectUser);
  const theme = useSelector(selectApplicationTheme);
  const openedTabs = useSelector(selectOpenedTabs);
  const backendVersion = useSelector(selectBackendVersion);
  const activeTabIndex = useSelector(selectActiveTabIndex);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    headerData: { language },
  } = useAppSelector((state_) => state_.HeaderDataSliceReducer);

  const handleThemeChange = (themeKey: string) => {
    const openedTabsArr = openedTabs.map(({ id, label }) => ({
      Type: {
        Name: id,
        Module: id,
        Label: label,
      },
    }));

    entityApi
      .saveEntity({
        oldRecordKey: user?.username || '',
        data: {
          ApplicationTheme: themeKey,
          BackendVersion: backendVersion,
          OpenedTabs: openedTabsArr,
          ActiveTabIndex: activeTabIndex,
          User: user?.username || null,
          _t: 'UiAppState',
        },
      })
      .then(() => {
        dispatch(setApplicationTheme(themeKey));
      })
      .catch(toast.error);
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle className="w-100" tag="div">
        <UserInitials clickable />
      </DropdownToggle>
      <DropdownMenu className="p-0 rounded border-user-info-border">
        <div className={cx('overflow-hidden', styles.container)}>
          <div className="d-flex flex-row w-100 p-2 text-white bg-user-info-header align-items-center gap-3">
            <UserInitials clickable={false} />
            <div className="d-flex flex-column lh-16 fw-semibold">
              <div className="fs-13">{user?.fullName}</div>
              <div className="fs-10">{user?.email}</div>
            </div>
            <LogoutButton />
          </div>
          <div className="container bg-user-info-background d-flex flex-column gap-1 p-12px text-user-info-text">
            <div className="d-flex flex-row">
              <Icon className="pe-8px" SvgIcon={APP_ICONS.theme} clickable={false} color="text-user-info-icon" />
              <DropdownRow
                label={t('header.userInfo.theme.label')}
                selectedOptionKey={theme}
                options={getThemeOptionsHelper(t)}
                onChange={handleThemeChange}
              />
            </div>
            <div className="d-flex flex-row">
              <Icon className="pe-8px" SvgIcon={APP_ICONS.language} clickable={false} color="text-user-info-icon" />
              <DropdownRow
                label={t('header.userInfo.language.label')}
                selectedOptionKey={language}
                options={AVAIABLE_LANGUAGES}
                onChange={(langKey) => dispatch(setLanguage(langKey))}
              />
            </div>

            <div className="row bg-user-info-background mt-1 fs-13 border-top border-user-info-separator">
              <InfoListItem icon={<UserIcon className="text-user-info-icon" />} label={`${t('header.userInfo.role')}:`}>
                {user?.roles?.reduce((acc, role) => `${acc}${acc ? ',' : ''} ${role}`, '')}
              </InfoListItem>
              <InfoListItem
                icon={<Icon SvgIcon={APP_ICONS.build} clickable={false} color="text-user-info-icon" />}
                label={`${t('header.userInfo.build')}:`}
                action={<Copy textToCopy={process.env.CL_FRONTEND_VERSION!} />}
              >
                {process.env.CL_FRONTEND_VERSION!}
              </InfoListItem>

              {/* Commented until User Mode adapted to the new theme
              <InfoListItem
                icon={<SwitchIcon className="text-user-info-icon" />}
                label={`${t('header.userInfo.userMode')}:`}
              >
                <div className="d-flex items-center">
                  <UISwitch value={state.isUserMode} onChange={action.setIsUserMode} />
                </div>
              </InfoListItem> */}

              <Versions />
            </div>
          </div>
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
