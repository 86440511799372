import { DefaultSortTypes, SortByFn, Row } from 'react-table';
import BigNumber from 'bignumber.js';

import { GridTabDataType, ViewersMetadata } from 'store/types';
import { DataType } from 'view/Grid/utils/types';
import { DateFormatEnum, DateFormatUtility, isNaN, isNullable, isObject, isString, parseStringNumber } from 'utils';
import { ValueType } from 'store/types/tabMetaDataTypes';

export const getSortTypeByValueType = (
  valueType: ValueType['Type'] | undefined
): DefaultSortTypes | SortByFn<DataType> => {
  switch (valueType) {
    case 'Long':
    case 'Bool':
      return 'basic';
    case 'Int':
    case 'Double':
      return (rowA: Row<DataType>, rowB: Row<DataType>, columnId: string): number => {
        const valueA = rowA.values[columnId];
        const valueB = rowB.values[columnId];

        if (isNaN(valueA) && isNaN(valueB)) return 0;
        if (isNaN(valueA)) return 1;
        if (isNaN(valueB)) return -1;

        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;

        return 0;
      };
    case 'Time':
      return (rowA: Row<DataType>, rowB: Row<DataType>, columnId: string): number => {
        const valueA = rowA.values[columnId];
        const valueB = rowB.values[columnId];

        if (!isString(valueA) && !isString(valueB)) return 0;
        if (!isString(valueA)) return 1;
        if (!isString(valueB)) return -1;

        const timeA = DateFormatUtility.create(valueA, DateFormatEnum.TIME_MS);
        const timeB = DateFormatUtility.create(valueB, DateFormatEnum.TIME_MS);

        return timeA.sort(timeB);
      };
    default:
      return 'alphanumeric';
  }
};

export const getViewersTabs = (viewers: ViewersMetadata[]): GridTabDataType[] =>
  viewers.map(({ Name }) => ({
    id: Name,
    key: Name,
    label: Name,
    isCloseable: false,
  }));

export const toString = (input: unknown): string => {
  if (isNullable(input)) return '';
  if (BigNumber.isBigNumber(input)) return input.toString();
  if (isObject(input)) return JSON.stringify(input);

  switch (typeof input) {
    case 'bigint':
    case 'number':
    case 'boolean':
    case 'symbol':
      return input.toString();
    case 'string':
      return input;
    default:
      return '';
  }
};

export const toNumber = (input: unknown): number | BigNumber => {
  if (BigNumber.isBigNumber(input)) return input;

  switch (typeof input) {
    case 'number':
      return input;
    case 'boolean':
      return Number(input);
    case 'string':
      return parseStringNumber(input);
    default:
      return 0;
  }
};
