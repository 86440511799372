import { FC, ReactNode } from 'react';
import cx from 'classnames';

import styles from './Toolbar.module.scss';

type ToolbarProps = {
  children?: ReactNode;
};

type ToolbarGroupProps = {
  isRight?: boolean;
  children?: ReactNode;
};

type ToolbarComponentProps = FC<ToolbarProps> & {
  Divider: FC;
  Group: FC<ToolbarGroupProps>;
};

export const Toolbar: ToolbarComponentProps = ({ children }) => <div className={styles.toolbar}>{children}</div>;

Toolbar.Group = ({ children, isRight = false }) => (
  <div className={cx(styles.toolbarGroup, { [styles.toolbarGroupRight]: isRight })}>{children}</div>
);
Toolbar.Group.displayName = 'ToolbarGroup';

Toolbar.Divider = () => <div className={styles.toolbarDivider} />;
Toolbar.Divider.displayName = 'ToolbarDivider';
