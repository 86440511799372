import { HandlerOptionsType, handlerApi } from 'api';
import { isObject } from 'utils';

import { ProgressOutputType } from '../../../utils/types';

type ParamsType = {
  dataset: string;
  fileNames: string[];
  fileContentBytes: string[];
  removedFileNames: string[];
  datasource: string;
  key: string;
  table: string;
  prompt: string;
  chatKey: string;
  signal?: AbortSignal;
};

type ParamsWithStreamType = ParamsType & HandlerOptionsType<ProgressOutputType>;

const isProgressOutputType = (input: unknown): input is ProgressOutputType => {
  const keys: Array<keyof ProgressOutputType> = ['message', 'done'];
  return isObject(input) && keys.every((key: keyof ProgressOutputType) => Object.hasOwn(input, key));
};

export const sendPromptRequest = ({
  dataset,
  fileNames,
  fileContentBytes,
  removedFileNames,
  key,
  table,
  prompt,
  datasource,
  chatKey,
  signal,
}: ParamsType): Promise<string> =>
  handlerApi.postRun<string>({
    signal,
    key,
    table,
    dataset,
    method: 'SendPrompt',
    dataSource: datasource,
    args: {
      prompt,
      CurrentChat: chatKey,
      RemovedFileNames: removedFileNames,
      FileNames: fileNames,
      FileContentBytes: fileContentBytes,
    },
  });

export const sendFileRequest = ({
  dataset,
  fileNames,
  fileContentBytes,
  removedFileNames,
  key,
  table,
  datasource,
  chatKey,
  signal,
}: ParamsType): Promise<string> =>
  handlerApi.postRun<string>({
    signal,
    key,
    table,
    dataset,
    method: 'ParseFilesInput',
    dataSource: datasource,
    args: {
      CurrentChat: chatKey,
      RemovedFileNames: removedFileNames,
      FileNames: fileNames,
      FileContentBytes: fileContentBytes,
    },
  });

export const sendPromptStreamRequest = (params: ParamsWithStreamType) =>
  sendFileRequest(params).then(() =>
    handlerApi.postRunStream<ProgressOutputType>(
      {
        signal: params.signal,
        key: params.key,
        table: params.table,
        dataset: params.dataset,
        method: 'SendPrompt',
        dataSource: params.datasource,
        args: {
          prompt: params.prompt,
          CurrentChat: params.chatKey,
          RemovedFileNames: [],
          FileNames: [],
          FileContentBytes: [],
        },
      },
      {
        onProgress: (output) => {
          if (isProgressOutputType(output) && !output.done) {
            params.onProgress?.(output);
          }
        },
        onError: params.onError,
        onClose: params.onClose,
      }
    )
  );
