import { UIControlEnum, UIControlType, UIPasswordControlParams, UITextControlParams } from '../types';

export function createFormBuilder<T extends object>() {
  const controls: UIControlType<T>[] = [];

  return {
    addTextControl(params: UITextControlParams<T>) {
      const control: UIControlType<T> = { type: UIControlEnum.TEXT, ...params };
      controls.push(control);
      return this;
    },
    addPasswordControl(params: UIPasswordControlParams<T>) {
      const control: UIControlType<T> = { type: UIControlEnum.PASSWORD, ...params };
      controls.push(control);
      return this;
    },
    build(): UIControlType<T>[] {
      return controls;
    },
  };
}
