import { escapeStringRegexp } from 'utils';

type HighlightTextMatchProps = {
  text: string;
  searchText: string;
  isTypesSearch?: boolean;
};

export const HighlightTextMatch = ({ text, searchText, isTypesSearch = false }: HighlightTextMatchProps) => {
  const regex = new RegExp(`(${escapeStringRegexp(searchText)})`, 'gi');
  const formatText = (part: string): string => part.replaceAll(' ', '').toLowerCase();
  const formattedSearchText = formatText(searchText);
  const addStyledPart = (textPart: string, index: number) => (
    <span key={index} className="text-search-match-color">
      {textPart}
    </span>
  );

  if (!searchText) return <>{text}</>;

  const textAbbreviation = text
    .split(' ')
    .map((label) => label.charAt(0))
    .join('')
    .toLowerCase();

  const configAbbrArr = () => {
    const newTextArr: (string | JSX.Element)[] = [];
    let searchAbbreviation = searchText;

    text.split(' ').forEach((item, index) => {
      if (item[0].toLowerCase() === searchAbbreviation[0]?.toLowerCase()) {
        searchAbbreviation = searchAbbreviation.substring(1);
        newTextArr.push(addStyledPart(item[0], index), `${item.substring(1)} `);
      } else {
        newTextArr.push(`${item} `);
      }
    });
    return newTextArr;
  };

  return (
    <>
      {isTypesSearch && textAbbreviation.includes(searchText.toLowerCase())
        ? configAbbrArr().map((item) => item)
        : text
            .split(regex)
            .map((part, index) => (formatText(part) === formattedSearchText ? addStyledPart(part, index) : part))}
    </>
  );
};
