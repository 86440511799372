import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { selectRefreshToken, selectType } from 'store/selectors';
import { authApi, AuthStrategyEnum, AuthTypeEnum } from 'api';
import { useRequest } from 'hook/request.hook';
import { useAppNavigate } from 'router/hooks';
import { logout as signOut } from 'store/shared-reducers/AppStateSlice';

import styles from './LogoutButton.module.scss';

export const LogoutButton: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetch: getLogoutRedirect, isLoading } = useRequest(authApi.getLogoutRedirect);
  const { navigateToServerError, navigateToLoginMultiple } = useAppNavigate();
  const selectedAuthType = useSelector(selectType);
  const refreshToken = useSelector(selectRefreshToken);

  const logout = () => {
    if (!selectedAuthType) {
      navigateToLoginMultiple();
      return;
    }

    if (!refreshToken) {
      navigateToServerError();
      return;
    }

    switch (selectedAuthType.login_strategy) {
      case AuthStrategyEnum.default:
        dispatch(signOut());
        break;
      case AuthStrategyEnum.redirect:
        getLogoutRedirect({ typeId: selectedAuthType.id, refresh_token: refreshToken })
          .then(({ redirect_url: redirectUrl }) => {
            window.location.href = redirectUrl;
          })
          .catch(() => navigateToServerError());
        break;
      default:
        throw new Error('Cannot logout for selected login strategy.');
    }
  };

  if (selectedAuthType?.type === AuthTypeEnum.None) {
    return null;
  }

  if (isLoading) {
    return <div className={cx(styles.spinnerIcon, 'ms-auto')} />;
  }

  return (
    <Icon
      className="ms-auto"
      SvgIcon={APP_ICONS.signOut}
      title={t('auth.signOut') || ''}
      onClick={logout}
      color="text-user-info-header-icon"
    />
  );
};
