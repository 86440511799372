import { FC, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { EntityPanelModel, EntityPanelViewOfModel } from 'api';
import { Viewers } from 'view/Viewers';
import { resizePlotly } from 'utils';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

import { SearchBar, ViewersList } from './components';

type ElementListViewerProps = {
  viewerKey?: string;
  viewerType?: string;
  data: EntityPanelModel['ViewOf'];
  onTransition?: (data: TransitionRecordType) => void;
};

export const ElementListViewer: FC<ElementListViewerProps> = ({ viewerKey, viewerType, data, onTransition }) => {
  const [viewersList, setViewersList] = useState<EntityPanelViewOfModel[]>(data as EntityPanelViewOfModel[]);
  const [activeViewer, setActiveViewer] = useState<EntityPanelViewOfModel>((data as EntityPanelViewOfModel[])[0]);

  const handleActiveViewer = (viewer: EntityPanelViewOfModel) => {
    setActiveViewer(viewer);
  };

  // ToDo: Remove once backend is corrected
  const getActiveViewerName = () =>
    (activeViewer.Name && Object.hasOwn(activeViewer.Name, 'String') && activeViewer.Name.String) ||
    (activeViewer.Name && String(activeViewer.Name)) ||
    '';

  return (
    <PanelGroup direction="horizontal">
      <Panel
        defaultSize={25}
        minSize={25}
        className="w-100 h-100 position-relative d-flex flex-column"
        onResize={resizePlotly}
      >
        <SearchBar noFilteredViewers={data as EntityPanelViewOfModel[]} setViewersList={setViewersList} />
        <ViewersList
          viewersList={viewersList}
          activeViewerName={getActiveViewerName()}
          handleActiveViewer={handleActiveViewer}
        />
      </Panel>
      <PanelResizeHandle className="verticalPanelResizer" />
      <Panel className="h-100" minSize={50} onResize={resizePlotly}>
        <Viewers
          panelKey={viewerKey}
          panelType={viewerType}
          activeViewerType={activeViewer?._t}
          data={activeViewer}
          onTransition={onTransition}
        />
      </Panel>
    </PanelGroup>
  );
};
