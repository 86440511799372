import { handlerApi } from 'api';

import { SessionType } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  table: string;
  dataset: string;
  datasource: string;
  sessionChatKeys: Array<SessionType['ChatKey']>;
};

export const deleteChatSessionsRequest = ({ key, table, sessionChatKeys, dataset, datasource }: RequestParamsType) =>
  handlerApi.run<null>({
    key,
    table,
    dataset,
    method: 'DeleteChatSessions',
    dataSource: datasource,
    args: { CurrentChats: sessionChatKeys },
  });
