import { ColumnInstance, ColumnWidthProps, Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import {
  Dispatch,
  KeyboardEvent as NavigationKeyDownEvent,
  SetStateAction,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  WheelEvent,
} from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from '@tanstack/react-virtual';

import {
  ColumnNameType,
  DataType,
  GridModeEnum,
  SelectedRangeCellsType,
  SelectedRowsIdsType,
  SelectedRowsRangeType,
} from 'view/Grid/utils/types';
import { GridContext } from 'view/Grid/Context';
import { useContextMenu } from 'shared/Hooks/useContextMenu';
import { ContextMenuItem } from 'shared/ContextMenu';
import { APP_ICONS } from 'utils/icons';
import { KeyboardCode, isNaN } from 'utils';

import { TableRow } from './TableRow';
import { VerticalScroll } from './VerticalScroll';
import styles from './styles.module.scss';

type TableBodyPropsType = {
  gridId: string;
  isHiddenVerticalScroll: boolean;
  setHiddenVerticalScroll: (isHidden: boolean) => void;
  markedIndex: number;
  isDefaultMarkedIndex: boolean;
  columns: ColumnInstance<DataType>[];
  rowHeight?: number;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<DataType>) => TableBodyProps;
  prepareRow: (row: Row<DataType>) => void;
  rows: Row<DataType>[];
  preFilteredRows: Row<DataType>[];
  isInfoCellExist: boolean;
  isCountRowCellExist?: boolean;
  selectedRangeCells: SelectedRangeCellsType;
  selectedCellIds: SelectedRangeCellsType;
  selectedRowsRange: SelectedRowsRangeType;
  selectedRowsIds: SelectedRowsIdsType;
  onSelectedRowsRangeChanged: (newSelectedRowsRange: SelectedRowsRangeType | undefined) => void;
  gridMode: GridModeEnum;
  handleCopySelectedRangeCells: () => void;
  onRowDoubleClicked: (row: Row<DataType>) => void;
  selectedRowId: string | undefined;
  onSelectRowId: (rowId: string) => void;
  navigationKeyDownHandler: (
    event: NavigationKeyDownEvent<HTMLDivElement>,
    options?: { selectedCells: SelectedRangeCellsType; updateFn: (cells: Record<string, boolean>) => void }
  ) => void;
  getColumnWidthProps?: () => ColumnWidthProps;
  rewriteActiveRowStartCell: (cellId: string) => void;
  selectNewCellsArr: (start: string, end: string) => void;
  selectProvidedRowsCells: (rows: Row<DataType>[]) => void;
  selectedRowsPerPage?: SelectedRowsRangeType;
  setSelectedRowsPerPage?: Dispatch<SetStateAction<SelectedRowsRangeType>>;
  updateSelectedRowsPerPageState: (newState: SelectedRowsRangeType) => void;
  isSelectAllRecordsBtnClicked?: boolean;
  setIsSelectAllRecordsBtnClicked?: Dispatch<SetStateAction<boolean>>;
  setSelectedCellIds: (selectedCellIds: Record<string, boolean>) => void;
  setCurrentSelectedCellIds: (selectedCellIds: Record<string, boolean>) => void;
  changeSelectedCell: (cells: Record<string, boolean>) => void;
};

export const TableBody = ({
  gridId,
  isHiddenVerticalScroll,
  setHiddenVerticalScroll,
  isDefaultMarkedIndex,
  markedIndex,
  columns,
  getTableBodyProps,
  prepareRow,
  rows,
  preFilteredRows,
  selectedCellIds,
  selectedRangeCells,
  selectedRowsRange,
  selectedRowsIds,
  onSelectedRowsRangeChanged,
  gridMode,
  rowHeight = gridMode === GridModeEnum.EDITOR ? 25 : 28,
  handleCopySelectedRangeCells,
  onRowDoubleClicked,
  selectedRowId,
  onSelectRowId,
  navigationKeyDownHandler,
  getColumnWidthProps,
  rewriteActiveRowStartCell,
  selectNewCellsArr,
  selectProvidedRowsCells,
  selectedRowsPerPage,
  setSelectedRowsPerPage,
  updateSelectedRowsPerPageState,
  isSelectAllRecordsBtnClicked,
  setIsSelectAllRecordsBtnClicked,
  setSelectedCellIds,
  setCurrentSelectedCellIds,
  changeSelectedCell,
}: TableBodyPropsType) => {
  const bodyWrapperRef = useRef<HTMLDivElement | null>(null);
  const { bodyRef } = useContext(GridContext);
  const { t } = useTranslation();
  const { handleOpenContextMenu } = useContextMenu();
  const selectionRowsCount = useRef<number>(0);
  const selectedRowsIdsKeys = Object.keys(selectedRowsIds);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => bodyRef.current,
    estimateSize: useCallback(() => rowHeight, [rowHeight]),
  });

  const totalSize = virtualizer.getTotalSize();

  const [startRowRange, setStartRowRange] = useState(0);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState<number[]>([]);

  const isShowRows = useMemo(() => columns.filter((column) => !column.suppressHideColumn).length > 0, [columns]);

  useLayoutEffect(() => {
    if (isDefaultMarkedIndex) return;
    if (gridMode === GridModeEnum.MAIN && bodyWrapperRef.current) {
      bodyWrapperRef.current.focus();

      if (selectedRowsPerPage) {
        const newSelectedCellsRange: Record<string, boolean> = {};

        Object.keys(selectedRowsPerPage).forEach((selectedRowperPageId) => {
          const selectedRow = rows.find((row) => row.id === selectedRowperPageId);

          if (selectedRow) {
            const cellsRange = selectedRow.allCells
              .map((cell) => cell.id)
              .filter((cellId) => {
                if (!cellId.includes('filter')) {
                  newSelectedCellsRange[cellId] = true;

                  return true;
                }

                return false;
              });

            selectedRowsIds[selectedRow.id] = {
              _key: selectedRow.original._key,
              _t: selectedRow.original._t,
              allCells: cellsRange,
            };
          }
        });

        setSelectedCellIds(newSelectedCellsRange);
      }

      if (selectedRowsIdsKeys.length && selectedRowsPerPage && !Object.keys(selectedRowsPerPage).length) {
        selectedRowsIdsKeys.forEach((id) => {
          delete selectedRowsIds[id];
        });
      }
    }
  }, []);

  const toggleRowsInRange = (rowsIndex: number[]) => {
    let newSelectedRowsRange = structuredClone(selectedRowsRange);
    const newSelectedRowsPerPage = structuredClone(selectedRowsPerPage || {});
    const newSelectedCellIds = { ...selectedCellIds };

    rowsIndex.forEach((rowIndex) => {
      const row = preFilteredRows[rowIndex];
      if (newSelectedRowsRange[row.id]) {
        delete newSelectedRowsRange[row.id];
        delete newSelectedRowsPerPage[row.id];

        if (selectedRowsIds[row.id]) {
          selectedRowsIdsKeys.forEach((id) => {
            //  Remove selected cells from marked rows
            const cells = selectedRowsIds[id].allCells;

            for (let i = 0; i < cells.length; i++) {
              delete newSelectedCellIds[cells[i]];
            }

            delete selectedRowsIds[id];
            delete newSelectedRowsRange[id];
            delete newSelectedRowsPerPage[id];
          });
        }
      } else {
        newSelectedRowsRange[row.id] = { _key: row.original._key, _t: row.original._t };
        newSelectedRowsPerPage[row.id] = { _key: row.original._key, _t: row.original._t };

        if (selectedRowsIds[row.id]) {
          newSelectedRowsRange = { ...newSelectedRowsRange, ...selectedRowsIds };
          Object.assign(newSelectedRowsPerPage, selectedRowsIds);
        }
      }
    });

    if (isSelectAllRecordsBtnClicked) {
      newSelectedRowsRange = structuredClone(newSelectedRowsPerPage);
      setIsSelectAllRecordsBtnClicked?.(false);
    }

    setSelectedCellIds(newSelectedCellIds);
    setCurrentSelectedCellIds(newSelectedCellIds);
    onSelectedRowsRangeChanged(newSelectedRowsRange);
    updateSelectedRowsPerPageState(newSelectedRowsPerPage);
    setSelectedRowsPerPage && setSelectedRowsPerPage(newSelectedRowsPerPage);
  };

  const getIndexesRange = (start: number, current: number): number[] => {
    const [min, max] = [start, current].sort((a, b) => a - b);

    return Array.from({ length: max - min + 1 }, (_, i) => i + min);
  };

  const addRowsToRangeByIndex = (rowsIndexesInRange: number[]) => {
    const rowsInRange: SelectedRowsRangeType = {};

    rowsIndexesInRange.forEach((rowInRange) => {
      rowsInRange[rows[rowInRange].id] = {
        _key: rows[rowInRange].original._key,
        _t: rows[rowInRange].original._t,
      };
    });

    onSelectedRowsRangeChanged(rowsInRange);
  };

  const selectRow = (row: Row<DataType>) => {
    setSelectedRowsIndex([row.index]);
    if (selectedRowId !== row.id) onSelectRowId(row.id);
  };

  const handelRowClick = (event: React.MouseEvent<Element, MouseEvent>, row: Row<DataType>, rowIndex: number) => {
    const filterColumnIndex = row.allCells.findIndex((el) => el.column.id === ColumnNameType.FILTER);
    const isTargetCheckbox = event.target instanceof HTMLInputElement && event.target.type === 'checkbox';
    const firstCellInRowSelection = row.allCells[filterColumnIndex !== -1 ? filterColumnIndex + 1 : 0].id; // Cell around checkbox should not be higlighted.

    if (event.shiftKey) {
      const rowsIndexInRange = getIndexesRange(startRowRange, rowIndex);
      addRowsToRangeByIndex(rowsIndexInRange);

      selectProvidedRowsCells(rowsIndexInRange.map((index) => rows[index]));
      setSelectedRowsIndex(rowsIndexInRange);

      return;
    }

    if (isTargetCheckbox || event.ctrlKey) {
      toggleRowsInRange([row.index]);
      setStartRowRange(rowIndex);
      rewriteActiveRowStartCell(firstCellInRowSelection);
      return;
    }

    setStartRowRange(rowIndex);

    selectRow(row);
    rewriteActiveRowStartCell(firstCellInRowSelection);

    if (selectionRowsCount.current === 0) {
      selectionRowsCount.current = 1;
    }
  };

  const updateSelectedCellsByKeyDown = (cells: Record<string, boolean>) => {
    changeSelectedCell(cells);

    const newSelectedIndex: number[] = [];

    Object.keys(cells).forEach((key) => {
      const rowIndex = parseInt(key.split('_')[1], 10);
      if (!isNaN(rowIndex) && !newSelectedIndex.includes(rowIndex)) newSelectedIndex.push(rowIndex);
    });

    setStartRowRange(newSelectedIndex[0]);
    setSelectedRowsIndex(newSelectedIndex);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Space' && selectedRowsIndex.length) {
      toggleRowsInRange(selectedRowsIndex);
    }

    if (e.ctrlKey && e.code === KeyboardCode.KeyA) {
      e.preventDefault();
      const lastRowCells = rows[rows.length - 1]?.allCells || [];
      selectNewCellsArr(rows[0].allCells[1].id, lastRowCells[lastRowCells.length - 1].id);
    }

    if (e.ctrlKey && e.code === KeyboardCode.KeyC) {
      handleCopySelectedRangeCells();
    }

    navigationKeyDownHandler(e, { selectedCells: selectedRangeCells, updateFn: updateSelectedCellsByKeyDown });
  };

  const contextMenuItems: ContextMenuItem[] = useMemo(
    () => [
      {
        title: t('shared.copy'),
        icon: APP_ICONS.copy,
        onClick: handleCopySelectedRangeCells,
      },
    ],
    [handleCopySelectedRangeCells, t]
  );

  const handleContextMenu = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      handleOpenContextMenu({ items: contextMenuItems, e });
    },
    [contextMenuItems, handleOpenContextMenu]
  );

  const onWheel = (event: WheelEvent<HTMLDivElement>) => {
    const offset = event.deltaY;
    virtualizer.scrollToOffset(virtualizer.scrollOffset + offset);
  };
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  return (
    <div
      tabIndex={-1}
      onContextMenu={handleContextMenu}
      onKeyDown={handleKeyDown}
      className={cx('fs-13 flex-grow-1 d-flex overflow-hidden', styles.body)}
      {...getTableBodyProps()}
      ref={bodyWrapperRef}
    >
      <div ref={bodyRef} className="flex-grow-1 overflow-hidden" onWheel={onWheel}>
        <div className="position-relative" style={{ height: totalSize }}>
          {isShowRows &&
            virtualizer.getVirtualItems().map((virtualItem) => {
              const row = rows[virtualItem.index];
              prepareRow(row);

              return (
                <TableRow
                  style={{
                    height: virtualItem.size,
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                  selectedRowId={selectedRowId}
                  key={virtualItem.key}
                  gridId={gridId}
                  markedIndex={markedIndex}
                  rowIndex={virtualItem.index}
                  row={row}
                  selectedRangeCells={selectedRangeCells}
                  gridMode={gridMode}
                  onClick={(event) => handelRowClick(event, row, virtualItem.index)}
                  onDoubleClick={() => onRowDoubleClicked(row)}
                  getColumnWidthProps={getColumnWidthProps}
                  canvasContext={context}
                  selectedRowsRange={selectedRowsRange}
                />
              );
            })}
        </div>
      </div>
      <VerticalScroll
        className="flex-shrink-0"
        isHidden={isHiddenVerticalScroll}
        setHidden={setHiddenVerticalScroll}
        scrollOffset={virtualizer.scrollOffset}
        totalHeight={totalSize}
        scrollToOffset={virtualizer.scrollToOffset}
      />
    </div>
  );
};
