import { FC } from 'react';
import { Dayjs } from 'dayjs';
import cx from 'classnames';

import { UIInputDate } from 'ui';
import { DateFormatEnum, DateFormatUtility } from 'utils';

import styles from './QueryBuilderDateInput.module.scss';

type QueryBuilderDateProps = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const QueryBuilderDateInput: FC<QueryBuilderDateProps> = ({ value, onChange }) => {
  const date = value ? DateFormatUtility.create(value).toInstance() : null;

  const onChangeHandler = (changedDate: Dayjs | null) => {
    onChange(changedDate?.isValid ? changedDate.format(DateFormatEnum.DATE) : changedDate?.toString() ?? null);
  };

  return <UIInputDate className={cx(styles.dropdown)} value={date} onChange={onChangeHandler} />;
};
