import { FC, memo, SyntheticEvent, useEffect, useRef } from 'react';
import cx from 'classnames';
import { useMergeRefs } from '@floating-ui/react';
import useResizeObserver from 'use-resize-observer';

import styles from './styles.module.scss';

type VerticalScrollProps = {
  className?: string;
  isHidden: boolean;
  setHidden: (isHidden: boolean) => void;
  totalHeight: number;
  scrollOffset: number;
  scrollToOffset: (offset: number) => void;
};

export const VerticalScroll: FC<VerticalScrollProps> = memo(
  ({ isHidden, setHidden, className, totalHeight, scrollOffset, scrollToOffset }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { ref: resizeRef, height = 0 } = useResizeObserver();
    const mergedRefs = useMergeRefs([resizeRef, ref]);

    useEffect(() => {
      if (!ref.current) return;

      const currentScrollTop = ref.current.scrollTop;
      if (currentScrollTop === scrollOffset) return;
      ref.current.scrollTo({ top: scrollOffset });
    }, [scrollOffset]);

    useEffect(() => {
      setHidden(totalHeight < height);
    }, [totalHeight, height]);

    const onScroll = (event: SyntheticEvent<HTMLDivElement>) => {
      const offsetTop = event.currentTarget.scrollTop;
      if (offsetTop === scrollOffset) return;
      scrollToOffset(offsetTop);
    };

    return (
      <div
        ref={mergedRefs}
        className={cx('h-100 overflow-y-auto overflow-x-hidden', className, styles.container, {
          [styles.hidden]: isHidden,
        })}
        onScroll={onScroll}
      >
        <div style={{ height: totalHeight }} className={styles.container} />
      </div>
    );
  }
);

VerticalScroll.displayName = 'VerticalScroll';
