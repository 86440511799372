import cx from 'classnames';

import { FC, useEffect, useRef, useState } from 'react';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { TabItem } from 'shared/Tabs/components/TabItem';

import './UIExcelSheetBar.scss';

const ADD_SHEET_WIDTH = 45;
const NAVIGATION_WIDTH = 50;
const ONE_TAP_SCROLL_LENGTH = 100;

enum ScrollDirection {
  Left = 'left',
  Right = 'right',
}

export type UIExcelSheetsBarProps = {
  sheetNames: string[];
  currentSheet: number;
  onCurrentSheetChange: (index: number) => void;
};

export const UIExcelSheetBar: FC<UIExcelSheetsBarProps> = ({ sheetNames, currentSheet, onCurrentSheetChange }) => {
  const sheetsBarRef = useRef<HTMLDivElement>(null);
  const sheetNamesRef = useRef<HTMLDivElement>(null);

  const [isEnabledMoveLeft, setIsEnabledMoveLeft] = useState<boolean>(true);
  const [isEnabledMoveRight, setIsEnabledMoveRight] = useState<boolean>(true);
  const [isSheetNavigationOverflowed, setIsSheetNavigationOverflowed] = useState<boolean>(false);

  useEffect(() => {
    const observer = new ResizeObserver((nodes) => {
      nodes.forEach((node) => {
        const sheetsWidth = sheetNamesRef.current?.scrollWidth ?? 0;
        setIsSheetNavigationOverflowed(node.target.clientWidth < sheetsWidth + ADD_SHEET_WIDTH + NAVIGATION_WIDTH);
        handleUpdateNavigationScrollEnabling();
      });
    });

    if (sheetsBarRef.current) {
      observer.observe(sheetsBarRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleSelectSheet = (index: number) => {
    onCurrentSheetChange(index);

    if (sheetNamesRef.current) {
      Array.from(sheetNamesRef.current.children)[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getNavigationScrollEnabling = (element: HTMLDivElement) => {
    const currentClientWidth = element.clientWidth;
    const currentLeftScroll = element.scrollLeft;
    const minLeftScroll = 0;
    const maxLeftScroll = element.scrollWidth;

    return {
      isLeftEnabled: currentLeftScroll > minLeftScroll,
      isRightEnabled: currentLeftScroll + currentClientWidth < maxLeftScroll,
      minLeftScroll,
      maxLeftScroll,
      currentLeftScroll,
      currentClientWidth,
    };
  };

  const handleUpdateNavigationScrollEnabling = () => {
    if (sheetNamesRef.current) {
      const { isLeftEnabled, isRightEnabled } = getNavigationScrollEnabling(sheetNamesRef.current);
      setIsEnabledMoveLeft(isLeftEnabled);
      setIsEnabledMoveRight(isRightEnabled);
    }
  };

  const handleNavigationSheetScroll = (direction: ScrollDirection) => {
    if (sheetNamesRef.current) {
      const { isLeftEnabled, isRightEnabled, minLeftScroll, maxLeftScroll, currentLeftScroll, currentClientWidth } =
        getNavigationScrollEnabling(sheetNamesRef.current);

      if (direction === ScrollDirection.Left) {
        sheetNamesRef.current.scrollLeft =
          currentLeftScroll - ONE_TAP_SCROLL_LENGTH > minLeftScroll
            ? currentLeftScroll - ONE_TAP_SCROLL_LENGTH
            : minLeftScroll;
        setIsEnabledMoveLeft(isLeftEnabled);
        return;
      }

      if (direction === ScrollDirection.Right) {
        sheetNamesRef.current.scrollLeft =
          currentLeftScroll + currentClientWidth + ONE_TAP_SCROLL_LENGTH < maxLeftScroll
            ? currentLeftScroll + 100
            : maxLeftScroll;
        setIsEnabledMoveRight(isRightEnabled);
      }
    }
  };

  return (
    <nav className="ui-excel-sheet-bar bg-gray-100 bg-opacity-50" ref={sheetsBarRef}>
      <div ref={sheetNamesRef} className="ui-excel-sheet-bar__sheets" onScroll={handleUpdateNavigationScrollEnabling}>
        {sheetNames.map((label, index) => (
          <TabItem
            key={index}
            isActive={index === currentSheet}
            label={label}
            role="ui-excel-sheet-button"
            onClick={() => handleSelectSheet(index)}
            isCloseable={false}
          />
        ))}
      </div>

      <div className={cx('ui-excel-sheet-bar__nav-buttons', { overflowed: isSheetNavigationOverflowed })}>
        <Icon
          SvgIcon={APP_ICONS.prevArrow}
          onClick={() => handleNavigationSheetScroll(ScrollDirection.Left)}
          disable={!isEnabledMoveLeft}
        />
        <Icon
          SvgIcon={APP_ICONS.nextArrow}
          onClick={() => handleNavigationSheetScroll(ScrollDirection.Right)}
          disable={!isEnabledMoveRight}
        />
      </div>
    </nav>
  );
};
