import { FC, memo } from 'react';

import { QueryBuilderIndexDropdown } from '../../../QueryBuilderIndexDropdown/QueryBuilderIndexDropdown.component';

export const QueryBuilderToolbar: FC = memo(() => (
  <div className="d-flex align-items-center p-2_5">
    <QueryBuilderIndexDropdown />
  </div>
));

QueryBuilderToolbar.displayName = 'QueryBuilderToolbar';
