import BigNumber from 'bignumber.js';

import { SimpleValueType, ValueType, ViewOfType } from 'store/types';
import { simpleValueTypes } from 'utils/types/valueTypes';
import { FlatViewModel, SimpleViewModel } from 'api';

import { isNonDigitChars } from './isNonDigitChars';

export const isDefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const isNullable = <T>(value: T | null | undefined): value is null | undefined =>
  value === null || value === undefined;

export const isNaN = <T>(value: T | null | undefined) => isNullable(value) || Number.isNaN(value);

export const isArray = (value: unknown): value is unknown[] => Array.isArray(value);

export const isObject = <T extends object>(value: unknown): value is T =>
  typeof value === 'object' && !isArray(value) && value !== null;

export const isEmpty = <T>(value: T) => isObject(value) && Object.keys(value).length === 0;

export const isEmptyString = <T>(value: T) => typeof value === 'string' && value.length === 0;

export const isNumber = (value: unknown): value is number => isDefined(value) && typeof value === 'number';

export const isString = (value: unknown): value is string => isDefined(value) && typeof value === 'string';

export const isBool = (value: unknown): value is boolean => typeof value === 'boolean';

export const isSimpleViewType = (value: unknown): value is SimpleViewModel =>
  !isArray(value) && (typeof value !== 'object' || BigNumber.isBigNumber(value) || isNullable(value));

export const isArraySimpleViewType = (value: unknown): value is SimpleViewModel[] =>
  isArray(value) && value.length > 0 && value.every(isSimpleViewType);

export const isFlatViewType = (value: unknown): value is FlatViewModel => isObject(value);

export const isArrayFlatViewType = (value: unknown): value is FlatViewModel[] =>
  isArray(value) && value.length > 0 && value.every(isFlatViewType);

export const isViewOfType = (value: unknown): value is ViewOfType =>
  typeof value === 'object' && !isArray(value) && value !== null && Object.values(value).length > 0;

export const isSimpleValueType = (valueType: ValueType['Type'] | undefined): valueType is SimpleValueType =>
  isDefined(valueType) ? simpleValueTypes.some((simpleValueType) => simpleValueType === valueType) : false;

export const isArrayViewOfType = (value: unknown): value is ViewOfType[] =>
  typeof value === 'object' && isArray(value) && value.length > 0;

export const isInt = <T>(value: T) => isNumber(value) && Number.isInteger(value);

export const isStringBool = (value: unknown): value is string => String(value) === 'true' || String(value) === 'false';

export const isArrayOfStrings = (value: unknown): value is string[] =>
  isArray(value) && value.length > 0 && value.every(isString);

export const isArrayOfObjects = <T extends object>(value: unknown): value is T[] =>
  isArray(value) && value.length > 0 && value.every(isObject);

export const isOnlyDigits = (value: unknown): value is number => isString(value) && !isNonDigitChars(value);
