import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { UiDropdownItem } from 'ui';

import { Handler } from '../Handler';
import { ExecutedHandlerInfo, HandlerItemType } from '../../types';

import styles from './styles.module.scss';

type RunHandlerItemProps = {
  handler: HandlerItemType;
  handlerExecutionInfo?: ExecutedHandlerInfo;
  onHandlerClick: () => void;
  onPinHandler: () => void;
  onCancelHandler: (handlerId: string, isStatic?: boolean) => void;
  renderHandlerName?: (handlerName: string) => ReactNode;
  isReadOnly?: boolean;
};

export const RunHandlerItem: FC<RunHandlerItemProps> = ({
  handler,
  handlerExecutionInfo,
  onHandlerClick,
  onPinHandler,
  onCancelHandler,
  renderHandlerName,
  isReadOnly = false,
}) => {
  const handlePinIconClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onPinHandler();
  };

  const isExecutionDisabled = handler.isDisable;

  return (
    <UiDropdownItem
      key={handler.id}
      tag={'div'}
      toggle={!isReadOnly}
      className={cx('d-flex align-items-center justify-content-between gap-2', styles.dropdownMenuItem)}
      onClick={() => (isExecutionDisabled ? undefined : onHandlerClick())}
    >
      <button className={cx('text-text1', styles.handlerBtn)} disabled={isReadOnly}>
        <Handler
          handler={handler}
          handlerExecutionInfo={handlerExecutionInfo}
          renderHandlerName={renderHandlerName}
          onCancelHandler={onCancelHandler}
          isReadOnly={isReadOnly}
        />
      </button>

      <Icon
        SvgIcon={APP_ICONS.pin}
        className={cx('justify-content-center flex-shrink-0', styles.pinIcon, {
          [styles.pinnedIcon]: handler.isPinned,
        })}
        onClick={handlePinIconClick}
      />
    </UiDropdownItem>
  );
};
