import { FC } from 'react';

import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { ValueEditorViewString } from 'view/Editor/components/EditorView/ValueEditorView/ValueEditorViewString.component';

type ValueEditorViewProps = {
  tab: EditorTabType;
  value: string;
};

export const ValueEditorView: FC<ValueEditorViewProps> = ({ tab, value }) => {
  const { action } = useEditorState();

  const handleChangeValue = (updateValue: string) => {
    if (tab.path) {
      action.updateEditorData({ path: tab.path, updateValue });
    }
  };

  if (tab?.tabEditorValueType === 'String') {
    return <ValueEditorViewString value={value} isReadonly={tab?.isReadOnly} onChange={handleChangeValue} />;
  }

  return null;
};
