import { SchemaTypeElementsModel } from 'api';

export const generateSchemaDictionaryByElements = (elements: SchemaTypeElementsModel[]) =>
  elements.reduce(
    (dic, element) => {
      dic[element.Name] = element;
      return dic;
    },
    {} as Record<string, SchemaTypeElementsModel>
  );
