import { FC, Fragment, useCallback, useState } from 'react';
import { NodeChange, OnNodesChange, OnSelectionChangeParams, useNodesState, Node } from 'reactflow';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { DAGType, DagViewer as Viewer, isNodeRemoveChange, mapEdgesToDomain, mapNodesToDomain } from 'view/Dag';

import { DagViewerNodeDetails } from './components';

type DagViewerProps = DAGType;

export const DagViewer: FC<DagViewerProps> = ({ Nodes, Edges }) => {
  const [nodes, , onNodesChange] = useNodesState(mapNodesToDomain(Nodes));
  const edges = mapEdgesToDomain(Edges);
  const [selectedNode, setSelectedNode] = useState<Node>();

  const nodesChangeHandler: OnNodesChange = useCallback((nodeChanges: NodeChange[]) => {
    const allowedNodeChanges = nodeChanges.filter((changedNode) => !isNodeRemoveChange(changedNode));
    onNodesChange(allowedNodeChanges);
  }, []);

  const onSelectionChange = useCallback((params: OnSelectionChangeParams) => setSelectedNode(params.nodes[0]), []);

  return (
    <PanelGroup direction="horizontal">
      <Panel id="dag-viewer" order={1} defaultSize={60}>
        <Viewer nodes={nodes} edges={edges} onNodesChange={nodesChangeHandler} onSelectionChange={onSelectionChange} />
      </Panel>
      {selectedNode && (
        <Fragment>
          <PanelResizeHandle className="verticalPanelResizer" />
          <Panel id="dag-viewer-node-details" order={1} minSize={0} maxSize={40}>
            <DagViewerNodeDetails node={selectedNode} />
          </Panel>
        </Fragment>
      )}
    </PanelGroup>
  );
};
