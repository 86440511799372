import { Header, flexRender } from '@tanstack/react-table';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import { UIGridSortingIndicator } from '../UIGridSortingIndicator/UIGridSortingIndicator.component';
import { UIGridMenu } from '../UIGridMenu/UIGridMenu.component';
import './UIGridHeaderCellWrapper.styles.scss';

type UIGridHeaderCellWrapperProps<TData, TValue> = {
  header: Header<TData, TValue>;
};

export const UIGridHeaderCellWrapper = <TData, TValue>({ header }: UIGridHeaderCellWrapperProps<TData, TValue>) => {
  const { openedHeaderMenuId, setOpenedHeaderMenuId } = useGrid<TData>();
  const isOpenMenu = header.id === openedHeaderMenuId;

  const toggleMenu = () => {
    setOpenedHeaderMenuId((prevOpenedHeaderMenuId) => {
      if (prevOpenedHeaderMenuId) {
        return prevOpenedHeaderMenuId === header.id ? undefined : header.id;
      }

      return header.id;
    });
  };

  return (
    <div
      className={cx('ui-grid-header-cell-wrapper', {
        'ui-grid-header-cell-wrapper--sortable': header.column.getCanSort(),
        'ui-grid-header-cell-wrapper--menu-opened': isOpenMenu,
      })}
      onClick={header.column.getToggleSortingHandler()}
    >
      <div className="ui-grid-header-cell-wrapper__content">
        {flexRender(header.column.columnDef.header, header.getContext())}
      </div>
      <UIGridSortingIndicator sorting={header.column.getIsSorted()} />
      <UIGridMenu
        className="ui-grid-header-cell-wrapper__menu"
        header={header}
        isOpen={isOpenMenu}
        toggle={toggleMenu}
      />
    </div>
  );
};
