import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { FileType, MessageGroupStatus, MessageGroupType, MessageType } from '../../utils/types';
import { loadHistoryRequest, RequestParamsType as LoadHistoryRequestParamsType } from './utils/loadHistoryRequest';

export function useMessages() {
  const [messageGroups, setMessageGroups] = useState<MessageGroupType[]>([]);

  const load = useCallback(
    (params: LoadHistoryRequestParamsType) =>
      loadHistoryRequest(params).then((fetchedMessageGroups) => setMessageGroups(fetchedMessageGroups)),
    []
  );

  const createGroup = useCallback(() => {
    const id = uuid();
    setMessageGroups((prevMessageGroups) => [...prevMessageGroups, { id, status: MessageGroupStatus.INITIAL }]);

    return id;
  }, []);

  const createMessage = useCallback((message: string, files: FileType[] = []): MessageType => {
    const id = uuid();
    return {
      id,
      text: message,
      files,
    };
  }, []);

  const updateGroupById = useCallback((groupId: string, updatedData: Partial<MessageGroupType>) => {
    setMessageGroups((prevMessageGroups) =>
      prevMessageGroups.map((messageGroup) => {
        if (messageGroup.id === groupId) {
          return {
            ...messageGroup,
            ...updatedData,
          };
        }

        return messageGroup;
      })
    );
  }, []);

  const addQuestion = useCallback(
    (groupId: string, question: string, files: FileType[] = []) => {
      updateGroupById(groupId, {
        status: MessageGroupStatus.LOADING,
        question: createMessage(question, files),
      });
    },
    [updateGroupById, createMessage]
  );

  const addAnswer = useCallback(
    (groupId: string, answer: string) => {
      updateGroupById(groupId, {
        status: MessageGroupStatus.SUCCESS,
        answer: createMessage(answer),
      });
    },
    [createMessage, updateGroupById]
  );

  const updateStatus = useCallback(
    (groupId: string, status: MessageGroupStatus) => {
      updateGroupById(groupId, { status });
    },
    [updateGroupById]
  );

  const onClear = useCallback(() => {
    setMessageGroups([]);
  }, []);

  return {
    messageGroups,
    createGroup,
    addQuestion,
    addAnswer,
    updateStatus,
    onClear,
    load,
  };
}
