import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'react-table';

import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { useRequest } from 'hook/request.hook';
import { entityApi } from 'api';
import { getViewerTypeHelper } from 'utils';
import { DataType } from 'view/Grid/utils/types';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';
import { openTab } from 'store/shared-reducers/AppStateSlice';

import { selectPrioritizedPanel } from './utils/helpers';

export function useNewTab() {
  const { activeTab } = useTabParams();

  const dispatch = useDispatch();
  const dataset = useSelector(selectActiveDataset);
  const listPanelsRequest = useRequest(entityApi.getListPanels);
  const getPanelRequest = useRequest(entityApi.getPanel);

  const handler = useCallback(
    (row: Row<DataType>) => {
      if (!activeTab || !dataset) return;
      const rowId = row.id;

      listPanelsRequest
        .fetch({ type: activeTab.id, key: rowId })
        .then(selectPrioritizedPanel)
        .then((prioritizedPanel) => {
          if (!prioritizedPanel) return Promise.resolve();

          return getPanelRequest
            .fetch({
              panelId: prioritizedPanel.Name,
              type: activeTab.id,
              key: rowId,
              dataset,
            })
            .then((panelData) => {
              const key = getViewerTypeHelper(panelData) as string;
              const label = `${key}: ${rowId}`;

              dispatch(
                openTab({
                  id: rowId,
                  label,
                  key,
                  dynamicType: {
                    type: prioritizedPanel.Type,
                    id: rowId,
                  },
                })
              );
            });
        });
    },
    [activeTab?.id, dataset]
  );

  return { handler };
}
