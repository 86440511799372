import { FC } from 'react';
import cx from 'classnames';

import { QueryOperatorEnum } from 'api';
import { OptionType, UiSelect } from 'ui';
import { generateOptionsBySchemaType } from 'view/QueryBuilder/helpers';

import { QueryBuilderConditionCellProps } from '../../utils/types';
import { dropdownStyles } from './utils/constant';

import styles from './QueryBuilderConditionCell.module.scss';

export const QueryBuilderConditionCell: FC<QueryBuilderConditionCellProps> = ({
  type,
  condition,
  onChangeCondition: onChange,
}) => {
  const options = generateOptionsBySchemaType(type);
  const selectedOption = options.find((option) => option.value === condition);

  return (
    <UiSelect
      className={cx('h-100 w-100 bg-gray-500', styles.dropdown)}
      menuPortalTarget={document.body}
      isSearchable={false}
      isClearable={false}
      value={selectedOption}
      options={options}
      styles={dropdownStyles}
      onChange={(selected: OptionType<QueryOperatorEnum>) => onChange(selected.value)}
    />
  );
};
