import { t } from 'i18next';

import { LoadingStatusesEnum, SchemaTypeModel } from 'api';

export const getNoGridDataTitle = (status: LoadingStatusesEnum, error: string) => {
  switch (status) {
    case LoadingStatusesEnum.ERROR:
      return error;
    case LoadingStatusesEnum.SUCCESS:
      return t('noData.inTheReport');
    default:
      return '';
  }
};

export const isInteractiveDashboard = (metadata: SchemaTypeModel): boolean => {
  const isExistsSendPromptHandler = metadata.Implement?.Handlers?.find((handler) => handler.Name === 'SendPrompt');

  return Boolean(isExistsSendPromptHandler);
};
