import { FC } from 'react';

import { StorageEntityModel } from 'api';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { Editor } from 'view/Editor/Editor';
import { EditorSubmitType } from 'view/Editor/type';
import { Loader } from 'shared/Loader';
import { UiEmpty } from 'ui';
import { useRequest } from 'hook/request.hook';
import { toast } from 'utils';

export type EditorKeySelectProps = {
  recordType: string;
  editorData?: StorageEntityModel;
  onClose: () => void;
  onSubmit?: (key: string) => void;
};

export const EditorNewRecord: FC<EditorKeySelectProps> = ({ recordType, editorData, onSubmit, onClose }) => {
  const { request, i18n } = useEditorState();
  const getSchemaRequest = useRequest(request.getSchema, { autorun: true, args: { name: recordType } });
  const saveRecord = useRequest(request.saveRecord);

  if (getSchemaRequest.isLoading) return <Loader />;

  if (!getSchemaRequest.state) return <UiEmpty />;

  const handleSubmit = ({ data }: EditorSubmitType) => {
    saveRecord
      .fetch({ data: { _t: recordType, ...data } })
      .then((result) => {
        onSubmit?.(result.key);
      })
      .catch(toast.error);
  };

  return (
    <Editor
      data={editorData}
      schema={getSchemaRequest.state.typeList}
      rootSchemaName={getSchemaRequest.state.rootType}
      onCancel={onClose}
      onSubmit={handleSubmit}
      requestConfig={request}
      i18n={i18n}
    />
  );
};
