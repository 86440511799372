export enum RouterEnum {
  ROOT = '/',
  ENVIRONMENT = '/:environment',
  ACTIVE_TAB = '/:environment/:activeTab',
  LOGIN = '/login',
  LOGIN_MULTIPLE = '/login/multiple',
  LOGIN_CALLBACK = '/login/callback',
  LOGOUT_CALLBACK = '/logout/callback',
  SERVER_ERROR = '/server-error',
  NOT_FOUND = '*',
}
