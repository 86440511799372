import { createContext, FC, PropsWithChildren, useState } from 'react';

import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { UiModal } from 'ui';
import i18n from 'i18n';
import { useLocalStorage } from 'shared/Hooks/useLocalStorage';

import {
  AppContextAction,
  AppContextOpenTabParams,
  AppContextOpenTabParamsCallback,
  AppContextOpenTabParamsCase,
  AppContextOpenTabParamsOptions,
  AppContextOpenTabParamsState,
  AppContextState,
  CacheTabItemType,
  SetCacheQueryDataActionProps,
} from './types';

export const AppContextWrapper = createContext<AppContext | null>(null);

export type AppContext = {
  state: AppContextState;
  action: AppContextAction;
  openTabParams: AppContextOpenTabParams;
};

export const initState: AppContextState = {
  isEditorDataChanged: false,
  fullScreenLayerId: null,
  activeEditorTab: EditorTabsEnum.EDITOR,
  mainGridStackContentLength: 0,
  cacheTabData: null,
  showLineHider: true,
  isUserMode: false,
};

export const AppContext: FC<PropsWithChildren> = ({ children }) => {
  const [fullScreenLayerId, setFullScreenLayerId] = useState(initState.fullScreenLayerId);
  const [isEditorDataChanged, setIsEditorDataChanged] = useState(initState.isEditorDataChanged);
  const [activeEditorTab, setActiveEditorTab] = useState(initState.activeEditorTab);
  const [mainGridStackContentLength, setMainGridStackContentLength] = useState<number>(0);
  const [cacheTabData, setCacheTabData] = useState(initState.cacheTabData);
  const [isUserMode, setIsUserMode] = useLocalStorage('userMode', initState.isUserMode);

  const setCacheQueryDataAction = ({ query, entityData, entitySchema, type }: SetCacheQueryDataActionProps) => {
    if (!query || !entityData || !entitySchema || !type) return;

    const currentData: CacheTabItemType = { query, entityData, entitySchema };
    setCacheTabData((cache) => {
      if (cache?.[type]) {
        cache[type] = currentData;
        return { ...cache };
      }

      return { ...cache, [type]: currentData };
    });
  };

  const clearCacheQueryByTypeAction = (type: string) => {
    if (type && cacheTabData?.[type]) {
      delete cacheTabData[type];
      setCacheTabData(cacheTabData);
    }
  };
  // Check if there is some other way to implement line hide functionality
  const [showLineHider, setShowLineHider] = useState(true);

  const [openTabParams, setOpenTabParams] = useState<AppContextOpenTabParamsState>({} as AppContextOpenTabParamsState);

  const handleSetOpenTabParams = <T,>(key: AppContextOpenTabParamsCase, args: T) => {
    setOpenTabParams((prev) => ({ ...prev, [key]: args }));
  };

  const handleGetOpenTabParams = <T,>(
    key: AppContextOpenTabParamsCase,
    callback: AppContextOpenTabParamsCallback<T>,
    options?: AppContextOpenTabParamsOptions
  ) => {
    callback(openTabParams[key] as T | undefined);

    if (options?.clearImmediately) {
      handleClearOpenTabParams(key);
    }
  };

  const handleClearOpenTabParams = (key: AppContextOpenTabParamsCase) => {
    setOpenTabParams((prev) => {
      delete prev[key];
      return prev;
    });
  };

  const onConfirmLeaveEditor = (callback: () => void) => {
    if (!isEditorDataChanged) {
      callback();
      return;
    }

    UiModal.warning({
      title: i18n.t('unsavedChangeModal.title'),
      content: i18n.t('unsavedChangeModal.content'),
      onOk: (close) => {
        callback();
        setIsEditorDataChanged(false);
        close();
      },
    });
  };

  const setFullScreenLayerIdAction = (id: string) => setFullScreenLayerId(id);

  const setIsEditorDataChangedAction = (value: boolean) => setIsEditorDataChanged(value);

  const setActiveEditorTabAction = (tab: EditorTabsEnum) => setActiveEditorTab(tab);

  const setMainGridStackContentLengthAction = (contentLength: number) => setMainGridStackContentLength(contentLength);

  const setShowLineHiderAction = (flag: boolean) => setShowLineHider(flag);

  const value: AppContext = {
    state: {
      isEditorDataChanged,
      fullScreenLayerId,
      activeEditorTab,
      mainGridStackContentLength,
      cacheTabData,
      showLineHider,
      isUserMode,
    },
    action: {
      setIsEditorDataChangedAction,
      setFullScreenLayerIdAction,
      setActiveEditorTabAction,
      setMainGridStackContentLengthAction,
      setCacheQueryDataAction,
      clearCacheQueryByTypeAction,
      setShowLineHiderAction,
      onConfirmLeaveEditor,
      setIsUserMode,
    },
    openTabParams: {
      chatAbout: {
        setParams: (data) => handleSetOpenTabParams(AppContextOpenTabParamsCase.ChatAbout, data),
        getParams: (callback) => handleGetOpenTabParams(AppContextOpenTabParamsCase.ChatAbout, callback),
        clearParams: () => handleClearOpenTabParams(AppContextOpenTabParamsCase.ChatAbout),
      },
    },
  };

  return <AppContextWrapper.Provider value={value}>{children}</AppContextWrapper.Provider>;
};
