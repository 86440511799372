import { FC } from 'react';
import cx from 'classnames';

import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';

import styles from './styles.module.scss';

export type NewChatProfileDescriptionProps = {
  description: string;
};

export const NewChatProfileDescription: FC<NewChatProfileDescriptionProps> = ({ description }) => (
  <div className={cx(styles.profileDescription, 'border-start border-gray-100')}>
    <Icon SvgIcon={APP_ICONS.info} className="py-1 text-secondary" />
    <div>
      <span className={styles.title}>Profile Description</span>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  </div>
);
