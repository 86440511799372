import { useRef } from 'react';
import ReactSelect, { GroupBase, Props, SelectInstance } from 'react-select';
import cx from 'classnames';

import { DropdownIndicator } from './components';
import { generateStyles, themeConfig } from './utils/constants';
import { OptionType } from './utils/types';

type UiSelectProps<
  TOption = OptionType,
  isMulti extends boolean = false,
  Group extends GroupBase<TOption> = GroupBase<TOption>,
> = Props<TOption, isMulti, Group> & {
  isTransparent?: boolean;
};

export const UiSelect = <TOption = OptionType,>({ menuPlacement = 'auto', ...props }: UiSelectProps<TOption>) => {
  const selectRef = useRef<SelectInstance<TOption> | null>(null);

  const closeMenuOnScroll: Props['closeMenuOnScroll'] = (event: Event) => {
    const menuRef = selectRef.current?.menuListRef;
    if (!menuRef) return true;

    const isScrollInsideMenu = event.target instanceof HTMLElement ? event.target.contains(menuRef) : false;
    return !isScrollInsideMenu;
  };

  return (
    <ReactSelect
      {...props}
      ref={selectRef}
      menuPlacement={menuPlacement}
      className={cx('d-flex align-items-center fs-13', props.className)}
      components={{
        DropdownIndicator,
        ...props.components,
      }}
      theme={themeConfig}
      styles={generateStyles({
        ...props.styles,
        isTransparent: props.isTransparent,
      })}
      closeMenuOnScroll={closeMenuOnScroll}
    />
  );
};
