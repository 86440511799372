import { FC, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { UiDropdownItem } from 'ui';
import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { DateFormatEnum, DateFormatUtility } from 'utils';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger, useDisplayTooltip } from 'shared/Tooltip';

import { ChatHistoryCheckbox } from '../ChatHistoryCheckbox';
import styles from './styles.module.scss';

type ChatHistoryOptionProps = {
  isActive: boolean;
  isSelected: boolean;
  session: SessionType;
  onClick: () => void;
  onCheck: () => void;
};

export const ChatHistoryOption: FC<ChatHistoryOptionProps> = ({ isActive, isSelected, session, onClick, onCheck }) => {
  const nameRef = useRef<HTMLSpanElement | null>(null);
  const { t } = useTranslation();
  const isTooltipEnabled = useDisplayTooltip(nameRef);

  const formattedDate = useMemo(
    () => DateFormatUtility.create(session.Created).format(DateFormatEnum.DATE_TIME),
    [session.Created]
  );

  const icon = useMemo(() => (session.IsEncrypted ? APP_ICONS.mask : APP_ICONS.chat), [session.IsEncrypted]);

  const name = useMemo(() => {
    if (session.Name) return session.Name;
    return t(session.IsEncrypted ? 'chat.types.private' : 'chat.types.internal');
  }, [session.IsEncrypted, session.Name]);

  return (
    <div className={cx('d-flex align-items-center gap-2 px-2 overflow-x-hidden', styles.container)}>
      <ChatHistoryCheckbox className="flex-shrink-0" value={isSelected} onChange={onCheck} />
      <Tooltip enabled={isTooltipEnabled}>
        <TooltipTrigger className="overflow-x-hidden flex-grow-1">
          <UiDropdownItem
            className={cx('d-flex align-items-center', styles.item, {
              'text-secondary': isActive,
            })}
            disabled={isActive}
            onClick={onClick}
          >
            <Icon className={cx({ 'text-secondary': isActive })} SvgIcon={icon} clickable={false} />
            <span ref={nameRef} className="text-capitalize text-truncate">
              {name}
            </span>
            <span className={cx('ms-auto text-end', styles.date)}>{formattedDate}</span>
          </UiDropdownItem>
        </TooltipTrigger>
        <TooltipContent>{name}</TooltipContent>
      </Tooltip>
    </div>
  );
};
