import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';
import { isConfigType } from '../guards/isConfigType';
import { StackEnum } from '../constants';

export const checkForSingleContentConfig = (passedConfigCopy: ConfigType | GridTabDataType) => {
  const isConfigTypeWithSingleContent = isConfigType(passedConfigCopy) && passedConfigCopy.content.length === 1;

  return (
    isConfigTypeWithSingleContent &&
    isConfigType(passedConfigCopy.content[0]) &&
    ((passedConfigCopy.content[0]?.type as StackEnum) === StackEnum.STACK ||
      (passedConfigCopy.content[0]?.type as StackEnum) === StackEnum.ROW ||
      (passedConfigCopy.content[0]?.type as StackEnum) === StackEnum.COLUMN)
  );
};
