import cx from 'classnames';

import { UIFormSlotsType, UIInput, UIInputPassword } from 'ui';

import { UIFormActions } from './UIFormActions.component';
import { UIControlEnum, UIControlType } from './types';
import { useUIForm } from './hooks';

type UIFormProps<T extends object> = {
  initialValues: T;
  controls: Array<UIControlType<T>>;
  className?: string;
  slots?: UIFormSlotsType;
  onSubmit: (values: T) => Promise<void>;
  onValuesChange?: (values: T) => void;
};

export const UIForm = <T extends object>({
  initialValues,
  className,
  controls,
  onSubmit,
  slots,
  onValuesChange,
}: UIFormProps<T>) => {
  const Actions = slots?.actions ?? UIFormActions;

  const { isSubmitting, values, touched, errors, handleSubmit, handleBlur, handleChange } = useUIForm({
    controls,
    initialValues,
    onSubmit,
    onValuesChange,
  });

  return (
    <form
      className={cx(className, 'd-flex flex-column')}
      autoComplete="false"
      noValidate={true}
      onSubmit={handleSubmit}
    >
      {controls.map((control: UIControlType<T>) => {
        switch (control.type) {
          case UIControlEnum.TEXT:
            return (
              <UIInput
                rounded
                key={control.name}
                fullHeight={false}
                className={control.className}
                autoComplete={control.autoComplete}
                name={control.name}
                label={control.label}
                value={values[control.name]}
                placeholder={control.placeholder}
                error={Boolean(errors[control.name]) && touched[control.name]}
                helperText={Boolean(errors[control.name]) && touched[control.name] ? errors[control.name] : undefined}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
          case UIControlEnum.PASSWORD:
            return (
              <UIInputPassword
                rounded
                key={control.name}
                fullHeight={false}
                className={control.className}
                autoComplete={control.autoComplete}
                name={control.name}
                label={control.label}
                value={values[control.name]}
                placeholder={control.placeholder}
                error={Boolean(errors[control.name]) && touched[control.name]}
                helperText={Boolean(errors[control.name]) && touched[control.name] ? errors[control.name] : undefined}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            );
          default:
            return null;
        }
      })}
      <Actions isSubmitting={isSubmitting} />
    </form>
  );
};
