import cx from 'classnames';

import './UISwitch.scss';

type UISwitcherProps = {
  value: boolean;
  onChange: (isOn: boolean) => void;
};

export const UISwitch: React.FC<UISwitcherProps> = ({ value: isOn, onChange }) => {
  const handleToggle = () => {
    onChange?.(!isOn);
  };

  return (
    <div className={cx('ui-switch', { 'ui-switch--on': isOn })} onClick={handleToggle}>
      <div className="ui-switch__button" />
    </div>
  );
};
