import { format } from 'numerable';
import BigNumber from 'bignumber.js';

import { FormatTypesUnum, isNumber } from 'utils';
import { ValueType } from 'store/types/tabMetaDataTypes';
import { IntFormatting } from 'utils/types/decimals';

import { SimpleViewModel } from 'api';

import { isArray, isBool, isDefined, isEmptyString, isSimpleViewType, isString } from './valueCheck';

const numberTypesArr = ['Int', 'Double', 'Long'];

const isLessThanAbsoluteValue = (value: number | BigNumber, limit: number): boolean => {
  if (BigNumber.isBigNumber(value)) {
    return value.abs().isLessThan(limit);
  }

  return Math.abs(value) < limit;
};

const isCloseToZero = (value: number | BigNumber): boolean => isLessThanAbsoluteValue(value, 1e-10);

const isSmallNumber = (value: number | BigNumber): boolean => isLessThanAbsoluteValue(value, 1);

export const isExponentialNumber = (value: number | BigNumber): boolean => {
  const precisionValue = value.toPrecision();
  const exponentialRegex = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/g;
  return exponentialRegex.test(precisionValue);
};

const formatDoubleDecimal = (
  optionalDec: string,
  mandatoryDec: string,
  value: number | BigNumber,
  formatType: string | undefined
) => {
  if (value.toString().includes('e')) {
    return `.${optionalDec}`;
  }

  if (formatType === FormatTypesUnum.NUMBER_PERCENT || formatType === FormatTypesUnum.NUMBER_BASIS_POINT) {
    return '.00';
  }

  const matchFractionDigits = formatType?.match(/:(,?)(\.\d+)?f/);

  if (matchFractionDigits) {
    const [, , precision] = matchFractionDigits;

    const fractionDigits = precision ? parseInt(precision.slice(1), 10) : 0;

    return fractionDigits > 0 ? `.${'0'.repeat(fractionDigits)}` : '';
  }

  return `.${mandatoryDec}`;
};

const generateFormatPattern = (
  value: number | BigNumber,
  formatType?: string,
  type?: ValueType['Type'],
  maxValue?: number
) => {
  const exponentialPattern = !isCloseToZero(value) && isExponentialNumber(value) ? 'e+0' : '';
  const decimalPattern = isSmallNumber(value) ? '####' : '##';

  let decimals = '00';

  if (maxValue && maxValue > 100) {
    decimals = '00';
  } else if (maxValue && maxValue > 1 && maxValue <= 100) {
    decimals = '0000';
  } else if (maxValue && maxValue > 0 && maxValue <= 1) {
    decimals = '000000';
  }

  const basePattern = `0,0${
    type === 'Double' ? formatDoubleDecimal(decimalPattern, decimals, value, formatType) : `.${decimalPattern}`
  }${exponentialPattern}`;

  const nonFormatedIntPattern = `0${
    type === 'Double' ? formatDoubleDecimal(decimalPattern, decimals, value, formatType) : `.${decimalPattern}`
  }${exponentialPattern}`;

  if (type === IntFormatting.INT && formatType !== IntFormatting.NUMBER) {
    return nonFormatedIntPattern;
  }

  switch (formatType) {
    case FormatTypesUnum.NUMBER_AMOUNT:
      return `$${basePattern}`;
    case FormatTypesUnum.NUMBER_BASIS_POINT:
      return `${basePattern}bp`;
    case FormatTypesUnum.NUMBER_PERCENT:
      return `${basePattern}%`;
    default:
      return basePattern;
  }
};

export const formatNumberValue = (
  valueData: SimpleViewModel,
  formatType?: string,
  type?: ValueType['Type'],
  maxValue?: number
) => {
  if (!isDefined(valueData) || isEmptyString(valueData)) return '';

  let value = BigNumber.isBigNumber(valueData) ? valueData : +valueData;
  if (formatType === FormatTypesUnum.NUMBER_BASIS_POINT && typeof value === 'number') {
    value *= 10_000;
  }

  if (typeof valueData === 'boolean') {
    return format(valueData.toString(), generateFormatPattern(value, formatType, type, maxValue));
  }

  return format(value.toString(), generateFormatPattern(value, formatType, type, maxValue));
};

export const isNumberType = (type?: ValueType['Type']) => type && numberTypesArr.includes(type);

export const getFormatedNumber = (value: unknown, valueType?: ValueType['Type'], formatType?: string) =>
  isNumberType(valueType) && !isArray(value) && isSimpleViewType(value)
    ? formatNumberValue(value, formatType, valueType)
    : value;

export const isNegativeNumber = (value: unknown): boolean => {
  if (BigNumber.isBigNumber(value)) {
    return new BigNumber(value).isNegative();
  }

  if (!isDefined(value) || isBool(value) || typeof value === 'object') {
    return false;
  }

  const updatedValue = isString(value) ? value.replaceAll(',', '') : value;

  return isNumber(Number(updatedValue)) && Number(updatedValue) < 0;
};
