import { FC } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type DotLoaderProps = {
  className?: string;
};

export const DotLoader: FC<DotLoaderProps> = ({ className }) => (
  <div className={cx(className, 'position-relative', styles.container)}>
    <div className={cx('position-absolute', styles.dotFlashing)}></div>
  </div>
);
