import { FC, useEffect, useId, useMemo } from 'react';

import { EntityPanelViewOfModel, schemaApi } from 'api';
import { Grid } from 'view/Grid';
import { DataType } from 'view/Grid/utils/types';
import { useRequest } from 'hook/request.hook';
import { Loader } from 'shared/Loader';
import { mapMetadataToGridColumnsHelper } from 'view/Grid/utils/helpers';
import { isArray, isLoading, toast } from 'utils';

import { getTypeOfFirstElementHelper } from './utils/helpers';

type InstanceListViewerProps = {
  data: EntityPanelViewOfModel[];
};

export const InstanceListViewer: FC<InstanceListViewerProps> = ({ data }) => {
  const id = useId();
  const { status, fetch, state: schemaDictionary } = useRequest(schemaApi.getType);

  const fetchSchemaType = () => {
    const currentSchemaType = getTypeOfFirstElementHelper(data);
    if (!currentSchemaType) return;

    fetch({ name: currentSchemaType }).catch(toast.error);
  };

  useEffect(fetchSchemaType, [data]);

  const columns = useMemo(() => {
    if (!schemaDictionary) return [];

    const schema = schemaDictionary.typeList[schemaDictionary.rootType];
    return isArray(schema?.Elements) ? mapMetadataToGridColumnsHelper(schema.Elements) : [];
  }, [schemaDictionary]);

  if (isLoading([status])) return <Loader />;

  return (
    <div className="h-100 overflow-auto">
      <Grid
        gridId={id}
        columns={columns}
        data={data as unknown as DataType[]} // TODO update type after refactor
      />
    </div>
  );
};
