import { FC, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { VisibilityState } from '@tanstack/react-table';

import { UIInput } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import './HeaderFilterCellSearch.styles.scss';

type HeaderFilterCellSearchProps = {
  visibility: VisibilityState;
  isEmptySelection: boolean;
  isAllColumnsChecked: boolean;
  search: string;
  onChange: (search: string) => void;
  toggleAllColumnsVisibility: () => void;
};

export const HeaderFilterCellSearch: FC<HeaderFilterCellSearchProps> = ({
  visibility,
  isEmptySelection,
  isAllColumnsChecked,
  toggleAllColumnsVisibility,
  search,
  onChange,
}) => {
  const { t } = useTranslation();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const clear = () => onChange('');

  useEffect(() => {
    if (!checkboxRef.current) return;

    const indeterminate = !isAllColumnsChecked && !isEmptySelection;
    if (indeterminate) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    } else if (isAllColumnsChecked) {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
    } else {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = false;
    }
  }, [visibility, isAllColumnsChecked, isEmptySelection]);

  return (
    <div className="header-filter-cell-search">
      <Input
        innerRef={checkboxRef}
        type="checkbox"
        className="header-filter-cell-checkbox"
        onChange={toggleAllColumnsVisibility}
      />
      <UIInput
        className="header-filter-cell-search__input"
        value={search}
        onChange={(event) => onChange(event.target.value)}
        placeholder={t('grid.columnFilter.filterPlaceholder') || ''}
        prefixElement={<Icon clickable={false} SvgIcon={APP_ICONS.search} />}
        suffixElement={search && <Icon SvgIcon={APP_ICONS.cancelInCirlce} onClick={clear} />}
      />
    </div>
  );
};
