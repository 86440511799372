import { CellProps, CellValue, Column, FilterProps, Row } from 'react-table';

import { SchemaTypeElementsModel, ValueTypeModel } from 'api';
import { DataType } from 'view/Grid/utils/types';
import { Cell } from 'view/Grid/components/TableHeader/Cell';
import { DataCell } from 'view/Grid/components/DataCell/DataCell.component';
import { FilterInput } from 'view/Grid/components/TableHeader/FilterInput';
import { isDefined } from 'utils';

import { ValueFormatterService } from 'services/valueFormatter';

import { getSortTypeByValueType } from 'utils/helpers/mapper';
import { ValueType } from 'store/types';

export type SimpleValueType = Extract<
  ValueTypeModel,
  | 'String'
  | 'Bool'
  | 'Int'
  | 'Double'
  | 'Long'
  | 'Date'
  | 'Time'
  | 'DateTime'
  | 'TimeElement'
  | 'DateTimeElement'
  | 'Mixed'
>;

type ColumnType = Pick<SchemaTypeElementsModel, 'Value' | 'Vector' | 'Format' | 'Name' | 'Key' | 'Optional'>;

type SubColumnType = {
  Header: string;
  accessor: string;
  columns?: SubColumnType[];
  disableSortBy?: boolean;
  disableFilters?: boolean;
};

export enum ValueTypesEnum {
  Data = 'Data',
  Bool = 'Bool',
  Double = 'Double',
  Long = 'Long',
  Int = 'Int',
  String = 'String',
  Date = 'Date',
  Time = 'Time',
  DateTime = 'DateTime',
  Key = 'Key',
  TimeElement = 'TimeElement',
  DateTimeElement = 'DateTimeElement',
  Mixed = 'Mixed',
}

export const simpleValueTypes: Array<SimpleValueType> = [
  ValueTypesEnum.String,
  ValueTypesEnum.Bool,
  ValueTypesEnum.Int,
  ValueTypesEnum.Double,
  ValueTypesEnum.Long,
  ValueTypesEnum.Date,
  ValueTypesEnum.Time,
  ValueTypesEnum.DateTime,
  ValueTypesEnum.TimeElement,
  ValueTypesEnum.DateTimeElement,
  ValueTypesEnum.Mixed,
];
const firstLetterToUppercase = (srt: string): string => srt.charAt(0).toUpperCase() + srt.slice(1);

export const isSimpleValueType = (type: string): boolean =>
  isDefined(type) ? simpleValueTypes.some((it) => it === type) : false;

export const isDataValueType = (type: string | undefined): boolean => type === ValueTypesEnum.Data;

export const getColumnProps = (
  element: ColumnType,
  isExpander: boolean,
  options?: { shouldSaveFilterValue: boolean; tabType: string },
  isColumnFormatter?: boolean,
  subColumns?: SubColumnType[]
): Column<DataType> => {
  const { Name, Value, Format, Optional } = element;
  const type = Value?.Type && (firstLetterToUppercase(Value.Type) as ValueType['Type']);

  return {
    id: isExpander ? 'expander' : Name,
    Header: Name,
    accessor: Name,
    columnType: type,
    isRequired: !Optional,
    sortType: getSortTypeByValueType(type),
    ...(isColumnFormatter && {
      valueFormatter: (cellValue: CellValue) => ValueFormatterService.simpleValueFormatter(cellValue, type, Format),
    }),
    ...(subColumns && { columns: subColumns, disableSortBy: true, disableFilters: true }),
    Filter: (props: FilterProps<DataType>) => <FilterInput {...props} {...options} />,
    filter: (rows: Row<DataType>[], ids: string[], filterValue: string) => {
      if (filterValue === '') {
        return rows;
      }
      return rows.filter((row) =>
        ids.some((id: string) => {
          const formattedValue = ValueFormatterService.simpleValueFormatter(row.values[id], Value?.Type, Format);
          return String(formattedValue).toLowerCase().includes(String(filterValue).toLowerCase());
        })
      );
    },
  };
};

const configSubColumnsArr = (metadataElArr: ColumnType[], headerSeparator: string) => {
  const newMetadataArr: SubColumnType[] = [];

  function modifyColumnsArr(
    columnsArr: SubColumnType[],
    firstHeader: string,
    prevHeader: string,
    header: string,
    isLastItem: boolean
  ) {
    const columnObj = {
      Header: header,
      accessor: header,
      disableSortBy: !isLastItem,
      disableFilters: !isLastItem,
    };

    for (let i = 0; i < columnsArr.length; i++) {
      const { Header, columns } = columnsArr[i];

      if (Header === prevHeader) {
        if (columns?.length) {
          if (columns?.find((item) => item.Header === header)) return;
          columns?.push(columnObj);
          return;
        }
        columnsArr[i].columns = [columnObj];
      }

      if (columns?.length) {
        modifyColumnsArr(columns, firstHeader, prevHeader, header, isLastItem);
      }
    }
  }

  metadataElArr.forEach((item) => {
    const splitedName: string[] = item.Name.split(headerSeparator);

    splitedName?.forEach((header, index) => {
      const isLastItem = index === splitedName.length - 1;

      // FIRST COLUMN LEVEL
      if (!newMetadataArr.length || (index === 0 && !newMetadataArr.find((metadata) => metadata.Header === header))) {
        newMetadataArr.push({
          Header: header,
          accessor: header,
        });
      } else {
        // SECOND & NEXT COLUMN LEVELS
        modifyColumnsArr(newMetadataArr, splitedName[0], splitedName[index - 1], header, isLastItem);
      }
    });
  });

  return newMetadataArr;
};

export const mapMetadataToGridColumnsHelper = (
  metadataElements: ColumnType[],
  options?: { shouldSaveFilterValue: boolean; tabType: string },
  headerSeparator?: string
): Column<DataType>[] => {
  const getHeaderFirstName = (header: string) => header.split(headerSeparator!)[0];
  const filteredMetadataElements = metadataElements.filter(
    ({ Value, Vector, Key }) =>
      Value?.Type && (isSimpleValueType(Value.Type) || Key || isDataValueType(Value.Type)) && !Vector
  );

  const getMetadataArr = () => {
    if (headerSeparator) {
      const metadataArr: ColumnType[] = [];
      filteredMetadataElements.forEach((item) => {
        if (!metadataArr.find((header) => getHeaderFirstName(header.Name) === getHeaderFirstName(item.Name))) {
          metadataArr.push(item);
        }
      });
      return metadataArr;
    }

    return filteredMetadataElements;
  };

  return getMetadataArr().map((element, index) => {
    const isExpander = index === 0;
    let subColumns;

    if (headerSeparator) {
      const allSubColumns = configSubColumnsArr(filteredMetadataElements, headerSeparator);

      element.Name = getHeaderFirstName(element.Name);
      subColumns = allSubColumns.find((item) => item.Header === element.Name)?.columns;
    }

    return {
      ...getColumnProps(element, isExpander, options, true, subColumns),
      Cell: (cellProps: CellProps<DataType>) => {
        if (isDataValueType(element.Value?.Type)) return <DataCell {...cellProps} />;
        if (isExpander) return <Cell {...cellProps} />;

        const formatterValue = cellProps.column.valueFormatter
          ? cellProps.column.valueFormatter(cellProps.value)
          : cellProps.value;
        return <>{formatterValue}</>;
      },
    };
  });
};
