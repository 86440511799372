import { FC } from 'react';

import { WarningIcon } from 'ui';
import './EditorWarningIcon.scss';

export const EditorWaringIcon: FC = () => (
  <div className="editor-warning-icon">
    <WarningIcon size="S" />
  </div>
);
