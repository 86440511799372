import { FC } from 'react';

import { UiDropdown, UiDropdownItem } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { useElementsDimension } from 'utils/hooks';
import { ElementWidth } from 'utils/hooks/useElementsDimension';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';

import { PinnedHandlerItem, PinnedHandlerProps } from '../PinnedHandlerItem';
import { ExecutedHandlerInfoByRecord, HandlerItemType } from '../../types';

import styles from './styles.module.scss';

export type PinnedHandlersProps = {
  pinnedHandlers: HandlerItemType[];
  executedHandlersInfoByRecord: ExecutedHandlerInfoByRecord;
  onHandlerClick: (handler: HandlerItemType) => void;
  onCancelHandler: (handlerId: string) => void;
  isReadOnly?: boolean;
};

export const PinnedHandlers: FC<PinnedHandlersProps> = ({
  pinnedHandlers,
  executedHandlersInfoByRecord,
  onHandlerClick,
  onCancelHandler,
  isReadOnly = false,
}) => {
  const { elementsRef, visibleElements, hiddenElements, isDropdownButtonVisible, dropdownRef } = useElementsDimension({
    elements: pinnedHandlers,
    elementWidth: ElementWidth.HANDLERS,
  });

  const getHandlerProps = (
    handler: HandlerItemType & {
      key: string;
    }
  ): PinnedHandlerProps => ({
    handler,
    handlerExecutionInfo: executedHandlersInfoByRecord[handler.key],
    onHandlerClick,
    onCancelHandler,
    tooltipPlacement: 'bottom',
  });

  return (
    <div ref={elementsRef} className="d-flex w-100">
      <div className="d-flex w-100">
        {visibleElements.map((handler) => (
          <Tooltip key={handler.id}>
            <TooltipTrigger>
              <PinnedHandlerItem
                {...getHandlerProps(handler)}
                className={styles.visibleHandler}
                isVisibleHandler
                isReadOnly={isReadOnly}
              />
            </TooltipTrigger>
            <TooltipContent>{handler.name}</TooltipContent>
          </Tooltip>
        ))}
      </div>
      {isDropdownButtonVisible && (
        <div ref={dropdownRef} className="d-flex align-items-center">
          <UiDropdown className="mx-3" tag="span" toggleButton={<Icon SvgIcon={APP_ICONS.more} />}>
            {hiddenElements.map((handler) => (
              <UiDropdownItem key={handler.key} asWrapper className={styles.dropdownItem}>
                <PinnedHandlerItem key={handler.key} {...getHandlerProps(handler)} className={styles.dropdownHandler} />
              </UiDropdownItem>
            ))}
          </UiDropdown>
        </div>
      )}
    </div>
  );
};
