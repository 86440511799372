import { FC, useMemo } from 'react';
import { Edge, Node } from 'reactflow';
import cx from 'classnames';

import { DagActionPlaceholder } from '../DagActionPlaceholder';
import { DagNodeForm, DagNodeFormVariant } from '../DagNodeForm';
import { DagEdgeForm } from '../DagEdgeForm';
import { mapEdgeToDTO, mapNodeToDTO } from '../../utils';
import { useDagContext } from '../../hooks';
import { ActionMode } from '../../types';

type DagActionsProps = {
  className?: string;
  selectedRowKey: string;
};

export const DagActions: FC<DagActionsProps> = ({ selectedRowKey, className }) => {
  const { type, actionMode, selected, resetActionMode, addNode, updateSelectedNode, updateSelectedEdge } =
    useDagContext();
  const selectedEdge = useMemo(() => {
    const edge: Edge | undefined = selected.edges[0];
    return edge ? mapEdgeToDTO(edge) : undefined;
  }, [selected.edges]);
  const selectedNode = useMemo(() => {
    const node: Node<{ label: string }> | undefined = selected.nodes[0];
    return node ? mapNodeToDTO(node) : undefined;
  }, [selected.nodes]);

  const renderAction = (mode: ActionMode) => {
    switch (mode) {
      case ActionMode.ADD_NODE:
        return (
          <DagNodeForm
            variant={DagNodeFormVariant.CREATE}
            type={type}
            selectedRowKey={selectedRowKey}
            onSubmit={addNode}
            onCancel={resetActionMode}
          />
        );
      case ActionMode.EDIT_NODE: {
        return (
          <DagNodeForm
            key={selectedNode?.Id_}
            variant={DagNodeFormVariant.UPDATE}
            selectedRowKey={selectedRowKey}
            type={type}
            initialValues={selectedNode}
            onSubmit={updateSelectedNode}
            onCancel={resetActionMode}
          />
        );
      }
      case ActionMode.EDIT_EDGE: {
        return (
          <DagEdgeForm
            key={selectedEdge?.Id_}
            type={type}
            selectedRowKey={selectedRowKey}
            initialValues={selectedEdge}
            onSubmit={updateSelectedEdge}
            onCancel={resetActionMode}
          />
        );
      }
      default:
        return <DagActionPlaceholder />;
    }
  };

  return (
    <div className={cx(className, 'd-flex flex-column text-text1 p-2 h-100 w-100')}>
      <h5 className="text-center">Actions</h5>
      {renderAction(actionMode)}
    </div>
  );
};
