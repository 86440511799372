import { isNullable, isObject } from 'utils';
import { SchemaTypeElementsModel, ValueTypeModel } from 'api';

// TODO Get SchemaType from backend
export enum SchemaType {
  Enum = 'Enum',
  GenericKey = 'GenericKey',
  GenericKeyList = 'GenericKeyList',
  GenericData = 'GenericData',
  GenericDataList = 'GenericDataList',
  SimpleKey = 'SimpleKey',
  SimpleKeyList = 'SimpleKeyList',
  Variant = 'Variant',
  VariantList = 'VariantList',
  Successor = 'Successor',
  Dict = 'Dict',
  BinaryContent = 'BinaryContent',
}

// TODO Backend have to create binaryContent marker and after we can remove binaryContentModuleName
export const binaryContentModuleName = 'Cl.Runtime.View.BinaryContent';

export type SchemaListType =
  | SchemaType.VariantList
  | SchemaType.GenericKeyList
  | SchemaType.GenericDataList
  | SchemaType.SimpleKeyList;

export const getSchemaTypeListItem = (type: SchemaListType) =>
  ({
    [SchemaType.VariantList]: SchemaType.Variant,
    [SchemaType.GenericDataList]: SchemaType.GenericData,
    [SchemaType.GenericKeyList]: SchemaType.GenericKey,
    [SchemaType.SimpleKeyList]: SchemaType.SimpleKey,
  })[type] || null;

export type CellSchemaType = SchemaType | null;

export type CellPropsType = {
  key: string;
  label: string;
  elementKey: string;
  schemaType: CellSchemaType;
  valueType?: ValueTypeModel;
  isKey: boolean;
  enumSelector?: string;
  description?: string;
  isRequired: boolean;
  successor?: string;
  isVector: boolean;
  isFlat: boolean;
  moduleName?: string;
  keyName?: string;
  keyModuleName?: string;
  path: string[];
  schemaPath?: string;
  selector?: string;
  isReadOnly: boolean;
};

export type CellParamsType = {
  schemaElement: SchemaTypeElementsModel;
  index: number;
  path: string[];
  keys?: string[];
  schemaPath?: string;
};

export const getCellProps = ({
  schemaElement,
  schemaPath = '',
  path = [],
  keys,
  index,
}: CellParamsType): CellPropsType => {
  const isExistKey = !!schemaElement.Key;
  const isVector = !!schemaElement.Vector;
  const successorData =
    (isNullable(schemaElement.Key) && isObject(schemaElement) && !isVector && schemaElement.Data?.Name) || undefined;

  const getSchemaType = () => {
    // TODO Its a temporary solution. Remove after backend refactor
    if (getSelector(schemaElement?.Data?.Module?.ModuleName, schemaElement?.Data?.Name) === binaryContentModuleName) {
      return SchemaType.BinaryContent;
    }
    if (successorData) {
      return SchemaType.Successor;
    }
    if (schemaElement?.Enum) {
      return SchemaType.Enum;
    }
    if (schemaElement.Value?.Type === 'Key' && isVector) {
      return SchemaType.GenericKeyList;
    }
    if (schemaElement.Value?.Type === 'Key' && !isVector) {
      return SchemaType.GenericKey;
    }
    if (schemaElement.Value?.Type === 'Data' && isVector) {
      return SchemaType.GenericDataList;
    }
    if (schemaElement.Value?.Type === 'Data' && !isVector) {
      return SchemaType.GenericData;
    }
    if (schemaElement.Value?.Type === 'Variant' && isVector) {
      return SchemaType.VariantList;
    }
    if (schemaElement.Value?.Type === 'Variant' && !isVector) {
      return SchemaType.Variant;
    }
    if (isExistKey && !isVector) {
      return SchemaType.SimpleKey;
    }
    if (isExistKey && isVector) {
      return SchemaType.SimpleKeyList;
    }
    if (schemaElement.Value?.Type === 'Dict') {
      return SchemaType.Dict;
    }
    return null;
  };

  const schemaType = getSchemaType();

  return {
    key: schemaElement.Name,
    label: schemaElement.Label || schemaElement.Name,
    isVector,
    schemaType,
    elementKey: schemaElement.Name,
    valueType: schemaElement.Value?.Type,
    description: schemaElement.Comment,
    isRequired: !schemaElement.Optional,
    successor: successorData,
    isKey: Boolean(keys?.includes(schemaElement.Name)),
    isFlat: (schemaType === SchemaType.GenericData || !!schemaElement.Data) && !isVector,
    moduleName: schemaElement.Data?.Name,
    keyName: schemaElement.Key?.Name,
    keyModuleName: schemaElement.Key?.Module.ModuleName,
    path: [...path, schemaElement.Name],
    schemaPath: `${schemaPath}.Elements[${index}].Data`,
    enumSelector: getSelector(schemaElement?.Enum?.Module?.ModuleName, schemaElement?.Enum?.Name),
    selector: getSelector(schemaElement?.Data?.Module?.ModuleName, schemaElement?.Data?.Name),
    isReadOnly: Boolean(schemaElement.ReadOnly),
  };
};

const getSelector = (moduleName?: string, name?: string): string | undefined =>
  moduleName && name ? `${moduleName}.${name}` : undefined;
