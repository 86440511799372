import { createContext, Dispatch, SetStateAction } from 'react';
import type { Connection, Edge, EdgeChange, Node, NodeChange, OnSelectionChangeParams } from 'reactflow';

import type { ActionMode, DAGEdgeType, DAGNodeType } from '../types';

export type DagContextType = {
  isReadonly: boolean;
  isUpdating: boolean;
  type: string;
  pointer: string;
  actionMode: ActionMode;
  activateAddNodeMode: () => void;
  resetActionMode: () => void;
  nodes: Node[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  onNodesChange: (changes: NodeChange[]) => void;
  edges: Edge[];
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  onEdgesChange: (changes: EdgeChange[]) => void;
  addNode: (dto: DAGNodeType) => void;
  updateSelectedNode: (dto: DAGNodeType) => void;
  updateSelectedEdge: (dto: DAGEdgeType) => void;
  onConnect: (connection: Connection) => void;
  selected: OnSelectionChangeParams;
  onSelectionChange: (params: OnSelectionChangeParams) => void;
};

export const DagContext = createContext<DagContextType>({} as DagContextType);
