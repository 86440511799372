import { FC } from 'react';
import { CellProps } from 'react-table';

import { Icon } from 'shared/Icon';
import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { APP_ICONS } from 'utils/icons';
import { RequiredIcon } from 'shared/RequiredIcon';
import { GoToDrilledDataIcon } from 'view/Editor/components/TableBody/TableBodyCell/GoToDrilledDataIcon';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { FlatWithType } from 'view/Editor/context/editorContext/type';
import { isString, ValueTypesEnum } from 'utils';
import { TransitionIcon } from 'view/Editor/components/TableBody/TableBodyCell/TransitionIcon';
import { SchemaType } from 'view/Editor/helpers/getCellProps.helper';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';
import './EditorKeyCell.scss';
import { useValidationError } from 'view/Editor/validation/hook/validationError.hook';
import { EditorWaringIcon } from 'view/Editor/validation/component/EditorWarningIcon';
import { EditorErrorTooltip } from 'view/Editor/validation/component/EditorErrorTooltip';

type EditorKeyCellProps = CellProps<EditorViewDataType>;

export const EditorKeyCell: FC<EditorKeyCellProps> = ({ row }) => {
  const {
    isFlat,
    isVector,
    description,
    isRequired,
    isKey,
    value: rowValue,
    path,
    valueType,
    keyName,
    schemaType,
    moduleName,
    keyModuleName,
    schemaPath,
    selector,
    label,
  } = row.original;

  const { action, event } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const { errorList } = useValidationError({ path });

  const getNextTabData = () => ({
    schemaType,
    title: label,
    key: path.toString(),
    path,
    valueType,
    moduleName,
    keyModuleName,
    keyName,
    schemaPath,
    selector: selector || '',
    isVector,
    isFlat,
  });

  const withDataType = schemaType === SchemaType.GenericData || schemaType === SchemaType.Successor;

  const handleDrilledData = () => {
    action.openEditorTabAction(getNextTabData(), tabState.tabIndex);
  };

  const handleDrillWithDataType = () => {
    if (!withDataType || !rowValue) return;
    const typeSelector = action.findSchemaByEndWith((rowValue as FlatWithType)?._t) || '';

    action.openEditorTabAction({ ...getNextTabData(), selector: typeSelector }, tabState.tabIndex);
  };

  const handleTransitionToRecord = () => {
    if (keyName && isString(rowValue)) {
      event?.onTransition?.({ type: keyName, key: rowValue });
    }
  };

  const renderActionIcons = () => {
    const isTransitionGenericIcon = schemaType === SchemaType.GenericKey && isString(rowValue);
    const isTransitionRecordIcon = schemaType === SchemaType.SimpleKey && rowValue && event?.onTransition;
    const isDict = valueType === ValueTypesEnum.Dict;
    // TODO Remove SchemaType.BinaryContent after refactor backend
    const isDrillDownIcon =
      schemaType !== SchemaType.BinaryContent &&
      !withDataType &&
      !isTransitionGenericIcon &&
      (isFlat || isVector || isDict);

    return (
      <>
        {isTransitionRecordIcon && <Icon SvgIcon={APP_ICONS.goToObject} onClick={handleTransitionToRecord} />}
        {isTransitionGenericIcon && <TransitionIcon data={rowValue} />}
        {isDrillDownIcon && <GoToDrilledDataIcon onClick={handleDrilledData} />}
        {withDataType && <GoToDrilledDataIcon onClick={handleDrillWithDataType} />}
      </>
    );
  };

  const renderError = () =>
    errorList?.length > 0 && (
      <EditorErrorTooltip errors={errorList}>
        <EditorWaringIcon />
      </EditorErrorTooltip>
    );

  return (
    <div title={description} className="editor-key-cell">
      <div className="editor-key-cell__info">
        <div className="editor-key-cell__label"> {label}</div>
        {isRequired && <RequiredIcon />}
        {isKey && <Icon clickable={false} SvgIcon={APP_ICONS.key} />}
      </div>

      <div className="editor-key-cell__actions">
        {renderError()}
        {renderActionIcons()}
      </div>
    </div>
  );
};
