import { FC, useEffect } from 'react';

import { StorageEntityModel } from 'api';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { Loader } from 'shared/Loader';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';

type EditorViewerProps = {
  recordType: string;
  editorData: StorageEntityModel;
  onTransition?: (data: TransitionRecordType) => void;
};

export const EditorViewer: FC<EditorViewerProps> = ({ recordType, editorData, onTransition }) => {
  const { getSchema } = useEditorRequest();

  useEffect(() => {
    if (recordType) void getSchema.fetch({ name: recordType });
  }, [recordType]);

  if (getSchema.isLoading) return <Loader />;

  if (!getSchema.state || !editorData) return <UiEmpty />;

  return (
    <EditorInstance
      readOnly
      data={editorData}
      schema={getSchema.state.typeList}
      rootSchemaName={getSchema.state.rootType}
      onTransition={onTransition}
    />
  );
};
