import { createEventManager } from 'utils/helpers/eventManager';
import { DeleteForeverIcon, InfoIcon, WarningIcon } from 'ui';
import i18n from 'i18n';

import { UiModalEvent, UiModalEventType, UiModalMethodProps, UIModalMethodType } from './type';

export const modalEvent = createEventManager();

export const uiModalCreate: UiModalEventType<UiModalMethodProps> = {
  emit: (data, type) => modalEvent.emit(UiModalEvent.Open, { ...getPresetModalData(type), ...data }),
  subscribe: (callback) => modalEvent.subscribe(UiModalEvent.Open, callback),
};

export const UiModalMethod: Record<UIModalMethodType, (data: UiModalMethodProps) => void> = {
  delete: (data) => uiModalCreate.emit(data, UIModalMethodType.Delete),
  warning: (data) => uiModalCreate.emit(data, UIModalMethodType.Warning),
  info: (data) => uiModalCreate.emit(data, UIModalMethodType.Info),
  error: (data) => uiModalCreate.emit(data, UIModalMethodType.Error),
  confirm: (data) => uiModalCreate.emit(data, UIModalMethodType.Confirm),
};

const getPresetModalData = (type: UIModalMethodType): UiModalMethodProps =>
  (
    ({
      delete: {
        type: UIModalMethodType.Delete,
        icon: <DeleteForeverIcon size="Full" />,
        okType: 'destructive',
        okText: i18n.t('buttons.delete'),
      },
      warning: {
        type: UIModalMethodType.Warning,
        icon: <WarningIcon size="Full" />,
        okType: 'warning',
        okText: i18n.t('buttons.ok'),
      },
      info: {
        type: UIModalMethodType.Info,
        icon: <InfoIcon size="Full" />,
        okType: 'primary',
        okText: i18n.t('buttons.ok'),
      },
      error: {
        type: UIModalMethodType.Error,
        icon: <InfoIcon size="Full" />,
        okType: 'primary',
        okText: i18n.t('buttons.ok'),
      },
      confirm: {
        type: UIModalMethodType.Confirm,
        okType: 'primary',
        okText: i18n.t('buttons.yes'),
      },
    }) as Record<UIModalMethodType, Partial<UiModalMethodProps>>
  )[type] || {};
