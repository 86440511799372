import { useContext } from 'react';

import {
  EditorTabContextType,
  EditorTabContextState,
  EditorTabContextWrapper,
} from 'view/Editor/context/editorTabContext/editorTabContext';

export const useEditorTabState = (): EditorTabContextType => {
  const data = useContext(EditorTabContextWrapper);

  return {
    state: data?.state || ({} as EditorTabContextState),
  };
};
