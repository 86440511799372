import { useEffect } from 'react';

import { EntityActionModel, EntityPanelViewOfModel, handlerApi, LoadingStatusesEnum } from 'api';
import { HandlerGetParamsRequestDto } from 'api/data/handler.data';
import { useRequest } from 'hook/request.hook';

export type ViewHandlerActionWrapperChildrenProps = {
  status: LoadingStatusesEnum;
  data: EntityPanelViewOfModel | EntityPanelViewOfModel[] | null;
};

type ViewHandlerActionWrapperProps = {
  Action: EntityActionModel;
  viewerKey: string;
  viewerType: string;
  environment: string;
  dataset: string;
  children: (props: ViewHandlerActionWrapperChildrenProps) => JSX.Element | null;
};

export const ViewHandlerActionWrapper = ({
  Action,
  viewerKey,
  viewerType,
  dataset,
  environment,
  children,
}: ViewHandlerActionWrapperProps) => {
  const handlerRequest = useRequest<EntityPanelViewOfModel | EntityPanelViewOfModel[], HandlerGetParamsRequestDto>(
    handlerApi.run
  );

  useEffect(() => {
    handlerRequest.fetch({
      key: viewerKey,
      dataset,
      table: viewerType,
      dataSource: environment,
      method: Action?.Handler,
      args: Action?.Arguments,
    });
  }, [Action, viewerKey, viewerType]);

  return children({ status: handlerRequest.status, data: handlerRequest.state });
};
