import { useId, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { selectActiveDataset } from 'store/selectors';

import styles from './styles.module.scss';
import { DatasetModal } from '../DatasetModal';

export const CommonButton = () => {
  const gridId = useId();
  const activeDatasetName = useSelector(selectActiveDataset);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpened(true);
  };

  const handleCloseModal = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      {isModalOpened && (
        <DatasetModal isModalOpened={isModalOpened} handleCloseModal={handleCloseModal} gridId={gridId} />
      )}
      <div
        className={cx('bg-dataset-btn-background c-button d-flex c-pointer me-2', styles.commonButton)}
        onClick={handleOpenModal}
      >
        <Icon clickable={false} SvgIcon={APP_ICONS.dataset} title="Dataset" color="text-dataset-btn-text" />
        <div
          className={cx(
            'd-flex align-items-center fs-13 lh-16 ms-2 text-dataset-btn-text position-relative flex-grow-1 overflow-hidden',
            styles.commonButtonText
          )}
        >
          {activeDatasetName}
        </div>
      </div>
    </>
  );
};
