import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import { v4 as uuid } from 'uuid';

import { GridTabDataType } from 'store/types';
import { HighlightTextMatch } from 'shared/highlightTextMatch';
import { ProgressBarSpinner } from 'view/ProgressBarSpinner';

import styles from './styles.module.scss';

type DropdownSectionProps = {
  searchText?: string;
  title: string;
  items?: GridTabDataType[];
  isCustomType?: boolean;
  activeTab?: string;
  onClick: (tab: GridTabDataType) => void;
  isLoading?: boolean;
  isActive: boolean;
  focusedItemIndex: number;
};

export const DropdownSection = ({
  searchText,
  title,
  items,
  isCustomType,
  activeTab,
  onClick,
  isLoading = false,
  isActive,
  focusedItemIndex,
}: DropdownSectionProps) => {
  const { t } = useTranslation();
  const isItemsList = !isLoading && items;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (containerElement && isActive && focusedItemIndex !== -1) {
      const focusedItemElement = containerElement.querySelector(`[data-item-index="${focusedItemIndex}"]`);

      if (focusedItemElement) {
        focusedItemElement.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [focusedItemIndex, isActive]);

  return (
    <div className={cx('d-flex flex-column p-0', styles.container)}>
      <div
        className={cx('bg-header-search-dropdown-header fs-14 ps-2_5 pt-1 pb-1 fw-semibold', styles.header, {
          'text-header-search-dropdown-header-text': !isCustomType,
          'text-header-search-dropdown-header-text-active': isCustomType || isActive,
        })}
      >
        {title}
      </div>
      <div
        className="d-flex flex-column overflow-auto bg-header-search-dropdown-background text-header-search-dropdown-text pt-1 h-100"
        ref={containerRef}
      >
        {!isItemsList ? (
          <div className="d-flex align-items-center justify-content-center flex-column h-100 fs-13">
            <span className="mb-2_5">{t('noData.loading')}</span>
            <ProgressBarSpinner />
          </div>
        ) : (
          items.map(({ key, label }, itemIndex) => {
            const isActiveItem = key === activeTab;
            const isKeyboardFocusedItem = itemIndex === focusedItemIndex;

            return (
              <div
                aria-hidden="true"
                // ToDo Backend: Replace uuid to identifier came from BE
                key={uuid()}
                data-item-index={itemIndex}
                className={cx(
                  'fs-13 lh-16 w-100 ms-0 ps-2_5 py-1 pe-1 border-start border-header-search-dropdown-item-border border-3',
                  styles.row,
                  {
                    'border-header-search-dropdown-item-border-active bg-header-search-dropdown-item-background-active text-header-search-dropdown-item-text-active':
                      isActiveItem,
                    'bg-header-search-dropdown-item-background-active text-header-search-dropdown-item-text-active':
                      isKeyboardFocusedItem,
                  }
                )}
                title={label}
                onClick={() => onClick({ id: key, key, label })}
              >
                <HighlightTextMatch text={label} searchText={searchText!} isTypesSearch />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
