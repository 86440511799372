import { FC } from 'react';
import cx from 'classnames';

import styles from './LoginError.module.scss';

type LoginErrorProps = { message?: string };

export const LoginError: FC<LoginErrorProps> = ({ message }) => (
  <p className={cx('fs-13 lh-16 m-0 text-danger', styles.error)}>{message}</p>
);
