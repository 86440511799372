import { useContext } from 'react';

import { GridContext, GridContextProps } from '../contexts';

export function useGrid<TData>() {
  const context = useContext(GridContext);

  if (!context) throw new Error('Grid components must be wrapped in <UIGrid />');

  return context as GridContextProps<TData>;
}
