import { Column } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { SchemaTypeHierarchyModel } from 'api/model/schema.model';
import { FilterInput } from 'view/Grid/components/TableHeader/FilterInput';

export const columns: Column<DataType>[] = [
  {
    Header: 'Type Name',
    accessor: 'typeName',
    id: 'typeName',
    Filter: FilterInput,
  },
  {
    Header: 'Package',
    accessor: 'package',
    id: 'package',
    Filter: FilterInput,
  },
  {
    Header: 'Module',
    accessor: 'module',
    id: 'module',
    Filter: FilterInput,
  },
];

export const getRowsHelper = (successors?: SchemaTypeHierarchyModel[]) =>
  successors &&
  successors.map((successor) =>
    columns.reduce(
      (acc, column, i) => ({ ...acc, [column.id as string]: i === 0 ? successor.Label : successor.Name }),
      {}
    )
  );
