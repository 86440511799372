import { FC, HTMLAttributes } from 'react';
import cx from 'classnames';

import './UIListGroup.scss';

export type UIListGroupProps = HTMLAttributes<HTMLUListElement>;

export const UIListGroup: FC<UIListGroupProps> = ({ className, children, ...rest }) => {
  const classes = cx('ui-list-group', className);
  return (
    <ul className={classes} role="listbox" aria-label="List group" {...rest}>
      {children}
    </ul>
  );
};
