import { FC } from 'react';
import { CellProps } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { EntityPanelHandlerExtensionType, EntityPanelViewOfDataType } from 'api';
import { isArray, isObject } from 'utils';

import { DataHandlerCell, DataNavigationCell } from './components';

type DataCellProps = CellProps<DataType, EntityPanelViewOfDataType | null>;

const isHandlerExtension = (input: unknown): input is EntityPanelHandlerExtensionType =>
  isObject(input) && Object.hasOwn(input, 'HandlerName') && Object.hasOwn(input, 'TargetTypeName');

const isHandlerData = (input: EntityPanelViewOfDataType | null): input is EntityPanelViewOfDataType =>
  isObject(input) && isArray(input.Extensions) && isHandlerExtension(input.Extensions[0]);

const isValueWithArguments = (input: unknown): input is EntityPanelViewOfDataType =>
  isObject(input) && Object.hasOwn(input, 'Arguments');

export const DataCell: FC<DataCellProps> = (props) => {
  if (isValueWithArguments(props.value)) {
    return <DataNavigationCell {...(props as CellProps<DataType, EntityPanelViewOfDataType>)} />;
  }

  if (isHandlerData(props.value)) {
    return <DataHandlerCell {...(props as CellProps<DataType, EntityPanelViewOfDataType>)} />;
  }

  return null;
};
