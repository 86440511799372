import { FC, memo } from 'react';

import { EditorDag } from 'view/EditorData/EditorDag';
import { EditorNewDag } from 'view/EditorData/EditorNewDag';

import { DagNodeFormVariant } from './type';
import { DAGNodeType } from '../../types';

type DagNodeFormProps = {
  initialValues?: DAGNodeType;
  variant: DagNodeFormVariant;
  type: string;
  selectedRowKey: string;
  onSubmit: (updatedNode: DAGNodeType) => void;
  onCancel: () => void;
};

export const DagNodeForm: FC<DagNodeFormProps> = memo(
  ({ variant, type, selectedRowKey, initialValues, onSubmit, onCancel }) => {
    const isCreateMode = variant === DagNodeFormVariant.CREATE;

    if (isCreateMode) {
      return <EditorNewDag recordType={type} onCancel={onCancel} onUpdate={(data) => onSubmit(data as DAGNodeType)} />;
    }

    return (
      <EditorDag
        editorData={initialValues}
        recordKey={selectedRowKey}
        recordType={type}
        onCancel={onCancel}
        onUpdate={(data) => onSubmit(data as DAGNodeType)}
      />
    );
  }
);

DagNodeForm.displayName = 'DagNodeForm';
