import { FC, useEffect } from 'react';

import { Carousel, CarouselItem } from 'shared/Carousel';
import { EditorContext, initState } from 'view/Editor/context/editorContext/editorContext';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { EditorButtonsGroup } from 'view/Editor/components/EditorButtonsGroup/EditorButtonsGroup';
import { EditorToolbar } from 'view/Editor/components/EditorToolbar/EditorToolbar';
import { KeyboardCode, ValueTypesEnum } from 'utils';
import { EditorTabContext } from 'view/Editor/context/editorTabContext/editorTabContext';
import { EditorBreadcrumbs } from 'view/Editor/components/EditorBreadcrumbs/EditorBreadcrumbs';
import { EditorComponentProps, EditorProps } from 'view/Editor/type';
import { isExistError } from 'view/Editor/validation/validation';

import styles from './Editor.module.scss';
import { EditorView } from './components/EditorView';

export const EditorComponent: FC<EditorComponentProps> = ({ data, schema, rootSchemaName, isSubmitDisabled }) => {
  const { state, action, event } = useEditorState();
  const { editorData, editorSchema, breadcrumbsData, originalEditorData } = state;
  const isError = Object.keys(state.validationError).length > 0;
  const isOriginalDataChangesExist = originalEditorData !== editorData;

  const isSubmitDisabled_ = isSubmitDisabled === false ? isSubmitDisabled : !isOriginalDataChangesExist || isError;

  useEffect(() => {
    event?.onChangeData?.(isOriginalDataChangesExist);
  }, [isOriginalDataChangesExist]);

  useEffect(() => {
    if (schema && rootSchemaName) {
      const initResult = action.initEditor({ schema, data, rootSchemaName });
      if (!isExistError(initResult)) {
        event?.onInit?.({ data: initResult.data });
      }
    }
  }, [data, schema, rootSchemaName]);

  const handleSubmit = () =>
    action.validateEditor() ||
    event?.onSubmit?.({
      data: state.editorData,
    });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.code === KeyboardCode.KeyS) {
      e.preventDefault();
      handleSubmit();
    }
  };

  if (!editorData || !editorSchema) return null;

  return (
    <div className={styles.editorWrapper} onKeyDown={handleKeyDown}>
      <EditorToolbar />
      <EditorBreadcrumbs />
      <Carousel activeIndex={breadcrumbsData.activeTabIndex} onChange={action.switchTabAction}>
        {breadcrumbsData.tabs.map((tab, i) => (
          <CarouselItem key={tab.path.toString()} useFullWidth={tab.tabEditorValueType === ValueTypesEnum.String}>
            <EditorTabContext tab={tab} tabIndex={i}>
              <EditorView
                tab={tab}
                data={editorData}
                nextTabTitle={breadcrumbsData.tabs[i + 1]?.title}
                schema={editorSchema[tab.selector]}
              />
            </EditorTabContext>
          </CarouselItem>
        ))}
      </Carousel>
      <EditorButtonsGroup isSubmitDisabled={isSubmitDisabled_} onSubmit={handleSubmit} onCancel={event?.onCancel} />
    </div>
  );
};

export const Editor: FC<EditorProps> = ({
  readOnly = initState.isReadOnly,
  isDisableKeyFields = initState.isDisableKeyFields,
  requestConfig,
  i18n,
  onTransition,
  onInit,
  onCancel,
  onSubmit,
  onChangeData,
  onRefresh,
  ...editorComponentProps
}) => (
  <EditorContext
    isDisableKeyFields={isDisableKeyFields}
    isReadOnly={readOnly}
    requestConfig={requestConfig}
    i18n={i18n}
    event={{
      onCancel,
      onSubmit,
      onChangeData,
      onRefresh,
      onInit,
      onTransition,
    }}
  >
    <EditorComponent {...editorComponentProps} />
  </EditorContext>
);
