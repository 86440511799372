import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './styles.module.scss';

type PromptLabelProps = {
  prompt: string;
};

export const PromptLabel: FC<PromptLabelProps> = memo(({ prompt }) => {
  const { t } = useTranslation();

  return (
    <div className={cx('d-flex align-items-center gap-1 fs-13 lh-16 text-chat-prompt-label-text', styles.textMedium)}>
      <span>{t('chat.prompt')}:</span>
      <span>{prompt}</span>
    </div>
  );
});

PromptLabel.displayName = 'PromptLabel';
