type OpenParameters = {
  to: string;
  subject?: string;
  body?: string;
};

type RestParameters = Omit<OpenParameters, 'to'>;

const URL_MAX_LENGTH = 1900;
// prevent email ending with unparsable encoded character e.g. %252
const regex = /%\d*$/;

export const emailService = {
  open({ to, ...restParameters }: OpenParameters): void {
    const baseUrl = `mailto:${to}`;
    const url = (Object.keys(restParameters) as Array<keyof RestParameters>)
      .filter((key: keyof RestParameters) => restParameters[key])
      .reduce((acc: string, key: keyof RestParameters, index: number) => {
        const isFirstIndex = index === 0;
        const prefix = isFirstIndex ? '?' : '&';
        const parameter = encodeURIComponent(restParameters[key] as string);
        return acc.concat(`${prefix}${key}=${parameter}`);
      }, baseUrl)
      .slice(0, URL_MAX_LENGTH)
      .replace(regex, '')
      .concat('...');
    window.open(url, '_blank');
  },
};
