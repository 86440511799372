import { FC } from 'react';
import { Button } from 'reactstrap';
import { CellProps } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { EntityPanelViewOfDataType } from 'api';
import { getFormattedValue } from 'view/Grid/components/DataCell/utils/helpers';
import { useViewersNavigator } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/ViewersNavigator';

import { mapArgumentsToNavigation, parseArguments } from './utils/helpers';

type DataNavigationCellProps = CellProps<DataType, EntityPanelViewOfDataType>;

export const DataNavigationCell: FC<DataNavigationCellProps> = ({ value }) => {
  const { navigateTo } = useViewersNavigator();
  const formattedText = getFormattedValue(value);

  const onNavigate = () => {
    const navigationParameters = mapArgumentsToNavigation(parseArguments(value.Arguments));
    if (!navigationParameters) return;

    navigateTo(navigationParameters);
  };

  return (
    <Button
      className="bg-transparent border-0 p-0 d-flex align-items-center gap-1 mw-100 btn-link"
      onClick={onNavigate}
    >
      {formattedText}
    </Button>
  );
};
