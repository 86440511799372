import { THEME_KEYS } from 'store/constants/theme';

import { InitialScreen } from 'containers/TabItemContent/components/InitialScreen';

import { CustomHeader } from './light/components/CustomHeader';
import { CustomMainContainer } from './light/components/CustomMainContainer';
import { CustomTabItem } from './light/components/CustomTabItem';
import { CustomTabs } from './light/components/CustomTabs';
import { CustomEditorContentInner } from './light/components/CustomEditorContentInner';
import { CustomTypeToolbarInner } from './light/components/CustomTypeToolbarInner';

const lightThemeOverrideComponentsDictionary = {
  Header: CustomHeader,
  Main: CustomMainContainer,
  TabItem: CustomTabItem,
  Tabs: CustomTabs,
  EditorContentInner: CustomEditorContentInner,
  TypeToolbaInner: CustomTypeToolbarInner,
  InitialScreen,
};

const darkThemeOverrideComponentsDictionary = {
  InitialScreen,
};

export const overrideComponentsDictionary = {
  [THEME_KEYS.light]: lightThemeOverrideComponentsDictionary,
  [THEME_KEYS.dark]: darkThemeOverrideComponentsDictionary,
};
