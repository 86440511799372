import { storageApi } from 'api';

type RequestTypeParams = {
  type: string;
  key: string;
  dataset: string;
};

export const isExistsEntityRequest = ({ type, key, dataset }: RequestTypeParams): Promise<boolean> =>
  storageApi
    .getRecord({ type, key, dataset })
    .then(({ data }) => Boolean(data))
    .catch(() => false);
