import { FC, PropsWithChildren, useCallback, useEffect, useId, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { ButtonCode, eventManager, handleMouseDown } from 'utils';

import { HIDE_EVENT_NAMES } from './utils/constants';
import { ContextMenu, ContextMenuItem, ContextMenuOpenEvent, ContextMenuShowEventProps } from './ContextMenu.context';
import styles from './ContextMenu.module.scss';

enum Events {
  HIDE_ALL = '[Context menu] hide all',
}

export const ContextMenuProvider: FC<PropsWithChildren<object>> = ({ children }) => {
  const id = useId();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [contextMenu, setContextMenu] = useState<{
    isOpen: boolean;
    position: number[];
    items: ContextMenuItem[];
  }>();

  const show = useCallback<ContextMenuOpenEvent>((data) => {
    if (!data) return;

    const { items, e } = data;
    const { clientX, clientY } = e;

    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth);

    const menuWidth = 170;
    const padding = 15;

    const maxX = vw - menuWidth - padding;

    setContextMenu({
      isOpen: true,
      position: [Math.min(clientX, maxX), clientY],
      items,
    });
  }, []);

  const handleOpenContextMenu = useCallback<ContextMenuOpenEvent>((data) => {
    if (!data) return;
    const { e, items } = data;
    e.stopPropagation();

    eventManager.emit(Events.HIDE_ALL).emit<ContextMenuShowEventProps>(id, { items, e });
  }, []);

  const hide = useCallback(() => {
    setContextMenu({ isOpen: false, position: [], items: [] });
  }, []);

  const onClickHandler = useCallback(
    (onClick: () => void) => {
      onClick();
      hide();
    },
    [hide]
  );

  const clickOutside = useCallback(
    (event: MouseEvent) => {
      const isClickInsideMenu = menuRef.current && menuRef.current.contains(event.target as Node);
      if (!isClickInsideMenu) {
        hide();
      }
    },
    [hide]
  );

  useEffect(() => {
    eventManager.subscribe(id, show).subscribe(Events.HIDE_ALL, hide);

    return () => {
      eventManager.unsubscribe(id, show).unsubscribe(Events.HIDE_ALL, hide);
    };
  }, [id]);

  useEffect(() => {
    if (contextMenu?.isOpen) {
      HIDE_EVENT_NAMES.forEach((eventName: string) => window.addEventListener(eventName, hide));
      window.addEventListener('click', clickOutside, true);
    }

    return () => {
      HIDE_EVENT_NAMES.forEach((eventName: string) => window.removeEventListener(eventName, hide));
      window.removeEventListener('click', clickOutside, true);
    };
  }, [hide, contextMenu?.isOpen]);

  return (
    <ContextMenu.Provider value={{ handleOpenContextMenu }}>
      {contextMenu?.isOpen &&
        createPortal(
          <div
            ref={menuRef}
            className={cx(
              'position-fixed d-flex flex-column bg-primary shadow-lg rounded border border-gray-700 p-0_5',
              styles.contextMenu
            )}
            style={{
              left: contextMenu.position[0],
              top: contextMenu.position[1],
            }}
          >
            {contextMenu.items.map(({ title, icon, onClick }) => (
              <div
                onMouseDown={(event: MouseEvent) =>
                  handleMouseDown(event, {
                    [ButtonCode.MAIN]: () => onClickHandler(onClick),
                  })
                }
                key={title}
                className={cx('d-flex align-items-center fs-14 text-text2 px-2 py-1_5', styles.contextMenuItem)}
              >
                <Icon SvgIcon={icon} clickable={false} className="pe-2" />
                <div>{title}</div>
              </div>
            ))}
          </div>,
          document.body
        )}
      {children}
    </ContextMenu.Provider>
  );
};
