import type { Edge, Node } from 'reactflow';

import type { DAGEdgeType, DAGNodeType } from '../types';

export const mapNodeToDTO = (node: Node<{ label: string }>): DAGNodeType => ({
  _t: 'DAGNode',
  Id_: node.id,
  Position: { X: node.position.x, Y: node.position.y },
  Data: { Label: node.data.label },
});

export const mapNodesToDTOs = (nodes: Node<{ label: string }>[]): DAGNodeType[] => nodes.map(mapNodeToDTO);

export const mapNodeToDomain = (dagNode: DAGNodeType): Node => {
  const { Label, ...meta } = dagNode.Data;
  return {
    id: dagNode.Id_,
    position: {
      x: dagNode.Position.X,
      y: dagNode.Position.Y,
    },
    data: {
      label: Label,
      meta,
    },
  };
};

export const mapEdgeToDTO = (edge: Edge): DAGEdgeType => ({
  Id_: edge.id,
  Source: edge.source,
  Target: edge.target,
  Label: edge.label as string,
});

export const mapEdgesToDTOs = (edges: Edge[]): DAGEdgeType[] => edges.map(mapEdgeToDTO);

export const mapNodesToDomain = (dagNodes: DAGNodeType[]): Node[] => dagNodes.map(mapNodeToDomain);

export const mapEdgeToDomain = (dagEdge: DAGEdgeType): Edge => ({
  id: dagEdge.Id_,
  source: dagEdge.Source,
  target: dagEdge.Target,
  label: dagEdge.Label,
});

export const mapEdgesToDomain = (dagEdges: DAGEdgeType[]): Edge[] => dagEdges.map(mapEdgeToDomain);
