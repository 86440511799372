import { UIControlRuleType } from './UIControlRule.type';

export enum UIControlEnum {
  TEXT,
  PASSWORD,
}

type UIBaseControlParams<T extends object> = {
  className?: string;
  label?: string;
  name: Extract<keyof T, string>;
  rules?: Array<UIControlRuleType<T>>;
};

export type UITextControlType<T extends object> = UIBaseControlParams<T> & {
  type: UIControlEnum.TEXT;
  placeholder?: string;
  autoComplete?: string;
};
export type UITextControlParams<T extends object> = Omit<UITextControlType<T>, 'type'>;

export type UIPasswordControlType<T extends object> = UIBaseControlParams<T> & {
  type: UIControlEnum.PASSWORD;
  placeholder?: string;
  autoComplete?: string;
};
export type UIPasswordControlParams<T extends object> = Omit<UIPasswordControlType<T>, 'type'>;

export type UIControlType<T extends object> = UITextControlType<T> | UIPasswordControlType<T>;
