import { isString } from 'utils';

import { CharIndexType } from './types';

export class PageCharIndexList {
  private charIndexes: CharIndexType[] = [];

  constructor(initialCharIndexes: CharIndexType[] = []) {
    this.charIndexes = initialCharIndexes;
  }

  public static createByElements(elements: Element[]): PageCharIndexList {
    const charIndexes = elements
      .map((element) => element.textContent)
      .reduce((acc, textContent, index) => {
        if (isString(textContent)) {
          // it's a line-break
          if (textContent.length === 0) {
            return acc.concat([{ char: ' ', charIndexInSpan: 0, spanIndex: index }]);
          }

          return acc.concat(
            textContent.split('').map((char, charIndex) => ({ char, charIndexInSpan: charIndex, spanIndex: index }))
          );
        }

        return acc;
      }, [] as CharIndexType[]);
    return new PageCharIndexList(charIndexes);
  }

  public get isEmpty(): boolean {
    return this.charIndexes.length === 0;
  }

  public get fullText(): string {
    return this.charIndexes.map((item) => item.char).join('');
  }

  public slice(startIndex: number, endIndex: number): PageCharIndexList {
    const slicedCharIndexes = this.charIndexes.slice(startIndex, endIndex);
    return new PageCharIndexList(slicedCharIndexes);
  }

  public groupBySpanIndex(): Record<CharIndexType['spanIndex'], CharIndexType[]> {
    return this.charIndexes.reduce(
      (dic, item) => ({
        ...dic,
        [item.spanIndex]: (dic[item.spanIndex] || []).concat([item]),
      }),
      {} as Record<CharIndexType['spanIndex'], CharIndexType[]>
    );
  }
}
