import { useCallback, useState } from 'react';

import { FileStatusType, FileType } from '../../utils/types';
import { fileToBase64 } from './utils/fileToBase64';
import { loadChatFilesRequest, RequestParamsType as LoadChatFilesRequestParams } from './utils/loadChatFilesRequest';

export function useFiles() {
  const [filesWithContent, setFilesWithContent] = useState<File[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);

  const loadChatFiles = useCallback(
    (params: LoadChatFilesRequestParams) =>
      loadChatFilesRequest(params).then((loadedFiles) => {
        setFiles(loadedFiles);
        setFilesWithContent([]);
      }),
    []
  );

  const onRemove = useCallback((filename: string) => {
    setFilesWithContent((prevFilesWithContent: File[]) =>
      prevFilesWithContent.filter((prevFileWithContent) => prevFileWithContent.name !== filename)
    );
    setFiles((prevFiles: FileType[]) =>
      prevFiles.reduce((acc, prevFile: FileType) => {
        if (prevFile.filename === filename) {
          if (prevFile.isSaved) {
            acc.push({
              ...prevFile,
              status: FileStatusType.REMOVED,
              savedStatus: FileStatusType.REMOVED,
            });
          }
        } else {
          acc.push(prevFile);
        }

        return acc;
      }, [] as Array<FileType>)
    );
  }, []);

  const onChange = useCallback((updatedFilesWithContent: File[]) => {
    setFilesWithContent((prevFilesWithContent: File[]) => {
      const updatedFileNames = updatedFilesWithContent.map((updatedFile: File) => updatedFile.name);

      return prevFilesWithContent
        .filter((file) => updatedFileNames.indexOf(file.name) === -1)
        .concat(updatedFilesWithContent);
    });
    setFiles((prevFiles: FileType[]) => {
      const updatedFiles = [];
      const updatedFileNamesWithContent = updatedFilesWithContent.map((file) => file.name);
      for (let i = 0; i < prevFiles.length; i += 1) {
        const prevFile = prevFiles[i];

        if (updatedFileNamesWithContent.includes(prevFile.filename)) {
          // update status
          updatedFiles.push({
            ...prevFile,
            status: FileStatusType.UPDATED,
          });

          // removed item from file names
          const removedIndex = updatedFileNamesWithContent.indexOf(prevFile.filename);
          updatedFileNamesWithContent.splice(removedIndex, 1);
        } else {
          updatedFiles.push(prevFile);
        }
      }

      return updatedFiles.concat(
        updatedFileNamesWithContent.map((updatedFileNameWithContent) => ({
          filename: updatedFileNameWithContent,
          status: FileStatusType.ADDED,
          savedStatus: FileStatusType.NONE,
          isSaved: false,
        }))
      );
    });
  }, []);

  const onReset = useCallback(() => setFilesWithContent([]), []);

  const getFileNames = useCallback(() => filesWithContent.map((file) => file.name), [filesWithContent]);

  const getUnsavedFiles = useCallback(() => {
    const fileNamesWithContent = filesWithContent.map((file) => file.name);
    return files.filter(
      (file) => fileNamesWithContent.includes(file.filename) || file.status === FileStatusType.REMOVED
    );
  }, [files, filesWithContent]);

  const onSend = useCallback(() => {
    onReset();
    setFiles((prevFiles: FileType[]) =>
      prevFiles
        .filter((prevFile: FileType) => prevFile.status !== FileStatusType.REMOVED)
        .map((prevFile: FileType) => ({
          ...prevFile,
          status: FileStatusType.NONE,
          savedStatus: FileStatusType.NONE,
          isSaved: true,
        }))
    );
  }, [onReset]);

  const getFilesAsBase64List = useCallback(() => {
    const promises = filesWithContent.map(fileToBase64);
    return Promise.all(promises);
  }, [filesWithContent]);

  const getRemovedFileNames = useCallback(
    () =>
      files.filter((file: FileType) => file.status === FileStatusType.REMOVED).map((file: FileType) => file.filename),
    [files]
  );

  return {
    files,
    filesWithContent,
    onChange,
    onReset,
    getFileNames,
    getUnsavedFiles,
    getFilesAsBase64List,
    onRemove,
    loadChatFiles,
    getRemovedFileNames,
    onSend,
  };
}
