import { isArray, isDefined, isObject } from 'utils';
import { deepClone } from 'view/Editor/context/util';
import { FlatViewModel, SchemaTypeListModel, StorageEntityModel } from 'api';

export enum SetValueByPathType {
  AddToArray = 'addToArray',
  SetValue = 'setValue',
}

export type SetValueByPathProps<T> = {
  object: T;
  value: unknown;
  pathList: string[];
  addType?: SetValueByPathType;
  addedIndex?: number;
  withCreate?: boolean;
};

export const setValueByPath = <T>({
  object,
  value,
  pathList,
  withCreate = true,
  addType = SetValueByPathType.SetValue,
  addedIndex,
}: SetValueByPathProps<T>): typeof object | null => {
  if (!(isObject(object) || isArray(object)) || !pathList) return null;

  const objClone = deepClone(object) as typeof object;
  const pathListCopy = [...pathList];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let currentObject = objClone as Record<string, any>;

  while (pathListCopy.length > 1) {
    const index = pathListCopy.shift();
    if (!index || !(isObject(objClone) || isArray(objClone))) return null;

    if (!currentObject[index] && withCreate) {
      currentObject[index] = {};
    }

    currentObject = currentObject[index];
  }

  const lastIndex = pathListCopy.shift();

  if (currentObject && lastIndex && (isObject(objClone) || isArray(objClone))) {
    if (addType === SetValueByPathType.SetValue) {
      currentObject[lastIndex] = value;
    }
    if (addType === SetValueByPathType.AddToArray) {
      if (!currentObject[lastIndex]) {
        currentObject[lastIndex] = [];
      }
      const targetArray = currentObject[lastIndex];
      if (isArray(targetArray)) {
        if (isDefined(addedIndex)) {
          targetArray.splice(addedIndex, 0, value);
        } else {
          targetArray.push(value);
        }
      }
    }

    return objClone;
  }

  return null;
};

// TODO Update createEmptyDataBySchema rules
export const createEmptyDataBySchema = (schema: SchemaTypeListModel, rootSchema: string): StorageEntityModel => {
  if (!schema || !rootSchema) return null;
  const createDataBySchema = (currentSchemaKey: string): FlatViewModel | null => {
    const currentSchema = schema[currentSchemaKey];
    if (!currentSchema) return null;

    return (
      currentSchema?.Elements?.reduce((acc, element) => {
        const isVectorData = !!element.Vector;
        const isGenericData = element?.Value?.Type === 'Data' || element?.Value?.Type === 'Key';
        const isVectorNestedData = isVectorData && element?.Data?.Module?.ModuleName;
        const isVectorNestedKey = isVectorData && element?.Key?.Module?.ModuleName;
        const isNotVectorNestedData = !isVectorData && element?.Data?.Module?.ModuleName;

        if (isGenericData) {
          acc[element.Name] = null;
          return acc;
        }
        if (isVectorNestedData) {
          acc[element.Name] = null;
          return acc;
        }
        if (isVectorNestedKey) {
          acc[element.Name] = null;
          return acc;
        }
        if (isVectorData) {
          acc[element.Name] = null;
          return acc;
        }
        if (isNotVectorNestedData) {
          const innerSchemaName = `${element.Data!.Module.ModuleName}.${element.Data!.Name}`;
          acc[element.Name] = createDataBySchema(innerSchemaName);
          return acc;
        }
        if (!isVectorData) {
          acc[element.Name] = null;
          return acc;
        }
        return acc;
      }, {} as FlatViewModel) || null
    );
  };

  return createDataBySchema(rootSchema);
};
