import { FC, memo } from 'react';

import {
  FileStatusType,
  FileType,
} from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { FileItem } from '../FileItem';

type FileListProps = {
  isLoading: boolean;
  files: FileType[];
  onRemove: (filename: string) => void;
};

export const FileList: FC<FileListProps> = memo(({ files, isLoading, onRemove }) => (
  <div className="d-flex flex-wrap gap-2 p-2">
    {files
      .filter((file) => file.status !== FileStatusType.REMOVED)
      .map((file) => (
        <FileItem
          key={file.filename}
          name={file.filename}
          status={file.status}
          isLoading={isLoading}
          isRemovable={false}
          onRemove={() => onRemove(file.filename)}
        />
      ))}
  </div>
));

FileList.displayName = 'FileList';
