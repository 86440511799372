import { Header } from '@tanstack/react-table';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import { UiGridFooterCell } from '../UIGridFooterCell/UIGridFooterCell.component';
import { UIGridFooterRow } from '../UIGridFooterRow/UIGridFooterRow.component';
import { UIGridCorner } from '../UIGridCorner/UIGridCorner.component';
import './UIGridFoot.styles.scss';

type UIGridFootProps = {
  className?: string;
};

export const UIGridFoot = <TData,>({ className }: UIGridFootProps) => {
  const { api, footerRef } = useGrid<TData>();

  const renderCell = <TValue,>(footer: Header<TData, TValue>) => <UiGridFooterCell footer={footer} />;

  return (
    <div className={cx('ui-grid-footer', className)} ref={footerRef}>
      {api.getFooterGroups().map((footerGroup) => (
        <UIGridFooterRow key={footerGroup.id} footerGroup={footerGroup} renderCell={renderCell} />
      ))}
      <UIGridCorner className="ui-grid-footer__corner" direction="vertical" />
    </div>
  );
};
