import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { PdfSearchInput, PdfSearchInputRef } from './components';
import styles from './PDFSearch.module.scss';

type PdfSearchProps = {
  initialSearch: string;
  currentMatchedTerm: number;
  matchedTermsSize: number;
  onNextMatch: () => void;
  onPreviousMatch: () => void;
  isOpen: boolean;
  onClose: () => void;
  onChangeSearch: (search: string) => void;
  isClosable?: boolean;
};

export const PdfSearch: FC<PdfSearchProps> = ({
  initialSearch,
  currentMatchedTerm,
  matchedTermsSize,
  isOpen,
  onClose,
  onChangeSearch,
  onNextMatch,
  onPreviousMatch,
  isClosable = true,
}) => {
  const inputRef = useRef<PdfSearchInputRef | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    if (isOpen) {
      inputRef.current.setSearch(initialSearch);
      inputRef.current.focus();
    } else {
      inputRef.current.clear();
      onChangeSearch('');
    }
  }, [isOpen]);

  return (
    <div
      className={cx(styles.pdfSearchContainer, {
        [styles.closableSearchBorder]: isClosable,
      })}
    >
      <div className="d-flex align-items-center gap-2 py-1 px-2 fs-13 lh-16 text-text1">
        <PdfSearchInput
          ref={inputRef}
          currentMatchedTerm={currentMatchedTerm}
          matchedTermsSize={matchedTermsSize}
          onNextMatch={onNextMatch}
          onPreviousMatch={onPreviousMatch}
          onChangeSearch={onChangeSearch}
        />
        {isClosable && (
          <Button className={styles.button} onClick={onClose}>
            <Icon clickable={false} SvgIcon={APP_ICONS.close} />
          </Button>
        )}
      </div>
    </div>
  );
};
