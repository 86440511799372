import { ChatAboutParams } from './chatAboutParams.type';

export enum AppContextOpenTabParamsCase {
  ChatAbout = 'ChatAbout',
}

export type AppContextOpenTabParamsState = Record<AppContextOpenTabParamsCase, unknown>;

export type AppContextOpenTabParamsCallback<T> = (args?: T) => void;

export type AppContextOpenTabParamsOptions = {
  clearImmediately?: boolean;
};

type AppContextOpenTabParamsActions<T = unknown> = {
  setParams: (args: T) => void;
  getParams: (callback: AppContextOpenTabParamsCallback<T>, options?: AppContextOpenTabParamsOptions) => void;
  clearParams: () => void;
};

export type AppContextOpenTabParams = {
  chatAbout: AppContextOpenTabParamsActions<ChatAboutParams>;
};
