import { FC, memo } from 'react';
import cx from 'classnames';

import { GridTabDataType, ViewerMetadataType } from 'store/types';

import { ViewersNavigator } from '../ViewersNavigator';

import styles from './styles.module.scss';

type ContentProps = {
  currentKey?: string;
  type?: string;
  activeViewerType: string | undefined;
  viewerData: ViewerMetadataType;
  activeTab: string | undefined;
  tabs: GridTabDataType[];
  switchTab: (tab: GridTabDataType) => void;
  updateOrderTabs: (tabs: GridTabDataType[]) => void;
  isLoading: boolean;
};

export const Content: FC<ContentProps> = memo((props) => (
  <div className={cx('w-100 d-flex flex-column h-100', styles.top)}>
    <div className="w-100 d-flex flex-column h-100 border border-chat-tabs-bottom-border">
      <ViewersNavigator {...props} />
    </div>
  </div>
));

Content.displayName = 'Content';
