import { FC } from 'react';

import { getIconSize, IconProps } from '../type';

export const DeleteForeverIcon: FC<IconProps> = ({
  color = 'currentColor',
  className,
  size = 'S',
  width,
  height,
  onClick,
}) => (
  <svg
    className={className}
    width={width || getIconSize(size)}
    height={height || getIconSize(size)}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      d="M85.8,455.4c0,31.4,26,56.6,56.6,56.6h227.3c31.4,0,56.6-26,56.6-56.6V114.1H85.8V455.4z M155.8,252.4l40.4-40.4l60.2,60.2l60.2-60.2l40.4,40.4l-60.2,60.2l60.2,60.2l-40.4,40.4L256.4,353l-60.2,60.2l-40.4-40.4 l60.2-60.2L155.8,252.4z M356.2,28.7L327.4,0H185.5l-28.7,28.7H57v56.6H455V28.7H356.2z"
      fill={color}
    />
  </svg>
);
