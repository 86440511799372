import {
  ActionType,
  Hooks,
  ReducerTableState,
  TableInstance,
  TableProps,
  TableState,
  actions,
  makePropGetter,
  useGetLatest,
} from 'react-table';

actions.resize = 'columnResizing';

function reducer<D extends object>(newState: TableState<D>, action: ActionType): ReducerTableState<D> {
  if (action.type === actions.init) {
    return {
      ...newState,
    };
  }

  if (action.type === actions.resize) {
    const { columnId, width } = action;

    return {
      ...newState,
      resizedColumn: { columnId, width },
    };
  }

  return newState;
}

function useInstance<D extends object>(instance: TableInstance<D>): void {
  const { getHooks } = instance;

  const getInstance = useGetLatest(instance);

  const getColumnWidthProps = makePropGetter(getHooks().getColumnWidthProps as Hooks, { instance: getInstance() });

  Object.assign(instance, {
    getColumnWidthProps,
  });
}

const defaultGetColumnWidthProps = (props: TableProps, { instance }: { instance: TableInstance }) => {
  const { dispatch } = instance;

  return [
    props,
    {
      saveColumWidth: ({ columnId, width }: { columnId: string; width: number }) => {
        dispatch({ type: actions.resize, columnId, width });
      },
    },
  ];
};

export const useColumnWidth = <D extends object>(hooks: Hooks<D>) => {
  Object.assign(hooks, {
    getColumnWidthProps: [defaultGetColumnWidthProps],
  });
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useColumnWidth.plaginName = 'useColumnWidth';
