import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OptionType } from 'ui';
import { SchemaTypeElementsModel } from 'api';

import { QueryBuilderContext, QueryBuilderContextType } from '../../contexts';
import { CUSTOM_INDEX, QueryType, ValueWithConditionType } from '../../types';
import { generateSchemaDictionaryByElements } from './utils/helpers';
import { generateValueWithConditionBySchema, validateValueBySchemaType } from '../../helpers';

type QueryBuilderProviderComponentProps = {
  initialQuery: QueryType;
  elements: SchemaTypeElementsModel[];
  children: (context: QueryBuilderContextType) => ReactNode;
  onReset: () => void;
};

export const QueryBuilderProvider: FC<QueryBuilderProviderComponentProps> = ({
  initialQuery,
  elements,
  children,
  onReset,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<QueryType>(initialQuery);
  const [selectedIndex, setIndex] = useState<string | undefined>(() => Object.keys(initialQuery).at(0));
  const isShowIntroductionSection = !selectedIndex;
  const indexes: OptionType[] = useMemo(() => {
    const baseIndexes = elements.map((element: SchemaTypeElementsModel) => ({
      label: element.Name,
      value: element.Name,
    }));
    if (baseIndexes.length > 0) {
      baseIndexes.push({ label: t('queryBuilder.customIndex'), value: CUSTOM_INDEX });
    }
    return baseIndexes;
  }, [elements]);
  const schemaDictionary = useMemo(() => generateSchemaDictionaryByElements(elements), [elements]);
  const validQuery = useMemo(
    () =>
      Object.keys(query).some((key: string) => {
        const valueWithCondition = query[key];
        const schema = schemaDictionary[key];
        return validateValueBySchemaType(valueWithCondition, schema);
      }),
    [query, schemaDictionary]
  );

  const selectIndex = useCallback(
    (index: string | undefined) => {
      setIndex((prevIndex) => {
        if (prevIndex === index) return prevIndex;

        let updatedQuery: QueryType = {};
        if (index === CUSTOM_INDEX) {
          updatedQuery = Object.keys(schemaDictionary).reduce((acc, key) => {
            const schema = schemaDictionary[key];
            return { ...acc, [key]: generateValueWithConditionBySchema(schema) };
          }, {});
        } else {
          const schema = index ? schemaDictionary[index] : undefined;
          updatedQuery = index ? { [index]: generateValueWithConditionBySchema(schema) } : {};
        }
        setQuery(updatedQuery);

        return index;
      });
    },
    [schemaDictionary]
  );

  const updateValueByKey = useCallback((key: string, values: ValueWithConditionType) => {
    setQuery((prevQuery) => {
      if (Object.hasOwn(prevQuery, key)) {
        return {
          ...prevQuery,
          [key]: values,
        };
      }

      return prevQuery;
    });
  }, []);

  const reset = useCallback(() => {
    setIndex(undefined);
    setQuery({});
    onReset();
  }, []);

  const context: QueryBuilderContextType = {
    validQuery,
    schemaDictionary,
    indexes,
    query,
    selectedIndex,
    selectIndex,
    isShowIntroductionSection,
    updateValueByKey,
    reset,
  };

  return <QueryBuilderContext.Provider value={context}>{children(context)}</QueryBuilderContext.Provider>;
};
