import { FC, PropsWithChildren, ReactNode, useRef } from 'react';

import { Tooltip, TooltipContent, TooltipTrigger, useDisplayTooltip } from 'shared/Tooltip';

import styles from './styles.module.scss';

export type InfoListItemProps = PropsWithChildren<{
  icon: ReactNode;
  label: string;
  action?: ReactNode;
}>;

export const InfoListItem: FC<InfoListItemProps> = ({ icon, label, action, children }) => {
  const contentRef = useRef<HTMLSpanElement | null>(null);
  const isTooltipEnabled = useDisplayTooltip(contentRef);

  return (
    <div className={styles.listItem}>
      <span className={styles.label}>
        {icon}
        {label}
      </span>

      <div className={styles.content}>
        <Tooltip enabled={isTooltipEnabled}>
          <TooltipTrigger ref={contentRef} className="overflow-x-hidden text-truncate">
            {children}
          </TooltipTrigger>
          <TooltipContent>{children}</TooltipContent>
        </Tooltip>

        <span>{action || null}</span>
      </div>
    </div>
  );
};
