import { FC, memo } from 'react';
import { Row } from 'react-table';

import { useSelector } from 'react-redux';

import { DataType } from 'view/Grid/utils/types';

import { selectRecordStatuses } from 'store/selectors/toolbarHandler.selector';
import { RunHandlerStatusCodeEnum } from 'api';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { env } from 'env';

import styles from './styles.module.scss';

type HandlerRecordStatusType = {
  row: Row<DataType>;
};

export const HandlerRecordStatus: FC<HandlerRecordStatusType> = memo(({ row }) => {
  const recordStatuses = useSelector(selectRecordStatuses);
  const renderHandlerStatus = (statusCode: string) => {
    switch (statusCode) {
      case RunHandlerStatusCodeEnum.Submitted:
      case RunHandlerStatusCodeEnum.Running:
        return <div className={styles.spinnerIcon} />;
      case RunHandlerStatusCodeEnum.Completed:
        return <Icon SvgIcon={APP_ICONS.applyLarge} clickable={false} color="text-green" />;
      case RunHandlerStatusCodeEnum.Cancelled:
      case RunHandlerStatusCodeEnum.Failed:
        return env.isDemoMode ? (
          <>
            <Icon SvgIcon={APP_ICONS.applyLarge} clickable={false} color="text-green" />
            <Icon SvgIcon={APP_ICONS.speechBubble} clickable={false} className={styles.speechBubble} />
          </>
        ) : (
          <Icon SvgIcon={APP_ICONS.cross} clickable={false} color="text-button-destructive" />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      {renderHandlerStatus(recordStatuses[row.id])}
    </div>
  );
});

HandlerRecordStatus.displayName = 'HandlerRecordStatus';
