import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';
import { findTarget } from './findTarget';
import { isGridTabDataType } from '../guards/isGridTabDataType';

export const setDraggingTabUndragging = (passedConfig: ConfigType, draggingLabel: GridTabDataType) => {
  const target = findTarget(passedConfig, draggingLabel.id);

  if (target && isGridTabDataType(target)) {
    target.isCurrentlyDragging = false;
  }
};
