import { useContext } from 'react';

import { TabItemContext } from 'containers/TabItemContent/context';
import { TabItemActionType, TabItemContextType, TabItemStateType } from 'containers/TabItemContent/utils/types';

export const useTabItemContext = (): TabItemContextType => {
  const data = useContext(TabItemContext);

  return {
    state: data?.state || ({} as TabItemStateType),
    action: data?.action || ({} as TabItemActionType),
  };
};
