import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';
import { isConfigType } from '../guards/isConfigType';

export const filterConfigContent = (passedConfig: ConfigType | GridTabDataType, draggingTab: GridTabDataType) => {
  const passedConfigCopy = passedConfig;

  if (isConfigType(passedConfigCopy) && passedConfigCopy.content.length) {
    passedConfigCopy.content = passedConfigCopy.content.filter((contentItem) =>
      filterConfigContent(contentItem, draggingTab)
    );

    if (passedConfigCopy.content.length === 0) {
      return undefined;
    }
  }

  if (passedConfigCopy.key !== draggingTab?.key) {
    return passedConfigCopy;
  }

  return undefined;
};
