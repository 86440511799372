import { FC } from 'react';

import { UIInputDate, UIInputDateTime, UIInputNumber, UIInputTime } from 'ui';
import { BooleanValue } from 'view/Editor/components/TableBody/TableBodyCell/BooleanValue';
import { DateFormatEnum, DateFormatUtility, isString } from 'utils';
import { StringValue } from 'view/Editor/components/TableBody/TableBodyCell/StringValue';
import { GenericKeySelect } from 'view/Editor/components/TableBody/TableBodyCell/GenericKeySelect';

import { VariantSelectValueType, VariantType } from './VariantSelect.component';
import styles from './VariantSelectValue.component.module.scss';

export type VariantSelectValueProps = {
  isError?: boolean;
  type: VariantType;
  value: VariantSelectValueType;
  onChange: (value: VariantSelectValueType) => void;
};

export const VariantSelectValue: FC<VariantSelectValueProps> = ({ isError, type, value, onChange }) => {
  const renderSelectValue = () => {
    switch (type) {
      case VariantType.String:
        return <StringValue elementName={type} value={(value || '') as string} error={isError} onChange={onChange} />;

      case VariantType.Bool:
        return <BooleanValue value={value as boolean} onChange={onChange} />;

      case VariantType.Int:
        return <UIInputNumber error={isError} value={value as number} onChange={onChange} />;

      case VariantType.Double:
        return <UIInputNumber error={isError} value={value as number} onChange={onChange} />;

      case VariantType.Date:
        return (
          <UIInputDate
            value={isString(value) ? DateFormatUtility.create(value).toInstance() : null}
            onChange={(changedDate) => {
              onChange(changedDate?.format(DateFormatEnum.DATE));
            }}
          />
        );

      case VariantType.Time:
        return (
          <UIInputTime
            value={isString(value) ? DateFormatUtility.create(value, DateFormatEnum.TIME_MS).toInstance() : null}
            onChange={(changedDate) => {
              onChange(changedDate?.format(DateFormatEnum.TIME_MS));
            }}
          />
        );

      case VariantType.DateTime:
        return (
          <UIInputDateTime
            value={isString(value) ? DateFormatUtility.create(value).toInstance() : null}
            onChange={(changedDate) => {
              onChange(changedDate?.toISOString());
            }}
          />
        );

      case VariantType.Key:
        return <GenericKeySelect error={isError} value={(value as string) || ''} onChange={onChange} />;

      default:
        return <div className={styles.VariantSelectValue__notSupport}>Type not supported</div>;
    }
  };

  return renderSelectValue();
};
