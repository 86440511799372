import { FC, KeyboardEvent, useEffect, useRef } from 'react';
import cx from 'classnames';

import { KeyboardCode } from 'utils';

import styles from './PDFInputPagination.module.scss';

type PdfInputPaginationProps = {
  page: number;
  lastPage: number;
  onChangePage: (page: number) => void;
};

export const PdfInputPagination: FC<PdfInputPaginationProps> = ({ page, lastPage, onChangePage }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.value = page.toString();
  }, [page]);

  useEffect(() => {
    const cancelWheel = (event: WheelEvent) => event.preventDefault();

    inputRef.current?.addEventListener('wheel', cancelWheel, { passive: false });

    return () => {
      inputRef.current?.removeEventListener('wheel', cancelWheel);
    };
  }, []);

  const handleValue = () => {
    if (!inputRef.current) return;

    const inputValue = parseInt(inputRef.current.value, 10);
    const isMoreThanLastPage = inputValue > lastPage;
    const isLessThanMinPage = inputValue < 1;
    if (Number.isNaN(inputValue) || isMoreThanLastPage || isLessThanMinPage) {
      inputRef.current.value = page.toString();
      return;
    }

    if (inputValue !== page) {
      onChangePage(inputValue);
    }
  };

  const onKeyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    const isEnterPressed =
      (event.code as KeyboardCode) === KeyboardCode.Enter || (event.code as KeyboardCode) === KeyboardCode.NumpadEnter;
    if (isEnterPressed) {
      handleValue();
    }
  };

  return (
    <input
      defaultValue={page}
      ref={inputRef}
      type="number"
      className={cx(styles.input, 'text-center bg-transparent text-text1 rounded py-0 lh-16')}
      onKeyDown={onKeyDownHandler}
      onBlur={handleValue}
    />
  );
};
