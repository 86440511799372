import { useCallback, useMemo, useReducer, useRef } from 'react';

import { mapValuesToErrorDictionary, mapValuesToTouchedDictionary } from './UIForm.helpers';

export type UIFormStateType<T extends object> = {
  values: T;
  touched: Record<keyof T, boolean>;
  errors: Record<keyof T, string>;
  isSubmitting: boolean;
};

export enum UIFormActionEnum {
  SET_VALUE_BY_KEY = 'SET_VALUE_BY_KEY',
  SET_TOUCHED_BY_KEY = 'SET_TOUCHED_BY_KEY',
  SET_ERRORS = 'SET_ERRORS',
  SET_ALL_TOUCHED = 'SET_ALL_TOUCHED',
  SET_IS_SUBMITTING = 'SET_IS_SUBMITTING',
}
export type UIFormActions<T extends object> =
  | {
      type: UIFormActionEnum.SET_VALUE_BY_KEY;
      payload: { key: string; value: T[keyof T] };
    }
  | {
      type: UIFormActionEnum.SET_TOUCHED_BY_KEY;
      payload: string;
    }
  | {
      type: UIFormActionEnum.SET_ERRORS;
      payload: UIFormStateType<T>['errors'];
    }
  | {
      type: UIFormActionEnum.SET_ALL_TOUCHED;
    }
  | {
      type: UIFormActionEnum.SET_IS_SUBMITTING;
      payload: boolean;
    };

export function reducer<T extends object>(state: UIFormStateType<T>, action: UIFormActions<T>): UIFormStateType<T> {
  switch (action.type) {
    case UIFormActionEnum.SET_VALUE_BY_KEY:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.key]: action.payload.value,
        },
      };
    case UIFormActionEnum.SET_TOUCHED_BY_KEY:
      return {
        ...state,
        touched: {
          ...state.touched,
          [action.payload]: true,
        },
      };
    case UIFormActionEnum.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case UIFormActionEnum.SET_ALL_TOUCHED:
      return {
        ...state,
        touched: (Object.keys(state.touched) as Array<keyof T>).reduce(
          (acc, key: keyof T) => {
            acc[key] = true;
            return acc;
          },
          {} as Record<keyof T, boolean>
        ),
      };
    case UIFormActionEnum.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    default:
      return state;
  }
}

export function useUIFormStore<T extends object>(initialValues: T) {
  const initialTouched = useMemo(() => mapValuesToTouchedDictionary(initialValues), []);
  const initialErrors = useMemo(() => mapValuesToErrorDictionary(initialValues), []);
  const stateRef = useRef<UIFormStateType<T>>({
    values: initialValues,
    touched: initialTouched,
    errors: initialErrors,
    isSubmitting: false,
  });
  const [, forceRerender] = useReducer((x: number) => x + 1, 0);

  const dispatch = useCallback((action: UIFormActions<T>) => {
    const prev = stateRef.current;
    stateRef.current = reducer(stateRef.current, action);
    if (prev !== stateRef.current) forceRerender();
  }, []);

  const setValueByKey = useCallback((key: string, value: T[keyof T]) => {
    dispatch({
      type: UIFormActionEnum.SET_VALUE_BY_KEY,
      payload: { key, value },
    });
  }, []);

  const setErrors = useCallback((errors: Record<keyof T, string>) => {
    dispatch({ type: UIFormActionEnum.SET_ERRORS, payload: errors });
  }, []);

  const touchedByKey = useCallback((key: string) => {
    dispatch({ type: UIFormActionEnum.SET_TOUCHED_BY_KEY, payload: key });
  }, []);

  const setAllTouched = useCallback(() => {
    dispatch({ type: UIFormActionEnum.SET_ALL_TOUCHED });
  }, []);

  const setIsSubmitting = useCallback((isSubmitting: boolean) => {
    dispatch({ type: UIFormActionEnum.SET_IS_SUBMITTING, payload: isSubmitting });
  }, []);

  return {
    stateRef,
    setValueByKey,
    setErrors,
    touchedByKey,
    setAllTouched,
    setIsSubmitting,
  };
}
