import { ToastContent } from 'react-toastify';

import { UIToastOptions } from 'ui/UIToast';

export type ToastType = 'info' | 'success' | 'warning' | 'error';

export enum ToastTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export type OpenToastType = {
  [key: string]: Record<
    string,
    {
      content?: ToastContent<unknown>;
      options?: UIToastOptions;
    }
  >;
};

export type CustomToastType = {
  type: ToastTypeEnum;
  id?: string;
  content?: unknown;
  options?: UIToastOptions;
};
