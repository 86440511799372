import { convertUserDtoToModel } from '../converter/user.converter';
import { AuthLogoutRedirectModel, AuthTokesModel, AuthTypeMetadataModel, AuthTypeModel } from '../model/auth.model';
import { UserModel } from '../model/user.model';
import {
  AuthHandleRedirectRequestDto,
  AuthLoginRequestDto,
  AuthLogoutRedirectRequestDto,
  AuthRenewRequestDto,
} from '../data/auth.data';
import { UserDto } from '../data/user.data';
import { axiosMain } from '../axios';
import { getBaseUrl } from '../helpers/generateBaseUrl';

export type AuthApi = {
  handleRedirect(data: AuthHandleRedirectRequestDto): Promise<AuthTokesModel>;
  getLoginRedirectUrl(type: AuthTypeModel): string;
  getTypes(): Promise<AuthTypeModel[]>;
  getType(typeId: AuthTypeModel['id']): Promise<AuthTypeMetadataModel>;
  login(data: AuthLoginRequestDto): Promise<AuthTokesModel>;
  renew(type: AuthTypeModel, data: AuthRenewRequestDto): Promise<AuthTokesModel>;
  profile(): Promise<UserModel>;
  getLogoutRedirect(data: AuthLogoutRedirectRequestDto): Promise<AuthLogoutRedirectModel>;
};

export const authApiUrls: Record<keyof AuthApi, string> = {
  handleRedirect: '/auth/:typeId:login-path',
  getLoginRedirectUrl: '/auth/:typeId:login-path',
  getTypes: '/auth/types',
  getType: '/auth/:typeId/type-info',
  login: '/auth/:typeId/login',
  renew: '/auth/:typeId:renew-path',
  profile: '/auth/me',
  getLogoutRedirect: '/auth/:typeId/logout-redirect',
};

export const authApi: AuthApi = {
  async handleRedirect({ type, oneTimeToken }: AuthHandleRedirectRequestDto): Promise<AuthTokesModel> {
    const res = await axiosMain.get<AuthTokesModel>(
      authApiUrls.handleRedirect.replace(':typeId', type.id).replace(':login-path', type.login_path),
      {
        params: { one_time_token: oneTimeToken },
        withCredentials: true,
      }
    );
    return res.data;
  },
  getLoginRedirectUrl(type: AuthTypeModel): string {
    const baseUrl = getBaseUrl();
    return `${baseUrl}${authApiUrls.getLoginRedirectUrl
      .replace(':typeId', type.id)
      .replace(':login-path', type.login_path)}`;
  },
  async getTypes(): Promise<AuthTypeModel[]> {
    const res = await axiosMain.get<AuthTypeModel[]>(authApiUrls.getTypes);
    return res.data;
  },
  async getType(typeId: AuthTypeModel['id']): Promise<AuthTypeMetadataModel> {
    const res = await axiosMain.get<AuthTypeMetadataModel>(authApiUrls.getType.replace(':typeId', typeId));
    return res.data;
  },
  async login(data): Promise<AuthTokesModel> {
    const formData = new FormData();
    formData.set('username', data.username);
    formData.set('password', data.password);

    const res = await axiosMain.post<AuthTokesModel>(authApiUrls.login.replace(':typeId', data.type_id), formData);
    return res.data;
  },
  async renew(type: AuthTypeModel, data: AuthRenewRequestDto): Promise<AuthTokesModel> {
    const res = await axiosMain.post<AuthTokesModel>(
      authApiUrls.renew.replace(':typeId', type.id).replace(':renew-path', type.renew_path),
      data
    );
    return res.data;
  },
  async profile(): Promise<UserModel> {
    const res = await axiosMain.get<UserDto>(authApiUrls.profile);
    return convertUserDtoToModel(res.data);
  },
  async getLogoutRedirect(data: AuthLogoutRedirectRequestDto): Promise<AuthLogoutRedirectModel> {
    const { typeId, ...dto } = data;
    const res = await axiosMain.post<AuthLogoutRedirectModel>(
      authApiUrls.getLogoutRedirect.replace(':typeId', typeId),
      dto
    );
    return res.data;
  },
};
