import { FC, useContext } from 'react';

import { GridTabDataType } from 'store/types';
import { Tabs } from 'shared/Tabs';
import { ConfigType } from 'view/DragnDrop/utils/types';
import { MaximizeButton } from 'shared/MaximizeButton';
import { useAppContext } from 'context';
import { DnDContext } from 'view/DragnDrop/DraggableContainer';
import { StackEnum } from 'view/DragnDrop/utils/constants';

export type DraggableGridTabsProps = {
  activeTabs?: string[];
  activeTab?: string;
  tabs: GridTabDataType[];
  stackRef?: ConfigType | GridTabDataType;
  onChangeTab: (tab: GridTabDataType) => void;
  onSetTabs: (tabs: GridTabDataType[]) => void;
};

export const DraggableGridTabs: FC<DraggableGridTabsProps> = ({
  activeTabs,
  activeTab,
  tabs,
  onChangeTab,
  onSetTabs,
  stackRef,
}) => {
  const { state, action } = useAppContext();
  const { config } = useContext(DnDContext);
  const isMaximizedView = !!state.fullScreenLayerId;
  const handleMaximizedButton = () => {
    if (!stackRef?.id) return;
    action.setFullScreenLayerIdAction(isMaximizedView ? '' : stackRef.id);
  };

  const renderMaximizeBtn = () => (
    <div className="d-flex align-items-center px-2">
      <MaximizeButton isMaximizedView={isMaximizedView} onCLick={handleMaximizedButton} />
    </div>
  );

  return (
    <Tabs
      dndTabs
      activeTabs={activeTabs}
      shouldTranslate
      openedTabs={tabs}
      activeTab={activeTab}
      onChangeTab={(tab) => onChangeTab(tab)}
      onSetTabs={(reorderedTabs) => onSetTabs(reorderedTabs)}
      stackRef={stackRef}
      renderRightContent={
        config.content.length > 1 && config.type !== StackEnum.STACK ? renderMaximizeBtn() : undefined
      }
    />
  );
};
