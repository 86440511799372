import { FC } from 'react';
import cx from 'classnames';

import { UiEmpty, UIListGroup } from 'ui';

import { ProfileModel } from '../../api/model';
import styles from './styles.module.scss';

export type NewChatTabListProps = {
  list: ProfileModel[];
  activeProfile?: string;
  onProfileChange: (profile: ProfileModel) => void;
};

export const NewChatTabList: FC<NewChatTabListProps> = ({ list, activeProfile, onProfileChange }) =>
  list.length ? (
    <UIListGroup className={cx(styles.list, 'col-3 mt-2_5')}>
      {list.map((profile) => {
        const isActive = activeProfile === profile.profileName;
        return (
          <UIListGroup.Item
            key={profile.profileName}
            active={isActive}
            onClick={() => onProfileChange(profile)}
            className="py-2 px-3"
          >
            {profile.profileName}
          </UIListGroup.Item>
        );
      })}
    </UIListGroup>
  ) : (
    <UiEmpty title="The group doesn't have any profiles" />
  );
