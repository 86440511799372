import { FC } from 'react';

import { useDagContext } from '../../hooks';
import { DagViewer } from '../DagViewer';

type DagContentProps = { className?: string };

export const DagContent: FC<DagContentProps> = ({ className }) => {
  const { nodes, edges, onNodesChange, onEdgesChange, onConnect, onSelectionChange } = useDagContext();

  return (
    <DagViewer
      className={className}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      onSelectionChange={onSelectionChange}
    />
  );
};
