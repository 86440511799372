import { FormEvent, useCallback, useMemo } from 'react';
import { actions, makePropGetter, functionalUpdate, Hooks, Row, TableInstance, Cell, TableState } from 'react-table';

import { ButtonCode } from 'utils';
import { ColumnNameType, DataType, SelectedRowsIdsType } from 'view/Grid/utils/types';

actions.singleCellSelection = 'singleCellSelection';
actions.cellRangeSelectionStart = 'cellRangeSelectionStart';
actions.cellRangeSelecting = 'cellRangeSelecting';
actions.cellRangeSelectionEnd = 'cellRangeSelectionEnd';
actions.setSelectedCellIds = 'setSelectedCellIds';
actions.setCurrentSelectedCellIds = 'setCurrentSelectedCellIds';
actions.singleFilterCellSelection = 'singleFilterCellSelection';
actions.deselectCells = 'deselectCells';
actions.selectCellsArr = 'selectCellsArr';
actions.filterCellRangeSelecting = 'filterCellRangeSelecting';
actions.clearState = 'clearState';
actions.selectAllRowsAllCells = 'selectAllRowsAllCells';
actions.saveLeaveFilterCellsIds = 'saveLeaveFilterCellsIds';
actions.selectProvidedRowsCells = 'selectProvidedRowsCells';

interface ActionType {
  type: string;
  singleCell: string;
  startCell: string;
  rowFirstCell: string;
  selectingEndCell: string;
  endCell: string;
  selectedCellIds: Record<string, boolean>;
  event: KeyboardEvent;
  row: Row<DataType>;
}

const defaultgetCellRangeSelectionProps = (
  props: Row<DataType>,
  { instance, cell, row }: { instance: TableInstance<DataType>; cell: Cell<DataType>; row: Row<DataType> }
) => {
  const {
    state: { isSelectingCells },
    dispatch,
  } = instance;
  const { allCells } = row;

  const filterColumnIndex = allCells.findIndex((el) => el.column.id === ColumnNameType.FILTER);
  const firstCellInRowSelection = allCells[filterColumnIndex !== -1 ? filterColumnIndex + 1 : 0]?.id; // Cell around checkbox should not be higlighted.
  const lastCellInRowSelection = allCells.at(-1)?.id || firstCellInRowSelection;

  const single = (singleCell: string, event: FormEvent) =>
    dispatch({ type: actions.singleCellSelection, singleCell, event });
  const start = (startCell: string, event: FormEvent, rowFirstCell: string) =>
    dispatch({ type: actions.cellRangeSelectionStart, startCell, event, rowFirstCell });
  const selecting = (selectingEndCell: string, event: FormEvent) =>
    dispatch({ type: actions.cellRangeSelecting, selectingEndCell, event });
  const end = (endCell: string, event: FormEvent) => dispatch({ type: actions.cellRangeSelectionEnd, endCell, event });

  const singleFilterCellSelection = (startCell: string, endCell: string, passedRow: Row<DataType>, event: FormEvent) =>
    dispatch({ type: actions.singleFilterCellSelection, startCell, endCell, row: passedRow, event });

  const deselectCells = (startCell: string, endCell: string, event: FormEvent) =>
    dispatch({ type: actions.deselectCells, startCell, endCell, event });

  const filterSelecting = (endCell: string, passedRow: Row<DataType>, event: FormEvent) =>
    dispatch({ type: actions.filterCellRangeSelecting, endCell, row: passedRow, event });

  const saveLeaveFilterCellsIds = (startCell: string, endCell: string) =>
    dispatch({ type: actions.saveLeaveFilterCellsIds, startCell, endCell });

  return [
    props,
    {
      onMouseDown: (e: React.MouseEvent) => {
        e.persist();
        if (e.button === ButtonCode.SECONDARY) {
          single(cell.id, e);
          return;
        }

        if (cell.column.id === ColumnNameType.FILTER) {
          singleFilterCellSelection(firstCellInRowSelection, lastCellInRowSelection, cell.row, e);
        } else {
          start(cell.id, e, firstCellInRowSelection);
        }
      },
      onMouseUp: (e: React.MouseEvent) => {
        e.persist();
        end(cell.id, e);
      },
      onMouseEnter: (e: React.MouseEvent) => {
        if (isSelectingCells) {
          e.persist();

          if (cell.column.id === ColumnNameType.FILTER) {
            filterSelecting(lastCellInRowSelection, cell.row, e);
          } else {
            selecting(cell.id, e);
          }
        }
      },
      onMouseLeave: (e: React.MouseEvent) => {
        if (isSelectingCells) {
          e.persist();
          if (cell.column.id === ColumnNameType.FILTER) {
            saveLeaveFilterCellsIds(firstCellInRowSelection, lastCellInRowSelection);
          }
        }
      },
      onClick: (e: React.MouseEvent) => {
        e.persist();
        if (cell.column.id === ColumnNameType.FILTER || e.ctrlKey) {
          deselectCells(firstCellInRowSelection, lastCellInRowSelection, e);
        }
      },
    },
  ];
};

function reducer(
  state: TableState<DataType>,
  action: ActionType,
  previousState: TableInstance,
  instance: TableInstance<DataType>
) {
  if (action.type === actions.init) {
    return {
      ...state,
      selectedCellIds: { ...instance.initialState?.selectedCellIds } || {},
      isSelectingCells: false,
      startCellSelection: null,
      endCellSelection: null,
      currentSelectedCellIds: {},
      activeRowStartCell: null,
      filterSelectedCellIds: {},
      leaveFilterRowCellsIds: {},
      startRowFirstCell: null,
      currentFilterRowCellsSelectedIds: {},
      cellRangeSelecting: {},
      freezedSelectedCellIds: {},
      selectedRowsIds: {},
      enteredFilterCellRowId: null,
    };
  }

  if (action.type === actions.singleCellSelection) {
    const { singleCell } = action;

    if (Object.keys(state.selectedCellIds).length > 0 && state.selectedCellIds[singleCell]) return state;

    return {
      ...state,
      selectedCellIds: { [singleCell]: true },
      isSelectingCells: false,
    };
  }

  if (action.type === actions.cellRangeSelectionStart) {
    const { startCell, event, rowFirstCell } = action;

    let newState = Object.assign(state.selectedCellIds, {});
    const feezedState = { ...newState };

    if (!event.ctrlKey) {
      newState = {
        [startCell]: true,
      };
    }

    return {
      ...state,
      selectedCellIds: {
        ...newState,
      },
      isSelectingCells: true,
      startCellSelection: startCell,
      currentSelectedCellIds: newState,
      startRowFirstCell: rowFirstCell,
      freezedSelectedCellIds: feezedState,
      selectedRowsIds: {},
    };
  }

  if (action.type === actions.cellRangeSelecting) {
    const { selectingEndCell, event } = action;
    const {
      state: { startCellSelection },
      getCellsBetweenId,
    } = instance;

    const newState = getCellsBetweenId(startCellSelection, selectingEndCell);
    const freezedState = Object.assign(state.freezedSelectedCellIds, {});

    let newSelectedCellIds = { ...state.selectedCellIds };

    const filterCellRowKeys = Object.keys(state.leaveFilterRowCellsIds);

    if (filterCellRowKeys.length) {
      if (event.ctrlKey) {
        const allFilterRowsKey = Object.keys(state.currentFilterRowCellsSelectedIds);

        for (let i = 0; i < allFilterRowsKey.length; i++) {
          const key = allFilterRowsKey[i];

          if (!freezedState[key]) {
            delete newSelectedCellIds[key];
          }
        }

        newSelectedCellIds = { ...newSelectedCellIds, ...newState };
      } else {
        newSelectedCellIds = newState;
      }
    }

    if (!filterCellRowKeys.length && !event.ctrlKey) {
      newSelectedCellIds = newState;
    }

    if (!filterCellRowKeys.length && event.ctrlKey) {
      const previousSelection = Object.keys(previousState.cellRangeSelecting);

      for (let i = 0; i < previousSelection.length; i++) {
        const key = previousSelection[i];

        if (!freezedState[key]) {
          delete newSelectedCellIds[key];
        }
      }

      newSelectedCellIds = { ...newSelectedCellIds, ...newState };
    }

    return {
      ...state,
      endCellSelection: selectingEndCell,
      currentSelectedCellIds: newState,
      selectedCellIds: newSelectedCellIds,
      leaveFilterRowCellsIds: {},
      currentFilterRowCellsSelectedIds: {},
      cellRangeSelecting: newState,
      selectedRowsIds: {},
    };
  }

  if (action.type === actions.cellRangeSelectionEnd) {
    const {
      state: { selectedCellIds, currentSelectedCellIds },
    } = instance;

    return {
      ...state,
      selectedCellIds: { ...selectedCellIds, ...currentSelectedCellIds },
      isSelectingCells: false,
      currentSelectedCellIds: {},
      startCellSelection: null,
      endCellSelection: null,
      filterSelectedCellIds: {},
      leaveFilterRowCellsIds: {},
      currentFilterRowCellsSelectedIds: {},
      cellRangeSelecting: {},
      freezedSelectedCellIds: {},
    };
  }

  if (action.type === actions.setSelectedCellIds) {
    const selectedCellIds = functionalUpdate(action.selectedCellIds, state.selectedCellIds);

    return {
      ...state,
      selectedCellIds: selectedCellIds,
    };
  }

  if (action.type === actions.singleFilterCellSelection) {
    const { startCell, endCell, row, event } = action;

    const {
      getCellsBetweenId,
      getRowsBetweenId,
      state: { currentSelectedCellIds, selectedRowsIds },
      saveSelectedRow,
    } = instance;

    let cellsInRow = getCellsBetweenId(startCell, endCell);
    let rows = getRowsBetweenId(startCell, endCell);
    let newState = Object.assign(state.selectedCellIds, {});
    const newRowsIds = Object.assign(state.selectedRowsIds, {});

    const freezedState = { ...newState };

    if (event.ctrlKey === true) {
      if (selectedRowsIds[row.id]) {
        delete newRowsIds[row.id];
      } else {
        newRowsIds[row.id] = saveSelectedRow(row);
      }

      const keys = Object.keys(cellsInRow);
      newState = { ...currentSelectedCellIds, ...newState };

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (newState[key]) {
          delete newState[key];
        } else {
          newState[key] = true;
        }
      }
    }

    if (event.shiftKey === true) {
      cellsInRow = getCellsBetweenId(state.activeRowStartCell, endCell);
      const keys = Object.keys(cellsInRow);
      rows = getRowsBetweenId(state.activeRowStartCell, endCell);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (newState[key]) {
          delete newState[key];
        } else {
          newState[key] = true;
        }
      }
    }

    const finalState = event.ctrlKey ? newState : cellsInRow;
    const finaleRowsIds = event.ctrlKey
      ? { ...newRowsIds, ...rows }
      : {
          [row.id]: saveSelectedRow(row),
          ...rows,
        };

    return {
      ...state,
      selectedCellIds: finalState,
      currentSelectedCellIds: finalState,
      isSelectingCells: true,
      startCellSelection: startCell,
      startRowFirstCell: startCell,
      currentFilterRowCellsSelectedIds: cellsInRow,
      freezedSelectedCellIds: freezedState,
      selectedRowsIds: finaleRowsIds,
    };
  }

  if (action.type === actions.filterCellRangeSelecting) {
    const { endCell, row, event } = action;

    const {
      getCellsBetweenId,
      getRowsBetweenId,
      state: { selectedCellIds, selectedRowsIds },
      saveSelectedRow,
    } = instance;

    if (
      selectedRowsIds[row.id] &&
      previousState.enteredFilterCellRowId &&
      row.id !== previousState.enteredFilterCellRowId
    ) {
      delete selectedRowsIds[previousState.enteredFilterCellRowId];
    }

    const newState = getCellsBetweenId(state.startRowFirstCell || state.startCellSelection, endCell);
    const rows = getRowsBetweenId(state.startRowFirstCell || state.startCellSelection, endCell);
    let allSelectedCells = { ...newState };

    const freezedState = Object.assign(state.freezedSelectedCellIds, {});

    if (event.ctrlKey === true) {
      const selectedCellIdsCopy = { ...selectedCellIds };

      let prevKeys = Object.keys(previousState.currentFilterRowCellsSelectedIds);

      if (prevKeys.length === 0) {
        prevKeys = Object.keys(state.cellRangeSelecting);
      }

      for (let i = 0; i < prevKeys.length; i++) {
        const key = prevKeys[i];

        if (!newState[key] && !freezedState[key]) {
          delete selectedCellIdsCopy[key];
        }
      }

      allSelectedCells = { ...selectedCellIdsCopy, ...newState };
    }

    return {
      ...state,
      selectedCellIds: allSelectedCells,
      currentSelectedCellIds: allSelectedCells,
      endCellSelection: endCell,
      filterSelectedCellIds: newState,
      currentFilterRowCellsSelectedIds: { ...state.currentFilterRowCellsSelectedIds, ...newState },
      enteredFilterCellRowId: row.id,
      selectedRowsIds: {
        [row.id]: saveSelectedRow(row),
        ...rows,
      },
    };
  }

  if (action.type === actions.saveLeaveFilterCellsIds) {
    const { startCell, endCell } = action;

    const { getCellsBetweenId } = instance;

    const newLeaveFilterRowCellsIds = getCellsBetweenId(startCell, endCell);

    return {
      ...state,
      leaveFilterRowCellsIds: newLeaveFilterRowCellsIds,
    };
  }

  if (action.type === actions.deselectCells) {
    const { startCell, endCell, event } = action;

    const { getCellsBetweenId } = instance;

    const cellsInRow = getCellsBetweenId(startCell, endCell);
    const newState = Object.assign(state.selectedCellIds, {});

    if ((event.target as HTMLElement).tagName === 'INPUT') {
      const keys = Object.keys(cellsInRow);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if ((event.target as HTMLInputElement).checked) {
          newState[key] = true;
        } else {
          delete newState[key];
        }
      }
    }

    return {
      ...state,
      selectedCellIds: newState,
      isSelectingCells: false,
      currentSelectedCellIds: {},
      startCellSelection: null,
      endCellSelection: null,
    };
  }

  if (action.type === actions.selectCellsArr) {
    const { startCell, endCell } = action;

    const { getCellsBetweenId } = instance;

    const cellsInRow = getCellsBetweenId(startCell, endCell);

    return {
      ...state,
      selectedCellIds: cellsInRow,
      isSelectingCells: false,
      currentSelectedCellIds: cellsInRow,
      startCellSelection: startCell,
      endCellSelection: endCell,
    };
  }

  if (action.type === actions.clearState) {
    return {
      ...state,
      selectedCellIds: {},
      isSelectingCells: false,
      currentSelectedCellIds: {},
      startCellSelection: null,
      endCellSelection: null,
      currentFilterRowCellsSelectedIds: {},
      cellRangeSelecting: {},
      freezedSelectedCellIds: {},
    };
  }

  if (action.type === actions.selectAllRowsAllCells) {
    const { getCellsBetweenId } = instance;

    const startCell = instance.rows[0].allCells[1].id;
    const endCell = instance.rows.at(-1)?.allCells.at(-1)?.id || startCell;

    const newSelectedCellIds = getCellsBetweenId(startCell, endCell);

    return {
      ...state,
      selectedCellIds: newSelectedCellIds,
      isSelectingCells: false,
      currentSelectedCellIds: {},
      startCellSelection: null,
      endCellSelection: null,
    };
  }

  if (action.type === actions.setCurrentSelectedCellIds) {
    const { selectedCellIds } = action;

    return {
      ...state,
      currentSelectedCellIds: selectedCellIds,
    };
  }

  if (action.type === actions.selectProvidedRowsCells) {
    const { selectedCellIds } = action;

    return {
      ...state,
      selectedCellIds: selectedCellIds,
      isSelectingCells: false,
      currentSelectedCellIds: selectedCellIds,
    };
  }

  return undefined;
}

function useInstance(instance: TableInstance<DataType>) {
  const { dispatch, allColumns, rows, state } = instance;

  const cellsById: Record<string, Cell> = useMemo(() => ({}), []);

  const defaultCellIdSplitBy = '_';
  const cellIdSplitBy = instance.cellIdSplitBy || defaultCellIdSplitBy;
  Object.assign(instance, { cellIdSplitBy });

  const setSelectedCellIds = useCallback(
    (selectedCellIds: Record<string, boolean>) =>
      dispatch({
        type: actions.setSelectedCellIds,
        selectedCellIds,
      }),
    [dispatch]
  );

  const setCurrentSelectedCellIds = useCallback(
    (selectedCellIds: Record<string, boolean>) =>
      dispatch({
        type: actions.setCurrentSelectedCellIds,
        selectedCellIds,
      }),
    [dispatch]
  );

  const getCellsBetweenId = useCallback(
    (startCell: string, endCell: string) => {
      if (!cellsById[startCell] || !cellsById[endCell]) return null;

      const rowsIndex = [cellsById[startCell].row.index, cellsById[endCell].row.index];
      const columnsIndex: number[] = [];
      allColumns.forEach((col, index) => {
        if (col.id === cellsById[startCell].column.id || col.id === cellsById[endCell].column.id) {
          columnsIndex.push(index);
        }
      });

      const selectedColumns = [];
      const selectedRows = [];
      for (let i = Math.min(...columnsIndex); i <= Math.max(...columnsIndex); i += 1) {
        selectedColumns.push(allColumns[i].id);
      }
      for (let i = Math.min(...rowsIndex); i <= Math.max(...rowsIndex); i += 1) {
        selectedRows.push(rows[i].index);
      }

      const cellsBetween: Record<string, boolean> = {};

      if (selectedRows.length && selectedColumns.length) {
        for (let i = 0; i < selectedRows.length; i += 1) {
          for (let j = 0; j < selectedColumns.length; j += 1) {
            const id = `${selectedColumns[j]}${cellIdSplitBy}${selectedRows[i]}`;
            const cell = cellsById[id];
            cellsBetween[cell.id] = true;
          }
        }
      }

      return cellsBetween;
    },
    [allColumns, cellsById, cellIdSplitBy, rows]
  );

  const saveSelectedRow = (row: Row<DataType>) => ({
    _key: row.original._key,
    _t: row.original._t,
    allCells: row.allCells.map((cell) => cell.id).filter((name) => !name.includes('filter')),
  });

  const getRowsBetweenId = useCallback(
    (startCell: string, endCell: string) => {
      if (!cellsById[startCell] || !cellsById[endCell]) return null;

      const rowsIndex = [cellsById[startCell].row.index, cellsById[endCell].row.index];
      const columnsIndex: number[] = [];
      allColumns.forEach((col, index) => {
        if (col.id === cellsById[startCell].column.id || col.id === cellsById[endCell].column.id) {
          columnsIndex.push(index);
        }
      });

      const selectedColumns = [];
      const selectedRows = [];
      for (let i = Math.min(...columnsIndex); i <= Math.max(...columnsIndex); i += 1) {
        selectedColumns.push(allColumns[i].id);
      }
      for (let i = Math.min(...rowsIndex); i <= Math.max(...rowsIndex); i += 1) {
        selectedRows.push(rows[i].index);
      }

      const rowsBetween: SelectedRowsIdsType = {};

      if (selectedRows.length && selectedColumns.length) {
        for (let i = 0; i < selectedRows.length; i += 1) {
          for (let j = 0; j < selectedColumns.length; j += 1) {
            const id = `${selectedColumns[j]}${cellIdSplitBy}${selectedRows[i]}`;
            const cell: Cell<DataType> = cellsById[id];
            rowsBetween[cell.row.id] = saveSelectedRow(cell.row);
          }
        }
      }

      return rowsBetween;
    },
    [allColumns, cellsById, cellIdSplitBy, rows]
  );

  const rewriteActiveRowStartCell = (cellId: string) => {
    state.activeRowStartCell = cellId;
  };

  const selectNewCellsArr = (startCell: string, endCell: string) => {
    dispatch({ type: actions.selectCellsArr, startCell, endCell });
  };

  const clearCellSelectionState = () => {
    dispatch({ type: actions.clearState });
  };

  const selectAllRowsAllCells = () => {
    dispatch({ type: actions.selectAllRowsAllCells });
  };

  const changeSelectedCell = (cells: Record<string, boolean>) => {
    state.selectedCellIds = cells;
  };

  const selectProvidedRowsCells = (passedRows: Row<DataType>[]) => {
    const selectedCells = passedRows.reduce(
      (accum, currVal) => {
        currVal.allCells.forEach((cell) => {
          if (!cell.id.includes(ColumnNameType.FILTER)) {
            accum[cell.id] = true;
          }
        });

        return accum;
      },
      {} as Record<string, boolean>
    );

    dispatch({ type: actions.selectProvidedRowsCells, selectedCellIds: selectedCells });
  };

  Object.assign(instance, {
    getCellsBetweenId,
    getRowsBetweenId,
    cellsById,
    setSelectedCellIds,
    setCurrentSelectedCellIds,
    rewriteActiveRowStartCell,
    selectNewCellsArr,
    clearCellSelectionState,
    selectAllRowsAllCells,
    saveSelectedRow,
    changeSelectedCell,
    selectProvidedRowsCells,
  });
}

function prepareRow(
  row: Row<DataType>,
  { instance: { cellsById, cellIdSplitBy }, instance }: { instance: TableInstance<DataType> }
) {
  row.allCells.forEach((cell) => {
    Object.assign(cell, {
      id: `${cell.column.id}${cellIdSplitBy}${row.index}`,
      getCellRangeSelectionProps: makePropGetter(instance.getHooks().getCellRangeSelectionProps as Hooks, {
        instance: instance,
        row,
        cell,
      }),
    });
    Object.assign(cellsById, { [cell.id]: cell });
  });
}

export const useCellRangeSelection = (hooks: Hooks) => {
  Object.assign(hooks, {
    getCellRangeSelectionProps: [defaultgetCellRangeSelectionProps],
  });
  hooks.stateReducers.push(reducer as never);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useCellRangeSelection.pluginName = 'useCellRangeSelection';
