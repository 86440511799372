import { SchemaTypeElementsModel } from 'api';
import i18n from 'i18n';

import { EditorValidationType, EditorValidationRuleType } from '../type';
import { isRequiredValidation, isRequiredSchema } from './isRequired';

export const validationRules = (): Record<EditorValidationType, EditorValidationRuleType> => ({
  [EditorValidationType.IsRequired]: {
    validate: isRequiredValidation,
    schemaRule: isRequiredSchema,
    message: i18n.t('validationMessage.fieldRequired'),
  },
});

export const getValidationRulesBySchema = (element: SchemaTypeElementsModel): EditorValidationType[] =>
  Object.values(validationRules()).reduce((acc, validationItem) => {
    const ruleName = validationItem.schemaRule(element);
    if (ruleName) acc.push(ruleName);

    return acc;
  }, [] as EditorValidationType[]);
