import { ProfileStructureDto } from '../dto';
import { ProfileModel } from './profile.model';

export type ProfilesStructureModel = Record<string, ProfileModel[]>;

function createProfileStructureModel(dto: ProfileStructureDto): ProfilesStructureModel {
  return dto.reduce((acc, profile) => ({ ...acc, [profile.Name]: profile.Profiles?.map(ProfileModel.create) }), {});
}

export const ProfilesStructureModel = {
  create: createProfileStructureModel,
};
