import { ReactPortal, useId, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { createPortal } from 'react-dom';

import { SimpleViewModel } from 'api';
import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { getSchemaTypeListItem, SchemaListType } from 'view/Editor/helpers/getCellProps.helper';
import { Grid } from 'view/Grid';
import { DataType, GridModeEnum } from 'view/Grid/utils/types';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { SimpleVectorCell } from 'view/Editor/components/TableBody/TableBodyCell/SimpleVectorCell';
import { ArrayToolbar } from 'view/Editor/components/EditorView/ArrayToolbar';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

type ComplexVectorSimpleListProps = {
  tab: EditorTabType;
  data: SimpleViewModel[];
};

export const ComplexVectorSimpleList = ({ data, tab }: ComplexVectorSimpleListProps) => {
  const id = useId();
  const { state } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Record<string, boolean>>({});

  const { memoColumns, memoData } = useMemo(() => {
    const colProps = {
      Header: tab.title,
      accessor: (originalRow) => originalRow[tab.title],
      Cell: SimpleVectorCell,
      minWidth: 325,
    } as Column<DataType>;

    const gridColumns = [
      {
        schemaType: getSchemaTypeListItem(tab.schemaType as SchemaListType),
        keyName: tab.keyName,
        keyModuleName: tab.keyModuleName,
        path: tab.path,
        ...colProps,
      },
    ];

    const gridData =
      data?.map((value) => ({
        [tab.title]: value,
      })) || [];

    return {
      memoColumns: gridColumns,
      memoData: gridData,
    };
  }, [data]);

  const renderPortalToolbar = (): ReactPortal | null => {
    if (!state.editorToolbarRef || !tabState.isActive || state.isReadOnly) return null;

    return createPortal(
      <ArrayToolbar
        tab={tab}
        arrayLength={memoData.length}
        selectedIndex={selectedIndex}
        selectedGroup={selectedCheckbox}
        onSelectedGroupChange={setSelectedCheckbox}
        onSelectedIndexChange={setSelectedIndex}
      />,
      state.editorToolbarRef
    );
  };

  return (
    <div>
      {renderPortalToolbar()}
      <Grid
        useCheckboxes
        gridId={id}
        gridMode={GridModeEnum.EDITOR}
        columns={memoColumns}
        data={memoData}
        selectedRowId={selectedIndex.toString()}
        selectedCheckbox={selectedCheckbox}
        onSelectedCheckboxChange={setSelectedCheckbox}
        onChangeSelectedRowId={(rowId) => setSelectedIndex(+rowId)}
      />
    </div>
  );
};
