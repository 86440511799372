import './styles.scss';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { isDateType, isObject, ValueTypesEnum } from 'utils';
import { ValueTypeModel } from 'api';
import { UIInput, UIInputNumber } from 'ui';
import {
  VariantSelect,
  VariantSelectDataType,
  VariantType,
} from 'view/Editor/components/TableBody/TableBodyCell/VariantSelect/VariantSelect.component';
import { GenericKeySelect } from 'view/Editor/components/TableBody/TableBodyCell/GenericKeySelect';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';
import { CellSchemaType, SchemaType } from 'view/Editor/helpers/getCellProps.helper';
import { KeySelect } from 'view/Editor/components/TableBody/TableBodyCell/KeySelect/KeySelect';
import { GenericDataValue } from 'view/Editor/components/TableBody/TableBodyCell/GenericDataValue/GenericDataValue.component';
import { cleanTypeHelper } from 'utils/helpers/cleanType.helper';
import { FlatWithType } from 'view/Editor/context/editorContext/type';
import { SuccessorValue } from 'view/Editor/components/TableBody/TableBodyCell/SuccessorValue/SuccessorValue.component';
import { useValidationError } from 'view/Editor/validation/hook/validationError.hook';
import { EditorErrorTooltip } from 'view/Editor/validation/component/EditorErrorTooltip';

import { BooleanValue } from './BooleanValue';
import { EnumValue } from './EnumValue';
import { DateValue } from './DateValue';
import { StringValue } from './StringValue';
import { BinaryValue, BinaryValueDataType } from './BinaryValue';

export type TableBodyCellProps = {
  path?: string[];
  elementName: string;
  originalValue: unknown;
  keyName?: string;
  keyModuleName?: string;
  enumSelector?: string;
  valueType?: ValueTypeModel;
  schemaType?: CellSchemaType;
  successor?: string;
  isViewOnly?: boolean;
  isRequired: boolean;
  isReadOnly: boolean;
  isFlatCell?: boolean;
  isVector: boolean;
  prefixElement?: ReactNode;
  suffixElement?: ReactNode;
  onChange: (updatedValue: unknown) => void;
};

export const TableBodyCell: FC<TableBodyCellProps> = ({
  path,
  schemaType,
  keyModuleName,
  keyName,
  elementName,
  originalValue,
  successor,
  valueType,
  isViewOnly = false,
  prefixElement,
  suffixElement,
  enumSelector,
  isRequired,
  isReadOnly,
  onChange,
  isFlatCell,
  isVector,
}) => {
  const isTempChanged = useRef(false);
  const [tempValue, setTempValue] = useState<unknown>(originalValue || '');
  const { errorList } = useValidationError({ path });

  const isErrorExist = errorList.length > 0;

  useEffect(() => {
    setTempValue(originalValue || '');
  }, [originalValue]);

  const handleSetTempValue = (value: unknown) => {
    isTempChanged.current = true;
    setTempValue(value);
  };

  const isReadOnlyCell = isReadOnly || isViewOnly;

  const handleChangeOriginValue = () => {
    if (!isReadOnlyCell && isTempChanged.current) {
      onChange(tempValue);
      isTempChanged.current = false;
    }
  };

  const renderInputContent = () => {
    if (schemaType === SchemaType.BinaryContent) {
      return (
        <BinaryValue
          value={originalValue as BinaryValueDataType[]}
          isMultiply={isVector}
          isReadOnly={isReadOnlyCell}
          onChange={onChange}
        />
      );
    }

    if (schemaType === SchemaType.Successor && successor) {
      const successorDataValue = originalValue as FlatWithType;
      const currentSuccessor = cleanTypeHelper(successorDataValue?._t);

      return (
        <SuccessorValue
          schemaSuccessor={successor}
          value={dataToString(originalValue)}
          currentSuccessor={currentSuccessor}
          onChange={onChange}
        />
      );
    }

    if (schemaType === SchemaType.SimpleKey) {
      return (
        <KeySelect
          isReadOnly={isReadOnlyCell}
          isVector={isVector}
          schemaName={keyName}
          schemaModuleName={keyModuleName}
          value={tempValue as string}
          onChange={onChange}
          onChangeInput={handleSetTempValue}
        />
      );
    }

    if (valueType === ValueTypesEnum.Dict) {
      return <UIInput variant="transparent" selectOnFocus readOnly ellipsis value={dataToString(originalValue)} />;
    }

    if (schemaType === SchemaType.GenericData) {
      const genericDataValue = originalValue as FlatWithType;
      const genericDataType = cleanTypeHelper(genericDataValue?._t);

      return (
        <GenericDataValue
          variant="transparent"
          drillPath={isVector ? path : undefined}
          type={genericDataType}
          value={genericDataValue}
          onChange={onChange}
        />
      );
    }

    if (schemaType === SchemaType.GenericKey) {
      return (
        <GenericKeySelect
          isReadOnly={isReadOnlyCell}
          prefixElement={prefixElement}
          value={(originalValue as string) || ''}
          onChange={onChange}
        />
      );
    }

    if (schemaType === SchemaType.Variant) {
      const variantType = isObject(originalValue) ? Object.keys(originalValue)?.[0] : null;
      const variantValue = variantType ? (originalValue as VariantSelectDataType)?.[variantType] : null;

      return (
        <VariantSelect
          selectOnFocus
          isReadOnly={isReadOnly}
          variantType={variantType as VariantType}
          value={variantValue}
          onChange={onChange}
        />
      );
    }

    if (valueType === ValueTypesEnum.String) {
      return (
        <StringValue
          selectOnFocus
          deselectOnEsc
          blurOnEnter
          variant="transparent"
          useTabEditor
          path={path}
          valueType={valueType}
          elementName={elementName}
          value={tempValue as string}
          onChange={handleSetTempValue}
          onBlur={handleChangeOriginValue}
          readOnly={isReadOnlyCell}
        />
      );
    }

    if (valueType === ValueTypesEnum.Bool) {
      return (
        <BooleanValue
          value={originalValue as boolean}
          onChange={onChange}
          isDisable={isReadOnlyCell}
          isRequired={isRequired}
        />
      );
    }

    if (schemaType === SchemaType.Enum) {
      return (
        <EnumValue
          value={originalValue as string}
          onChange={onChange}
          isDisable={isReadOnlyCell}
          enumSelector={enumSelector}
        />
      );
    }

    if (isDateType(valueType) && !isViewOnly) {
      return (
        <DateValue
          readOnly={isReadOnlyCell}
          value={tempValue as string}
          valueType={valueType}
          onInputChange={handleSetTempValue}
          onPickerChange={onChange}
          onBlur={handleChangeOriginValue}
        />
      );
    }

    if ((valueType === ValueTypesEnum.Int || valueType === VariantType.Double) && !isViewOnly) {
      return (
        <UIInputNumber
          variant="transparent"
          selectOnFocus
          deselectOnEsc
          blurOnEnter
          highlightNegativeNumbers
          value={originalValue as number}
          textAlign={!isFlatCell ? 'right' : undefined}
          readOnly={isReadOnlyCell}
          prefixElement={prefixElement}
          onChange={handleSetTempValue}
          onBlur={handleChangeOriginValue}
        />
      );
    }

    if (isViewOnly) {
      return (
        <UIInput
          selectOnFocus
          value={dataToString(originalValue)}
          variant="transparent"
          readOnly={isReadOnlyCell}
          prefixElement={prefixElement}
          suffixElement={suffixElement}
        />
      );
    }

    return (
      <UIInput
        selectOnFocus
        deselectOnEsc
        blurOnEnter
        value={tempValue}
        variant="transparent"
        readOnly={isReadOnlyCell}
        prefixElement={prefixElement}
        suffixElement={suffixElement}
        onChange={(e) => handleSetTempValue(e.target.value)}
        onBlur={handleChangeOriginValue}
      />
    );
  };

  return (
    <EditorErrorTooltip errors={errorList}>
      <div className={cx('table-body-cell', { 'table-body-cell__error': isErrorExist })}>{renderInputContent()}</div>
    </EditorErrorTooltip>
  );
};
