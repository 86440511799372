import { Row } from 'react-table';
import { ChangeEvent, FC } from 'react';

import { DataType } from 'view/Grid/utils/types';

export type CheckboxSelection = {
  row: Row<DataType>;
  selectedRows: Record<string, boolean>;
  onSelectedCheckboxChange?: (selection: Record<string, boolean>) => void;
};

export const CheckboxSelection: FC<CheckboxSelection> = ({ row, selectedRows, onSelectedCheckboxChange }) => {
  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      selectedRows[row.index] = true;
    }

    if (!e.target.checked) {
      delete selectedRows[row.index];
    }

    onSelectedCheckboxChange?.(selectedRows);
  };

  return (
    <input className="form-check-input" type="checkbox" onChange={handleSelect} checked={selectedRows[row.index]} />
  );
};
