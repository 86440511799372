import cx from 'classnames';
import { FC, useCallback, useContext, useEffect, useRef } from 'react';

import { GridTabDataType } from 'store/types';
import { reorderTabs } from 'shared/Tabs/utils/helpers';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';

import { DnDContext } from '../DraggableContainer';

import { filterConfigContent } from '../utils/helpers/filterConfigContent';
import { ConfigType, LayoutType } from '../utils/types';

import styles from './styles.module.scss';
import { correctContent } from '../utils/helpers/correctContent';
import { findParent } from '../utils/helpers/findParent';
import { DefaultPanelSize, StackEnum } from '../utils/constants';
import { findTarget } from '../utils/helpers/findTarget';
import { isGridTabDataType } from '../utils/guards/isGridTabDataType';
import { setActiveFalse } from '../utils/helpers/setActiveFalse';
import { setDraggingTabUndragging } from '../utils/helpers/setDraggingTabUndragging';
import { isConfigType } from '../utils/guards/isConfigType';

export type DnDDroppableProps = {
  children?: React.ReactNode;
  isAbsolute?: boolean;
  isTabs?: boolean;
  isTop?: boolean;
  isBottom?: boolean;
  isLeft?: boolean;
  isRight?: boolean;
  isSingle?: boolean;
  isMaxTop?: boolean;
  isMaxBottom?: boolean;
  isMaxLeft?: boolean;
  isMaxRight?: boolean;
  stackRef?: ConfigType | GridTabDataType;
};

export const DnDDroppable: FC<DnDDroppableProps> = ({
  children,
  isAbsolute,
  isTabs,
  isTop,
  isBottom,
  isLeft,
  isRight,
  isSingle,
  isMaxTop,
  isMaxBottom,
  isMaxLeft,
  isMaxRight,
  stackRef,
}) => {
  const droppableAreaRef = useRef<HTMLDivElement | null>(null);

  const {
    config,
    draggingLabel,
    dropMarker,
    dropPreview,
    endTabIndex,
    isDragging,
    leftMouseBtnClicked,
    startTabIndex,
    setConfig,
    setDraggingLabel,
    setDropZone,
    setEndTabIndex,
    setIsDragging,
    setTabsZone,
    setTargetStackRef,
    selectedGridRow,
    managerRef,
    initialTabsZone,
    saveLayout,
  } = useContext(DnDContext);

  useEffect(() => {
    if (droppableAreaRef.current?.dataset.position === 'max-top' && managerRef.current) {
      const firstStackRect = managerRef.current.querySelector('.stack-content')?.getBoundingClientRect();

      droppableAreaRef.current.style.top = `${firstStackRect?.top}px`;
    }
  }, [config, isDragging]);

  const handleMouseEnter = useCallback((): void => {
    if (!leftMouseBtnClicked) {
      return;
    }

    if (!droppableAreaRef.current || !dropMarker.current || !dropPreview.current) {
      return;
    }

    if (droppableAreaRef.current.classList.contains('tabs')) {
      dropPreview.current.classList.add('d-none');
      const { childElementCount } = droppableAreaRef.current;

      const tabExistinStack =
        stackRef && isConfigType(stackRef) && stackRef.content.some((tab) => tab.id === draggingLabel?.id);

      setEndTabIndex(tabExistinStack ? childElementCount - 1 : childElementCount);
      setTabsZone(droppableAreaRef.current);
      setTargetStackRef(stackRef);
      dropMarker.current.classList.add('d-none');
      return;
    }

    dropMarker.current.classList.remove('d-none');
    dropPreview.current.classList.remove('d-none');

    const droppableAreaRefRect = droppableAreaRef.current.getBoundingClientRect();
    const parentElementRect = droppableAreaRef.current.parentElement!.getBoundingClientRect();

    dropMarker.current.style.top = `${droppableAreaRefRect.top}px`;

    if (droppableAreaRef.current.classList.contains('right')) {
      dropMarker.current.style.left = `${parentElementRect.right - parentElementRect.width / 2}px`;
    } else {
      dropMarker.current.style.left = `${droppableAreaRefRect.left}px`;
    }

    if (droppableAreaRef.current.classList.contains('left') || droppableAreaRef.current.classList.contains('right')) {
      dropMarker.current.style.width = `${parentElementRect.width / 2}px`;
    } else {
      dropMarker.current.style.width = droppableAreaRef.current.classList.contains('single')
        ? '100%'
        : `${droppableAreaRefRect.width}px`;
    }

    dropMarker.current.style.height = `${droppableAreaRefRect.height}px`;

    setDropZone(droppableAreaRef.current);
  }, [isDragging, draggingLabel, leftMouseBtnClicked]);

  const toggleDroppableZones = useCallback(() => (isDragging ? 'd-flex' : 'd-none'), [isDragging]);

  const handleMouseUp = useCallback(() => {
    if (!isDragging || !dropPreview.current) return;

    const configCopy = JSON.parse(JSON.stringify(config)) as ConfigType;

    const configCopyParent = findParent(configCopy, draggingLabel!.id) as ConfigType;

    if (draggingLabel?.isMainGridTab) {
      configCopyParent.isMainGrid = false;
    }

    if (
      droppableAreaRef.current?.dataset.position === 'left' ||
      droppableAreaRef.current?.dataset.position === 'right' ||
      droppableAreaRef.current?.dataset.position === 'top' ||
      droppableAreaRef.current?.dataset.position === 'bottom' ||
      droppableAreaRef.current?.dataset.position === 'max-left' ||
      droppableAreaRef.current?.dataset.position === 'max-right' ||
      droppableAreaRef.current?.dataset.position === 'max-bottom' ||
      droppableAreaRef.current?.dataset.position === 'max-top'
    ) {
      const filteredInitialConfig = filterConfigContent(configCopy, draggingLabel!) as ConfigType;

      filteredInitialConfig.key = `${filteredInitialConfig.type}-${(
        filteredInitialConfig.content[0] as GridTabDataType
      )?.key.replace(/\s/g, '')}`;

      filteredInitialConfig.id = `${filteredInitialConfig.type}-${(
        filteredInitialConfig.content[0] as GridTabDataType
      )?.key.replace(/\s/g, '')}`;

      if (draggingLabel?.isMainGridTab) {
        filteredInitialConfig.isMainGrid = false;
      }

      if (draggingLabel?.isMainGridTab) {
        filteredInitialConfig.content.forEach((el) => {
          const elCopy = el;
          (elCopy as ConfigType).isMainGrid = false;
        });
      }

      draggingLabel!.isActive = true;

      const newStack = {
        type: StackEnum.STACK,
        key: `stack-${draggingLabel!.key.replace(/\s/g, '')}`,
        id: `stack-${draggingLabel!.key.replace(/\s/g, '')}`,
        isMainGrid: draggingLabel?.isMainGridTab || false,
        content: [draggingLabel],
      };

      const isRow =
        droppableAreaRef.current?.dataset.position === 'right' ||
        droppableAreaRef.current?.dataset.position === 'left' ||
        droppableAreaRef.current?.dataset.position === 'max-left' ||
        droppableAreaRef.current?.dataset.position === 'max-right';

      const newEl: ConfigType = {
        type: `${isRow ? StackEnum.ROW : StackEnum.COLUMN}`,
        key: `${isRow ? StackEnum.ROW : StackEnum.COLUMN}-1`,
        id: `${isRow ? StackEnum.ROW : StackEnum.COLUMN}-1`,
        isMainGrid: false,
        size: DefaultPanelSize.DEFAULT,
        content: [],
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (filteredInitialConfig.type === StackEnum.ROW || filteredInitialConfig.type === StackEnum.COLUMN) {
        const parent = findParent(filteredInitialConfig, stackRef!.key) || stackRef;

        const targetStackIndex = (parent as ConfigType).content.findIndex(
          (el) => (el as ConfigType)!.id === stackRef!.key
        );

        newEl.content = [
          ...(droppableAreaRef.current?.dataset.position === 'left' ||
          droppableAreaRef.current?.dataset.position === 'max-left' ||
          droppableAreaRef.current?.dataset.position === 'top' ||
          droppableAreaRef.current?.dataset.position === 'max-top'
            ? [
                newStack as ConfigType,
                targetStackIndex >= 0 ? (parent as ConfigType).content[targetStackIndex] : filteredInitialConfig,
              ]
            : [
                targetStackIndex >= 0 ? (parent as ConfigType).content[targetStackIndex] : filteredInitialConfig,
                newStack as ConfigType,
              ]),
        ];

        if (targetStackIndex >= 0) {
          (parent as ConfigType).content.splice(targetStackIndex, 1, newEl);
        }

        newEl.key = `${newEl.type}-${(newEl.content[0] as ConfigType).key.replace(/\s/g, '')}`;
        newEl.id = `${newEl.type}-${(newEl.content[0] as ConfigType).key.replace(/\s/g, '')}`;

        const correctedFilteredConfig = correctContent(
          targetStackIndex >= 0 ? filteredInitialConfig : newEl
        ) as ConfigType;

        correctedFilteredConfig.key = `${correctedFilteredConfig.type}-${correctedFilteredConfig.content[0].key.replace(
          /\s/g,
          ''
        )}`;
        correctedFilteredConfig.id = `${correctedFilteredConfig.type}-${correctedFilteredConfig.content[0].key.replace(
          /\s/g,
          ''
        )}`;

        const simplifiedConfig = getParsedConfig(correctedFilteredConfig, true) as LayoutType;

        setConfig(correctedFilteredConfig);
        saveLayout(simplifiedConfig);
      } else {
        newEl.content = [
          ...(droppableAreaRef.current?.dataset.position === 'left' ||
          droppableAreaRef.current?.dataset.position === 'max-left' ||
          droppableAreaRef.current?.dataset.position === 'top' ||
          droppableAreaRef.current?.dataset.position === 'max-top'
            ? [newStack as ConfigType, filteredInitialConfig]
            : [filteredInitialConfig, newStack as ConfigType]),
        ];

        correctContent(newEl);

        newEl.key = `${isRow ? StackEnum.ROW : StackEnum.COLUMN}-${(newEl.content[0] as ConfigType).key.replace(
          /\s/g,
          ''
        )}`;
        newEl.id = `${isRow ? StackEnum.ROW : StackEnum.COLUMN}-${(newEl.content[0] as ConfigType).key.replace(
          /\s/g,
          ''
        )}`;

        const simplifiedConfig = getParsedConfig(newEl, true) as LayoutType;

        setConfig(newEl);
        saveLayout(simplifiedConfig);
      }
    }

    if (droppableAreaRef.current?.dataset.position === 'tabs') {
      const parent = findParent(configCopy, draggingLabel!.key);

      if (!parent) return;

      if (parent.key !== stackRef!.key) {
        const targetStack = findTarget(configCopy, stackRef!.key);

        if (!targetStack) return;

        targetStack.content.forEach((tab) => {
          const tabCopy = tab;
          if (isGridTabDataType(tabCopy)) {
            tabCopy.isActive = false;
          }
        });

        draggingLabel!.isActive = true;

        targetStack.isMainGrid = targetStack.isMainGrid || !!draggingLabel?.isMainGridTab;

        targetStack.content.splice(endTabIndex, 0, draggingLabel as GridTabDataType);

        (parent as ConfigType).content.splice(startTabIndex, 1);
      } else {
        // Drop that performed within same stack and tab is main grid
        if (draggingLabel?.isMainGridTab) {
          configCopyParent.isMainGrid = true;
        }

        setActiveFalse(configCopyParent, draggingLabel!);

        const reorderedTabs = reorderTabs(
          (parent as ConfigType).content as GridTabDataType[],
          startTabIndex,
          endTabIndex
        );

        (parent as ConfigType).content = reorderedTabs;
      }

      const correctedConfigCopy = correctContent(configCopy) as ConfigType;
      correctedConfigCopy.size = DefaultPanelSize.FULL_VIEW;

      setDraggingTabUndragging(correctedConfigCopy, draggingLabel!);

      const simplifiedConfig = getParsedConfig(correctedConfigCopy, true) as LayoutType;

      setConfig(correctedConfigCopy);
      saveLayout(simplifiedConfig);

      let child = droppableAreaRef.current.firstChild as HTMLElement;

      while (child) {
        child.removeAttribute('style');
        child = child.nextSibling as HTMLElement;
      }

      if (droppableAreaRef.current !== initialTabsZone) {
        let initialTabsZoneChild = initialTabsZone?.firstChild as HTMLElement | null | undefined;

        while (initialTabsZoneChild) {
          initialTabsZoneChild.removeAttribute('style');
          initialTabsZoneChild = initialTabsZoneChild.nextSibling as HTMLElement;
        }
      }
    }

    dropPreview.current.classList.add('d-none');
    dropPreview.current.removeAttribute('style');

    setIsDragging(false);
    setDraggingLabel(null);
  }, [
    config,
    draggingLabel,
    isDragging,
    startTabIndex,
    endTabIndex,
    leftMouseBtnClicked,
    selectedGridRow,
    initialTabsZone,
  ]);

  const processMouseDown = () => {
    setTabsZone(droppableAreaRef.current);
    setTargetStackRef(stackRef);
  };

  const processMouseLeave = useCallback(
    (el: HTMLElement | null) => {
      const isElDraggingOutOfTabs = !el || !isDragging || el.dataset.position !== 'tabs';

      const isElDraggingWithinSameStackTabs =
        isElDraggingOutOfTabs || (stackRef as ConfigType).content.find((stack) => stack.id === draggingLabel?.id);

      if (isElDraggingWithinSameStackTabs) return;

      let child = el.firstChild as HTMLElement;

      while (child) {
        child.style.transform = 'translate(0px, 0px)';

        child = child.nextSibling as HTMLElement;
      }
    },
    [stackRef, isDragging, draggingLabel]
  );

  return (
    <div
      ref={droppableAreaRef}
      className={cx(
        'droppable',
        isTabs ? 'd-flex w-100' : toggleDroppableZones(),
        isAbsolute && 'position-absolute',
        { tabs: isTabs },
        isTop && styles.top,
        isBottom && styles.bottom,
        { left: isLeft, [styles.left]: isLeft },
        { right: isRight, [styles.right]: isRight },
        { single: isSingle },
        isSingle && 'w-100 h-100',
        { maxTop: isMaxTop, [styles.maxTop]: isMaxTop },
        { maxBottom: isMaxBottom, [styles.maxBottom]: isMaxBottom },
        { maxLeft: isMaxLeft, [styles.maxLeft]: isMaxLeft },
        { maxRight: isMaxRight, [styles.maxRight]: isMaxRight },
        styles.droppable
      )}
      data-position={
        (isRight && 'right') ||
        (isLeft && 'left') ||
        (isTop && 'top') ||
        (isBottom && 'bottom') ||
        (isTabs && 'tabs') ||
        (isSingle && 'single') ||
        (isMaxTop && 'max-top') ||
        (isMaxBottom && 'max-bottom') ||
        (isMaxLeft && 'max-left') ||
        (isMaxRight && 'max-right')
      }
      onMouseDown={processMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => processMouseLeave(droppableAreaRef.current)}
    >
      {children}
    </div>
  );
};
