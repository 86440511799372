import { handlerApi } from 'api';

import { SessionType } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  table: string;
  datasource: string;
  dataset: string;
  chatKey: string;
};

type RawSessionType = SessionType & {
  _t: string;
};

const mapRawData = (rawSessions: Array<RawSessionType>): SessionType[] =>
  rawSessions.map((rawSession) => ({
    ChatKey: rawSession.ChatKey,
    Created: rawSession.Created,
    IsEncrypted: rawSession.IsEncrypted,
    Profile: rawSession.Profile,
    Name: rawSession.Name,
  }));

export const getChatSessionsRequest = ({ key, table, dataset, datasource, chatKey }: RequestParamsType) =>
  handlerApi
    .run<Array<RawSessionType>>({
      key,
      table,
      dataset,
      method: 'GetChatSessions',
      dataSource: datasource,
      args: { CurrentChat: chatKey },
    })
    .then(mapRawData);
