import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { UiModal } from 'ui';
import { StorageEntityModel } from 'api';
import { EditorNewRecord } from 'view/Editor/EditorOption/EditorNewRecord';

type KeySelectCopyModalProps = {
  isOpen: boolean;
  schemaName: string;
  data?: StorageEntityModel;
  onClose: () => void;
  onSubmit?: (key: string) => void;
};

export const KeySelectCopyModal: FC<KeySelectCopyModalProps> = ({ isOpen, onClose, schemaName, data, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <UiModal isOpen={isOpen} defaultHeight={450} title={t('editor.copyEntity')} onClose={onClose}>
      <UiModal.Body className="overflow-auto">
        <EditorNewRecord recordType={schemaName} editorData={data} onClose={onClose} onSubmit={onSubmit} />
      </UiModal.Body>
    </UiModal>
  );
};
