import { Input } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';

import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { ViewOfType } from 'store/types';
import { EntityPanelViewOfModel } from 'api';

import { filterViewersHelper } from '../../utils/helpers';

import styles from './styles.module.scss';

type SearchBarProps = {
  noFilteredViewers: EntityPanelViewOfModel[];
  setViewersList: Dispatch<SetStateAction<ViewOfType[]>>;
};

export const SearchBar: FC<SearchBarProps> = ({ noFilteredViewers, setViewersList }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const fiteredViewers = filterViewersHelper(noFilteredViewers, value);
    setInputValue(value);
    setViewersList(fiteredViewers);
  };

  const handleClearInput = () => {
    setInputValue('');
    setViewersList(noFilteredViewers);
  };

  return (
    <div className={cx(`d-flex align-items-center w-100`, styles.searchContainer)}>
      <div className="position-relative d-flex align-items-center w-100">
        <Icon clickable={false} SvgIcon={APP_ICONS.search} className={styles.searchIcon} />
        <Input
          placeholder={t('grid.columnFilter.filterPlaceholder') || ''}
          value={inputValue}
          onChange={handleChangeInput}
          className={cx(`w-100 fs-13 text-text1 c-pointer`, styles.input)}
        />
        {inputValue && (
          <Icon SvgIcon={APP_ICONS.cancelInCirlce} className={styles.cancelIcon} onClick={handleClearInput} />
        )}
      </div>
    </div>
  );
};
