import { FC } from 'react';
import ReactFlow, { Edge, Node, OnConnect, OnEdgesChange, OnNodesChange, OnSelectionChangeFunc } from 'reactflow';
import cx from 'classnames';

import 'reactflow/dist/style.css';
import styles from './DagViewer.module.scss';

type DagViewerProps = {
  className?: string;
  nodes: Node[];
  edges: Edge[];
  onNodesChange?: OnNodesChange;
  onEdgesChange?: OnEdgesChange;
  onConnect?: OnConnect;
  onSelectionChange?: OnSelectionChangeFunc;
};

const proOptions = { hideAttribution: true };

export const DagViewer: FC<DagViewerProps> = ({
  className,
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onSelectionChange,
}) => (
  <ReactFlow
    className={cx(styles.viewer, className)}
    nodes={nodes}
    edges={edges}
    onNodesChange={onNodesChange}
    onEdgesChange={onEdgesChange}
    onConnect={onConnect}
    onSelectionChange={onSelectionChange}
    proOptions={proOptions}
    fitView
  />
);
