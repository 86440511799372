import { StylesConfig } from 'react-select';

import { OptionType } from 'ui';

export const dropdownStyles: StylesConfig<OptionType<boolean | null>> = {
  control: () => ({
    minWidth: 0,
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
  }),
  option: () => ({
    height: 20,
  }),
};
