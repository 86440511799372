import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GridTabDataType } from 'store/types';
import { useLatest } from 'shared/Hooks/useLatest';
import { getViewerTypeHelper, isLoading, isNullable } from 'utils';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { selectActiveDataset } from 'store/selectors/dateset.selector';

import { useTabs } from '../useTabs';
import { useViewerData } from '../useViewerData';

export function useDashboard({ activeType }: { activeType: string }) {
  const storedActiveType = useLatest(activeType);
  const activeDataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);
  const lastKeyRef = useRef<string | undefined>();

  const { tabs, status: tabStatus, activeTab, loadTabs, activateTab, updateOrderTabs, reset: resetTabs } = useTabs();
  const { loadViewerData, data: viewerData, status: viewerDataStatus, reset: resetViewerData } = useViewerData();

  const activeViewerType = useMemo(() => getViewerTypeHelper(viewerData), [viewerData]);

  const loading = useMemo(() => isLoading([tabStatus, viewerDataStatus]), [tabStatus, viewerDataStatus]);

  const load = useCallback(
    (key?: string) => {
      lastKeyRef.current = key;

      return loadTabs(storedActiveType.current, key).then(({ activeTab: tab }) => {
        if (tab && !isNullable(activeDataset)) {
          loadViewerData(tab, storedActiveType.current, activeDataset, environment, key);
        }
      });
    },
    [loadTabs, storedActiveType, activeDataset, loadViewerData, environment]
  );

  const switchTab = useCallback(
    (tab: GridTabDataType) => {
      activateTab(tab.key);

      if (!isNullable(activeDataset)) {
        loadViewerData(tab.key, storedActiveType.current, activeDataset, environment, lastKeyRef.current);
      }
    },
    [activateTab, activeDataset, loadViewerData, environment, storedActiveType]
  );

  const reset = useCallback(() => {
    resetTabs();
    resetViewerData();
    lastKeyRef.current = undefined;
  }, [resetTabs, resetViewerData]);

  return {
    loading,
    loadingViewerData: isLoading([viewerDataStatus]),
    activeTab,
    tabs,
    activeViewerType,
    viewerData,
    load,
    updateOrderTabs,
    switchTab,
    reset,
  };
}
