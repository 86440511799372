import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPanelModel } from 'api';
import { Toolbar } from 'shared/Toolbar/Toolbar';
import { IconWithTooltip } from 'shared/IconWithTooltip/IconWithTooltip';
import { APP_ICONS } from 'utils/icons';

import './EditorContentToolbar.scss';
import { EditorContentJsonView } from './EditorContentJsonView';

type EditorToolbarProps = {
  panelData?: EntityPanelModel['ViewOf'];
  onRefresh?: () => void;
};

export const EditorContentToolbar: FC<EditorToolbarProps> = ({ panelData, onRefresh }) => {
  const { t } = useTranslation();

  return (
    <div className="editor-content-toolbar">
      <Toolbar>
        <Toolbar.Group>
          {onRefresh && (
            <IconWithTooltip onClick={onRefresh} SvgIcon={APP_ICONS.refresh} tooltip={t('editorToolbar.refresh')} />
          )}
          <EditorContentJsonView panelData={panelData} />
        </Toolbar.Group>
      </Toolbar>
    </div>
  );
};
