import { FC } from 'react';
import { Button } from 'reactstrap';

import { Icon } from 'shared/Icon';
import { UiModal } from 'ui/UiModal';
import { APP_ICONS } from 'utils/icons';

type ErrorModalProps = {
  isOpen: boolean;
  errors: string[];
  close: () => void;
};

export const ErrorModal: FC<ErrorModalProps> = ({ isOpen, errors, close }) => (
  <UiModal isOpen={isOpen} title="Uploading files error" onClose={close}>
    <UiModal.Body className="overflow-auto">
      <div className="p-2 pb-3 text-text4 fs-13">
        <div className="d-flex align-items-center gap-2 mb-3">
          <Icon clickable={false} SvgIcon={APP_ICONS.serverErrorMedium} />
          <p className="m-0">There were an errors processing your file:</p>
        </div>
        <ul className="m-0">
          {errors.map((error, index) => (
            <li key={index}>
              <p className="mb-1">{error}</p>
            </li>
          ))}
        </ul>
      </div>
    </UiModal.Body>
    <UiModal.Footer>
      <Button className="c-button-primary border-0 text-text2 fs-14 c-button" onClick={close}>
        Ok
      </Button>
    </UiModal.Footer>
  </UiModal>
);
