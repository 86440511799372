import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';
import { isConfigType } from '../guards/isConfigType';

export const filterConfigTransitionTabs = (passedConfig: ConfigType): ConfigType => {
  const passedConfigCopy = passedConfig;

  const filterConfig = (config: ConfigType): ConfigType => {
    if (!isConfigType(config) || config.content.length === 0) return config;

    if (config.type === 'stack') {
      // Filter transition tabs
      config.content = config.content.filter((tab_) => {
        const firstStackTab = config.content[0] as GridTabDataType;
        const tab = tab_ as GridTabDataType;

        const isTransitionTab = tab.id.includes(EditorTabsEnum.EDITOR_TRANSITION);

        // Change active tab to first in stack
        if (isTransitionTab && tab.isActive) {
          firstStackTab.isActive = true;
        }

        return !isTransitionTab;
      });
    } else {
      // Drill to children config items
      config.content = config.content.map((contentItem) => filterConfig(contentItem as ConfigType));
    }

    return config;
  };

  return filterConfig(passedConfigCopy);
};
