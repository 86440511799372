import { Input } from 'reactstrap';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type LogsHeaderSearchProps = {
  searchValue: string;
  onChangeSearchValue: Dispatch<SetStateAction<string>>;
};

export const LogsHeaderSearch = ({ searchValue, onChangeSearchValue }: LogsHeaderSearchProps) => {
  const { t } = useTranslation();

  const handleChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSearchValue(event.target.value);
  };

  const handleCleanSearch = () => onChangeSearchValue('');

  return (
    <div className="position-relative">
      <Icon clickable={false} SvgIcon={APP_ICONS.search} className="position-absolute ms-2 mt-1" />
      {searchValue && (
        <Icon SvgIcon={APP_ICONS.cancelInCirlce} className={styles.cirlceIcon} onClick={handleCleanSearch} />
      )}
      <Input
        placeholder={t('logs.search')}
        className={styles.searchInput}
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
    </div>
  );
};
