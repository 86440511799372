import { UIControlRuleType, UIControlType } from '../../types';

export function mapValuesToDictionary<T extends object, TValue>(
  values: T,
  dictionaryValue: TValue
): Record<keyof T, TValue> {
  return (Object.keys(values) as Array<keyof T>).reduce(
    (dic, key) => {
      dic[key] = dictionaryValue;
      return dic;
    },
    {} as Record<keyof T, TValue>
  );
}

export function mapValuesToErrorDictionary<T extends object>(values: T): Record<keyof T, string> {
  return mapValuesToDictionary(values, '');
}

export function mapValuesToTouchedDictionary<T extends object>(values: T): Record<keyof T, boolean> {
  return mapValuesToDictionary(values, false);
}

export function mapControlsToRulesDictionary<T extends object>(
  controls: Array<UIControlType<T>>
): Record<keyof T, UIControlRuleType<T>[]> {
  return controls.reduce(
    (dictionary, control: UIControlType<T>) => {
      dictionary[control.name] = control.rules || [];
      return dictionary;
    },
    {} as Record<keyof T, UIControlRuleType<T>[]>
  );
}

export function validateValueByRules<T extends object>(value: T[keyof T], rules: UIControlRuleType<T>[]): string {
  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const isValidate = rule.validate(value);
    if (!isValidate) {
      return rule.message;
    }
  }
  return '';
}
