import { FC } from 'react';

import { getIconSize, IconProps } from '../type';

export const UserIcon: FC<IconProps> = ({ color = 'currentColor', className, size = 'S', width, height, onClick }) => (
  <svg
    className={className}
    width={width || getIconSize(size)}
    height={height || getIconSize(size)}
    onClick={onClick}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C10.2 0 12 1.8 12 4C12 6.2 10.2 8 8 8C5.8 8 4 6.2 4 4C4 1.8 5.8 0 8 0ZM8 10C12.4 10 16 11.8 16 14V16H0V14C0 11.8 3.6 10 8 10Z"
      fill={color}
    />
  </svg>
);
