import { CellProps, Column, ColumnInstance, Row } from 'react-table';

import { Cell } from 'view/Grid/components/TableHeader/Cell';
import { isBool, isObject, isSimpleValueType, toString } from 'utils';
import { getColumnProps } from 'services/getColumnProps';
import { ColumnStateType, EntityPanelViewOfNameType, SchemaTypeElementsModel, ValueTypeModel } from 'api';
import { ValueTypesEnum } from 'view/Viewers/utils/helper';
import { ColumnNamesEnum } from 'utils/types/columnNames';

import { BoolEnum, DataType } from './types';

export const mapMetadataToGridColumnsHelper = (
  metadataElements: Pick<SchemaTypeElementsModel, 'Value' | 'Vector' | 'Format' | 'Name' | 'Key' | 'Enum' | 'Hidden'>[],
  columnsState?: ColumnStateType[]
): Column<DataType>[] =>
  metadataElements
    .filter(
      ({ Value, Vector, Key, Enum, Hidden }) => (isSimpleValueType(Value?.Type) || Key || Enum) && !Vector && !Hidden
    )
    .map((element, index) => {
      const isExpander = index === 0;
      const columnId = isExpander ? ColumnNamesEnum.EXPANDER : element.Name;
      const columnState = columnsState?.find((col) => col.Id === columnId);

      return {
        ...getColumnProps(element, { isExpander, columnId, isColumnFormatter: true, columnState }),
        Cell: (cellProps: CellProps<DataType>) => {
          if (isExpander) return <Cell {...cellProps} />;
          const formatterValue = cellProps.column.valueFormatter
            ? cellProps.column.valueFormatter(cellProps.value)
            : cellProps.value;

          if (cellProps.column.columnType === 'Double') {
            const doubleColumnsMaxValue: number[] = [];

            cellProps.column.filteredRows.forEach((row) => {
              const cell = row.cells.find((rowCell) => rowCell.column?.id === cellProps.column?.id);

              doubleColumnsMaxValue.push(cell?.value as number);
            });

            const maxValue = Math.max(...doubleColumnsMaxValue.map((el) => Math.abs(el)));

            const doubleFormatterValue = cellProps.column.valueFormatter
              ? cellProps.column.valueFormatter(cellProps.value, maxValue)
              : cellProps.value;

            return <>{doubleFormatterValue}</>;
          }

          if (isBool(formatterValue)) {
            return <>{formatterValue ? BoolEnum.YES : BoolEnum.NO}</>;
          }

          return <>{formatterValue}</>;
        },
      };
    });

const getCSVTableTextHelper = (columns: ColumnInstance<DataType>[], rows: Row<DataType>[], divider: string) => {
  const availableColumns = columns.filter((column) => !column.suppressHideColumn);

  const th = availableColumns.map((column) => column.Header).join(divider);
  const td = rows
    .map((row) =>
      availableColumns.reduce((str, column) => {
        if (column.id in row.values) {
          const value = row.values[column.id];
          const formattedValue = column.valueFormatter ? column.valueFormatter(value) : value;
          return str.concat(toString(formattedValue), `"${divider}"`);
        }

        return str;
      }, '')
    )
    .join('"\n"');

  return `${th}\n"${td}"`;
};

const exportToCSV = (text: string, fileName: string) => {
  const hiddenElement = document.createElement('a');
  const date = new Date();
  hiddenElement.href = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;
  hiddenElement.download = `${fileName}-${date.toLocaleDateString()}.csv`;
  hiddenElement.click();
};

export const downloadGridCSVHelper = (
  columns: ColumnInstance<DataType>[],
  rows: Row<DataType>[],
  fileName = 'Table'
) => {
  const text = getCSVTableTextHelper(columns, rows, ',');
  exportToCSV(text, fileName);
};

export const defaultGetRowId = (row: DataType, relativeIndex: number, parent?: Row<DataType>) =>
  parent ? [parent.id, relativeIndex].join('.') : relativeIndex.toString();

export const getColumnTypeByCells = (
  columnIndex: number,
  columnCount: number,
  cells: Array<EntityPanelViewOfNameType | null>,
  defaultColumnType: ValueTypeModel = ValueTypesEnum.String
): ValueTypeModel => {
  const columnCellArr: EntityPanelViewOfNameType[] = [];

  for (let i = columnIndex; i < cells.length; i += columnCount) {
    const currentCell = cells[i];
    if (isObject(currentCell)) columnCellArr.push(currentCell);
  }

  const columnType = columnCellArr.length !== 0 && (Object.keys(columnCellArr[0]).at(0) as ValueTypeModel);

  if (!columnType) return defaultColumnType;

  if (columnCellArr.every((cell) => Object.keys(cell).at(0) === columnType)) {
    return columnType;
  }

  return ValueTypesEnum.Mixed;
};
