import { FC } from 'react';

import { RunHandlers } from '../RunHandlers';
import { PinnedHandlers } from '../PinnedHandlers';
import { ExecutedHandlerInfoByRecord, HandlerItemType } from '../../types';

type HandlersContainerProps = {
  handlers?: HandlerItemType[];
  executedHandlersInfoByRecord: ExecutedHandlerInfoByRecord;
  isLogsOpened: boolean;
  onPinHandler: (handler: HandlerItemType) => void;
  onHandlerClick: (handler: HandlerItemType) => void;
  onCancelHandler: (handlerId: string, isStatic?: boolean) => void;
  isReadOnly?: boolean;
};

export const HandlersContainer: FC<HandlersContainerProps> = ({
  handlers,
  executedHandlersInfoByRecord,
  isLogsOpened,
  onPinHandler,
  onHandlerClick,
  onCancelHandler,
  isReadOnly = false,
}) => {
  const pinnedHandlers = handlers?.filter((handler) => handler.isPinned);

  return (
    <div className="d-flex w-100">
      <RunHandlers
        handlers={handlers}
        executedHandlersInfoByRecord={executedHandlersInfoByRecord}
        isLogsOpened={isLogsOpened}
        onPinHandler={onPinHandler}
        onHandlerClick={onHandlerClick}
        onCancelHandler={onCancelHandler}
        isReadOnly={isReadOnly}
      />
      {pinnedHandlers && (
        <PinnedHandlers
          pinnedHandlers={pinnedHandlers}
          executedHandlersInfoByRecord={executedHandlersInfoByRecord}
          onHandlerClick={onHandlerClick}
          onCancelHandler={onCancelHandler}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
};
