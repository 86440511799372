export enum AuthTypeEnum {
  None = 'None',
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  Okta = 'OKTA',
}

export enum AuthStrategyEnum {
  default = 'default',
  redirect = 'redirect',
}

export type AuthTypeModel = {
  id: string;
  name: string;
  login_path: string;
  login_strategy: AuthStrategyEnum;
  type: AuthTypeEnum;
  renew_path: string;
};

export type AuthTypeMetadataModel = {
  redirectUrl: string;
};

export type AuthTokesModel = {
  access_token: string;
  refresh_token: string;
  token_type: 'bearer';
};

export type AuthLogoutRedirectModel = {
  redirect_url: string;
};
