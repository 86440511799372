import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UiModal } from 'ui';
import { Button } from 'shared/Button';
import { useValidation } from 'shared/Hooks/useValidation';
import {
  VariantSelectDataType,
  VariantSelectValueType,
  VariantType,
} from 'view/Editor/components/TableBody/TableBodyCell/VariantSelect/VariantSelect.component';

import { VariantSelectContent } from './VariantSelectContent.component';

export type VariantSelectModalProps = {
  variantType: VariantType;
  value?: VariantSelectValueType;
  isRequiredValue?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit: (value: VariantSelectDataType) => void;
};

export const VariantSelectModal: FC<VariantSelectModalProps> = ({
  variantType,
  value,
  isRequiredValue,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <UiModal size="md" title={t('confirmationModal.variantSelect.title')} isOpen={isOpen} onClose={onClose}>
      <VariantSelectModalContent
        value={value}
        variantType={variantType}
        isRequiredValue={isRequiredValue}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </UiModal>
  );
};

const VariantSelectModalContent: FC<VariantSelectModalProps> = ({
  variantType,
  value,
  isRequiredValue,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [selectedType, setSelectedType] = useState(variantType);
  const [selectedValue, setSelectedValue] = useState(value);

  const { validate } = useValidation({
    type: selectedType === VariantType.Missing ? undefined : selectedType,
    isRequired: isRequiredValue,
  });

  const handleSubmit = () => {
    if (!selectedType) return;

    const variantData =
      selectedType === VariantType.Missing
        ? null
        : {
            [selectedType]: selectedValue,
          };
    onSubmit(variantData);
  };

  const handleChangeValue = (data: VariantSelectValueType) => {
    setSelectedValue(data);
    setIsError(!!validate(data));
  };

  const handleChangeType = (data: VariantType) => {
    setSelectedType(data);
    setIsError(false);
    setSelectedValue(null);
  };

  return (
    <>
      <UiModal.Body>
        <VariantSelectContent
          isError={isError}
          variantType={selectedType}
          value={selectedValue}
          onChangeType={handleChangeType}
          onChangeValue={handleChangeValue}
        />
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={t('buttons.cancel')} variant="secondary" onClick={onClose} />
        <Button title={t('buttons.save')} disabled={isError} onClick={handleSubmit} />
      </UiModal.Footer>
    </>
  );
};
