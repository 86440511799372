import { FC, ReactNode } from 'react';
import { Placement } from '@floating-ui/react';
import cx from 'classnames';

import { LoadingStatusesEnum } from 'api';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { HandlerItemType, ExecutedHandlerInfo } from '../../types';
import { HandlerStatus } from '../HandlerStatus/index.';

import styles from './styles.module.scss';

type HandlerProps = {
  handler: HandlerItemType;
  handlerExecutionInfo?: ExecutedHandlerInfo;
  tooltipPlacement?: Placement;
  renderHandlerName?: (handlerName: string) => ReactNode;
  onCancelHandler: (handlerId: string, isStatic?: boolean) => void;
  isVisibleHandler?: boolean;
  isReadOnly?: boolean;
};

export const Handler: FC<HandlerProps> = ({
  handler,
  handlerExecutionInfo,
  tooltipPlacement,
  renderHandlerName,
  onCancelHandler,
  isVisibleHandler = false,
  isReadOnly = false,
}) => {
  const handleCancelIconClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onCancelHandler(handler.id, handler.isStatic);
  };

  return (
    <div className={cx('d-flex justify-content-between w-100', styles.handler)}>
      <div
        className={cx('d-flex align-items-start gap-1 flex-grow-1 overflow-hidden', {
          'align-items-center': isVisibleHandler,
        })}
      >
        <Icon
          clickable={false}
          disable={isReadOnly}
          SvgIcon={handler.isStatic ? APP_ICONS.doubleTriangle : APP_ICONS.handler}
          className={styles.handlerIcon}
        />
        <HandlerStatus
          name={handler.name}
          handlerInfo={handlerExecutionInfo}
          tooltipPlacement={tooltipPlacement}
          renderName={renderHandlerName}
        />
      </div>

      <Icon
        SvgIcon={APP_ICONS.cancelInCirlce}
        className={cx(
          { [styles.invisibleIcon]: handlerExecutionInfo?.status !== LoadingStatusesEnum.LOADING },
          styles.cancelIcon
        )}
        onClick={handleCancelIconClick}
      />
    </div>
  );
};
