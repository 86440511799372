import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type UserInitialsType = {
  clickable: boolean;
  chatIcon?: boolean;
};

export const UserInitials = ({ clickable, chatIcon = false }: UserInitialsType) => (
  <div
    className={cx(styles.container, {
      'c-pointer': clickable,
      'pe-none': !clickable,
      [styles.chatContainer]: chatIcon,
    })}
  >
    <Icon SvgIcon={chatIcon ? APP_ICONS.chatUserIcon : APP_ICONS.whiteThemeUserIcon} clickable={false} />
  </div>
);
