import { Input } from 'reactstrap';
import { Column, VisibilityState } from '@tanstack/react-table';

import { UiEmpty } from 'ui';

import './HeaderFilterCellList.styles.scss';

type HeaderFilterCellListProps<TData> = {
  columns: Column<TData>[];
  visibility: VisibilityState;
  toggleColumnVisibleById: (columnId: string) => void;
};

export const HeaderFilterCellList = <TData,>({
  columns,
  visibility,
  toggleColumnVisibleById,
}: HeaderFilterCellListProps<TData>) => {
  const renderList = () => {
    if (columns.length === 0) {
      return <UiEmpty />;
    }

    return columns.map(({ id }) => (
      <div className="header-filter-cell-list__item" key={id} onClick={() => toggleColumnVisibleById(id)}>
        <Input className="header-filter-cell-checkbox" type="checkbox" readOnly checked={visibility[id]} />
        <span className="header-filter-cell-list__text">{id}</span>
      </div>
    ));
  };

  return <div className="header-filter-cell-list">{renderList()}</div>;
};
