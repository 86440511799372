import { GridTabDataType } from 'store/types';

export const reorderTabs = (openedTabs: GridTabDataType[], startIndex: number, endIndex: number) => {
  const result = Array.from(openedTabs);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const filterOtherTabs = (openedTabs: GridTabDataType[], currentTabId: string) =>
  openedTabs.filter((tab) => tab.id === currentTabId || tab.isCloseable === false);

export const filterUncloseableTabs = (openedTabs: GridTabDataType[]) =>
  openedTabs.filter((tab) => tab.isCloseable === false);
