import { FC, useMemo } from 'react';

import { SimpleViewModel } from 'api';
import { OptionType, UiSelect } from 'ui';

import { VariantType } from './VariantSelect.component';
import { VariantSelectList, VariantSelectListItem } from './VariantSelectList.component';
import { VariantSelectValue } from './VariantSelectValue.component';

export type VariantSelectContentProps = {
  isError?: boolean;
  variantType: VariantType;
  value: SimpleViewModel;
  onChangeType: (value: VariantType) => void;
  onChangeValue: (value: SimpleViewModel) => void;
};

export const VariantSelectContent: FC<VariantSelectContentProps> = ({
  variantType,
  value,
  isError,
  onChangeValue,
  onChangeType,
}) => {
  const variantOptions = useMemo<OptionType[]>(
    () => Object.keys(VariantType).map((it) => ({ value: it, label: it })),
    []
  );

  const handleSelectVariantType = (data: OptionType) => {
    onChangeType((data?.value as VariantType) || VariantType.Missing);
  };

  return (
    <VariantSelectList>
      <VariantSelectListItem title="Type">
        <UiSelect
          className="h-100"
          isTransparent
          menuPortalTarget={document.body}
          value={{ value: variantType, label: variantType }}
          options={variantOptions}
          onChange={(data) => handleSelectVariantType(data as OptionType)}
        />
      </VariantSelectListItem>
      {variantType && variantType !== VariantType.Missing && (
        <VariantSelectListItem title="Value">
          <VariantSelectValue value={value} type={variantType} isError={isError} onChange={onChangeValue} />
        </VariantSelectListItem>
      )}
    </VariantSelectList>
  );
};
