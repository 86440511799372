import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

type NoGridDataProps = {
  title: string;
  isRefreshButton?: boolean;
  handleButtonClick?: () => void;
};

export const NoGridData = ({ title, isRefreshButton = false, handleButtonClick }: NoGridDataProps) => {
  const { t } = useTranslation();

  return title ? (
    <div className="d-flex align-items-center justify-content-center flex-column h-50 fs-20 fw-bolder text-text2">
      {title}
      {isRefreshButton && (
        <button
          className="d-flex align-items-center mt-3 bg-gray-400 border-0 text-text4 fs-13 c-button"
          onClick={handleButtonClick}
        >
          <Icon clickable={false} SvgIcon={APP_ICONS.refresh} className="me-2" />
          {t('noData.refresh')}
        </button>
      )}
    </div>
  ) : null;
};
