import { FC, ReactPortal, useId, useMemo, useState } from 'react';
import { CellProps, Column } from 'react-table';
import { createPortal } from 'react-dom';

import { DictionaryDataModel, DictionaryDataPath, DictionaryDataValueModel } from 'api';
import { DictionaryViewToolbar } from 'view/Editor/components/EditorView/DictionaryView/DictionaryViewToolbar.component';
import { DataType, GridModeEnum } from 'view/Grid/utils/types';
import { Grid } from 'view/Grid';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

import { DictionaryViewValueCell } from './DictionaryViewValueCell.component';
import { DictionaryViewKeyCell } from './DictionaryViewKeyCell.component';

export type DictionaryValuesType = {
  dictKey: string;
  dictValue: DictionaryDataValueModel | DictionaryDataValueModel[] | null;
};

type DictionaryViewProps = {
  isReadonly?: boolean;
  path: string[];
  title?: string;
  data: DictionaryDataModel[];
};

export const DictionaryView: FC<DictionaryViewProps> = ({ isReadonly = false, path, title, data }) => {
  const id = useId();
  const { state } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Record<string, boolean>>({});

  const dictValues = useMemo<DictionaryValuesType[]>(
    () =>
      data?.map((dictItem) => ({
        dictKey: dictItem?.key || '',
        dictValue: dictItem?.value ? dictItem.value : null,
      })) || [],
    [data]
  );

  const dictColumns = useMemo<Readonly<Column<DictionaryValuesType>>[]>(
    () => [
      {
        id: 'key',
        accessor: (row) => row,
        width: 200,
        Header: 'Key',
        Cell: (props: CellProps<DictionaryValuesType, DictionaryValuesType>) => (
          <DictionaryViewKeyCell
            isReadonly={isReadonly}
            dictKey={props.value.dictKey}
            path={createPath(props.row.index, DictionaryDataPath.Key)}
          />
        ),
      },
      {
        id: 'value',
        width: 400,
        accessor: (row) => row,
        Header: 'Value',
        Cell: (props: CellProps<DictionaryValuesType, DictionaryValuesType>) => (
          <DictionaryViewValueCell
            title={title}
            isReadonly={isReadonly}
            value={props.value.dictValue}
            path={createPath(props.row.index, DictionaryDataPath.Value)}
          />
        ),
      },
    ],
    [path]
  );

  const createPath = (index: number, type: DictionaryDataPath) => [...path, index.toString(), type];

  const renderPortalToolbar = (): ReactPortal | null => {
    if (!state.editorToolbarRef || !tabState.isActive) return null;

    return createPortal(
      <DictionaryViewToolbar
        path={path}
        isReadonly={isReadonly}
        arrayLength={dictValues.length}
        selectedIndex={selectedIndex}
        selectedGroup={selectedCheckbox}
        onSelectedGroupChange={setSelectedCheckbox}
        onSelectedIndexChange={setSelectedIndex}
      />,
      state.editorToolbarRef
    );
  };

  return (
    <div>
      {!isReadonly && renderPortalToolbar()}
      <Grid
        useCheckboxes={!isReadonly}
        gridId={id}
        gridMode={GridModeEnum.EDITOR}
        columns={dictColumns as Column<DataType>[]}
        data={dictValues as DataType[]}
        selectedRowId={selectedIndex.toString()}
        onChangeSelectedRowId={(rowId) => setSelectedIndex(+rowId)}
        selectedCheckbox={selectedCheckbox}
        onSelectedCheckboxChange={setSelectedCheckbox}
      />
    </div>
  );
};
