import { FC, ReactNode, useRef } from 'react';
import cx from 'classnames';
import { Placement } from '@floating-ui/react';

import { LoadingStatusesEnum } from 'api';
import { Tooltip, TooltipContent, TooltipTrigger, useDisplayTooltip } from 'shared/Tooltip';
import { env } from 'env';

import { ExecutedHandlerInfo } from '../../types';

import styles from './styles.module.scss';

type HandlerStatusProps = {
  name: string;
  handlerInfo?: ExecutedHandlerInfo;
  tooltipPlacement?: Placement;
  renderName?: (name: string) => ReactNode;
};

const defaultRenderName: HandlerStatusProps['renderName'] = (name: string) => name;

export const HandlerStatus: FC<HandlerStatusProps> = ({
  name,
  handlerInfo,
  renderName = defaultRenderName,
  tooltipPlacement = 'top',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isEnabledTooltip = useDisplayTooltip(ref);

  return (
    <Tooltip enabled={isEnabledTooltip} placement={tooltipPlacement}>
      <TooltipTrigger
        ref={ref}
        className={cx('text-truncate w-100 me-2', styles.container, {
          [styles.error]: handlerInfo?.status === LoadingStatusesEnum.ERROR && !env.isDemoMode,
          [styles.demoError]: handlerInfo?.status === LoadingStatusesEnum.ERROR && env.isDemoMode,
          [styles.success]: handlerInfo?.status === LoadingStatusesEnum.SUCCESS,
          [styles.loading]: handlerInfo?.status === LoadingStatusesEnum.LOADING,
        })}
      >
        <div className={styles.handlerName}>{renderName(name)}</div>
      </TooltipTrigger>
      <TooltipContent>{name}</TooltipContent>
    </Tooltip>
  );
};
