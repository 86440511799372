import { GridTabDataType } from 'store/types';
import { DefaultPanelSize, GRID_TAB, GridTabEnum, StackEnum } from 'view/DragnDrop/utils/constants';

export const returnDefaultConfig = (tabs: GridTabDataType[]) => ({
  type: StackEnum.COLUMN,
  key: 'column-stack-main',
  id: 'column-stack-main',
  isMainGrid: false,
  size: DefaultPanelSize.FULL_VIEW,
  content: [
    {
      type: StackEnum.STACK,
      key: `stack-${GridTabEnum.MAIN}`,
      id: `stack-${GridTabEnum.MAIN}`,
      isMainGrid: true,
      size: DefaultPanelSize.DEFAULT,
      content: [GRID_TAB[GridTabEnum.MAIN]],
    },
    {
      type: StackEnum.STACK,
      key: `stack-${tabs[0]?.id || 1}`,
      id: `stack-${tabs[0]?.id || 1}`,
      isMainGrid: false,
      size: DefaultPanelSize.DEFAULT,
      content: tabs,
    },
  ],
});
