import { getColumnProps } from 'services/getColumnProps';
import { ValueType } from 'store/types';
import { isArray } from 'utils';
import { SimpleVectorCell } from 'view/Editor/components/TableBody/TableBodyCell/SimpleVectorCell';
import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { SimpleViewModel } from 'api';

export const generateSimpleColumnsHelper = (data: EditorViewDataType[], path: string[]) =>
  data.map((element) => {
    const columnProps = getColumnProps({
      Name: element.key,
      Label: element.label,
      Value: { Type: element.valueType as ValueType['Type'] },
    });

    return {
      ...columnProps,
      enumSelector: element.enumSelector,
      schemaType: element.schemaType,
      keyName: element.keyName,
      keyModuleName: element.keyModuleName,
      path: [...path, element.key],
      isRequired: element.isRequired,
      Cell: SimpleVectorCell,
    };
  });

export const generateDataHelper = (data: EditorViewDataType[]) =>
  Array.from(
    data
      .reduce<Map<number, Record<string, SimpleViewModel>>>((acc, { value: cells, key: column }) => {
        isArray(cells) &&
          cells.forEach((cell, index) => {
            const row = acc.get(index) ?? {};
            acc.set(index, { ...row, [column]: cell } as Record<string, SimpleViewModel>);
          });
        return acc;
      }, new Map())
      .values()
  );
