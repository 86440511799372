import { FC } from 'react';
import cx from 'classnames';

type UiEmptyProps = {
  title?: string;
  alignItems?: 'center' | 'start' | 'end';
  justifyContent?: 'center' | 'start' | 'end';
  className?: string;
};

export const UiEmpty: FC<UiEmptyProps> = (props) => {
  const { title = 'No data available', alignItems = 'center', justifyContent = 'center', className } = props;

  const classes = cx(
    'ui-empty h-100 w-100 d-flex text-text2',
    {
      [`justify-content-${justifyContent}`]: justifyContent,
      [`align-items-${alignItems}`]: alignItems,
    },
    className
  );

  return <div className={classes}>{title}</div>;
};
