import { FC } from 'react';
import { useSelector } from 'react-redux';

import UiControls from '@compatibl/ui-controls';

import { EntityPanelModel, EntityPanelViewOfModel, axiosMain } from 'api';
import { env } from 'env';
import { isArray, toast } from 'utils';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { EditorContentToolbar } from 'view/EditorContent/EditorContentToolbar/EditorContentToolbar';
import { DAGType } from 'view/Dag';
import { cleanTypeHelper } from 'utils/helpers/cleanType.helper';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { selectAccessToken, selectApplicationTheme } from 'store/selectors/AppState.selector';
import { ThemesType } from 'store/types';

import { ViewerTypesEnum } from './utils/constants';
import { BinaryContentContainer } from './BinaryContentContainer';
import { TableViewer } from './TableViewer';
import { CompositeView } from './CompositeView';
import { FormattedVariantTable } from './FormattedVariantTable';
import { ScriptViewer } from './ScriptViewer';
import { MatrixViewer } from './MatrixViewer';
import { DefaultViewer } from './DefaultViewer';
import { ParamsViewer } from './ParamsViewer';
import { ViewHandlerAction } from './ViewHandlerAction/ViewHandlerAction.component';

import { DagViewer } from './DagViewer';

type ViewersProps = {
  activeTab?: string;
  panelType?: string;
  panelKey?: string;
  panelWithParamsName?: string;
  showToolbar?: boolean;
  activeViewerType?: string;
  data?: EntityPanelModel['ViewOf'];
  onTransition?: (data: TransitionRecordType) => void;
  onRefresh?: () => void;
};

export const Viewers: FC<ViewersProps> = ({
  activeTab,
  showToolbar,
  activeViewerType,
  data,
  panelKey,
  panelWithParamsName,
  panelType,
  onTransition,
  onRefresh,
}) => {
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);
  const accessToken = useSelector(selectAccessToken) as string;
  // TODO: update theme time for UI Viewer library
  const theme = useSelector(selectApplicationTheme) as keyof ThemesType;

  if (panelWithParamsName)
    return <ParamsViewer activeTab={activeTab} panelKey={panelKey} panelWithParamsName={panelWithParamsName} />;

  if (!data) return null;

  const renderViewer = (type: ViewerTypesEnum) => {
    const cleanType = cleanTypeHelper(type);

    const defineViewer = {
      [ViewerTypesEnum.VariantTree]: <div>VariantTree</div>,
      [ViewerTypesEnum.VariantTable]: !isArray(data) && <TableViewer {...data} />,
      [ViewerTypesEnum.BinaryContent]: !isArray(data) && <BinaryContentContainer {...data} />,
      [ViewerTypesEnum.VariantMatrix]: !isArray(data) && <MatrixViewer {...data} />,
      [ViewerTypesEnum.ViewContainer]: !isArray(data) && <CompositeView {...data} onTransition={onTransition} />,
      [ViewerTypesEnum.Script]: !isArray(data) && <ScriptViewer data={data as EntityPanelViewOfModel} />,
      [ViewerTypesEnum.FormattedVariantTable]: !isArray(data) && <FormattedVariantTable {...data} />,
      [ViewerTypesEnum.DAG]: !isArray(data) && <DagViewer {...(data as DAGType)} />,
      [ViewerTypesEnum.ViewHandlerAction]: !isArray(data) && (
        <ViewHandlerAction
          dataset={dataset}
          environment={environment}
          viewerKey={panelKey}
          viewerType={panelType}
          {...data}
          onTransition={onTransition}
        />
      ),
      [ViewerTypesEnum.UIViewer]: !isArray(data) && (
        <UiControls
          data={data as EntityPanelViewOfModel}
          dataset={dataset}
          dataSource={environment}
          panelType={panelType}
          panelKey={panelKey}
          activeTab={activeTab}
          accessToken={accessToken}
          theme={theme}
          isDemoMode={env.isDemoMode}
          apiUrl={env.apiUrl}
          onRefresh={onRefresh}
          toast={toast}
          onTransition={onTransition}
          axiosClient={axiosMain}
        />
      ),
    }[cleanType];

    if (defineViewer) {
      return (
        <>
          {showToolbar && <EditorContentToolbar panelData={data} onRefresh={onRefresh} />}
          {defineViewer}
        </>
      );
    }

    return (
      <DefaultViewer
        viewerType={panelType}
        viewerKey={panelKey}
        showToolbar={showToolbar}
        data={data}
        onTransition={onTransition}
        onRefresh={onRefresh}
      />
    );
  };

  return renderViewer(activeViewerType as ViewerTypesEnum);
};
