import { ModelSwitcher } from 'shared/ModelSwitcher';
import { ProfileStructureDto } from 'api/data/profileStructure.data';
import { ProfileDto } from 'api/data/profile.data';

import { DefaultViewTile } from '../DefaultViewTile';

type MainScreenDefaultProps = {
  dashboardItems: ProfileStructureDto[] | null;
  handleClickTile: (Name: string, Profiles?: ProfileDto[]) => () => void;
  modelsList?: string[];
};

export const MainScreenDefault = ({ dashboardItems, handleClickTile }: MainScreenDefaultProps) => (
  <div className="my-auto d-flex flex-column align-items-center gap-4">
    <ModelSwitcher />
    <div className="d-flex gap-3 flex-wrap justify-content-center">
      {dashboardItems?.map(({ Name, Description, Profiles }) => (
        <DefaultViewTile
          key={Name}
          Name={Name}
          Description={Description}
          Profiles={Profiles}
          onClickTile={handleClickTile(Name, Profiles)}
        />
      ))}
    </div>
  </div>
);
