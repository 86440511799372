import { FC, Fragment, memo, useEffect, useRef } from 'react';
import cx from 'classnames';

import {
  MessageGroupStatus,
  MessageGroupType,
  ProgressOutputType,
} from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { Message } from '../Message';
import { Reply } from '../Reply';

import styles from './styles.module.scss';

type ChatProps = {
  isShowMaskIcon: boolean;
  className?: string;
  messageGroups: MessageGroupType[];
  uploadingProgress?: ProgressOutputType;
};

export const Chat: FC<ChatProps> = memo(({ isShowMaskIcon, className, messageGroups, uploadingProgress }) => {
  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messageGroups]);

  return (
    <div
      className={cx(
        className,
        'd-flex flex-column gap-3 border-top border-chat-tabs-bottom-border justify-content-between overflow-hidden p-3'
      )}
    >
      <div className="d-flex flex-column overflow-y-auto">
        {messageGroups.map((group) => (
          <Fragment key={group.id}>
            {group.question && (
              <Message isShowIcon={isShowMaskIcon} text={group.question.text} files={group.question.files} />
            )}
            <Reply
              uploadingProgress={uploadingProgress}
              isShowIcon={isShowMaskIcon}
              text={group.answer?.text}
              isLoading={group.status === MessageGroupStatus.LOADING}
            />
            <div className={cx(styles.underline, 'bg-chat-separator-color w-100 flex-shrink-0')}></div>
          </Fragment>
        ))}
        <div ref={bottomRef}></div>
      </div>
    </div>
  );
});

Chat.displayName = 'Chat';
