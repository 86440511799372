import { useCallback, useState } from 'react';

import {
  getChatSessionsRequest,
  RequestParamsType as GetChatSessionsRequestParamsType,
} from './utils/getChatSessionsRequest';
import { switchChatSessionRequest } from './utils/switchChatSessionRequest';
import {
  deleteChatSessionsRequest,
  RequestParamsType as DeleteChatSessionsRequestParamsType,
} from './utils/deleteChatSessionsRequest';
import { SessionType } from '../../utils/types';

type DeleteManyParamsType = Omit<DeleteChatSessionsRequestParamsType, 'sessionChatKeys'> & { sessions: SessionType[] };

export function useSessions() {
  const [sessions, setSessions] = useState<SessionType[]>([]);

  const reset = useCallback(() => {
    setSessions([]);
  }, []);

  const load = useCallback(
    (params: GetChatSessionsRequestParamsType) =>
      getChatSessionsRequest(params)
        .then((loadedSessions: SessionType[]) => {
          setSessions(loadedSessions);
          return loadedSessions;
        })
        .catch(() => {
          reset();
          return [];
        }),
    [reset]
  );

  const select = useCallback(switchChatSessionRequest, []);

  const deleteMany = useCallback(
    (params: DeleteManyParamsType) => {
      const { sessions: removedSessions, ...restParams } = params;
      const removedSessionKeys = removedSessions.map((session: SessionType) => session.ChatKey);

      return deleteChatSessionsRequest({
        ...restParams,
        sessionChatKeys: removedSessionKeys,
      }).then(() => {
        const removedSessionKeySet = new Set<SessionType['ChatKey']>(removedSessionKeys);
        const availableSessions = sessions.filter((session: SessionType) => !removedSessionKeySet.has(session.ChatKey));
        setSessions(availableSessions);

        return availableSessions;
      });
    },
    [sessions]
  );

  return {
    sessions,
    load,
    select,
    reset,
    deleteMany,
  };
}
