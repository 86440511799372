import { useLayoutEffect, useRef, useState } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import useResizeObserver from 'use-resize-observer';

export enum ElementWidth {
  TABS = 202,
  HANDLERS = 200,
}

type DimensionType = {
  necessaryWidths: number[];
  dropdownWidth: number;
};

type ReorderOptionsType<T> = {
  activeElementIndex: number;
  isCurrentlyDragging?: boolean;
  onReorderElements: (elements: T[], activeElementIndex: number, lastVisibleIndex: number) => void;
};

type UseElementsDimensionProps<T> = {
  elements: T[];
  elementWidth?: ElementWidth;
  reorderOptions?: ReorderOptionsType<T>;
};

export const useElementsDimension = <T>({
  elements = [],
  elementWidth = ElementWidth.TABS,
  reorderOptions,
}: UseElementsDimensionProps<T>) => {
  const elementsRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [lastVisibleElementIndex, setLastVisibleElementIndex] = useState(-1);
  const [dimensions, setDimensions] = useState<DimensionType>({
    necessaryWidths: [],
    dropdownWidth: 0,
  });

  useLayoutEffect(() => {
    if (!elementsRef.current || !dropdownRef.current) return;

    const { dropdownWidth, necessaryWidths, containerWidth } = getPrecalculatedWidths(
      elementsRef.current,
      dropdownRef.current,
      elements.length,
      elementWidth
    );

    const lastElementIndex = getLastVisibleTabIndex({
      containerWidth,
      necessaryWidths,
      dropdownWidth,
    });

    if (reorderOptions) {
      const { activeElementIndex, isCurrentlyDragging, onReorderElements } = reorderOptions;

      if (
        lastElementIndex < elements.length - 1 &&
        activeElementIndex > lastElementIndex &&
        !isCurrentlyDragging &&
        containerWidth
      ) {
        onReorderElements(
          elements,
          activeElementIndex,
          lastVisibleElementIndex < activeElementIndex ? activeElementIndex - 1 : lastVisibleElementIndex
        );
      }
    }

    setDimensions({ dropdownWidth, necessaryWidths });
    setLastVisibleElementIndex(lastElementIndex);
  }, [elements.length, elements, lastVisibleElementIndex, reorderOptions?.activeElementIndex]);

  const isDropdownButtonVisible = lastVisibleElementIndex < elements.length - 1;

  const visibleElements = lastVisibleElementIndex === -1 ? elements : elements.slice(0, lastVisibleElementIndex + 1);

  const hiddenElements = elements.slice(lastVisibleElementIndex + 1);

  const listener = () => {
    if (!elementsRef.current) return;
    const updatedLastElementIndex = getLastVisibleTabIndex({
      containerWidth: elementsRef.current.getBoundingClientRect().width,
      necessaryWidths: dimensions.necessaryWidths,
      dropdownWidth: dimensions.dropdownWidth,
    });

    if (updatedLastElementIndex !== lastVisibleElementIndex) {
      setLastVisibleElementIndex(updatedLastElementIndex);
    }
  };

  const { ref: resizeRef } = useResizeObserver({ onResize: listener });

  const mergedRefs = useMergeRefs([resizeRef, elementsRef]);

  return { elementsRef: mergedRefs, dropdownRef, visibleElements, hiddenElements, isDropdownButtonVisible };
};

const getPrecalculatedWidths = (
  containerElement: HTMLElement,
  dropdownElement: HTMLElement,
  elementsLength: number,
  elementWidth: ElementWidth
) => {
  const { width: containerWidth } = containerElement.getBoundingClientRect();

  const dropdownWidth = dropdownElement.getBoundingClientRect().width || 0;

  const necessaryWidths = Array(elementsLength)
    .fill(0)
    .reduce<number[]>((acc, _, index) => {
      const previousWidth = elementWidth * index;
      const width = previousWidth + elementWidth;

      return [...acc, width];
    }, []);

  return {
    dropdownWidth,
    necessaryWidths,
    containerWidth,
  };
};

const getLastVisibleTabIndex = ({
  necessaryWidths,
  containerWidth,
  dropdownWidth,
}: {
  necessaryWidths: number[];
  containerWidth: number;
  dropdownWidth: number;
}) => {
  if (necessaryWidths[necessaryWidths.length - 1] < containerWidth) {
    return necessaryWidths.length - 1;
  }

  const visibleElements = necessaryWidths.filter((width) => width + dropdownWidth < containerWidth);

  return visibleElements.length ? visibleElements.length - 1 : 0;
};
