import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  ColumnStateType,
  entityApi,
  getErrorMessage,
  SchemaTypeListModel,
  SchemaTypeModel,
  storageApi,
  StorageItemModel,
  StorageRecordListModel,
} from 'api';
import { useRequest } from 'hook/request.hook';
import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { DataType, SelectedRowsRangeType } from 'view/Grid/utils/types';
import { useDebounce } from 'shared/Hooks/useDebounce';
import { mapMetadataToGridColumnsHelper } from 'view/Grid/utils/helpers';
import { QueryType } from 'view/QueryBuilder';
import { formatFileName, toast } from 'utils';
import { selectActiveDataset } from 'store/selectors';

import { usePagination } from 'shared/Hooks/usePagination/pagination.hook';
import { useQueryBuilder } from 'shared/Hooks/useQueryBuilder/queryBuilder.hook';
import { filesService } from 'services/files';
import { useAppContext } from 'context';
import { externalStorage } from 'shared/ExternalStorage';

const initialSelectedRow: SelectedGridRowType = { key: '', type: '' };

export type EntitiesHookProps = {
  tabSchema?: SchemaTypeModel;
  useCache?: boolean;
  columnsState?: ColumnStateType[];
};

export function useEntities({ tabSchema, useCache = false, columnsState }: EntitiesHookProps) {
  const { t } = useTranslation();
  const { state: appState, action: appAction } = useAppContext();
  const cacheTab = (tabSchema?.Name && appState.cacheTabData?.[tabSchema.Name]) || null;
  const pagination = usePagination();
  const queryBuilder = useQueryBuilder(!!cacheTab);
  const dataset = useSelector(selectActiveDataset);

  const [renderCount, forceRerender] = useReducer((x: number) => x + 1, 0);

  const maxEntitiesCountRef = useRef(0);
  const fetchedEntitiesCount = useRef(0);
  const isFirstRender = useRef(true);

  const [schema, setSchema] = useState<SchemaTypeModel | undefined>(undefined);
  const [schemaTypeList, setSchemaTypeList] = useState<SchemaTypeListModel>({});
  const { debounce, isLoading: isSelectedLoading } = useDebounce<string>();
  const [selectedRow, selectRow] = useState<SelectedGridRowType>(initialSelectedRow);
  const [selectedRangeRows, selectRangeRows] = useState<SelectedRowsRangeType>({});
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState<SelectedRowsRangeType>({});
  const [entities, setEntities] = useState<StorageItemModel[]>([]);
  const [isSelectAllRecordsBtnClicked, setIsSelectAllRecordsBtnClicked] = useState(false);
  const { fetch: select, isLoading: isSelectLoading, status: selectStatus, error } = useRequest(storageApi.select);
  const { fetch: deleteEntities, isLoading: isDeleteLoading } = useRequest(entityApi.deleteEntities);

  // Flag to notify DraggableContainer that we should refetch editor tabs.
  const [refreshFlag, setRefreshFlag] = useState(false);

  // fetch method isn't updated when selectedRow changed even with useCallback;
  // ToDo: Investigate why after the demo;
  const selectedRowRef = useRef({ ...initialSelectedRow });
  const isSelectedRangeRowsExist = Object.values(selectedRangeRows).length !== 0;

  const loading = isSelectLoading || isDeleteLoading;
  const columns = useMemo(
    () => mapMetadataToGridColumnsHelper(schema?.Elements || [], columnsState),
    [schema, columnsState]
  );

  const clearSelection = () => selectRow(initialSelectedRow);

  const clearSelectedRangeRows = () => selectRangeRows({});

  const getRowId = useCallback((row: DataType) => row._key as string, []);

  const fetch = () => {
    if (!tabSchema) return;

    setRefreshFlag((prev) => !prev);

    const limit = pagination.getLimit();
    const skip = pagination.getSkipByCurrentPage();
    select({
      params: { type: tabSchema.Name, module: tabSchema.Module.ModuleName, skip, limit, table_format: true },
      dto: queryBuilder.mapQueryToDTO(),
    })
      .then((response: StorageRecordListModel) => {
        if (useCache && queryBuilder.isApplied) {
          // Cache only if results exist
          if (response.data.length) {
            appAction.setCacheQueryDataAction({
              type: tabSchema.Name,
              query: queryBuilder.query,
              entityData: response.data,
              entitySchema: response.schema.typeList[response.schema.rootType],
            });
          } else {
            appAction.clearCacheQueryByTypeAction(tabSchema.Name);
          }
        }

        pagination.updatePagination(response.data);
        setSchema(response.schema.typeList[response.schema.rootType]);
        setSchemaTypeList(response.schema.typeList);
        const paginatedEntities = pagination.sliceEntitiesByLimit(response.data);
        const isExistsSelectedRow = paginatedEntities.find((entity) => getRowId(entity) === selectedRow.key);
        // Executed on tab switch or pagination change
        if (!isExistsSelectedRow && selectedRowRef.current.key === '') {
          clearSelection();
        }

        setEntities(paginatedEntities);
        fetchedEntitiesCount.current = paginatedEntities.length;
      })
      .catch(toast.error)
      .finally(() => {
        pagination.updateRange(fetchedEntitiesCount.current);
        pagination.reset();
      });
  };

  const deleteSelectedRangeRows = () => {
    const defaultSelectedRow = [{ _key: selectedRow.key, _t: selectedRow.type }];
    const recordKeys = isSelectedRangeRowsExist ? Object.values(selectedRangeRows) : defaultSelectedRow;

    deleteEntities(recordKeys)
      .then(() => {
        toast.success(
          t(recordKeys.length === 1 ? `gridToolbar.entityDeletedSuccessfuly` : `gridToolbar.entitiesDeletedSuccessfuly`)
        );

        const isDeletedSelectedKey = recordKeys.some((selectedRangeRow) => selectedRangeRow._key === selectedRow.key);

        if (isDeletedSelectedKey) {
          const deletedRange = recordKeys.map((selectedRangeRow) => selectedRangeRow._key);
          const newSelectedRow = entities.find((entity) => deletedRange.indexOf(entity._key) === -1);
          selectRow(
            newSelectedRow
              ? {
                  key: newSelectedRow._key,
                  type: newSelectedRow._t,
                }
              : initialSelectedRow
          );
        }

        clearSelectedRangeRows();
        pagination.onResetPage();

        if (Object.keys(selectedRangeRows).length === maxEntitiesCountRef.current) {
          queryBuilder.reset();
        } else {
          forceRerender();
        }
      })
      .catch((e) => toast.error(e));
  };

  const exportBulkEntities = async (isWithDependencies: boolean) => {
    const recordKeys = isSelectedRangeRowsExist ? Object.keys(selectedRangeRows) : [selectedRow.key!];

    const params = {
      type: selectedRow.type || '',
      keys: recordKeys,
      dataset: dataset || '',
      withDependencies: isWithDependencies,
    };

    try {
      const response = await storageApi.export(params);
      const blob = new Blob([response.data], { type: 'application/zip' });
      filesService.download(blob, formatFileName(response.fileName));
    } catch (e) {
      toast.error(e);
    }
  };

  const saveRecordPermanently = async (isWithDependencies: boolean) => {
    const recordKeys = isSelectedRangeRowsExist ? Object.keys(selectedRangeRows) : [selectedRow.key!];

    const params = {
      type: selectedRow.type || '',
      keys: recordKeys,
      dataset: dataset || '',
      withDependencies: isWithDependencies,
    };

    await storageApi
      .saveRecordPermanently(params)
      .then(() => {
        toast.success(t('shared.success'));
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const selectRowByKey = (rowId: ReturnType<typeof getRowId>) => {
    const row = entities.find((entity) => getRowId(entity) === rowId);
    if (!row) return;

    selectedRowRef.current.key = row._key;
    selectedRowRef.current.type = row._t;

    selectRow({ key: row._key, type: row._t });
  };

  const selectRowByKeyWithDelay = (rowId: ReturnType<typeof getRowId>) => {
    selectRowByKey(rowId);

    const noop = () => {};
    debounce({ value: '', fn: noop });
    externalStorage.removeAllParamsViewers();
  };

  const onApplyQuery = (query: QueryType) => {
    queryBuilder.apply(query);
    pagination.onResetPage();
  };

  const resetQuery = () => {
    pagination.onResetPage();
    queryBuilder.reset();
    if (tabSchema?.Name) appAction.clearCacheQueryByTypeAction(tabSchema.Name);
  };

  useEffect(() => {
    if (isFirstRender.current && cacheTab) {
      isFirstRender.current = false;
      pagination.updatePagination(cacheTab.entityData);
      const paginatedEntities = pagination.sliceEntitiesByLimit(cacheTab.entityData);
      setSchema(cacheTab.entitySchema);
      setEntities(paginatedEntities);
    } else {
      fetch();
    }

    // Clear ref when change tab or paginaion
    selectedRowRef.current.key = '';
    selectedRowRef.current.type = '';
  }, [tabSchema, pagination.page, queryBuilder.query, renderCount]);

  const selectAllRecords = () => {
    setIsSelectAllRecordsBtnClicked(true);
  };

  return {
    isSelectedLoading,
    loading,
    status: selectStatus,
    columns,
    entities,
    error: error ? getErrorMessage(error) : '',
    selectedRow,
    getRowId,
    selectedRangeRows,
    selectRangeRows,
    fetch,
    selectRow,
    selectRowByKeyWithDelay,
    deleteSelectedRangeRows,
    exportBulkEntities,
    saveRecordPermanently,
    selectedRowsPerPage,
    setSelectedRowsPerPage,
    refreshFlag,
    isSelectAllRecordsBtnClicked,
    setIsSelectAllRecordsBtnClicked,
    selectAllRecords,
    maxEntitiesCountRef,
    pagination: {
      range: pagination.generateRangeByEntities(entities),
      isAvailableNextPage: pagination.isAvailableNextPage,
      isAvailablePreviousPage: pagination.isAvailablePreviousPage,
      onNextPage: pagination.onNextPage,
      onPreviousPage: pagination.onPreviousPage,
      onFirstPage: pagination.onFirstPage,
      isAvailableNextPageRef: pagination.isAvailableNextPageRef,
      page: pagination.page,
      isPageDataLoading: pagination.isPageDataLoading,
    },
    queryBuilder: {
      query: queryBuilder.query,
      isQueryApplied: queryBuilder.isApplied,
      renderQueryBuilderHeader: queryBuilder.renderHeader,
      queryBuilderElements: queryBuilder.generateElementsBySchema(schema, schemaTypeList),
      isShowQueryBuilder: queryBuilder.isShow,
      showQueryBuilder: queryBuilder.show,
      closeQueryBuilder: queryBuilder.close,
      resetQuery: resetQuery,
      applyQuery: onApplyQuery,
    },
  };
}
