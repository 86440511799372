import { ChangeEvent, Children, cloneElement, FC, isValidElement, ReactElement, ReactNode } from 'react';
import cx from 'classnames';

import { UIRadio, UIRadioProps, UIRadioValue } from './UIRadio';

import './UIRadioGroup.scss';

export type UIRadioOption = {
  value?: string | number;
  label?: ReactNode;
};

export type UIRadioGroupProps = {
  options?: string[] | UIRadioOption[];
  value?: UIRadioValue;
  disabled?: boolean;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  layout?: 'vertical' | 'horizontal';
  children?: ReactElement<UIRadioProps>[];
};

const optionPreparing = (value: string | UIRadioOption): UIRadioOption => {
  if (typeof value === 'string') {
    return { label: value, value };
  }

  return value;
};

export const UIRadioGroup: FC<UIRadioGroupProps> = ({
  options,
  value,
  disabled,
  className,
  layout = 'horizontal',
  onChange,
  children,
}) => {
  const classes = cx('ui-radio-group', `ui-radio-group--${layout}`, className);

  const renderChildren = () =>
    Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          checked: value === child.props.value,
          disabled,
        });
      }

      return child;
    });

  return (
    <div className={classes}>
      {children
        ? renderChildren()
        : options?.map((option) => {
            const option_ = optionPreparing(option);

            return (
              <UIRadio
                key={option_.value}
                value={option_.value}
                checked={value === option_.value}
                onChange={onChange}
                disabled={disabled}
              >
                {option_.label}
              </UIRadio>
            );
          })}
    </div>
  );
};
