import cx from 'classnames';
import { FC } from 'react';

import Logo from 'assets/logos/cl-logo.svg';
import { useAppContext } from 'context';
import { Logger } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/Logger';

import { HeaderSearch } from './components/HeaderSearch';
import styles from './styles.module.scss';
import { UserInfo } from './components/UserInfo';
import { UserModeActions } from './components/UserModeActions';

type HeaderProps = {
  mainContainerClassname?: string;
  innerContainerClassname?: string;
};

export const Header: FC<HeaderProps> = ({ mainContainerClassname, innerContainerClassname }: HeaderProps) => {
  const { state } = useAppContext();

  if (state.isUserMode) {
    return <UserModeActions />;
  }

  return (
    <div className={cx('px-8px bg-header-background text-white headerContainer', mainContainerClassname)}>
      <div className={cx('d-flex align-items-center bg-header-background', styles.header, innerContainerClassname)}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <HeaderSearch />
        <div className="ms-auto d-flex justify-content-end align-items-center gap-2">
          <Logger />
          <UserInfo />
        </div>
      </div>
    </div>
  );
};
