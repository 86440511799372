import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeySelectCreateModal } from 'view/Editor/components/TableBody/TableBodyCell/KeySelect/KeySelectCreateModal';
import { TableToolbar, TableToolbarItemProps } from 'view/Editor/components/EditorView/TableToolbar';
import { APP_ICONS } from 'utils/icons';
import { KeySelectCopyModal } from 'view/Editor/components/TableBody/TableBodyCell/KeySelect/KeySelectCopyModal';
import { StorageEntityModel } from 'api';

type KeySelectToolbarProps = {
  schemaName: string;
  data?: StorageEntityModel;
  onSubmit: (value: string) => void;
};

export const KeySelectToolbar: FC<KeySelectToolbarProps> = ({ schemaName, data, onSubmit }) => {
  const { t } = useTranslation();
  const [isCreateRecordOpen, setIsCreateRecordOpen] = useState(false);
  const [isCopyRecordOpen, setIsCopyRecordOpen] = useState(false);

  const toolbarItems: TableToolbarItemProps[] = [
    {
      key: 'createRecord',
      icon: APP_ICONS.addNewRecord,
      tooltipContent: t('editor.createEntity'),
      onClick: () => setIsCreateRecordOpen(true),
    },
    {
      key: 'createCopyRecord',
      icon: APP_ICONS.addCopyRecord,
      tooltipContent: t('editor.copyEntity'),
      isDisabled: !data,
      onClick: () => setIsCopyRecordOpen(true),
    },
  ];

  return (
    <>
      <TableToolbar items={toolbarItems} />
      <KeySelectCreateModal
        schemaName={schemaName}
        isOpen={isCreateRecordOpen}
        onClose={() => setIsCreateRecordOpen(false)}
        onSubmit={onSubmit}
      />
      <KeySelectCopyModal
        schemaName={schemaName}
        data={data}
        isOpen={isCopyRecordOpen}
        onClose={() => setIsCopyRecordOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};
