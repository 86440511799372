import { ReactElement } from 'react';
import { Row } from '@tanstack/react-table';

import { useGrid } from '../../hooks';
import './UIGridVirtualContent.styles.scss';

type UiGridVirtualContentProps<TData> = {
  children: (row: Row<TData>) => ReactElement;
};

export const UIGridVirtualContent = <TData,>({ children }: UiGridVirtualContentProps<TData>) => {
  const { api, virtualizer } = useGrid<TData>();
  const { rows } = api.getRowModel();

  return (
    <div className="ui-grid-virtual-content" style={{ height: virtualizer.getTotalSize() }}>
      {virtualizer.getVirtualItems().map((virtualItem) => {
        const row = rows[virtualItem.index];
        return (
          <div
            key={row.id}
            className="ui-grid-virtual-content__row"
            style={{ height: virtualItem.size, transform: `translateY(${virtualItem.start}px)` }}
          >
            {children(row)}
          </div>
        );
      })}
    </div>
  );
};
