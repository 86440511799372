import cx from 'classnames';

import styles from './styles.module.scss';
import { RightSplitPaneType } from './utils/types';

type RightSplitPaneProps = RightSplitPaneType & {
  children: React.ReactNode;
};

export const RightSplitPane = ({ rightBlockRef, rightBlockWidth, startResizing, children }: RightSplitPaneProps) => (
  <div ref={rightBlockRef} className={cx('d-flex flex-row h-100', styles.container)} style={{ width: rightBlockWidth }}>
    <div
      role="button"
      tabIndex={0}
      aria-label="resizer"
      className={cx('bg-gray-100 h-100 d-flex align-items-center justify-content-center', styles.divider)}
      onMouseDown={startResizing}
    >
      <div className={styles.dividerResizer} />
    </div>
    <div className={cx('d-flex flex-column h-100', styles.paneBlock)}>{children}</div>
  </div>
);
