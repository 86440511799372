import { FC, useMemo } from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { ChatHistoryCheckbox } from '../ChatHistoryCheckbox';

type ChatHistoryHeaderProps = {
  isAllSelection: boolean;
  isEmptySelection: boolean;
  className?: string;
  toggleAllSessions: () => void;
};

export const ChatHistoryHeader: FC<ChatHistoryHeaderProps> = ({
  className,
  isAllSelection,
  isEmptySelection,
  toggleAllSessions,
}) => {
  const { t } = useTranslation();

  const isIndeterminate = useMemo(() => !isAllSelection && !isEmptySelection, [isAllSelection, isEmptySelection]);

  return (
    <div className={cx(className, 'd-flex px-2 py-1')}>
      <Label className="d-flex align-items-center gap-2 c-pointer mb-0">
        <ChatHistoryCheckbox indeterminate={isIndeterminate} value={isAllSelection} onChange={toggleAllSessions} />
        <span className="fs-13 text-text1 text-capitalize user-select-none">{t('chat.allChats')}</span>
      </Label>
    </div>
  );
};
