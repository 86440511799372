import { createContext } from 'react';

import { EventManagerHandler } from 'utils/helpers/eventManager';

export type ContextMenuItem = {
  title: string;
  icon: React.ElementType<React.ComponentPropsWithRef<'svg'>>;
  onClick: () => void;
};

export type ContextMenuShowEventProps = {
  items: ContextMenuItem[];
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
};

export type ContextMenuOpenEvent = EventManagerHandler<ContextMenuShowEventProps>;

type ContextMenuType = {
  handleOpenContextMenu: ContextMenuOpenEvent;
};

export const ContextMenu = createContext<ContextMenuType>({
  handleOpenContextMenu: () => undefined,
});
