import { FC, ReactNode, useEffect, useState } from 'react';
import { ToastContainerProps, ToastContainer as ToastifyContainer, TypeOptions, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { externalStorage } from 'shared/ExternalStorage';
import { OpenToastType, ToastType, ToastTypeEnum } from 'utils';

import 'react-toastify/dist/ReactToastify.css';
import './ToastContainer.styles.scss';
import { env } from 'env';
import { getErrorMessage } from 'api';

export type { Id as UIToastId, ToastOptions as UIToastOptions } from 'react-toastify';

const TOAST_WIDTH = 400;

export const UIToastContainer: FC<ToastContainerProps & { isDemoMode: boolean }> = ({
  autoClose = 5000,
  limit = 3,
  isDemoMode,
}) => {
  const { t } = useTranslation();
  const [openToasts, setOpenToasts] = useState<OpenToastType>();

  useEffect(() => {
    externalStorage.removeAllToasts();
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setOpenToasts(externalStorage.getToasts());
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (!openToasts) return;

    Object.keys(openToasts).forEach((groupToastType) => {
      const singularOpenToastCount = Object.keys(openToasts[groupToastType]).length;

      if (groupToastType !== ToastTypeEnum.INFO && singularOpenToastCount >= 3) {
        Object.keys(openToasts[groupToastType]).forEach((toastId) => {
          if (toast.isActive(toastId)) {
            toast.dismiss(toastId);
          }
        });

        const groupToastContent = `${singularOpenToastCount} ${getGroupToastContent(groupToastType)}`;

        if (toast.isActive(groupToastType)) {
          toast.update(groupToastType, {
            render: groupToastContent,
          });
        } else {
          toast[groupToastType as ToastType](groupToastContent, {
            toastId: groupToastType,
            onClose: () => externalStorage.removeAllTypeToasts(groupToastType as ToastType),
            onClick: () => toast.dismiss(groupToastType),
          });
        }
      } else {
        if (toast.isActive(groupToastType)) return;

        Object.keys(openToasts[groupToastType]).forEach((singularToastId) => {
          if (!toast.isActive(singularToastId)) {
            const { content, options } = openToasts[groupToastType][singularToastId];

            const getToastContent = () => {
              if (groupToastType === ToastTypeEnum.ERROR)
                return env.isDemoMode ? t('toast.completedUpperCase') : getErrorMessage(content);
              return content;
            };

            toast[groupToastType as ToastType](getToastContent(), {
              ...options,
              toastId: singularToastId,
              onClose: () => {
                !toast.isActive(groupToastType) &&
                  externalStorage.removeToast(groupToastType as ToastType, singularToastId);
              },
              onClick: () => toast.dismiss(singularToastId),
            });
          }
        });
      }
    });
  }, [openToasts]);

  const getGroupToastContent = (type: string) => {
    const content: Record<string, string> = {
      success: t('toast.completed'),
      error: t('toast.failed'),
      warning: t('toast.warnings'),
    };

    return content[type];
  };

  const getIcon = (type: TypeOptions): ReactNode => {
    const icons: Record<TypeOptions, ReactNode> = {
      success: <Icon SvgIcon={APP_ICONS.successsMedium} color="text-success" />,
      error: (
        <Icon
          SvgIcon={isDemoMode ? APP_ICONS.refreshArrows : APP_ICONS.serverErrorMedium}
          color={isDemoMode ? 'gray' : 'text-toast-warning-icon'}
        />
      ),
      info: <Icon SvgIcon={APP_ICONS.infoMedium} color="text-info" />,
      warning: <Icon SvgIcon={APP_ICONS.warningMedium} color="text-warning" />,
      default: <Icon SvgIcon={APP_ICONS.successsMedium} color="text-success" />,
    };

    return icons[type];
  };

  return (
    <ToastifyContainer
      limit={limit}
      autoClose={autoClose}
      hideProgressBar={true}
      icon={({ type }) => getIcon(type)}
      toastStyle={{ width: TOAST_WIDTH }}
      style={{ width: TOAST_WIDTH }}
    />
  );
};
