import { useCallback, useEffect, useMemo } from 'react';
import { SortingRule } from 'react-table';

import { ColumnStateType } from 'api';

import { externalStorage } from 'shared/ExternalStorage';
import { DataType } from 'view/Grid/utils/types';
import { isDefined } from 'utils';

export function useSorting(activeTypeTab: string, columnsState?: ColumnStateType[]) {
  const initial = useMemo(
    () =>
      columnsState
        ?.filter((column) => column.SortDesc !== undefined)
        .map((column) => ({
          id: column.Id,
          desc: column.SortDesc,
        }))
        .filter((it) => isDefined(it.desc)) || [],
    [columnsState]
  );

  const onChanged = useCallback(
    (sortBy: Array<SortingRule<DataType>>) => externalStorage.setSortByKey(activeTypeTab, sortBy),
    [activeTypeTab]
  );

  const onClear = () => externalStorage.clearSortByKey(activeTypeTab);

  useEffect(() => onClear, []);

  return {
    initial,
    onChanged,
  };
}
