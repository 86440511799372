import { FC, useEffect, useMemo } from 'react';

import { OptionType, UIInput, UiSelect } from 'ui';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { useRequest } from 'hook/request.hook';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

import styles from './SuccessorValue.component.module.scss';

type SuccessorValueProps = {
  isReadOnly?: boolean;
  schemaSuccessor: string;
  currentSuccessor: string;
  value: unknown;
  onChange: (value: unknown) => void;
};

export const SuccessorValue: FC<SuccessorValueProps> = ({
  value,
  currentSuccessor,
  schemaSuccessor,
  isReadOnly,
  onChange,
}) => {
  const { fetch, request } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const successorRequest = useRequest(request.getTypeHierarchy);

  useEffect(() => {
    if (schemaSuccessor) void successorRequest.fetch({ name: schemaSuccessor });
    if (currentSuccessor) void fetch.getAdditionalSchema({ type: currentSuccessor });
  }, []);

  const successorOptions = useMemo<OptionType[] | null>(
    () => successorRequest.state?.map(({ Label, Name }) => ({ label: Label, value: Name })) || null,
    [successorRequest.state]
  );
  const selectedOption = useMemo<OptionType | null>(
    () => successorOptions?.find((option: OptionType) => option.value === currentSuccessor) || null,
    [successorOptions, currentSuccessor]
  );

  const handleSuccessorsChanged = async (type: string | null) => {
    if (!type || type === currentSuccessor) return;

    const newData = await fetch.getFlatWithTypeDataFetch({ type, nextTabIndex: tabState.tabIndex + 1 });
    onChange?.(newData);
  };

  return (
    <div className={styles.SuccessorValue}>
      <div className={styles.SuccessorValue__value}>
        <UIInput readOnly selectOnFocus value={value} variant="transparent" />
      </div>
      {successorOptions && (
        <div className={styles.SuccessorValue__successor}>
          <UiSelect
            className="h-100"
            isTransparent
            menuPortalTarget={document.body}
            isDisabled={isReadOnly}
            value={selectedOption}
            options={successorOptions}
            onChange={(data) => void handleSuccessorsChanged(data?.value || null)}
          />
        </div>
      )}
    </div>
  );
};
