import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { APP_ICONS } from 'utils/icons';
import { handlerApi } from 'api';
import { Icon } from 'shared/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { useAppDispatch } from 'store/hooks';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import {
  setAllTasksStatuses,
  setAllCanceledHandlerIds,
  setRecordStatuses,
} from 'store/shared-reducers/ToolbarHandlerSlice';
import { env } from 'env';
import { ExecutedHandlerStatusData } from 'view/TypeToolbar/types';
import { showHandlerToast } from 'utils/helpers/showHandlerToast';
import { ToastTypeEnum } from 'utils';

import styles from './CancelButton.module.scss';

type CancelButtonType = {
  isReadOnly?: boolean;
};

export const CancelButton = ({ isReadOnly = false }: CancelButtonType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const environment = useSelector(selectActiveEnvironment);
  const dataset = useSelector(selectActiveDataset);

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    handlerApi
      .cancelAll<ExecutedHandlerStatusData[]>({
        dataSource: environment,
        dataset,
      })
      .then((data) => {
        if (env.isSSEHandlers || !data.length) return;
        const canceledIds = data.map((item) => item.TaskRunId);
        dispatch(setAllCanceledHandlerIds(canceledIds));
        dispatch(setAllTasksStatuses(data));
        dispatch(setRecordStatuses(data));
        showHandlerToast(ToastTypeEnum.SUCCESS, t('shared.success'));
      })
      .catch((error) => {
        showHandlerToast(ToastTypeEnum.ERROR, error);
      });
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button className={styles.button} onClick={onCancel} disabled={isReadOnly}>
          <div className={styles.iconContainer}>
            <Icon SvgIcon={APP_ICONS.cancelInCirlce} clickable={false} />
          </div>
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('header.cancelButton.tooltip')}</TooltipContent>
    </Tooltip>
  );
};
