import { FC } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { Toolbar } from 'shared/Toolbar/Toolbar';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { useDagContext } from '../../hooks';
import styles from './DagToolbar.module.scss';

export const DagToolbar: FC = () => {
  const { isUpdating, activateAddNodeMode } = useDagContext();

  return (
    <Toolbar>
      <Toolbar.Group>
        <Button
          className={cx(
            styles.button,
            'd-flex align-items-center d-flex bg-gray-600 gap-1 border-none text-text1 px-2 py-1 rounded'
          )}
          onClick={activateAddNodeMode}
        >
          <Icon SvgIcon={APP_ICONS.add} />
          <span className="fs-13 lh-16">Add node</span>
        </Button>
      </Toolbar.Group>
      <div>{isUpdating && <p className="text-text1 fs-13 lh-16 m-0">Updating...</p>}</div>
    </Toolbar>
  );
};
