import { FC, useEffect } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { useBoolean } from 'utils/hooks';

import { useChatHistoryToggleTooltip } from './hooks/useChatHistoryToggleTooltip';
import styles from './styles.module.scss';

type ChatHistoryToggleProps = {
  isOpen: boolean;
  isEmpty: boolean;
  isLoading: boolean;
};

export const ChatHistoryToggle: FC<ChatHistoryToggleProps> = ({ isLoading, isEmpty, isOpen }) => {
  const { tooltipMessage } = useChatHistoryToggleTooltip(isLoading, isEmpty, isOpen);
  const [isShowTooltip, { set: setTooltip, setFalse: hideTooltip }] = useBoolean();

  useEffect(() => {
    if (isOpen || isLoading) {
      hideTooltip();
    }
  }, [isOpen, isLoading]);

  return (
    <Tooltip open={isShowTooltip} onOpenChange={setTooltip}>
      <TooltipTrigger>
        <Button
          disabled={isLoading || isEmpty}
          className={cx(styles.toggle, 'd-flex align-items-center h-100 border-none bg-transparent p-0')}
        >
          <Icon SvgIcon={APP_ICONS.history} clickable={false} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{tooltipMessage}</TooltipContent>
    </Tooltip>
  );
};
