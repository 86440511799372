import cx from 'classnames';

import { CustomDropdown } from 'shared/CustomDropdown';
import { OptionType } from 'shared/CustomDropdown/types';

import styles from './index.module.scss';

type DropdownRowProps = {
  label: string;
  selectedOptionKey: string;
  options: OptionType[];
  onChange: (key: string) => void;
};

export const DropdownRow = ({ label, selectedOptionKey, options, onChange }: DropdownRowProps) => (
  <div className="row p-0 w-100">
    <div className={cx(styles.label, 'col-3 text-dropdown-row-label fs-13 d-flex align-items-center')}>{label}</div>
    <div className="col-9">
      <CustomDropdown selectedValue={selectedOptionKey} options={options} onChange={onChange} />
    </div>
  </div>
);
