import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

type QueryBuilderActionsProps = {
  onResetAll: () => void;
  onCancel: () => void;
  isApplyDisabled?: boolean;
  onApply: () => void;
};

export const QueryBuilderActions: FC<QueryBuilderActionsProps> = ({
  isApplyDisabled = false,
  onResetAll,
  onCancel,
  onApply,
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center p-2_5">
      <Button className="c-button c-button-destructive border-0 fs-14 text-uppercase" onClick={onResetAll}>
        {t('queryBuilder.resetAll')}
      </Button>
      <div className="ms-auto d-flex align-items-center gap-2">
        <Button className="c-button c-button-secondary border-0 fs-14 text-uppercase" onClick={onCancel}>
          {t('queryBuilder.close')}
        </Button>
        <Button
          className="c-button c-button-primary border-0 fs-14 text-uppercase"
          disabled={isApplyDisabled}
          onClick={onApply}
        >
          {t('queryBuilder.apply')}
        </Button>
      </div>
    </div>
  );
};
