import { useEffect, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import cx from 'classnames';

import { ProgressBarSpinner } from 'view/ProgressBarSpinner';
import { UiDropdownItem } from 'ui';

import { getSupportedLlms } from 'api/requests/llms.api';
import { useRequest } from 'hook/request.hook';
import { externalStorage } from 'shared/ExternalStorage';

import styles from './styles.module.scss';

export const ModelSwitcher = () => {
  const { getSelectedLlm, setSelectedLlm } = externalStorage;
  const [shownLlm, setShownLlm] = useState<string | null>(null);
  const { state: supportedLlms, fetch: fetchSupportedLlms } = useRequest(getSupportedLlms);

  useEffect(() => {
    fetchSupportedLlms().then((data) => {
      if (!getSelectedLlm()) {
        setSelectedLlm(data[0]);
        setShownLlm(data[0]);
      } else {
        setShownLlm(getSelectedLlm());
      }
    });
  }, []);

  const onDropdownItemClick = (llm: string) => () => {
    setSelectedLlm(llm);
    setShownLlm(llm);
  };

  return (
    <div className="d-flex gap-2 align-items-center">
      <span className="fs-13 text-text2">Chat Model:</span>
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          className={cx(
            styles.dropdownToggle,
            'px-2 py-0_5 bg-transparent border-gray-800 border-1 text-text1 rounded justify-content-between align-items-center'
          )}
        >
          {shownLlm || <ProgressBarSpinner />}
        </DropdownToggle>
        <DropdownMenu className="bg-header-search-dropdown-background">
          {supportedLlms?.map((model, id) => (
            <UiDropdownItem className={styles.dropdownItem} onClick={onDropdownItemClick(model)} key={id}>
              {model}
            </UiDropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
