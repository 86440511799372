import { MouseEvent, useMemo, useState } from 'react';
import { HeaderContext } from '@tanstack/react-table';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { UiDropdown } from 'ui';
import { useBoolean } from 'utils/hooks';
import { searchByName } from 'utils';

import { HeaderFilterCellActions, HeaderFilterCellList, HeaderFilterCellSearch } from './components';
import { useVisibilityColumns } from './hooks';
import './HeaderFilterCell.styles.scss';

type HeaderFilterCellProps<TData> = {
  context: HeaderContext<TData, unknown>;
};

export const HeaderFilterCell = <TData,>({ context }: HeaderFilterCellProps<TData>) => {
  const [isOpen, { toggle, setFalse: close }] = useBoolean();
  const [search, setSearch] = useState('');

  const columns = context.table.getAllLeafColumns().filter((column) => column.getCanHide());
  const filteredColumnsBySearch = useMemo(
    () => columns.filter((column) => searchByName(column.id, search)),
    [search, columns]
  );

  const {
    columnVisibility,
    isAllColumnsChecked,
    isEmptySelection,
    toggleColumnVisibleById,
    toggleAllColumnsVisibility,
    resetColumnsVisibility,
  } = useVisibilityColumns(columns);

  const handleDropdownToggle = (event: MouseEvent) => {
    event.stopPropagation();
    toggle();
  };

  const onCancel = () => {
    resetColumnsVisibility();
    close();
  };

  const onApply = () => {
    context.table.setColumnVisibility((prevColumnVisibility) => ({
      ...prevColumnVisibility,
      ...columnVisibility,
    }));
    close();
  };

  return (
    <UiDropdown
      className="header-filter-cell-dropdown"
      isOpen={isOpen}
      toggle={handleDropdownToggle}
      tag="div"
      toggleButton={<Icon SvgIcon={APP_ICONS.filter} color="text-icon1" />}
    >
      <div className="header-filter-cell">
        <HeaderFilterCellSearch
          visibility={columnVisibility}
          isEmptySelection={isEmptySelection}
          isAllColumnsChecked={isAllColumnsChecked}
          toggleAllColumnsVisibility={toggleAllColumnsVisibility}
          search={search}
          onChange={setSearch}
        />
        <HeaderFilterCellList
          columns={filteredColumnsBySearch}
          visibility={columnVisibility}
          toggleColumnVisibleById={toggleColumnVisibleById}
        />
        <HeaderFilterCellActions onApply={onApply} onCancel={onCancel} />
      </div>
    </UiDropdown>
  );
};
