import { ChangeEvent, FC } from 'react';
import cx from 'classnames';

import { UIInput, UIInputNumber } from 'ui';
import { ValueTypesEnum } from 'utils';

import { QueryBuilderValueCellProps } from '../../utils/types';
import {
  QueryBuilderBoolInput,
  QueryBuilderDateInput,
  QueryBuilderDateTimeInput,
  QueryBuilderTimeInput,
} from './components';

import styles from './QueryBuilderValueCell.module.scss';

export const QueryBuilderValueCell: FC<QueryBuilderValueCellProps> = ({
  isOptional,
  type,
  value,
  onChangeValue: onChange,
}) => {
  switch (type) {
    case ValueTypesEnum.Int:
    case ValueTypesEnum.Double:
    case ValueTypesEnum.Long:
      return (
        <UIInputNumber
          className={cx('w-100 bg-gray-500', styles.dropdown)}
          fullHeight
          maximumFractionDigits={type === 'Int' ? 0 : undefined}
          value={value}
          onChange={onChange}
        />
      );
    case ValueTypesEnum.String:
      return (
        <UIInput
          className={cx('w-100 bg-gray-500', styles.dropdown)}
          fullHeight
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        />
      );
    case ValueTypesEnum.Bool:
      return <QueryBuilderBoolInput isOptional={isOptional} value={value} onChange={onChange} />;
    case ValueTypesEnum.Date:
      return <QueryBuilderDateInput value={value} onChange={onChange} />;
    case ValueTypesEnum.DateTime:
      return <QueryBuilderDateTimeInput value={value} onChange={onChange} />;
    case ValueTypesEnum.Time:
      return <QueryBuilderTimeInput value={value} onChange={onChange} />;
    default:
      return null;
  }
};
