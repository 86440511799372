import { handlerApi, HandlerGetParamsRequestDto } from 'api';

import { ProfilesStructureModel } from '../model';
import { ProfileStructureDto } from '../dto';

export type ProfileStructureRequestParams = Pick<HandlerGetParamsRequestDto, 'dataSource' | 'dataset'>;

export type ChatApi = {
  profilesStructure(data: ProfileStructureRequestParams): Promise<ProfilesStructureModel>;
};

export const chatApi: ChatApi = {
  async profilesStructure(params: ProfileStructureRequestParams): Promise<ProfilesStructureModel> {
    return handlerApi
      .run<ProfileStructureDto>({
        key: 'Main',
        table: 'ProfilesStructure',
        method: 'GetProfilesStructure',
        ...params,
      })
      .then(ProfilesStructureModel.create);
  },
};
