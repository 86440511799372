import { handlerApi } from 'api';

export type RequestParamsType = {
  key: string;
  dataset: string;
  table: string;
  datasource: string;
  chatKey: string;
};

type ResponseType = [
  {
    key: 'message';
    value: {
      String: string;
    };
  },
  {
    key: 'status';
    value: {
      String: 'success' | 'error';
    };
  },
];

export const sendCloExclusionsRequest = ({ key, datasource, dataset, table, chatKey }: RequestParamsType) =>
  handlerApi.run<ResponseType>({
    key,
    table,
    dataset,
    method: 'SendCloExclusions',
    dataSource: datasource,
    args: {
      CurrentChat: chatKey,
    },
  });
