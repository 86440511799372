import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HeaderDataType } from 'view/Header/utils/types';

const initialState: { headerData: HeaderDataType } = {
  headerData: {
    language: 'eng',
  },
};

const HeaderDataSlice = createSlice({
  name: 'headerData',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.headerData.language = action.payload;
    },
  },
});

export const HeaderDataSliceReducer = HeaderDataSlice.reducer;
export const { setLanguage } = HeaderDataSlice.actions;
