import { FC, useEffect, useState } from 'react';

import { EditorSubmitType } from 'view/Editor/type';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { UiEmpty } from 'ui';
import { StorageEntityModel } from 'api';
import { EditorInstance } from 'view/EditorData/EditorInstance';

type EditorNewDagProps = {
  recordType: string;
  onCancel?: () => void;
  onUpdate?: (data: StorageEntityModel) => void;
};

export const EditorNewDag: FC<EditorNewDagProps> = ({ recordType, onCancel, onUpdate }) => {
  const { getSchema } = useEditorRequest();
  const [rootSchemaName, setRootSchemaName] = useState('');

  useEffect(() => {
    if (recordType) {
      getSchema
        .fetch({
          name: recordType,
        })
        .then((schema) => {
          setRootSchemaName(Object.keys(schema.typeList)?.find((it) => it.endsWith('Node')) || '');
        });
    }
  }, [recordType]);

  const handleSubmit = ({ data }: EditorSubmitType) => onUpdate?.(data);

  if (getSchema.isLoading) return <Loader />;

  if (!getSchema.state || !rootSchemaName) return <UiEmpty />;

  return (
    <EditorInstance
      schema={getSchema.state.typeList}
      rootSchemaName={rootSchemaName}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};
