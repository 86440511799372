import { ChangeEvent, Dispatch, FC, RefObject, SetStateAction, useEffect, useState } from 'react';
import cx from 'classnames';
import { PickersLayoutProps, TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { Box, PopperPlacementType } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';

import { Button } from 'shared/Button';
import { Icon } from 'shared/Icon';
import { DateFormatEnum, DateFormatUtility, KeyboardCode } from 'utils';
import { APP_ICONS } from 'utils/icons';

import { UIInput, UIInputProps } from './UIInput.component';
import { usePicker } from './hooks';

export type UIInputTimeProps<T = Dayjs> = Pick<
  TimePickerProps<T>,
  'value' | 'onChange' | 'format' | 'ampm' | 'className' | 'open' | 'onClose' | 'onOpen'
> & {
  pickerRef?: RefObject<HTMLDivElement>;
  pickerButtonRef?: RefObject<HTMLButtonElement>;
  disabledPickerButton?: boolean;
  pickerPlacement?: PopperPlacementType;
  inputProps?: UIInputProps;
};

export type UITimePickerLayoutProps<T = Dayjs> = PickersLayoutProps<T | null, T, TimeViewWithMeridiem>;

export type UITimePickerProps = {
  hours: string;
  minutes: string;
  seconds: string;
  setHours: Dispatch<SetStateAction<string>>;
  setMinutes: Dispatch<SetStateAction<string>>;
  setSeconds: Dispatch<SetStateAction<string>>;
  handleClosePicker: () => void;
  handleSubmit: () => void;
};

export const UIInputTime: FC<UIInputTimeProps> = ({
  pickerRef,
  pickerButtonRef,
  pickerPlacement = 'bottom-end',
  disabledPickerButton,
  className,
  ampm = false,
  format = DateFormatEnum.TIME,
  inputProps,
  ...rest
}) => {
  const classes = cx('ui-input-time', className);

  return (
    <TimePicker<Dayjs>
      format={format}
      ampm={ampm}
      className={classes}
      slots={{
        textField: UIInput,
        layout: UITimePickerLayout,
        openPickerIcon: APP_ICONS.clock,
      }}
      slotProps={{
        popper: {
          ref: pickerRef,
          placement: pickerPlacement,
        },
        openPickerButton: {
          ref: pickerButtonRef,
          disabled: disabledPickerButton,
        },
        textField: {
          placeholder: '',
          ...inputProps,
        },
      }}
      {...rest}
    />
  );
};

const UITimePickerLayout: FC<UITimePickerLayoutProps> = ({ value, onClose, onChange }) => {
  const { getStringWithRequiredDigits } = usePicker();

  const initialValue = value || DateFormatUtility.create().toInstance();

  const [hours, setHours] = useState<string>(getStringWithRequiredDigits(initialValue?.hour()));
  const [minutes, setMinutes] = useState<string>(getStringWithRequiredDigits(initialValue?.minute()));
  const [seconds, setSeconds] = useState<string>(getStringWithRequiredDigits(initialValue?.second()));

  const isTimeDefined = hours || minutes || seconds;

  const handleAcceptTime = () => {
    const updatedTime = isTimeDefined
      ? DateFormatUtility.create(`${hours}:${minutes}:${seconds}`, DateFormatEnum.TIME).toInstance()
      : null;

    onChange(updatedTime);
    onClose();
  };

  return (
    <UITimePicker
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      setHours={setHours}
      setMinutes={setMinutes}
      setSeconds={setSeconds}
      handleClosePicker={onClose}
      handleSubmit={handleAcceptTime}
    />
  );
};

export const UITimePicker: FC<UITimePickerProps> = ({
  hours,
  minutes,
  seconds,
  setHours,
  setMinutes,
  setSeconds,
  handleClosePicker,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const { hoursRef, minutesRef, secondsRef, updateHours, updateMinutes, updateSeconds, updateOnBlur } = usePicker();

  const isResetDisabled = [hours, minutes, seconds].every((v) => !v || v === '00');

  const handleHoursChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateHours(e.target.value, setHours);
  };

  const handleMinutesChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateMinutes(e.target.value, setMinutes);
  };

  const handleSecondsChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateSeconds(e.target.value, setSeconds);
  };

  const handleClearTime = () => {
    setHours('00');
    setMinutes('00');
    setSeconds('00');
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    updateFn: Dispatch<SetStateAction<string>>
  ) => {
    updateOnBlur(e.target.value, updateFn);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.code === KeyboardCode.Enter) {
      (e.target as HTMLInputElement).blur();
    }
  };

  useEffect(() => {
    if (hoursRef.current) {
      hoursRef.current.focus();
      hoursRef.current.setSelectionRange(0, hours.length);
    }
  }, []);

  return (
    <Box
      padding="16px 12px 12px"
      display="flex"
      flexDirection="column"
      rowGap="15px"
      bgcolor="var(--bs-primary)"
      borderRadius="5px"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex" alignItems="center" columnGap="6px" color="var(--bs-text1)">
        <Box width={43} height={24} bgcolor="var(--bs-gray-400)" borderRadius="2px">
          <UIInput
            inputRef={hoursRef}
            placeholder="hh"
            fullHeight
            rounded
            textAlign="center"
            value={hours}
            onChange={handleHoursChange}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={(e) => handleBlur(e, setHours)}
          />
        </Box>
        {':'}
        <Box width={43} height={24} bgcolor="var(--bs-gray-400)" borderRadius="2px">
          <UIInput
            inputRef={minutesRef}
            placeholder="mm"
            fullHeight
            rounded
            textAlign="center"
            value={minutes}
            onChange={handleMinutesChange}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={(e) => handleBlur(e, setMinutes)}
          />
        </Box>
        {':'}
        <Box width={43} height={24} bgcolor="var(--bs-gray-400)" borderRadius="2px">
          <UIInput
            inputRef={secondsRef}
            rounded
            placeholder="ss"
            fullHeight
            textAlign="center"
            value={seconds}
            onChange={handleSecondsChange}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={(e) => handleBlur(e, setSeconds)}
          />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="var(--bs-gray-300)"
          borderRadius="2px"
          height={24}
          width={35}
          marginLeft="5px"
          onClick={handleClearTime}
        >
          <Icon SvgIcon={APP_ICONS.goBack} disable={isResetDisabled} />
        </Box>
      </Box>

      <Box display="flex" columnGap="12px" justifyContent="end">
        <Button title={t('buttons.cancel')} variant="secondary" onClick={handleClosePicker} autoMinWidth />
        <Button title={t('buttons.apply')} onClick={handleSubmit} autoMinWidth />
      </Box>
    </Box>
  );
};
