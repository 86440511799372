import { FC } from 'react';

import { getIconSize, IconProps } from '../type';

export const InfoIcon: FC<IconProps> = ({ color = 'currentColor', className, size, width, height, onClick }) => (
  <svg
    className={className}
    width={width || getIconSize(size)}
    height={height || getIconSize(size)}
    onClick={onClick}
    fill={color}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM9,13H7V7H9ZM9,5H7V3H9Z" />
  </svg>
);
