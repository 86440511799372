import cx from 'classnames';
import { Row } from 'react-table';
import { ChangeEvent, FC } from 'react';

import { DataType } from 'view/Grid/utils/types';

export type CheckboxSelectionAll = {
  rows: Row<DataType>[];
  selectedRows: Record<string, boolean>;
  onSelectedCheckboxChange?: (selection: Record<string, boolean>) => void;
};

export const CheckboxSelectionAll: FC<CheckboxSelectionAll> = ({ rows, selectedRows, onSelectedCheckboxChange }) => {
  const isSelectedAll = rows.every((row) => selectedRows[row.index]);

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const updatedSelection = rows.reduce(
        (acc, row) => ({
          ...acc,
          [row.index]: e.target.checked,
        }),
        {}
      );

      return onSelectedCheckboxChange?.(updatedSelection);
    }

    return onSelectedCheckboxChange?.({});
  };

  return rows.length > 0 ? (
    <div className={cx('d-flex justify-content-center align-items-center h-100')}>
      <input className="form-check-input" type="checkbox" onChange={handleSelectAll} checked={isSelectedAll} />
    </div>
  ) : null;
};
