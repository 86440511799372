import { FC, PropsWithChildren, useCallback } from 'react';

import { useCallbackRef } from 'shared/Hooks/useCallbackRef';

type UiDropdownMenuWrapperProps = PropsWithChildren<{
  onClickAway?: () => void;
  onScrollAway?: () => void;
  onResize?: () => void;
}>;

export const UiDropdownMenuWrapper: FC<UiDropdownMenuWrapperProps> = ({
  children,
  onClickAway,
  onScrollAway,
  onResize,
}) => {
  const onRefCallback = useCallbackRef(
    useCallback((node: HTMLDivElement) => {
      const resizeHandler = () => onResize?.();

      const mousedownHandler = (event: MouseEvent) => {
        const isClickInside = event.target instanceof HTMLElement ? node.contains(event.target) : false;
        if (!isClickInside) {
          onClickAway?.();
        }
      };

      const scrollHandler = (event: Event) => {
        const isScrollInside = event.target instanceof HTMLElement ? event.target.contains(node) : false;
        if (!isScrollInside) {
          onScrollAway?.();
        }
      };

      window.addEventListener('resize', resizeHandler, true);
      window.addEventListener('mousedown', mousedownHandler, true);
      window.addEventListener('scroll', scrollHandler, true);

      return () => {
        window.removeEventListener('resize', resizeHandler, true);
        window.removeEventListener('mousedown', mousedownHandler, true);
        window.removeEventListener('scroll', scrollHandler, true);
      };
    }, [])
  );

  return <div ref={onRefCallback}>{children}</div>;
};
