import { createContext, FC, ReactNode, useState } from 'react';

import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

export const EditorTabContextWrapper = createContext<EditorTabContextType | null>(null);

export type EditorTabContextType = {
  state: EditorTabContextState;
};

export type EditorTabContextState = {
  tabIndex: number;
  isActive: boolean;
  tab: EditorTabType;
};

export type EditorTabContextProps = {
  tabIndex: number;
  tab: EditorTabType;
  children: ReactNode;
};

export const EditorTabContext: FC<EditorTabContextProps> = ({ tabIndex, tab, children }) => {
  const { state } = useEditorState();
  const [tabIndexState] = useState(tabIndex);
  const [tabState] = useState(tab);
  const isActive = state.breadcrumbsData.activeTabIndex === tabIndexState;

  const stateValue: EditorTabContextType = {
    state: {
      tabIndex: tabIndexState,
      tab: tabState,
      isActive,
    },
  };

  return <EditorTabContextWrapper.Provider value={stateValue}>{children}</EditorTabContextWrapper.Provider>;
};
