import { useCallback, useMemo, useState } from 'react';

import {
  getActiveChatSessionRequest,
  RequestParamsType as GetActiveChatSessionRequestParamsType,
} from './utils/getActiveChatSessionRequest';
import { ChatType, SessionType } from '../../utils/types';

export function useActiveChat() {
  const [activeChat, setActiveChat] = useState<SessionType>();
  const [chatType, setChatType] = useState<ChatType>(ChatType.INTERNAL);

  const isEncryptedType = useMemo(() => chatType === ChatType.ENCRYPTED, [chatType]);
  const isInternalType = useMemo(() => chatType === ChatType.INTERNAL, [chatType]);
  const isPrivateType = useMemo(() => chatType === ChatType.PRIVATE, [chatType]);

  const change = useCallback((type: ChatType) => setChatType(type), []);

  const getActiveChat = useCallback(
    (params: GetActiveChatSessionRequestParamsType) =>
      getActiveChatSessionRequest(params)
        .then((response) => {
          if (!response) return null;

          setActiveChat(response);

          change(response.IsEncrypted ? ChatType.PRIVATE : ChatType.INTERNAL);

          return response;
        })
        .catch(() => setActiveChat(undefined)),
    [change]
  );

  return {
    activeChat,
    chatType,
    isEncryptedType,
    isInternalType,
    isPrivateType,
    change,
    getActiveChat,
    setActiveChat,
  };
}
