import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { TabItemContent } from 'containers/TabItemContent';
import { GridTabs } from 'view/GridTabs';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';
import { ViewersMetadataType } from 'store/types';
import { VerticalSplitView } from 'shared/VerticalSplitView';
import { selectSchemaTypes } from 'store/selectors';
import { Logs } from 'view/Logs';
import { SchemaSearchType } from 'view/Header/utils/types';
import { isArray } from 'utils';
import { useComponentsMapper } from 'shared/Hooks/useComponentsMapper';

type MainProps = {
  mainContainerClassname?: string;
  gridTabsContainerClassname?: string;
};

export const Main: FC<MainProps> = ({ mainContainerClassname, gridTabsContainerClassname }: MainProps) => {
  const { t } = useTranslation();
  const { activeTab } = useTabParams();
  const { schemaTypes } = useSelector(selectSchemaTypes);

  const { InitialScreen } = useComponentsMapper();

  const areSchemaTypesExist = useMemo(
    () =>
      Object.keys(schemaTypes).some((key) => {
        const typedKey = key as keyof SchemaSearchType;
        return isArray(schemaTypes[typedKey]) && schemaTypes[typedKey].length > 0;
      }),
    [schemaTypes]
  );

  return (
    <div className={cx('d-flex justify-content-center flex-column h-100 overflow-auto', mainContainerClassname)}>
      <VerticalSplitView
        leftPane={
          <div className="h-100 d-flex flex-column">
            {activeTab?.id ? (
              <>
                <span className={gridTabsContainerClassname}>
                  <GridTabs activeTabId={activeTab.id} />
                </span>
                <TabItemContent
                  activeTabId={activeTab.id}
                  options={{
                    dynamicId: activeTab.dynamicType?.id,
                    isDynamicDashboard: activeTab.dynamicType?.type === ViewersMetadataType.DASHBOARD,
                    isDynamicPrimaryDashboard: activeTab.dynamicType?.type === ViewersMetadataType.PRIMARY_DASHBOARD,
                  }}
                />
              </>
            ) : (
              <InitialScreen
                title={t(areSchemaTypesExist ? 'grid.noTypesSelected' : 'grid.pleaseContantTheAdministrator')}
              />
            )}
          </div>
        }
        rightPane={Logs}
      />
    </div>
  );
};
