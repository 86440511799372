import { useCallback, useMemo, useState } from 'react';

import { SchemaTypeModel, StorageSelectDto, SchemaTypeListModel } from 'api';
import { useBoolean } from 'utils/hooks';
import { isEmpty, isNullable } from 'utils';
import { mapSchemaToElements, QueryBuilderHeader, QueryBuilderHeaderProps, QueryType } from 'view/QueryBuilder';

export function useQueryBuilder(defaultShow = false) {
  const [isShow, { setTrue: show, setFalse: close }] = useBoolean(defaultShow);
  const [query, setQuery] = useState<QueryType>({});
  const isApplied = useMemo(() => !isEmpty(query), [query]);

  const renderHeader = useCallback(
    (props: QueryBuilderHeaderProps) => <QueryBuilderHeader {...props} onClose={close} />,
    []
  );

  const generateElementsBySchema = (schema?: SchemaTypeModel, schemaTypeList?: SchemaTypeListModel) =>
    schema && schemaTypeList ? mapSchemaToElements(schema, schemaTypeList) : [];

  const mapQueryToDTO = (): StorageSelectDto =>
    Object.keys(query).reduce((dic, key) => {
      const { condition, value } = query[key];
      if (isNullable(value)) return dic; // Remove empty values because API doesn't support them.
      return { ...dic, [key]: { [condition]: value } };
    }, {});

  const apply = (updatedQuery: QueryType) => setQuery({ ...updatedQuery }); // The cloning was added to update the reference and force rerender.

  const reset = () => setQuery({});

  return {
    isApplied,
    renderHeader,
    isShow,
    query,
    mapQueryToDTO,
    generateElementsBySchema,
    close,
    show,
    apply,
    reset,
  };
}
