import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunHandlerStatusCodeEnum } from 'api';

import { ToolbarHandlerType } from 'store/types';
import { RowKeyDataType, ToolbarHandlerStateType } from 'store/types/toolbarHandlerType';
import { ExecutedHandlerStatusData, ExecutedHandlerRunTaskDataType } from 'view/TypeToolbar/types';

type ExecutableHandlerDataType = {
  tableName: string;
  handlerId: string;
  rowsData: ExecutedHandlerRunTaskDataType[];
};

type TaskHandlerStatusDataType = {
  tableName: string;
  handlerId: string;
  taskRunArr?: ExecutedHandlerStatusData[];
  keys?: string[];
  statusCode?: RunHandlerStatusCodeEnum;
};

const initialState: ToolbarHandlerStateType = {
  toolbarHandler: {},
  canceledHanderIds: [],
  recordStatuses: {},
};

const ToolbarHandlerSlice = createSlice({
  name: 'toolbarHandler',
  initialState,
  reducers: {
    setRunHandlersData(state, action: PayloadAction<ExecutableHandlerDataType>) {
      const { tableName, handlerId, rowsData } = action.payload;
      const keysObj: RowKeyDataType = {};

      rowsData.forEach((item) => {
        keysObj[item.Key] = {
          TaskRunId: item.TaskRunId,
          StatusCode: RunHandlerStatusCodeEnum.Running,
        };
      });

      const innerKeys = (state.toolbarHandler[tableName] && state.toolbarHandler[tableName][handlerId]) || {};

      state.toolbarHandler = {
        ...state.toolbarHandler,
        [tableName]: {
          ...state.toolbarHandler[tableName],
          [handlerId]: { ...innerKeys, ...keysObj },
        },
      };
    },
    setTaskHandlerStatus(state, action: PayloadAction<TaskHandlerStatusDataType>) {
      const { tableName, handlerId, taskRunArr } = action.payload;

      const changeStatuses = () => {
        const newObj: RowKeyDataType = {};

        if (!state.toolbarHandler[tableName]) return {};

        Object.keys(state.toolbarHandler[tableName][handlerId]).forEach((taskKey) => {
          const filteredTaskData = taskRunArr?.find(
            (item) => item.TaskRunId === state.toolbarHandler[tableName][handlerId][taskKey].TaskRunId
          );
          if (filteredTaskData) {
            newObj[taskKey] = filteredTaskData;
          }
        });

        return newObj;
      };

      const prevHandlerData = state.toolbarHandler[tableName] ? state.toolbarHandler[tableName][handlerId] : {};

      state.toolbarHandler = {
        ...state.toolbarHandler,
        [tableName]: {
          ...state.toolbarHandler[tableName],
          [handlerId]: {
            ...prevHandlerData,
            ...changeStatuses(),
          },
        },
      };
    },
    setAllHandlerTasksStatus(state, action: PayloadAction<TaskHandlerStatusDataType>) {
      const { tableName, handlerId, keys, statusCode } = action.payload;
      const { toolbarHandler } = state;

      const newHandlerObj: RowKeyDataType =
        tableName in toolbarHandler && handlerId in toolbarHandler[tableName]
          ? { ...toolbarHandler[tableName][handlerId] }
          : {};

      keys?.forEach((item) => {
        newHandlerObj[item] = { TaskRunId: '', StatusCode: statusCode as string }; //  TODO CHECK STATUS TYPE
      });

      state.toolbarHandler = {
        ...toolbarHandler,
        [tableName]: {
          ...toolbarHandler[tableName],
          [handlerId]: {
            ...newHandlerObj,
          },
        },
      };
    },
    setAllTasksStatuses(state, action: PayloadAction<ExecutedHandlerStatusData[]>) {
      const taskRunArr = action.payload;
      const handlersObjCopy: ToolbarHandlerType = JSON.parse(JSON.stringify(state.toolbarHandler));

      Object.keys(handlersObjCopy).forEach((tableName) => {
        Object.keys(handlersObjCopy[tableName]).forEach((handlerId) => {
          Object.keys(handlersObjCopy[tableName][handlerId]).forEach((key) => {
            const newtaskStatusObj = taskRunArr.find(
              (item) => item.TaskRunId === handlersObjCopy[tableName][handlerId][key].TaskRunId
            );
            if (newtaskStatusObj) {
              handlersObjCopy[tableName][handlerId][key] = newtaskStatusObj;
            }
          });
        });
      });

      state.toolbarHandler = handlersObjCopy;
    },
    setCanceledHandlerIds(state, action: PayloadAction<string[]>) {
      state.canceledHanderIds = [...state.canceledHanderIds, ...action.payload];
    },
    setAllCanceledHandlerIds(state, action: PayloadAction<string[]>) {
      state.canceledHanderIds = action.payload;
    },

    setRecordStatuses(state, action: PayloadAction<ExecutedHandlerStatusData[]>) {
      const data = action.payload;
      const newRecordStatuses: Record<string, string> = { ...state.recordStatuses }; //  TODO CHECK STATUS TYPE
      data.forEach(({ Key, StatusCode }) => {
        if (Key) newRecordStatuses[Key] = StatusCode;
      });

      state.recordStatuses = newRecordStatuses;
    },

    setSSERecordStatuses(state, action: PayloadAction<{ Key: string; StatusCode: string }[]>) {
      const data = action.payload;
      const newRecordStatuses: Record<string, string> = { ...state.recordStatuses };

      data.forEach(({ Key, StatusCode }) => {
        newRecordStatuses[Key] = StatusCode;
      });

      state.recordStatuses = newRecordStatuses;
    },
    removeHandlerRecordsData(state) {
      state.recordStatuses = {};
    },
    clearHandlerState(state) {
      state.toolbarHandler = {};
      state.canceledHanderIds = [];
      state.recordStatuses = {};
    },
  },
});

export const ToolbarHandlerSliceReducer = ToolbarHandlerSlice.reducer;
export const {
  setRunHandlersData,
  setTaskHandlerStatus,
  setAllHandlerTasksStatus,
  setAllTasksStatuses,
  setCanceledHandlerIds,
  setAllCanceledHandlerIds,
  setRecordStatuses,
  setSSERecordStatuses,
  removeHandlerRecordsData,
  clearHandlerState,
} = ToolbarHandlerSlice.actions;
