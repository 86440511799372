import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { UiDropdownItem } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type ChatHistoryFooterProps = {
  className?: string;
  isDisabled: boolean;
  deleteSelected: () => void;
};

export const ChatHistoryFooter: FC<ChatHistoryFooterProps> = ({ className, isDisabled, deleteSelected }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(className, 'd-flex px-2 py-1')}>
      <UiDropdownItem
        className={cx(
          styles.button,
          'btn d-flex gap-2 align-items-center fs-13 border-none bg-transparent text-text1 px-0 py-1'
        )}
        disabled={isDisabled}
        onClick={deleteSelected}
      >
        <Icon SvgIcon={APP_ICONS.delete} clickable={false} />
        <span className="text-capitalize">{t('chat.deleteSelectedChats')}</span>
      </UiDropdownItem>
    </div>
  );
};
