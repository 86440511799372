import { FC, useEffect } from 'react';

import { EditorSubmitType } from 'view/Editor/type';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { useAppContext } from 'context';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { EditorUpdateType } from 'view/EditorData/type';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';

type EditorEntityProps = {
  dataset?: string;
  isReadOnly?: boolean;
  isDisableKeyFields?: boolean;
  recordType: string;
  recordKey: string;
  onTransition?: (data: TransitionRecordType) => void;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const EditorEntity: FC<EditorEntityProps> = ({
  isDisableKeyFields,
  dataset,
  isReadOnly,
  recordType,
  recordKey,
  onUpdate,
  onTransition,
}) => {
  const { action } = useAppContext();
  const { getRecord, saveEntity } = useEditorRequest();

  useEffect(() => {
    if (recordType && recordKey) getEditorData();
  }, [recordType, dataset, recordKey]);

  const getEditorData = (newKey?: string) => {
    void getRecord.fetch({
      key: newKey || recordKey,
      type: recordType,
      dataset,
    });
  };

  const handleSubmit = ({ data }: EditorSubmitType) => {
    saveEntity.fetch({ data, recordType, oldRecordKey: recordKey }).then((result) => {
      onUpdate?.({
        oldKey: recordKey,
        newKey: result.key,
      });
      action.setIsEditorDataChangedAction(false);
    });
  };

  // ToDo: check the getRecord.state
  if (getRecord.isLoading || !getRecord.hasInitFetch) return <Loader />;

  if (!getRecord.state) return <UiEmpty />;

  return (
    <EditorInstance
      isDisableKeyFields={isDisableKeyFields}
      readOnly={isReadOnly}
      data={getRecord.state.data}
      schema={getRecord.state.schema.typeList}
      rootSchemaName={getRecord.state.schema.rootType}
      onTransition={onTransition}
      onCancel={() => getEditorData()}
      onSubmit={handleSubmit}
      onChangeData={action.setIsEditorDataChangedAction}
    />
  );
};
