import { axiosMain } from 'api';
import { ProfileStructureDto } from 'api/data/profileStructure.data';

export async function getDashboardItems(): Promise<ProfileStructureDto[]> {
  const res = await axiosMain.get<{ data: { ProfileStructureHeaderElements: ProfileStructureDto[] } }>(
    '/storage/record?type=DashboardStructure&key=Main&ignore_record_absence=true'
  );

  return res.data.data.ProfileStructureHeaderElements;
}
