import { isDefined } from 'utils';

export const getPropByPath = (object: unknown, paths: string[] = []) => {
  if (paths.length === 0) return object;

  return paths.reduce((dir, pathItem) => {
    if (!pathItem) return dir;
    const pathValue = (dir as Record<string, unknown>)?.[pathItem];
    return isDefined(pathValue) ? pathValue : null;
  }, object);
};
