import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import useResizeObserver from 'use-resize-observer';

import { CarouselContext } from 'shared/Carousel/Context';
import { CarouselItemType } from 'shared/Carousel/utils/types';

type CarouselType = {
  /** @desc the minimal visible size of the previous or next item in the carousel */
  viewSize?: number;
  /** @desc the gap in px between items */
  gap?: number;
  activeIndex: number;
  onChange: (index: number) => void;
  children: ReactNode;
};

export const Carousel: FC<CarouselType> = ({ viewSize = 100, children, gap = 8, activeIndex, onChange }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [items, setItems] = useState<CarouselItemType[]>([]);

  const activeId = items[activeIndex]?.id;

  const scrollTo = (left: number) => {
    if (!ref.current) return;

    ref.current.scrollTo({
      left,
      behavior: 'smooth',
    });
  };

  const registerItem = (itemId: string, item: CarouselItemType) => {
    setItems((prevItems) => {
      const isExists = prevItems.find((prevItem: CarouselItemType) => prevItem.id === itemId);
      if (isExists) return prevItems;

      return [...prevItems, item];
    });
  };

  const unregisterItem = (itemId: string) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  const setActiveId = (id: string) => {
    const index = items.findIndex((item) => item.id === id);
    onChange(index);
  };

  const adjustActive = () => {
    const activeCoordinate = items
      .slice(0, activeIndex)
      .reduce((coordinate, item) => item.getBoundingWidth() + coordinate + gap, 0);

    scrollTo(activeCoordinate ? activeCoordinate - viewSize : activeCoordinate);
  };

  useEffect(() => {
    if (activeId) adjustActive();
  }, [activeId]);

  const { ref: resizeRef } = useResizeObserver({
    onResize: () => {
      adjustActive();
    },
  });

  const mergedRef = useMergeRefs([resizeRef, ref]);

  return (
    <div ref={mergedRef} style={{ gap }} className="d-flex h-100 overflow-y-hidden overflow-x-auto">
      <CarouselContext.Provider
        value={{
          activeId,
          setActiveId,
          registerItem,
          unregisterItem,
        }}
      >
        {children}
      </CarouselContext.Provider>
    </div>
  );
};
