import { FC } from 'react';
import { InputAdornment } from '@mui/material';
import cx from 'classnames';
import { Button } from 'reactstrap';

import useToggle from 'utils/hooks/useToggle';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';

import { UIInput, UIInputProps } from './UIInput.component';

export type UIInputPasswordProps = Omit<UIInputProps, 'type'>;

export const UIInputPassword: FC<UIInputPasswordProps> = ({ className, ...rest }) => {
  const [isShowPassword, { toggle }] = useToggle(false);
  const classes = cx('ui-input-password', className);

  return (
    <UIInput
      className={classes}
      type={isShowPassword ? 'text' : 'password'}
      suffixElement={
        <InputAdornment position="end">
          <Button className="bg-transparent border-0 p-0" onClick={toggle}>
            <Icon SvgIcon={isShowPassword ? APP_ICONS.visibilityOn : APP_ICONS.visibilityOff} />
          </Button>
        </InputAdornment>
      }
      {...rest}
    />
  );
};
