import { serialize } from 'utils';

import { EntitiesDeleteModel, EntityListPanelModel, EntityPanelModel, EntitySaveModel } from 'api';

import {
  EntityListPanelsRequestDto,
  EntityPanelRequestDto,
  EntitySaveRequestDto,
  EntitiesDeleteRequestDto,
} from '../data/entity.data';
import { axiosMain } from '../axios';

export type EntityApi = {
  getListPanels(data: EntityListPanelsRequestDto): Promise<EntityListPanelModel[]>;
  getPanel(data: EntityPanelRequestDto): Promise<EntityPanelModel>;
  saveEntity(data: EntitySaveRequestDto): Promise<EntitySaveModel>;
  deleteEntities(data: EntitiesDeleteRequestDto): Promise<EntitiesDeleteModel>;
  saveLayout(data: EntitySaveRequestDto): Promise<EntitySaveModel>;
};

export const entityApi: EntityApi = {
  async getListPanels({ type, key, dataset }: EntityListPanelsRequestDto): Promise<EntityListPanelModel[]> {
    const res = await axiosMain.get<EntityListPanelModel[]>('/entity/list_panels', {
      params: {
        type,
        key,
        dataset,
      },
    });

    return res.data;
  },
  async getPanel({ type, panelId, key, dataset }: EntityPanelRequestDto): Promise<EntityPanelModel> {
    const res = await axiosMain.get<EntityPanelModel>('/entity/panel', {
      params: {
        type,
        key,
        dataset,
        panel_id: panelId,
      },
    });

    return res.data;
  },

  async saveEntity({ data, oldRecordKey }: EntitySaveRequestDto): Promise<EntitySaveModel> {
    const res = await axiosMain.post<EntitySaveModel>('/entity/save', serialize(data), {
      headers: { 'Content-Type': 'application/json' },
      params: { old_record_key: oldRecordKey },
    });
    return res.data;
  },

  async deleteEntities(data: EntitiesDeleteRequestDto): Promise<EntitiesDeleteModel> {
    const res = await axiosMain.post<EntitiesDeleteModel>('/entity/delete_many', serialize(data), {
      headers: { 'Content-Type': 'application/json' },
    });
    return res.data;
  },

  async saveLayout({ data, oldRecordKey }: EntitySaveRequestDto): Promise<EntitySaveModel> {
    const res = await axiosMain.post<EntitySaveModel>('/entity/save', serialize(data), {
      headers: { 'Content-Type': 'application/json' },
      params: { old_record_key: oldRecordKey },
    });
    return res.data;
  },
};
