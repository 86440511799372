import { FC, ReactNode } from 'react';

import styles from './VariantSelectList.component.module.scss';

export type VariantSelectListProps = {
  children: ReactNode;
};

export const VariantSelectList: FC<VariantSelectListProps> = ({ children }) => (
  <div className={styles.VariantSelectList}>{children}</div>
);

export type VariantSelectListItemProps = {
  title?: string;
  children: ReactNode;
};

export const VariantSelectListItem: FC<VariantSelectListItemProps> = ({ title, children }) => (
  <>
    <div className={styles.VariantSelectListItem__title}>{title}</div>
    <div className={styles.VariantSelectListItem__value}>{children}</div>
  </>
);
