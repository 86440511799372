import { FC, ReactPortal, useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { Grid } from 'view/Grid';
import { GridModeEnum } from 'view/Grid/utils/types';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { TableToolbar, TableToolbarItemProps } from 'view/Editor/components/EditorView/TableToolbar';
import { APP_ICONS } from 'utils/icons';
import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

import { generateDataHelper, generateSimpleColumnsHelper } from './utils/helpers';

type SimpleVectorViewProps = {
  data: EditorViewDataType[];
  tab: EditorTabType;
};

export const SimpleVectorView: FC<SimpleVectorViewProps> = ({ data, tab }) => {
  const id = useId();
  const { t } = useTranslation();
  const { action, state } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const memoColumns = useMemo(() => generateSimpleColumnsHelper(data, tab.path), [data]);
  const memoData = useMemo(() => generateDataHelper(data), [data]);

  const listHeaders = useMemo(() => data?.map((it) => it.elementKey), [data]);

  const handleAddListElement = () => {
    action.addGroupListElementAction({ path: tab.path, headers: listHeaders, rowCount: memoData.length });
  };
  const handleRemoveListElement = () => {
    action.removeGroupListElementAction({ path: tab.path, headers: listHeaders, rowCount: memoData.length });
  };

  const toolbarItems: TableToolbarItemProps[] = [
    {
      key: 'addElement',
      icon: APP_ICONS.add,
      tooltipContent: t('editor.listToolbar.add'),
      isDisabled: false,
      onClick: handleAddListElement,
    },
    {
      key: 'deleteElement',
      icon: APP_ICONS.delete,
      tooltipContent: t('editor.listToolbar.remove'),
      isDisabled: false,
      onClick: handleRemoveListElement,
    },
  ];

  const renderPortalToolbar = (): ReactPortal | null => {
    if (!state.editorToolbarRef || !tabState.isActive || state.isReadOnly) return null;

    return createPortal(<TableToolbar items={toolbarItems} />, state.editorToolbarRef);
  };

  return (
    <div>
      {renderPortalToolbar()}
      <Grid
        gridId={tab?.path.length === 0 ? 'root' : id}
        columns={memoColumns}
        data={memoData}
        gridMode={GridModeEnum.EDITOR}
        isCountRowCellExist
      />
    </div>
  );
};
