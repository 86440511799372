import { FC } from 'react';

import { getIconSize, IconProps } from '../type';

export const WarningIcon: FC<IconProps> = ({ color = 'currentColor', className, size, width, height, onClick }) => (
  <svg
    className={className}
    width={width || getIconSize(size)}
    height={height || getIconSize(size)}
    onClick={onClick}
    viewBox="0 0 42 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21 0L0 36H42L21 0ZM23 31H19V27H23V31ZM19 23V12H23V23H19Z" fill={color} />
  </svg>
);
