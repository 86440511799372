import { CellProps } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { TableBodyCell } from 'view/Editor/components/TableBody/TableBodyCell';
import { CellPropsType } from 'view/Editor/helpers/getCellProps.helper';
import { useComplexCell } from 'view/Editor/components/TableBody/TableBodyCell/hooks/useComplexCell';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { GoToDrilledDataIcon } from 'view/Editor/components/TableBody/TableBodyCell/GoToDrilledDataIcon';
import { isArray, isDefined } from 'utils';
import { LengthIcon } from 'view/Editor/components/TableBody/TableBodyCell/LengthIcon';

type ComplexVectorCellProps = CellProps<DataType> &
  CellPropsType & {
    currentTabIndex: number;
  };

export const ComplexVectorCell = (props: ComplexVectorCellProps) => {
  const { action } = useEditorState();
  const { cell, isVector, isFlat, path } = props;
  const rowIndex = props.row.index;
  const cellKey = path.toString();
  const isDrillExist = (isVector || isFlat) && isDefined(rowIndex);

  const { checkReadOnly, checkViewOnly } = useComplexCell({
    ...props,
    isReadOnly: !!cell.column.isReadOnly,
  });

  const handleChange = (updateValue: unknown) => {
    action.updateEditorData({
      path,
      updateValue,
    });
  };

  const handleAddTab = () => {
    action.openEditorTabAction(
      {
        title: props.elementKey,
        key: cellKey,
        path,
        isVector,
        valueType: props.valueType,
        moduleName: props.moduleName,
        schemaPath: props.schemaPath,
        isFlat: props.isFlat,
        selector: props.selector || '',
      },
      props.currentTabIndex
    );
  };

  return (
    <TableBodyCell
      key={cellKey}
      isVector={isVector}
      keyName={props.keyName}
      keyModuleName={props.keyModuleName}
      path={path}
      elementName={cell.column.Header as string}
      originalValue={props.value}
      schemaType={props.schemaType}
      enumSelector={props.enumSelector}
      valueType={cell.column.columnType}
      isRequired={!!cell.column.isRequired}
      isReadOnly={checkReadOnly()}
      isViewOnly={checkViewOnly()}
      suffixElement={isArray(props.value) && <LengthIcon length={props.value.length} />}
      prefixElement={isDrillExist && <GoToDrilledDataIcon onClick={handleAddTab} />}
      onChange={handleChange}
    />
  );
};
