import { Hooks, TableInstance, TableState, actions } from 'react-table';

import { DataType, SelectedRowsRangeType } from 'view/Grid/utils/types';

actions.updateSelectedRowsPerPageState = 'updateSelectedRowsPerPageState';

interface ActionType {
  type: string;
  newCount: number;
  newState: SelectedRowsRangeType;
}

function reducer(state: TableState<DataType>, action: ActionType) {
  if (action.type === actions.init) {
    return {
      ...state,
      selectedRowsPerPageState: {},
    };
  }

  if (action.type === actions.updateSelectedRowsPerPageState) {
    const { newState } = action;

    return {
      ...state,
      selectedRowsPerPageState: newState,
    };
  }

  return undefined;
}

function useInstance(instance: TableInstance<DataType>) {
  const { dispatch } = instance;

  const updateSelectedRowsPerPageState = (newState: SelectedRowsRangeType) => {
    dispatch({ type: actions.updateSelectedRowsPerPageState, newState });
  };

  Object.assign(instance, {
    updateSelectedRowsPerPageState,
  });
}

export const useFilterColumnCheckBox = (hooks: Hooks) => {
  hooks.stateReducers.push(reducer as never);
  hooks.useInstance.push(useInstance);
};

useFilterColumnCheckBox.pluginName = 'useFilterColumnCheckBox';
