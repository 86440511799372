export enum ContentHandlerAttachmentEnum {
  Attachment = 'Attachment',
  MessageInput = 'MessageInput',
}

export type AttachmentContentHandlerModel = {
  attachmentType: ContentHandlerAttachmentEnum.Attachment;
  content: File;
};

export type MessageContentHandlerModel = {
  attachmentType: ContentHandlerAttachmentEnum.MessageInput;
  content: string;
};

export type ContentHandlerModel = AttachmentContentHandlerModel | MessageContentHandlerModel;
