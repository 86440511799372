import { FC, useEffect, useMemo } from 'react';

import { EditorSubmitType } from 'view/Editor/type';
import { useAppContext } from 'context';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { EditorUpdateType } from 'view/EditorData/type';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { FlatViewModel, SchemaTypeDataModel, StorageEntityModel } from 'api';
import { deepClone } from 'view/Editor/context/util';

type EditorCopyEntityProps = {
  dataset?: string;
  recordType: string;
  recordKey: string;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const createCopyDataWithoutKeyFieldsBySchema = (schema: SchemaTypeDataModel, data: StorageEntityModel) => {
  const rootSchema = schema.typeList[schema.rootType];
  const copyData = deepClone(data) as FlatViewModel;

  rootSchema.Keys?.forEach((key) => {
    if (copyData[key]) {
      copyData[key] = null;
    }
  });

  return copyData;
};

export const EditorCopyEntity: FC<EditorCopyEntityProps> = ({ dataset, recordType, recordKey, onUpdate }) => {
  const { action, state } = useAppContext();
  const { getRecord, saveEntity } = useEditorRequest();

  useEffect(() => {
    if (recordType && recordKey) {
      getEditorData();
    }
  }, [recordType, dataset, recordKey]);

  const getEditorData = (newKey?: string) => {
    void getRecord.fetch({
      key: newKey || recordKey,
      type: recordType,
      dataset,
    });
  };

  const handleSubmit = ({ data }: EditorSubmitType) => {
    saveEntity.fetch({ data, recordType }).then((result) => {
      onUpdate?.({
        oldKey: recordKey,
        newKey: result.key,
      });

      action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);

      if (state.fullScreenLayerId) {
        action.setFullScreenLayerIdAction(`stack-${EditorTabsEnum.EDITOR}`);
      }
    });
  };

  const handleCancel = () => {
    action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);
    action.setFullScreenLayerIdAction('');
  };

  const copyData = useMemo(() => {
    if (getRecord.state) {
      return createCopyDataWithoutKeyFieldsBySchema(getRecord.state.schema, getRecord.state.data);
    }

    return null;
  }, [getRecord.state]);

  if (getRecord.isLoading) return <Loader />;

  if (!getRecord.state || !copyData) return <UiEmpty />;

  return (
    <EditorInstance
      data={copyData}
      schema={getRecord.state.schema.typeList}
      rootSchemaName={getRecord.state.schema.rootType}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
    />
  );
};
