import { Button } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { supportApi } from 'api';
import { formatFileName, toast } from 'utils';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { filesService } from 'services/files';

export const DownloadLogsButton = () => {
  const { t } = useTranslation();

  const onDownload = () => {
    supportApi
      .downloadLogs()
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        filesService.download(blob, formatFileName(response.fileName));
      })
      .catch(toast.error);
  };

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button className={cx('border-none bg-transparent p-1')} onClick={onDownload}>
          <Icon SvgIcon={APP_ICONS.download} className="text-header-icon" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('header.downloadLogs')}</TooltipContent>
    </Tooltip>
  );
};
