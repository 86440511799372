import { flexRender, Header } from '@tanstack/react-table';

import './UIGridFooterCell.styles.scss';

type UiGridFooterCellProps<TData, TValue> = {
  footer: Header<TData, TValue>;
};

export const UiGridFooterCell = <TData, TValue>({ footer }: UiGridFooterCellProps<TData, TValue>) => (
  <div className="ui-grid-footer-cell" style={{ width: footer.getSize() }}>
    {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.footer, footer.getContext())}
  </div>
);
