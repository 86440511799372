import { useContext } from 'react';

import { DagContext } from '../contexts';

export function useDagContext() {
  const context = useContext(DagContext);
  if (context == null) {
    throw new Error('Cannot find Dag context');
  }
  return context;
}
