import { Column, ColumnResizeMode } from '@tanstack/react-table';

import { isDefined } from 'utils';

import { ResizeMode } from '../types';

const getOffsetByOnChangeMode = (): number => 0;
const getOffsetByOnEndMode = <TData>(column: Column<TData>, resizingDeltaOffset: number): number => {
  const { minSize, maxSize } = column.columnDef;
  const initialSize = column.getSize();
  const size = initialSize + resizingDeltaOffset;
  if (isDefined(minSize) && size < minSize) return minSize - initialSize;
  if (isDefined(maxSize) && size > maxSize) return maxSize - initialSize;
  return resizingDeltaOffset;
};

export const getOffsetByMode = <TData>(
  mode: ColumnResizeMode,
  column: Column<TData>,
  resizingDeltaOffset: number
): number => {
  switch (mode) {
    case ResizeMode.onChange:
      return getOffsetByOnChangeMode();
    case ResizeMode.onEnd:
      return getOffsetByOnEndMode(column, resizingDeltaOffset);
    default:
      throw new Error(`Cannot find the strategy by mode: ${mode}`);
  }
};
