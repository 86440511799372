import cx from 'classnames';

import styles from './styles.module.scss';

interface IconProps extends React.DOMAttributes<HTMLDivElement> {
  SvgIcon: React.ElementType<React.ComponentPropsWithRef<'svg'>>;
  color?: string;
  className?: string;
  title?: string;
  disable?: boolean;
  clickable?: boolean;
  id?: string;
  headerCellClear?: boolean;
}

export const Icon = ({
  SvgIcon,
  color,
  className,
  title,
  clickable = true,
  disable = false,
  id,
  headerCellClear = false,
  ...rest
}: IconProps) => (
  <div
    className={cx('d-flex align-items-center lightIconColor', color, className, {
      [styles.clickable]: clickable,
      [styles.disable]: disable,
      [styles.clear]: headerCellClear,
    })}
    id={id}
    title={title}
    {...rest}
  >
    <SvgIcon />
  </div>
);
