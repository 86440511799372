import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import { escapeStringRegexp, KeyboardCode } from 'utils';
import { useBoolean } from 'utils/hooks';
import { PdfMatchType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';
import { searchAllMatches } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/helpers';

import { usePDFMatches } from '../usePDFMatches';
import { getInitialMatchedIndexByMatches } from './utils/helpers';

type PDFSearchHookOptionsType = {
  pdfRef: MutableRefObject<PDFDocumentProxy | null>;
  documentRef: MutableRefObject<HTMLDivElement | null>;
};

export function usePDFSearch({ documentRef, pdfRef }: PDFSearchHookOptionsType) {
  const [isShowSearch, { toggle: onToggleSearch, setFalse: onCloseSearch }] = useBoolean(false);

  const [search, setSearch] = useState('');
  const {
    currentMatchedTerm,
    currentMatchedIndex,
    matchedSize,
    reset: onResetMatches,
    next: onNextMatch,
    previous: onPreviousMatch,
    update: onUpdateMatches,
    forceUpdateCurrentMatchedTerm,
  } = usePDFMatches();
  const textContents = useRef<string[]>([]);

  const onSetTextContents = useCallback((content: string[]) => {
    textContents.current = content;
  }, []);

  const isExistsTextInContent = useCallback((text: string): boolean => {
    const regex = new RegExp(escapeStringRegexp(text), 'ig');
    return textContents.current.some((pageContent: string) => regex.test(pageContent));
  }, []);

  const onChangeSearch = useCallback(
    (changedSearch: string, initialMatch?: { page: number }) => {
      setSearch((prevSearch: string) => {
        if (prevSearch === changedSearch) {
          if (matchedSize > 1) {
            onNextMatch();
          } else {
            forceUpdateCurrentMatchedTerm();
          }

          return changedSearch;
        }

        const pdf = pdfRef.current;
        if (!pdf) return changedSearch;

        if (changedSearch) {
          let matches: PdfMatchType[] = [];
          textContents.current.forEach((pageContent, pageIndex) => {
            const page = pageIndex + 1;
            const pageMatches = searchAllMatches(changedSearch, pageContent).map((match, matchIndex) => ({
              ...match,
              page,
              matchIndex,
            }));
            matches = matches.concat(pageMatches);
          });

          const initialMatchedIndex = getInitialMatchedIndexByMatches(matches, initialMatch);
          onUpdateMatches(matches, initialMatchedIndex);
        } else {
          onResetMatches();
        }

        return changedSearch;
      });
    },
    [matchedSize]
  );

  useEffect(() => {
    const keydownHandler = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.code as KeyboardCode) === KeyboardCode.KeyF) {
        event.preventDefault();
        event.stopPropagation();

        onToggleSearch();
      }
    };

    if (documentRef.current) {
      documentRef.current.setAttribute('tabIndex', '-1');
      documentRef.current.addEventListener('keydown', keydownHandler);
    }

    return () => {
      if (documentRef.current) {
        documentRef.current.removeAttribute('tabIndex');
        documentRef.current.addEventListener('keydown', keydownHandler);
      }
    };
  }, []);

  return {
    isShowSearch,
    onToggleSearch,
    onCloseSearch,
    isExistsTextInContent,
    search,
    onChangeSearch,
    onSetTextContents,
    currentMatchedTerm,
    matchedSize,
    currentMatchedIndex,
    onNextMatch,
    onPreviousMatch,
  };
}
