import { createSelector } from '@reduxjs/toolkit';

import { AppStateType } from 'store/types/AppStateType';
import { RootState } from 'store';
import { AuthTypeEnum } from 'api';

export const selectStore = (state: RootState) => state.AppStateSliceReducer;

export const selectOpenedTabs = createSelector(selectStore, (state: AppStateType) => state.openedTabs);
export const selectVersions = createSelector(selectStore, (state: AppStateType) => state.versions); // TODO: move it to another store
export const selectIsReadonly = createSelector(selectStore, (state: AppStateType) => state.isReadonly);
export const selectApplicationName = createSelector(selectStore, (state: AppStateType) => state.applicationName); // TODO: move it to another store
export const selectApplicationTheme = createSelector(selectStore, (state: AppStateType) => state.applicationTheme); // TODO: move it to another store
export const selectBackendVersion = createSelector(selectStore, (state: AppStateType) => state.backendVersion); // TODO: move it to another store
export const selectActiveTabIndex = createSelector(selectStore, (state: AppStateType) => state.activeTabIndex);

export const selectAccessToken = createSelector(selectStore, (state: AppStateType) => state.tokens.accessToken);
export const selectRefreshToken = createSelector(selectStore, (state: AppStateType) => state.tokens.refreshToken);
export const selectType = createSelector(selectStore, (state: AppStateType) => state.authType);
export const selectUser = createSelector(selectStore, (state: AppStateType) => state.user);

export const selectIsNoneType = createSelector(
  selectType,
  (authType: AppStateType['authType']) => authType?.type === AuthTypeEnum.None
);

export const selectIsAuthenticated = createSelector(
  selectIsNoneType,
  selectAccessToken,
  (isNoneType: boolean, accessToken: AppStateType['tokens']['accessToken']) =>
    isNoneType ? true : Boolean(accessToken)
);
