import { FC } from 'react';
import { Button } from 'reactstrap';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { useAppDispatch } from 'store/hooks';
import { openTab } from 'store/shared-reducers/AppStateSlice';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

export const Logger: FC = () => {
  const dispatch = useAppDispatch();

  const { setActiveTab } = useTabParams();

  const openLogEntryTab = () => {
    dispatch(openTab({ id: 'LogEntry', label: 'Log Entry', key: 'LogEntry' }));
    setActiveTab('LogEntry');
  };

  return (
    <Button className="border-none bg-transparent position-relative p-1" onClick={openLogEntryTab}>
      <Icon SvgIcon={APP_ICONS.bell} color="text-header-icon" />
    </Button>
  );
};
