import { isAnyOf } from '@reduxjs/toolkit';

import { AppStartListening } from 'store/listeners';
import { saveAppState, setTabs } from 'store/shared-reducers/AppStateSlice';

export const SaveAppStateListener = (startListening: AppStartListening) => {
  startListening({
    matcher: isAnyOf(setTabs),
    effect: (_, api) => {
      api.dispatch(saveAppState());
    },
  });
};
