import { ElementRectWithCoordinates } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';

import { CharIndexType } from './types';
import { CharIndexSpan } from './charIndexSpan';

export class CharIndexList {
  private charIndexes: CharIndexType[][];

  constructor(initialCharIndexes: CharIndexType[][]) {
    this.charIndexes = initialCharIndexes;
  }

  public removeSpacesBetweenWords(): CharIndexList {
    this.charIndexes = this.charIndexes.filter(
      (charIndexSpan) => charIndexSpan.length !== 1 || charIndexSpan[0].char.trim() !== ''
    );

    return this;
  }

  public generateRectWithCoordinates(containerRect: DOMRect, elements: Element[]): ElementRectWithCoordinates[] {
    return this.charIndexes.reduce((acc, charIndexSpan) => {
      const elementRectWithCoordinates = new CharIndexSpan(charIndexSpan, elements).toRectWithCoordinates(
        containerRect
      );

      if (!elementRectWithCoordinates) return acc;

      acc.push(elementRectWithCoordinates);

      return acc;
    }, [] as ElementRectWithCoordinates[]);
  }
}
