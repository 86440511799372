import { Dispatch, SetStateAction } from 'react';

export const rightSideStyling = (
  closestTabWrapper: HTMLElement,
  closesTabWrapperIndex: number,
  endTabIndex: number,
  setEndTabIndex: Dispatch<SetStateAction<number>>
) => {
  const closestTabWrapperCopy = closestTabWrapper;

  closestTabWrapperCopy.style.pointerEvents = 'none';
  closestTabWrapperCopy.style.transform = 'translate(0px, 0px)';

  const postAnimationClearance = () => {
    if (endTabIndex !== closesTabWrapperIndex) {
      setEndTabIndex(() => closesTabWrapperIndex);
    } else {
      setEndTabIndex(() => closesTabWrapperIndex + 1);
    }

    closestTabWrapperCopy.style.removeProperty('pointer-events');
    closestTabWrapperCopy.removeEventListener('transitionend', postAnimationClearance);
  };

  closestTabWrapperCopy.addEventListener('transitionend', postAnimationClearance);
};
