import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

import { UiDropdown, UiDropdownItem } from 'ui';
import { KindTypeModel } from 'api/model/schema.model';
import { useBoolean } from 'utils/hooks';
import { Icon } from 'shared/Icon';
import { ExportGridOptionsType, SelectedRowsRangeType } from 'view/Grid/utils/types';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { UiModal } from 'ui/UiModal';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { useAppContext } from 'context';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectMetaData } from 'containers/TabItemContent/store/selectors';
import { formatExcelDate } from 'services/excel';
import { schemaApi, SettingsRecordModel, StorageItemModel, UserRole } from 'api';
import { InfoModalWithCheckbox } from 'view/GridToolbar/components/InfoModalWithChechbox/InfoModalWithCheckbox';
import { selectIsReadonly, selectUser } from 'store/selectors/AppState.selector';
import { useTabItemContext } from 'containers/TabItemContent/context/useTabItemContext.hook';
import { useRequest } from 'hook/request.hook';

import { ChatAboutContent, GridToolbarPagination } from './components';
import { GridToolbarDataType } from './utils/types';
import styles from './index.module.scss';

type GridToolbarProps = {
  type: string;
  selectedGridRowKey?: string;
  entities: StorageItemModel[];
  paginationRange: { start: number; end: number };
  paginationPage: number;
  isAvailableNextPage: boolean;
  isAvailablePreviousPage: boolean;
  isQueryApplied: boolean;
  onExportGridOptions: () => ExportGridOptionsType;
  showQueryBuilder: () => void;
  typeViewSettings: SettingsRecordModel;
  selectedRangeRows: SelectedRowsRangeType;
  onDeleteSelectedRangeRows: () => void;
  onExportBulkEntities: (isWithDependencies: boolean) => Promise<void>;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onFirstPage: () => void;
  onRefresh: () => void;
  isSelectAllRecordsBtnClicked: boolean;
  selectAllRecords: () => void;
  isAllRecordsSelected: boolean;
  onRestoreDefaultLayout: () => void;
  onSaveRecordPermanently: (isWithDependencies: boolean) => Promise<void>;
  isGridPageDataLoading: boolean;
};

export const GridToolbar: FC<GridToolbarProps> = ({
  type,
  selectedGridRowKey,
  entities,
  paginationRange,
  paginationPage,
  isAvailablePreviousPage,
  isAvailableNextPage,
  isQueryApplied,
  onExportGridOptions,
  showQueryBuilder,
  typeViewSettings,
  selectedRangeRows,
  onDeleteSelectedRangeRows,
  onExportBulkEntities,
  onSaveRecordPermanently,
  onNextPage,
  onPreviousPage,
  onFirstPage,
  onRefresh,
  isSelectAllRecordsBtnClicked,
  selectAllRecords,
  isAllRecordsSelected,
  onRestoreDefaultLayout,
  isGridPageDataLoading,
}) => {
  const { t } = useTranslation();
  const { action, state } = useAppContext();
  const { state: tabItemState } = useTabItemContext();
  const { activeEditorTab, cacheTabData } = state;
  const [isOpenBulkExportModal, { toggle: toggleBulkExportModal }] = useBoolean();
  const [isOpenRecordExportModal, { toggle: toggleRecordExportModal }] = useBoolean();
  const [isOpenExcelExportModal, { toggle: toggleExcelExportModal }] = useBoolean();

  const user = useSelector(selectUser);
  const { data: gridSchema } = useSelector(selectGridSchema);
  const metaData = useSelector(selectMetaData);
  const activeDataset = useSelector(selectActiveDataset);
  const isAppStateReadOnly = useSelector(selectIsReadonly);

  const successorRequest = useRequest(schemaApi.getTypeHierarchy, {
    args: { name: gridSchema.Name },
    autorun: gridSchema.Kind === KindTypeModel.ABSTRACT,
  });

  const isFinalReadOnly = isAppStateReadOnly || tabItemState.isTypeReadOnly;
  const selectedRangeRowsLength = Object.keys(selectedRangeRows).length;
  const isNoEntityExist = entities.length === 0;
  const cacheTabQuery = cacheTabData?.[type]?.query || null;
  const successors = successorRequest.state?.filter((elem) => elem.Name !== type);
  const isAbstractNewEntityDisabled = gridSchema.Kind === KindTypeModel.ABSTRACT && successors?.length === 0;

  const handleConfirmBulkExport = (isWithDependencies?: boolean) => {
    onExportBulkEntities(Boolean(isWithDependencies));
    toggleBulkExportModal();
  };

  const handleSaveRecordPermanently = (isWithDependencies?: boolean) => {
    onSaveRecordPermanently(Boolean(isWithDependencies));
    toggleRecordExportModal();
  };

  const handleExportExcel = () => {
    const exportTime = formatExcelDate(new Date());

    const additionalCells = [
      {
        name: 'Export time:',
        value: exportTime,
      },
      {
        name: 'Type:',
        value: metaData?.data?.Name || 'no active type',
      },
      {
        name: 'Dataset:',
        value: activeDataset || 'no active dataset',
      },
    ];

    const getSelectedRowKeys = () => {
      if (selectedRangeRowsLength) return Object.keys(selectedRangeRows);
      return selectedGridRowKey ? [selectedGridRowKey] : [];
    };

    onExportGridOptions().toExcel({
      sheetName: metaData?.data?.Module?.ModuleName || 'Sheet 1',
      selectedRowKeys: getSelectedRowKeys(),
      additionalCells,
    });
    toggleExcelExportModal();
  };

  const getSelectedRowsCount = () => {
    if (isSelectAllRecordsBtnClicked) return 0;

    return selectedRangeRowsLength !== 0 ? selectedRangeRowsLength : 1;
  };

  const gridToolbarData: GridToolbarDataType[] = [
    {
      groupId: uuid(),
      icons: [
        {
          className: isQueryApplied || cacheTabQuery ? 'text-warning' : undefined,
          SvgIcon: APP_ICONS.query,
          tooltipPlaceholder: t('gridToolbar.query') || '',
          tooltipTarget: 'queryBuilder',
          actionHandler: showQueryBuilder,
          isDisabled: isQueryApplied || cacheTabQuery ? false : isNoEntityExist,
        },
      ],
    },
    {
      groupId: uuid(),
      icons: [
        {
          SvgIcon: APP_ICONS.multipleCheckbox,
          isDisabled: isNoEntityExist || isAllRecordsSelected || isSelectAllRecordsBtnClicked,
          isHide: typeViewSettings.LayoutSettings.HideEditor || isFinalReadOnly,
          tooltipPlaceholder: t('gridToolbar.selectAllRecords'),
          tooltipTarget: 'selectAllRecords',
          actionHandler: isSelectAllRecordsBtnClicked ? undefined : selectAllRecords,
        },
        {
          SvgIcon: APP_ICONS.add,
          isDisabled: activeEditorTab === EditorTabsEnum.NEW_ENTITY || isAbstractNewEntityDisabled,
          isHide: typeViewSettings.ReadOnly || isFinalReadOnly,
          tooltipPlaceholder: t('gridToolbar.addNewEntity') || '',
          tooltipTarget: 'addNewEntity',
          actionHandler: () =>
            action.setActiveEditorTabAction(
              gridSchema.Kind === KindTypeModel.ABSTRACT ? EditorTabsEnum.SELECT_TYPE : EditorTabsEnum.NEW_ENTITY
            ),
        },
        {
          SvgIcon: APP_ICONS.copy,
          isDisabled: activeEditorTab === EditorTabsEnum.COPY_ENTITY || !selectedGridRowKey,
          isHide: typeViewSettings.ReadOnly || isFinalReadOnly,
          tooltipPlaceholder: t('gridToolbar.copyEntity') || '',
          tooltipTarget: 'copyEntity',
          actionHandler: () => action.setActiveEditorTabAction(EditorTabsEnum.COPY_ENTITY),
        },
        {
          SvgIcon: APP_ICONS.delete,
          isDisabled: isNoEntityExist,
          isHide: typeViewSettings.ReadOnly || isFinalReadOnly,
          tooltipPlaceholder:
            selectedRangeRowsLength < 2
              ? t('gridToolbar.deleteSelectedEntity')
              : t('gridToolbar.deleteSelectedEntities', {
                  numberOfItems: `${isSelectAllRecordsBtnClicked ? 'All' : selectedRangeRowsLength} `,
                }),
          tooltipTarget: 'deleteSelectedEntities',
          actionHandler: () =>
            UiModal.delete({
              title: t('confirmationModal.deleteRecords.title'),
              content:
                selectedRangeRowsLength < 2
                  ? t('confirmationModal.deleteRecords.confirmationTextOneEntity')
                  : t('confirmationModal.deleteRecords.confirmationTexts', {
                      numberOfItems: selectedRangeRowsLength !== 0 ? selectedRangeRowsLength : 1,
                    }),
              onOk: (close) => {
                onDeleteSelectedRangeRows();
                close();
              },
            }),
          isBarge: selectedRangeRowsLength !== 0,
        },
        {
          SvgIcon: APP_ICONS.savePermanently,
          isDisabled: isNoEntityExist,
          isHide: !user?.roles?.includes(UserRole.Developer),
          tooltipPlaceholder:
            selectedRangeRowsLength < 2
              ? t('gridToolbar.savePermanentlyEntity')
              : t('gridToolbar.savePermanentlyEntities', {
                  numberOfItems: `${isSelectAllRecordsBtnClicked ? 'All' : selectedRangeRowsLength} `,
                }),
          tooltipTarget: 'savePermanently',
          actionHandler: toggleRecordExportModal,
          isBarge: selectedRangeRowsLength !== 0,
        },
        {
          SvgIcon: APP_ICONS.chatAbout,
          tooltipPlaceholder: t('gridToolbar.chatAbout') || '',
          tooltipTarget: 'chatAbout',
          isHide: process.env.CL_CHAT_ABOUT_ON === 'false',
          component: (
            <ChatAboutContent type={type} selectedGridRowKey={selectedGridRowKey} isDisabled={isNoEntityExist} />
          ),
        },
      ],
    },
    {
      groupId: uuid(),
      dropdown: {
        title: t('gridToolbar.layout') || '',
        dropdownItems: [
          {
            SvgIcon: APP_ICONS.refresh,
            name: t('shared.refresh') || '',
            actionHandler: onRefresh,
          },
          {
            SvgIcon: APP_ICONS.undo,
            name: t('gridToolbar.restoreDefaultLayout') || '',
            actionHandler: () =>
              UiModal.info({
                title: t('confirmationModal.restoreLayout.title'),
                content: t('confirmationModal.restoreLayout.confirmationText'),
                onOk: (close) => {
                  onRestoreDefaultLayout();
                  close();
                },
              }),
            isDisabled: isNoEntityExist,
          },
        ],
      },
    },
    {
      groupId: uuid(),
      dropdown: {
        title: t('gridToolbar.export') || '',
        dropdownItems: [
          {
            SvgIcon: APP_ICONS.openExcelAddin,
            name: t('gridToolbar.excel') || '',
            isDisabled: isNoEntityExist,
            actionHandler: toggleExcelExportModal,
          },
          {
            SvgIcon: APP_ICONS.openJson,
            name: t('gridToolbar.json') || '',
            isDisabled: isNoEntityExist,
            actionHandler: toggleBulkExportModal,
          },
        ],
      },
    },
  ];

  return (
    <div
      className={cx(
        'bg-grid-toolbar-background d-flex justify-content-start text-grid-toolbar-text fs-13 border-grid-toolbar-border',
        styles.shadow
      )}
    >
      <GridToolbarPagination
        isGridPageDataLoading={isGridPageDataLoading}
        className={styles.pagination}
        isAvailableNextPage={isAvailableNextPage}
        isAvailablePreviousPage={isAvailablePreviousPage}
        range={paginationRange}
        page={paginationPage}
        onNext={onNextPage}
        onPrev={onPreviousPage}
        onFirst={onFirstPage}
      />
      <div className="d-flex">
        {gridToolbarData.map(({ groupName, groupId, icons, dropdown }) => (
          <div key={groupId} className={cx('d-flex align-items-center', styles.iconsBlock)}>
            {groupName && <span>{t(groupName || '')}</span>}

            {icons?.map(
              ({
                className,
                tooltipTarget,
                SvgIcon,
                isDisabled,
                tooltipPlaceholder,
                actionHandler,
                isHide,
                component,
                isBarge,
              }) => {
                const toolbarIcon = (
                  <Icon
                    className={className}
                    clickable={Boolean(actionHandler)}
                    SvgIcon={SvgIcon}
                    disable={isDisabled}
                    color="text-grid-toolbar-icon"
                  />
                );

                return (
                  !isHide && (
                    <Tooltip key={tooltipTarget}>
                      <TooltipTrigger>
                        <div
                          aria-hidden="true"
                          onClick={() => !isDisabled && actionHandler?.()}
                          className={cx('d-flex align-items-center')}
                        >
                          {isBarge ? (
                            <div className={styles.bargeContainer}>
                              <div className={styles.barge} />
                              {toolbarIcon}
                            </div>
                          ) : (
                            component ?? toolbarIcon
                          )}
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>{tooltipPlaceholder}</TooltipContent>
                    </Tooltip>
                  )
                );
              }
            )}
            {dropdown && (
              <UiDropdown
                tag="span"
                toggleButton={
                  <div tabIndex={0} className={styles.dropdownToogleButton}>
                    {dropdown.title}
                    <Icon SvgIcon={APP_ICONS.fillArrowDown} />
                  </div>
                }
              >
                {dropdown.dropdownItems.map(({ actionHandler, name, isDisabled, SvgIcon }) => (
                  <UiDropdownItem
                    key={name}
                    tag="div"
                    className={styles.dropdownItem}
                    onClick={actionHandler}
                    disabled={isDisabled}
                  >
                    <Icon SvgIcon={SvgIcon} disable={isDisabled} />
                    {name}
                  </UiDropdownItem>
                ))}
              </UiDropdown>
            )}
          </div>
        ))}
      </div>
      <InfoModalWithCheckbox
        title={t('confirmationModal.export.title')}
        isOpen={isOpenBulkExportModal}
        content={t('confirmationModal.export.exportEntity', {
          count: getSelectedRowsCount(),
        })}
        onClose={toggleBulkExportModal}
        onConfirm={handleConfirmBulkExport}
      />
      <InfoModalWithCheckbox
        title={t('confirmationModal.savePermanently.title')}
        isOpen={isOpenRecordExportModal}
        content={t('confirmationModal.savePermanently.exportEntity', {
          count: getSelectedRowsCount(),
        })}
        onClose={toggleRecordExportModal}
        onConfirm={handleSaveRecordPermanently}
      />
      <InfoModalWithCheckbox
        title={t('confirmationModal.export.title')}
        isOpen={isOpenExcelExportModal}
        content={t('confirmationModal.export.exportEntity', {
          count: getSelectedRowsCount(),
        })}
        onClose={toggleExcelExportModal}
        onConfirm={handleExportExcel}
        isWithDependencies={false}
      />
    </div>
  );
};
