import { ChangeEvent, forwardRef, KeyboardEvent, useImperativeHandle, useRef } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { KeyboardCode } from 'utils';
import { useBoolean } from 'utils/hooks';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { useDebounce } from 'shared/Hooks/useDebounce';
import { DotLoader } from 'shared/DotLoader';

import styles from './PDFSearchInput.module.scss';

type PdfSearchInputProps = {
  currentMatchedTerm: number;
  matchedTermsSize: number;
  onNextMatch: () => void;
  onPreviousMatch: () => void;
  onChangeSearch: (search: string) => void;
};

export type PdfSearchInputRef = {
  setSearch: (search: string) => void;
  focus: () => void;
  clear: () => void;
};

export const PdfSearchInput = forwardRef<PdfSearchInputRef, PdfSearchInputProps>((props, ref) => {
  const { t } = useTranslation();
  const [isFocused, { setTrue: setFocused, setFalse: setUnFocused }] = useBoolean(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { debounce, isLoading: debounceLoading } = useDebounce();
  const isShowStatus = (inputRef.current?.value || '').length > 0;

  const isDisabledPrevious = props.matchedTermsSize === 0;
  const isDisabledNext = props.matchedTermsSize === 0;

  const onPreviousMatch = () => {
    if (isDisabledPrevious) return;

    props.onPreviousMatch();
  };

  const onNextMatch = () => {
    if (isDisabledNext) return;

    props.onNextMatch();
  };

  const keydownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    const isEnterPressed =
      (event.code as KeyboardCode) === KeyboardCode.Enter || (event.code as KeyboardCode) === KeyboardCode.NumpadEnter;
    const inputValue = inputRef.current?.value || '';
    const isExistsMatches = props.matchedTermsSize > 0;
    if (isEnterPressed && isExistsMatches) {
      props.onChangeSearch(inputValue);
    }
  };

  const focus = () => inputRef.current?.focus();

  const clear = () => {
    if (!inputRef.current) return;

    inputRef.current.value = '';
  };

  const setSearch = (search: string) => {
    if (!inputRef.current) return;

    inputRef.current.value = search;
  };

  useImperativeHandle(ref, () => ({
    setSearch,
    focus,
    clear,
  }));

  const renderStatus = () => {
    if (debounceLoading) return <DotLoader />;

    if (isShowStatus) {
      return (
        <div>
          {props.currentMatchedTerm} / {props.matchedTermsSize}
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={cx('d-flex align-items-center gap-1 bg-input-backgound px-2 py-1 rounded', styles.container, {
        [styles.focused]: isFocused,
      })}
    >
      <Icon SvgIcon={APP_ICONS.search} clickable={false} />
      <input
        ref={inputRef}
        type="text"
        className={cx('text-text2 fs-13 border-0 flex-grow-1', styles.input)}
        placeholder={t('viewers.pdf.placeholder') || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          debounce({ fn: props.onChangeSearch, value: event.target.value })
        }
        onKeyDown={keydownHandler}
        onFocus={setFocused}
        onBlur={setUnFocused}
      />
      {renderStatus()}
      <div className="d-flex align-items-center gap-1">
        <Tooltip>
          <TooltipTrigger>
            <Button
              className={cx(
                'bg-transparent bg-gray-400 d-flex align-items-center justify-content-center border-0 p-0 rounded',
                styles.button
              )}
              disabled={isDisabledPrevious}
              onClick={onPreviousMatch}
            >
              <Icon className={styles.previousIcon} clickable={false} SvgIcon={APP_ICONS.arrowRight} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('viewers.pdf.previousMatch')}</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger>
            <Button
              className={cx(
                'bg-transparent d-flex align-items-center justify-content-center border-0 p-0 rounded',
                styles.button
              )}
              disabled={isDisabledNext}
              onClick={onNextMatch}
            >
              <Icon clickable={false} SvgIcon={APP_ICONS.arrowRight} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('viewers.pdf.nextMatch')}</TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
});

PdfSearchInput.displayName = 'PdfSearchInput';
