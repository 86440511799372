import { Component, ErrorInfo, ReactNode } from 'react';
import cx from 'classnames';
import { Button } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { supportApi } from 'api';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import Logo from 'assets/logos/cl-logo.svg';
import { externalStorage } from 'shared/ExternalStorage';

import styles from './styles.module.scss';

interface Props extends WithTranslation {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    externalStorage.clear();
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    supportApi.saveFrontendError(error.toString());
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column bg-primary content position-relative">
          <div className="container-fluid bg-header-background text-white">
            <div className="row p-2 d-flex align-items-center">
              <div className="col-1">
                <Logo />
              </div>
              <div className="offset-8 col-3 d-flex justify-content-end align-items-center">
                <div
                  className={cx(
                    'rounded-circle text-white fw-bold bg-header-background d-flex justify-content-center align-items-center',
                    styles.iconContainer
                  )}
                >
                  <Icon SvgIcon={APP_ICONS.whiteThemeUserIcon} clickable={false} color="text-white" />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-primary bg-opacity-50 border-0 w-100 h-100 d-flex flex-column align-items-center justify-content-xl-center gap-3">
            <div className="text-text2 fw-bolder">{this.props.t('shared.somethingWentWrong')}</div>
            <Button
              className={cx('bg-button border-0 text-text2 fw-bolder fs-13 c-button', styles.button)}
              onClick={() => window.location.reload()}
            >
              <div className="d-flex align-items-center p-1">
                <Icon SvgIcon={APP_ICONS.refresh} color="text-text2" clickable={false} className="me-2" />
                {this.props.t('shared.reloadPage')}
              </div>
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

// eslint-disable-next-line import/no-default-export
export default withTranslation()(ErrorBoundary);
