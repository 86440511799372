import { FC, ReactPortal, useId, useState } from 'react';
import { Column } from 'react-table';

import { createPortal } from 'react-dom';

import { Grid } from 'view/Grid';
import { DataType, GridModeEnum } from 'view/Grid/utils/types';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { isArray } from 'utils';
import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { ArrayToolbar } from 'view/Editor/components/EditorView/ArrayToolbar';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

type ComplexVectorViewGridProps = {
  columns: Column<DataType>[];
  data: DataType[];
  tab: EditorTabType;
};

export const ComplexVectorViewGrid: FC<ComplexVectorViewGridProps> = ({ data, columns, tab }) => {
  const id = useId();
  const { state } = useEditorState();
  const { state: tabState } = useEditorTabState();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState<Record<string, boolean>>({});

  const gridData = isArray(data) ? data : []; // use empty array for null list

  const renderPortalToolbar = (): ReactPortal | null => {
    if (!state.editorToolbarRef || !tabState.isActive || state.isReadOnly) return null;

    return createPortal(
      <ArrayToolbar
        tab={tab}
        arrayLength={gridData.length}
        selectedIndex={selectedIndex}
        selectedGroup={selectedCheckbox}
        onSelectedGroupChange={setSelectedCheckbox}
        onSelectedIndexChange={setSelectedIndex}
      />,
      state.editorToolbarRef
    );
  };

  return (
    <div>
      {renderPortalToolbar()}
      <Grid
        useCheckboxes
        gridId={id}
        columns={columns}
        data={gridData}
        gridMode={GridModeEnum.EDITOR}
        selectedRowId={selectedIndex.toString()}
        selectedCheckbox={selectedCheckbox}
        onSelectedCheckboxChange={setSelectedCheckbox}
        onChangeSelectedRowId={(rowId) => setSelectedIndex(+rowId)}
      />
    </div>
  );
};
