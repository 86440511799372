import { useState, useEffect } from 'react';

import { externalStorage } from 'shared/ExternalStorage';

export const useLogsPanel = () => {
  const eventName = 'isLogsPanel';

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener(eventName, handleStorageChange);

    return () => {
      window.removeEventListener(eventName, handleStorageChange);
    };
  }, []);

  const handleStorageChange = () => {
    setIsOpen(externalStorage.getIsLogsPanel());
  };

  const togglePanel = (isLogsOpen: boolean) => {
    externalStorage.setIsLogsPanel(isLogsOpen);
    window.dispatchEvent(new Event(eventName));
  };

  return {
    isOpen,
    togglePanel,
  };
};
