import { useTranslation } from 'react-i18next';

import { FC } from 'react';

import { BinaryContentTypesEnum } from 'view/Viewers/utils/constants';

import { XlsxViewer } from './Components/XlsxViewer';
import { ContentViewer } from './Components/ContentViewer';
import { ImageViewer } from './Components/ImageViewer';
import { PlotlyViewer } from './Components/PlotlyViewer';
import { PDFViewer } from './Components/PDFViewer';

type BinaryContentContainerProps = {
  ContentType?: string;
  Content?: string;
};

export const BinaryContentContainer: FC<BinaryContentContainerProps> = ({ ContentType, Content }) => {
  const { t } = useTranslation();

  if (!Content) return null;

  const renderContent = (type: BinaryContentTypesEnum) =>
    ({
      [BinaryContentTypesEnum.Png]: <ImageViewer content={Content} />,
      [BinaryContentTypesEnum.Jpg]: <ImageViewer content={Content} />,
      [BinaryContentTypesEnum.Html]: <ContentViewer content={Content} />,
      [BinaryContentTypesEnum.Plotly]: <PlotlyViewer content={Content} />,
      [BinaryContentTypesEnum.Pdf]: <PDFViewer content={Content} />,
      [BinaryContentTypesEnum.Xlsx]: <XlsxViewer content={Content} />,
    })[type] || <div>{t('shared.notSupported')}</div>;

  return renderContent(ContentType as BinaryContentTypesEnum);
};
