import { useMemo } from 'react';
import { ColumnResizeMode, Header } from '@tanstack/react-table';
import cx from 'classnames';

import { getOffsetByMode } from '../../helpers';
import './UIGridHeaderCellResizer.styles.scss';

type UIGridHeaderCellResizerProps<TData, TValue> = {
  header: Header<TData, TValue>;
  resizeMode: ColumnResizeMode;
  resizingDeltaOffset: number;
};

export const UIGridHeaderCellResizer = <TData, TValue>({
  header,
  resizeMode,
  resizingDeltaOffset,
}: UIGridHeaderCellResizerProps<TData, TValue>) => {
  const isResizing = header.column.getIsResizing();
  const offset = useMemo(() => {
    if (!isResizing) return 0;
    return getOffsetByMode(resizeMode, header.column, resizingDeltaOffset);
  }, [isResizing, resizingDeltaOffset, resizeMode, header.column]);

  return (
    <div
      className={cx('ui-grid-header-cell-resizer', {
        'ui-grid-header-cell-resizer--resizing': isResizing,
        'ui-grid-header-cell-resizer--disabled': !header.column.getCanResize(),
      })}
      style={{ transform: `translateX(${offset}px)` }}
      onDoubleClick={header.column.resetSize}
      onMouseDown={header.getResizeHandler()}
      onTouchStart={header.getResizeHandler()}
    />
  );
};
