import { FC, RefObject } from 'react';
import cx from 'classnames';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { PopperPlacementType } from '@mui/material';

import { DateFormatEnum } from 'utils';
import { APP_ICONS } from 'utils/icons';

import { UIInput, UIInputProps } from './UIInput.component';
import './UIInputDate.styles.scss';

export type UIInputDateProps<T = Dayjs> = Pick<
  DatePickerProps<T>,
  'value' | 'onChange' | 'format' | 'showDaysOutsideCurrentMonth' | 'className' | 'open' | 'onClose' | 'onOpen'
> & {
  pickerRef?: RefObject<HTMLDivElement>;
  pickerButtonRef?: RefObject<HTMLButtonElement>;
  disabledPickerButton?: boolean;
  pickerPlacement?: PopperPlacementType;
  inputProps?: UIInputProps;
};

export const UIInputDate: FC<UIInputDateProps> = ({
  pickerRef,
  pickerButtonRef,
  disabledPickerButton,
  pickerPlacement = 'bottom-end',
  className,
  format = DateFormatEnum.DATE,
  showDaysOutsideCurrentMonth = true,
  inputProps,
  ...rest
}) => {
  const classes = cx('ui-input-date', className);

  return (
    <DatePicker<Dayjs>
      format={format}
      className={classes}
      showDaysOutsideCurrentMonth={showDaysOutsideCurrentMonth}
      dayOfWeekFormatter={(_, weekday) => weekday.format('dd')}
      slots={{
        textField: UIInput,
        openPickerIcon: APP_ICONS.calendar,
      }}
      slotProps={{
        popper: {
          ref: pickerRef,
          placement: pickerPlacement,
          sx: {
            '& .MuiDateCalendar-root': {
              width: 230,
              height: '100%',
            },
          },
        },
        openPickerButton: {
          ref: pickerButtonRef,
          disabled: disabledPickerButton,
        },
        textField: {
          placeholder: '',
          inputProps: {},
          ...inputProps,
        },
        actionBar: {
          actions: ['today'],
        },
      }}
      {...rest}
    />
  );
};
