import { ChangeEvent, FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';

import { useAppContext } from 'context';
import {
  ChatType,
  CloExclusionsStateType,
  FileType,
  MessageGroupType,
  ProgressOutputType,
  SessionType,
} from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';

import { FileList } from './components/FileList';
import { Chat } from './components/Chat';
import { ChatInput, ChatInputRefType } from './components/ChatInput';
import { Toolbar } from './components/Toolbar';
import { UploadZone } from './components/UploadZone';

import styles from './styles.module.scss';

type HistoryProps = {
  cloExclusionsState: CloExclusionsStateType;
  isExecuteAnalyzer: boolean;
  uploadingProgress?: ProgressOutputType;
  isShowMaximizedButton: boolean;
  isMaximized: boolean;
  isTopic: boolean;
  files: FileType[];
  isSending: boolean;
  isPrivateType: boolean;
  message: string;
  messageGroups: MessageGroupType[];
  activeSession?: SessionType;
  sessions: SessionType[];
  selectSession: (session: SessionType) => void;
  deleteSelectedSessions: (sessions: SessionType[]) => void;
  isLoading: boolean;
  onChangeInput: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSendMessage: () => void;
  cancelSendMessage: () => void;
  startNewChat: (chatType: ChatType, isProfile?: boolean) => void;
  onChangeFiles: (files: File[]) => void;
  onRemoveFile: (filename: string) => void;
  toggleMaximizedView: () => void;
  openKeyDialog: () => void;
  getUnsavedFiles: () => FileType[];
  openMailClient: () => void;
  sendCloExclusions: () => void;
};

export const History: FC<HistoryProps> = ({
  cloExclusionsState,
  isExecuteAnalyzer,
  uploadingProgress,
  isShowMaximizedButton,
  isMaximized,
  isTopic,
  files,
  isSending,
  isPrivateType,
  isLoading,
  activeSession,
  sessions,
  selectSession,
  deleteSelectedSessions,
  startNewChat,
  messageGroups,
  onSendMessage,
  cancelSendMessage,
  message,
  onChangeInput,
  onChangeFiles,
  onRemoveFile,
  toggleMaximizedView,
  sendCloExclusions,
  openKeyDialog,
  getUnsavedFiles,
  openMailClient,
}) => {
  const chatInputRef = useRef() as MutableRefObject<ChatInputRefType>;
  const { state, action } = useAppContext();

  useEffect(() => {
    state.showLineHider && action.setShowLineHiderAction(false);
  }, []);

  const onChangeFilesHandler = useCallback(
    (updatedFiles: File[]) => {
      onChangeFiles(updatedFiles);
      chatInputRef.current.focus();
    },
    [onChangeFiles]
  );

  return (
    <UploadZone className="h-100" isLoading={isLoading} onChangeFiles={onChangeFilesHandler}>
      {({ open }) => (
        <div className={cx('h-100 overflow-hidden', styles.top)}>
          <div className={cx('d-flex flex-column h-100 overflow-hidden', styles.container)}>
            <Toolbar
              cloExclusionsState={cloExclusionsState}
              isShowMaximized={isShowMaximizedButton}
              isMaximized={isMaximized}
              isTopic={isTopic}
              activeSession={activeSession}
              isLoading={isLoading}
              sessions={sessions}
              startNewChat={startNewChat}
              selectSession={selectSession}
              deleteSelectedSessions={deleteSelectedSessions}
              toggleMaximizedView={toggleMaximizedView}
              sendCloExclusions={sendCloExclusions}
              openMailClient={openMailClient}
            />
            <Chat
              uploadingProgress={uploadingProgress}
              isShowMaskIcon={isPrivateType}
              className="flex-grow-1"
              messageGroups={messageGroups}
            />
            <FileList isLoading={isLoading} files={files} onRemove={onRemoveFile} />
            <ChatInput
              ref={chatInputRef}
              isExecuteAnalyzer={isExecuteAnalyzer}
              getUnsavedFiles={getUnsavedFiles}
              isProfileChosen={Boolean(activeSession?.Profile)}
              isSending={isSending}
              isShowMaskIcon={isPrivateType}
              isLoading={isLoading}
              input={message}
              onChangeInput={onChangeInput}
              onSendMessage={onSendMessage}
              openFileExplorer={open}
              cancelSendMessage={cancelSendMessage}
              openKeyDialog={openKeyDialog}
            />
          </div>
        </div>
      )}
    </UploadZone>
  );
};
