import { handlerApi } from 'api';

export type RequestParamsType = {
  key: string;
  dataset: string;
  table: string;
  datasource: string;
  currentChat: string;
};

export const getEmailBody = ({ key, datasource, dataset, table, currentChat }: RequestParamsType) =>
  handlerApi.run<string>({
    key,
    table,
    dataset,
    method: 'GetEmailBody',
    dataSource: datasource,
    args: { CurrentChat: currentChat },
  });
