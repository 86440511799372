import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './EditorViewScroll.module.scss';

export type EditorViewScrollProps = PropsWithChildren & {
  noIcon?: boolean;
  minGoDownHeight?: number;
};

const SCROLL_BOTTOM_OFFSET = 5;

export const EditorViewScroll: FC<EditorViewScrollProps> = ({ children, minGoDownHeight = 100, noIcon = false }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const [isAtTheBottom, setIsAtTheBottom] = useState<boolean>(false);

  const handleSetIsOverflow = (node: HTMLDivElement) => {
    setIsOverflow(node.scrollHeight > node.clientHeight && node.clientHeight > minGoDownHeight);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const node = entries[0].target;
      handleSetIsOverflow(node as HTMLDivElement);
    });

    if (scrollRef.current) {
      resizeObserver.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        resizeObserver.unobserve(scrollRef.current);
      }
    };
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const node = e.currentTarget;

    if (node) {
      const height = node.scrollTop + node.clientHeight;
      handleSetIsOverflow(node);
      setIsAtTheBottom(
        height >= node.scrollHeight - SCROLL_BOTTOM_OFFSET && height <= node.scrollHeight + SCROLL_BOTTOM_OFFSET
      );
    }
  };

  const handleGoDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: isAtTheBottom ? 0 : scrollRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  if (noIcon) {
    return <div className={styles.noIcon}>{children}</div>;
  }

  return (
    <div className={styles.editorViewScroll}>
      <div ref={scrollRef} onScroll={handleScroll} className={styles.editorViewScroll__content}>
        <div className={styles.children}>{children}</div>
      </div>
      <Icon
        SvgIcon={APP_ICONS.doubleArrowDown}
        className={cx({
          [styles.editorViewScroll__goDown]: !isAtTheBottom,
          [styles.editorViewScroll__goUp]: isAtTheBottom,
          [styles.editorViewScroll__goDown_allowed]: isOverflow,
        })}
        onClick={handleGoDown}
      />
    </div>
  );
};
