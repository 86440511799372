import { Column, useTable } from 'react-table';

import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { EditorFlatTable } from 'view/Editor/components/EditorFlatTable/EditorFlatTable';
import { EditorKeyCell } from 'view/Editor/components/TableBody/TableBodyCell/EditorKeyCell/EditorKeyCell';
import { FlatCell } from 'view/Editor/components/TableBody/TableBodyCell/FlatCell';

type FlatTableViewProps = {
  data: EditorViewDataType[];
  nextTabTitle?: string;
};

export const FlatTableView = ({ data, nextTabTitle }: FlatTableViewProps) => {
  const { rows, prepareRow } = useTable<EditorViewDataType>(
    {
      columns,
      data,
    },
    (hooks) => {
      hooks.prepareRow.push((row) => {
        Object.assign(row, {
          isRowExpanded: row.original.elementKey === nextTabTitle,
        });
      });
    }
  );

  return (
    <EditorFlatTable>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <EditorFlatTable.Row key={row.id} isSelected={row.isRowExpanded}>
            {row.cells.map((cell) => (
              <EditorFlatTable.Cell key={cell.getCellProps().key}>{cell.render('Cell')}</EditorFlatTable.Cell>
            ))}
          </EditorFlatTable.Row>
        );
      })}
    </EditorFlatTable>
  );
};

const columns: Column<EditorViewDataType>[] = [
  {
    id: 'key',
    accessor: 'key',
    Cell: EditorKeyCell,
  },
  {
    id: 'value',
    accessor: 'value',
    Cell: FlatCell,
  },
];
