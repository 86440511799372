import { useNavigate, useParams } from 'react-router-dom';

import { RouterEnum } from 'router/types';

export const useAppNavigate = () => {
  const navigate = useNavigate();
  const { environment } = useParams();

  const navigateToRoot = () => {
    navigate(RouterEnum.ROOT);
  };

  const navigateToEnvironment = (env: string) => {
    navigate(RouterEnum.ENVIRONMENT.replace(':environment', env));
  };

  const navigateToActiveTab = (type: string) => {
    if (environment) {
      navigate(RouterEnum.ACTIVE_TAB.replace(':environment', environment).replace(':activeTab', type));
    }
  };

  const navigateToLogin = () => {
    navigate(RouterEnum.LOGIN);
  };

  const navigateToLoginMultiple = () => {
    navigate(RouterEnum.LOGIN_MULTIPLE);
  };

  const navigateToServerError = () => {
    navigate(RouterEnum.SERVER_ERROR);
  };

  const navigateToNotFound = () => {
    navigate(RouterEnum.NOT_FOUND);
  };

  return {
    navigateToRoot,
    navigateToEnvironment,
    navigateToActiveTab,
    navigateToServerError,
    navigateToNotFound,
    navigateToLogin,
    navigateToLoginMultiple,
  };
};
