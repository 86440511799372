import { FC, useMemo } from 'react';

import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { OptionType, UiSelect } from 'ui';

type EnumValueDataType = OptionType<string | null>;

type EnumValueProps = {
  enumSelector?: string;
  value?: string;
  isDisable?: boolean;
  onChange: (value?: string) => void;
};

export const EnumValue: FC<EnumValueProps> = ({ enumSelector, value, isDisable, onChange }) => {
  const { state } = useEditorState();

  const options = useMemo<EnumValueDataType[]>(() => {
    const enumItems = (enumSelector && state.editorSchema?.[enumSelector]?.Items) || [];
    return [
      { label: '(none)', value: null },
      ...enumItems.map((option) => ({ label: option.Label || option.Name, value: option.Name })),
    ];
  }, [enumSelector, state.editorSchema]);

  const handleChange = (data: OptionType) => {
    onChange(data?.value);
  };

  return (
    <UiSelect
      className="h-100"
      isTransparent
      isDisabled={isDisable}
      menuPortalTarget={document.body}
      value={
        {
          value: value,
          label: value,
        } as EnumValueDataType
      }
      options={options}
      onChange={(data) => handleChange(data as OptionType)}
    />
  );
};

EnumValue.displayName = 'EnumValue';
