import { Header } from '@tanstack/react-table';
import cx from 'classnames';

import { useGrid } from '../../hooks';
import { UIGridHeaderCell } from '../UIGridHeaderCell/UIGridHeaderCell.component';
import { UiGridHeadRow } from '../UIGridHeadRow/UIGridHeadRow.component';
import { UIGridCorner } from '../UIGridCorner/UIGridCorner.component';
import './UIGridHead.styles.scss';

type UIGridHeadProps = {
  className?: string;
};

export const UIGridHead = <TData,>({ className }: UIGridHeadProps) => {
  const { api, headerRef } = useGrid<TData>();
  const resizeMode = api.options.columnResizeMode!; // TODO: remove ! operator.
  const resizingDeltaOffset = api.getState().columnSizingInfo.deltaOffset || 0;

  const renderCell = <TValue,>(header: Header<TData, TValue>) => (
    <UIGridHeaderCell resizeMode={resizeMode} header={header} resizingDeltaOffset={resizingDeltaOffset} />
  );

  return (
    <div className={cx('ui-grid-header', className)} ref={headerRef}>
      {api.getHeaderGroups().map((headerGroup) => (
        <UiGridHeadRow key={headerGroup.id} headerGroup={headerGroup} renderCell={renderCell} />
      ))}
      <UIGridCorner className="ui-grid-header__corner" direction="vertical" />
    </div>
  );
};
