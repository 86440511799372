import { GridTabDataType } from 'store/types';
import { SchemaTypesItemModel } from 'api';
import { escapeStringRegexp } from 'utils/helpers/escapeStringRegexp';

import { SchemaSearchType } from './types';

export const getTypeTabData = (types?: SchemaTypesItemModel[]): GridTabDataType[] | undefined =>
  types &&
  types.map(({ Name, Label }) => ({
    key: Name,
    label: Label,
    id: Name,
  }));

export const filterSchemaTypes = (types: SchemaTypesItemModel[]): SchemaSearchType => {
  const NotEmptyTypes = types.filter((type) => type.DataAvailable);
  return {
    OpenedTypes: [],
    FinalTypes: getTypeTabData(types) ?? [],
    NotEmptyTypes: getTypeTabData(NotEmptyTypes) ?? [],
  };
};

export const filterTypeHelper = (typesArr: GridTabDataType[], text: string): GridTabDataType[] => {
  if (!text) return typesArr;

  const filteredArr: GridTabDataType[] = [];
  const filteredWithSpacesArr: GridTabDataType[] = [];
  const lowerCaseText = text.toLowerCase();
  const regex = new RegExp(`(${escapeStringRegexp(text)})`, 'gi');

  typesArr.forEach((item) => {
    const isAbbreviationMatch = item.label
      .split(' ')
      .map((label) => label.charAt(0))
      .join('')
      .toLowerCase()
      .includes(lowerCaseText);

    if (item.label.toLowerCase().includes(lowerCaseText) || isAbbreviationMatch) {
      filteredArr.push(item);
    } else if (item.label.match(regex)) {
      filteredWithSpacesArr.push(item);
    }
  });

  const sortFilteredArr = (arr: GridTabDataType[]) =>
    arr.sort(
      (a, b) =>
        a.label.toLowerCase().indexOf(lowerCaseText) - b.label.toLowerCase().indexOf(lowerCaseText) ||
        a.label.length - b.label.length
    );

  return [...sortFilteredArr(filteredArr), ...sortFilteredArr(filteredWithSpacesArr)];
};
