import { convertSchemaTypeDtoToModel, convertSchemaTypeHierarchyDtoToModel } from 'api/converter/schema.converter';

import {
  SchemaTypeRequestDto,
  SchemaTypeHierarchyRequestDto,
  SchemaTypeHierarchyResponseDto,
} from '../data/schema.data';
import { axiosMain } from '../axios';
import {
  SchemaTypeModel,
  SchemaTypesItemModel,
  SchemaInterfaceModel,
  SchemaEnumModel,
  SchemaTypeHierarchyModel,
  SchemaTypeDataModel,
  SchemaModeluesItemModel,
} from '../model/schema.model';

export type SchemaApi = {
  getTypes(): Promise<SchemaTypesItemModel[]>;
  getType(data: SchemaTypeRequestDto): Promise<SchemaTypeDataModel>;
  getInterfaces(): Promise<SchemaInterfaceModel[]>;
  getEnums(): Promise<SchemaEnumModel[]>;
  getTypeHierarchy(data: SchemaTypeHierarchyRequestDto): Promise<SchemaTypeHierarchyModel[]>;
  getModules(): Promise<SchemaModeluesItemModel[]>;
};

export const schemaApi: SchemaApi = {
  async getType(params: SchemaTypeRequestDto): Promise<SchemaTypeDataModel> {
    const res = await axiosMain.get<Record<string, SchemaTypeModel>>('/schema/typeV2', {
      params,
    });

    return convertSchemaTypeDtoToModel(res.data, params.name);
  },
  async getTypes(): Promise<SchemaTypesItemModel[]> {
    const res = await axiosMain.get<SchemaTypesItemModel[]>('/schema/types');

    return res.data;
  },
  async getInterfaces(): Promise<SchemaInterfaceModel[]> {
    const res = await axiosMain.get<SchemaInterfaceModel[]>('/schema/interfaces');

    return res.data;
  },
  async getEnums(): Promise<SchemaEnumModel[]> {
    const res = await axiosMain.get<SchemaEnumModel[]>('/schema/enums');

    return res.data;
  },
  async getTypeHierarchy({
    name,
    returnAncestors = false,
  }: SchemaTypeHierarchyRequestDto): Promise<SchemaTypeHierarchyModel[]> {
    const res = await axiosMain.get<SchemaTypeHierarchyResponseDto>('/schema/type-hierarchy', {
      params: {
        name,
        return_ancestors: returnAncestors,
      },
    });

    return convertSchemaTypeHierarchyDtoToModel(res.data);
  },
  async getModules(): Promise<SchemaModeluesItemModel[]> {
    const res = await axiosMain.get<SchemaModeluesItemModel[]>('/schema/modules');

    return res.data;
  },
};
