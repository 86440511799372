import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { SchemaTypeElementsModel } from 'api';

import { QueryBuilderContextType } from '../contexts';
import { QueryBuilderHeader, QueryBuilderHeaderProps } from './QueryBuilderHeader/QueryBuilderHeader.component';
import { QueryBuilderIntroduction } from './QueryBuilderIntroduction/QueryBuilderIntroduction.component';
import { QueryBuilderContent } from './QueryBuilderContent/QueryBuilderContent.component';
import { QueryBuilderProvider } from './QueryBuilderProvider/QueryBuilderProvider.component';
import { QueryType } from '../types';

import styles from './QueryBuilder.module.scss';

export type QueryBuilderProps = {
  initialQuery: QueryType;
  elements: SchemaTypeElementsModel[];
  renderHeader?: (props: QueryBuilderHeaderProps) => ReactNode;
  onCancel: () => void;
  onApply: (updatedQuery: QueryType) => void;
  onReset: () => void;
};

export const QueryBuilder: FC<QueryBuilderProps> = memo(
  ({ initialQuery, elements, renderHeader = QueryBuilderHeader, onCancel, onApply, onReset }) => {
    const { t } = useTranslation();

    return (
      <QueryBuilderProvider initialQuery={initialQuery} elements={elements} onReset={onReset}>
        {(context: QueryBuilderContextType) => (
          <div className="d-flex flex-column gap-1 h-100">
            <div
              className={cx(
                'd-flex align-items-center px-2_5 py-1 fs-14 lh-1 border-bottom border-grid-input-bottom-border flex-shrink-0',
                styles.header
              )}
            >
              {renderHeader({ title: t('queryBuilder.title') })}
            </div>
            <div className="d-flex flex-grow-1 overflow-y-auto">
              {context.isShowIntroductionSection ? (
                <QueryBuilderIntroduction className="w-100 h-100" />
              ) : (
                <QueryBuilderContent onCancel={onCancel} onApply={onApply} />
              )}
            </div>
          </div>
        )}
      </QueryBuilderProvider>
    );
  }
);

QueryBuilder.displayName = 'QueryBuilder';
