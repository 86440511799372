import { FC, ReactNode } from 'react';
import cx from 'classnames';
import './EditorFlatTable.scss';

export type EditorFlatTableComponentProps = FC<EditorFlatTableProps> & {
  Row: FC<EditorFlatTableRowProps>;
  Cell: FC<EditorFlatTableCellProps>;
};

type EditorFlatTableProps = {
  children: ReactNode;
};

export const EditorFlatTable: EditorFlatTableComponentProps = ({ children }) => (
  <table className="editor-flat-table">
    <colgroup>
      <col className="editor-flat-table__label-column-size" />
      <col className="editor-flat-table__value-column-size" />
    </colgroup>
    <tbody>{children}</tbody>
  </table>
);

export type EditorFlatTableRowProps = {
  isSelected?: boolean;
  children: ReactNode;
};
EditorFlatTable.Row = ({ children, isSelected }) => (
  <tr tabIndex={-1} className={cx('editor-flat-table__tr', { 'editor-flat-table__tr_selected': isSelected })}>
    {children}
  </tr>
);
EditorFlatTable.Row.displayName = 'EditorFlatTableRow';

export type EditorFlatTableCellProps = {
  children: ReactNode;
};
EditorFlatTable.Cell = ({ children }) => <td className="editor-flat-table__td">{children}</td>;
EditorFlatTable.Cell.displayName = 'EditorFlatTableCell';
