import { ChangeEvent, useCallback, useEffect } from 'react';

import { useAppContext } from 'context';

import { useLocalStorage } from 'shared/Hooks/useLocalStorage';

export function useMessage(initialMessage = '') {
  const { openTabParams } = useAppContext();

  // TODO: add key based on the session ID.
  const [message, setMessage] = useLocalStorage<string>('chat-message', initialMessage);

  useEffect(() => {
    openTabParams.chatAbout.getParams((chatParams) => {
      if (chatParams) {
        setMessage('');
      }
    });
  }, []);

  const onChangeEvent = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
  }, []);

  const onClear = useCallback(() => {
    setMessage(initialMessage);
  }, [initialMessage]);

  return {
    message,
    onChangeEvent,
    onChange: setMessage,
    onClear,
  };
}
