import { useContext } from 'react';

import { QueryBuilderContext, QueryBuilderContextType } from '../contexts';

export function useQueryBuilderContext(): QueryBuilderContextType {
  const context = useContext(QueryBuilderContext);

  if (context == null) {
    throw new Error('QueryBuilder components must be wrapped in <QueryBuilderProvider>');
  }

  return context;
}
