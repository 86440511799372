import { FC, memo } from 'react';
import cx from 'classnames';

import styles from './styles.module.scss';

type VersionPairProps = {
  label: string;
  value: string;
};

export const VersionPair: FC<VersionPairProps> = memo(({ label, value }) => (
  <div className="d-flex align-items-center gap-1">
    <span className={cx(styles.key, 'text-truncate text-user-info-versions-text')}>{label}</span>
    <span>{value}</span>
  </div>
));

VersionPair.displayName = 'VersionPair';
