import { FC } from 'react';

type ImageViewerProps = {
  content: string;
};

export const convertBase64toImage = (content: string) => `data:image/;base64,${content}`;

export const ImageViewer: FC<ImageViewerProps> = ({ content }) => (
  <div className="d-flex w-100 h-100 px-4 pt-0 pb-7">
    <img className="h-auto w-auto m-auto mw-100 mh-100" src={convertBase64toImage(content)} />
  </div>
);
