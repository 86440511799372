export const prevSiblingStyling = (closestTabWrapperCopy: HTMLElement, draggingTab: HTMLDivElement) => {
  let prevSibling = closestTabWrapperCopy.previousSibling as HTMLElement;

  while (prevSibling) {
    if (prevSibling === draggingTab) {
      prevSibling = prevSibling.previousSibling as HTMLElement;
    }

    if (prevSibling === null) {
      break;
    }

    prevSibling.style.transform = `translate(0px, 0px)`;

    prevSibling = prevSibling.previousSibling as HTMLElement;
  }
};
