import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { UiModal } from 'ui';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { selectActiveEnvironment } from 'store/selectors/environment.selector';
import { useRequest } from 'hook/request.hook';
import { Loader } from 'shared/Loader';
import { Button } from 'shared/Button';

import { NewChatProfileDescription } from './NewChatProfileDescription';
import { chatApi, ProfileModel, ProfilesStructureModel } from '../../api';
import { NewChatTabs } from './NewChatTabs';
import { NewChatTabList } from './NewChatTabList';

import styles from './styles.module.scss';

type NewChatModalProps = {
  activeSession?: SessionType;
  onOpen: () => void;
  onClose: () => void;
  onCreate: (promptId: string | undefined) => void;
};

export const NewChatModal: FC<NewChatModalProps> = ({ onClose, onCreate }) => {
  const dataset = useSelector(selectActiveDataset);
  const environment = useSelector(selectActiveEnvironment);

  const { state: profilesStructure, isLoading } = useRequest<ProfilesStructureModel>(
    () =>
      chatApi.profilesStructure({
        dataset,
        dataSource: environment,
      }),
    {
      autorun: true,
    }
  );

  const tabs = Object.keys(profilesStructure ?? {});
  const [activeTab, setActiveTab] = useState<string>('');

  const groupList = profilesStructure?.[activeTab] ?? [];
  const [activeProfile, setActiveProfile] = useState<ProfileModel | null>(null);

  useEffect(() => {
    if (profilesStructure) {
      setActiveTab(tabs[0]);
      setActiveProfile(profilesStructure[tabs[0]][0]);
    }
  }, [profilesStructure]);

  const handleActiveTabChange = (tab: string) => {
    if (profilesStructure) {
      setActiveTab(tab);
      setActiveProfile(profilesStructure[tab][0]);
    }
  };

  const handleCreateChat = () => {
    onCreate(activeProfile?.profileName);
  };

  return (
    <UiModal
      className={styles.modal}
      isOpen
      defaultHeight={450}
      minHeightConstraints={450}
      defaultWidth={800}
      minWidthConstraints={800}
      title="New Chat"
      onClose={onClose}
    >
      <UiModal.Body className={cx(styles.body, 'px-0 pt-0 overflow-hidden')}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.navList}>
              <div className={styles.tabsContainer}>
                <NewChatTabs tabs={tabs} activeTab={activeTab} onTabChange={handleActiveTabChange} />
              </div>
              <div className="d-flex border-top border-bottom border-gray-100 flex-grow-1 overflow-auto">
                <NewChatTabList
                  list={groupList}
                  activeProfile={activeProfile?.profileName}
                  onProfileChange={setActiveProfile}
                />

                {activeProfile?.profileDescription ? (
                  <NewChatProfileDescription description={activeProfile?.profileDescription} />
                ) : (
                  <NewChatProfileDescription description="No description available" />
                )}
              </div>
            </div>
          </>
        )}
      </UiModal.Body>
      <UiModal.Footer>
        <Button title={'Cancel'} variant="secondary" onClick={onClose} />
        <Button title={'Create'} variant="primary" disabled={!activeProfile} onClick={handleCreateChat} />
      </UiModal.Footer>
    </UiModal>
  );
};
