import { FC, Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { selectIsAuthenticated, selectType } from 'store/selectors/AppState.selector';
import { RouterEnum } from 'router';

type LoginGuardProps = PropsWithChildren;

export const LoginGuard: FC<LoginGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const typeId = useSelector(selectType);

  if (!typeId) {
    return <Navigate to={RouterEnum.LOGIN_MULTIPLE} />;
  }

  if (isAuthenticated) {
    return <Navigate to={RouterEnum.ROOT} />;
  }

  return <Fragment>{children}</Fragment>;
};
