import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { createFormBuilder, formRules, UIButton, UIForm } from 'ui';
import { authApi, getErrorMessage } from 'api';
import Logo from 'assets/logos/cl-logo.svg';
import { useAppDispatch } from 'store/hooks';
import { useAppNavigate } from 'router/hooks';
import { clearType, setTokens } from 'store/shared-reducers/AppStateSlice';
import { selectType } from 'store/selectors/AppState.selector';

import { CredentialType } from '../../types';
import { LoginError } from '../../components';
import styles from './Login.module.scss';

export const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { navigateToRoot, navigateToLoginMultiple } = useAppNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();
  const selectedAuthType = useSelector(selectType);

  const controls = useMemo(
    () =>
      createFormBuilder<CredentialType>()
        .addTextControl({
          name: 'username',
          autoComplete: 'username',
          className: cx(styles.control, styles.control__username),
          placeholder: t('auth.login.username.placeholder'),
          rules: [formRules.required(t('auth.login.username.required'))],
        })
        .addPasswordControl({
          name: 'password',
          autoComplete: 'current-password',
          className: cx(styles.control, styles.control__password),
          placeholder: t('auth.login.password.placeholder'),
          rules: [formRules.required(t('auth.login.password.required'))],
        })
        .build(),
    []
  );

  const clearErrorMessage = () => setErrorMessage('');

  const handleSubmit = async (credentials: CredentialType) => {
    clearErrorMessage();

    try {
      const tokens = await authApi.login({
        type_id: selectedAuthType?.id || '',
        username: credentials.username,
        password: credentials.password,
      });
      dispatch(setTokens(tokens));
      navigateToRoot();
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  const handleValuesChanged = (credentials: CredentialType) => {
    const isCredentialsEmpty = Object.values(credentials).every((field) => !field);

    if (isCredentialsEmpty && errorMessage) {
      clearErrorMessage();
    }
  };

  const backToTypes = () => {
    dispatch(clearType());
    navigateToLoginMultiple();
  };

  return (
    <div className="d-flex justify-content-start flex-column overflow-auto bg-primary content">
      <div className={styles.container}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <h4 className={styles.title}>{t('auth.login.title')}</h4>
        <p className={styles.nameContainer}>{selectedAuthType?.name || ''}</p>
        {errorMessage && <LoginError message={errorMessage} />}
        <UIForm
          initialValues={{ username: '', password: '' }}
          controls={controls}
          onSubmit={handleSubmit}
          onValuesChange={handleValuesChanged}
          slots={{
            actions: ({ isSubmitting }) => (
              <div className={styles.formControls}>
                <UIButton disabled={isSubmitting} className={styles.loginBtn}>
                  {t('auth.login.actions.login')}
                </UIButton>
                <UIButton variant="secondary" disabled={isSubmitting} onClick={backToTypes} className={styles.loginBtn}>
                  {t('auth.login.actions.backToTypes')}
                </UIButton>
              </div>
            ),
          }}
        />
      </div>
    </div>
  );
};
