import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { ComplexView } from 'view/Editor/components/EditorView/ComplexView';
import { ComplexVectorView } from 'view/Editor/components/EditorView/ComplexVectorView';
import { DictionaryDataModel, FlatViewModel, SchemaTypeModel, SimpleViewModel, StorageEntityModel } from 'api';
import { ComplexVectorSimpleList } from 'view/Editor/components/EditorView/ComplexVectorSimpleList';
import { DictionaryView } from 'view/Editor/components/EditorView/DictionaryView/DictionaryView.component';
import { ValueEditorView } from 'view/Editor/components/EditorView/ValueEditorView/ValueEditorView.component';
import { UiEmpty } from 'ui';
import { getPropByPath } from 'view/Editor/helpers/getPropByPath.helper';
import { SchemaType } from 'view/Editor/helpers/getCellProps.helper';
import { EditorViewScroll } from 'view/Editor/components/EditorViewScroll/EditorViewScroll.component';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

type EditorViewProps = {
  tab: EditorTabType;
  data: StorageEntityModel;
  schema?: SchemaTypeModel;
  nextTabTitle: EditorTabType['title'];
};

export const EditorView = ({ tab, data, schema, nextTabTitle }: EditorViewProps) => {
  const { t } = useTranslation();

  const activeViewData = useMemo(() => data && getPropByPath(data, tab.path), [data, tab]);
  const { state } = useEditorState();
  const { isReadOnly } = state;

  switch (true) {
    case !!tab.tabEditorValueType:
      return <ValueEditorView tab={tab} value={activeViewData as string} />;
    case tab.path.length === 0 || tab.isFlat:
      return schema ? (
        <ComplexView data={activeViewData as FlatViewModel} schema={schema} tab={tab} nextTabTitle={nextTabTitle} />
      ) : (
        <UiEmpty title="Empty view schema" className="px-3" />
      );
    case tab.schemaType === SchemaType.Dict:
      return (
        <EditorViewScroll>
          <DictionaryView
            title={tab.title}
            data={activeViewData as DictionaryDataModel[]}
            path={tab.path}
            isReadonly={isReadOnly}
          />
        </EditorViewScroll>
      );
    case tab.isVector && !tab.selector:
      return (
        <EditorViewScroll>
          <ComplexVectorSimpleList data={activeViewData as SimpleViewModel[]} tab={tab} />
        </EditorViewScroll>
      );
    case tab.isVector:
      return (
        <EditorViewScroll>
          <ComplexVectorView data={activeViewData as StorageEntityModel} schema={schema} tab={tab} />
        </EditorViewScroll>
      );
    default:
      return <div>{t('shared.somethingWentWrong')}</div>;
  }
};
