import { handlerApi } from 'api';
import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { externalStorage } from 'shared/ExternalStorage';

export type RequestParamsType = {
  key: string;
  dataset: string;
  table: string;
  datasource: string;
  isEncrypted?: boolean;
  promptId?: string;
  activeChat?: string;
};

export const startNewChatRequest = ({
  key,
  datasource,
  dataset,
  table,
  isEncrypted = false,
  promptId,
  activeChat,
}: RequestParamsType) =>
  handlerApi.run<SessionType>({
    key,
    table,
    dataset,
    method: 'StartNewChat',
    dataSource: datasource,
    args: {
      LlmToUse: externalStorage.getSelectedLlm(),
      encrypted: isEncrypted,
      ...(promptId && { profile: { Name: promptId } }),
      ActiveChat: activeChat,
    },
  });
