import { AxiosRequestConfig } from 'axios';

interface RetryQueueItem {
  resolve: <T>(value?: T) => void;
  reject: <T>(error?: T) => void;
  config: AxiosRequestConfig;
}

export const createRefreshAndRetryQueue = () => {
  let queue: RetryQueueItem[] = [];
  return {
    iterate(callback: (item: RetryQueueItem) => void) {
      queue.forEach(callback);
    },
    enqueue(item: RetryQueueItem) {
      queue.push(item);
    },
    clear() {
      queue = [];
    },
  };
};
