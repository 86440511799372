import { FC, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { EntityPanelViewOfModel } from 'api';
import { KeyboardCode } from 'utils';

import styles from './styles.module.scss';

type ViewersListProps = {
  viewerKey?: string;
  viewersList: EntityPanelViewOfModel[];
  activeViewerName?: string;
  handleActiveViewer: (viewer: EntityPanelViewOfModel) => void;
};

export const ViewersList: FC<ViewersListProps> = ({ viewersList, activeViewerName, handleActiveViewer }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleTabKeyDown = (e: KeyboardEvent) => {
    if ((e.code as KeyboardCode) === KeyboardCode.Tab) {
      e.preventDefault();
      ref.current && ref.current.focus();
    }
  };

  useEffect(() => {
    // Functionality for focusing on a block when pressing the Tab button
    window.addEventListener('keydown', handleTabKeyDown);

    return () => {
      window.removeEventListener('keydown', handleTabKeyDown);
    };
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const activeIndex = viewersList.findIndex((viewer) => viewer.Name?.String === activeViewerName);
    let nextViewer;

    if (activeIndex === -1) return;

    if ((e.code as KeyboardCode) === KeyboardCode.ArrowDown) {
      nextViewer = viewersList[activeIndex + 1];
    }

    if ((e.code as KeyboardCode) === KeyboardCode.ArrowUp) {
      nextViewer = viewersList[activeIndex - 1];
    }

    if (nextViewer) {
      handleActiveViewer(nextViewer);
    }
  };

  // ToDo: Remove once backend is corrected
  const checkNameMatch = (viewer: EntityPanelViewOfModel) =>
    (viewer.Name && Object.hasOwn(viewer.Name, 'String') && activeViewerName === viewer.Name.String) ||
    activeViewerName === viewer.Name;

  return (
    <div className={styles.wrapper} tabIndex={0} onKeyDown={handleKeyDown} ref={ref}>
      {viewersList.map((viewer) => (
        <div
          key={uuid()}
          className={cx(styles.viewerItem, {
            [styles.active]: checkNameMatch(viewer),
          })}
          onClick={() => handleActiveViewer(viewer)}
        >
          {viewer.Name?.String || viewer.ViewName || String(viewer.Name) || ''}
        </div>
      ))}
    </div>
  );
};
