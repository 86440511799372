import { SortOrderEnum } from 'view/Grid/components/TableHeader/utils/types';
import { APP_ICONS } from 'utils/icons';

import { SortDropdownItemsType } from './SortDropdown.types';

export const sortDropdownItems: SortDropdownItemsType[] = [
  {
    label: 'Sort Asc',
    value: SortOrderEnum.ASC,
    SvgIcon: APP_ICONS.sortAZ,
  },
  {
    label: 'Sort Desc',
    value: SortOrderEnum.DESC,
    SvgIcon: APP_ICONS.sortZA,
  },
  {
    label: 'No Sort',
    value: SortOrderEnum.NONE,
    SvgIcon: APP_ICONS.noSort,
  },
];
