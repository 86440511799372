import { SortingColumn } from '@tanstack/react-table';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

type UIGridSortingIndicatorProps<TData> = {
  sorting: ReturnType<SortingColumn<TData>['getIsSorted']>;
};

export const UIGridSortingIndicator = <TData,>({ sorting }: UIGridSortingIndicatorProps<TData>) => {
  if (!sorting) return null;

  return (
    <Icon clickable={false} SvgIcon={sorting === 'desc' ? APP_ICONS.sortZA : APP_ICONS.sortAZ} color="text-icon1" />
  );
};
