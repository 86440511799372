import { FC, useMemo } from 'react';

import { SchemaTypeElementsModel } from 'api';
import { QueryType, ValueWithConditionType } from 'view/QueryBuilder';

import { QueryBuilderConditionCell, QueryBuilderKeyCell, QueryBuilderTable, QueryBuilderValueCell } from './components';
import { mapQueryToRows } from './utils/helpers';

type QueryBuilderEditorProps = {
  className?: string;
  schemaDictionary: Record<string, SchemaTypeElementsModel>;
  query: QueryType;
  updateValueByKey: (key: string, value: ValueWithConditionType) => void;
};

export const QueryBuilderEditor: FC<QueryBuilderEditorProps> = ({
  className,
  query,
  schemaDictionary,
  updateValueByKey,
}) => {
  const rows = useMemo(
    () => mapQueryToRows(query, schemaDictionary, updateValueByKey),
    [query, schemaDictionary, updateValueByKey]
  );

  return (
    <div className={className}>
      <QueryBuilderTable
        rows={rows}
        renderKeyCell={QueryBuilderKeyCell}
        renderConditionCell={QueryBuilderConditionCell}
        renderValueCell={QueryBuilderValueCell}
      />
    </div>
  );
};
