import { ElementRectWithCoordinates } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';

import { CharIndexType } from './types';
import { unwrap } from './helpers';

export class CharIndexSpan {
  private charIndexes: CharIndexType[];

  private readonly elements: Element[] = [];

  constructor(initialCharIndexes: CharIndexType[], elements: Element[]) {
    this.charIndexes = initialCharIndexes;
    this.elements = elements;
  }

  public toRectWithCoordinates(containerRect: DOMRect): ElementRectWithCoordinates | null {
    const { firstChild } = this.element;
    if (!firstChild || firstChild.nodeType !== Node.TEXT_NODE) return null;

    const length = firstChild.textContent?.length || 0;
    const { start: startOffset, end: endOffset } = this.offsets;
    if (startOffset > length || endOffset + 1 > length) return null;

    const range = this.createRange(firstChild, startOffset, endOffset + 1);
    return this.normalizedCoordinatesByParent(containerRect, this.getRectAndCoordinatesByRange(range));
  }

  private normalizedCoordinatesByParent(parentRect: DOMRect, coordinates: ElementRectWithCoordinates) {
    return {
      ...coordinates,
      left: coordinates.left - parentRect.left,
      top: coordinates.top - parentRect.top,
    };
  }

  private getRectAndCoordinatesByRange(range: Range): ElementRectWithCoordinates {
    const wrapper = document.createElement('span');
    range.surroundContents(wrapper);

    const wrapperRect = wrapper.getBoundingClientRect();

    const elementWithCoordinates = {
      left: wrapperRect.left,
      top: wrapperRect.top,
      height: wrapperRect.height,
      width: wrapperRect.width,
    };

    unwrap(wrapper);

    return elementWithCoordinates;
  }

  private createRange(node: ChildNode, start: number, end: number): Range {
    const range = document.createRange();
    range.setStart(node, start);
    range.setEnd(node, end);

    return range;
  }

  private get offsets() {
    const start = this.charIndexes[0].charIndexInSpan;
    const end = this.charIndexes.length === 1 ? start : this.charIndexes[this.charIndexes.length - 1].charIndexInSpan;

    return { start, end };
  }

  private get element(): Element {
    return this.elements[this.charIndexes[0].spanIndex];
  }
}
