import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ValueType } from 'store/types';
import {
  DateFormatEnum,
  DateFormatUtility,
  ValueTypesEnum,
  isDefined,
  isEmptyString,
  isInt,
  isString,
  isStringBool,
  toString,
} from 'utils';

import { ErrorType, ErrorTypesEnum } from './types';

type UseValidationProps = {
  type?: ValueType['Type'];
  isRequired?: boolean;
};

export const useValidation = ({ isRequired, type }: UseValidationProps) => {
  const { t } = useTranslation();
  const [validateError, setValidateError] = useState<ErrorType>();

  const validate = useCallback(
    (value?: unknown) => {
      const generateError = () => {
        switch (true) {
          case (!isDefined(value) || isEmptyString(value)) && isRequired:
            return {
              type: ErrorTypesEnum.REQUIRED,
              message: t('validationMessage.fieldRequired'),
            };

          case type === ValueTypesEnum.Bool && !isStringBool(value):
            return {
              type: ErrorTypesEnum.VALIDATE,
              message: t('validationMessage.boolValue'),
            };

          case type === ValueTypesEnum.Int && !isInt(value) && toString(value).length > 0:
            return {
              type: ErrorTypesEnum.VALIDATE,
              message: t('validationMessage.intValue'),
            };

          case type === ValueTypesEnum.Date && isString(value) && !DateFormatUtility.create(value).isValid():
            return {
              type: ErrorTypesEnum.VALIDATE,
              message: t('validationMessage.dateValue'),
            };

          case type === ValueTypesEnum.Time &&
            isString(value) &&
            !DateFormatUtility.create(value, DateFormatEnum.TIME_MS).isValid():
            return {
              type: ErrorTypesEnum.VALIDATE,
              message: t('validationMessage.timeValue'),
            };

          case type === ValueTypesEnum.DateTime && isString(value) && !DateFormatUtility.create(value).isValid():
            return {
              type: ErrorTypesEnum.VALIDATE,
              message: t('validationMessage.dateTimeValue'),
            };

          default:
            return undefined;
        }
      };

      const error = generateError();
      setValidateError(error);
      return error;
    },
    [isRequired, t, type]
  );

  return { validate, validateError };
};
