export enum LogsListViewEnum {
  FLAT_VIEW = 'flatView',
  HANDLERS = 'handlers',
  NOTIFICATIONS = 'notifications',
}

export type LogsEventDataType = {
  Level: string;
  Message: string;
  Name: string;
  Raw: string;
  Timestamp: string;
  User: string;
  _t: string;
};

export enum LogsLevelEnum {
  CRITICAL = 'CRITICAL',
  FATAL = 'FATAL',
  ERROR = 'ERROR',
  WARN = 'WARN',
  WARNING = 'WARNING',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  NOTSET = 'NOTSET',
}
