import { useTranslation } from 'react-i18next';
import type { DropResult } from '@hello-pangea/dnd';
import { type FC, ReactElement, useContext } from 'react';
import { useSelector } from 'react-redux';

import { GridTabDataType } from 'store/types';
import { useBoolean } from 'utils/hooks';
import { DnDContext } from 'view/DragnDrop/DraggableContainer';
import { ConfigType, LayoutType } from 'view/DragnDrop/utils/types';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { findParent } from 'view/DragnDrop/utils/helpers/findParent';
import { correctContent } from 'view/DragnDrop/utils/helpers/correctContent';
import { findTarget } from 'view/DragnDrop/utils/helpers/findTarget';
import { isGridTabDataType } from 'view/DragnDrop/utils/guards/isGridTabDataType';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';
import { useAppContext } from 'context';
import { selectApplicationTheme } from 'store/selectors';
import { THEME_KEYS } from 'store/constants/theme';

import { useComponentsMapper } from 'shared/Hooks/useComponentsMapper';

import { TabsScrollable } from './components/TabsScrollable/TabsScrollable';
import { TabsDefault } from './components/TabsDefault/TabsDefault';
import { TabItemProps } from './components/TabItem';
import { filterOtherTabs, filterUncloseableTabs, reorderTabs } from './utils/helpers';

export type TabsProps = {
  dndTabs?: boolean;
  isChatTabs?: boolean;
  openedTabs: GridTabDataType[];
  activeTab?: string;
  activeTabs?: string[];
  shouldTranslate?: boolean;
  stackRef?: ConfigType | GridTabDataType;
  renderRightContent?: ReactElement;
  onChangeTab: (tab: GridTabDataType) => void;
  onCloseTab?: (tab: GridTabDataType) => void;
  onSetTabs: (tabs: GridTabDataType[]) => void;
  isCurrentlyDragging?: boolean;
  isEditorTabs?: boolean;
  isDashboardTabs?: boolean;
};

export const TabsInner: FC<TabsProps> = (props) => {
  const {
    dndTabs,
    isChatTabs,
    openedTabs,
    activeTab,
    activeTabs,
    shouldTranslate,
    renderRightContent,
    onChangeTab,
    onCloseTab,
    onSetTabs,
    stackRef,
    isEditorTabs = false,
    isDashboardTabs = false,
  } = props;

  const { t } = useTranslation();
  const [isDragging, { set: setIsDragging }] = useBoolean(false);
  const { state, action } = useAppContext();
  const {
    isDragging: isCurrentlyDragging,
    setDraggingLabel,
    config,
    setConfig,
    selectedGridRow,
    saveLayout,
  } = useContext(DnDContext);
  const theme = useSelector(selectApplicationTheme);

  const handleReorderTabs = (tabs: GridTabDataType[], startIndex: number, endIndex: number) => {
    const reorderedTabs = reorderTabs(tabs, startIndex, endIndex);

    onSetTabs(reorderedTabs);
  };

  const role = 'tab';

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);

    if (!result.destination || result.destination.index === result.source.index) return;

    handleReorderTabs(openedTabs, result.source.index, result.destination.index);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onCloseOtherTabs = (id: string) => {
    onSetTabs(filterOtherTabs(openedTabs, id));
  };

  const onCloseAllTabs = () => {
    onSetTabs(filterUncloseableTabs(openedTabs));
  };

  const getTabProps = (currentTab: GridTabDataType): TabItemProps => {
    const { label, id, isCloseable, key, disableActions } = currentTab;

    return {
      isChatTab: isChatTabs,
      isActive: id === activeTab || activeTabs?.some((tab) => tab === id) || false,
      label: shouldTranslate ? t(label) : label,
      isCloseable,
      disableActions,
      role,
      isRecordTab: key.includes(EditorTabsEnum.EDITOR_TRANSITION),
      isDashboardTab: isDashboardTabs,
      onClick: () => {
        activeTab !== id && onChangeTab(currentTab);

        !isChatTabs && activeTab !== id && !state.showLineHider && action.setShowLineHiderAction(true);
      },
      onCloseTab: (event) => {
        event?.stopPropagation();

        !isChatTabs && activeTab === id && !state.showLineHider && action.setShowLineHiderAction(true);

        isCloseable !== false && onCloseTab?.(currentTab);

        if (stackRef && selectedGridRow && selectedGridRow.type) {
          const configCopy = structuredClone(config);

          const parent = findParent(configCopy, currentTab.id) as ConfigType;
          const stackIndex = parent.content.findIndex((el) => el.id === currentTab.id);

          const editorTab = findTarget(parent, EditorTabsEnum.EDITOR);

          if (editorTab && currentTab.isActive && isGridTabDataType(editorTab)) {
            editorTab.isActive = true;
          } else if (parent.content.length - 1 > stackIndex && currentTab.isActive) {
            (parent.content[stackIndex + 1] as GridTabDataType).isActive = true;
          } else if (parent.content.length > 1 && parent.content.length - 1 === stackIndex && currentTab.isActive) {
            (parent.content[stackIndex - 1] as GridTabDataType).isActive = true;
          }

          parent.content.splice(stackIndex, 1);

          const correctedConfigCopy = correctContent(configCopy) as ConfigType;

          correctedConfigCopy.id = `${correctedConfigCopy.type}-${correctedConfigCopy.content[0]?.key.replace(
            /\s/g,
            ''
          )}`;
          correctedConfigCopy.key = correctedConfigCopy.id;

          const simplifiedConfig = getParsedConfig(correctedConfigCopy, true) as LayoutType;

          setConfig(correctedConfigCopy);
          saveLayout(simplifiedConfig);
        }
      },
      onCloseOtherTabs: () => onCloseOtherTabs(id),
      onCloseAllTabs: onCloseAllTabs,
    };
  };

  if (theme === THEME_KEYS.demo) {
    return (
      <TabsScrollable
        activeTab={activeTab}
        dndTabs={dndTabs}
        stackRef={stackRef}
        isEditorTabs={isEditorTabs}
        isDashboardTabs={isDashboardTabs}
        isChatTabs={isChatTabs}
        openedTabs={openedTabs}
        isDragging={isDragging}
        getTabProps={getTabProps}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onSetDraggingTab={setDraggingLabel}
        renderRightContent={renderRightContent}
      />
    );
  }

  return (
    <TabsDefault
      activeTab={activeTab}
      dndTabs={dndTabs}
      stackRef={stackRef}
      isEditorTabs={isEditorTabs}
      isDashboardTabs={isDashboardTabs}
      isChatTabs={isChatTabs}
      openedTabs={openedTabs}
      isDragging={isDragging}
      getTabProps={getTabProps}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      renderRightContent={renderRightContent}
      isCurrentlyDragging={isCurrentlyDragging}
      onReorderTabs={handleReorderTabs}
      onSetDraggingTab={setDraggingLabel}
    />
  );
};

export const Tabs = (props: TabsProps) => {
  const { TabsInner: TabsInnerComponent } = useComponentsMapper();

  return <TabsInnerComponent {...props} />;
};
