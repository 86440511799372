import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';

import { TooltipOptions } from '../utils/Tooltip.types';
import { handleClose } from '../utils/handleClose';

export function useTooltip({
  enabled = true,
  initialOpen = false,
  placement = 'top',
  delay,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const arrowRef = useRef(null);
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = enabled ? controlledOpen ?? uncontrolledOpen : false;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowHeight = 7;
  const arrowGap = 3;
  const padding = 5;
  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(arrowHeight + arrowGap),
      flip({ padding }),
      shift({ padding }),
      arrow({ element: arrowRef }),
      size({
        padding,
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.min(availableWidth, 500)}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, { move: false, enabled, handleClose: handleClose(), delay });
  const focus = useFocus(context, { enabled });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      arrowRef,
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}
