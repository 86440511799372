import { v4 as uuid } from 'uuid';
import { ToastOptions } from 'react-toastify';

import { externalStorage } from 'shared/ExternalStorage';
import { CustomToastType, ToastTypeEnum } from 'utils';
import { getErrorMessage } from 'api';

const showToast = (toast: CustomToastType) => {
  const { type, content } = toast;

  externalStorage.addToast({
    ...toast,
    id: uuid(),
    content: type === ToastTypeEnum.ERROR && content ? getErrorMessage(content) : content,
  });
  window.dispatchEvent(new Event('storage'));
};

export const toast = {
  success: (content: unknown, options?: ToastOptions) => showToast({ type: ToastTypeEnum.SUCCESS, content, options }),
  error: (content: unknown, options?: ToastOptions) => showToast({ type: ToastTypeEnum.ERROR, content, options }),
  warning: (content: unknown, options?: ToastOptions) => showToast({ type: ToastTypeEnum.WARNING, content, options }),
  info: (content: unknown, options?: ToastOptions) => showToast({ type: ToastTypeEnum.INFO, content, options }),
};
