import { handlerApi } from 'api';

import { SessionType } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  table: string;
  datasource: string;
  dataset: string;
  session: SessionType;
  activeChat?: string;
};

export const switchChatSessionRequest = ({ key, table, datasource, dataset, session, activeChat }: RequestParamsType) =>
  handlerApi.run<SessionType>({
    key,
    table,
    dataset,
    method: 'SwitchChatSession',
    dataSource: datasource,
    args: { CurrentChat: session.ChatKey, ActiveChat: activeChat },
  });
