import { Dispatch, SetStateAction } from 'react';

import { nextSiblingStyling } from './nextSiblingStyling';
import { prevSiblingStyling } from './prevSiblingStyling';
import { animationClearance } from './animationClearance';

export const leftSideStiling = (
  closestTabWrapper: HTMLElement,
  closesTabWrapperIndex: number,
  draggingTab: HTMLDivElement,
  dropZone: HTMLElement,
  tabsZone: HTMLElement | null,
  startTabIndex: number,
  endTabIndex: number,
  setEndTabIndex: Dispatch<SetStateAction<number>>,
  dragOutOfTabsZone: boolean,
  setDragOutOfTabsZone: Dispatch<SetStateAction<boolean>>
) => {
  const closestTabWrapperCopy = closestTabWrapper;

  closestTabWrapperCopy.style.pointerEvents = 'none';
  closestTabWrapperCopy.style.transform = `translate(${draggingTab.offsetWidth}px, ${0}px)`;

  nextSiblingStyling(closestTabWrapper, draggingTab);
  prevSiblingStyling(closestTabWrapper, draggingTab);

  animationClearance(
    closestTabWrapper,
    closesTabWrapperIndex,
    dropZone,
    tabsZone,
    startTabIndex,
    endTabIndex,
    setEndTabIndex,
    dragOutOfTabsZone,
    setDragOutOfTabsZone
  );

  if (endTabIndex === closesTabWrapperIndex && dropZone === tabsZone) {
    setEndTabIndex(startTabIndex);
  }
};
