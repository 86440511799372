import { ComponentPropsWithRef, ElementType, FC } from 'react';

import { Icon } from 'shared/Icon';
import { UITooltip } from 'ui';

type IconWithTooltipProps = {
  tooltip?: string;
  SvgIcon: ElementType<ComponentPropsWithRef<'svg'>>;
  onClick?: () => Promise<void> | void;
};

export const IconWithTooltip: FC<IconWithTooltipProps> = ({ onClick, SvgIcon, tooltip }) => (
  <UITooltip title={tooltip}>
    <div aria-hidden="true" onClick={onClick} className="d-flex align-items-center">
      <Icon SvgIcon={SvgIcon} />
    </div>
  </UITooltip>
);
