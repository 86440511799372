import { useMemo } from 'react';
import { CellProps } from 'react-table';
import cx from 'classnames';

import { DataType } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { expandedCellPadding } from './utils/helpers';

export const Cell = ({ row, column }: CellProps<DataType>) => {
  const dotsCount = row.id ? Array.from(row.id).filter((item) => item === '.').length : null;

  const formattedValue = useMemo(
    () => (column.valueFormatter ? column.valueFormatter(row.values.expander) : (row.values.expander as string)),
    [column, row.values.expander]
  );

  return row.canExpand ? (
    <span
      {...row.getToggleRowExpandedProps()}
      className={cx(`d-flex align-items-center`, { [`ps-${expandedCellPadding(dotsCount!)}`]: dotsCount })}
    >
      <Icon clickable={false} SvgIcon={row.isExpanded ? APP_ICONS.arrowDown : APP_ICONS.arrowRight} />

      <span className="mx-2">{formattedValue}</span>
    </span>
  ) : (
    <span>{formattedValue}</span>
  );
};
