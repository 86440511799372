import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { StorageEntityModel } from 'api';
import { UiEmpty } from 'ui';
import { EditorInstance } from 'view/EditorData/EditorInstance';
import { EditorSubmitType, EditorProps } from 'view/Editor/type';

type EditorViewerWithParamsProps = {
  isReadOnly?: boolean;
  partSchemaName: string;
  recordType: string;
  editorData?: StorageEntityModel;
  onSubmit?: (data: StorageEntityModel) => void;
};

export const EditorViewerWithParams: FC<EditorViewerWithParamsProps> = ({
  editorData,
  partSchemaName,
  isReadOnly,
  recordType,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { getSchema } = useEditorRequest();
  const [editorProps, setEditorProps] = useState<Pick<EditorProps, 'data' | 'schema' | 'rootSchemaName'> | null>(null);

  useEffect(() => {
    if (recordType && partSchemaName) {
      getSchema
        .fetch({
          name: recordType,
        })
        .then((schema) => {
          setEditorProps({
            data: editorData,
            schema: schema.typeList,
            rootSchemaName: Object.keys(schema.typeList)?.find((it) => it.endsWith(partSchemaName)) || '',
          });
        });
    }
  }, [recordType, partSchemaName, editorData]);

  const handleInit = ({ data }: EditorSubmitType) => !editorData && onSubmit?.(data);

  const handleSubmit = ({ data }: EditorSubmitType) => onSubmit?.(data);

  if (getSchema.isLoading) return <Loader />;

  if (!editorProps) return <UiEmpty />;

  return (
    <EditorInstance
      {...editorProps}
      readOnly={isReadOnly}
      i18n={{
        submitBtnText: t('buttons.apply'),
      }}
      onInit={handleInit}
      onSubmit={handleSubmit}
    />
  );
};
