import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC, ReactNode } from 'react';

type UiLocalizationProviderProps = {
  children: ReactNode;
};

export const UILocalizationProvider: FC<UiLocalizationProviderProps> = ({ children }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: 'MMM YYYY' }}>
    {children}
  </LocalizationProvider>
);
