import { handlerApi } from 'api';
import { isNumber } from 'utils';

import { FileStatusType, FileType } from '../../../utils/types';

export type RequestParamsType = {
  key: string;
  dataset: string;
  table: string;
  datasource: string;
  chatKey: string;
};

type ResponseFileType = {
  _incomplete: boolean;
  FileNameWithExt: string;
  Status?: string;
  CloDate?: number;
  CloExclusionsCount?: number;
  CloRegion?: string;
  Created?: number;
};

const mapResponseFile = (responseFile: ResponseFileType): FileType => {
  const status = FileStatusType.NONE;
  return {
    isSaved: true,
    filename: responseFile.FileNameWithExt,
    savedStatus: status,
    status,
    hasExclusions: isNumber(responseFile.CloExclusionsCount) ? responseFile.CloExclusionsCount > 0 : false,
  };
};

export const loadChatFilesRequest = ({ key, dataset, table, datasource, chatKey }: RequestParamsType) =>
  handlerApi
    .run<Array<ResponseFileType>>({
      key,
      table,
      dataset,
      method: 'LoadChatFiles',
      dataSource: datasource,
      args: { CurrentChat: chatKey },
    })
    .then((response) => response.map(mapResponseFile));
