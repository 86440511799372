import { FC } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { CloExclusionsStateType } from '../../../../../../utils/types';

type CloExclusionsButtonProps = {
  cloExclusionsState: CloExclusionsStateType;
  isLoading?: boolean;
  onClick?: () => void;
};

export const CloExclusionsButton: FC<CloExclusionsButtonProps> = ({ cloExclusionsState, isLoading, onClick }) => {
  const { t } = useTranslation();
  const isDisabled = isLoading || cloExclusionsState.isDisabled;

  if (cloExclusionsState.isHiddenState) return null;

  return (
    <Tooltip>
      <TooltipTrigger>
        <Button
          className="d-flex align-items-center p-0 bg-transparent border-0"
          disabled={isDisabled}
          onClick={onClick}
        >
          <Icon SvgIcon={APP_ICONS.snowflake} />
        </Button>
      </TooltipTrigger>
      <TooltipContent>{t('chat.cloExclusions.tooltip')}</TooltipContent>
    </Tooltip>
  );
};
