import { FC, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import './UITooltip.component.styles.scss';

export type UITooltipProps = Pick<TooltipProps, 'placement' | 'children' | 'title'> & {
  isDisable?: boolean;
  title?: string | ReactNode;
};

export const UITooltip: FC<UITooltipProps> = ({ title, placement = 'top', isDisable = false, children }) => {
  if (isDisable) return <>{children}</>;

  return (
    <Tooltip title={<>{title}</>} placement={placement} classes={{ popper: 'ui-tooltip' }}>
      {children}
    </Tooltip>
  );
};
