import { createContext } from 'react';

import { OptionType } from 'ui';
import { SchemaTypeElementsModel } from 'api';

import { QueryType, ValueWithConditionType } from '../types';

export type QueryBuilderContextType = {
  validQuery: boolean;
  query: QueryType;
  schemaDictionary: Record<string, SchemaTypeElementsModel>;
  selectedIndex: string | undefined;
  indexes: OptionType[];
  isShowIntroductionSection: boolean;
  updateValueByKey: (key: string, values: ValueWithConditionType) => void;
  reset: () => void;
  selectIndex: (selectedIndex: string | undefined) => void;
};

export const QueryBuilderContext = createContext<QueryBuilderContextType>({} as QueryBuilderContextType);
