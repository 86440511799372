import { useCallback, useMemo, useReducer, useState } from 'react';

import { PdfMatchType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';

export function usePDFMatches() {
  const [matchedTerms, setMatchedTerms] = useState<PdfMatchType[]>([]);
  const [currentMatchedIndex, setCurrentMatchedIndex] = useState(0);

  /** @description This state helps to update the reference for the `currentMatchedTerm` variable, which is used as a dependency in `useEffect` */
  const [isForceUpdatedCurrentMatchedTerm, forceUpdateCurrentMatchedTerm] = useReducer((x: number) => x + 1, 0);

  const matchedSize = matchedTerms.length;

  const currentMatchedTerm: PdfMatchType | undefined = useMemo(() => {
    const matchedTerm = matchedTerms[currentMatchedIndex - 1];
    /** @description if `matchedTerms` or `currentMatchedIndex` don't change it's important to make a copy of this object to force calling in the subscription */
    return matchedTerm ? { ...matchedTerm } : undefined;
  }, [matchedTerms, currentMatchedIndex, isForceUpdatedCurrentMatchedTerm]);

  const update = (matches: PdfMatchType[], initialMatchedIndex?: number) => {
    const matchedIndex = initialMatchedIndex ?? (matches.length === 0 ? 0 : 1);
    setMatchedTerms(matches);
    setCurrentMatchedIndex(matchedIndex);
  };

  const reset = useCallback(() => {
    setMatchedTerms([]);
    setCurrentMatchedIndex(0);
  }, []);

  const previous = useCallback(
    (isLoop = true) => {
      setCurrentMatchedIndex((prev) => {
        if (matchedSize === 0 || prev === 0) return prev;

        const isFirst = prev === 1;
        if (isLoop) return isFirst ? matchedSize : prev - 1;

        return isFirst ? prev : prev - 1;
      });
      forceUpdateCurrentMatchedTerm();
    },
    [matchedSize]
  );

  const next = useCallback(
    (isLoop = true) => {
      setCurrentMatchedIndex((prev) => {
        if (matchedSize === 0 || prev === 0) return prev;

        const isMax = prev === matchedSize;
        if (isLoop) return isMax ? 1 : prev + 1;

        return isMax ? prev : prev + 1;
      });
      forceUpdateCurrentMatchedTerm();
    },
    [matchedTerms.length]
  );

  return {
    currentMatchedTerm,
    currentMatchedIndex,
    matchedSize,
    previous,
    next,
    reset,
    update,
    forceUpdateCurrentMatchedTerm,
  };
}
