import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { handlerApi } from 'api';

export type RequestParamsType = {
  key: string;
  table: string;
  datasource: string;
  dataset: string;
};

export const getActiveChatSessionRequest = ({ key, table, datasource, dataset }: RequestParamsType) =>
  handlerApi.run<SessionType | null>({
    key,
    table,
    dataset,
    method: 'GetActiveChatSession',
    dataSource: datasource,
  });
