import { forwardRef, ReactNode, SyntheticEvent, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import './UiModalResizableWrapper.scss';

export type UiModalResizableWrapperProps = {
  defaultHeight?: number;
  defaultWidth?: number;
  minWidthConstraints?: number;
  minHeightConstraints?: number;
  children?: ReactNode;
};

export const UiModalResizableWrapper = forwardRef<HTMLDivElement, UiModalResizableWrapperProps>(
  ({ children, defaultHeight, defaultWidth, minWidthConstraints, minHeightConstraints }, ref) => {
    const [width, setWidth] = useState(defaultWidth);
    const [height, setHeight] = useState(defaultHeight);
    const [maxModalConstraints, setMaxModalConstraints] = useState<[width: number, height: number]>([
      window.innerWidth,
      window.innerHeight,
    ]);

    const handleModalResize = (_: SyntheticEvent<Element, Event>, resizeData: ResizeCallbackData) => {
      setWidth(resizeData.size.width);
      setHeight(resizeData.size.height);
    };

    const handleModalConstraints = () => {
      if (typeof ref === 'function' || !ref?.current) return;
      const modalContentRect = ref.current.getBoundingClientRect();
      const maxModalWidth = window.innerWidth - modalContentRect.right + modalContentRect.width;
      const maxModalHeight = window.innerHeight - modalContentRect.bottom + modalContentRect.height;
      setMaxModalConstraints([maxModalWidth, maxModalHeight]);
    };

    return (
      <Resizable
        className="box"
        height={height!}
        width={width!}
        resizeHandles={['se']}
        minConstraints={[minWidthConstraints!, minHeightConstraints!]}
        maxConstraints={maxModalConstraints}
        onResizeStart={handleModalConstraints}
        onResize={handleModalResize}
      >
        <div
          className="ui-modal-resizable-wrapper"
          ref={ref}
          style={{
            height: `${height}px`,
            width: `${width}px`,
          }}
        >
          {children}
        </div>
      </Resizable>
    );
  }
);

UiModalResizableWrapper.displayName = 'UiModalResizableWrapper';
