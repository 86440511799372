import { getBaseUrl } from '../helpers/generateBaseUrl';

export type SSEApi = {
  subscribe(): string;
};

export const sseApiUrls: Record<keyof SSEApi, string> = {
  subscribe: '/sse/subscribe',
};

export const sseApi: SSEApi = {
  subscribe(): string {
    return `${getBaseUrl()}${sseApiUrls.subscribe}`;
  },
};
