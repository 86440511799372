export const сalculateEndIndex = (
  startTabIndex: number,
  dragOutOfTabsZone: boolean,
  dropZone: HTMLElement,
  tabsZone: HTMLElement | null,
  closesTabWrapperIndex: number,
  endTabIndex: number
) => {
  let newEndIndex = startTabIndex;

  const isMovedOutBackToSameStackHoverOntoNewTabRight =
    dragOutOfTabsZone && dropZone === tabsZone && closesTabWrapperIndex > startTabIndex;
  const isNotMovedOutReturnedToOriginFromNextRightTab =
    !dragOutOfTabsZone && dropZone === tabsZone && endTabIndex === closesTabWrapperIndex;
  const isNotMovedOutReturnedToOriginFromRightTabs =
    !dragOutOfTabsZone && dropZone === tabsZone && startTabIndex === closesTabWrapperIndex - 1;
  const isNotMovedOutHoverOntoAnotherTab =
    !dragOutOfTabsZone && dropZone === tabsZone && endTabIndex !== closesTabWrapperIndex;
  const isMovedOutBackToSameStackHoverOntoPrevTabLeft =
    dragOutOfTabsZone && dropZone === tabsZone && closesTabWrapperIndex < startTabIndex;
  const isMovedOutToAnotherStack = dragOutOfTabsZone && dropZone !== tabsZone;
  const isNotMovedOutToAnotherStack = !dragOutOfTabsZone && dropZone !== tabsZone;
  const isNotMovedOutReturnedToOrigin = !dragOutOfTabsZone && endTabIndex === closesTabWrapperIndex;

  if (
    isMovedOutBackToSameStackHoverOntoNewTabRight ||
    isNotMovedOutReturnedToOriginFromNextRightTab ||
    isNotMovedOutReturnedToOriginFromRightTabs
  ) {
    newEndIndex = closesTabWrapperIndex - 1;
  } else if (
    isNotMovedOutHoverOntoAnotherTab ||
    isMovedOutBackToSameStackHoverOntoPrevTabLeft ||
    isMovedOutToAnotherStack ||
    isNotMovedOutToAnotherStack
  ) {
    newEndIndex = closesTabWrapperIndex;
  } else if (isNotMovedOutReturnedToOrigin) {
    newEndIndex = startTabIndex;
  }

  if (newEndIndex < 0) {
    newEndIndex = 0;
  }

  return newEndIndex;
};
