import { FC, useMemo } from 'react';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { ParseGenericValue } from 'view/Editor/helpers/parseGenericValue.helper';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

type TransitionIconProps = {
  data: string;
};

export const TransitionIcon: FC<TransitionIconProps> = ({ data }) => {
  const { event } = useEditorState();
  const { type, key } = useMemo(
    () =>
      // TODO Remove ParseGenericValue after refactor back response
      ({
        type: ParseGenericValue.toDomainType(data),
        key: ParseGenericValue.toDomainRecord(data),
      }),
    [data]
  );

  const handleClick = () => {
    event?.onTransition?.({
      type,
      key,
    });
  };

  if (!type || !key || !event?.onTransition) return null;

  // TODO Refactor Icon component
  return <Icon SvgIcon={APP_ICONS.goToObject} onClick={handleClick} />;
};
