import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import './UiModalHeader.scss';

export type UiModalHeaderProps = {
  title?: ReactNode;
  isDraggable?: boolean;
  className?: string;
  isClosable?: boolean;
  onClose?: () => void;
};

export const UiModalHeader: FC<UiModalHeaderProps> = ({
  title,
  isClosable = true,
  isDraggable,
  onClose,
  className,
}) => {
  const classes = cx(
    'ui-modal-header bg-ui-modal-header text-ui-modal-header-text border-ui-modal-header-border',
    className,
    {
      'ui-modal-header__draggable': isDraggable,
    }
  );

  return (
    <div className={classes}>
      <div className="fs-14">{title}</div>
      {isClosable && (
        <Icon
          className="ui-modal-header__close"
          onClick={onClose}
          SvgIcon={APP_ICONS.biggerClose}
          clickable={false}
          color="text-ui-modal-header-text"
        />
      )}
    </div>
  );
};
