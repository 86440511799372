import { FC, useId, useMemo } from 'react';

import { LoadingStatusesEnum, SchemaTypesItemModel } from 'api';
import { Grid } from 'view/Grid';
import { mapMetadataToGridColumnsHelper } from 'view/Grid/utils/helpers';
import { GetRowIdType } from 'view/Grid/utils/types';

type GenericTypesTableProps = {
  genericTypes: SchemaTypesItemModel[] | null;
  isLoading?: boolean;
  selectedGenericType?: string;
  onSelectGenericType: (type: string) => void;
};

export const GenericTypesTable: FC<GenericTypesTableProps> = ({
  isLoading = false,
  genericTypes,
  selectedGenericType,
  onSelectGenericType,
}) => {
  const typesGridId = useId();

  const typesData = useMemo(
    () =>
      genericTypes?.map(({ Label, Name }) => ({
        Types: Label || 'Unknown', // ToDo: At least one type doen't have Label
        _t: Name,
      })),
    [genericTypes]
  );

  const typesColumns = useMemo(
    () => mapMetadataToGridColumnsHelper([{ Name: 'Types', Value: { Type: 'String' } }]),
    []
  );

  const getRowId: GetRowIdType = (row) => row._t as string;

  return (
    <Grid
      status={isLoading ? LoadingStatusesEnum.LOADING : LoadingStatusesEnum.SUCCESS}
      variant="secondary"
      gridId={typesGridId}
      columns={typesColumns}
      data={typesData ?? []}
      selectedRowId={selectedGenericType}
      onChangeSelectedRowId={onSelectGenericType}
      getRowId={getRowId}
      isColumnFilter={false}
    />
  );
};
