import { Dispatch, SetStateAction } from 'react';

import { сalculateEndIndex } from './calculateEndIndex';

export const animationClearance = (
  closestTabWrapperCopy: HTMLElement,
  closesTabWrapperIndex: number,
  dropZone: HTMLElement,
  tabsZone: HTMLElement | null,
  startTabIndex: number,
  endTabIndex: number,
  setEndTabIndex: Dispatch<SetStateAction<number>>,
  dragOutOfTabsZone: boolean,
  setDragOutOfTabsZone: Dispatch<SetStateAction<boolean>>
) => {
  const newEndIndex = сalculateEndIndex(
    startTabIndex,
    dragOutOfTabsZone,
    dropZone,
    tabsZone,
    closesTabWrapperIndex,
    endTabIndex
  );

  setDragOutOfTabsZone(false);

  const postAnimationClearance = () => {
    setEndTabIndex(() => newEndIndex);

    closestTabWrapperCopy.style.removeProperty('pointer-events');
    closestTabWrapperCopy.removeEventListener('transitionend', postAnimationClearance);
  };

  closestTabWrapperCopy.addEventListener('transitionend', postAnimationClearance);
};
