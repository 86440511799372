import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { logout } from 'store/shared-reducers/AppStateSlice';
import { useAppNavigate } from 'router/hooks';

export const LogoutCallbackPage: FC = () => {
  const { navigateToLogin } = useAppNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    navigateToLogin();
  }, []);

  return <AppFallbackSkeleton />;
};
