import { FC } from 'react';

import { EntityPanelViewOfModel } from 'api';
import { TransitionRecordType } from 'view/EditorContent/utils/types';

import { InstanceListViewer, ElementListViewer } from './components';
import { checkSimpleTypesHelper } from './utils/helpers';

type ListContentContainerProps = {
  viewerKey?: string;
  viewerType?: string;
  data: EntityPanelViewOfModel[];
  onTransition?: (data: TransitionRecordType) => void;
};

export const ListContentContainer: FC<ListContentContainerProps> = ({ viewerKey, viewerType, data, onTransition }) =>
  checkSimpleTypesHelper(data) ? (
    <InstanceListViewer data={data} />
  ) : (
    <ElementListViewer viewerKey={viewerKey} viewerType={viewerType} data={data} onTransition={onTransition} />
  );
