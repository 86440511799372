import { Fragment, ReactElement } from 'react';
import { Header, HeaderGroup } from '@tanstack/react-table';

import './UIGridHeadRow.style.scss';

type UiGridHeadRowProps<TData> = {
  headerGroup: HeaderGroup<TData>;
  renderCell: <TValue>(headerCell: Header<TData, TValue>) => ReactElement;
};

export const UiGridHeadRow = <TData,>({ headerGroup, renderCell }: UiGridHeadRowProps<TData>) => (
  <div className="ui-grid-header-row" key={headerGroup.id}>
    {headerGroup.headers.map((header) => (
      <Fragment key={header.id}>{renderCell(header)}</Fragment>
    ))}
  </div>
);
