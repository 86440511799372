import { FC, Fragment, memo } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { DagActions, DagContent, DagProvider, DagToolbar } from './components';

type DagProps = {
  isReadonly?: boolean;
  type: string;
  selectedRowKey: string;
};

export const Dag: FC<DagProps> = memo(({ type, selectedRowKey, isReadonly = false }) => (
  <DagProvider type={type} pointer={selectedRowKey} isReadonly={isReadonly}>
    <div className="d-flex w-100 h-100">
      <PanelGroup direction="horizontal">
        <Panel id="flow-viewer-content" order={1} defaultSize={60}>
          <div className="d-flex flex-column h-100 w-100">
            {!isReadonly && <DagToolbar />}
            <DagContent className="flex-grow-1" />
          </div>
        </Panel>
        {!isReadonly && (
          <Fragment>
            <PanelResizeHandle className="verticalPanelResizer" />
            <Panel id="flow-viewer-actions" order={1} minSize={0} maxSize={40}>
              <DagActions selectedRowKey={selectedRowKey} />
            </Panel>
          </Fragment>
        )}
      </PanelGroup>
    </div>
  </DagProvider>
));

Dag.displayName = 'Dag';
