import { FC } from 'react';

import { CodeEditor } from 'shared/CodeEditor';
import { LanguageEnum } from 'api';

import { parseValueFromCode } from 'view/Editor/helpers/parseValue.helper';

import styles from './ValueEditorViewString.component.module.scss';

type ValueEditorViewStringProps = {
  isReadonly?: boolean;
  value: string;
  onChange: (value: string) => void;
};

export const ValueEditorViewString: FC<ValueEditorViewStringProps> = ({ value, isReadonly, onChange }) => {
  const handleChangeValue = (code: string) => {
    onChange(parseValueFromCode(code));
  };

  return (
    <div className={styles.valueEditorViewString}>
      <CodeEditor
        code={value}
        isReadonly={isReadonly}
        onChange={handleChangeValue}
        language={LanguageEnum.Plaintext}
        wordWrap={true}
      />
    </div>
  );
};
