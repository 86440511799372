import { useSelector } from 'react-redux';
import { FC, useEffect } from 'react';

import { KindTypeModel } from 'api';
import { EditorSubmitType } from 'view/Editor/type';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { useAppContext } from 'context';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { UiEmpty } from 'ui';

import { EditorInstance } from 'view/EditorData/EditorInstance';

import { EditorUpdateType } from './type';

type EditorNewEntityProps = {
  recordType: string;
  recordKey?: string;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const EditorNewEntity: FC<EditorNewEntityProps> = ({ recordType, recordKey, onUpdate }) => {
  const gridSchema = useSelector(selectGridSchema);
  const { state, action } = useAppContext();
  const { getSchema, saveEntity } = useEditorRequest();

  useEffect(() => {
    if (recordType) void getSchema.fetch({ name: recordType });
  }, [recordType]);

  const handleSubmit = ({ data }: EditorSubmitType) => {
    saveEntity.fetch({ data, recordType }).then((result) => {
      onUpdate?.({
        oldKey: recordKey,
        newKey: result.key,
      });
      action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);
      if (state.fullScreenLayerId) {
        action.setFullScreenLayerIdAction(`stack-${EditorTabsEnum.EDITOR}`);
      }
    });
  };

  const handleCancelEditorData = () => {
    action.setActiveEditorTabAction(
      gridSchema.data.Kind === KindTypeModel.ABSTRACT ? EditorTabsEnum.SELECT_TYPE : EditorTabsEnum.EDITOR
    );
    action.setFullScreenLayerIdAction('');
  };

  if (getSchema.isLoading) return <Loader />;

  if (!getSchema.state) return <UiEmpty />;

  return (
    <EditorInstance
      schema={getSchema.state.typeList}
      rootSchemaName={getSchema.state.rootType}
      onCancel={handleCancelEditorData}
      onSubmit={handleSubmit}
    />
  );
};
