import { useEffect, useRef, useState } from 'react';

import { LoadingStatusesEnum } from 'api';

export type RequestHookProps<ResponseModel, RequestDto> = {
  autorun?: boolean;
  args?: RequestDto;
  initState?: ResponseModel | undefined;
  onSuccess?: (data: ResponseModel) => void;
  onError?: (error: unknown) => void;
};

export type RequestHookFetch<ResponseModel, RequestDto = undefined> = RequestDto extends undefined
  ? () => Promise<ResponseModel>
  : (data: RequestDto) => Promise<ResponseModel>;

export type RequestHookReturnType<ResponseModel, RequestDto, RequestError = unknown> = {
  fetch: RequestHookFetch<ResponseModel, RequestDto>;
  isLoading: boolean;
  status: LoadingStatusesEnum;
  hasInitFetch: boolean;
  hasError: boolean;
  error: RequestError | null;
  state: ResponseModel | null;
};

export const useRequest = <ResponseModel, RequestDto = undefined>(
  request: (data: RequestDto) => Promise<ResponseModel>,
  props: RequestHookProps<ResponseModel, RequestDto> = {}
): RequestHookReturnType<ResponseModel, RequestDto> => {
  const mountedRef = useRef(true);
  const [status, setStatus] = useState<LoadingStatusesEnum>(LoadingStatusesEnum.INITIAL);
  const [hasInitFetch, setHasInitFetch] = useState(false);
  const [state, setState] = useState<ResponseModel | null>(props.initState ?? null);
  const [error, setError] = useState<unknown>(null);

  const initRequest = async (data?: RequestDto): Promise<ResponseModel> => {
    try {
      setStatus(LoadingStatusesEnum.LOADING);
      setError(null);
      const response = await request(data as RequestDto);

      if (mountedRef.current) {
        setHasInitFetch(true);
        setState(response);
        setStatus(LoadingStatusesEnum.SUCCESS);
        props.onSuccess?.(response);
      }

      return response;
    } catch (e: unknown) {
      props.onError?.(e);
      setError(e);
      setStatus(LoadingStatusesEnum.ERROR);
      throw e;
    }
  };

  useEffect(() => {
    if (props.autorun) {
      initRequest(props?.args as RequestDto);
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    fetch: initRequest as RequestHookFetch<ResponseModel, RequestDto>,
    isLoading: status === LoadingStatusesEnum.LOADING,
    status,
    state,
    hasInitFetch,
    error,
    hasError: !!error,
  };
};
