import { UiModalMethod } from './UiModalUtil/config';
import { UiModal as InternalUiModal } from './UiModal';
import { UiModalBody } from './UiModalBody';
import { UiModalFooter } from './UiModalFooter';

export * from './UiModal';
export * from './UiModalUtil/UiModalContainer';

type InternalUiModalType = typeof InternalUiModal;

type CompoundedUiModal = InternalUiModalType &
  typeof UiModalMethod & {
    Body: typeof UiModalBody;
    Footer: typeof UiModalFooter;
  };

export const UiModal: CompoundedUiModal = InternalUiModal as CompoundedUiModal;

UiModal.Body = UiModalBody;
UiModal.Footer = UiModalFooter;
UiModal.delete = UiModalMethod.delete;
UiModal.info = UiModalMethod.info;
UiModal.warning = UiModalMethod.warning;
UiModal.error = UiModalMethod.error;
UiModal.confirm = UiModalMethod.confirm;
