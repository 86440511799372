import { AxiosError, AxiosResponse } from 'axios';

import { deserialize, isObject, isString, serialize } from 'utils';

import { ErrorResponseDto } from '../type';

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    return parseAxiosErrorMessage(error as AxiosError);
  }

  if (isString(error)) return error;

  return 'Unexpected error occurred!';
};

export const parseAxiosErrorMessage = (error: AxiosError): string => {
  const { data } = (error.response as AxiosResponse<string>) ?? {};
  try {
    if (data) {
      const { Message: errorResponse } = deserialize<ErrorResponseDto>(data);
      return isObject(errorResponse) ? serialize(errorResponse) : errorResponse;
    }

    return error.message;
  } catch (_) {
    return error.message;
  }
};
