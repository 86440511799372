import { FC, useCallback, useState } from 'react';

import { GridTabDataType, ViewerMetadataType } from 'store/types';
import { Tabs } from 'shared/Tabs';
import { Loader } from 'shared/Loader';
import { Viewers } from 'view/Viewers';
import { EntityPanelModel } from 'api';

import { ViewersNavigatorContext, ViewersNavigatorContextType } from './contexts';
import { ActionType, NavigateToOptions } from './types';

type ViewersNavigatorProps = {
  currentKey?: string;
  type?: string;
  activeTab: string | undefined;
  tabs: GridTabDataType[];
  switchTab: (tab: GridTabDataType) => void;
  updateOrderTabs: (tabs: GridTabDataType[]) => void;
  isLoading: boolean;
  activeViewerType: string | undefined;
  viewerData: ViewerMetadataType;
};

export const ViewersNavigator: FC<ViewersNavigatorProps> = ({
  currentKey,
  type,
  activeViewerType,
  viewerData,
  isLoading,
  activeTab,
  tabs,
  updateOrderTabs,
  switchTab,
}) => {
  const [actionStack, setActionStack] = useState<ActionType[]>([]);

  const addActionToStack = useCallback((action: ActionType) => {
    setActionStack((prevActionStack) => [...prevActionStack, action]);
  }, []);

  const removeActionFromStack = useCallback((action: ActionType) => {
    setActionStack((prevActionStack) =>
      prevActionStack.filter(
        (prevAction) => prevAction.action !== action.action && prevAction.params.page !== action.params.page
      )
    );
  }, []);

  const navigateTo = useCallback(
    (options: NavigateToOptions) => {
      const tabById = tabs.find((tab) => tab.id === options.panel);
      if (!tabById) return;

      switchTab(tabById);
      addActionToStack({ action: options.action, params: options.params });
    },
    [tabs]
  );

  const context: ViewersNavigatorContextType = {
    actionStack,
    navigateTo,
    addActionToStack,
    removeActionFromStack,
  };
  return (
    <ViewersNavigatorContext.Provider value={context}>
      <Tabs
        shouldTranslate
        openedTabs={tabs}
        activeTab={activeTab}
        onChangeTab={switchTab}
        onSetTabs={updateOrderTabs}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Viewers
          panelKey={currentKey}
          panelType={type}
          key={activeTab}
          activeViewerType={activeViewerType}
          data={viewerData.ViewOf as EntityPanelModel['ViewOf']}
        />
      )}
    </ViewersNavigatorContext.Provider>
  );
};
