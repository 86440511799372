import { Main } from 'containers/Main';
import { NoGridData } from 'containers/TabItemContent/components/NoGridData';
import { TabsInner } from 'shared/Tabs';
import { TabItem } from 'shared/Tabs/components/TabItem';
import { EditorContentInner } from 'view/EditorContent';
import { Header } from 'view/Header';
import { TypeToolbaInner } from 'view/TypeToolbar';

export const coreComponentsDictionary = {
  Header,
  Main,
  TabItem,
  TabsInner,
  EditorContentInner,
  TypeToolbaInner,
  InitialScreen: NoGridData,
};
