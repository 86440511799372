import { Dropdown, DropdownToggle, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { UiDropdownItem, UiDropdownMenu } from 'ui';
import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { LogsLevelEnum } from 'view/Logs/utils/types';
import { externalStorage } from 'shared/ExternalStorage';

import { initLogsFilters, logsFiltersListItems } from './utils/constants';
import styles from './styles.module.scss';

type LogsHeaderFilterProps = {
  onSetActiveFilters: (levels: LogsLevelEnum[]) => void;
  activeFilters: LogsLevelEnum[];
};

export const LogsHeaderFilter = ({ onSetActiveFilters, activeFilters }: LogsHeaderFilterProps) => {
  const { t } = useTranslation();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const areAllFiltersActive = activeFilters.length === initLogsFilters.length;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [areAllFiltersChecked, setAreAllFiltersChecked] = useState(areAllFiltersActive);

  useEffect(() => {
    if (isFilterOpen && activeFilters.length && !areAllFiltersActive) {
      checkboxRef.current!.indeterminate = true;
    }
  }, [isFilterOpen]);

  useEffect(() => {
    switch (true) {
      case areAllFiltersActive:
        checkboxRef.current!.indeterminate = false;
        setAreAllFiltersChecked(true);
        break;
      case !activeFilters.length:
        checkboxRef.current!.indeterminate = false;
        setAreAllFiltersChecked(false);
        break;
      default:
        checkboxRef.current!.indeterminate = true;

        setAreAllFiltersChecked(true);
    }
  }, [activeFilters]);

  const isFilterSelected = (filterValue: LogsLevelEnum) => activeFilters.includes(filterValue);

  const changeActiveFilters = (filters: LogsLevelEnum[]) => {
    setIsFilterOpen(false);
    onSetActiveFilters(filters);
    externalStorage.setLogsFilters(filters);
  };

  const handleCheckAllFilters = () => changeActiveFilters(areAllFiltersChecked ? [] : initLogsFilters);

  const handleRunFilter = (filterValue: LogsLevelEnum) => () => {
    const newActiveFilters = activeFilters.includes(filterValue)
      ? activeFilters.filter((item) => item !== filterValue)
      : [...activeFilters, filterValue];
    changeActiveFilters(newActiveFilters);
  };

  return (
    <Dropdown className="d-flex h-100 w-100" isOpen={isFilterOpen} toggle={() => setIsFilterOpen((prev) => !prev)}>
      <DropdownToggle className="d-flex flex-grow-1" tag="div">
        <Icon SvgIcon={APP_ICONS.filter} className={cx({ [styles.filterIcon]: !areAllFiltersActive })} />
      </DropdownToggle>
      <UiDropdownMenu>
        <UiDropdownItem onClick={handleCheckAllFilters}>
          <Input innerRef={checkboxRef} readOnly type="checkbox" className="mt-0 me-2" checked={areAllFiltersChecked} />
          {t('logsFilter.all')}
        </UiDropdownItem>
        {logsFiltersListItems.map((item) => (
          <UiDropdownItem key={item.id} onClick={handleRunFilter(item.value)}>
            <Input type="checkbox" readOnly className="mt-0 me-2" checked={isFilterSelected(item.value)} />
            {t(item.title)}
          </UiDropdownItem>
        ))}
      </UiDropdownMenu>
    </Dropdown>
  );
};
