import { FC } from 'react';

import { isDefined } from 'utils';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import './EditorBreadcrumbs.scss';
import { EditorBreadcrumbItem } from 'view/Editor/components/EditorBreadcrumbs/EditorBreadcrumbItem';

export const EditorBreadcrumbs: FC = () => {
  const { state, action } = useEditorState();
  const { activeTabIndex, tabs } = state.breadcrumbsData;

  return (
    <div className="editor-breadcrumbs">
      {tabs?.map(({ title, path, key, childIndex }, index) => (
        <EditorBreadcrumbItem
          key={key}
          path={path}
          label={isDefined(childIndex) ? `${title}[${childIndex + 1}]` : title}
          isActive={index === activeTabIndex}
          isCloseable={index > 0}
          onClose={() => action.closeTabAction(index)}
          onClick={() => action.switchTabAction(index)}
        />
      ))}
    </div>
  );
};
