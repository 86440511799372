import { FC } from 'react';
import cx from 'classnames';

import { QueryBuilderKeyCellProps } from '../../utils/types';
import styles from './QueryBuilderKeyCell.module.scss';

export const QueryBuilderKeyCell: FC<QueryBuilderKeyCellProps> = ({ key, isActive }) => {
  const backgroundCx = isActive ? 'bg-query-focused' : 'bg-query-option-background';
  return (
    <div className={cx('d-flex align-items-center py-1 px-2 text-truncate', styles.keyborder, backgroundCx)}>{key}</div>
  );
};
