import { forwardRef, HTMLProps } from 'react';
import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cx from 'classnames';

import { useTooltipContext } from '../../hooks/useTooltipContext';
import { FLOATING_CONTENT_CLASSNAME } from '../../utils/constants';

export const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, propRef) => {
  const { ...context } = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  const floatingProps = context.getFloatingProps({
    ...props,
    className: cx(props.className, 'floating-tooltip'),
  });

  if (!floatingProps.children) return null;

  return (
    <FloatingPortal>
      <div ref={ref} style={context.floatingStyles} {...floatingProps}>
        <div
          className={cx(
            'overflow-y-auto bg-tooltip-background border border-tooltip-outline text-tooltip-text',
            FLOATING_CONTENT_CLASSNAME
          )}
        >
          <>{floatingProps.children}</>
        </div>
      </div>
    </FloatingPortal>
  );
});

TooltipContent.displayName = 'TooltipContent';
