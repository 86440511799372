import { FC, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { Input } from 'reactstrap';
import cx from 'classnames';

type ChatHistoryCheckboxProps = {
  className?: string;
  indeterminate?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
};

export const ChatHistoryCheckbox: FC<ChatHistoryCheckboxProps> = ({ className, indeterminate, value, onChange }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (indeterminate) {
      ref.current.checked = false;
      ref.current.indeterminate = true;
    } else if (value) {
      ref.current.checked = true;
      ref.current.indeterminate = false;
    } else {
      ref.current.checked = false;
      ref.current.indeterminate = false;
    }
  }, [value, indeterminate]);

  const onClickHandler = useCallback((event: MouseEvent) => event.stopPropagation(), []);

  const onChangeHandler = useCallback(() => onChange(!value), [value, onChange]);

  return (
    <Input
      className={cx('m-0 c-pointer', className)}
      innerRef={ref}
      type="checkbox"
      onChange={onChangeHandler}
      onClick={onClickHandler}
    />
  );
};
