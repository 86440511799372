export const progressMessagesDataMock = [
  {
    Category: 0,
    Spans: [
      {
        DataSet: null,
        Text: 'RUNNING Share',
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:08.526Z',
      },
    ],
  },
  {
    Category: 0,
    Spans: [
      {
        DataSet: null,
        Text: 'The handler has been added into the queue.',
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:08.526Z',
      },
    ],
  },
  {
    Category: 0,
    Spans: [
      {
        DataSet: null,
        Text: 'The handler started executing.',
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:08.526Z',
      },
    ],
  },
  {
    Category: 2,
    Spans: [
      {
        DataSet: null,
        Text: "Favorite encountered an error in performing action 'Share': Class ClFavorite is not found in ClassInfo data types map. Import this class before loading.",
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:12.411Z',
      },
    ],
  },
  {
    Category: 0,
    Spans: [
      {
        DataSet: null,
        Text: 'FAILED Share',
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:12.411Z',
      },
    ],
  },
  {
    Category: 0,
    Spans: [
      {
        DataSet: null,
        Text: 'COMPLETED Share',
        FullTypeName: null,
        ForeignKey: null,
        Timestamp: '2022-10-27T13:59:12.411Z',
      },
    ],
  },
];

export const handlerStatisticsDataMock = [
  {
    Statistic: {
      DataSetId: '',
      EntityKey: {
        DataSetId: '',
        ForeignKey:
          'pgAAAAJfa2V5ACUAAAAxMGZjMGM2OS1mNTYyLTQ2Y2ItYmE4Yi00MzE0YjY1NzExNzcABF90ADkAAAACMAAJAAAAQ2xSZWNvcmQAAjEAHQAAAENsLkFuYWx5c3QuV2ViLkNsRmF2b3JpdGVLZXkAAAJGYXZvcml0ZUlEACUAAAAxMGZjMGM2OS1mNTYyLTQ2Y2ItYmE4Yi00MzE0YjY1NzExNzcAAA==',
        DisplayText: null,
        Elements: null,
      },
      EntityShortInfo: ['10fc0c69-f562-46cb-ba8b-4314b6571177'],
      EndTime: '2022-10-27T13:59:12.411Z',
      ExecutedInFullTypeName: 'Cl.Runtime.Analyst.Web.FavoriteData',
      ExecutionStatus: 5,
      HandlerInfo: {
        Category: null,
        ConfirmationInfo: null,
        DataBrowsingMode: false,
        FullTypeName: 'Cl.Runtime.Analyst.Web.FavoriteShareArgs',
        Hidden: false,
        InteractiveEdit: false,
        IsCustomFilter: false,
        IsStatic: false,
        Label: 'Share',
        Name: 'Share',
        Return: null,
        SkipViewersUpdateOnFinish: false,
        TriggeredBy: {
          FirstName: null,
          DisplayName: 'annat',
          Email: null,
          LastName: null,
          Login: 'annat',
          ShortName: 'an',
        },
      },
      IsActive: true,
      Parameters: null,
      Progress: 1,
      StartTime: '2022-10-27T13:59:08.526Z',
      SubmitId: 'e95863192e5b1328fc6bd27491c832a',
      TabTitle: 'Favorite',
      EnvName: 'Main.Dev;Default',
      RemovedUsers: null,
    },
    Messages: [
      {
        Category: 0,
        Spans: [
          {
            DataSet: null,
            Text: 'RUNNING Share',
            FullTypeName: null,
            ForeignKey: null,
            Timestamp: '2022-10-27T13:59:08.526Z',
          },
        ],
      },
      {
        Category: 0,
        Spans: [
          {
            DataSet: null,
            Text: 'The handler has been added into the queue.',
            FullTypeName: null,
            ForeignKey: null,
            Timestamp: '2022-10-27T13:59:08.526Z',
          },
        ],
      },
      {
        Category: 0,
        Spans: [
          {
            DataSet: null,
            Text: 'The handler started executing.',
            FullTypeName: null,
            ForeignKey: null,
            Timestamp: '2022-10-27T13:59:08.526Z',
          },
        ],
      },
      {
        Category: 2,
        Spans: [
          {
            DataSet: null,
            Text: "ClFavorite encountered an error in performing action 'Share': Class ClFavorite is not found in ClassInfo data types map. Import this class before loading.",
            FullTypeName: null,
            ForeignKey: null,
            Timestamp: '2022-10-27T13:59:12.411Z',
          },
        ],
      },
      {
        Category: 0,
        Spans: [
          {
            DataSet: null,
            Text: 'FAILED Share',
            FullTypeName: null,
            ForeignKey: null,
            Timestamp: '2022-10-27T13:59:12.411Z',
          },
        ],
      },
    ],
    Notifications: [],
  },
];
