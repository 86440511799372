import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { selectGridSchema } from 'store/selectors/grid.selector';
import { contentHandlerApi, ContentHandlersModal, RunContentHandlerParams } from 'view/Handlers';
import { useBoolean } from 'utils/hooks';
import { contentHandlersConverter } from 'view/Handlers/api/converters';
import { useAppContext } from 'context';
import { selectActiveDataset, selectActiveEnvironment } from 'store/selectors';
import { openTab } from 'store/shared-reducers/AppStateSlice';
import { useTabParams } from 'containers/TabItemContent/context/useTabParams';

type ChatAboutContentProps = {
  type: string;
  selectedGridRowKey?: string;
  isDisabled: boolean;
};

// Todo: Get from BE
const CHAT_KEY = 'ChatDashboard';
const CHAT_LABEL = 'Chat Dashboard';

export const ChatAboutContent: FC<ChatAboutContentProps> = ({ type, selectedGridRowKey, isDisabled }) => {
  const environment = useSelector(selectActiveEnvironment);
  const dataset = useSelector(selectActiveDataset);
  const dispatch = useDispatch();
  const { data: gridSchema } = useSelector(selectGridSchema);

  const { activeTab, setActiveTab } = useTabParams();
  const { openTabParams } = useAppContext();

  const [isModalOpen, { setTrue: handleOpenModal, setFalse: handleCloseModal }] = useBoolean(false);

  const contentHandlers = contentHandlersConverter.toDomain(gridSchema.Declare?.Handlers);
  const isChatAboutDisabled = isDisabled || !contentHandlers.isExist;

  const handleMoveToPreviousTab = () => {
    if (activeTab?.id) {
      setActiveTab(activeTab.id);
      openTabParams.chatAbout.clearParams();
    }
  };

  const handleRunContentFile = async (params: Pick<RunContentHandlerParams, 'handler'>): Promise<void> => {
    if (!selectedGridRowKey) return;

    const content = await contentHandlerApi.runContentHandler({
      handler: params.handler,
      key: selectedGridRowKey,
      type: type,
      dataset,
      environment,
    });

    dispatch(openTab({ id: CHAT_KEY, key: CHAT_KEY, label: CHAT_LABEL }));
    setActiveTab(CHAT_KEY);

    openTabParams.chatAbout.setParams({
      data: content,
      handleMoveToPreviousTab,
    });
  };

  const handleGoToChat = () => {
    if (contentHandlers.isMultiChoice) {
      handleOpenModal();
      return;
    }

    handleRunContentFile({ handler: contentHandlers.handlers[0] });
  };

  return (
    <>
      <Icon onClick={handleGoToChat} SvgIcon={APP_ICONS.chatAbout} disable={isChatAboutDisabled} />
      {isModalOpen && (
        <ContentHandlersModal
          isOpen
          handlers={contentHandlers.handlers}
          onClose={handleCloseModal}
          onApply={handleRunContentFile}
        />
      )}
    </>
  );
};
