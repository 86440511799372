import cx from 'classnames';

import { Tooltip, TooltipTrigger, TooltipContent } from 'shared/Tooltip';
import { DateFormatEnum, DateFormatUtility } from 'utils';
import { getStatusTextColor } from 'view/Logs/utils/helpers';
import { LogsEventDataType } from 'view/Logs/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import styles from './styles.module.scss';

type LogsFlatViewItemProps = {
  listItem: LogsEventDataType;
  isErrorItem?: boolean;
};

export const LogsFlatViewItem = ({ listItem, isErrorItem = false }: LogsFlatViewItemProps) => {
  const { Level, Timestamp, Message } = listItem;

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className={styles.messageBlock}>
          {isErrorItem && <Icon SvgIcon={APP_ICONS.cross} className={styles.crossIcon} clickable={false} />}
          <div className={cx(getStatusTextColor(Level), styles.message)}>{Message}</div>
        </div>

        <div className={styles.timestamp}>{DateFormatUtility.create(Timestamp).format(DateFormatEnum.TIME_DATE)}</div>
      </TooltipTrigger>
      <TooltipContent
        className={cx({
          [styles.errorTooltip]: isErrorItem,
        })}
      >
        {Message}
      </TooltipContent>
    </Tooltip>
  );
};
