import { OnValidate } from '@monaco-editor/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/Button';
import { CodeEditor } from 'shared/CodeEditor';
import { Icon } from 'shared/Icon';
import { UIInput, UIInputProps, UiModal } from 'ui';
import { useBoolean } from 'utils/hooks';
import { APP_ICONS } from 'utils/icons';
import { LanguageEnum, ValueTypeModel } from 'api';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

type StringValueProps = Omit<UIInputProps, 'value' | 'onChange'> & {
  elementName: string;
  path?: string[];
  useTabEditor?: boolean;
  valueType?: ValueTypeModel;
  value: string;
  onChange?: (value: string) => void;
  onUpdateOriginalValue?: (value: string) => void;
};

const isMultiLineText = (text: string): boolean => /\\n/gi.test(text);
const getFirstLine = (text: string): string => text.split('\\n')[0].trim().concat('...') || '';

export const StringValue: FC<StringValueProps> = ({
  path = [],
  useTabEditor = false,
  elementName,
  value,
  onChange,
  onFocus,
  onBlur,
  readOnly,
  onUpdateOriginalValue,
  valueType,
  ...rest
}) => {
  const { action } = useEditorState();
  const { state } = useEditorTabState();
  const { t } = useTranslation();

  const [isOpenModal, { setFalse: handleCloseModal, setTrue: handleOpenModal }] = useBoolean(false);
  const [isFocused, { setTrue: setFocus, setFalse: setBlur }] = useBoolean(false);
  const [isDisabledSaving, { setTrue: handleDisableSaving, setFalse: handleEnableSaving }] = useBoolean(false);

  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (isOpenModal) {
      setCode(value);
    }
  }, [isOpenModal]);

  const handleCodeChange = (updatedValue: string) => {
    setCode(updatedValue);
    handleEnableSaving();
  };

  const handleCodeSave = () => {
    const updatedValue = code ?? '';

    onUpdateOriginalValue?.(updatedValue);
    onChange?.(updatedValue);

    handleCloseModal();
  };

  const handleCodeValidate: OnValidate = (markers) => {
    markers.length > 0 && handleDisableSaving();
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setFocus();
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    setBlur();
  };

  const getDisplayingValue = () => {
    if (isFocused) {
      return value;
    }

    return isMultiLineText(value) ? getFirstLine(value) : value;
  };

  const handleOpenStringEditor = (e: React.MouseEvent) => {
    if (useTabEditor && valueType) {
      // "stopPropagation" needed if click occurred from NO Active carousel item.
      // We need just to replace old breadcrumb tab to the new one, without setting active current tab where icon located
      e.stopPropagation();

      action.openEditorTabAction(
        {
          title: elementName,
          key: valueType,
          path,
          tabEditorValueType: valueType,
          selector: '',
          isReadOnly: readOnly,
        },
        state.tabIndex
      );
      return;
    }
    handleOpenModal();
  };

  return (
    <>
      <UIInput
        {...rest}
        readOnly={readOnly}
        value={getDisplayingValue()}
        onChange={(e) => onChange?.(e.target.value)}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        suffixElement={<Icon SvgIcon={APP_ICONS.multiLineEdit} onClick={handleOpenStringEditor} />}
      />
      <UiModal
        minHeightConstraints={200}
        minWidthConstraints={400}
        defaultHeight={200}
        defaultWidth={600}
        title={elementName}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      >
        <UiModal.Body className="overflow-auto">
          <CodeEditor
            code={code}
            isReadonly={readOnly}
            onChange={handleCodeChange}
            onValidate={handleCodeValidate}
            language={LanguageEnum.Plaintext}
          />
        </UiModal.Body>
        <UiModal.Footer>
          <Button title={t('buttons.cancel')} variant="secondary" onClick={handleCloseModal} />
          <Button title={t('buttons.save')} disabled={isDisabledSaving || readOnly} onClick={handleCodeSave} />
        </UiModal.Footer>
      </UiModal>
    </>
  );
};
