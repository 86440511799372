import { ViewerMetadataType, ViewOfType } from 'store/types';
import { isNullable, isObject } from 'utils';

const isViewOfType = (input: ViewerMetadataType['ViewOf']): input is ViewOfType => isObject(input) && '_t' in input;

export const getViewerTypeHelper = (viewerData?: ViewerMetadataType): string | undefined => {
  if (isNullable(viewerData)) return undefined;

  if (isViewOfType(viewerData.ViewOf)) {
    const { _t: type } = viewerData.ViewOf;
    return type;
  }

  return undefined;
};
