import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

type ExtendedModalIconProps = {
  onClick: () => void;
  disable?: boolean;
};

export const ExtendedModalIcon = ({ onClick, disable }: ExtendedModalIconProps) => {
  const { t } = useTranslation();

  return (
    <Icon
      SvgIcon={APP_ICONS.fillArrowDown}
      title={t('shared.open') || ''}
      disable={disable}
      onClick={onClick}
      className="px-1 py-2"
    />
  );
};
