import { UIListGroup as InternalUIListGroup } from './UIListGroup';
import { UIListGroupItem } from './UIListGroupItem';

export type { UIListGroupProps } from './UIListGroup';
export type { UIListGroupItemProps } from './UIListGroupItem';

type InternalUIListGroupType = typeof InternalUIListGroup;

type CompoundedUIListGroup = InternalUIListGroupType & {
  Item: typeof UIListGroupItem;
};

export const UIListGroup: CompoundedUIListGroup = InternalUIListGroup as CompoundedUIListGroup;

UIListGroup.Item = UIListGroupItem;
