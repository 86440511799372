import cx from 'classnames';
import { FC } from 'react';

import { GridTabDataType } from 'store/types';
import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { EditorTabs } from 'view/EditorContent/EditorTabs';
import { NoGridData } from 'containers/TabItemContent/components/NoGridData';
import { Loader } from 'shared/Loader';
import { IconWithTooltip } from 'shared/IconWithTooltip/IconWithTooltip';
import { APP_ICONS } from 'utils/icons';
import { Toolbar } from 'shared/Toolbar/Toolbar';

import styles from './styles.module.scss';

export type DropPreviewType = {
  activeTab: string | undefined;
  draggingTab: GridTabDataType | null;
  selectedGridRow?: SelectedGridRowType;
  showToolbar: boolean;
  type: string;
};

export const DropPreview: FC<DropPreviewType> = ({ activeTab, draggingTab, showToolbar }) => {
  const onChangeTab = () => undefined;

  const onSetTabs = () => undefined;

  if (!draggingTab || !activeTab) {
    return <NoGridData title={''} />;
  }

  return (
    <div className={cx('w-100 d-flex flex-column h-100 overflow-x-auto overflow-y-hidden', styles.previewBG)}>
      <EditorTabs activeTab={activeTab} tabs={[draggingTab]} onChangeTab={onChangeTab} onSetTabs={onSetTabs} />
      <div className="stack-content d-flex flex-column h-100 position-relative overflow-auto">
        {showToolbar && (
          <Toolbar>
            <IconWithTooltip SvgIcon={APP_ICONS.openJson} />
          </Toolbar>
        )}
        <div className="w-100 h-100">
          <Loader />
        </div>
      </div>
    </div>
  );
};
