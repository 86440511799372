import type { PageViewport, PDFPageProxy } from 'pdfjs-dist';

import { escapeStringRegexp, isObject } from 'utils';

import type { TextItem } from './types';

export function getOffsetOfPreviousViewports(index: number, viewports: PageViewport[]) {
  // TODO: add viewport offset
  return viewports.slice(0, index).reduce((offset: number, viewport: PageViewport) => offset + viewport.height, 0);
}

function isTextItem(input: unknown): input is TextItem {
  if (isObject(input)) {
    const fields: Array<keyof TextItem> = ['str', 'dir', 'transform', 'width', 'height', 'fontName', 'hasEOL'];
    return fields.every((field: keyof TextItem) => field in input);
  }

  return false;
}

export async function getPageContent(page: PDFPageProxy): Promise<string> {
  const pageContent = await page.getTextContent();
  return pageContent.items
    .filter(isTextItem)
    .map((item: TextItem) => {
      let str = item.str || '';
      if (item.hasEOL) str += ' ';
      return str;
    })
    .join('');
}

export function searchAllMatches(search: string, content: string) {
  const regex = new RegExp(escapeStringRegexp(search), 'ig');
  return Array.from(content.matchAll(regex), (match) => {
    const matchedIndex = match.index || 0;
    return {
      startIndex: matchedIndex,
      endIndex: matchedIndex + match[0].length,
    };
  });
}
