import { FC } from 'react';

import { StringValue } from 'view/Editor/components/TableBody/TableBodyCell/StringValue';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

type DictionaryViewKeyCellProps = {
  isReadonly?: boolean;
  dictKey: string;
  path: string[];
};

export const DictionaryViewKeyCell: FC<DictionaryViewKeyCellProps> = ({ isReadonly = false, dictKey, path }) => {
  const { action } = useEditorState();

  const handleKeyChange = (updateValue: string) => {
    action.updateEditorData({ path, updateValue });
  };

  return (
    <StringValue
      variant="transparent"
      readOnly={isReadonly}
      selectOnFocus
      elementName={dictKey}
      value={dictKey}
      onChange={handleKeyChange}
    />
  );
};
