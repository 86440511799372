import { CellProps } from 'react-table';

import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { TableBodyCell } from 'view/Editor/components/TableBody/TableBodyCell';
import { useComplexCell } from 'view/Editor/components/TableBody/TableBodyCell/hooks/useComplexCell';
import { isArray } from 'utils';
import { LengthIcon } from 'view/Editor/components/TableBody/TableBodyCell/LengthIcon';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

export const FlatCell = ({ value, row }: CellProps<EditorViewDataType>) => {
  const { action } = useEditorState();
  const { path } = row.original;
  const { checkViewOnly, checkReadOnly } = useComplexCell({
    ...row.original,
    value,
  });

  const handleSimpleValueChanged = (updatedValue: unknown) => {
    action.updateEditorData({
      path,
      updateValue: updatedValue,
    });
  };

  return (
    <TableBodyCell
      key={path.toString()}
      path={path}
      enumSelector={row.original.enumSelector}
      elementName={row.original.elementKey}
      originalValue={value}
      keyName={row.original.keyName}
      keyModuleName={row.original.keyModuleName}
      schemaType={row.original.schemaType}
      successor={row.original.successor}
      valueType={row.original.valueType}
      isVector={row.original.isVector}
      isRequired={row.original.isRequired}
      isViewOnly={checkViewOnly()}
      isReadOnly={checkReadOnly()}
      isFlatCell
      suffixElement={isArray(value) && <LengthIcon length={value.length} />}
      onChange={handleSimpleValueChanged}
    />
  );
};
