import { Dayjs } from 'dayjs';
import React, { ChangeEvent, FC, useEffect, useRef } from 'react';

import { ValueType } from 'store/types';
import { UIInputDate, UIInputDateTime, UIInputProps, UIInputTime } from 'ui';
import { DateFormatEnum, DateFormatUtility, ValueTypesEnum } from 'utils';
import { useBoolean } from 'utils/hooks';

type HandleDateValueType = (date: Dayjs | null, updateValueFn: (value?: string) => void) => void;

type DateValueProps = {
  readOnly?: boolean;
  value?: string;
  valueType?: ValueType['Type'];
  error?: boolean;
  onInputChange: (value?: string) => void;
  onPickerChange: (e?: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const DateValue: FC<DateValueProps> = ({
  readOnly,
  value,
  valueType,
  onInputChange,
  onPickerChange,
  onFocus,
  onBlur,
  onKeyDown,
  error,
}) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const pickerButtonRef = useRef<HTMLButtonElement>(null);

  const [isPickerOpened, { setFalse: handleClosePicker, setTrue: handleOpenPicker }] = useBoolean(false);

  const handleDate: HandleDateValueType = (date, updateValueFn) => {
    updateValueFn(date?.isValid ? date.format(DateFormatEnum.DATE) : date?.toString() ?? undefined);
  };

  const handleTime: HandleDateValueType = (date, updateValueFn) => {
    updateValueFn(date?.isValid ? date.format(DateFormatEnum.TIME_MS) : date?.toString() ?? undefined);
  };

  const handleDateTime: HandleDateValueType = (date, updateValueFn) => {
    updateValueFn(date?.isValid ? date.toISOString() : date?.toString() ?? undefined);
  };

  useEffect(() => {
    const handleClickOutsidePicker = (e: MouseEvent) => {
      if (isPickerOpened && e?.target instanceof Element) {
        const isClickOutside = !pickerRef.current?.contains(e.target) && !pickerButtonRef.current?.contains(e.target);

        if (isClickOutside) {
          e.preventDefault();
          handleClosePicker();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutsidePicker, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePicker, true);
    };
  }, [isPickerOpened]);

  const getInputProps = (onTextChange: HandleDateValueType) => ({
    pickerRef,
    pickerButtonRef,
    disabledPickerButton: readOnly,
    open: isPickerOpened,
    onOpen: handleOpenPicker,
    onClose: handleClosePicker,
    inputProps: {
      variant: 'transparent',
      selectOnFocus: true,
      deselectOnEsc: true,
      blurOnEnter: true,
      readOnly,
      error,
      preventSelectionByClick: true,
      onFocus: onFocus,
      onBlur: onBlur,
      onKeyDown: onKeyDown,
      onChange: (e: ChangeEvent<HTMLInputElement>) => onTextChange(e as unknown as Dayjs | null, onInputChange),
    } as UIInputProps,
  });

  if (valueType === ValueTypesEnum.Date) {
    const date = value ? DateFormatUtility.create(value).toInstance() : null;
    return <UIInputDate {...getInputProps(handleDate)} value={date} onChange={(e) => handleDate(e, onPickerChange)} />;
  }

  if (valueType === ValueTypesEnum.Time) {
    const time = value ? DateFormatUtility.create(value, DateFormatEnum.TIME).toInstance() : null;
    return <UIInputTime {...getInputProps(handleTime)} value={time} onChange={(e) => handleTime(e, onPickerChange)} />;
  }

  if (valueType === ValueTypesEnum.DateTime) {
    const dateTime = value ? DateFormatUtility.create(value).toInstance() : null;
    return (
      <UIInputDateTime
        {...getInputProps(handleDateTime)}
        value={dateTime}
        onChange={(e) => handleDateTime(e, onPickerChange)}
      />
    );
  }

  return null;
};
