import { SchemaTypeListModel, StorageEntityModel } from 'api';
import { isArray, isObject } from 'utils';
import { EditorValidationErrorType } from 'view/Editor/context/editorContext/type';

import { ValidationDataType, ValidationResultType } from './type';
import { getValidationSchemaByPath, validateValueBySchema } from './helper';

export type Validate = {
  schema: SchemaTypeListModel;
  editorData: StorageEntityModel;
  rootSchema: string;
  path: string[];
  value: unknown;
};

/**
 * Validate value by path.
 * @returns  ValidationResultType error with descriptions.
 */
export const validate = ({ schema, rootSchema, editorData, path, value }: Validate): ValidationResultType | null => {
  const validationSchema = getValidationSchemaByPath({ schema, path, rootSchema, editorData });

  return validationSchema?.rules ? validateValueBySchema(validationSchema, value) : null;
};

/**
 * Check is exist validation error in data.
 * @returns  Return "true" with first error.
 */
export const isExistError = ({ data, schema, rootSchemaName }: ValidationDataType): boolean => {
  const validateData = (currentData: unknown, path: string[]): boolean => {
    const isInvalidValue = (): boolean =>
      !!validate({ schema, path, rootSchema: rootSchemaName, editorData: data, value: currentData });

    if (isArray(currentData)) {
      if (!currentData.length) {
        return isInvalidValue();
      }

      return currentData.some((item, index) => validateData(item, [...path, index.toString()]));
    }
    if (isObject(currentData)) {
      return Object.entries(currentData).some(([key, value]) => validateData(value, [...path, key]));
    }

    return isInvalidValue();
  };
  return validateData(data, []);
};

/**
 * Validate full editor data.
 * @returns EditorValidationErrorType list of errors.
 */
export const validateEditorData = ({ data, rootSchemaName, schema }: ValidationDataType): EditorValidationErrorType => {
  const validationResult = {} as EditorValidationErrorType;
  const validateData = (currentData: unknown, path: string[]) => {
    const checkValue = () => {
      const result = validate({ schema, path, rootSchema: rootSchemaName, editorData: data, value: currentData });

      if (result) {
        validationResult[result.path.toString()] = result;
      }
    };

    if (isArray(currentData)) {
      if (!currentData.length) {
        checkValue();
        return;
      }

      currentData.forEach((item, index) => {
        validateData(item, [...path, index.toString()]);
      });
      return;
    }
    if (isObject(currentData)) {
      Object.entries(currentData).forEach(([key, value]) => {
        validateData(value, [...path, key]);
      });
      return;
    }

    checkValue();
  };
  validateData(data, []);
  return validationResult;
};
