import { MouseEvent } from 'react';
import { Header } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { APP_ICONS } from 'utils/icons';
import { Icon } from 'shared/Icon';
import { UiDropdown, UiDropdownItem } from 'ui';

import { UIGridMenuItem } from '../UIGridMenuItem/UIGridMenuItem.component';

type UIGridMenuProps<TData, TValue> = {
  className?: string;
  header: Header<TData, TValue>;
  isOpen: boolean;
  toggle: () => void;
};

export const UIGridMenu = <TData, TValue>({ className, header, isOpen, toggle }: UIGridMenuProps<TData, TValue>) => {
  const { t } = useTranslation();
  const menuItems = header.column.getCanSort()
    ? [
        {
          label: t('grid.sorting.asc'),
          icon: APP_ICONS.sortAZ,
          onClick: () => header.column.toggleSorting(false),
        },
        {
          label: t('grid.sorting.desc'),
          icon: APP_ICONS.sortZA,
          onClick: () => header.column.toggleSorting(true),
        },
        {
          label: t('grid.sorting.none'),
          icon: APP_ICONS.noSort,
          onClick: () => header.column.clearSorting(),
        },
      ]
    : [];

  const handleDropdownToggle = (event: MouseEvent) => {
    event.stopPropagation();
    toggle();
  };

  if (menuItems.length === 0) return null;

  return (
    <UiDropdown
      className={className}
      isOpen={isOpen}
      toggle={handleDropdownToggle}
      tag="div"
      toggleButton={<Icon clickable={false} SvgIcon={APP_ICONS.burger} />}
    >
      {menuItems.map(({ icon, label, onClick }, index) => (
        <UiDropdownItem key={index} onClick={onClick}>
          <UIGridMenuItem label={label} icon={icon} />
        </UiDropdownItem>
      ))}
    </UiDropdown>
  );
};
