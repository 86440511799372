import { AxiosHeaders } from 'axios';

import { SupportExportLogsModel } from 'api/model/support.model';

import { axiosMain } from '../axios';

export type SupportApi = {
  saveFrontendError(error: string): Promise<string>;
  downloadLogs(): Promise<SupportExportLogsModel>;
  sendFeedback(): Promise<string>;
};

export const supportApi: SupportApi = {
  async saveFrontendError<TResult>(error: string): Promise<TResult> {
    const res = await axiosMain.post<TResult>(`/support/save_frontend_error?error=${error}`);
    return res.data;
  },

  async downloadLogs(): Promise<SupportExportLogsModel> {
    const response = await axiosMain.get<Blob>('/support/download_logs', {
      responseType: 'arraybuffer',
    });

    const fileName =
      response.headers instanceof AxiosHeaders ? (response.headers.get('Content-Disposition') as string) : null;

    return { data: response.data, fileName };
  },

  async sendFeedback<TResult>(): Promise<TResult> {
    const res = await axiosMain.post<TResult>('/support/send_feedback');
    return res.data;
  },
};
