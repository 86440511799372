import { CellProps } from 'react-table';
import BigNumber from 'bignumber.js';

import { isObject } from 'utils';
import { EditorViewDataType } from 'view/Editor/components/utils/types';
import { useEditorState } from 'view/Editor/hook/editorState.hook';

type ComplexCellProps = EditorViewDataType & {
  value: CellProps<EditorViewDataType>['value'];
};

export const useComplexCell = ({ value, isKey, isFlat, isVector, isReadOnly }: ComplexCellProps) => {
  const { state } = useEditorState();

  const checkViewOnly = () => {
    const viewObject = isObject(value) && !BigNumber.isBigNumber(value);

    return isVector || isFlat || viewObject;
  };

  const checkReadOnly = () => (isKey && state.isDisableKeyFields) || state.isReadOnly || isReadOnly;

  return {
    checkViewOnly,
    checkReadOnly,
  };
};
