import { UIRadio as InternalUIRadio } from './UIRadio';
import { UIRadioGroup } from './UIRadioGroup';

export type { UIRadioProps } from './UIRadio';

type InternalUIRadioType = typeof InternalUIRadio;

type CompoundedUIRadio = InternalUIRadioType & {
  Group: typeof UIRadioGroup;
};

export const UIRadio: CompoundedUIRadio = InternalUIRadio as CompoundedUIRadio;

UIRadio.Group = UIRadioGroup;
