import { useCallback } from 'react';
import { Filters } from 'react-table';

import { externalStorage } from 'shared/ExternalStorage';
import { DataType, GridFiltersType } from 'view/Grid/utils/types';

export function useFilters(activeTypeTab: string) {
  const initial = externalStorage.getFiltersByKey(activeTypeTab);

  const onChanged = useCallback(
    (filter: Filters<DataType>) => {
      const setOfFilters = {} as GridFiltersType;

      filter.forEach((item) => {
        setOfFilters[item.id as keyof GridFiltersType] = item.value;
      });

      const result: Filters<DataType> = [];

      const filtersEntries = Object.entries(setOfFilters);

      filtersEntries.forEach((entries) => {
        result.push({ id: entries[0], value: entries[1] });
      });

      externalStorage.setFiltersByKey(activeTypeTab, result);
    },
    [activeTypeTab]
  );

  return {
    initial,
    onChanged,
  };
}
