export const addDataToClickboard = (data: string) => {
  if (navigator.clipboard) return navigator.clipboard.writeText(data);

  const textArea = document.createElement('textarea');
  textArea.value = data;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    return Promise.resolve();
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
    return Promise.reject(err);
  } finally {
    document.body.removeChild(textArea);
  }
};
