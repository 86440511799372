import { GridTabDataType } from 'store/types';
import { SimplifiedEnum, StackEnum } from 'view/DragnDrop/utils/constants';
import { isConfigType } from 'view/DragnDrop/utils/guards/isConfigType';
import { ConfigType, ElementType, LayoutType, StackType } from 'view/DragnDrop/utils/types';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';

const handleStackParsing = (el: ConfigType) => {
  const layoutEl: ElementType = {
    ColsCount: 1,
    Content: [],
    _t: SimplifiedEnum.LAYOUT_ELEMENT,
  };

  const stackEl: StackType = {
    Key: '',
    Stack: [],
    _t: SimplifiedEnum.LAYOUT_STACK_ELEMENT,
  };

  el.content.forEach((tab) => {
    if ((tab as GridTabDataType).isActive) {
      stackEl.Key = tab.id;
    }

    // Skip editor-transition tab
    if (tab.id !== EditorTabsEnum.EDITOR_TRANSITION) {
      stackEl.Stack?.push({ Key: tab.id, _t: SimplifiedEnum.LAYOUT_STACK_ELEMENT });
    }
  });

  if (stackEl.Key === EditorTabsEnum.EDITOR_TRANSITION && stackEl.Stack?.length) {
    stackEl.Key = stackEl.Stack[0].Key;
  }

  layoutEl.Content.push(stackEl);

  return layoutEl;
};

export const getParsedConfig = (passedConfig: ConfigType, topLevel = false) => {
  if (passedConfig.type === StackEnum.COLUMN) {
    const parsedLayout: LayoutType = [];

    passedConfig.content.forEach((configEl) => {
      if (isConfigType(configEl) && configEl.type === StackEnum.STACK) {
        const layoutEl = handleStackParsing(configEl);

        parsedLayout.push(layoutEl);
      }

      if (isConfigType(configEl) && configEl.type === StackEnum.ROW) {
        const layoutEl: ElementType = {
          ColsCount: configEl.content.length,
          Content: [],
          _t: SimplifiedEnum.LAYOUT_ELEMENT,
        };

        configEl.content.forEach((rowEl) => {
          const layoutItem = getParsedConfig(rowEl as ConfigType);

          if (layoutItem && !Array.isArray(layoutItem)) {
            layoutEl.Content.push(layoutItem);
          }

          if (layoutItem && Array.isArray(layoutItem)) {
            const colLayoutEl: ElementType = {
              ColsCount: 1,
              Content: layoutItem,
              _t: SimplifiedEnum.LAYOUT_ELEMENT,
            };

            layoutEl.Content.push(colLayoutEl);
          }
        });

        parsedLayout.push(layoutEl);
      }
    });

    return parsedLayout;
  }

  if (passedConfig.type === StackEnum.ROW) {
    const parsedLayout: LayoutType = [];

    const layoutEl: ElementType = {
      ColsCount: passedConfig.content.length,
      Content: [],
      _t: SimplifiedEnum.LAYOUT_ELEMENT,
    };

    passedConfig.content.forEach((configEl) => {
      if (isConfigType(configEl) && configEl.type === StackEnum.STACK) {
        layoutEl.Content.push(handleStackParsing(configEl));
      }

      if (isConfigType(configEl) && configEl.type === StackEnum.COLUMN) {
        const colEl: ElementType = {
          ColsCount: 1,
          Content: [],
          _t: SimplifiedEnum.LAYOUT_ELEMENT,
        };

        configEl.content.forEach((rowEl) => {
          const layoutItem = getParsedConfig(rowEl as ConfigType);

          if (layoutItem && !Array.isArray(layoutItem)) {
            colEl.Content.push(layoutItem);
          }

          if (layoutItem && Array.isArray(layoutItem)) {
            const rowLayoutEl: ElementType = {
              ColsCount: (rowEl as ConfigType).content.length,
              Content: layoutItem,
              _t: SimplifiedEnum.LAYOUT_ELEMENT,
            };

            colEl.Content.push(rowLayoutEl);
          }
        });

        layoutEl.Content.push(colEl);
      }
    });

    parsedLayout.push(layoutEl);

    return parsedLayout;
  }

  if (passedConfig.type === StackEnum.STACK) {
    const parsedStack = handleStackParsing(passedConfig);

    return topLevel ? [parsedStack] : parsedStack;
  }

  return undefined;
};
