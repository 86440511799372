import { GridTabDataType } from 'store/types/gridTabsDataTypes';

import { ConfigType } from '../types';
import { isConfigType } from '../guards/isConfigType';
import { StackEnum } from '../constants';
import { checkForSingleContentConfig } from './checkForSingleContentConfig';

export const correctContent = (
  passedConfig: ConfigType | GridTabDataType,
  blockApplyNewId = false
): ConfigType | GridTabDataType => {
  const passedConfigCopy = passedConfig;

  if (isConfigType(passedConfigCopy) && passedConfigCopy.content.length > 1) {
    for (let i = 0; i < passedConfigCopy.content.length; i += 1) {
      const iChild = passedConfigCopy.content[i];
      const isConfigTypeIChild = isConfigType(iChild);

      if (iChild && isConfigTypeIChild) {
        if (iChild.content.length > 1) {
          correctContent(iChild, blockApplyNewId);
        }

        if (
          iChild.type === passedConfigCopy.type ||
          (iChild.content.length === 1 &&
            isConfigType(iChild.content[0]) &&
            ((iChild.content[0]?.type as StackEnum) === StackEnum.STACK ||
              (iChild.content[0]?.type as StackEnum) === StackEnum.ROW ||
              (iChild.content[0]?.type as StackEnum) === StackEnum.COLUMN))
        ) {
          passedConfigCopy.content.splice(i, 1, ...iChild.content);
          i -= 1;
        }

        if (iChild.content.length === 0) {
          passedConfigCopy.content.splice(i, 1);
          i -= 1;
        }

        if (!blockApplyNewId) {
          iChild.id = `${iChild.type}-${iChild.content[0]?.key.replace(/\s/g, '')}`;
          iChild.key = iChild.id;
        }
      }

      if (iChild && !isConfigTypeIChild) {
        iChild.isCurrentlyDragging = false;
      }
    }

    passedConfigCopy.id = `${passedConfigCopy.type}-${passedConfigCopy.content[0]?.key.replace(/\s/g, '')}`;
    passedConfigCopy.key = passedConfigCopy.id;
  }

  if (isConfigType(passedConfigCopy) && checkForSingleContentConfig(passedConfigCopy)) {
    return correctContent(passedConfigCopy.content[0], blockApplyNewId);
  }

  return passedConfigCopy;
};
