import { ButtonCode } from 'utils/types/buttonCode';

type MouseDownHandlers = { [key in ButtonCode]?: () => void };

export const handleMouseDown = <T>(event: React.MouseEvent<T, MouseEvent>, handlers: MouseDownHandlers): void => {
  event.stopPropagation();

  const closeSearchMenuEvent = new CustomEvent('closesearchmenu');
  document.dispatchEvent(closeSearchMenuEvent);

  const handler = handlers[event.button as ButtonCode];
  return handler && handler();
};
