import { StylesConfig } from 'react-select';

import { OptionType } from 'ui';
import { QueryOperatorEnum } from 'api';

export const dropdownStyles: StylesConfig<OptionType<QueryOperatorEnum>> = {
  control: () => ({
    minWidth: 0,
    width: '100%',
    paddingTop: 2,
    paddingBottom: 2,
  }),
};
