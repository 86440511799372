import { FC, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import type { PageViewport, PDFDocumentProxy } from 'pdfjs-dist';
import { useVirtualizer } from '@tanstack/react-virtual';

import { UICollapse } from 'ui';
import { Loader } from 'shared/Loader';
import { useThrottle } from 'shared/Hooks/useThrottle';
import { isAbortedPromiseError, makeCancellablePromise } from 'utils';
import { useBoolean } from 'utils/hooks';
import { useViewersNavigator } from 'containers/TabItemContent/components/InteractiveDashboardContainer/components/ViewersNavigator';

import { getOffsetOfPreviousViewports, getPageContent } from './utils/helpers';
import { PdfPage, PDFPagination, PdfSearch, PDFSearchToggle } from './components';
import { usePDFSearch } from './hooks';
import styles from './styles.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = '/static/pdf.worker.min.js';

type PDFViewerProps = {
  content: string;
};

export enum Actions {
  SCROLL_TO_PAGE = '[PDF viewer] Scroll to page',
  SEARCH_BY_MATCH = '[PDF viewer] Search by match',
}

export const PDFViewer: FC<PDFViewerProps> = ({ content }) => {
  const { actionStack, removeActionFromStack } = useViewersNavigator();

  const documentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const pdf = useRef<PDFDocumentProxy | null>(null);

  const {
    isShowSearch,
    onToggleSearch,
    onCloseSearch,
    isExistsTextInContent,
    onSetTextContents,
    currentMatchedTerm,
    currentMatchedIndex,
    matchedSize,
    onNextMatch,
    search,
    onChangeSearch,
    onPreviousMatch,
  } = usePDFSearch({ documentRef, pdfRef: pdf });

  const abortController = useRef<AbortController>(new AbortController());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageViewports, setPageViewports] = useState<PageViewport[]>([]);
  const options = useMemo(
    () => ({
      cMapUrl: 'cmaps/',
      standardFontDataUrl: 'standard_fonts/',
    }),
    []
  );

  const [shouldScroll, { setTrue: setShouldScroll, setFalse: setScrolled }] = useBoolean(false);

  const file = content ? `data:application/pdf;base64,${content}` : '';

  const virtualizer = useVirtualizer({
    scrollPaddingStart: -5,
    count: pageViewports.length,
    getScrollElement: () => containerRef.current,
    estimateSize: useCallback((index: number) => pageViewports[index].height, [pageViewports]),
  });

  useEffect(
    () => () => {
      abortController.current.abort('Component will unmount');
    },
    []
  );

  const onLoadSuccess = (loadedPdf: PDFDocumentProxy) => {
    pdf.current = loadedPdf;

    abortController.current = new AbortController();
    const { signal } = abortController.current;

    const getPagesPromises = Array.from({ length: loadedPdf.numPages }, (_, index) => index + 1).map(
      (pageNumber: number) => loadedPdf.getPage(pageNumber)
    );

    makeCancellablePromise(
      Promise.all(getPagesPromises)
        .then((pages) => {
          const viewports = pages.map((page) => page.getViewport({ scale: 1 }));
          const pageContentPromises = pages.map(getPageContent);

          return Promise.all(pageContentPromises).then((textContents) => ({ viewports, textContents }));
        })
        .then(({ viewports, textContents }) => {
          setPageViewports(viewports);
          onSetTextContents(textContents);
        }),
      signal
    ).catch((error) => {
      if (isAbortedPromiseError(error)) return;

      // TODO: add the ability to show the error component
      console.error(error);
    });
  };

  const onScroll = useThrottle(
    (event: UIEvent<HTMLDivElement>) => {
      const scrollPosition = (event.target as HTMLDivElement).scrollTop;
      const containerHeight = containerRef.current?.offsetHeight || 0;
      const centeredScrollPosition = scrollPosition + containerHeight / 2;
      const virtualItem = virtualizer.getVirtualItemForOffset(centeredScrollPosition);
      setCurrentPage(virtualItem.index + 1);
    },
    100,
    [virtualizer]
  );

  const scrollToPage = useCallback(
    (page: number) => {
      const index = page - 1;
      virtualizer.scrollToIndex(index, { align: 'start' });
    },
    [virtualizer]
  );

  const scrollToPageOffset = useCallback(
    (page: number, pageOffset: number) => {
      const offset = getOffsetOfPreviousViewports(page - 1, pageViewports);
      virtualizer.scrollToOffset(offset + pageOffset);
    },
    [pageViewports, virtualizer]
  );

  useEffect(() => {
    if (pageViewports.length === 0) return;

    for (let i = 0; i < actionStack.length; i += 1) {
      const action = actionStack[i];
      switch (action.action) {
        case Actions.SCROLL_TO_PAGE:
          scrollToPage(action.params.page);
          removeActionFromStack(action);
          break;
        case Actions.SEARCH_BY_MATCH:
          if (action.params.match) {
            onToggleSearch();
            onChangeSearch(action.params.match, { page: action.params.page });

            const isEmptyMatches = !isExistsTextInContent(action.params.match);
            if (isEmptyMatches) scrollToPage(action.params.page);
          }
          removeActionFromStack(action);
          break;
        default:
          break;
      }
    }
  }, [actionStack, pageViewports]);

  useEffect(() => {
    if (!currentMatchedTerm) return;

    setShouldScroll();

    const pageIndex = currentMatchedTerm.page - 1;
    const virtualPages = virtualizer.getVirtualItems();
    const isExistMatchedPage = virtualPages.some((virtualPage) => virtualPage.index === pageIndex);
    if (isExistMatchedPage) return;

    scrollToPage(currentMatchedTerm.page);
  }, [currentMatchedTerm, virtualizer]);

  return (
    <Document
      inputRef={documentRef}
      className={cx(styles.document, 'd-flex flex-column align-items-center w-100 h-100 overflow-hidden')}
      file={file}
      options={options}
      loading={<Loader />}
      onLoadSuccess={onLoadSuccess}
    >
      <div className="d-flex w-100 py-6px px-8px gap-3 justify-content-between border-bottom border-gray-100">
        <PDFPagination currentPage={currentPage} lastPage={pdf?.current?.numPages} onChangePage={scrollToPage} />
        <PDFSearchToggle onClick={onToggleSearch} />
      </div>
      <UICollapse className="w-100" isOpen={isShowSearch}>
        <PdfSearch
          initialSearch={search}
          currentMatchedTerm={currentMatchedIndex}
          matchedTermsSize={matchedSize}
          onPreviousMatch={onPreviousMatch}
          onNextMatch={onNextMatch}
          isOpen={isShowSearch}
          onClose={onCloseSearch}
          onChangeSearch={onChangeSearch}
        />
      </UICollapse>
      <div className="h-100 w-100 overflow-y-auto py-2" ref={containerRef} onScroll={onScroll}>
        <div className="w-100 position-relative" style={{ height: virtualizer.getTotalSize() }}>
          {virtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={virtualItem.key}
              className="position-absolute top-0 start-0 w-100"
              data-virtual-index={virtualItem.index}
              style={{
                height: virtualItem.size,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <PdfPage
                shouldScroll={shouldScroll}
                setScrolled={setScrolled}
                currentMatchedTerm={currentMatchedTerm}
                documentRef={documentRef}
                containerRef={containerRef}
                pageNumber={virtualItem.index + 1}
                search={search}
                onScrollToOffset={(pageOffset: number) => scrollToPageOffset(virtualItem.index + 1, pageOffset)}
              />
            </div>
          ))}
        </div>
      </div>
    </Document>
  );
};
