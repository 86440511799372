import { store } from 'store';
import { selectActiveDataset, selectActiveEnvironment, selectStore } from 'store/selectors';

export type GenerateHeadersOptions = {
  initialHeaders: Record<string, string> | undefined;
};

const searchParams = new URLSearchParams(window.location.search);

const SUPER_USER_EMAIL = 'uladzimirs@compatibl.com';
export const isSuperUser = searchParams.get('isSuperUser') === 'true';

export function generateHeaders(): Record<string, string> {
  const state = store.getState();

  const { tokens, user } = selectStore(state);
  const dataset = selectActiveDataset(state);
  const environment = selectActiveEnvironment(state);

  return {
    ...(tokens.accessToken && { Authorization: `Bearer ${tokens.accessToken}` }),
    ...(dataset && { Dataset: dataset }),
    ...(environment && { Environment: environment }),
    ...(user && { user: user.id }),
    ...(isSuperUser && { isSuperUser: 'true', user: SUPER_USER_EMAIL }),
    Locale: navigator.language,
    Timezone: new Date().getTimezoneOffset().toString(),
  };
}
