import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Navigate } from 'react-router-dom';

import Logo from 'assets/logos/cl-logo.svg';
import { authApi, AuthStrategyEnum, AuthTypeModel } from 'api';
import { useRequest } from 'hook/request.hook';
import { useAppDispatch } from 'store/hooks';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { isLoading, toast } from 'utils';
import { useAppNavigate } from 'router/hooks';
import { setType } from 'store/shared-reducers/AppStateSlice';
import { RouterEnum } from 'router';

import { LoginType } from '../../components/LoginType';
import styles from './MultiLogin.module.scss';

export const MultiLoginPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { navigateToLogin } = useAppNavigate();

  const { state: types, status, hasError } = useRequest(authApi.getTypes, { autorun: true, onError: toast.error });

  const onSelectType = (type: AuthTypeModel) => {
    dispatch(setType(type));

    switch (type.login_strategy) {
      case AuthStrategyEnum.default:
        navigateToLogin();
        break;
      case AuthStrategyEnum.redirect:
        window.location.href = authApi.getLoginRedirectUrl(type);
        break;
      default:
        throw new Error('Auth strategy does not exist');
    }
  };

  if (isLoading([status])) {
    return <AppFallbackSkeleton />;
  }

  if (hasError) {
    return <Navigate to={RouterEnum.SERVER_ERROR} />;
  }

  return (
    <div className="d-flex justify-content-start flex-column overflow-auto bg-primary content">
      <div className="d-flex flex-column align-items-center gap-3 m-auto">
        <Logo />
        <h4 className="text-text2 text-center">{t('auth.multiLogin.title')}</h4>
        <div className={cx(styles.container, 'd-flex flex-wrap justify-content-center gap-2')}>
          {types?.map((type) => <LoginType key={type.id} className={styles.item} onSelect={onSelectType} {...type} />)}
        </div>
      </div>
    </div>
  );
};
