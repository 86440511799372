import { Header, HeaderGroup } from '@tanstack/react-table';
import { Fragment, ReactElement } from 'react';

import './UIGridFooterRow.styles.scss';

type UiGridFooterRowProps<TData> = {
  footerGroup: HeaderGroup<TData>;
  renderCell: <TValue>(footerCell: Header<TData, TValue>) => ReactElement;
};

export const UIGridFooterRow = <TData,>({ footerGroup, renderCell }: UiGridFooterRowProps<TData>) => (
  <div className="ui-grid-footer-row" key={footerGroup.id}>
    {footerGroup.headers.map((footer) => (
      <Fragment key={footer.id}>{renderCell(footer)}</Fragment>
    ))}
  </div>
);
