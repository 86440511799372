import { FC, PropsWithChildren } from 'react';

import { TooltipContext } from '../../contexts/TooltipContext';
import { useTooltip } from '../../hooks/useTooltip';
import { TooltipOptions } from '../../utils/Tooltip.types';

type TooltipProps = PropsWithChildren<TooltipOptions>;

export const Tooltip: FC<TooltipProps> = ({ children, ...options }) => {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};
