export type SortByParamsType = {
  id: string;
  desc: boolean;
};

export enum SortOrderEnum {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}
