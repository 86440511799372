export type FilesService = {
  download: (obj: File | Blob, fileName: string) => void;
};

export const filesService: FilesService = {
  download: (obj: File | Blob, fileName: string) => {
    const url = URL.createObjectURL(obj);
    const anchorElement = document.createElement('a');

    anchorElement.href = url;
    anchorElement.setAttribute('target', '_blank');
    anchorElement.setAttribute('download', fileName || obj.name);

    anchorElement.click();
    URL.revokeObjectURL(url);
  },
};
