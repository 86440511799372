import { FC, useEffect, useState } from 'react';

import { EditorInstance } from 'view/EditorData/EditorInstance';
import { EditorSubmitType } from 'view/Editor/type';
import { Loader } from 'shared/Loader';
import { useEditorRequest } from 'view/EditorData/hook/editorRequest.hook';
import { UiEmpty } from 'ui';
import { DAGNodeType } from 'view/Dag';
import { StorageEntityModel } from 'api';

type EditorDagProps = {
  editorData?: StorageEntityModel;
  recordType: string;
  recordKey: string;
  onCancel?: () => void;
  onUpdate?: (data: StorageEntityModel) => void;
};

export const EditorDag: FC<EditorDagProps> = ({ editorData, recordType, recordKey, onCancel, onUpdate }) => {
  const { getRecord } = useEditorRequest();
  const [rootSchemaName, setRootSchemaName] = useState('');

  useEffect(() => {
    if (recordType && recordKey) {
      getRecord
        .fetch({
          key: recordKey,
          type: recordType,
        })
        .then((record) => {
          setRootSchemaName(Object.keys(record.schema.typeList)?.find((it) => it.endsWith('Node')) || '');
        });
    }
  }, [recordType, recordKey]);

  const handleSubmit = ({ data }: EditorSubmitType) => onUpdate?.(data as DAGNodeType);

  if (getRecord.isLoading) return <Loader />;

  if (!getRecord.state || !rootSchemaName || !editorData) return <UiEmpty />;

  return (
    <EditorInstance
      data={editorData}
      schema={getRecord.state.schema.typeList}
      rootSchemaName={rootSchemaName}
      onCancel={onCancel}
      onSubmit={handleSubmit}
    />
  );
};
