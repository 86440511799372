export enum KeyboardCode {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Escape = 'Escape',
  KeyA = 'KeyA',
  KeyC = 'KeyC',
  KeyF = 'KeyF',
  KeyS = 'KeyS',
  KeyX = 'KeyX',
  KeyV = 'KeyV',
  Backspace = 'Backspace',
  Plus = 'Plus',
  Minus = 'Minus',
  Period = 'Period',
  Zero = 'Digit0',
  One = 'Digit1',
  Two = 'Digit2',
  Three = 'Digit3',
  Four = 'Digit4',
  Five = 'Digit5',
  Six = 'Digit6',
  Seven = 'Digit7',
  Eight = 'Digit8',
  Nine = 'Digit9',
  Tab = 'Tab',
  NumpadEnter = 'NumpadEnter',
  NumpadMinus = 'NumpadMinus',
  NumpadPlus = 'NumpadPlus',
  NumpadPeriod = 'NumpadPeriod',
  NumpadZero = 'Numpad0',
  NumpadOne = 'Numpad1',
  NumpadTwo = 'Numpad2',
  NumpadThree = 'Numpad3',
  NumpadFour = 'Numpad4',
  NumpadFive = 'Numpad5',
  NumpadSix = 'Numpad6',
  NumpadSeven = 'Numpad7',
  NumpadEight = 'Numpad8',
  NumpadNine = 'Numpad9',
}

export const arrowKeyboardCodes = [
  KeyboardCode.ArrowDown,
  KeyboardCode.ArrowLeft,
  KeyboardCode.ArrowRight,
  KeyboardCode.ArrowUp,
];

export const digitKeyboardCodes = [
  KeyboardCode.One,
  KeyboardCode.Two,
  KeyboardCode.Three,
  KeyboardCode.Four,
  KeyboardCode.Five,
  KeyboardCode.Six,
  KeyboardCode.Seven,
  KeyboardCode.Eight,
  KeyboardCode.Nine,
  KeyboardCode.Zero,
];

export const numpadDigitKeyboardCodes = [
  KeyboardCode.NumpadOne,
  KeyboardCode.NumpadTwo,
  KeyboardCode.NumpadThree,
  KeyboardCode.NumpadFour,
  KeyboardCode.NumpadFive,
  KeyboardCode.NumpadSix,
  KeyboardCode.NumpadSeven,
  KeyboardCode.NumpadEight,
  KeyboardCode.NumpadNine,
  KeyboardCode.NumpadZero,
];

export const numberKeyboardCodes = [...digitKeyboardCodes, ...numpadDigitKeyboardCodes];
