import { FC } from 'react';

import { GridTabDataType } from 'store/types/gridTabsDataTypes';

import { ConfigType } from '../utils/types';
import { DropBoxEnum } from '../utils/constants';
import { DnDDroppable } from '../Droppable';

export type DropBoxType = {
  position: string;
  stackRef?: ConfigType | GridTabDataType;
  children?: React.ReactNode;
};

export const DropBox: FC<DropBoxType> = ({ position, stackRef, children }) => {
  switch (position) {
    case DropBoxEnum.GLOBAL:
      return (
        <>
          <DnDDroppable isAbsolute isMaxTop stackRef={stackRef} />
          <DnDDroppable isAbsolute isMaxBottom stackRef={stackRef} />
          <DnDDroppable isAbsolute isMaxLeft stackRef={stackRef} />
          <DnDDroppable isAbsolute isMaxRight stackRef={stackRef} />
        </>
      );
    case DropBoxEnum.TOP:
      return (
        <DnDDroppable isTabs stackRef={stackRef}>
          {children}
        </DnDDroppable>
      );
    case DropBoxEnum.SINGLE:
      return <DnDDroppable isAbsolute isSingle stackRef={stackRef} />;
    default:
      return (
        <>
          <DnDDroppable isAbsolute isTop stackRef={stackRef} />
          <DnDDroppable isAbsolute isBottom stackRef={stackRef} />
          <DnDDroppable isAbsolute isLeft stackRef={stackRef} />
          <DnDDroppable isAbsolute isRight stackRef={stackRef} />
        </>
      );
  }
};
