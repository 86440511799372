import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { UILocalizationProvider } from 'ui';

import { store } from 'store';
import { router } from 'router';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';

import './index.scss';
import './i18n';

const rootNode = document.getElementById('root');
const root = createRoot(rootNode!);

root.render(
  <Provider store={store}>
    <UILocalizationProvider>
      <RouterProvider router={router} fallbackElement={<AppFallbackSkeleton />} />
    </UILocalizationProvider>
  </Provider>
);
