import { MouseEventHandler } from 'react';

export type IconProps = {
  color?: string;
  width?: string;
  height?: string;
  className?: string;
  size?: EnumToType<IconSize>;
  onClick?: MouseEventHandler;
};

export enum IconSize {
  S = 'S',
  M = 'M',
  L = 'L',
  Full = 'Full',
}

export const getIconSize = (size: keyof typeof IconSize | undefined): string =>
  size
    ? {
        [IconSize.S]: '16px',
        [IconSize.M]: '24px',
        [IconSize.L]: '32px',
        [IconSize.Full]: '100%',
      }[size] || size
    : '';
