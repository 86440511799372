import { FC, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';

import { SessionType } from 'containers/TabItemContent/components/InteractiveDashboardContainer/utils/types';
import { UiDropdown } from 'ui';

import { ChatHistoryMenu } from './components/ChatHistoryMenu';
import { ChatHistoryToggle } from './components/ChatHistoryToggle';

type ChatHistoryProps = {
  activeSession?: SessionType;
  isLoading: boolean;
  className?: string;
  sessions: SessionType[];
  selectSession: (session: SessionType) => void;
  deleteSelectedSessions: (sessions: SessionType[]) => void;
};

export const ChatHistory: FC<ChatHistoryProps> = ({
  isLoading,
  activeSession,
  sessions,
  className,
  selectSession,
  deleteSelectedSessions,
}) => {
  const [isOpen, open] = useState(false);

  const isDisabled = useMemo(() => isLoading || sessions.length === 0, [isLoading, sessions.length]);

  const toggle = useCallback(() => open((prevIsOpen) => !prevIsOpen), []);

  return (
    <UiDropdown
      className={cx(className)}
      disabled={isDisabled}
      isOpen={isOpen}
      toggle={toggle}
      tag="div"
      toggleButton={<ChatHistoryToggle isOpen={isOpen} isEmpty={sessions.length === 0} isLoading={isLoading} />}
    >
      <ChatHistoryMenu
        activeSession={activeSession}
        isOpen={isOpen}
        sessions={sessions}
        selectSession={selectSession}
        deleteSelected={deleteSelectedSessions}
      />
    </UiDropdown>
  );
};
