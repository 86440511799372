import { GridTabDataType } from 'store/types';

import { ConfigType } from '../types';

export const setActiveFalse = (configCopyParent: ConfigType, draggingLabel: GridTabDataType) => {
  configCopyParent?.content.forEach((el) => {
    const elCopy = el;
    if (elCopy.id !== draggingLabel.id && draggingLabel.isActive) {
      (elCopy as GridTabDataType).isActive = false;
    }
  });
};
