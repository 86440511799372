import { DropdownIndicatorProps, components } from 'react-select';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { OptionType } from 'ui';

export const DropdownIndicator = <TOption = OptionType,>(props: DropdownIndicatorProps<TOption, false>) => (
  <components.DropdownIndicator {...props} innerProps={{ style: { padding: 0 } }}>
    <Icon SvgIcon={APP_ICONS.fillArrowDown}></Icon>
  </components.DropdownIndicator>
);
