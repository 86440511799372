import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';

import { GridTabDataType } from 'store/types';
import { Tabs } from 'shared/Tabs';
import { ConfigType } from 'view/DragnDrop/utils/types';
import { MaximizeButton } from 'shared/MaximizeButton';
import { useAppContext } from 'context';
import { DnDContext } from 'view/DragnDrop/DraggableContainer';
import { StackEnum } from 'view/DragnDrop/utils/constants';
import { selectMetaData } from 'containers/TabItemContent/store/selectors';
import { SchemaDisplayKind } from 'api';

export type EditorTabsProps = {
  activeTabs?: string[];
  activeTab?: string;
  tabs: GridTabDataType[];
  stackRef?: ConfigType | GridTabDataType;
  onCloseTab?: (tab: GridTabDataType) => void;
  onChangeTab: (tab: GridTabDataType) => void;
  onSetTabs: (tabs: GridTabDataType[]) => void;
};

export const EditorTabs: FC<EditorTabsProps> = ({
  activeTabs,
  activeTab,
  tabs,
  onChangeTab,
  onCloseTab,
  onSetTabs,
  stackRef,
}) => {
  const { state, action } = useAppContext();
  const { config } = useContext(DnDContext);
  const metaData = useSelector(selectMetaData);

  const isDashboard = metaData.data.DisplayKind === SchemaDisplayKind.Dashboard;
  const isMaximizedView = !!state.fullScreenLayerId;

  const handleMaximizedButton = () => {
    if (!stackRef?.id) return;
    action.setFullScreenLayerIdAction(isMaximizedView ? '' : stackRef.id);
  };

  const renderMaximizeBtn = () => (
    <div className="d-flex align-items-center px-2">
      <MaximizeButton isMaximizedView={isMaximizedView} onCLick={handleMaximizedButton} />
    </div>
  );

  return (
    <Tabs
      dndTabs
      onCloseTab={onCloseTab}
      activeTabs={activeTabs}
      shouldTranslate
      openedTabs={tabs}
      activeTab={activeTab}
      onChangeTab={(tab) => onChangeTab(tab)}
      onSetTabs={(reorderedTabs) => onSetTabs(reorderedTabs)}
      stackRef={stackRef}
      renderRightContent={
        config.content.length > 1 && config.type !== StackEnum.STACK ? renderMaximizeBtn() : undefined
      }
      isEditorTabs
      isDashboardTabs={isDashboard}
    />
  );
};
