import { UserDto } from '../data/user.data';
import { UserModel } from '../model/user.model';

export const convertUserDtoToModel = (dto: UserDto): UserModel => {
  const fullName = [dto.first_name, dto?.last_name].filter(Boolean).join(' ');

  return {
    username: dto.username,
    firstName: dto.first_name,
    fullName,
    lastName: dto.last_name || null,
    email: dto.email || null,
    roles: dto.scopes,
    sessionId: dto.session_id,
  };
};
