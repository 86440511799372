import cx from 'classnames';
import { DropdownItem } from 'reactstrap';
import type { FC } from 'react';
import type { DropdownItemProps } from 'reactstrap';

import styles from './UiDropdownItem.module.scss';

export type UiDropdownItemProps = DropdownItemProps & {
  asWrapper?: boolean;
};

export const UiDropdownItem: FC<UiDropdownItemProps> = (props) => {
  const { asWrapper, children, className, ...rest } = props;

  const classes = cx(`d-flex align-items-center`, styles.dropdownItem, className);

  return asWrapper ? (
    <div className={classes} {...rest}>
      {children}
    </div>
  ) : (
    <DropdownItem className={classes} {...rest}>
      {children}
    </DropdownItem>
  );
};
