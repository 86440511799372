import { FC } from 'react';
import { Dayjs } from 'dayjs';
import cx from 'classnames';

import { UIInputDateTime } from 'ui';
import { DateFormatUtility } from 'utils';

import styles from './QueryBuilderDateTimeInput.module.scss';

type QueryBuilderDateTimeInputProps = {
  value: string | null;
  onChange: (value: string | null) => void;
};

export const QueryBuilderDateTimeInput: FC<QueryBuilderDateTimeInputProps> = ({ value, onChange }) => {
  const dateTime = value ? DateFormatUtility.create(value).toInstance() : null;

  const onChangeHandler = (changedDateTime: Dayjs | null) => {
    onChange(changedDateTime?.isValid ? changedDateTime.toISOString() : changedDateTime?.toString() ?? null);
  };

  return <UIInputDateTime className={cx(styles.dropdown)} value={dateTime} onChange={onChangeHandler} />;
};
