import { CellValue, Column } from 'react-table';

import { ColumnStateType, SchemaTypeElementsModel } from 'api';
import { ValueFormatterService } from 'services/valueFormatter';
import { getSortTypeByValueType } from 'utils/helpers/mapper';
import { FilterInput } from 'view/Grid/components/TableHeader/FilterInput';
import { DataType, GridColumWidth } from 'view/Grid/utils/types';

export const getColumnProps = (
  element: Pick<
    SchemaTypeElementsModel,
    'Value' | 'Vector' | 'Format' | 'Name' | 'Key' | 'Optional' | 'ReadOnly' | 'Label'
  >,
  options?: {
    isExpander?: boolean;
    columnId?: string;
    isColumnFormatter?: boolean;
    isShowMiliseconds?: boolean;
    columnState?: ColumnStateType;
  }
): Column<DataType> => {
  const { Name, Label, Value, Format, Optional, ReadOnly } = element;

  return {
    // Size in percent: percent to what size? Need to discuss.
    width: options?.columnState?.SizeInPercent || GridColumWidth.DEFAULT_WIDTH,
    maxWidth: options?.columnState?.MaxSizeInPixels || Number.MAX_SAFE_INTEGER,
    minWidth: options?.columnState?.MinSizeInPixels || GridColumWidth.MIN_WIDTH,
    disableResizing: options?.columnState?.SizeFixed || false,
    id: options?.columnId,
    Header: Label || Name,
    accessor: (originalRow) => originalRow[Name],
    columnType: Value?.Type,
    isRequired: !Optional,
    isReadOnly: ReadOnly,
    sortType: getSortTypeByValueType(Value?.Type),
    ...(options?.isColumnFormatter && {
      valueFormatter: (value: CellValue, maxValue?: number) =>
        ValueFormatterService.simpleValueFormatter(value, Value?.Type, Format, options?.isShowMiliseconds, maxValue),
    }),
    Filter: FilterInput,
    filter: (rows, ids, filterValue) => {
      if (filterValue === '') {
        return rows;
      }
      return rows.filter((row) =>
        ids.some((id) => {
          const formattedValue = ValueFormatterService.simpleValueFormatter(
            row.values[id],
            Value?.Type,
            Format,
            options?.isShowMiliseconds
          );
          return String(formattedValue).toLowerCase().includes(String(filterValue).toLowerCase());
        })
      );
    },
  };
};
