import { GridTabDataType } from 'store/types';
import { DefaultPanelSize, GRID_TAB, GridTabEnum, StackEnum } from 'view/DragnDrop/utils/constants';
import { isConfigType } from 'view/DragnDrop/utils/guards/isConfigType';
import { ConfigType, ElementType, LayoutType, StackType } from 'view/DragnDrop/utils/types';
import { EDITOR_TABS, EditorTabsEnum } from 'view/EditorContent/utils/constants';

const standartConfigMock: ConfigType = {
  type: '',
  key: '',
  id: '',
  isMainGrid: false,
  size: DefaultPanelSize.FULL_VIEW,
  content: [],
};

const tabMock: GridTabDataType = {
  id: '',
  key: '',
  label: '',
  isActive: false,
  isCloseable: false,
  isMainGridTab: false,
};

const isElementType = (layoutEl: ElementType | StackType): layoutEl is ElementType =>
  Object.hasOwn(layoutEl, 'ColsCount');

const defineConfigNodeType = (parsedLayout: ElementType): string => {
  if (parsedLayout.ColsCount === 1 && parsedLayout.Content.length === 1) {
    return StackEnum.STACK;
  }

  if (parsedLayout.ColsCount === 1 && parsedLayout.Content.length > 1) {
    return StackEnum.COLUMN;
  }

  if (parsedLayout.ColsCount > 1 && parsedLayout.Content.length <= parsedLayout.ColsCount) {
    return StackEnum.ROW;
  }

  return '';
};

const defineTopLevelConfigType = (parsedLayout: LayoutType): string => {
  if (parsedLayout.length === 1 && parsedLayout[0].Content.length === 1) {
    return StackEnum.STACK;
  }

  if (parsedLayout.length === 1 && parsedLayout[0].Content.length > 1) {
    return StackEnum.ROW;
  }

  if (parsedLayout.length > 1) {
    return StackEnum.COLUMN;
  }

  return '';
};

export const getDeParsedConfig = (
  parsedLayout: LayoutType | ElementType | StackType,
  viewersContainer: string[] = []
) => {
  if (Array.isArray(parsedLayout)) {
    const topLevelConfigNode = structuredClone(standartConfigMock);

    parsedLayout.forEach((layoutItem) => {
      const contentItem = getDeParsedConfig(layoutItem, viewersContainer);

      if (!Array.isArray(contentItem) && contentItem) {
        topLevelConfigNode.content.push(contentItem);
      }
    });

    topLevelConfigNode.type = defineTopLevelConfigType(parsedLayout);

    topLevelConfigNode.id = `${topLevelConfigNode.type}-${topLevelConfigNode.content[0].id.replace(/\s/g, '')}`;
    topLevelConfigNode.key = topLevelConfigNode.id;

    if (topLevelConfigNode.content.length === 1) {
      return topLevelConfigNode.content[0];
    }

    return topLevelConfigNode;
  }

  if (isElementType(parsedLayout)) {
    const configNode = structuredClone(standartConfigMock);
    configNode.type = defineConfigNodeType(parsedLayout);

    parsedLayout.Content.forEach((contentEl) => {
      const childEl = getDeParsedConfig(contentEl, viewersContainer);

      if (childEl && isConfigType(childEl) && childEl.type === configNode.type) {
        configNode.isMainGrid = childEl.isMainGrid;
        configNode.content.push(...childEl.content);
      } else if (childEl) {
        configNode.content.push(childEl);
      }
    });

    if (configNode.content.length) {
      configNode.id = `${configNode.type}-${configNode.content[0].id.replace(/\s/g, '')}`;
      configNode.key = configNode.id;
    }

    return configNode;
  }

  if (!isElementType(parsedLayout)) {
    const configStack = structuredClone(standartConfigMock);
    configStack.type = StackEnum.STACK;

    parsedLayout.Stack?.forEach((stackItem) => {
      stackItem.Key !== GridTabEnum.MAIN && viewersContainer.push(stackItem.Key);
      const stackTab = { ...tabMock };

      if (stackItem.Key === parsedLayout.Key) {
        stackTab.isActive = true;
      }

      stackTab.id = stackItem.Key;
      stackTab.key = stackTab.id;

      if (stackItem.Key === EditorTabsEnum.EDITOR) {
        stackTab.label = EDITOR_TABS[EditorTabsEnum.EDITOR].label;
      } else if (stackItem.Key === GridTabEnum.MAIN) {
        stackTab.isMainGridTab = true;
        stackTab.label = GRID_TAB[GridTabEnum.MAIN].label;
        configStack.isMainGrid = true;
      } else {
        stackTab.label = stackTab.id;
      }

      configStack.content.push(stackTab);
    });

    if (configStack.content.length) {
      configStack.id = `${configStack.type}-${configStack.content[0].id.replace(/\s/g, '')}`;
      configStack.key = configStack.id;
    }

    return configStack;
  }

  return undefined;
};
