import { TFunction } from 'i18next';

import { env } from 'env';

import { OptionType } from 'shared/CustomDropdown/types';
import { THEME_KEYS } from 'store/constants/theme';
import { ThemesType } from 'store/types';

export const getThemeOptionsHelper = (t: TFunction): OptionType[] =>
  Object.keys(THEME_KEYS).map((key) => ({
    label: t(`header.theme.${key}`),
    value: THEME_KEYS[key as keyof ThemesType],
    isHide: key === 'demo' && !env.isDemoTheme,
  }));
