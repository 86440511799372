import { createContext, FC, ReactNode, useMemo, useState } from 'react';

import {
  DictionaryDataModel,
  FlatViewModel,
  SchemaTypeListModel,
  SchemaTypesItemModel,
  SimpleViewModel,
  StorageEntityModel,
} from 'api';
import { isArray, isArraySimpleViewType, isDefined, isFlatViewType, isObject, toast } from 'utils';
import { deepClone } from 'view/Editor/context/util';
import { cleanTypeHelper } from 'utils/helpers/cleanType.helper';
import { getPropByPath } from 'view/Editor/helpers/getPropByPath.helper';
import { DictionaryViewType } from 'view/Editor/components/EditorView/DictionaryView/DictionaryViewValueCell.component';
import { validate, validateEditorData } from 'view/Editor/validation/validation';
import { EditorEventType, EditorRequestConfigType } from 'view/Editor/type';
import { defaultEditorI18n, EditorI18nType } from 'view/Editor/i18n';

import { createEmptyDataBySchema, setValueByPath, SetValueByPathType } from './helper';
import {
  AddDictElementActionProps,
  AddGroupListElementActionProps,
  AdditionalSchemaType,
  AddListElementActionProps,
  EditorContextAction,
  EditorContextFetch,
  EditorContextState,
  EditorInitDataResultType,
  EditorInitDataType,
  EditorTabType,
  FlatWithType,
  MoveListElementActionProps,
  RemoveGroupListElementActionProps,
  RemoveListDataElementType,
  RemoveListElementActionProps,
  UpdateEditorDataProps,
  UpdateFlatWithTypeDataType,
} from './type';

export const EditorContextWrapper = createContext<EditorContextType | null>(null);

export type EditorContextType = {
  state: EditorContextState;
  event?: EditorEventType;
  action: EditorContextAction;
  fetch: EditorContextFetch;
  request: EditorRequestConfigType;
  i18n: EditorI18nType;
};

export const initState: EditorContextState = {
  isReadOnly: false,
  isDisableKeyFields: false,
  editorSchema: null,
  editorData: null,
  rootSchema: '',
  originalEditorData: null,
  breadcrumbsData: {
    tabs: [],
    activeTabIndex: 0,
  },
  allTypes: null,
  editorToolbarRef: null,
  validationError: {},
};

export type EditorContextProps = {
  isReadOnly: boolean;
  isDisableKeyFields: boolean;
  requestConfig: EditorRequestConfigType;
  i18n?: Partial<EditorI18nType>;
  event?: EditorEventType;
  children: ReactNode;
};

export const EditorContext: FC<EditorContextProps> = ({
  isReadOnly = initState.isReadOnly,
  isDisableKeyFields = initState.isDisableKeyFields,
  requestConfig,
  event: initEvent,
  i18n: editorI18n,
  children,
}) => {
  const [editorToolbarRef, setEditorToolbarRef] = useState<HTMLDivElement | null>(initState.editorToolbarRef);
  const [editorSchema, setEditorSchema] = useState(initState.editorSchema);
  const [editorData, setEditorData] = useState(initState.editorData);
  const [rootSchema, setRootSchema] = useState(initState.rootSchema);
  const [originalEditorData, setOriginalEditorData] = useState(initState.originalEditorData);
  const [breadcrumbsData, setBreadcrumbsData] = useState(initState.breadcrumbsData);
  const [allTypes, setAllTypes] = useState(initState.allTypes);
  const [validationError, setValidationError] = useState(initState.validationError);

  const request = useMemo(() => requestConfig, [requestConfig]);
  const event = useMemo(() => initEvent, [initEvent]);
  const i18n = useMemo(() => (editorI18n ? { ...defaultEditorI18n, ...editorI18n } : defaultEditorI18n), [editorI18n]);

  const initEditor = ({ rootSchemaName, schema, data }: EditorInitDataType): EditorInitDataResultType => {
    const initEditorData = data || createEmptyDataBySchema(schema, rootSchemaName);

    setEditorData(initEditorData);
    setOriginalEditorData(initEditorData);
    setEditorSchema(schema);
    setInitialBreadcrumbs(rootSchemaName);
    setRootSchema(rootSchemaName);
    setValidationError({});

    return {
      data: initEditorData,
      schema,
      rootSchemaName,
    };
  };

  const findSchemaByEndWith = (value: string, editorSchemaList = editorSchema): string | null => {
    const currentGenericDataType = cleanTypeHelper(value);
    if (!currentGenericDataType || !editorSchemaList) return null;

    return Object.keys(editorSchemaList)?.find((schema) => schema.endsWith(currentGenericDataType)) || null;
  };

  const setAllTypesAction = (types: SchemaTypesItemModel[] | null) => {
    setAllTypes(types);
  };

  const updateValidationValue = (path: string[], value: unknown) => {
    if (!editorSchema) return;

    const pathError = validate({ schema: editorSchema, path, rootSchema, editorData, value });
    if (pathError) {
      validationError[path.toString()] = pathError;
      setValidationError(structuredClone(validationError));
    } else if (validationError?.[path.toString()]) {
      delete validationError[path.toString()];
      setValidationError(structuredClone(validationError));
    }
  };

  const validateEditor = (): boolean => {
    if (!editorSchema) return false;

    const validationResult = validateEditorData({ data: editorData, schema: editorSchema, rootSchemaName: rootSchema });
    setValidationError(validationResult);

    return Object.keys(validationResult).length > 0;
  };

  const clearDeepValidationError = (path: string[]) => {
    setValidationError((state) => {
      const pathString = path.toString();
      Object.keys(state).forEach((errorPath) => {
        if (errorPath.startsWith(pathString)) {
          delete validationError[errorPath];
        }
      });
      return state;
    });
  };

  const setEditorDataAction = (data: StorageEntityModel) => setEditorData(data);

  const updateEditorData = ({ path, updateValue }: UpdateEditorDataProps) => {
    if (getPropByPath(editorData, path) === updateValue || !editorSchema) return;

    if (isObject(updateValue) && '_t' in updateValue) {
      clearDeepValidationError(path);
    }

    const updatedEditorData = setValueByPath({
      object: editorData,
      value: updateValue,
      pathList: path,
      addType: SetValueByPathType.SetValue,
    });

    if (updatedEditorData) {
      updateValidationValue(path, updateValue);
      setEditorData(updatedEditorData);
    }
  };

  const switchTabAction = (index: number) => {
    setBreadcrumbsData((it) => ({
      ...it,
      activeTabIndex: index,
    }));
  };

  const updateTab = (value: Partial<EditorTabType>, tabIndex: number) => {
    if (!breadcrumbsData.tabs[tabIndex]) return;

    breadcrumbsData.tabs[tabIndex] = { ...breadcrumbsData.tabs[tabIndex], ...value };
    setBreadcrumbsData((it) => ({
      ...it,
      tabs: [...breadcrumbsData.tabs],
    }));
  };

  const closeTabAction = (index: number) => {
    const { tabs, activeTabIndex } = breadcrumbsData;
    const tabSlice = tabs.slice(0, index);

    const newActiveTabIndex = activeTabIndex >= tabSlice.length ? tabSlice.length - 1 : activeTabIndex;
    setBreadcrumbsData({
      tabs: tabSlice,
      activeTabIndex: newActiveTabIndex,
    });
  };

  const openEditorTabAction = (payload: EditorTabType, prevIndex: number) => {
    const { tabs } = breadcrumbsData;

    const currentIndex = prevIndex + 1;
    breadcrumbsData.tabs.splice(currentIndex, tabs.length - currentIndex, {
      ...payload,
    });

    setBreadcrumbsData({
      tabs: [...breadcrumbsData.tabs],
      activeTabIndex: currentIndex,
    });
  };

  const addGroupListElementAction = ({ path, headers, rowCount }: AddGroupListElementActionProps) => {
    const currentPathData = getPropByPath(editorData, path);

    if (currentPathData) {
      // Case for simple list group
      if (isFlatViewType(currentPathData)) {
        headers?.forEach((key) => {
          if (!currentPathData[key]) {
            currentPathData[key] = [];
          }
          const listDataElement = currentPathData[key];
          if (isArray(listDataElement) && listDataElement.length === rowCount) {
            (listDataElement as SimpleViewModel[]).push(null);
          }
        });
      }

      setEditorData(deepClone(editorData) as StorageEntityModel);
    } else {
      // Case if pathData empty with group view (not in separate breadcrumbs)
      let updatedEditorData = editorData;
      headers?.forEach((header) => {
        updatedEditorData = setValueByPath({
          object: updatedEditorData,
          value: [null],
          pathList: [...path, header],
          addType: SetValueByPathType.SetValue,
        });
      });
      setEditorData(updatedEditorData);
    }
    clearDeepValidationError(path);
  };

  const removeGroupListElementAction = ({ path, headers, rowCount }: RemoveGroupListElementActionProps) => {
    if (rowCount <= 0) return;

    const currentPathData = getPropByPath(editorData, path);

    if (isFlatViewType(currentPathData)) {
      headers?.forEach((key) => {
        const listDataElement = currentPathData[key];
        if (isArraySimpleViewType(listDataElement) && listDataElement.length === rowCount) {
          listDataElement.pop();
        }
      });
    }
    clearDeepValidationError(path);
    setEditorData(deepClone(editorData) as StorageEntityModel);
  };

  const addListElementAction = ({ path, selector, index }: AddListElementActionProps) => {
    const updatedValue = editorSchema && selector ? createEmptyDataBySchema(editorSchema, selector) : null;

    const updatedEditorData = setValueByPath({
      object: editorData,
      pathList: path,
      value: updatedValue,
      addType: SetValueByPathType.AddToArray,
      addedIndex: index,
    });

    if (updatedEditorData) {
      clearDeepValidationError(path);
      setEditorData(updatedEditorData);
    }
  };

  const closeNotExistedDrilledTab = () => {
    const { activeTabIndex, tabs } = breadcrumbsData;
    const nextTabIndex = activeTabIndex + 1;
    if (tabs?.[nextTabIndex]?.path) {
      const nextTabData = getPropByPath(editorData, tabs[nextTabIndex].path);
      if (!nextTabData) {
        closeTabAction(nextTabIndex);
      }
    }
  };

  const removeListElementAction = ({
    path,
    index,
    removeType = RemoveListDataElementType.ByIndex,
  }: RemoveListElementActionProps) => {
    const currentPathData = getPropByPath(editorData, path);

    if (isArray(currentPathData) && currentPathData.length) {
      closeNotExistedDrilledTab();
      const getUpdatedValue = (type: RemoveListDataElementType): unknown =>
        ({
          [RemoveListDataElementType.ByIndex]: currentPathData?.filter((_, dataIndex) => !index.includes(dataIndex)),
          [RemoveListDataElementType.AllExceptIndex]: index.map((selectedIndex) => currentPathData[selectedIndex]),
        })[type];
      const updatedEditorData = setValueByPath({
        object: editorData,
        value: getUpdatedValue(removeType),
        pathList: path,
        addType: SetValueByPathType.SetValue,
      });

      clearDeepValidationError(path);
      setEditorData(updatedEditorData as StorageEntityModel);
    }
  };

  const moveListElementAction = ({ path, from, to }: MoveListElementActionProps): boolean => {
    const currentPathData = getPropByPath(editorData, path);
    if (!isArray(currentPathData) || !currentPathData.length || !isDefined(from) || !isDefined(to)) return false;

    closeNotExistedDrilledTab();
    const fromElement = currentPathData[from];
    currentPathData.splice(from, 1);
    currentPathData.splice(to, 0, fromElement);

    clearDeepValidationError(path);
    setEditorData(deepClone(editorData) as StorageEntityModel);
    return true;
  };

  const addDictElementAction = ({ path, type, index }: AddDictElementActionProps) => {
    const emptyDictData: DictionaryDataModel = {
      key: '',
      value: type === DictionaryViewType.Flat ? {} : [],
    };

    const updatedEditorData = setValueByPath({
      object: editorData,
      pathList: path,
      value: emptyDictData,
      addType: SetValueByPathType.AddToArray,
      addedIndex: index,
    });

    if (updatedEditorData) {
      clearDeepValidationError(path);
      setEditorData(updatedEditorData);
    }
  };

  const setInitialBreadcrumbs = (rootSelector: string) => {
    const defaultTab = {
      title: 'Root',
      key: 'root',
      index: 0,
      path: [],
      schemaPath: '',
      selector: rootSelector,
    };

    setBreadcrumbsData({
      activeTabIndex: 0,
      tabs: [defaultTab],
    });
  };

  const getAdditionalSchema = async ({ type }: AdditionalSchemaType): Promise<SchemaTypeListModel | null> => {
    try {
      const response = await request.getSchema({ name: type });
      setEditorSchema((state) => ({ ...state, ...response.typeList }));
      return response.typeList;
    } catch (e) {
      toast.error(e);
    }

    return null;
  };

  const getFlatWithTypeDataFetch = async ({
    type,
    nextTabIndex,
  }: UpdateFlatWithTypeDataType): Promise<FlatWithType | null> => {
    if (!type) return null;

    const updatedSchemaList = await getAdditionalSchema({ type });
    const rootType = findSchemaByEndWith(type, updatedSchemaList);

    if (!rootType || !updatedSchemaList) return null;

    if (nextTabIndex) {
      updateTab({ selector: rootType }, nextTabIndex);
    }

    const emptyData = createEmptyDataBySchema(updatedSchemaList, rootType);

    if (isObject<FlatViewModel>(emptyData)) {
      return { ...emptyData, _t: type };
    }

    return null;
  };

  const stateValue: EditorContextType = {
    state: {
      isReadOnly,
      isDisableKeyFields,
      rootSchema,
      editorData,
      editorSchema,
      originalEditorData,
      breadcrumbsData,
      allTypes,
      editorToolbarRef,
      validationError,
    },
    action: {
      initEditor,
      findSchemaByEndWith,
      setEditorDataAction,
      updateEditorData,
      switchTabAction,
      closeTabAction,
      openEditorTabAction,
      addGroupListElementAction,
      addListElementAction,
      addDictElementAction,
      removeGroupListElementAction,
      removeListElementAction,
      moveListElementAction,
      setAllTypesAction,
      setEditorToolbarRef,
      validateEditor,
    },
    fetch: {
      getAdditionalSchema,
      getFlatWithTypeDataFetch,
    },
    event,
    request,
    i18n,
  };

  return <EditorContextWrapper.Provider value={stateValue}>{children}</EditorContextWrapper.Provider>;
};
