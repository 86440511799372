import { FC, HTMLAttributes } from 'react';
import cx from 'classnames';

import './UIListGroupItem.scss';

export type UIListGroupItemProps = HTMLAttributes<HTMLLIElement> & {
  active?: boolean;
};

export const UIListGroupItem: FC<UIListGroupItemProps> = ({ className, active, children, ...rest }) => {
  const classes = cx('ui-list-group-item', { 'ui-list-group-item__active': active }, className);

  return (
    <li className={classes} role="button" aria-selected={active} {...rest}>
      {children}
    </li>
  );
};
