import { RefObject, useCallback, useState, useRef, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { ColumnInstance, Row } from 'react-table';
import cx from 'classnames';

import { UiDropdownMenu } from 'ui';
import { DataType, GridModeEnum, SelectedRowsRangeType } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { GridContext } from 'view/Grid/Context';

import { MainGridFilterDropdown } from './components/MainGridFilterDropdown';
import styles from './styles.module.scss';

export type MainGridFilterCellProps = {
  gridRef: RefObject<HTMLDivElement>;
  columns: ColumnInstance<DataType>[];
  backgroundColor?: string;
  gridMode: GridModeEnum;
  rows: Row<DataType>[];
  onSelectedRowsRangeChanged: (newSelectedRowsRange: SelectedRowsRangeType | undefined) => void;
  clearCellSelectionState: () => void;
  selectAllRowsAllCells: () => void;
  maxEntitiesCount: number;
  isAvailableNextPageState: boolean;
  maxRangeCounter: number;
  selectedRowsPerPage: SelectedRowsRangeType;
  setSelectedRowsPerPage: Dispatch<SetStateAction<SelectedRowsRangeType>>;
  updateSelectedRowsPerPageState: (newState: SelectedRowsRangeType) => void;
  isSelectAllRecordsBtnClicked: boolean;
  setIsSelectAllRecordsBtnClicked: Dispatch<SetStateAction<boolean>>;
};

export const MainGridFilterCell = ({
  gridRef,
  backgroundColor,
  columns,
  gridMode,
  rows,
  onSelectedRowsRangeChanged,
  clearCellSelectionState,
  selectAllRowsAllCells,
  maxEntitiesCount,
  isAvailableNextPageState,
  maxRangeCounter,
  selectedRowsPerPage,
  setSelectedRowsPerPage,
  updateSelectedRowsPerPageState,
  isSelectAllRecordsBtnClicked,
  setIsSelectAllRecordsBtnClicked,
}: MainGridFilterCellProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { selectedRowsRange } = useContext(GridContext);
  const selectedRowsRangeCount = Object.keys(selectedRowsRange).length;

  const [allRowsSelected, setAllRowsSelected] = useState(false);

  useEffect(() => {
    if (!checkboxRef.current) {
      return;
    }

    const newSelectedRowsPerPage: SelectedRowsRangeType = {};
    const newSelectedRowsRange = structuredClone(selectedRowsRange);

    rows.forEach((row) => {
      if (!isSelectAllRecordsBtnClicked && selectedRowsRange[row.id]) {
        newSelectedRowsPerPage[row.id] = structuredClone(selectedRowsRange[row.id]);
      }

      if (isSelectAllRecordsBtnClicked && !selectedRowsRange[row.id]) {
        newSelectedRowsRange[row.id] = {
          _key: row.original._key,
          _t: row.original._t,
        };
      }

      if (isSelectAllRecordsBtnClicked) {
        newSelectedRowsPerPage[row.id] = {
          _key: row.original._key,
          _t: row.original._t,
        };
      }
    });

    if (isSelectAllRecordsBtnClicked) {
      onSelectedRowsRangeChanged(newSelectedRowsRange);
    }

    updateSelectedRowsPerPageState(newSelectedRowsPerPage);
    setSelectedRowsPerPage(newSelectedRowsPerPage);

    setAllRowsSelected(Object.keys(newSelectedRowsPerPage).length === rows.length);
  }, [maxRangeCounter, isSelectAllRecordsBtnClicked]);

  useEffect(() => {
    if (!checkboxRef.current) {
      return;
    }

    const isSelectedRowsRangeExist = Object.keys(selectedRowsRange).length !== 0;
    const isMaxRangeRowsCount = Object.keys(selectedRowsRange).length === maxEntitiesCount;

    const isAllSelected =
      (isSelectedRowsRangeExist && isMaxRangeRowsCount && !isAvailableNextPageState) || isSelectAllRecordsBtnClicked;

    checkboxRef.current.checked = isAllSelected;
    checkboxRef.current.indeterminate = selectedRowsRangeCount > 0 && !isAllSelected && !isSelectAllRecordsBtnClicked;

    setAllRowsSelected(Object.keys(selectedRowsPerPage).length === rows.length);
  }, [rows.length, selectedRowsRange, selectedRowsPerPage, isSelectAllRecordsBtnClicked]);

  const handleToggle = useCallback(() => {
    setIsDropdownOpened((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => setIsDropdownOpened(false), []);

  const addAllRowsToRange = () => {
    const allRowsForRange: SelectedRowsRangeType = structuredClone(selectedRowsRange);
    const newSelectedRowsPerPage: SelectedRowsRangeType = {};

    rows.forEach((row) => {
      allRowsForRange[row.id] = { _key: row.original._key, _t: row.original._t };
      newSelectedRowsPerPage[row.id] = { _key: row.original._key, _t: row.original._t };
    });

    onSelectedRowsRangeChanged(allRowsForRange);
    updateSelectedRowsPerPageState(newSelectedRowsPerPage);
    setSelectedRowsPerPage(newSelectedRowsPerPage);

    selectAllRowsAllCells();

    setAllRowsSelected(true);
  };

  const addRowsToRange = useCallback(() => {
    const updatedSelectedRowsRange: SelectedRowsRangeType = structuredClone(selectedRowsRange);
    const newSelectedRowsPerPage: SelectedRowsRangeType = {};

    rows.forEach((row) => {
      if (!updatedSelectedRowsRange[row.id]) {
        updatedSelectedRowsRange[row.id] = {
          _key: row.original._key,
          _t: row.original._t,
        };
      }

      newSelectedRowsPerPage[row.id] = {
        _key: row.original._key,
        _t: row.original._t,
      };
    });

    onSelectedRowsRangeChanged(updatedSelectedRowsRange);
    updateSelectedRowsPerPageState(newSelectedRowsPerPage);
    setSelectedRowsPerPage(newSelectedRowsPerPage);

    setAllRowsSelected(true);

    selectAllRowsAllCells();
  }, [selectedRowsRange]);

  const removeRowsFromRange = useCallback(() => {
    const updatedSelectedRowsRange: SelectedRowsRangeType = structuredClone(selectedRowsRange);

    rows.forEach((row) => {
      if (updatedSelectedRowsRange[row.id]) {
        delete updatedSelectedRowsRange[row.id];
      }
    });

    onSelectedRowsRangeChanged(updatedSelectedRowsRange);
    updateSelectedRowsPerPageState({});
    setSelectedRowsPerPage({});

    setAllRowsSelected(false);

    clearCellSelectionState();
  }, [selectedRowsRange]);

  const handleSelectAll = useCallback(
    (event: React.MouseEvent) => {
      const isAllRowsPerPageSelected = rows.length === selectedRowsRangeCount;
      const isSomeRowsPerPageSelected = rows.length !== selectedRowsRangeCount && selectedRowsRangeCount !== 0;

      if ((isAllRowsPerPageSelected || isSomeRowsPerPageSelected) && !event.ctrlKey) {
        onSelectedRowsRangeChanged({});
        updateSelectedRowsPerPageState({});
        setSelectedRowsPerPage({});
        clearCellSelectionState();
        setAllRowsSelected(false);
        if (isSelectAllRecordsBtnClicked) {
          setIsSelectAllRecordsBtnClicked(false);
        }
        return;
      }

      if (!allRowsSelected && event.ctrlKey) {
        addRowsToRange();
        return;
      }

      if (allRowsSelected && event.ctrlKey) {
        removeRowsFromRange();
        return;
      }

      addAllRowsToRange();
    },
    [selectedRowsRangeCount, onSelectedRowsRangeChanged, rows.length, allRowsSelected, isSelectAllRecordsBtnClicked]
  );

  return (
    <>
      <div className={cx(`c-pointer bg-grid-filter-cell-background  ${backgroundColor || ''}`, styles.container)}>
        <Dropdown className="d-flex h-100 w-100" isOpen={isDropdownOpened} toggle={handleToggle}>
          <DropdownToggle className="d-flex flex-grow-1" tag="div">
            <Icon
              className="h-100 w-100 justify-content-center"
              SvgIcon={APP_ICONS.filter}
              color="text-grid-filter-cell-icon"
            />
          </DropdownToggle>
          <UiDropdownMenu container={gridRef}>
            <MainGridFilterDropdown columns={columns} handleClose={handleClose} />
          </UiDropdownMenu>
        </Dropdown>
      </div>
      {gridMode === GridModeEnum.MAIN && (
        <div className={cx('d-flex justify-content-center align-items-center h-100', styles.margingStart)}>
          <input ref={checkboxRef} className="form-check-input" type="checkbox" onClick={handleSelectAll} />
        </div>
      )}
    </>
  );
};
