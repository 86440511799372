import { createContext } from 'react';

import { CarouselItemType } from 'shared/Carousel/utils/types';

type CarouselContextType = {
  activeId?: string;
  setActiveId: (id: string) => void;
  registerItem: (itemId: string, item: CarouselItemType) => void;
  unregisterItem: (itemId: string) => void;
};

export const CarouselContext = createContext<CarouselContextType>({} as CarouselContextType);
