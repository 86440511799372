import React, { FC, Fragment, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RouterEnum } from 'router';
import { AuthStrategyEnum } from 'api';
import { selectIsAuthenticated, selectType } from 'store/selectors';

type LoginCallbackGuardProps = PropsWithChildren;

export const LoginCallbackGuard: FC<LoginCallbackGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authType = useSelector(selectType);

  if (!authType) {
    return <Navigate to={RouterEnum.LOGIN_MULTIPLE} />;
  }

  // The login callback page supports only the redirect strategy.
  if (authType.login_strategy === AuthStrategyEnum.default) {
    return <Navigate to={RouterEnum.SERVER_ERROR} />;
  }

  if (isAuthenticated) {
    return <Navigate to={RouterEnum.ROOT} />;
  }

  return <Fragment>{children}</Fragment>;
};
