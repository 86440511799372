import { Navigate, useSearchParams } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RouterEnum } from 'router';
import { useAppNavigate } from 'router/hooks/useAppNavigate.hook';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { selectType } from 'store/selectors';
import { authApi, AuthTypeModel } from 'api';
import { useRequest } from 'hook/request.hook';
import { setTokens } from 'store/shared-reducers/AppStateSlice';
import { useAppDispatch } from 'store/hooks';

export const LoginCallbackPage: FC = () => {
  const [searchParams] = useSearchParams();
  const oneTimeToken = searchParams.get('one_time_token');
  const { navigateToRoot, navigateToLoginMultiple } = useAppNavigate();
  const dispatch = useAppDispatch();
  const selectedType = useSelector(selectType) as AuthTypeModel;
  const { hasError, fetch } = useRequest(authApi.handleRedirect);

  useEffect(() => {
    if (selectedType && oneTimeToken) {
      fetch({ type: selectedType, oneTimeToken }).then((tokens) => {
        dispatch(setTokens(tokens));
        navigateToRoot();
      });
    } else {
      navigateToLoginMultiple();
    }
  }, [selectedType, oneTimeToken]);

  if (hasError) {
    return <Navigate to={RouterEnum.SERVER_ERROR} />;
  }

  return <AppFallbackSkeleton />;
};
