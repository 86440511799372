import { isArray, isObject, toString } from 'utils';

export const dataToString = (value: unknown, maxLength = 5): string => {
  if (isObject(value)) {
    const dataArray = objectToStringArray(value, maxLength);
    return trimArray(dataArray, maxLength - 1);
  }
  if (isArray(value)) {
    const dataArray = arrayToStringArray(value, maxLength);
    return trimArray(dataArray, maxLength - 1);
  }

  return toString(value);
};

const excludeKeyList = ['_t'];

const objectToStringArray = (value: object, max = 4, excludeKeys = excludeKeyList): string[] => {
  const valueArray = Object.entries(value)?.reduce<unknown[]>((acc, [arrayKey, arrayValue]) => {
    if (!excludeKeys.includes(arrayKey)) {
      acc.push(arrayValue as unknown);
    }

    return acc;
  }, []);
  return arrayToStringArray(valueArray, max);
};

const arrayToStringArray = (value: unknown[], max = 4): string[] =>
  [...value].reduce<string[]>((acc, itemValue, _, originArray) => {
    if (acc.length >= max) {
      originArray.splice(1);
      return acc;
    }
    if (isObject(itemValue)) {
      return [...acc, ...objectToStringArray(itemValue, max - acc.length)];
    }
    if (isArray(itemValue)) {
      return [...acc, ...arrayToStringArray(itemValue, max - acc.length)];
    }
    if (itemValue) {
      acc.push(toString(itemValue));
    }

    return acc;
  }, []);

const trimArray = (array: unknown[], length = 4) => {
  const resultArray = array.slice(0, length);
  if (array.length > length) resultArray.push('...');

  return resultArray.join('; ');
};
