import { FC } from 'react';
import { useSelector } from 'react-redux';
import * as monaco from 'monaco-editor';
import Editor, { loader, OnMount, OnValidate } from '@monaco-editor/react';
import Markdown from 'react-markdown';

import { LanguageEnum } from 'api';
import { useDebounce } from 'shared/Hooks/useDebounce';
import { THEME_KEYS } from 'store/constants/theme';
import { selectApplicationTheme } from 'store/selectors/AppState.selector';
import { ThemesType } from 'store/types';
import './index.scss';

type CodeEditorProps = {
  isReadonly?: boolean;
  code?: string;
  language?: LanguageEnum;
  onChange?: (value: string) => void;
  onValidate?: OnValidate;
  wordWrap?: boolean;
  isAutoFocus?: boolean;
};

export const CodeEditor: FC<CodeEditorProps> = ({
  isReadonly = false,
  code,
  language,
  onChange,
  onValidate,
  wordWrap,
  isAutoFocus = false,
}) => {
  loader.config({ monaco });

  const storedTheme = useSelector(selectApplicationTheme);
  const { debounce } = useDebounce();

  const theme =
    storedTheme === THEME_KEYS.dark ? 'vs-dark' : THEME_KEYS[storedTheme.toLocaleLowerCase() as keyof ThemesType];

  if (language === LanguageEnum.Markdown) {
    return (
      <div className="markdown-viewer fs-13 text-markdown-viewer-text">
        <Markdown>{code}</Markdown>
      </div>
    );
  }

  const handleEditorMount: OnMount = (editor) => {
    isAutoFocus && editor.focus();
  };

  return (
    <Editor
      className="code-editor overflow-hidden"
      language={language?.toLowerCase()}
      defaultValue=""
      value={code}
      theme={theme}
      width="100%"
      height="100%"
      onMount={handleEditorMount}
      onChange={(value) => {
        onChange && debounce({ fn: onChange, value });
      }}
      onValidate={onValidate}
      options={{
        scrollBeyondLastLine: false,
        readOnly: isReadonly,
        wordWrap: wordWrap ? 'on' : 'off',
        minimap: {
          enabled: false,
        },
        scrollbar: {
          verticalScrollbarSize: 10,
          horizontalScrollbarSize: 10,
        },
      }}
    />
  );
};
