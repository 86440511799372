import { FC, useMemo } from 'react';
import cx from 'classnames';

import { OptionType, UiSelect } from 'ui';

import { dropdownStyles } from './constant';

import styles from './QueryBuilderBoolInput.module.scss';

type QueryBuilderBoolDropdownProps = {
  isOptional?: boolean;
  value: boolean | null;
  onChange: (value: boolean | null) => void;
};

export const QueryBuilderBoolInput: FC<QueryBuilderBoolDropdownProps> = ({ value, onChange }) => {
  const options: OptionType<boolean | null>[] = useMemo(
    () => [
      { label: 'Y', value: true },
      { label: 'N', value: false },
    ],
    []
  );
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <UiSelect
      className={cx('h-100 w-100 bg-gray-500', styles.dropdown)}
      menuPortalTarget={document.body}
      isSearchable={false}
      isClearable={false}
      value={selectedOption}
      options={options}
      styles={dropdownStyles}
      onChange={(selected: OptionType<boolean>) => onChange(selected.value)}
    />
  );
};
