import cx from 'classnames';

import styles from './styles.module.scss';
import { useStartNewChat } from '../../utils/useStartNewChat';

type ProfileItemProps = {
  name: string;
  description: string;
  disabled?: boolean;
};

export const ProfileItem = ({ name, description, disabled = false }: ProfileItemProps) => {
  const startNewChat = useStartNewChat();

  const handleStartNewChat = () => {
    startNewChat(name);
  };

  return (
    <div
      className={cx('p-3 rounded-lg c-pointer bg-primary shadow-sm', styles.profileItemContainer, {
        [styles.disabled]: disabled,
      })}
      onClick={handleStartNewChat}
    >
      <div className="fw-bold text-tab-text-active">{name}</div>
      <div>{description}</div>
    </div>
  );
};
