export enum HandlerContentTypeEnum {
  BinaryContent = 'BinaryContent',
}

export type ExecutedHandlerData<T = unknown> = {
  Result: T | null;
};

export type ExecutedHandlerResult = {
  Content: string;
  ContentType: null;
  Name: string;
  _t: string;
};

export type ExecutedHandlerRunTaskDataType = {
  TaskRunId: string;
  Key: string;
  Result?: ExecutedHandlerResult;
};

export type ExecutedHandlerStatusData = {
  StatusCode: string;
  TaskRunId: string;
  Key?: string;
};

export type HandlerResultDataType = {
  Body: string[];
  _t: string;
  _dataset: string | null;
  _inherit: string[];
  _key: string;
};

export type ExecutedHandlerResultData = {
  Result: HandlerResultDataType;
  TaskRunId: string;
};
