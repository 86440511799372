import React, { Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { App } from 'containers/app/App';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import ErrorBoundary from 'containers/ErrorBoundary';
import { InjectInterceptorsGuard } from 'api';
import {
  LoginCallbackGuard,
  LoginCallbackPage,
  LoginGuard,
  LoginPage,
  LogoutCallbackPage,
  MultiLoginPage,
  SecureRoute,
} from 'auth';
import { UiModalContainer, UIToastContainer } from 'ui';
import { env } from 'env';

import { DefaultRedirectGuard, EnvironmentGuard, StartupGuard } from './guards';
import { RouterEnum } from './types';

export const router = createBrowserRouter(
  [
    {
      element: (
        <InjectInterceptorsGuard>
          <UIToastContainer isDemoMode={env.isDemoMode} />
          <UiModalContainer />
          <Outlet />
        </InjectInterceptorsGuard>
      ),
      children: [
        {
          path: RouterEnum.ROOT,
          element: (
            <StartupGuard>
              <SecureRoute>
                <Suspense fallback={<AppFallbackSkeleton />}>
                  <ErrorBoundary>
                    <Outlet />
                  </ErrorBoundary>
                </Suspense>
              </SecureRoute>
            </StartupGuard>
          ),
          children: [
            {
              path: RouterEnum.ENVIRONMENT,
              element: (
                <EnvironmentGuard>
                  <App />
                </EnvironmentGuard>
              ),
            },
            {
              path: RouterEnum.ACTIVE_TAB,
              element: (
                <EnvironmentGuard>
                  <App />
                </EnvironmentGuard>
              ),
            },
            {
              path: RouterEnum.ROOT,
              element: <DefaultRedirectGuard />,
            },
          ],
        },
        {
          path: RouterEnum.LOGIN,
          element: (
            <LoginGuard>
              <Suspense fallback={<AppFallbackSkeleton />}>
                <LoginPage />
              </Suspense>
            </LoginGuard>
          ),
        },
        {
          path: RouterEnum.LOGIN_MULTIPLE,
          element: <MultiLoginPage />,
        },
        {
          path: RouterEnum.LOGIN_CALLBACK,
          element: (
            <LoginCallbackGuard>
              <LoginCallbackPage />
            </LoginCallbackGuard>
          ),
        },
        {
          path: RouterEnum.LOGOUT_CALLBACK,
          element: <LogoutCallbackPage />,
        },
        {
          path: RouterEnum.SERVER_ERROR,
          async lazy() {
            const { ServerErrorPage } = await import('view/ServerErrorPage');
            return { Component: ServerErrorPage };
          },
        },
        {
          path: 'samples/grid',
          async lazy() {
            const { GridSamplesPage } = await import('./pages/GridSamplesPage');
            return { Component: GridSamplesPage };
          },
        },
        {
          path: RouterEnum.NOT_FOUND,
          element: <Navigate to={RouterEnum.ROOT} />,
        },
      ],
    },
  ],
  {
    basename: process.env.CL_BASENAME,
  }
);
