import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getErrorMessage, LoadingStatusesEnum, storageApi, StorageEnvsModel } from 'api';
import { EnvironmentDataStateType } from 'store/types';
import { isString, toast } from 'utils';

const initialState: EnvironmentDataStateType = {
  environmentData: {
    rowsData: [],
    status: LoadingStatusesEnum.INITIAL,
    error: '',
  },
};

export const getEnvironmentData = createAsyncThunk('getEnvironmentData', async (_, { rejectWithValue }) => {
  try {
    return storageApi.getEnvs();
  } catch (e) {
    toast.error(e);
    return rejectWithValue(getErrorMessage(e));
  }
});

const EnvironmentDataSlice = createSlice({
  name: 'environmentData',
  initialState: initialState,
  reducers: {
    selectEnvironment(state, action) {
      state.environmentData.active = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getEnvironmentData.pending, (state) => {
        state.environmentData.status = LoadingStatusesEnum.LOADING;
        state.environmentData.error = '';
      })
      .addCase(getEnvironmentData.fulfilled, (state, action: PayloadAction<StorageEnvsModel[]>) => {
        const firstEnvironment = action.payload.at(0);
        if (firstEnvironment) {
          state.environmentData.active = firstEnvironment.Name;
        }

        state.environmentData.rowsData = action.payload;
        state.environmentData.status = LoadingStatusesEnum.SUCCESS;
      })
      .addCase(getEnvironmentData.rejected, (state, action) => {
        state.environmentData.status = LoadingStatusesEnum.ERROR;
        state.environmentData.error = isString(action.payload) ? action.payload : '';
      }),
});

export const EnvironmentDataSliceReducer = EnvironmentDataSlice.reducer;

export const { selectEnvironment } = EnvironmentDataSlice.actions;
