import { useContext } from 'react';

import { AppContext, AppContextWrapper } from '../AppContext.context';
import { AppContextAction, AppContextOpenTabParams, AppContextState } from '../types';

export const useAppContext = (): AppContext => {
  const data = useContext(AppContextWrapper);

  return {
    state: data?.state || ({} as AppContextState),
    action: data?.action || ({} as AppContextAction),
    openTabParams: data?.openTabParams || ({} as AppContextOpenTabParams),
  };
};
