import { ChangeEvent, ClipboardEvent, FC, useEffect, useState } from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';

import { isExponentialNumber, isNegativeNumber, isNumber, toNumber, toString } from 'utils';

import { UIInput, UIInputProps } from './UIInput.component';
import './UIInputNumber.component.styles.scss';

export type UIInputNumberProps = Omit<UIInputProps, 'type' | 'value' | 'onChange'> & {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  highlightNegativeNumbers?: boolean;
  value?: number | BigNumber | null;
  onChange?: (value: number | BigNumber | null) => void;
};

// TODO: add the ability to increase/decrease value by up and down arrow.
// TODO: add the mask.
export const UIInputNumber: FC<UIInputNumberProps> = ({
  minimumFractionDigits = 0,
  maximumFractionDigits = 9,
  highlightNegativeNumbers,
  className,
  value,
  onChange,
  ...rest
}) => {
  const [text, setText] = useState<string>(() => toString(value));

  const classes = cx(
    'ui-input-number',
    { 'ui-input-number_negative': highlightNegativeNumbers && isNegativeNumber(value) },
    className
  );

  const validateRegex = new RegExp(
    `^-?[0-9]*${maximumFractionDigits === 0 ? '' : '\\.'}?[0-9]{${minimumFractionDigits},${maximumFractionDigits}}$|^$`
  );
  const parseRegex = new RegExp(
    `^-?[0-9]*${maximumFractionDigits === 0 ? '' : '\\.'}?[0-9]{${minimumFractionDigits},${maximumFractionDigits}}`
  );

  const parseNumber = (input: string): number | BigNumber | null => {
    const isParseable = !Number.isNaN(parseFloat(input));
    const parsedNumber = isParseable ? toNumber(input) : null;
    return isNumber(parsedNumber) && isExponentialNumber(parsedNumber) ? BigNumber(parsedNumber) : parsedNumber;
  };

  const isEqual = (x: number | BigNumber | null | undefined, y: number | BigNumber | null | undefined): boolean => {
    if (BigNumber.isBigNumber(x) && BigNumber.isBigNumber(y)) return x.isEqualTo(y);
    if (BigNumber.isBigNumber(x) && isNumber(y)) return x.isEqualTo(y);
    if (BigNumber.isBigNumber(y) && isNumber(x)) return y.isEqualTo(x);

    return x === y;
  };

  useEffect(() => {
    const numberValue = parseNumber(text);
    if (isEqual(numberValue, value)) return;

    setText(toString(value));
  }, [value]);

  const updateText = (updatedText: string) => {
    if (!validateRegex.test(updatedText)) return;

    setText(updatedText);

    onChange?.(parseNumber(updatedText));
  };

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    updateText(inputValue);
  };

  const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text');
    const [match] = pasteData.match(parseRegex) || [];
    if (!match) return;

    updateText(match);
  };

  return <UIInput type="text" className={classes} value={text} onChange={onChangeText} onPaste={onPaste} {...rest} />;
};
