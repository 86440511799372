import { Dispatch, SetStateAction, useRef } from 'react';

const MAX_HOURS = 23;
const MAX_FIRST_HOURS_NUMBER = 2;
const MAX_MINUTES_OR_SECONDS = 59;
const MAX_FIRST_MINUTES_OR_SECONDS_NUMBER = 5;

export const usePicker = () => {
  const hoursRef = useRef<HTMLInputElement>(null);
  const minutesRef = useRef<HTMLInputElement>(null);
  const secondsRef = useRef<HTMLInputElement>(null);

  const getStringWithRequiredDigits = (datePart?: number, digits = 2) =>
    datePart?.toLocaleString('en-US', { minimumIntegerDigits: digits, useGrouping: false }) || '';

  const updateHours = (value: string, updateStateFn: Dispatch<SetStateAction<string>>) => {
    let updatedValue = value;

    if (Number(updatedValue) < 0 || Number(updatedValue) > MAX_HOURS) return;

    if (updatedValue.length <= 1 && Number(updatedValue[0]) > MAX_FIRST_HOURS_NUMBER) {
      updatedValue = `0${updatedValue}`;
      minutesRef.current?.focus();
    }

    updateStateFn(updatedValue);

    if (updatedValue.length === 2 && Number(updatedValue) <= MAX_HOURS) {
      minutesRef.current?.focus();
    }
  };

  const updateMinutes = (value: string, updateStateFn: Dispatch<SetStateAction<string>>) => {
    let updatedValue = value;

    if (Number(updatedValue) < 0) return;

    if (updatedValue.length <= 1 && Number(updatedValue[0]) > MAX_FIRST_MINUTES_OR_SECONDS_NUMBER) {
      updatedValue = `0${updatedValue}`;
      secondsRef.current?.focus();
    }

    updateStateFn(updatedValue);

    if (updatedValue.length === 2 && Number(updatedValue) <= MAX_MINUTES_OR_SECONDS) {
      secondsRef.current?.focus();
    }
  };

  const updateSeconds = (value: string, updateStateFn: Dispatch<SetStateAction<string>>) => {
    let updatedValue = value;

    if (Number(updatedValue) < 0) return;

    if (updatedValue.length <= 1 && Number(updatedValue[0]) > MAX_FIRST_MINUTES_OR_SECONDS_NUMBER) {
      updatedValue = `0${updatedValue}`;
    }

    updateStateFn(updatedValue);

    if (updatedValue.length === 2 && Number(updatedValue) <= MAX_MINUTES_OR_SECONDS) {
      secondsRef.current?.blur();
    }
  };

  const updateOnBlur = (value: string, updateStateFn: Dispatch<SetStateAction<string>>) => {
    if (value.length === 1) {
      updateStateFn(`0${value}`);
    }
  };

  return {
    hoursRef,
    minutesRef,
    secondsRef,
    updateHours,
    updateMinutes,
    updateSeconds,
    updateOnBlur,
    getStringWithRequiredDigits,
  };
};
