import { FC, memo } from 'react';
import cx from 'classnames';

type DagActionPlaceholderProps = {
  placeholder?: string;
  className?: string;
};

export const DagActionPlaceholder: FC<DagActionPlaceholderProps> = memo(
  ({ className, placeholder = 'Please select a node or line to interact with them.' }) => (
    <p className={cx(className, 'm-0 fs-13 lh-16 text-center')}>{placeholder}</p>
  )
);

DagActionPlaceholder.displayName = 'DagActionPlaceholder';
