import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GridDataStateType } from 'store/types';

const initialState: GridDataStateType = {
  isGridMaximized: false,
  gridMaximizedId: '',
};

const GridDataSlice = createSlice({
  name: 'gridData',
  initialState: initialState,
  reducers: {
    setIsGridMaximized(state, action: PayloadAction<{ maximized: boolean; gridId?: string }>) {
      state.gridMaximizedId = action.payload.maximized && action.payload.gridId ? action.payload.gridId : '';
      state.isGridMaximized = action.payload.maximized;
    },
  },
});

export const GridDataSliceReducer = GridDataSlice.reducer;
export const { setIsGridMaximized } = GridDataSlice.actions;
