import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import cx from 'classnames';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnInstance } from 'react-table';

import { DataType } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Button } from 'shared/Button';
import { KeyboardCode } from 'utils';

import styles from './styles.module.scss';
import { runLocalSearch } from './utils/helpers';

type FilterDropdownProps = {
  handleClose: () => void;
  columns: ColumnInstance<DataType>[];
};

export const FilterDropdown = ({ handleClose, columns: allColumns }: FilterDropdownProps) => {
  const { t } = useTranslation();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const [innerColumns, setInnerColumns] = useState<ColumnInstance<DataType>[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [isAllColumnsChecked, setIsAllColumnsChecked] = useState(false);

  const columns = useMemo(() => allColumns.filter((column) => !column.suppressHideColumn), [allColumns]);

  const hiddenColumnsIds = useMemo(
    () => columns.filter((column) => !column.isVisible).map((column) => column.id),
    [columns]
  );

  useEffect(() => {
    setInnerColumns(columns);
    setHiddenColumns(hiddenColumnsIds);

    return () => setHiddenColumns([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumnsIds]);

  useEffect(() => {
    switch (true) {
      case !hiddenColumns.length:
        checkboxRef.current!.indeterminate = false;
        setIsAllColumnsChecked(true);
        break;
      case hiddenColumns.length === columns.length:
        checkboxRef.current!.indeterminate = false;
        setIsAllColumnsChecked(false);
        break;

      default:
        checkboxRef.current!.indeterminate = true;
        setIsAllColumnsChecked(true);
    }
  }, [hiddenColumns, columns]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      (event.code as KeyboardCode) === KeyboardCode.Escape && handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleDropdownItemClick =
    (columnId = '') =>
    () => {
      switch (true) {
        case !columnId:
          return;
        case hiddenColumns.includes(columnId):
          setHiddenColumns(hiddenColumns.filter((item) => item !== columnId));
          break;
        default:
          setHiddenColumns([...hiddenColumns, columnId]);
      }
    };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredColumns = runLocalSearch(columns, value);
    setInputValue(value);
    setInnerColumns(filteredColumns);
  };

  const handleClearInput = () => {
    setInputValue('');
    setInnerColumns(columns);
  };

  const handleCancelClick = () => {
    setHiddenColumns(hiddenColumnsIds);
    handleClose();
  };

  const handleApplyClick = () => {
    columns.forEach((column) => {
      const isHidden = hiddenColumns.includes(column.id);
      column.toggleHidden(isHidden);
    });
    handleClose();
  };

  const isDropdownItemChecked = (columnId?: string) => !hiddenColumns.includes(columnId!);

  const handleToggleAllCheckbox = () => {
    switch (true) {
      case isAllColumnsChecked:
        setHiddenColumns(columns.map((column) => column.id));
        setIsAllColumnsChecked(false);
        break;

      default:
        setHiddenColumns([]);
        setIsAllColumnsChecked(true);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center p-2">
        <Input
          innerRef={checkboxRef}
          type="checkbox"
          onChange={handleToggleAllCheckbox}
          checked={isAllColumnsChecked}
          className={cx('mt-0 me-2 flex-shrink-0')}
        />
        <div className={cx('position-relative d-flex align-items-center', styles.inputContainer)}>
          <Icon clickable={false} SvgIcon={APP_ICONS.search} className="position-absolute ms-2" />
          <Input
            placeholder={t('grid.columnFilter.filterPlaceholder') || ''}
            value={inputValue}
            onChange={handleChangeInput}
            className={cx(
              `w-100 bg-filter-input-backgrund fs-13 py-1 pe-${inputValue ? '4_25' : '2'} ps-4_25 text-text1 c-pointer`,
              styles.input
            )}
          />
          {inputValue ? (
            <Icon
              SvgIcon={APP_ICONS.cancelInCirlce}
              className="position-absolute end-0 me-2"
              onClick={handleClearInput}
              headerCellClear
            />
          ) : null}
        </div>
      </div>
      <div className={styles.dropdownList}>
        {innerColumns.map((column) => (
          <div
            key={column.id}
            tabIndex={0}
            role="button"
            className={cx('d-flex align-items-center c-pointer', styles.dropdownMenuItem)}
            onKeyDown={handleDropdownItemClick(column.id)}
            onClick={handleDropdownItemClick(column.id)}
          >
            <Input
              readOnly
              type="checkbox"
              className="mt-0 me-2 c-pointer"
              checked={isDropdownItemChecked(column.id)}
            />
            <span>{column.render('Header')}</span>
          </div>
        ))}
      </div>
      <div className="mx-3 mt-2 mb-3 d-flex justify-content-end">
        <Button title={t('buttons.cancel')} variant="secondary" classname="me-3" onClick={handleCancelClick} />
        <Button title={t('buttons.apply')} onClick={handleApplyClick} />
      </div>
    </>
  );
};
