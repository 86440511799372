import { useCallback, useState } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import { ColumnInstance } from 'react-table';
import cx from 'classnames';

import { DataType } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { UiDropdownMenu } from 'ui';

import { FilterDropdown } from './components/FilterDropdown';
import styles from './styles.module.scss';

type FilterCellProps = {
  columns: ColumnInstance<DataType>[];
};

export const FilterCell = ({ columns }: FilterCellProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const handleToggle = useCallback(() => {
    setIsDropdownOpened((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => setIsDropdownOpened(false), []);

  return (
    <>
      <div className={cx('c-pointer bg-grid-filter-cell-background', styles.container)}>
        <Dropdown className="d-flex h-100 w-100" isOpen={isDropdownOpened} toggle={handleToggle}>
          <DropdownToggle className="d-flex flex-grow-1" tag="div">
            <Icon
              className="h-100 w-100 justify-content-center"
              SvgIcon={APP_ICONS.filter}
              color="text-grid-filter-cell-icon"
            />
          </DropdownToggle>
          <UiDropdownMenu className={styles.dropdownMenu}>
            <FilterDropdown columns={columns} handleClose={handleClose} />
          </UiDropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};
