import { ComponentPropsWithRef, ElementType } from 'react';

import addIcon from 'assets/icons/add.svg';
import alightCenterIcon from 'assets/icons/alight-center.svg';
import alignLeftIcon from 'assets/icons/align-left.svg';
import alignRightIcon from 'assets/icons/align-right.svg';
import applyLargeIcon from 'assets/icons/apply-large.svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import arrowRightIcon from 'assets/icons/arrow-right.svg';
import arrowLeftIcon from 'assets/icons/arrow-left.svg';
import attachIcon from 'assets/icons/attach.svg';
import bellIcon from 'assets/icons/bell.svg';
import bigCloseIcon from 'assets/icons/big-close.svg';
import boldIcon from 'assets/icons/bold.svg';
import burgerLargeIcon from 'assets/icons/burger-large.svg';
import burgerIcon from 'assets/icons/burger.svg';
import calendarIcon from 'assets/icons/calendar.svg';
import chatAboutIcon from 'assets/icons/chat-about.svg';
import cancelInCirlceIcon from 'assets/icons/cancel-in-circle.svg';
import clearIcon from 'assets/icons/clear.svg';
import clearTextIcon from 'assets/icons/clear-text.svg';
import clockIcon from 'assets/icons/clock.svg';
import closeIcon from 'assets/icons/close.svg';
import connectToDatabaseIcon from 'assets/icons/connect-to-database.svg';
import copyRecordIcon from 'assets/icons/copy-record.svg';
import copyIcon from 'assets/icons/copy.svg';
import crossSmallIcon from 'assets/icons/cross-small.svg';
import crossIcon from 'assets/icons/cross.svg';
import datasetIcon from 'assets/icons/dataset.svg';
import deleteIcon from 'assets/icons/delete.svg';
import deleteForeverIcon from 'assets/icons/delete-forever.svg';
import downloadIcon from 'assets/icons/download.svg';
import doubleArrowDownIcon from 'assets/icons/double-arrow-down.svg';
import excelIcon from 'assets/icons/excel.svg';
import fillArrowDownIcon from 'assets/icons/fill-arrow-down.svg';
import filterIcon from 'assets/icons/filter.svg';
import goToArrayIcon from 'assets/icons/go-to-array.svg';
import goToTypeIcon from 'assets/icons/go-to-type.svg';
import goToObjectIcon from 'assets/icons/go-to-object.svg';
import handlerIcon from 'assets/icons/handler.svg';
import historyIcon from 'assets/icons/history.svg';
import italicIcon from 'assets/icons/italic.svg';
import justifIcon from 'assets/icons/justif.svg';
import keyIcon from 'assets/icons/key.svg';
import keyMediumIcon from 'assets/icons/key-medium.svg';
import maximizeIcon from 'assets/icons/maximize.svg';
import minimizeIcon from 'assets/icons/minimize.svg';
import newRecordIcon from 'assets/icons/new-record.svg';
import noSortIcon from 'assets/icons/no-sort.svg';
import numberingListIcon from 'assets/icons/numbering-list.svg';
import openExcelAddinIcon from 'assets/icons/open-excel-addin.svg';
import openJsonIcon from 'assets/icons/open-json.svg';
import pinIcon from 'assets/icons/pin.svg';
import refreshIcon from 'assets/icons/refresh.svg';
import runIcon from 'assets/icons/run.svg';
import savePermanentlyIcon from 'assets/icons/save-permanently.svg';
import scrollAutomaticallyIcon from 'assets/icons/scroll-automatically.svg';
import searchIcon from 'assets/icons/search.svg';
import sendLogsIcon from 'assets/icons/send-logs.svg';
import sortAZIcon from 'assets/icons/sort-az.svg';
import sortZAIcon from 'assets/icons/sort-za.svg';
import systemIcon from 'assets/icons/system.svg';
import underlineIcon from 'assets/icons/underline.svg';
import warningSmallIcon from 'assets/icons/warning-small.svg';
import warningIcon from 'assets/icons/warning.svg';
import successMediumIcon from 'assets/icons/success-medium.svg';
import serverErrorMediumIcon from 'assets/icons/server-error-medium.svg';
import warningMediumIcon from 'assets/icons/warning-medium.svg';
import userIcon from 'assets/icons/user.svg';
import userSettingsIcon from 'assets/icons/user-settings.svg';
import signOutIcon from 'assets/icons/sign-out.svg';
import themeIcon from 'assets/icons/theme.svg';
import toBeginIcon from 'assets/icons/tobegin.svg';
import languageIcon from 'assets/icons/language.svg';
import buildIcon from 'assets/icons/build.svg';
import moreIcon from 'assets/icons/more.svg';
import addCopyRecord from 'assets/icons/add-copy-record.svg';
import addNewRecord from 'assets/icons/add-new-record.svg';
// ToDo: delete duplicate icons
import infoIcon from 'assets/icons/info.svg';
import info2Icon from 'assets/icons/info2.svg';
import info3Icon from 'assets/icons/info3.svg';
import infoMediumIcon from 'assets/icons/info-medium.svg';
import sendIcon from 'assets/icons/send.svg';
import lockIcon from 'assets/icons/lock.svg';
import startNewChatIcon from 'assets/icons/start-new-chat.svg';
import chatIcon from 'assets/icons/chat.svg';
import chatBigIcon from 'assets/icons/chat-big.svg';
import maskIcon from 'assets/icons/mask.svg';
import cancelRed from 'assets/icons/cancel-red.svg';
import fileIcon from 'assets/icons/file.svg';
import queryIcon from 'assets/icons/query.svg';
import refreshArrows from 'assets/icons/refresh-arrows.svg';
import nextArrow from 'assets/icons/next-arrow.svg';
import prevArrow from 'assets/icons/prev-arrow.svg';
import containsIcon from 'assets/icons/contains.svg';
import endsWithIcon from 'assets/icons/ends-with.svg';
import startsWithIcon from 'assets/icons/starts-with.svg';
import equalsIcon from 'assets/icons/equals.svg';
import notEqualsIcon from 'assets/icons/not-equals.svg';
import lessThanIcon from 'assets/icons/less-than.svg';
import lessThanOrEqualsIcon from 'assets/icons/less-than-or-equals.svg';
import greaterThanOrEqualsIcon from 'assets/icons/greater-than-or-equals.svg';
import greaterThanIcon from 'assets/icons/greater-than.svg';
import resizeIcon from 'assets/icons/resize.svg';
import playIcon from 'assets/icons/play.svg';
import goBackIcon from 'assets/icons/go-back.svg';
import visibilityOnIcon from 'assets/icons/visibility-on.svg';
import visibilityOffIcon from 'assets/icons/visibility-off.svg';
import developLogoIcon from 'assets/icons/develop-logo.svg';
import oktaLogoIcon from 'assets/icons/okta-logo.svg';
import windowsLogoIcon from 'assets/icons/windows-logo.svg';
import cancelHandlersIcon from 'assets/icons/cancel-handlers.svg';
import multiLineEditIcon from 'assets/icons/multiline-edit.svg';
import doubleTriangle from 'assets/icons/double-triangle.svg';
import multipleCheckboxIcon from 'assets/icons/multiple-checkbox.svg';
import undoIcon from 'assets/icons/undo.svg';
import saveAsIcon from 'assets/icons/save-as.svg';
import sendLetterIcon from 'assets/icons/send-letter.svg';
import chatUserIcon from 'assets/icons/chat-user-icon.svg';
import whiteThemeUserIcon from 'assets/icons/user-icon.svg';
import clearFilter from 'assets/icons/clear-filter.svg';
import biggerClose from 'assets/icons/bigger-close.svg';
import snowflakeIcon from 'assets/icons/snowflake.svg';
import paperClip from 'assets/icons/paper-clip.svg';
import insertRow from 'assets/icons/insert-row.svg';
import moveUp from 'assets/icons/move-up.svg';
import moveDown from 'assets/icons/move-down.svg';
import deleteCrossed from 'assets/icons/delete-crossed.svg';
import toBeginIcon2 from 'assets/icons/tobegin2.svg';
import disquete from 'assets/icons/disquete.svg';
import cancelBtn from 'assets/icons/cancel-btn-icon.svg';
import speechBubbleIcon from 'assets/icons/speech-bubble.svg';
import mailIcon from 'assets/icons/mail.svg';

export const APP_ICONS: Record<string, ElementType<ComponentPropsWithRef<'svg'>>> = {
  add: addIcon,
  alightCenter: alightCenterIcon,
  alignLeft: alignLeftIcon,
  alignRight: alignRightIcon,
  applyLarge: applyLargeIcon,
  arrowDown: arrowDownIcon,
  arrowRight: arrowRightIcon,
  arrowLeft: arrowLeftIcon,
  attach: attachIcon,
  bell: bellIcon,
  bigClose: bigCloseIcon,
  bold: boldIcon,
  build: buildIcon,
  burger: burgerIcon,
  burgerLarge: burgerLargeIcon,
  calendar: calendarIcon,
  chatAbout: chatAboutIcon,
  cancelInCirlce: cancelInCirlceIcon,
  cancelRed: cancelRed,
  clear: clearIcon,
  clearText: clearTextIcon,
  clock: clockIcon,
  close: closeIcon,
  connectToDatabase: connectToDatabaseIcon,
  copy: copyIcon,
  copyRecord: copyRecordIcon,
  cross: crossIcon,
  crossSmall: crossSmallIcon,
  dataset: datasetIcon,
  download: downloadIcon,
  doubleArrowDown: doubleArrowDownIcon,
  excel: excelIcon,
  delete: deleteIcon,
  deleteForever: deleteForeverIcon,
  fillArrowDown: fillArrowDownIcon,
  filter: filterIcon,
  goToArray: goToArrayIcon,
  goToType: goToTypeIcon,
  goToObject: goToObjectIcon,
  handler: handlerIcon,
  history: historyIcon,
  italic: italicIcon,
  justif: justifIcon,
  key: keyIcon,
  keyMedium: keyMediumIcon,
  language: languageIcon,
  maximize: maximizeIcon,
  minimize: minimizeIcon,
  newRecord: newRecordIcon,
  noSort: noSortIcon,
  numberingList: numberingListIcon,
  openExcelAddin: openExcelAddinIcon,
  openJson: openJsonIcon,
  pin: pinIcon,
  refresh: refreshIcon,
  run: runIcon,
  savePermanently: savePermanentlyIcon,
  scrollAutomatically: scrollAutomaticallyIcon,
  search: searchIcon,
  send: sendIcon,
  sendLogs: sendLogsIcon,
  sendLetter: sendLetterIcon,
  sortAZ: sortAZIcon,
  sortZA: sortZAIcon,
  system: systemIcon,
  underline: underlineIcon,
  warning: warningIcon,
  warningSmall: warningSmallIcon,
  successsMedium: successMediumIcon,
  serverErrorMedium: serverErrorMediumIcon,
  speechBubble: speechBubbleIcon,
  warningMedium: warningMediumIcon,
  theme: themeIcon,
  user: userIcon,
  userSettings: userSettingsIcon,
  signOut: signOutIcon,
  more: moreIcon,
  info: infoIcon,
  info2: info2Icon,
  info3: info3Icon,
  infoMedium: infoMediumIcon,
  lock: lockIcon,
  startNewChat: startNewChatIcon,
  chat: chatIcon,
  chatBig: chatBigIcon,
  mask: maskIcon,
  file: fileIcon,
  query: queryIcon,
  refreshArrows: refreshArrows,
  nextArrow: nextArrow,
  prevArrow: prevArrow,
  prevDoubleArrow: toBeginIcon,
  contains: containsIcon,
  endsWith: endsWithIcon,
  startsWith: startsWithIcon,
  equals: equalsIcon,
  notEqual: notEqualsIcon,
  lessThan: lessThanIcon,
  lessThanOrEquals: lessThanOrEqualsIcon,
  greaterThanOrEquals: greaterThanOrEqualsIcon,
  greaterThan: greaterThanIcon,
  resize: resizeIcon,
  play: playIcon,
  goBack: goBackIcon,
  visibilityOn: visibilityOnIcon,
  visibilityOff: visibilityOffIcon,
  developLogo: developLogoIcon,
  oktaLogo: oktaLogoIcon,
  windowsLogo: windowsLogoIcon,
  cancelHandlers: cancelHandlersIcon,
  multiLineEdit: multiLineEditIcon,
  doubleTriangle: doubleTriangle,
  multipleCheckbox: multipleCheckboxIcon,
  undo: undoIcon,
  saveAs: saveAsIcon,
  chatUserIcon: chatUserIcon,
  whiteThemeUserIcon: whiteThemeUserIcon,
  clearFilter: clearFilter,
  biggerClose: biggerClose,
  paperClip: paperClip,
  insertRow: insertRow,
  moveUp: moveUp,
  moveDown: moveDown,
  deleteCrossed: deleteCrossed,
  toBeginIcon2: toBeginIcon2,
  disquete: disquete,
  cancelBtn: cancelBtn,
  addCopyRecord: addCopyRecord,
  addNewRecord: addNewRecord,
  snowflake: snowflakeIcon,
  mail: mailIcon,
};
