import { FC, ReactNode } from 'react';
import cx from 'classnames';
import './UiModalFooter.scss';

export type UiModalFooterProps = {
  className?: string;
  children: ReactNode;
};

export const UiModalFooter: FC<UiModalFooterProps> = ({ children, className }) => (
  <div className={cx('bg-ui-modal-footer ui-modal-footer gap-2_5', className)}>{children}</div>
);
