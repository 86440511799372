import { FC, memo } from 'react';

import { EditorDag } from 'view/EditorData/EditorDag';

import type { DAGEdgeType } from '../../types';

type DagEdgeFormProps = {
  type: string;
  selectedRowKey: string;
  initialValues?: DAGEdgeType;
  onSubmit: (updatedNode: DAGEdgeType) => void;
  onCancel: () => void;
};

export const DagEdgeForm: FC<DagEdgeFormProps> = memo(({ type, selectedRowKey, initialValues, onCancel, onSubmit }) => (
  <EditorDag
    editorData={initialValues}
    recordKey={selectedRowKey}
    recordType={type}
    onCancel={onCancel}
    onUpdate={(data) => onSubmit(data as DAGEdgeType)}
  />
));

DagEdgeForm.displayName = 'DagEdgeForm';
