import { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';

import { useAppNavigate } from 'router/hooks';
import { AppFallbackSkeleton } from 'shared/Skeleton/AppFallbackSkeleton';
import { env } from 'env';

import { setupInterceptors } from '../axios';

type InjectInterceptorsProps = PropsWithChildren;

// ToDo: Refine inject Interceptors. Figured out do we really need it.
export const InjectInterceptorsGuard: FC<InjectInterceptorsProps> = ({ children }) => {
  const { defaultTheme } = env;
  const navigate = useAppNavigate();
  const [isSetup, setSetup] = useState(false);

  useEffect(() => {
    document.documentElement.setAttribute('theme', defaultTheme);
    setupInterceptors(navigate);
    setSetup(true);
  }, []);

  if (!isSetup) {
    return <AppFallbackSkeleton skipContentLoading />;
  }

  return <Fragment>{children}</Fragment>;
};
