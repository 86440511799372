import { FC } from 'react';

import { TabItem } from 'shared/Tabs/components/TabItem';
import { ModelSwitcher } from 'shared/ModelSwitcher';

import styles from './styles.module.scss';

export type NewChatTabsProps = {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
};

export const NewChatTabs: FC<NewChatTabsProps> = ({ tabs, activeTab, onTabChange }) => (
  <nav className={styles.tabs}>
    {tabs?.map((label) => (
      <div key={label} className="border-end border-bottom border-gray-100">
        <TabItem
          key={label}
          isActive={activeTab === label}
          label={label}
          role="user-profile-item"
          onClick={() => onTabChange(label)}
          isCloseable={false}
        />
      </div>
    ))}
    <div className="mx-auto">
      <ModelSwitcher />
    </div>
  </nav>
);
