import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { isNullable, isNumber, isString } from 'utils';

import styles from './styles.module.scss';

type BadgeProps = {
  content: number | string;
  children?: ReactNode;
  className?: string;
  maxLength?: number;
};

export const Badge: FC<BadgeProps> = ({ content, children, className, maxLength = 3 }) => {
  if (isNullable(content)) {
    return <>{children}</>;
  }

  const truncateBadgeContent = (): BadgeProps['content'] => {
    if (content.toString().length <= maxLength) {
      return content;
    }

    if (isNumber(content)) {
      return `${'9'.repeat(maxLength)}+`;
    }

    if (isString(content)) {
      return `${content.toString().slice(0, maxLength)}..`;
    }

    return content;
  };

  return (
    <div className={cx(styles.uiBadgeRoot)}>
      {children}
      <span className={cx(styles.uiBadge, className)}>{truncateBadgeContent()}</span>
    </div>
  );
};
