import { LogsLevelEnum } from 'view/Logs/utils/types';

export const dropdownFilterItemStyle = 'd-flex align-items-center bg-gray-500 px-2 text-text1 fs-13';

export const initLogsFilters = [
  LogsLevelEnum.NOTSET,
  LogsLevelEnum.DEBUG,
  LogsLevelEnum.INFO,
  LogsLevelEnum.WARNING,
  LogsLevelEnum.WARN,
  LogsLevelEnum.ERROR,
  LogsLevelEnum.FATAL,
  LogsLevelEnum.CRITICAL,
];

export const logsFiltersListItems = [
  {
    id: 1,
    title: 'logsFilter.notSet',
    value: LogsLevelEnum.NOTSET,
  },
  {
    id: 2,
    title: 'logsFilter.debug',
    value: LogsLevelEnum.DEBUG,
  },
  {
    id: 3,
    title: 'logsFilter.info',
    value: LogsLevelEnum.INFO,
  },
  {
    id: 4,
    title: 'logsFilter.warning',
    value: LogsLevelEnum.WARNING,
  },
  {
    id: 5,
    title: 'logsFilter.warn',
    value: LogsLevelEnum.WARN,
  },
  {
    id: 6,
    title: 'logsFilter.error',
    value: LogsLevelEnum.ERROR,
  },
  {
    id: 7,
    title: 'logsFilter.fatal',
    value: LogsLevelEnum.FATAL,
  },
  {
    id: 8,
    title: 'logsFilter.critical',
    value: LogsLevelEnum.CRITICAL,
  },
];
