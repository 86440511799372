import { FC } from 'react';
import cx from 'classnames';

import { PdfHighlightType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';
import { PDF_HIGHLIGHT_CLASSNAME } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/components/PDFPage/utils/constants';

import styles from './PDFHighlight.module.scss';

type PdfHighlightProps = PdfHighlightType & { index: number; isActive: boolean };

export const PdfHighlight: FC<PdfHighlightProps> = ({ isActive, index, left, top, height, width }) => (
  <div
    data-index={index}
    style={{ width, height, left, top }}
    className={cx('position-absolute rounded', styles.highlight, PDF_HIGHLIGHT_CLASSNAME, {
      [styles.active]: isActive,
    })}
  />
);
