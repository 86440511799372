import cx from 'classnames';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { SelectedGridRowType } from 'store/types/gridDataTypes';
import { GridTabDataType } from 'store/types';
import { EditorTabContent } from 'view/EditorContent/EditorTabContent';
import { TransitionRecordType } from 'view/EditorContent/utils/types';
import { EDITOR_TABS, EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { useAppContext } from 'context';
import { getParsedConfig } from 'utils/helpers/getParsedConfig.helper';

import { EditorUpdateType } from 'view/EditorData/type';

import { DraggableGridTabs } from './DraggableGridTabs';
import { DropBox } from '../DropBox';
import { DropBoxEnum, GridTabEnum } from '../utils/constants';
import { ConfigType, LayoutType } from '../utils/types';
import { findParent } from '../utils/helpers/findParent';
import { DnDContext } from '../DraggableContainer';
import { findTarget } from '../utils/helpers/findTarget';
import { correctContent } from '../utils/helpers/correctContent';

export type DraggableGridContainerProps = {
  selectedGridRow: SelectedGridRowType;
  tabs?: GridTabDataType[];
  stackRef: ConfigType;
  type: string;
  showToolbar?: boolean;
  children: ReactNode;
  onUpdate?: (data?: EditorUpdateType) => void;
};

export const DraggableGridContainer: FC<DraggableGridContainerProps> = ({
  selectedGridRow,
  tabs,
  stackRef,
  type,
  showToolbar = true,
  children,
  onUpdate,
}) => {
  const { config, setConfig, isDragging, saveLayout } = useContext(DnDContext);
  const { action } = useAppContext();

  useEffect(() => {
    action.setMainGridStackContentLengthAction(stackRef.content.length);
  }, [stackRef.content.length]);

  const [successorType, setSuccessorType] = useState<string>();

  const onApplySuccessorType = (successor: string) => {
    setSuccessorType(successor);
  };

  const onChangeTab = (tab: GridTabDataType) => {
    const configCopy = JSON.parse(JSON.stringify(config)) as ConfigType;

    const parent = findParent(configCopy, tab.key);

    if (parent) {
      ((parent as ConfigType).content as GridTabDataType[]).forEach((configTab) => {
        const tabCopy = configTab;

        tabCopy.isCurrentlyDragging = false;
        tabCopy.isActive = tabCopy.key === tab.key;
      });

      setConfig(configCopy);

      const simplifiedLayout = getParsedConfig(configCopy, true) as LayoutType;

      saveLayout(simplifiedLayout);
    }
  };

  const onSetTabs = (updatedTabs: GridTabDataType[]) => {
    const configCopy = JSON.parse(JSON.stringify(config)) as ConfigType;

    const target = findTarget(configCopy, stackRef.key);

    if (target) {
      target.content = updatedTabs;
    }

    const correctedConfig = correctContent(configCopy, true) as ConfigType;

    setConfig(configCopy);

    const simplifiedLayout = getParsedConfig(correctedConfig, true) as LayoutType;

    saveLayout(simplifiedLayout);
  };

  const handleTransition = (data: TransitionRecordType) => {
    const configCopy = structuredClone(config);
    const tabId = `${EditorTabsEnum.EDITOR_TRANSITION}-${data.key}-${uuid()}`;

    const target = findTarget(configCopy, stackRef.key);
    if (target) {
      const isTransitionTabDuplicate = target.content.some((tab) => tab.id.includes(data.key));

      target.content = (target.content as GridTabDataType[]).map((tab) => ({
        ...tab,
        isActive: isTransitionTabDuplicate && tab.id.includes(data.key),
      }));

      if (!isTransitionTabDuplicate) {
        target.content.push({
          ...EDITOR_TABS[EditorTabsEnum.EDITOR_TRANSITION],
          id: tabId,
          key: tabId,
          disableActions: true,
          label: data.key,
          transitionData: data,
          isActive: true,
        });
      }

      const correctedConfig = correctContent(configCopy, true) as ConfigType;
      setConfig(correctedConfig);

      const simplifiedLayout = getParsedConfig(correctedConfig, true) as LayoutType;

      saveLayout(simplifiedLayout);
    }
  };

  const findActiveTab = (gridTabs: GridTabDataType[]) =>
    gridTabs?.find((tab) => tab.isActive && !tab.isCurrentlyDragging);
  const activeTab = findActiveTab(tabs || []);

  return (
    <div className="w-100 d-flex flex-column h-100 overflow-x-hidden overflow-y-hidden">
      <div className={cx({ 'd-none': stackRef.content.length === 1 && !isDragging })}>
        <DraggableGridTabs
          tabs={tabs || []}
          onChangeTab={onChangeTab}
          onSetTabs={onSetTabs}
          activeTab={activeTab?.key}
          stackRef={stackRef}
        />
      </div>
      <div className="stack-content d-flex flex-column h-100 position-relative">
        {activeTab?.key === GridTabEnum.MAIN ? (
          <>{children}</>
        ) : (
          // It was an EditorContext earlier. If no bugs reported - remove comment
          <>
            <DropBox position={DropBoxEnum.INNER} stackRef={stackRef} />
            <EditorTabContent
              showToolbar={showToolbar}
              activeTab={activeTab?.key}
              transitionTab={activeTab?.transitionData}
              type={type || ''}
              selectedGridRow={selectedGridRow}
              successorType={successorType}
              onApplySuccessorType={onApplySuccessorType}
              onTransition={handleTransition}
              onUpdate={onUpdate}
            />
          </>
        )}
      </div>
    </div>
  );
};
