import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { UIButton, UIButtonVariantType, UiModal, UiModalProps } from 'ui';

import { UIModalMethodType } from './type';
import './UIModalConfirm.scss';

export type UIModalConfirmProps = Pick<
  UiModalProps,
  'size' | 'minHeightConstraints' | 'minWidthConstraints' | 'defaultHeight' | 'defaultWidth' | 'onClose'
> & {
  type?: UIModalMethodType;
  icon?: ReactNode;
  title?: string;
  content?: string | ReactNode;
  footerContent?: string | ReactNode;
  okType?: UIButtonVariantType;
  cancelType?: UIButtonVariantType;
  okText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onOk?: () => void;
};

export const UIModalConfirm: FC<UIModalConfirmProps> = ({
  type = UIModalMethodType.Confirm,
  title,
  icon,
  cancelText = 'Cancel',
  okText = 'Ok',
  content,
  size = 'md',
  cancelType = 'secondary',
  okType = 'primary',
  minHeightConstraints = 150,
  minWidthConstraints = 400,
  defaultHeight = 150,
  defaultWidth = 400,
  onCancel,
  onOk,
  onClose,
}) => {
  const iconClasses = cx('ui-modal-confirm__icon', {
    [`ui-modal-confirm__icon-${type}`]: type,
  });

  return (
    <UiModal
      className="ui-modal-confirm"
      isOpen
      title={title}
      onClose={onClose}
      size={size}
      minHeightConstraints={minHeightConstraints}
      minWidthConstraints={minWidthConstraints}
      defaultHeight={defaultHeight}
      defaultWidth={defaultWidth}
    >
      <UiModal.Body>
        <div className="ui-modal-confirm__content">
          <div className={iconClasses}>{icon}</div>
          {content}
        </div>
      </UiModal.Body>
      <UiModal.Footer>
        <div className="ui-modal-confirm__nav">
          <UIButton variant={cancelType} onClick={onCancel}>
            {cancelText}
          </UIButton>
          <UIButton variant={okType} onClick={onOk}>
            {okText}
          </UIButton>
        </div>
      </UiModal.Footer>
    </UiModal>
  );
};
