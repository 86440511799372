import { safePolygon } from '@floating-ui/react';

import { FLOATING_CONTENT_CLASSNAME } from './constants';

type HandleCloseFnType = ReturnType<typeof safePolygon>;

function isScrollable(element: Element): boolean {
  return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
}

export function handleClose() {
  const safePolygonFn = safePolygon();

  const fn: HandleCloseFnType = (options) =>
    function (event: MouseEvent) {
      const floatingElement = options.elements.floating?.querySelector(`.${FLOATING_CONTENT_CLASSNAME}`);
      const isScrollableFloatingElement = floatingElement ? isScrollable(floatingElement) : false;
      if (isScrollableFloatingElement) {
        return safePolygonFn(options)(event);
      }

      return options.onClose();
    };

  fn.__options = safePolygonFn.__options;

  return fn;
}
