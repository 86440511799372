import { ChangeEvent, FC, useRef } from 'react';
import cx from 'classnames';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { UIInput, UIInputProps } from './UIInput.component';
import './UIInputFile.component.styles.scss';

export type UIInputFileProps = Omit<UIInputProps, 'value' | 'type' | 'onChange'> & {
  value?: string;
  multiply?: boolean;
  onChange: (files: File[] | null) => void;
};

export const UIInputFile: FC<UIInputFileProps> = ({ className, value, onChange, multiply = false, ...rest }) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const classes = cx('ui-input-file', className);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(Array.from(e.target.files as ArrayLike<File>));
  };

  const handleClear = () => {
    onChange(null);
  };

  const renderSuffixElement = () => (
    <div className="ui-input-file__nav">
      {value && (
        <button tabIndex={0} className="ui-input-file__nav-item" type="submit" onClick={handleClear}>
          <Icon SvgIcon={APP_ICONS.cancelInCirlce} />
        </button>
      )}
      <button tabIndex={0} className="ui-input-file__nav-item" type="submit" onClick={() => fileRef.current?.click()}>
        <Icon SvgIcon={APP_ICONS.attach} />
      </button>
      <input
        className="ui-input-file__hidden-input"
        type="file"
        ref={fileRef}
        multiple={multiply}
        onChange={handleOnChange}
      />
    </div>
  );

  return <UIInput type="text" className={classes} value={value} suffixElement={renderSuffixElement()} {...rest} />;
};
