import { FC, ReactNode } from 'react';
import cx from 'classnames';
import './UiModalBody.scss';

export type UiModalBodyProps = {
  className?: string;
  children: ReactNode;
};

export const UiModalBody: FC<UiModalBodyProps> = ({ className, children }) => (
  <div className={cx(className, 'ui-modal-body')}>{children}</div>
);
