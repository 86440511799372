import { FC, ReactNode } from 'react';

import { Loader } from 'shared/Loader';
import Logo from 'assets/logos/cl-logo.svg';

type AppFallbackSkeletonProps = {
  message?: string;
  renderContent?: (message?: AppFallbackSkeletonProps['message']) => ReactNode;
  skipContentLoading?: boolean;
};

const defaultRenderContent = (message?: string) => <Loader message={message} />;

export const AppFallbackSkeleton: FC<AppFallbackSkeletonProps> = ({
  message,
  renderContent = defaultRenderContent,
  skipContentLoading = false,
}) => {
  if (skipContentLoading) {
    return null;
  }

  return (
    <div className="d-flex justify-content-start flex-column overflow-auto bg-primary content">
      <div className="px-8px py-6px bg-primary text-white">
        <div className="d-flex align-items-center bg-header-background">
          <div className="me-35px">
            <Logo />
          </div>
        </div>
      </div>
      {renderContent(message)}
    </div>
  );
};
