import { FC } from 'react';

import { UiModal } from 'ui/UiModal';
import { StorageEntityModel } from 'api';
import { EditorHandler } from 'view/EditorData/EditorHandler';

import { HandlerItemType } from '../../types';

type HandlerWithParamsModalProps = {
  schemaType: string;
  selectedRowType?: string;
  selectedHandlerWithParams: HandlerItemType;
  onExecuteHandler: (handler: HandlerItemType, params: StorageEntityModel) => void;
  onClose: () => void;
};

export const HandlerWithParamsModal: FC<HandlerWithParamsModalProps> = ({
  schemaType,
  selectedRowType,
  selectedHandlerWithParams,
  onExecuteHandler,
  onClose,
}) => {
  const handleSubmit = (data: StorageEntityModel) => {
    onExecuteHandler(selectedHandlerWithParams, data);
    onClose();
  };

  return (
    <UiModal isOpen size="lg" defaultHeight={285} title={selectedHandlerWithParams.name} onClose={onClose}>
      <UiModal.Body className="overflow-auto">
        <EditorHandler
          schemaType={schemaType}
          selectedRecordType={selectedRowType}
          partSchemaName={`${selectedHandlerWithParams.id}Args`}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      </UiModal.Body>
    </UiModal>
  );
};
