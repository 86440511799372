export enum Decimals {
  TWO = 2,
  FOUR = 4,
  SIX = 6,
}

export enum OriginalDouble {
  HUNDRED = 100,
  ONE = 1,
  ZERO = 0,
}

export enum Double {
  DOUBLE = 'Double',
}

export enum IntFormatting {
  INT = 'Int',
  NUMBER = 'Number',
}
