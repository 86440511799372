import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { TableToolbar, TableToolbarItemProps } from 'view/Editor/components/EditorView/TableToolbar';
import { EditorTabType, RemoveListDataElementType } from 'view/Editor/context/editorContext/type';
import { useEditorState } from 'view/Editor/hook/editorState.hook';
import { APP_ICONS } from 'utils/icons';
import { isNullable } from 'utils';

type ArrayToolbarProps = {
  tab: EditorTabType;
  arrayLength: number;
  selectedIndex: number;
  selectedGroup?: Record<string, boolean>;
  onSelectedIndexChange: (index: number) => void;
  onSelectedGroupChange?: (index: Record<number, boolean>) => void;
};

export const ArrayToolbar = ({
  arrayLength,
  tab,
  selectedIndex,
  selectedGroup = {},
  onSelectedIndexChange,
  onSelectedGroupChange,
}: ArrayToolbarProps) => {
  const { action } = useEditorState();
  const { t } = useTranslation();
  const { path, selector } = tab;

  const isNoSelectedIndex = isNullable(selectedIndex);
  const isDataEmpty = !arrayLength;

  useEffect(() => {
    if (selectedIndex + 1 > arrayLength && arrayLength > 0) {
      onSelectedIndexChange(arrayLength - 1);
    }
  }, [arrayLength]);

  const getSelectedItems = (): number[] => {
    const selectedGroupArray = Object.keys(selectedGroup);

    if (selectedGroupArray.length > 0) {
      return selectedGroupArray.map((it) => +it);
    }

    return [selectedIndex];
  };

  const clearGroupSelect = () => onSelectedGroupChange?.({});

  const handleAddListElement = () => {
    action.addListElementAction({ path, selector });
  };

  const handleInsertRowElement = () => {
    action.addListElementAction({ path, index: selectedIndex, selector });
  };

  const handleMoveUpElement = () => {
    const toIndex = selectedIndex - 1;
    const isSuccess = action.moveListElementAction({ path, from: selectedIndex, to: toIndex });
    if (isSuccess) {
      onSelectedIndexChange(toIndex);
    }
  };

  const handleMoveDownElement = () => {
    const toIndex = selectedIndex + 1;
    const isSuccess = action.moveListElementAction({ path, from: selectedIndex, to: toIndex });
    if (isSuccess) {
      onSelectedIndexChange(toIndex);
    }
  };

  const handleRemoveListElement = () => {
    action.removeListElementAction({ path, index: getSelectedItems() });
    clearGroupSelect();
  };

  const handleDeleteAllExceptSelected = () => {
    action.removeListElementAction({
      path,
      index: getSelectedItems(),
      removeType: RemoveListDataElementType.AllExceptIndex,
    });
    clearGroupSelect();
  };

  const toolbarItems: TableToolbarItemProps[] = [
    {
      key: 'addElement',
      icon: APP_ICONS.add,
      tooltipContent: t('editor.listToolbar.add'),
      onClick: handleAddListElement,
    },
    {
      key: 'insertRow',
      icon: APP_ICONS.insertRow,
      tooltipContent: t('editor.listToolbar.insertRow'),
      isDisabled: isNoSelectedIndex || isDataEmpty,
      onClick: handleInsertRowElement,
    },
    {
      key: 'moveUp',
      icon: APP_ICONS.moveUp,
      tooltipContent: t('editor.listToolbar.moveRowUp'),
      isDisabled: isNoSelectedIndex || isDataEmpty || selectedIndex === 0,
      onClick: handleMoveUpElement,
    },
    {
      key: 'moveDown',
      icon: APP_ICONS.moveDown,
      tooltipContent: t('editor.listToolbar.moveRowDown'),
      isDisabled: isNoSelectedIndex || isDataEmpty || selectedIndex + 1 === arrayLength,
      onClick: handleMoveDownElement,
    },
    {
      key: 'deleteElement',
      icon: APP_ICONS.delete,
      tooltipContent: t('editor.listToolbar.remove'),
      isDisabled: isNoSelectedIndex || isDataEmpty,
      onClick: handleRemoveListElement,
    },
    {
      key: 'deleteAllExceptSelected',
      icon: APP_ICONS.deleteCrossed,
      tooltipContent: t('editor.listToolbar.deleteAllExceptSelected'),
      isDisabled: isNoSelectedIndex || isDataEmpty || arrayLength <= 1,
      onClick: handleDeleteAllExceptSelected,
    },
  ];

  return <TableToolbar items={toolbarItems} />;
};
