import { QueryOperatorEnum, SchemaTypeElementsModel } from 'api';
import { QueryType, ValueWithConditionType, getTypeBySchema } from 'view/QueryBuilder';

import { QueryBuilderRowType } from './types';

export const mapQueryToRows = (
  query: QueryType,
  schemaDictionary: Record<string, SchemaTypeElementsModel>,
  updateValueByKey: (key: string, value: ValueWithConditionType) => void
): QueryBuilderRowType[] =>
  Object.keys(query).map((key: string) => {
    const valueWithCondition = query[key];
    const schema = schemaDictionary[key];
    const type = getTypeBySchema(schema) as QueryBuilderRowType['type'];

    const onChangeCondition = (changedCondition: QueryOperatorEnum) => {
      if (valueWithCondition.condition === changedCondition) return;
      updateValueByKey(key, { value: valueWithCondition.value, condition: changedCondition });
    };

    const onChangeValue = (changedValue: QueryBuilderRowType['value']) => {
      if (valueWithCondition.value === changedValue) return;
      updateValueByKey(key, { value: changedValue, condition: valueWithCondition.condition });
    };

    return {
      type,
      key,
      isOptional: schema?.Optional ?? false,
      condition: valueWithCondition.condition,
      onChangeCondition,
      value: valueWithCondition.value,
      onChangeValue,
    } as QueryBuilderRowType;
  });
