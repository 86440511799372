import mime from 'mime';

import { handlerApi, SchemaTypeDeclareHandlersModel, storageApi } from 'api';
import { base64ToBytes, getMimeTypeFromBuffer, isNullable } from 'utils';
import { contentHandlersConverter } from 'view/Handlers/api/converters';

import {
  AttachmentContentHandlerModel,
  ContentHandlerAttachmentEnum,
  ContentHandlerModel,
  ContentHandlersModel,
  MessageContentHandlerModel,
} from '../models';
import { ContentHandlerDto } from '../dto/contentHandler.dto';

export type GetContentHandlersParams = {
  type: string;
  key: string;
  dataset?: string;
  environment?: string;
};

export type RunContentHandlerParams = {
  handler: SchemaTypeDeclareHandlersModel;
  type: string;
  key: string;
  dataset?: string;
  environment?: string;
};

const getFilename = (type: string, key: string, extension: string = 'txt'): string => `${type}-${key}.${extension}`;

export type ContentHandlerRequest = {
  getContentHandlers(params: GetContentHandlersParams): Promise<ContentHandlersModel>;
  runContentHandler(params: RunContentHandlerParams): Promise<ContentHandlerModel>;
};

export const contentHandlerApi: ContentHandlerRequest = {
  async getContentHandlers(params: GetContentHandlersParams): Promise<ContentHandlersModel> {
    if (isNullable(params.dataset) || isNullable(params.environment)) {
      throw new Error(`Cannot execute request because one of these parameters is empty: "dataset" or "environment"`);
    }

    const response = await storageApi.getRecord(params);
    const recordSchema = response.schema.typeList[response.schema.rootType];

    return contentHandlersConverter.toDomain(recordSchema.Declare?.Handlers);
  },

  async runContentHandler(params: RunContentHandlerParams): Promise<ContentHandlerModel> {
    if (isNullable(params.dataset) || isNullable(params.environment)) {
      throw new Error(`Cannot execute request because one of these parameters is empty: "dataset" or "environment"`);
    }

    const requestParams = {
      method: params.handler.Name,
      table: params.type,
      key: params.key,
      dataset: params.dataset,
      dataSource: params.environment,
    };

    const response = await handlerApi.run<ContentHandlerDto>(requestParams);

    if (response.Bytes_) {
      const bytes = base64ToBytes(response.Bytes_);
      const mimeType = getMimeTypeFromBuffer(bytes) || 'plain/text';
      const blob = new Blob([bytes], { type: mimeType });
      const extension = mime.getExtension(mimeType) || 'txt';
      const filename = getFilename(requestParams.table, requestParams.key, extension);

      return {
        attachmentType: response.AttachmentType,
        content: new File([blob], filename),
      } as AttachmentContentHandlerModel;
    }

    if (response.BinaryContent) {
      const bytes = base64ToBytes(response.BinaryContent.Content);
      const mimeType = getMimeTypeFromBuffer(bytes) || 'plain/text';
      const blob = new Blob([bytes], { type: mimeType });
      const extension = mime.getExtension(mimeType) || 'txt';
      const filename = getFilename(requestParams.table, requestParams.key, extension);

      return {
        attachmentType: response.AttachmentType,
        content: new File([blob], filename),
      } as AttachmentContentHandlerModel;
    }

    if (response.Instruction) {
      return {
        attachmentType: response.AttachmentType,
        content: response.Instruction,
      } as MessageContentHandlerModel;
    }

    if (response.Text) {
      if (response.AttachmentType === ContentHandlerAttachmentEnum.Attachment) {
        const blob = new Blob([response.Text], { type: 'plain/text' });
        const filename = getFilename(requestParams.table, requestParams.key);
        const file = new File([blob], filename);

        return {
          attachmentType: response.AttachmentType,
          content: file,
        } as AttachmentContentHandlerModel;
      }

      return {
        attachmentType: response.AttachmentType,
        content: response.Text,
      } as MessageContentHandlerModel;
    }

    throw new Error(`Cannot create request handler by handler - ${params.handler.Name}`);
  },
};
