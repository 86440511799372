import { TableResizerProps } from 'react-table';
import cx from 'classnames';

import styles from './styles.module.scss';

interface ColumnResizerProps extends TableResizerProps {
  isResizing: boolean;
  lastEl?: boolean;
}

export const ColumnResizer = ({ isResizing, lastEl = false, ...props }: ColumnResizerProps) => (
  <div
    className={cx('position-absolute py-0 d-inline-block', lastEl ? styles.columnResizerLast : styles.columnResizer)}
    {...props}
  >
    <div
      className={cx('h-100', {
        'bg-gray-100': isResizing,
        'bg-transparent': !isResizing,
      })}
    />
  </div>
);
