import cx from 'classnames';
import type { DropdownMenuProps, DropdownProps } from 'reactstrap';
import { Dropdown, DropdownToggle } from 'reactstrap';
import type { FC, ReactNode } from 'react';

import { useBoolean } from 'utils/hooks';

import { UiDropdownMenu } from './UiDropdownMenu/UiDropdownMenu.component';

export type UiDropdownProps = Omit<DropdownProps, 'toggle'> &
  DropdownMenuProps & {
    toggleButton: ReactNode;
    toggle?: (event: React.MouseEvent) => void;
    renderMenu?: () => ReactNode;
  };

export const UiDropdown: FC<UiDropdownProps> = (props) => {
  const {
    isOpen: controlledOpen,
    toggle: controlledToggle,
    toggleButton,
    children,
    container,
    tag,
    className,
    renderMenu = () => <UiDropdownMenu container={container}>{children}</UiDropdownMenu>,
    ...rest
  } = props;

  const [uncontrolledOpen, { toggle: setUncontrolledOpen }] = useBoolean(false);
  const isOpen = controlledOpen ?? uncontrolledOpen;
  const toggle = controlledToggle ?? setUncontrolledOpen;

  const classes = cx('d-flex align-items-center', className);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className={classes} {...rest}>
      <DropdownToggle tag={tag} data-toggle="dropdown" className="border-0">
        {toggleButton}
      </DropdownToggle>
      {renderMenu()}
    </Dropdown>
  );
};
