import { useEffect, useState, KeyboardEvent } from 'react';
import { Row } from 'react-table';

import { DataType, SelectedRangeCellsType } from 'view/Grid/utils/types';
import { KeyboardCode } from 'utils';

const DEFAULT_MARKED_INDEX = -1;

type UseNavigationOptions = {
  selectedRowId: string | undefined;
  onChangeSelectedRowId: (rowId: string) => void;
};

export function useNavigation({ selectedRowId, onChangeSelectedRowId }: UseNavigationOptions) {
  const [rows, setRows] = useState<Array<Row<DataType>>>([]);
  const [markedIndex, setMarkedIndex] = useState<number>(DEFAULT_MARKED_INDEX);

  const getSelectedRowIndex = () => rows.findIndex((row: Row<DataType>) => row.id === selectedRowId);

  const updateSelectedCells = (
    oldIndex: number,
    newIndex: number,
    options?: {
      selectedCells: SelectedRangeCellsType;
      updateFn: (cells: SelectedRangeCellsType) => void;
    }
  ) => {
    if (!options) {
      return;
    }

    const selectedCellsArr = Object.keys(options.selectedCells);

    const selectedCellsKeys = selectedCellsArr
      .filter((key) => key.includes(String(oldIndex)))
      .map((key) => {
        const selectedCellArgs = key.split('_');

        selectedCellArgs[1] = String(newIndex);

        return selectedCellArgs.join('_');
      });

    const updatedSelectedCells: SelectedRangeCellsType = {};

    selectedCellsKeys.forEach((cellId) => {
      updatedSelectedCells[cellId] = true;
    });

    options?.updateFn(updatedSelectedCells);
  };

  const prevMarkedRow = (options?: {
    selectedCells: SelectedRangeCellsType;
    updateFn: (cells: SelectedRangeCellsType) => void;
  }) => {
    const isDefault = markedIndex === DEFAULT_MARKED_INDEX;
    const currentMarkedIndex = isDefault ? getSelectedRowIndex() : markedIndex;
    const previousIndex = currentMarkedIndex === 0 ? 0 : currentMarkedIndex - 1;

    setMarkedIndex(() => previousIndex);

    updateSelectedCells(markedIndex, previousIndex, options);

    const markedRow = rows.at(previousIndex);

    if (markedRow) {
      onChangeSelectedRowId(markedRow.id);
    }
  };

  const nextMarkedRow = (options?: {
    selectedCells: SelectedRangeCellsType;
    updateFn: (cells: SelectedRangeCellsType) => void;
  }) => {
    const isDefault = markedIndex === DEFAULT_MARKED_INDEX;
    const maxIndex = rows.length - 1;
    const currentMarkedIndex = isDefault ? getSelectedRowIndex() : markedIndex;
    const nextIndex = currentMarkedIndex === maxIndex ? maxIndex : currentMarkedIndex + 1;

    setMarkedIndex(() => nextIndex);

    updateSelectedCells(markedIndex, nextIndex, options);

    const markedRow = rows.at(nextIndex);

    if (markedRow) {
      onChangeSelectedRowId(markedRow.id);
    }
  };

  const resetMarkedRow = () => setMarkedIndex(DEFAULT_MARKED_INDEX);

  const selectMarkedRow = () => {
    const markedRow = rows.at(markedIndex);
    if (!markedRow) return;
    onChangeSelectedRowId(markedRow.id);
  };

  const onKeyDown = <TElement extends HTMLElement>(
    event: KeyboardEvent<TElement>,
    options?: { selectedCells: SelectedRangeCellsType; updateFn: (cells: SelectedRangeCellsType) => void }
  ) => {
    switch (event.key) {
      case KeyboardCode.ArrowUp:
        prevMarkedRow(options);
        break;
      case KeyboardCode.ArrowDown:
        nextMarkedRow(options);
        break;
      case KeyboardCode.Enter:
      case KeyboardCode.NumpadEnter:
        selectMarkedRow();
        break;
      default:
        break;
    }
  };

  const attachRows = (newRows: Array<Row<DataType>>) => {
    setRows(newRows);
  };

  useEffect(() => {
    const selectedRowIndex = getSelectedRowIndex();
    setMarkedIndex(selectedRowIndex);
  }, [selectedRowId, rows]);

  return {
    isDefaultMarkedIndex: markedIndex === DEFAULT_MARKED_INDEX,
    markedIndex,
    prevMarkedRow,
    nextMarkedRow,
    resetMarkedRow,
    onKeyDown,
    attachRows,
  };
}
