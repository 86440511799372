import { FC, memo, useCallback, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';

import { UiModal } from 'ui/UiModal';

import { Disclaimer } from './components/Disclaimer';

type DisclaimerModalProps = {
  isOpen: boolean;
  isOnce: boolean;
  toggleOnce: () => void;
  agree: () => void;
};

export const DisclaimerModal: FC<DisclaimerModalProps> = memo(({ isOpen, isOnce, toggleOnce, agree }) => {
  const [checked, setChecked] = useState<boolean>(isOnce);

  const toggleChecked = useCallback(() => setChecked((prevIsChecked: boolean) => !prevIsChecked), []);

  const agreeHandler = useCallback(() => {
    agree();
    isOnce !== checked && toggleOnce();
  }, [agree, isOnce, checked, toggleOnce]);

  return (
    <UiModal isOpen={isOpen} defaultHeight={350} title="Disclaimer" isClosable={false}>
      <UiModal.Body className="overflow-auto">
        <Disclaimer />
      </UiModal.Body>
      <UiModal.Footer>
        <Label className="d-flex align-items-center gap-2 me-auto my-0 ms-0 c-pointer text-white">
          <Input type="checkbox" checked={checked} onChange={toggleChecked} className="flex-shrink-0 m-0" />
          <span className="user-select-none fs-13 text-text2">Do not show again</span>
        </Label>
        <Button className="c-button-primary border-0 text-text3 fs-14 c-button" onClick={agreeHandler}>
          Agree
        </Button>
      </UiModal.Footer>
    </UiModal>
  );
});

DisclaimerModal.displayName = 'DisclaimerModal';
