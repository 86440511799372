import { AxiosHeaders } from 'axios';

import { isEmpty, isNullable, serialize } from 'utils';

import { StorageRecordDto } from '../dto/storage.dto';
import { SchemaTypeModel } from '../model/schema.model';
import { convertSchemaTypeDtoToModel } from '../converter/schema.converter';
import {
  AppStateModel,
  SettingsRecordModel,
  StorageDatasetModel,
  StorageEnvsModel,
  StorageExportRecordModel,
  StorageItemModel,
  StorageRecordListModel,
  StorageRecordModel,
} from '../model/storage.model';
import {
  RecordSavePermanentlyRequestDto,
  SettingsRecordRequestDto,
  StorageExportRecordRequestDto,
  StorageGetDatasetRequestDto,
  StorageGetEntityRequestDto,
  StorageSelectRequestData,
} from '../data/storage.data';
import { axiosMain } from '../axios';

export type StorageApi = {
  getRecord(params: StorageGetEntityRequestDto): Promise<StorageRecordModel>;
  getTypeViewSettings(params: SettingsRecordRequestDto): Promise<SettingsRecordModel | null>;
  getAppState(key: string): Promise<AppStateModel>;
  getDatasets(data: StorageGetDatasetRequestDto): Promise<StorageDatasetModel[]>;
  getEnvs(): Promise<StorageEnvsModel[]>;
  select(data: StorageSelectRequestData): Promise<StorageRecordListModel>;
  export(params: StorageExportRecordRequestDto): Promise<StorageExportRecordModel>;
  saveRecordPermanently(params: StorageExportRecordRequestDto): Promise<string>;
};

export const storageApi: StorageApi = {
  async getRecord({
    type,
    key,
    dataset,
    ignoreRecordAbsence,
  }: StorageGetEntityRequestDto): Promise<StorageRecordModel> {
    const { data: res } = await axiosMain.get<StorageRecordDto>('/storage/record', {
      params: {
        type,
        key,
        dataset,
        ignore_record_absence: ignoreRecordAbsence,
      },
    });

    return { schema: convertSchemaTypeDtoToModel(res.schema, res.data?._t ?? type), data: res.data };
  },
  async getTypeViewSettings(params: SettingsRecordRequestDto): Promise<SettingsRecordModel | null> {
    return storageApi
      .getRecord({
        type: 'UiTypeState',
        key: `${params.module};${params.type};${params.user}`,
        ignoreRecordAbsence: true,
        module: 'Cl.Runtime.Backend.Core',
      })
      .then(({ data }) => data as SettingsRecordModel | null);
  },
  async getAppState(key: string): Promise<AppStateModel> {
    const params = { type: 'UiAppState', module: 'Cl.Runtime.Backend.Core', ignoreRecordAbsence: true };

    return storageApi.getRecord({ ...params, key }).then((response) => {
      const responseData = response.data as AppStateModel;

      if (key.length && !responseData) {
        return storageApi.getRecord({ ...params, key: '' }).then(({ data }) => data as AppStateModel);
      }
      return responseData;
    });
  },
  async getDatasets(params: StorageGetDatasetRequestDto): Promise<StorageDatasetModel[]> {
    const res = await axiosMain.get<StorageDatasetModel[]>('/storage/get_datasets', {
      params,
    });

    return res.data;
  },
  async getEnvs(): Promise<StorageEnvsModel[]> {
    const res = await axiosMain.get<StorageEnvsModel[]>('/storage/get_envs');

    return res.data;
  },
  async select({ params, dto }: StorageSelectRequestData): Promise<StorageRecordListModel> {
    const dtoWithType = isNullable(dto) || isEmpty(dto) ? undefined : serialize({ _t: `${params.type}Query`, ...dto });
    const res = await axiosMain.post<{ data: StorageItemModel[]; schema: Record<string, SchemaTypeModel> }>(
      '/storage/select',
      dtoWithType,
      {
        headers: { 'Content-Type': 'application/json' },
        params,
      }
    );

    return {
      data: res.data.data,
      schema: convertSchemaTypeDtoToModel(res.data.schema, params.type),
    };
  },

  async export({
    type,
    keys,
    dataset,
    withDependencies,
  }: StorageExportRecordRequestDto): Promise<StorageExportRecordModel> {
    const params = { type, keys, dataset, with_dependencies: withDependencies };
    const response = await axiosMain.post<Blob>('/storage/record/export', params, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer',
    });

    const fileName =
      response.headers instanceof AxiosHeaders ? (response.headers.get('Content-Disposition') as string) : null;

    return { data: response.data, fileName };
  },

  async saveRecordPermanently({
    type,
    keys,
    dataset,
    withDependencies,
  }: RecordSavePermanentlyRequestDto): Promise<string> {
    const params = { type, keys, dataset, with_dependencies: withDependencies };
    const response = await axiosMain.post<string>('/storage/record/save_permanently', params, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer',
    });

    return response.data;
  },
};
