import { useCallback, useMemo, useState } from 'react';

import { StorageKeyEnum } from 'shared/ExternalStorage/utils/types';
import { useLocalStorage } from 'shared/Hooks/useLocalStorage';

export function useDisclaimer() {
  const [isShow, show] = useState<boolean>(true);
  const [isOnce, setOnce] = useLocalStorage<boolean>(StorageKeyEnum.SHOW_DISCLAIMER_ONCE, false);
  const isOpen = useMemo(() => isShow && !isOnce, [isShow, isOnce]);

  const toggleOnce = useCallback(() => setOnce((prevIsOnce: boolean) => !prevIsOnce), [setOnce]);

  return {
    isOpen,
    isOnce,
    toggleOnce,
    show,
  };
}
