import { useCallback, useMemo, useRef, useState } from 'react';
import { ImperativePanelHandle } from 'react-resizable-panels';

export function useCollapsePanels() {
  const [sizes, setSizes] = useState<[number, number]>([50, 50]);
  const chatSize = useMemo(() => sizes[0], [sizes]);

  const contentPanelRef = useRef<ImperativePanelHandle>(null);
  const isMaximized = useMemo(() => chatSize === 100, [chatSize]);

  const onChangeSizes = useCallback((updatedSizes: number[]) => {
    if (updatedSizes.length === 0) return;

    const [firstSize, secondSize] = updatedSizes;
    setSizes([firstSize, secondSize ?? 100 - firstSize]);
  }, []);

  const collapseContentPanel = useCallback(() => {
    const panel = contentPanelRef.current;
    if (!panel) return;

    const size = panel.getCollapsed() ? 50 : 0;
    panel.resize(size);
  }, []);

  return {
    isMaximized,
    contentPanelRef,
    collapseContentPanel,
    onChangeSizes,
  };
}
