import { useEffect, useState } from 'react';

import { RightSplitPaneType } from 'containers/RightSplitPane/utils/types';
import { RightSplitPane } from 'containers/RightSplitPane';
import { useSSEContext } from 'containers/SSEProvider';
import { externalStorage } from 'shared/ExternalStorage';

import { LogsHeader } from './components/LogsHeader';
import { LogsList } from './components/LogsList';
import { LogsEventDataType, LogsLevelEnum, LogsListViewEnum } from './utils/types';
import { useLogsPanel } from './hooks/useLogsPanel';

type LogsProps = RightSplitPaneType;

export const Logs = ({ rightBlockRef, rightBlockWidth, startResizing }: LogsProps) => {
  const { isOpen: isLogsPanelOpen } = useLogsPanel();
  const { logsData } = useSSEContext();

  const [activeListView, setActiveListView] = useState<LogsListViewEnum>(LogsListViewEnum.FLAT_VIEW);
  const [logsListData, setLogsListData] = useState<LogsEventDataType[]>([]);
  const [filteredListData, setFilteredListData] = useState<LogsEventDataType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<LogsLevelEnum[]>(externalStorage.getLogsFilters());

  useEffect(() => {
    if (logsData) {
      setLogsListData([...logsListData, logsData]);
    }
  }, [logsData]);

  useEffect(() => {
    const newLogsList = logsListData.filter(
      (item) =>
        item.Message.toLowerCase().includes(searchValue.toLowerCase()) &&
        activeFilters.includes(item.Level as LogsLevelEnum)
    );
    setFilteredListData(newLogsList);
  }, [searchValue, activeFilters, logsData]);

  const handleChangeListView = (listView: LogsListViewEnum) => setActiveListView(listView);
  const handlerClearLogsList = () => {
    setLogsListData([]);
    setFilteredListData([]);
  };

  const handleChangeActiveFilters = (filters: LogsLevelEnum[]) => {
    setActiveFilters(filters);
  };

  if (!isLogsPanelOpen) return null;

  return (
    <RightSplitPane rightBlockRef={rightBlockRef} rightBlockWidth={rightBlockWidth} startResizing={startResizing}>
      <div className="d-flex flex-column h-100">
        <LogsHeader
          activeListView={activeListView}
          onChangeListView={handleChangeListView}
          searchValue={searchValue}
          onChangeSearchValue={setSearchValue}
          onClearLogsList={handlerClearLogsList}
          onSetActiveFilters={handleChangeActiveFilters}
          activeFilters={activeFilters}
        />
        <LogsList activeListView={activeListView} logsListData={filteredListData} />
      </div>
    </RightSplitPane>
  );
};
