import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  addEdge,
  Connection,
  OnSelectionChangeParams,
  useEdgesState,
  useNodesState,
  OnNodesChange,
  NodeChange,
  OnEdgesChange,
  EdgeChange,
} from 'reactflow';
import { useSelector } from 'react-redux';

import { entityApi, storageApi, StorageRecordModel } from 'api';
import { selectActiveDataset } from 'store/selectors/dateset.selector';
import { useDebounceValue } from 'shared/Hooks/useDebounceValue';

import { DagContext } from '../../contexts';
import { ActionMode, DagDtoType, DAGEdgeType, DAGNodeType, DAGType } from '../../types';
import { mapEdgesToDomain, mapEdgesToDTOs, mapNodesToDomain, mapNodesToDTOs } from '../../utils';

type DagProviderProps = PropsWithChildren<{ type: string; pointer: string; isReadonly: boolean }>;

export const DagProvider: FC<DagProviderProps> = ({ type, pointer, isReadonly, children }) => {
  const dataset = useSelector(selectActiveDataset) as string;

  const isInitiatingRef = useRef<boolean>(true);
  const dagRef = useRef<DAGType>();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selected, setSelected] = useState<OnSelectionChangeParams>({ nodes: [], edges: [] });
  const [actionMode, setActiveMode] = useState<ActionMode>(ActionMode.NONE);
  const [isUpdating, setUpdating] = useState(false);

  const DTOs: DagDtoType = useMemo(
    () => ({
      nodes: mapNodesToDTOs(nodes),
      edges: mapEdgesToDTOs(edges),
    }),
    [nodes, edges]
  );
  const deferredDTOs = useDebounceValue(DTOs);

  const onConnect = useCallback((params: Connection) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const nodesChangeHandler: OnNodesChange = useCallback(
    (changedNodes: NodeChange[]) => {
      if (isReadonly) return;

      onNodesChange(changedNodes);
    },
    [isReadonly]
  );

  const edgesChangeHandler: OnEdgesChange = useCallback(
    (changedEdges: EdgeChange[]) => {
      if (isReadonly) return;

      onEdgesChange(changedEdges);
    },
    [isReadonly]
  );

  const fetchNodes = () => {
    isInitiatingRef.current = true;

    storageApi
      .getRecord({ type, key: pointer, dataset: dataset })
      .then((response: StorageRecordModel) => {
        const data = response.data as DAGType;
        dagRef.current = data;
        setNodes(mapNodesToDomain(data.Nodes));
        setEdges(mapEdgesToDomain(data.Edges));
      })
      .finally(() => {
        isInitiatingRef.current = false;
      });
  };

  const addNode = useCallback(
    (dto: DAGNodeType) => {
      if (isReadonly) return;

      setNodes((prevNodes) => {
        const newNode = {
          id: dto.Id_,
          position: { x: dto.Position.X, y: dto.Position.Y },
          data: { label: dto.Data.Label },
        };
        return prevNodes.concat([newNode]);
      });
    },
    [isReadonly]
  );

  const updateSelectedNode = useCallback(
    (dto: DAGNodeType) => {
      if (isReadonly) return;

      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === dto.Id_) {
            return {
              ...node,
              position: { x: dto.Position.X, y: dto.Position.Y },
              data: { label: dto.Data.Label },
            };
          }
          return node;
        })
      );
    },
    [isReadonly]
  );

  const updateSelectedEdge = useCallback(
    (dto: DAGEdgeType) => {
      if (isReadonly) return;

      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === dto.Id_) {
            return {
              ...edge,
              target: dto.Target,
              source: dto.Source,
              label: dto.Label,
            };
          }

          return edge;
        })
      );
    },
    [isReadonly]
  );

  const activateAddNodeMode = useCallback(() => setActiveMode(ActionMode.ADD_NODE), []);

  const resetActionMode = useCallback(() => setActiveMode(ActionMode.NONE), []);

  const onSelectionChange = useCallback((params: OnSelectionChangeParams) => {
    setActiveMode(() => {
      if (params.nodes[0]) return ActionMode.EDIT_NODE;
      if (params.edges[0]) return ActionMode.EDIT_EDGE;
      return ActionMode.NONE;
    });
    setSelected(params);
  }, []);

  const saveDag = useCallback(
    (dto: DagDtoType) => {
      const dag = dagRef.current;
      if (!dag) throw new Error('Cannot save DAG object because "DAG ref" is empty');

      setUpdating(true);

      entityApi
        .saveEntity({
          oldRecordKey: pointer,
          data: {
            ...dag,
            Nodes: dto.nodes,
            Edges: dto.edges,
          },
        })
        .finally(() => setUpdating(false));
    },
    [pointer]
  );

  useEffect(fetchNodes, []);

  useEffect(() => {
    if (isInitiatingRef.current || isReadonly) return;
    saveDag(deferredDTOs);
  }, [deferredDTOs, isReadonly]);

  return (
    <DagContext.Provider
      value={{
        isReadonly,
        isUpdating,
        pointer,
        type,
        actionMode,
        activateAddNodeMode,
        resetActionMode,
        nodes,
        edges,
        setNodes,
        setEdges,
        onNodesChange: nodesChangeHandler,
        onEdgesChange: edgesChangeHandler,
        addNode,
        updateSelectedNode,
        updateSelectedEdge,
        onConnect,
        selected,
        onSelectionChange,
      }}
    >
      {children}
    </DagContext.Provider>
  );
};
