import { GridTabDataType } from 'store/types';
import { SelectedGridRowType } from 'store/types/gridDataTypes';

export const isAlltabsInLayout = (
  openedTabs: GridTabDataType[],
  initiallOpenedTabs: string[],
  selectedGridRow: SelectedGridRowType | undefined,
  type: string
) => {
  if (!selectedGridRow || !type) {
    return false;
  }

  return (
    openedTabs.length === initiallOpenedTabs.length &&
    openedTabs.every((tab) => initiallOpenedTabs.find((initialTab) => initialTab === tab.id))
  );
};
