import { FC, useMemo } from 'react';

import { UIInputFile } from 'ui';
import { fileToBase64, isArray } from 'utils';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';

export type BinaryValueDataType = {
  Name: string;
  Content: string;
};

type BinaryValueProps = {
  value: BinaryValueDataType[] | BinaryValueDataType | null;
  isReadOnly?: boolean;
  isMultiply?: boolean;
  onChange: (value: BinaryValueDataType[] | BinaryValueDataType | null) => void;
};

export const BinaryValue: FC<BinaryValueProps> = ({ value, isReadOnly, isMultiply = false, onChange }) => {
  const handleOnChange = async (files: File[] | null) => {
    onChange(await convertFilesToBinaryData(files, isMultiply));
  };

  const filesNames = useMemo(() => {
    if (isArray(value)) {
      return dataToString(value?.map((it) => it.Name));
    }
    return value?.Name || '';
  }, [value]);

  return (
    <UIInputFile
      variant="transparent"
      value={filesNames}
      onChange={(files) => void handleOnChange(files)}
      multiply={isMultiply}
      readOnly={isReadOnly}
    />
  );
};

const convertFilesToBinaryData = async (
  files: File[] | null,
  isMultiply: boolean
): Promise<BinaryValueDataType[] | BinaryValueDataType | null> => {
  if (!files) return null;

  const fileNames = files?.map((file) => file.name);
  const base64Promises = files?.map(fileToBase64);
  const base64Result = await Promise.all(base64Promises);

  if (isMultiply) {
    return base64Result?.map((base64, index) => ({
      Name: fileNames[index],
      Content: base64,
    }));
  }

  return {
    Name: fileNames[0],
    Content: base64Result[0],
  };
};
