import { isObject } from 'utils';

import { StorageKeyEnum } from './types';

export class StorageDictionary<TValue> extends Map<string, Array<TValue>> {
  private readonly key: StorageKeyEnum;

  public static createByKey<TValue>(key: StorageKeyEnum) {
    const storageItem = localStorage.getItem(key);
    if (storageItem) {
      const parsedStorageItem = JSON.parse(storageItem);
      return isObject(parsedStorageItem)
        ? new StorageDictionary<TValue>(key, Object.entries(parsedStorageItem))
        : new StorageDictionary<TValue>(key);
    }

    return new StorageDictionary<TValue>(key);
  }

  constructor(storageKey: StorageKeyEnum, entries?: readonly (readonly [string, Array<TValue>])[] | null) {
    super(entries);

    this.key = storageKey;
  }

  public updateOrDeleteByValue(key: string, values: Array<TValue>) {
    if (values.length > 0) {
      this.set(key, values);
    } else {
      this.delete(key);
    }

    return this;
  }

  public save(): void {
    localStorage.setItem(this.key, this.toString());
  }

  public toString() {
    return JSON.stringify(Object.fromEntries(this.entries()));
  }
}
