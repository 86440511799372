import { useMemo } from 'react';

import { EditorTabType } from 'view/Editor/context/editorContext/type';
import { DataType } from 'view/Grid/utils/types';
import { SchemaTypeModel, StorageEntityModel } from 'api';
import { generateComplexColumnsHelper } from 'view/Editor/components/EditorView/ComplexVectorView/utils/helpers';
import { ComplexVectorViewGrid } from 'view/Editor/components/EditorView/ComplexVectorView/ComplexVectorViewGrid';
import { UiEmpty } from 'ui';
import { useEditorTabState } from 'view/Editor/hook/editorTabState.hook';

type ComplexVectorViewProps = {
  tab: EditorTabType;
  data?: StorageEntityModel;
  schema?: SchemaTypeModel;
};

export const ComplexVectorView = ({ data, schema, tab }: ComplexVectorViewProps) => {
  const { schemaPath, path } = tab;
  const { state } = useEditorTabState();

  const columns = useMemo(
    () =>
      generateComplexColumnsHelper({
        schema,
        path,
        schemaPath,
        currentTabIndex: state.tabIndex,
      }),
    [path, schema, schemaPath]
  );

  if (schema) {
    return <ComplexVectorViewGrid columns={columns} data={data as DataType[]} tab={tab} />;
  }

  return <UiEmpty title="Empty Generic" className="p-3" />;
};
