import { ConfigType } from '../types';

export const findTarget = (passedConfig: ConfigType, searchKey: string): ConfigType | undefined => {
  if (!searchKey) {
    return undefined;
  }

  if (passedConfig.key === searchKey) {
    return passedConfig;
  }

  if (passedConfig.content && passedConfig.content.length) {
    for (let i = 0; i < passedConfig.content.length; i += 1) {
      const result = findTarget(passedConfig.content[i] as ConfigType, searchKey);

      if (result) {
        return result;
      }
    }
  }

  return undefined;
};
