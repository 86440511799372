import { ChangeEvent, FC, PropsWithChildren } from 'react';
import cx from 'classnames';

import './UIRadio.scss';

export type UIRadioValue = string | number;

export type UIRadioProps = PropsWithChildren<{
  className?: string;
  value?: UIRadioValue;
  checked?: boolean;
  autoFocus?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}>;

export const UIRadio: FC<UIRadioProps> = ({ className, value, checked, autoFocus, disabled, onChange, children }) => {
  const classes = cx('ui-radio', { 'ui-radio--disabled': disabled }, className);

  return (
    <label className={classes}>
      <input
        autoFocus={autoFocus}
        className="ui-radio__input"
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="ui-radio__custom-input" />
      {children}
    </label>
  );
};
