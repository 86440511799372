import cx from 'classnames';

import styles from './styles.module.scss';

export const ScrollCorner = () => (
  <div className={cx('flex-shrink-0 d-flex flex-column py-0_5', styles.scrollCorner)}>
    <div className={cx('bg-gray-700 h-50', styles.separator)} />
    <div />
  </div>
);
