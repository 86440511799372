import { FC, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import { Grid } from 'view/Grid';
import { DataType, GetRowIdType } from 'view/Grid/utils/types';
import { LoadingStatusesEnum } from 'api';

type GenericRecordsTableProps = {
  data?: DataType[];
  isLoading?: boolean;
  columns: Column<DataType>[];
  selectedGenericRecordKey?: string;
  onSelectedGenericRecordKey: (key: string) => void;
  onRowDoublerClick: (row: Row<DataType>) => void;
};

export const GenericRecordsTable: FC<GenericRecordsTableProps> = ({
  data,
  columns,
  selectedGenericRecordKey,
  isLoading,
  onSelectedGenericRecordKey,
  onRowDoublerClick,
}) => {
  const { t } = useTranslation();
  const recordsGridId = useId();

  const getRowId: GetRowIdType = (row) => row._key as string;

  if (!isLoading && !data?.length && !columns.length) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-modal-grid-background">
        {t('editor.modals.genericTypeEmpty')}
      </div>
    );
  }

  return (
    <Grid
      status={isLoading ? LoadingStatusesEnum.LOADING : LoadingStatusesEnum.SUCCESS}
      variant="secondary"
      gridId={recordsGridId}
      data={data ?? []}
      columns={columns}
      isColumnFilter={false}
      selectedRowId={selectedGenericRecordKey}
      onChangeSelectedRowId={onSelectedGenericRecordKey}
      getRowId={getRowId}
      onRowDoubleClicked={onRowDoublerClick}
    />
  );
};
