import { SchemaTypeElementsModel, SchemaTypeModel, SchemaTypeListModel } from 'api';
import { isArray, ValueTypesEnum } from 'utils';

export function mapSchemaToElements(
  schema: SchemaTypeModel,
  schemaTypeList: SchemaTypeListModel
): SchemaTypeElementsModel[] {
  if (isArray(schema.Elements)) {
    return schema.Elements.reduce((filterableElements: SchemaTypeElementsModel[], element) => {
      const isFilterable = element.Filterable;
      const isExistsInKeys = isArray(schema.Keys) ? schema.Keys.includes(element.Name) : false;

      const innerSchemaType =
        schemaTypeList[`${element.Key?.Module.ModuleName}.${element.Name}`]?.Elements?.[0]?.Value?.Type;

      const schemaType = element.Value?.Type || innerSchemaType;

      const isAvailableType = [
        ValueTypesEnum.String,
        ValueTypesEnum.Int,
        ValueTypesEnum.Double,
        ValueTypesEnum.Long,
        ValueTypesEnum.Bool,
        ValueTypesEnum.Date,
        ValueTypesEnum.Time,
        ValueTypesEnum.DateTime,
      ].includes(schemaType as ValueTypesEnum);
      if ((isFilterable || isExistsInKeys) && isAvailableType && !element.Vector) {
        filterableElements.push(element);
      }

      return filterableElements;
    }, []);
  }

  return [];
}
