export enum ViewerTypesEnum {
  VariantTable = 'VariantTable',
  BinaryContent = 'BinaryContent',
  VariantMatrix = 'VariantMatrix',
  ViewContainer = 'ViewContainer',
  Script = 'Script',
  VariantTree = 'VariantTree',
  FormattedVariantTable = 'FormattedVariantTable',
  ViewHandlerAction = 'ViewHandlerAction',
  DAG = 'DAG',
  UIViewer = 'Control',
}

export enum BinaryContentTypesEnum {
  Plotly = 'Plotly',
  Html = 'Html',
  Jpg = 'Jpg',
  Png = 'Png',
  Pdf = 'Pdf',
  Xlsx = 'Xlsx',
}
