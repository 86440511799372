import { ReactElement } from 'react';

import { useVerticalSplit } from 'shared/Hooks/useVerticalSplit';
import { RightSplitPaneType } from 'containers/RightSplitPane/utils/types';

import styles from './styles.module.scss';

type VerticalSplitViewProps = {
  leftPane: ReactElement;
  rightPane: ({ rightBlockRef, rightBlockWidth, startResizing }: RightSplitPaneType) => JSX.Element | null;
};

export const VerticalSplitView = ({ leftPane, rightPane: RightPane }: VerticalSplitViewProps) => {
  const { rightBlockRef, rightBlockWidth, runResize, startResizing, stopResizing } = useVerticalSplit();

  return (
    <div
      className={styles.resizerContainer}
      onMouseMove={runResize}
      onMouseUp={stopResizing}
      onMouseLeave={stopResizing}
    >
      <div className={styles.paneBlock}>{leftPane}</div>
      <RightPane rightBlockRef={rightBlockRef} rightBlockWidth={rightBlockWidth} startResizing={startResizing} />
    </div>
  );
};
