import { useRef, useState } from 'react';

import { isDefined } from 'utils';

type DebounceType<T> = {
  fn: (value: T) => void;
  value?: T;
};

export function useDebounce<T = string>(wait = 500) {
  const timerId = useRef<NodeJS.Timeout>();
  const [isLoading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const debounce = ({ fn, value }: DebounceType<T>) => {
    setLoading(true);
    const update = value;
    if (timerId.current) clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      if (isDefined(update)) {
        fn(update);
        setLoading(false);
      }

      if (showLoader) {
        setShowLoader(false);
      }
    }, wait);
  };

  return {
    debounce,
    isLoading,
    showLoader,
  };
}
