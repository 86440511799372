import cx from 'classnames';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { StorageItemModel } from 'api';
import { GridToolbarPagination } from 'view/GridToolbar/components';
import { GridToolbarDataType } from 'view/GridToolbar/utils/types';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';
import { isNullable } from 'utils';
import { Badge } from 'ui';
import { Icon } from 'shared/Icon';

import styles from './GenericToolbar.component.module.scss';

type GenericToolbarProps = {
  entities: StorageItemModel[];
  paginationRange: { start: number; end: number };
  paginationPage: number;
  isAvailableNextPage: boolean;
  isAvailablePreviousPage: boolean;
  isQueryApplied: boolean;
  showQueryBuilder: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onFirstPage: () => void;
  isGridPageDataLoading: boolean;
};

export const GenericToolbar: FC<GenericToolbarProps> = ({
  entities,
  paginationRange,
  paginationPage,
  isAvailableNextPage,
  isAvailablePreviousPage,
  isQueryApplied,
  showQueryBuilder,
  onNextPage,
  onPreviousPage,
  onFirstPage,
  isGridPageDataLoading,
}) => {
  const { t } = useTranslation();

  const genericToolbarData: GridToolbarDataType[] = useMemo(
    () => [
      {
        groupId: uuid(),
        icons: [
          {
            className: isQueryApplied ? 'text-warning' : undefined,
            SvgIcon: APP_ICONS.query,
            tooltipPlaceholder: t('gridToolbar.query') || '',
            tooltipTarget: 'queryBuilder',
            actionHandler: showQueryBuilder,
            isDisabled: isQueryApplied ? false : entities.length === 0,
          },
        ],
      },
    ],
    [entities]
  );

  return (
    <div className="border-bottom border-chat-tabs-bottom-border mb-1 bg-primary d-flex justify-content-end text-text2 fs-13 py-1 px-2">
      <GridToolbarPagination
        isGridPageDataLoading={isGridPageDataLoading}
        className="me-auto"
        isAvailableNextPage={isAvailableNextPage}
        isAvailablePreviousPage={isAvailablePreviousPage}
        range={paginationRange}
        page={paginationPage}
        onNext={onNextPage}
        onPrev={onPreviousPage}
        onFirst={onFirstPage}
      />
      {genericToolbarData.map(({ groupName, groupId, icons }) => (
        <div key={groupId} className={cx('d-flex align-items-center', styles.iconsBlock)}>
          <span>{t(groupName || '')}</span>

          {icons.map(
            ({
              className,
              tooltipTarget,
              SvgIcon,
              isDisabled,
              tooltipPlaceholder,
              actionHandler,
              isHide,
              badgeContent,
            }) =>
              !isHide && (
                <Tooltip key={tooltipTarget}>
                  <TooltipTrigger>
                    <div
                      aria-hidden="true"
                      onClick={() => !isDisabled && actionHandler?.()}
                      className={cx('d-flex align-items-center', {
                        'me-2': !isNullable(badgeContent),
                      })}
                    >
                      {badgeContent ? (
                        <Badge content={badgeContent}>
                          <Icon
                            className={className}
                            clickable={Boolean(actionHandler)}
                            SvgIcon={SvgIcon}
                            disable={isDisabled}
                          />
                        </Badge>
                      ) : (
                        <Icon
                          className={className}
                          clickable={Boolean(actionHandler)}
                          SvgIcon={SvgIcon}
                          disable={isDisabled}
                        />
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{tooltipPlaceholder}</TooltipContent>
                </Tooltip>
              )
          )}
        </div>
      ))}
    </div>
  );
};
