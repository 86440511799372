import { useCallback, useRef, useState } from 'react';

import { StorageItemModel } from 'api';

export const PAGINATION_LIMIT = 1_000;

export function usePagination<TEntity = StorageItemModel>() {
  const [page, setPage] = useState(1);
  const [range, setRange] = useState({ start: 1, end: PAGINATION_LIMIT });
  const [isAvailableNextPage, setAvailableNextPage] = useState(false);
  const [isAvailablePreviousPage, setAvailablePreviousPage] = useState(false);
  const isAvailableNextPageRef = useRef(true);
  const [isPageDataLoading, setIsPageDataLoading] = useState(false);

  const isInitialPage = page === 1;

  const getLimit = () => PAGINATION_LIMIT + 1;

  const getSkipByPage = (currentPage: number) => (currentPage - 1) * PAGINATION_LIMIT;

  const getSkipByCurrentPage = () => getSkipByPage(page);

  const sliceEntitiesByLimit = (entities: StorageItemModel[]) => entities.slice(0, PAGINATION_LIMIT);

  const generateRangeByEntities = useCallback(
    (entities: TEntity[]): { start: number; end: number } => {
      if (isPageDataLoading) {
        return range;
      }

      const entitiesLength = entities.length;

      const start = (page - 1) * PAGINATION_LIMIT + (entitiesLength ? 1 : 0);
      const end = start + (entitiesLength ? entitiesLength - 1 : 0);

      return {
        start,
        end,
      };
    },
    [isPageDataLoading]
  );

  const updateRange = (entities: number) => {
    const start = page === 1 ? page : (page - 1) * PAGINATION_LIMIT + 1;

    setRange({ start, end: start + entities - 1 });
  };

  const updatePagination = (entities: TEntity[]) => {
    const size = entities.length;
    setAvailableNextPage(size > PAGINATION_LIMIT);
    setAvailablePreviousPage(size ? page > 1 : false);
  };

  const onNextPage = () => {
    if (!isAvailableNextPage || isPageDataLoading) return;
    const nextPage = page + 1;
    setIsPageDataLoading(true);
    setPage(nextPage);
  };

  const onPreviousPage = () => {
    if (!isAvailablePreviousPage || isPageDataLoading) return;
    const previousPage = page - 1;
    setIsPageDataLoading(true);
    setPage(previousPage);
  };

  const onFirstPage = () => {
    if (!isAvailablePreviousPage) return;
    setIsPageDataLoading(true);
    setPage(1);
  };

  const onResetPage = () => setPage(1);

  const reset = () => {
    setIsPageDataLoading(false);
  };

  return {
    isAvailableNextPageRef,
    isInitialPage,
    page,
    setPage,
    isAvailableNextPage,
    isAvailablePreviousPage,
    getSkipByCurrentPage,
    getLimit,
    sliceEntitiesByLimit,
    getSkipByPage,
    updatePagination,
    onResetPage,
    onPreviousPage,
    onFirstPage,
    onNextPage,
    generateRangeByEntities,
    reset,
    isPageDataLoading,
    updateRange,
  };
}
