import { FC, useId, useMemo } from 'react';

import { Grid } from 'view/Grid';
import { EntityPanelViewOfContainerType, EntityPanelViewOfNameType, EntityPanelViewOfStyle } from 'api';
import { isArray, isArrayOfStrings, isObject } from 'utils';
import { getColumns, getRows } from 'view/Viewers/TableViewer/helpers';

type FormattedVariantTableProps = {
  ColHeaders?: EntityPanelViewOfNameType[] | null | string[];
  ValuesContainer?: EntityPanelViewOfContainerType | null | undefined;
  MultiHeaderSeparator?: string;
  Style?: EntityPanelViewOfStyle[];
};

export const FormattedVariantTable: FC<FormattedVariantTableProps> = ({
  ColHeaders,
  ValuesContainer,
  MultiHeaderSeparator,
}) => {
  const id = useId();
  const { columns, rows } = useMemo(() => {
    if (isArrayOfStrings(ColHeaders) && isObject(ValuesContainer) && isArray(ValuesContainer.Array)) {
      const columnHeaders = MultiHeaderSeparator
        ? ColHeaders.map((item) => item.split(MultiHeaderSeparator).pop()!)
        : ColHeaders;

      return {
        // Temporarily using helpers from the variant table because styles don't support the formatted variable table.
        columns: getColumns(ColHeaders, ValuesContainer.Array, MultiHeaderSeparator),
        rows: getRows(columnHeaders, ValuesContainer.Array),
      };
    }

    return { columns: [], rows: [] };
  }, [ColHeaders, ValuesContainer]);

  return (
    <div className="h-100 overflow-auto">
      <Grid gridId={id} columns={columns} data={rows} />
    </div>
  );
};
