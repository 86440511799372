import { FC, ReactNode, useEffect, useState } from 'react';
import cx from 'classnames';

import {
  QueryBuilderConditionCellProps,
  QueryBuilderKeyCellProps,
  QueryBuilderRowType,
  QueryBuilderValueCellProps,
} from '../../utils/types';
import styles from './QueryBuilderTable.module.scss';

type QueryBuilderTableProps = {
  rows: QueryBuilderRowType[];
  renderKeyCell: (props: QueryBuilderKeyCellProps) => ReactNode;
  renderConditionCell: (props: QueryBuilderConditionCellProps) => ReactNode;
  renderValueCell: (props: QueryBuilderValueCellProps) => ReactNode;
};

export const QueryBuilderTable: FC<QueryBuilderTableProps> = ({
  rows,
  renderKeyCell,
  renderConditionCell,
  renderValueCell,
}) => {
  const [selectedRowIndex, selectRowIndex] = useState(0);

  const onSelectRow = (rowIndex: number) => {
    if (rowIndex === selectedRowIndex) return;

    selectRowIndex(rowIndex);
  };

  useEffect(() => {
    if (selectedRowIndex > rows.length - 1) {
      selectRowIndex(0);
    }
  }, [rows.length, selectedRowIndex]);

  return (
    <div className={cx('d-flex flex-column text-text1 fs-13 lh-16', styles.container)} role="table">
      {rows.map((row, rowIndex) => (
        <div className={styles.row} role="row" key={row.key} onClick={() => onSelectRow(rowIndex)}>
          <div className="overflow-hidden">
            {renderKeyCell({ key: row.key, isActive: rowIndex === selectedRowIndex })}
          </div>
          <div>{renderConditionCell(row)}</div>
          <div>{renderValueCell(row)}</div>
        </div>
      ))}
    </div>
  );
};
