import { FC } from 'react';

import { QueryType } from 'view/QueryBuilder';

import { QueryBuilderActions, QueryBuilderEditor, QueryBuilderToolbar } from './components';
import { useQueryBuilderContext } from '../../hooks';

type QueryBuilderContentProps = {
  onCancel: () => void;
  onApply: (updatedQuery: QueryType) => void;
};

export const QueryBuilderContent: FC<QueryBuilderContentProps> = ({ onCancel, onApply }) => {
  const { validQuery, query, schemaDictionary, updateValueByKey, reset } = useQueryBuilderContext();

  return (
    <div className="d-flex flex-column w-100">
      <QueryBuilderToolbar />
      <QueryBuilderEditor
        className="flex-grow-1 mx-2_5 border border-1 border-query-border-bottom rounded p-2px"
        schemaDictionary={schemaDictionary}
        query={query}
        updateValueByKey={updateValueByKey}
      />
      <QueryBuilderActions
        isApplyDisabled={!validQuery}
        onResetAll={reset}
        onApply={() => onApply(query)}
        onCancel={onCancel}
      />
    </div>
  );
};
