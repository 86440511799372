import { useCallback, useMemo, useState } from 'react';

import { toast } from 'utils/helpers';

import {
  getCloExclusionsStateRequest,
  RequestParamsType as GetCloExclusionsStateParamsType,
} from './utils/getCloExclusionsStateRequest';
import {
  RequestParamsType as SendCloExclusionsRequestParamsType,
  sendCloExclusionsRequest,
} from './utils/sendCloExclusionsRequest';
import { CloExclusionsState, CloExclusionsStateType } from '../../utils/types';

export function useCloExclusions() {
  const [state, setState] = useState<CloExclusionsState>(CloExclusionsState.Hidden);
  const cloExclusionsState: CloExclusionsStateType = useMemo(
    () => ({
      state,
      isHiddenState: state === CloExclusionsState.Hidden,
      isDisabled: state === CloExclusionsState.Disabled,
      isEnabled: state === CloExclusionsState.Enabled,
    }),
    [state]
  );

  const getCloExclusionsState = useCallback((params: GetCloExclusionsStateParamsType) => {
    getCloExclusionsStateRequest(params).then(setState);
  }, []);

  const sendCloExclusions = useCallback(
    (params: SendCloExclusionsRequestParamsType) =>
      sendCloExclusionsRequest(params).then((response) => {
        const message = response[0].value.String;
        const isError = response[1].value.String === 'error';
        const notificationMethod = isError ? toast.error : toast.success;
        notificationMethod(message);

        getCloExclusionsState(params);
      }),
    []
  );

  return {
    state: cloExclusionsState,
    getCloExclusionsState: getCloExclusionsState,
    sendCloExclusions: sendCloExclusions,
  };
}
