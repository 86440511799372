import { FC } from 'react';

import { OptionType, UiSelect } from 'ui';
import { isBool, isNullable } from 'utils';

type BooleanValueType = OptionType<string | null>;

type BooleanValueProps = {
  value: boolean | null;
  isRequired?: boolean;
  isDisable?: boolean;
  onChange: (value: boolean | null) => void;
};

export const BooleanValue: FC<BooleanValueProps> = ({ value, isRequired, isDisable, onChange }) => {
  const options: BooleanValueType[] = [
    ...(isRequired ? [] : [{ label: '(none)', value: null }]),
    { label: 'Y', value: 'true' },
    { label: 'N', value: 'false' },
  ];

  const selectedValue = (): BooleanValueType => {
    let stringValue = null;
    let stringLabel;

    if (isBool(value)) {
      stringValue = value ? 'true' : 'false';
      stringLabel = value ? 'Y' : 'N';
    }

    return {
      value: stringValue,
      label: stringLabel || '',
    };
  };

  const valueToBool = (data: string | null): boolean | null => {
    if (isNullable(data)) return null;

    return data === 'true';
  };

  const handleChange = (data: BooleanValueType) => {
    onChange(valueToBool(data.value));
  };

  return (
    <UiSelect
      className="h-100"
      isTransparent
      isDisabled={isDisable}
      menuPortalTarget={document.body}
      value={selectedValue()}
      options={options}
      onChange={(data) => handleChange(data as BooleanValueType)}
    />
  );
};
