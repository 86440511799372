import { PdfHighlightType } from 'view/Viewers/BinaryContentContainer/Components/PDFViewer/utils/types';

const removeNode = (node: Node): void => {
  const parent = node.parentNode;
  if (parent) {
    parent.removeChild(node);
  }
};

const replaceNode = (replacementNode: Node, node: Node): void => {
  removeNode(replacementNode);
  const parent = node.parentNode;
  if (parent) {
    parent.insertBefore(replacementNode, node);
  }
  removeNode(node);
};

export const unwrap = (node: Node): void => {
  const parent = node.parentNode;
  if (!parent) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(node);

  replaceNode(range.extractContents(), node);

  // Merge the text nodes
  parent.normalize();
};

// TODO: move it into common helpers
export const isVisibleInContainer = (element: HTMLElement, container: HTMLElement): boolean => {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return elementRect.top >= containerRect.top && elementRect.bottom <= containerRect.bottom;
};

const sortHighlightByPositions = (a: PdfHighlightType, b: PdfHighlightType) => {
  // Compare the top values first
  if (a.top < b.top) return -1;
  if (a.top > b.top) return 1;

  // Then compare the left values
  if (a.left < b.left) return -1;
  if (a.left > b.left) return 1;

  return 0;
};

export const updateHighlightIndexByPosition = (highlights: PdfHighlightType[]): PdfHighlightType[] => {
  const sortedHighlights = highlights.slice().sort(sortHighlightByPositions);
  const indexDictionary = sortedHighlights.reduce(
    (acc, highlight, index) => {
      if (Object.hasOwn(acc, highlight.index)) return acc;
      return { ...acc, [highlight.index]: index };
    },
    {} as Record<string, number>
  );

  return sortedHighlights.map((highlight) => ({ ...highlight, index: indexDictionary[highlight.index] }));
};
