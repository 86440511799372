import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'store';

import { SaveAppStateListener } from './AppStateListener';

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

const listeners: Array<(startListening: AppStartListening) => void> = [SaveAppStateListener];
listeners.forEach((listener) => listener(startAppListening));
