import { ChangeEvent, FC, useState } from 'react';

import { DictionaryDataValueModel, SimpleViewModel } from 'api';
import { DateFormatEnum, DateFormatUtility, isString } from 'utils';
import { UIInput } from 'ui';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { dataToString } from 'view/Editor/components/TableBody/TableBodyCell/formatCell';

import { VariantSelectModal } from './VariantSelectModa';
import styles from './VariantSelect.component.module.scss';

const doubleNumberRegExp = /^[+-]?\d+(\.\d+)?$/;
const intNumberRegExp = /^\d+$/;
const dateNumberRegExp = /(\d{4})-(\d{2})-(\d{2})/;

export enum VariantType {
  Missing = 'Missing',
  Int = 'Int',
  Double = 'Double',
  Time = 'Time',
  Date = 'Date',
  DateTime = 'DateTime',
  String = 'String',
  Bool = 'Bool',
  Key = 'Key',
}

export type VariantSelectValueType = SimpleViewModel;

export type VariantSelectDataType = DictionaryDataValueModel | null;

export type VariantSelectProps = {
  variantType?: VariantType;
  value?: VariantSelectValueType;
  isReadOnly?: boolean;
  isRequiredValue?: boolean;
  selectOnFocus?: boolean;
  onChange: (value: VariantSelectDataType) => void;
};

export const VariantSelect: FC<VariantSelectProps> = ({
  variantType,
  value,
  isRequiredValue = false,
  isReadOnly,
  selectOnFocus,
  onChange,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => !isReadOnly && setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);
  const handleSubmit = (data: VariantSelectDataType) => {
    onChange(data);
    handleCloseModal();
  };

  const renderValue = () => {
    switch (variantType) {
      case VariantType.Time:
        return isString(value)
          ? DateFormatUtility.create(value, DateFormatEnum.TIME_MS).format(DateFormatEnum.TIME)
          : '';

      case VariantType.Bool:
        return value ? 'Y' : 'N';

      case VariantType.DateTime:
        return isString(value) ? DateFormatUtility.create(value).format(DateFormatEnum.DATE_TIME) : '';

      default:
        return dataToString(value);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parseVariantType = (parseValue: string): VariantType => {
      if (intNumberRegExp.test(parseValue)) return VariantType.Int;
      if (doubleNumberRegExp.test(parseValue)) return VariantType.Double;
      if (dateNumberRegExp.test(parseValue)) return VariantType.Date;
      if (!parseValue) return VariantType.Missing;

      return VariantType.String;
    };
    onChange({
      [parseVariantType(e.target.value)]: e.target.value,
    });
  };

  return (
    <>
      <div className={styles.VariantSelect__wrapper}>
        <UIInput
          variant="transparent"
          value={renderValue()}
          onChange={handleChange}
          readOnly={isReadOnly}
          selectOnFocus={selectOnFocus}
        />
        <button tabIndex={0} className={styles.VariantSelect__variant} type="submit" onClick={handleOpenModal}>
          <div className={styles.VariantSelect__variantName}>{variantType || VariantType.Missing}</div>
          <div className={styles.VariantSelect__variantIcon}>
            <Icon SvgIcon={APP_ICONS.multiLineEdit} clickable={false} />
          </div>
        </button>
      </div>
      <VariantSelectModal
        value={value}
        variantType={variantType || VariantType.Missing}
        isRequiredValue={isRequiredValue}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </>
  );
};
