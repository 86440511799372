import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { selectVersions } from 'store/selectors/AppState.selector';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Copy } from 'shared/Copy';

import { VersionPair } from './components';
import styles from './styles.module.scss';

type DataToCopyType = {
  [key: string]: string;
};

export const Versions = () => {
  const { t } = useTranslation();
  const versions = useSelector(selectVersions);

  if (!versions) return null;

  const dataToCopy: DataToCopyType = versions.reduce((acc: DataToCopyType, { key, value }) => {
    acc[key] = value.String || '';
    return acc;
  }, {});

  return (
    <div className="d-flex w-100 flex-column">
      <div className="d-flex align-items-center gap-2">
        <Icon SvgIcon={APP_ICONS.info} clickable={false} color="text-user-info-icon" />
        <div className="fw-medium">{t('header.userInfo.versions')}:</div>
        <Copy className="ms-auto" textToCopy={JSON.stringify(dataToCopy)} />
      </div>
      <ul className={cx(styles.list, 'mt-2 mb-0')}>
        {versions.map(({ key, value }) => (
          <li key={key}>
            <VersionPair label={key} value={value.String || ''} />
          </li>
        ))}
      </ul>
    </div>
  );
};
