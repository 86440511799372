import { FC, ReactNode } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import cx from 'classnames';

import './UIInput.styles.scss';
import { KeyboardCode } from 'utils';

export type UIInputVariant = 'transparent';

const getVariant = (type: UIInputVariant): string =>
  ({
    transparent: 'ui-input__style_transparent',
  })[type] || '';

export type UIInputProps = Pick<
  TextFieldProps,
  | 'inputRef'
  | 'value'
  | 'type'
  | 'onClick'
  | 'onChange'
  | 'onFocus'
  | 'onPaste'
  | 'className'
  | 'placeholder'
  | 'error'
  | 'fullWidth'
  | 'onBlur'
  | 'onKeyDown'
  | 'autoFocus'
  | 'name'
  | 'label'
  | 'helperText'
> & {
  autoComplete?: string;
  variant?: UIInputVariant;
  fullHeight?: boolean;
  ellipsis?: boolean;
  rounded?: boolean;
  textAlign?: 'center' | 'left' | 'right';
  readOnly?: boolean;
  preventSelectionByClick?: boolean;
  blurOnEnter?: boolean;
  deselectOnEsc?: boolean;
  selectOnFocus?: boolean;
  prefixElement?: ReactNode;
  suffixElement?: ReactNode;
};

export const UIInput: FC<UIInputProps> = ({
  inputRef,
  value,
  onClick,
  className,
  variant,
  selectOnFocus = false,
  fullWidth = true,
  fullHeight = true,
  ellipsis = true,
  blurOnEnter = false,
  deselectOnEsc = false,
  rounded,
  textAlign,
  readOnly,
  preventSelectionByClick,
  prefixElement,
  suffixElement,
  autoComplete,
  onFocus,
  onKeyDown,
  ...rest
}) => {
  const classes = cx(
    'ui-input',
    {
      [getVariant(variant!)]: variant,
      'ui-input--fullHeight': fullHeight,
      'ui-input--ellipsis': ellipsis,
      'ui-input--rounded': rounded,
      [`ui-input--text-align-${textAlign}`]: textAlign,
    },
    className
  );

  const deselectInputOnEsc = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === KeyboardCode.Escape && e.target instanceof HTMLInputElement) {
      const valueLength = e.target.value.length;
      e.target.setSelectionRange(valueLength, valueLength);
    }
  };

  const blurInputOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      (e.code === KeyboardCode.Enter || e.code === KeyboardCode.NumpadEnter) &&
      e.target instanceof HTMLInputElement
    ) {
      e.target.blur();
    }
  };

  return (
    <TextField
      inputRef={inputRef}
      value={value}
      onClick={!preventSelectionByClick ? onClick : undefined}
      className={classes}
      fullWidth={fullWidth}
      InputProps={{
        readOnly,
        autoComplete,
        startAdornment: prefixElement ? <div className="ui-input__prefix">{prefixElement}</div> : null,
        endAdornment: suffixElement ? <div className="ui-input__suffix">{suffixElement}</div> : null,
      }}
      onFocus={(event) => {
        if (selectOnFocus) event.target.select();
        onFocus?.(event);
      }}
      onKeyDown={(e) => {
        if (blurOnEnter) blurInputOnEnter(e);
        if (deselectOnEsc) deselectInputOnEsc(e);
        onKeyDown?.(e);
      }}
      {...rest}
    />
  );
};
