export const applyTabsTransition = (draggingTab: HTMLDivElement, dropZone: HTMLElement) => {
  let tab = dropZone?.firstChild as HTMLElement;

  while (tab) {
    if (tab === draggingTab) {
      tab = tab.nextSibling as HTMLElement;
    }

    if (tab === null) {
      break;
    }

    tab.style.transition = '0.15s';

    tab = tab.nextSibling as HTMLElement;
  }
};
