import { FC } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';

import './index.scss';

type GridToolbarPaginationProps = {
  className?: string;
  range: { start: number; end: number };
  page: number;
  isAvailableNextPage: boolean;
  isAvailablePreviousPage: boolean;
  onNext: () => void;
  onPrev: () => void;
  onFirst: () => void;
  isGridPageDataLoading: boolean;
};

export const GridToolbarPagination: FC<GridToolbarPaginationProps> = ({
  className,
  range,
  isAvailableNextPage,
  isAvailablePreviousPage,
  onPrev,
  onNext,
  onFirst,
  isGridPageDataLoading,
}) => {
  const { t } = useTranslation();

  const isPreviousPaginationPageDisabled = isGridPageDataLoading || !isAvailablePreviousPage || range.start === 1;
  const isNextPAginationPageDisabled = isGridPageDataLoading || !isAvailableNextPage;

  return (
    <div className={cx(className, 'paginationContainer')}>
      <Tooltip>
        <TooltipTrigger className={cx({ tooltipTriggerContainer: !isPreviousPaginationPageDisabled })}>
          <Button
            className={cx('bg-transparent rounded border-0', 'paginationBtn')}
            disabled={isPreviousPaginationPageDisabled}
            onClick={onFirst}
          >
            <Icon SvgIcon={APP_ICONS.toBeginIcon2} color="text-grid-toolbar-icon" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('gridToolbar.pagination.firstPage')}</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger className={cx({ tooltipTriggerContainer: !isPreviousPaginationPageDisabled })}>
          <Button
            className={cx('bg-grid-toolbar-pagination-background rounded border-0', 'paginationBtn')}
            disabled={isPreviousPaginationPageDisabled}
            onClick={onPrev}
          >
            <Icon SvgIcon={APP_ICONS.prevArrow} color="text-grid-toolbar-icon" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('gridToolbar.pagination.previousPage')}</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger className={cx({ tooltipTriggerContainer: !isNextPAginationPageDisabled })}>
          <Button
            className={cx('bg-grid-toolbar-pagination-background rounded border-0', 'paginationBtn')}
            disabled={isNextPAginationPageDisabled}
            onClick={onNext}
          >
            <Icon SvgIcon={APP_ICONS.nextArrow} color="text-grid-toolbar-icon" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t('gridToolbar.pagination.nextPage')}</TooltipContent>
      </Tooltip>
      <span className="recordsCounter">
        {range.start} - {range.end}
      </span>
    </div>
  );
};
