import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { HeaderGroup, SortingRule, TableSortByToggleProps } from 'react-table';
import cx from 'classnames';

import { SortDropdown } from 'view/Grid/components/TableHeader/SortDropdown';
import { DataType, GridModeEnum } from 'view/Grid/utils/types';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { SortOrderEnum } from 'view/Grid/components/TableHeader/utils/types';
import { RequiredIcon } from 'shared/RequiredIcon';
import { Tooltip, TooltipContent, TooltipTrigger } from 'shared/Tooltip';

import styles from './styles.module.scss';

type HeaderCellProps = {
  gridRef: RefObject<HTMLDivElement>;
  column: HeaderGroup<DataType>;
  sortByProps: TableSortByToggleProps;
  gridMode: GridModeEnum;
  onSortByChanged: (updatedSortBy: SortingRule<DataType>[]) => void;
};

export const HeaderCell: FC<HeaderCellProps> = ({ gridRef, column, sortByProps, gridMode, onSortByChanged }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isEnableTooltip, enableTooltip] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const content = ref.current;
    const isOverflow = content.offsetWidth < content.scrollWidth;
    enableTooltip(isOverflow);
  }, [column.width]);

  const getSorting = (isSortedDesc?: boolean) => {
    if (isSortedDesc === undefined) return '';

    return (
      <Icon
        clickable={false}
        SvgIcon={isSortedDesc ? APP_ICONS.sortZA : APP_ICONS.sortAZ}
        color="text-grid-sort-icon-color"
      />
    );
  };

  const handleChangeSorting = useCallback(
    (sortValue: SortOrderEnum) => {
      if (!column.canSort) return;

      if (sortValue === SortOrderEnum.NONE) {
        column.clearSortBy();
        onSortByChanged([]);
      } else {
        const descending = sortValue === SortOrderEnum.DESC;
        column.toggleSortBy(descending);
        onSortByChanged([{ id: column.id, desc: descending }]);
      }
    },
    [column]
  );

  const renderHeader = () => {
    if (column.customHeader) {
      return (
        <TooltipTrigger ref={ref} className={cx('w-100 bg-gray-700 text-icon1', styles.titleBlock)}>
          {column.render('Header')}
        </TooltipTrigger>
      );
    }

    if (column.canSort) {
      return (
        <div
          className={cx(
            'w-100 py-0_5 px-2 d-flex align-items-center justify-content-between bg-grid-headers-background text-grid-headers-text fw-semibold',
            styles.sortingTitleBlock
          )}
          style={sortByProps.style}
          onClick={sortByProps.onClick}
        >
          <div className="d-flex text-truncate">
            <TooltipTrigger ref={ref} className="ps-1px text-truncate">
              {column.render('Header')}
            </TooltipTrigger>
            <span className="ms-1">{gridMode === GridModeEnum.EDITOR && column.isRequired && <RequiredIcon />}</span>
          </div>
          <div className={cx('d-flex align-items-center gap-1', styles.sorting)}>
            {getSorting(column.isSortedDesc)}
            <SortDropdown gridRef={gridRef} handleChangeSorting={handleChangeSorting} />
          </div>
        </div>
      );
    }

    return (
      <div
        className={cx(
          'w-100 p-0_5 d-flex justify-content-center bg-header-cell-background text-header-cell-text-color fw-semibold',
          styles.titleBlock
        )}
      >
        <div className="d-flex mx-2_5 text-truncate">
          {gridMode === GridModeEnum.EDITOR && column.isRequired && <RequiredIcon />}
          <TooltipTrigger ref={ref} className="ms-1">
            {column.render('Header')}
          </TooltipTrigger>
        </div>
      </div>
    );
  };

  return (
    <Tooltip enabled={column.suppressHeaderTooltip ? false : isEnableTooltip}>
      {renderHeader()}
      <TooltipContent>{column.render('Header')}</TooltipContent>
    </Tooltip>
  );
};
