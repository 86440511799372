import { FC, useEffect, useId, useState } from 'react';
import { Row } from 'react-table';

import { schemaApi, SchemaTypeHierarchyModel } from 'api';
import { useRequest } from 'hook/request.hook';
import { Grid } from 'view/Grid';
import { Loader } from 'shared/Loader';
import { DataType } from 'view/Grid/utils/types';
import { EditorTabsEnum } from 'view/EditorContent/utils/constants';
import { useAppContext } from 'context';
import { toast } from 'utils';

import { columns, getRowsHelper } from './utils/helpers';
import { EditorButtonsGroup } from '../EditorButtonsGroup';

type EditorSelectSuccessorProps = {
  type: string;
  onApplySuccessorType: (successor: string) => void;
};

export const EditorSelectSuccessor: FC<EditorSelectSuccessorProps> = ({ type, onApplySuccessorType }) => {
  const { action } = useAppContext();
  const id = useId();
  const [successors, setSuccessors] = useState<SchemaTypeHierarchyModel[]>();
  const [selectedSuccessor, setSelectedSuccessor] = useState<string>();

  const successorRequest = useRequest(schemaApi.getTypeHierarchy);

  const fetchSuccessors = async () => {
    try {
      const response = await successorRequest.fetch({ name: type });

      const successorsWithoutAbstractType = response.filter((elem) => elem.Name !== type);
      setSuccessors(successorsWithoutAbstractType);
    } catch (e) {
      toast.error(e);
    }
  };

  useEffect(() => {
    void fetchSuccessors();
  }, [type]);

  const rows = getRowsHelper(successors);

  const onGridRowChanged = (selectedRow?: Row<DataType>) => {
    if (!selectedRow) return;

    const { module } = selectedRow.original;

    setSelectedSuccessor(module as string);
  };

  const getRowId = (row: DataType) => row.module as string;

  const onOpenNewEntity = (successor: string) => {
    action.setActiveEditorTabAction(EditorTabsEnum.NEW_ENTITY);
    onApplySuccessorType(successor);
  };

  const onRowDoubleClick = (row?: Row<DataType>) => {
    if (row) {
      const { module } = row.original;

      onGridRowChanged(row);
      onOpenNewEntity(module as string);
    }
  };

  const onSubmit = () => {
    if (selectedSuccessor) {
      onOpenNewEntity(selectedSuccessor);
    }
  };

  const onReturnToEntity = () => {
    action.setActiveEditorTabAction(EditorTabsEnum.EDITOR);
  };

  if (successorRequest.isLoading || !rows) return <Loader />;

  return (
    <div className="w-100 d-flex flex-column h-100">
      <Grid
        gridId={id}
        columns={columns}
        data={rows}
        isColumnFilter={false}
        getRowId={getRowId}
        selectedRowId={selectedSuccessor}
        onChangeSelectedRowId={setSelectedSuccessor}
        onRowDoubleClicked={onRowDoubleClick}
      />
      <EditorButtonsGroup submitTitle="buttons.apply" onSubmit={onSubmit} onCancel={onReturnToEntity} />
    </div>
  );
};
