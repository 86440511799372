import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { env } from 'env';

import { SendLogsDataType, SendLogsEmailTagType } from 'store/types';

const initialState: { sendLogsData: SendLogsDataType } = {
  sendLogsData: {
    isToFieldValid: true,
    isFormatTextareaValid: true,
    formatTextareaTextLength: 0,
    emailTags: [
      {
        id: uuid(),
        label: env.feedBackEmail,
        isValid: true,
      },
    ],
  },
};

const SendLogsDataSlice = createSlice({
  name: 'sendLogsData',
  initialState: initialState,
  reducers: {
    setIsToFieldValid(state, action: PayloadAction<boolean>) {
      state.sendLogsData.isToFieldValid = action.payload;
    },
    setIsFormatTextareaValid(state, action: PayloadAction<boolean>) {
      state.sendLogsData.isFormatTextareaValid = action.payload;
    },
    changeEmailTag(state, action: PayloadAction<SendLogsEmailTagType>) {
      state.sendLogsData.emailTags = state.sendLogsData.emailTags.map((item) => {
        if (item.id !== action.payload.id) return item;
        return action.payload;
      });
    },
    addEmailTag(state, action: PayloadAction<SendLogsEmailTagType>) {
      state.sendLogsData.emailTags = [...state.sendLogsData.emailTags, action.payload];
    },
    removeEmailTag(state, action: PayloadAction<string>) {
      state.sendLogsData.emailTags = state.sendLogsData.emailTags.filter((item) => item.id !== action.payload);
    },
    setFormatTextareaLength(state, action: PayloadAction<string>) {
      const isEmptyString = +action.payload === 1;

      state.sendLogsData.formatTextareaTextLength = isEmptyString ? 0 : +action.payload;
      state.sendLogsData.isFormatTextareaValid = !isEmptyString;
    },
    cleanSendLogsData(state) {
      state.sendLogsData = initialState.sendLogsData;
    },
  },
});

export const SendLogsDataSliceReducer = SendLogsDataSlice.reducer;
export const {
  setIsToFieldValid,
  setIsFormatTextareaValid,
  changeEmailTag,
  addEmailTag,
  removeEmailTag,
  setFormatTextareaLength,
  cleanSendLogsData,
} = SendLogsDataSlice.actions;
