import { createContext, Dispatch, RefObject, SetStateAction } from 'react';
import type { Table } from '@tanstack/react-table';
import type { Virtualizer } from '@tanstack/react-virtual';

export type GridContextProps<TData> = {
  api: Table<TData>;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  headerRef: RefObject<HTMLDivElement>;
  bodyRef: RefObject<HTMLDivElement>;
  footerRef: RefObject<HTMLDivElement>;
  openedHeaderMenuId: string | undefined;
  setOpenedHeaderMenuId: Dispatch<SetStateAction<string | undefined>>;
  isHiddenVerticalScroll: boolean;
  isHiddenHorizontalScroll: boolean;
  setHiddenVerticalScroll: (isHidden: boolean) => void;
  setHiddenHorizontalScroll: (isHidden: boolean) => void;
  setHeaderScrollLeft: (left: number) => void;
  setBodyScrollLeft: (left: number) => void;
  setFooterScrollLeft: (left: number) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GridContext = createContext<GridContextProps<any> | null>(null);
