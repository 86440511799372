import cx from 'classnames';
import { FC } from 'react';

type LengthIconProps = {
  length: number;
  separator?: boolean;
};

export const LengthIcon: FC<LengthIconProps> = ({ length, separator }) => {
  if (!length) return null;

  return (
    <div
      className={cx('vector-drilled h-100 d-flex align-items-center ms-0_5 text-text1 fs-14', {
        'border-start-2 border-primary': separator,
      })}
    >{`[${length}]`}</div>
  );
};
