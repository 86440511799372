import cx from 'classnames';
import { FC } from 'react';

import { ModelSwitcher } from 'shared/ModelSwitcher';
import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';
import { ProfileDto } from 'api/data/profile.data';

import { ProfileItem } from '../ProfileItem';
import styles from './styles.module.scss';

type DefaultViewProfileMenuProps = {
  name: string;
  profiles: ProfileDto[];
  onClickBack: () => void;
};

export const DefaultViewProfileMenu: FC<DefaultViewProfileMenuProps> = ({
  profiles,
  onClickBack,
  name,
}: DefaultViewProfileMenuProps) => (
  <div className="d-flex flex-column mx-auto gap-3 my-auto">
    <h5 className={cx('d-flex mb-3 fw-bold text-tab-text-active align-items-center', styles.headerIcon)}>
      <Icon SvgIcon={APP_ICONS.chatBig} className="me-2" clickable={false} />
      <span>{name}</span>
    </h5>
    <ModelSwitcher />
    {profiles.map(({ ProfileDescription, ProfileName, IsDisabled }) => (
      <ProfileItem key={ProfileName} name={ProfileName} description={ProfileDescription} disabled={IsDisabled} />
    ))}
    <div onClick={onClickBack} className="c-pointer mt-4 d-flex">
      <Icon className="me-2" SvgIcon={APP_ICONS.arrowLeft} color="text-white" />
      <span>Back to main screen</span>
    </div>
  </div>
);
