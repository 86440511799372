import { FC } from 'react';

import { DownloadLogsButton } from '../DownloadLogsButton';
import { SendFeedbackButton } from '../SendFeedbackButton';
import { UserInfo } from '../UserInfo';

import styles from './UserModeActions.module.scss';

export const UserModeActions: FC = () => (
  <div className={styles.container}>
    <DownloadLogsButton />
    <SendFeedbackButton />
    <UserInfo />
  </div>
);
