import { Quill } from 'react-quill';

import { Icon } from 'shared/Icon';
import { APP_ICONS } from 'utils/icons';

import { TextareaIconDivider } from './components/TextareaIconDivider';

import styles from './styles.module.scss';

type QuillIconsType = {
  [key: string]: string | null;
};

const icons = Quill.import('ui/icons') as QuillIconsType;

export const QuillToolbar = () => {
  Object.keys(icons).forEach((key) => {
    icons[key] = null;
  });

  return (
    <div id="toolbar" className={styles.toolbar}>
      <span className="ql-formats m-0">
        <button type="button" className="ql-bold">
          <Icon SvgIcon={APP_ICONS.bold} />
        </button>
        <button className="ql-italic">
          <Icon SvgIcon={APP_ICONS.italic} />
        </button>
        <button className="ql-underline">
          <Icon SvgIcon={APP_ICONS.underline} />
        </button>
      </span>
      <TextareaIconDivider />
      <span className="ql-formats m-0">
        <button type="button" className="ql-align ql-active" value="">
          <Icon SvgIcon={APP_ICONS.alignLeft} />
        </button>
        <button type="button" className="ql-align" value="center">
          <Icon SvgIcon={APP_ICONS.alightCenter} />
        </button>
        <button type="button" className="ql-align" value="right">
          <Icon SvgIcon={APP_ICONS.alignRight} />
        </button>
        <button type="button" className="ql-align" value="justify">
          <Icon SvgIcon={APP_ICONS.justif} />
        </button>
      </span>
      <TextareaIconDivider />
      <span className="ql-formats m-0">
        <button className="ql-list" value="ordered">
          <Icon SvgIcon={APP_ICONS.numberingList} />
        </button>
      </span>
      <TextareaIconDivider />
      <span className="ql-formats m-0">
        <button className="ql-image">
          <Icon SvgIcon={APP_ICONS.attach} className="text-header-icon" />
        </button>
      </span>
      <TextareaIconDivider />
      <span className="ql-formats m-0">
        <button className="ql-clean">
          <Icon SvgIcon={APP_ICONS.clearText} />
        </button>
      </span>
    </div>
  );
};
