import { useCallback, useState } from 'react';

import { ViewerMetadataType } from 'store/types';
import { LoadingStatusesEnum, entityApi } from 'api';

export function useViewerData() {
  const [status, setStatus] = useState<LoadingStatusesEnum>(LoadingStatusesEnum.INITIAL);
  const [data, setData] = useState<ViewerMetadataType>();

  const reset = useCallback((shouldResetStatus = true) => {
    setData(undefined);
    shouldResetStatus && setStatus(LoadingStatusesEnum.INITIAL);
  }, []);

  const loadViewerData = useCallback(
    (panelId: string, type: string, dataset: string, environment?: string, key?: string) => {
      setStatus(LoadingStatusesEnum.LOADING);

      entityApi
        .getPanel({ panelId, type, dataset, key })
        .then((loadedData) => {
          setData(loadedData);
          setStatus(LoadingStatusesEnum.SUCCESS);
        })
        .catch(() => {
          reset(false);
          setStatus(LoadingStatusesEnum.ERROR);
        });
    },
    [reset]
  );

  return {
    status,
    data,
    loadViewerData,
    reset,
  };
}
