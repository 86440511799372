import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import { GenericModal } from 'view/GenericModal';
import { ContentHandlersModal } from 'view/Handlers';
import { useBoolean } from 'utils/hooks';
import { DisclaimerModal } from 'view/DisclaimerModal';
import { History } from 'view/History';

import { NewChatModal } from './NewChatModal';
import { ChatType } from '../utils/types';
import { useHistory } from '../hooks/useHistory';
import { useCollapsePanels } from '../hooks/useCollapsePanels';
import { useDisclaimer } from '../hooks/useDisclaimer';
import { useDashboard } from '../hooks/useDashboard';
import { Content } from './Content';
import { isTopic as isTopicHandler } from '../utils/helpers';

type InteractiveDashboardContainerProps = {
  type: string;
  chatKey?: string;
};

export const InteractiveDashboardContainer: FC<InteractiveDashboardContainerProps> = ({
  type: activeTypeTab,
  chatKey,
}) => {
  const viewersType = 'Chat';
  const chatTypeRef = useRef<{ type: ChatType; isProfile?: boolean } | null>(null);

  const { collapseContentPanel, contentPanelRef, isMaximized, onChangeSizes } = useCollapsePanels();
  const { isOpen, show: showDisclaimer, isOnce, toggleOnce } = useDisclaimer();
  const [isOpenNewChatModal, { setTrue: openNewChatModal, setFalse: closeNewChatModal }] = useBoolean(false);
  const [isOpenGenericModal, { setTrue: openGenericModal, setFalse: closeGenericModal }] = useBoolean(false);

  const isTopic = useMemo(() => isTopicHandler(activeTypeTab), [activeTypeTab]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const onChangeLoading = useCallback((updatedPooling: boolean) => setLoading(updatedPooling), []);

  const {
    loadingViewerData,
    activeViewerType,
    viewerData,
    tabs,
    activeTab,
    switchTab,
    updateOrderTabs,
    load: loadViewers,
    reset: resetViewers,
  } = useDashboard({ activeType: viewersType });

  const {
    cloExclusionsState,
    chatAboutParams,
    getContentByHandler,
    contentHandlers,
    handleClearContentHandlers,
    isExecuteAnalyzer,
    uploadingProgress,
    files,
    isSending,
    isPrivateType,
    message,
    messageGroups,
    activeChat,
    sessions,
    selectSession,
    deleteSelectedSessions,
    onChangeInput,
    onChangeFiles,
    sendMessage,
    cancelSendMessage,
    startNewChat,
    onRemoveFile,
    sendCloExclusions,
    getContentByKey,
    getUnsavedFiles,
    openMailClient,
  } = useHistory({
    chatKey,
    activeTypeTab,
    isLoading,
    onChangeLoading,
    loadViewers,
    resetViewers,
  });

  useEffect(() => {
    if (chatAboutParams) {
      startNewChatHandler(ChatType.INTERNAL, true);
    }
  }, [chatAboutParams]);

  const onSelectPrompt = useCallback(
    (promptId: string | undefined) => {
      if (!chatTypeRef.current) throw new Error('Cannot start new chat without type');

      closeNewChatModal();
      startNewChat(chatTypeRef.current?.type, promptId);
      chatTypeRef.current = null;
    },
    [startNewChat]
  );

  const onAgree = useCallback(() => {
    showDisclaimer(false);
    if (chatTypeRef.current) {
      if (chatTypeRef.current?.isProfile) {
        openNewChatModal();
      } else {
        onSelectPrompt(undefined);
      }
    }
  }, [showDisclaimer, onSelectPrompt]);

  const startNewChatHandler = useCallback(
    (type: ChatType, isProfile?: boolean) => {
      chatTypeRef.current = { type, isProfile };
      showDisclaimer(true);
      if (isOnce) {
        if (isProfile) {
          openNewChatModal();
        } else {
          onSelectPrompt(undefined);
        }
      }
    },
    [isOnce, onSelectPrompt, showDisclaimer]
  );

  const isShowMaximizedButton = useMemo(() => tabs.length > 0, [tabs.length]);

  const handleCloseNewChatModal = () => {
    closeNewChatModal();

    if (chatAboutParams) {
      chatAboutParams.handleMoveToPreviousTab();
    }
  };

  return (
    <>
      <DisclaimerModal isOpen={isOpen} isOnce={isOnce} toggleOnce={toggleOnce} agree={onAgree} />
      {isOpenGenericModal && (
        <GenericModal isOpen={isOpenGenericModal} onSubmit={getContentByKey} onClose={closeGenericModal} />
      )}
      {contentHandlers?.isMultiChoice && (
        <ContentHandlersModal
          handlers={contentHandlers.handlers}
          isOpen={contentHandlers.isMultiChoice}
          onClose={handleClearContentHandlers}
          onApply={getContentByHandler}
        />
      )}
      {isOpenNewChatModal && (
        <NewChatModal
          activeSession={activeChat}
          onCreate={onSelectPrompt}
          onClose={handleCloseNewChatModal}
          onOpen={openNewChatModal}
        />
      )}
      <PanelGroup direction="horizontal" onLayout={onChangeSizes} className="p-0_5">
        <Panel id="chat-history" order={1} minSize={20} className="shadow-sm bg-primary topBorderGradient">
          <History
            cloExclusionsState={cloExclusionsState}
            isExecuteAnalyzer={Boolean(isExecuteAnalyzer)}
            uploadingProgress={uploadingProgress}
            isShowMaximizedButton={isShowMaximizedButton}
            isMaximized={isMaximized}
            isTopic={isTopic}
            files={files}
            isSending={isSending}
            activeSession={activeChat}
            isPrivateType={isPrivateType}
            isLoading={isLoading}
            sessions={sessions}
            message={message}
            messageGroups={messageGroups}
            onChangeInput={onChangeInput}
            onSendMessage={sendMessage}
            cancelSendMessage={cancelSendMessage}
            startNewChat={startNewChatHandler}
            selectSession={selectSession}
            deleteSelectedSessions={deleteSelectedSessions}
            onChangeFiles={onChangeFiles}
            onRemoveFile={onRemoveFile}
            toggleMaximizedView={collapseContentPanel}
            sendCloExclusions={sendCloExclusions}
            openKeyDialog={openGenericModal}
            getUnsavedFiles={getUnsavedFiles}
            openMailClient={openMailClient}
          />
        </Panel>
        {tabs.length > 0 && viewerData && (
          <>
            <PanelResizeHandle className="verticalPanelResizer mx-1_5" />
            <Panel
              id="chat-content"
              order={2}
              collapsible
              ref={contentPanelRef}
              minSize={0}
              className="shadow-sm bg-primary topBorderGradient"
            >
              <Content
                currentKey={chatKey || activeChat?.ChatKey}
                type={viewersType}
                activeViewerType={activeViewerType}
                activeTab={activeTab}
                tabs={tabs}
                switchTab={switchTab}
                updateOrderTabs={updateOrderTabs}
                viewerData={viewerData}
                isLoading={loadingViewerData}
              />
            </Panel>
          </>
        )}
      </PanelGroup>
    </>
  );
};
