import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { v4 as uuid } from 'uuid';

import { UiModalHeader } from 'ui/UiModal/UiModalHeader';
import { UiModalResizableWrapper, UiModalResizableWrapperProps } from 'ui/UiModal/UiModalResizableWrapper';
import './UiModalContent.scss';

export type UiModalContentProps = UiModalResizableWrapperProps & {
  isDraggable?: boolean;
  title?: string;
  isClosable?: boolean;
  onClose?: () => void;
};

export const UiModalContent: FC<UiModalContentProps> = ({
  defaultHeight,
  defaultWidth,
  minHeightConstraints,
  minWidthConstraints,
  isClosable,
  isDraggable,
  title,
  children,
  onClose,
}) => {
  const uniqDragSelector = useMemo(() => `modal__${uuid()}`, []);

  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [positionCenter, setPositionCenter] = useState<{ left: number; top: number } | null>(null);

  useEffect(() => {
    if (modalWrapperRef?.current) {
      const modalWrapper = modalWrapperRef?.current.getBoundingClientRect();
      const top = (window.innerHeight - modalWrapper.height) / 2;
      const left = (window.innerWidth - modalWrapper.width) / 2;
      setPositionCenter({ left, top });
    }
  }, []);

  const handleStartDrag = (_event: DraggableEvent, data: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = modalWrapperRef?.current?.getBoundingClientRect();

    if (!targetRect) return;

    setBounds({
      left: -targetRect.left + data.x,
      right: clientWidth - (targetRect.right - data.x),
      top: -targetRect.top + data.y,
      bottom: clientHeight - (targetRect.bottom - data.y),
    });
  };

  const centerPositionStyle = { opacity: positionCenter ? 1 : 0, top: positionCenter?.top, left: positionCenter?.left };

  return (
    <Draggable disabled={!isDraggable} handle={`.${uniqDragSelector}`} bounds={bounds} onStart={handleStartDrag}>
      <div className="ui-modal-content" style={centerPositionStyle}>
        <UiModalResizableWrapper
          ref={modalWrapperRef}
          minHeightConstraints={minHeightConstraints}
          minWidthConstraints={minWidthConstraints}
          defaultHeight={defaultHeight}
          defaultWidth={defaultWidth}
        >
          <UiModalHeader
            title={title}
            isDraggable={isDraggable}
            onClose={onClose}
            className={uniqDragSelector}
            isClosable={isClosable}
          />
          {children}
        </UiModalResizableWrapper>
      </div>
    </Draggable>
  );
};
